import { Table } from "antd";
import React, { useState } from "react";
import GenericButton from "../../components/GenericButton/GenericButton";
import SettingsIcon from "@mui/icons-material/Settings";
import AirBnbLogo from "../../assets/airbnb.png";
import bookingLogo from "../../assets/booking.png";
import expediaLogo from "../../assets/expedia.png";
import vrboLogo from "../../assets/vrbo.svg";
import tripAdvisorLogo from "../../assets/tripAdvisor.png";
import ChannelManagerChannelDetails from "../ChannelManagerChannelDetails/ChannelManagerChannelDetails";

const ChannelManagerChannels = () => {
  const [isDetailsPage, setIsDetailsPage] = useState(false);
  const [detailsPageData, setDetailsPageData] = useState({});

  const columns = [
    {
      title: "",
      dataIndex: "notConnected",
      key: "notConnected",
      width: "15%",
      render: (_, record) => {
        return (
          <div className="bg-gray-300 w-28 flex items-center justify-center">
            <div className="text-sm text-themeGrey">Not connected</div>
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "logo",
      key: "logo",
    },

    {
      title: "",
      key: "action",
      align: "right",
      render: (_, record) => {
        return (
          <GenericButton
            label="Configure"
            className="bg-themeGreen text-white rounded-none"
            iconLeftAlign={true}
            icon={
              <SettingsIcon style={{ fontSize: 16 }} className="rounded-none" />
            }
            onClick={() => {
              setDetailsPageData(record);
              setIsDetailsPage(true);
            }}
          />
        );
      },
    },
  ];

  // Sample data.
  const data = [
    {
      key: "1",
      logo: <img src={AirBnbLogo} className="w-44 h-18" />,
    },
    {
      key: "2",
      logo: <img src={bookingLogo} className="w-44 h-18" />,
    },
    {
      key: "3",
      logo: <img src={expediaLogo} className="w-44 h-18" />,
    },
    {
      key: "4",
      logo: <img src={vrboLogo} className="w-44 h-18" />,
    },
    {
      key: "5",
      logo: <img src={tripAdvisorLogo} className="w-44 h-18" />,
    },
  ];

  return (
    <div className="my-10">
      {isDetailsPage ? (
        <ChannelManagerChannelDetails
          detailsPageData={detailsPageData}
          setIsDetailsPage={setIsDetailsPage}
        />
      ) : (
        <Table
          className="border-t border-creme"
          dataSource={data}
          columns={columns}
          scroll={{
            x: 1000,
          }}
        />
      )}
    </div>
  );
};

export default ChannelManagerChannels;
