import { Tabs } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  GUEST_PAYMENTS_AUTO_PAYMENTS,
  GUEST_PAYMENTS_CHARGES,
  GUEST_PAYMENTS_DOCUMENT_TEMPLATES,
} from "../../constants/dashboardRoutes";
import GuestPaymentsCharges from "../GuestPaymentsCharges/GuestPaymentsCharges";
import GuestPaymentsAutoPayments from "../GuestPaymentsAutoPayments/GuestPaymentsAutoPayments";
import GuestPaymentsDocumentTemplates from "../GuestPaymentsDocumentTemplates/GuestPaymentsDocumentTemplates";

const GuestPayments = ({ activeKey }) => {
  const navigate = useNavigate();
  const items = [
    {
      key: "charges",
      label: (
        <div
          className={`${
            activeKey === "charges" ? "text-themeGreen" : "text-themeGrey"
          } font-bold flex items-center gap-3`}
          onClick={() => {
            navigate(GUEST_PAYMENTS_CHARGES);
          }}
        >
          Charges
        </div>
      ),
      children: <GuestPaymentsCharges />,
    },
    {
      key: "auto-payments",
      label: (
        <div
          className={`${
            activeKey === "auto-payments" ? "text-themeGreen" : "text-themeGrey"
          } font-bold flex items-center gap-3`}
          onClick={() => {
            navigate(GUEST_PAYMENTS_AUTO_PAYMENTS);
          }}
        >
          Auto-payments
        </div>
      ),
      children: <GuestPaymentsAutoPayments />,
    },
    {
      key: "document-templates",
      label: (
        <div
          className={`${
            activeKey === "document-templates"
              ? "text-themeGreen"
              : "text-themeGrey"
          } font-bold flex items-center gap-3`}
          onClick={() => {
            navigate(GUEST_PAYMENTS_DOCUMENT_TEMPLATES);
          }}
        >
          Document Templates
        </div>
      ),
      children: <GuestPaymentsDocumentTemplates />,
    },
  ];

  return (
    <div className="mx-5 my-6 bg-white pb-10 px-5">
      <div className="">
        <div className="font-semibold text-2xl mt-2 w-[16%]">
          Guest Payments
        </div>
        <Tabs activeKey={activeKey} items={items} />
      </div>
    </div>
  );
};

export default GuestPayments;
