import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import GenericButton from "../GenericButton/GenericButton";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SettingsIcon from "@mui/icons-material/Settings";
import LinkIcon from "@mui/icons-material/Link";

const MarketplaceCardDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { cardTitle, cardContent, cardImage } = location.state;
  return (
    <div className="bg-white pr-5">
      <div className="font-bold text-3xl text-black pl-5 mb-5">Marketplace</div>
      <div
        className="font-bold text-md text-themeGreen pl-5 mb-5 flex items-center hover:underline cursor-pointer"
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon style={{ fontSize: 12 }} /> Back to Marketplace
      </div>
      <div className="pl-5 mb-5 grid xs:grid-cols-1 lg:grid-cols-2 gap-10">
        <div className="">
          <div className="w-[70%] h-80 flex items-center justify-between border-2 border-creme shadow-lg hover:shadow-2xl">
            <img
              src={cardImage}
              alt=""
              className="xs:w-32 sm:w-48 xmd:w-56 max-w-[300px] mx-auto my-auto"
            />
          </div>
        </div>
        <div>
          <div className="text-2xl text-themeGrey mb-4">{cardTitle}</div>
          <div className="text-themeGrey mb-2">
            The company connects travelers seeking authentic experiences with
            hosts offering unique, inspiring spaces. Whether the available space
            is a castle for a night, a sailboat for a week, or an apartment for
            a month,Airbnb is the easiest way for people to showcase these
            distinctive spaces to an audience of millions. By facilitating
            bookings and financial transactions, Airbnb makes the process of
            listing or booking a space effortless and efficient. With 4,500,000
            listings in over 65,000 cities in 191 countries, the company offers
            the widest variety of unique spaces for everyone, at any price point
            around the globe.
          </div>
          <div className="text-xl text-themeGrey font-bold mb-4">
            Categories
          </div>
          <div className="flex items-center gap-3 mb-4">
            <p className="px-2 border bg-gray-300 rounded-md">
              Booking Channel
            </p>
            <p className="px-2 border bg-gray-300 rounded-md">OTA</p>
          </div>
          <div className="text-xl text-themeGrey font-bold mb-1">
            User Guide:
          </div>
          <div className="text-themeGrey mb-2">
            <p>Connect to your Airbnb Account</p>
            <p>Import your listings</p>
            <p>You are good to go</p>
          </div>
          <div className="flex items-center justify-between mb-10">
            <div className=" xs:grid xs:grid-col-1 lg:flex gap-2">
              <GenericButton
                label={<p className="text-xs font-bold">User guide</p>}
                className="text-white bg-themeGreen rounded-none"
                size="small"
                iconLeftAlign={true}
                icon={
                  <MenuBookIcon
                    style={{ fontSize: 12 }}
                    className="mr-1 mb-[2px]"
                  />
                }
              />
              <GenericButton
                label={<p className="text-xs font-bold">Partner Website</p>}
                className="text-themeGreen bg-labelGreen rounded-none"
                iconLeftAlign={true}
                size="small"
                icon={
                  <LinkIcon
                    style={{ fontSize: 12 }}
                    className="mr-1 mb-[2px]"
                  />
                }
              />
            </div>
            <div>
              <GenericButton
                label={<p className="text-xs font-bold">Configure</p>}
                className="text-white bg-themeGreen rounded-none"
                size="small"
                iconLeftAlign={true}
                icon={
                  <SettingsIcon
                    style={{ fontSize: 12 }}
                    className="mr-1 mb-[2px]"
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketplaceCardDetails;
