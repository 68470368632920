import React from "react";
import DonutChart from "../DonutChart/DonutChart";

const FinancialReportingDonutGraph = ({
  graphText = "Per channel (total)",
}) => {
  return (
    <div>
      <div className="text-themeGrey">{graphText}</div>
      <DonutChart />
    </div>
  );
};

export default FinancialReportingDonutGraph;
