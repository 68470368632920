import React from "react";
import GenericButton from "../GenericButton/GenericButton";
import NotFoundImage from "../../assets/notFound.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link } from "react-router-dom";
import { Info } from "@mui/icons-material";
import { Input, Select, Table } from "antd";

const InboxManageMessages = () => {
  const columns = [
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Listing",
      dataIndex: "listing",
      key: "listing",
    },
    {
      title: "Schedule",
      dataIndex: "schedule",
      key: "schedule",
    },
    {
      title: "Trigger event",
      dataIndex: "triggerEvent",
      key: "triggerEvent",
    },
    {
      title: "Check in",
      dataIndex: "checkin",
      key: "checkin",
    },
    {
      title: "Check out",
      dataIndex: "checkout",
      key: "checkout",
    },
    {
      title: "Guest",
      dataIndex: "guest",
      key: "guest",
    },
  ];

  return (
    <div>
      <div className="mt-5 mb-10 grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <div>
          <div className="text-black mb-1">Scheduled by (From)</div>
          <Input placeholder="From" className="mb-1 rounded-none w-full" />
        </div>
        <div>
          <div className="text-black mb-1">Scheduled by (To)</div>
          <Input placeholder="To" className="mb-1 rounded-none w-full" />
        </div>
        <div>
          <div className="text-black mb-1">Status</div>
          <Select
            mode="multiple"
            placeholder="Template group name"
            className="mb-1 rounded-none w-full"
          />
        </div>
        <div>
          <div className="text-black mb-1">Guest</div>
          <Input
            placeholder="Search by guest name"
            className="mb-1 rounded-none w-full"
          />
        </div>
        <div>
          <div className="text-black mb-1">Tags</div>
          <Select placeholder="Tags" className="mb-1 rounded-none w-full" />
        </div>
        <div>
          <div className="text-black mb-1">Automation</div>
          <Select
            placeholder="Automation"
            className="mb-1 rounded-none w-full"
          />
        </div>
        <div>
          <div className="text-black mb-1">Listings</div>
          <Select placeholder="Listings" className="mb-1 rounded-none w-full" />
        </div>
        <div>
          <div className="text-black mb-1">Limit</div>
          <Select placeholder="Limit" className="mb-1 rounded-none w-[40%]" />
        </div>
      </div>
      <div className="my-10">
        <Table
          className="border-t border-creme"
          columns={columns}
          scroll={{
            x: 1000,
          }}
        />
      </div>
      <div className="flex flex-col items-center justify-center my-10">
        <img src={NotFoundImage} />
        <div className="font-bold text-xl my-5">
          A good relationship starts with good communication
        </div>
        <div className="text-themeGrey text-xl mb-3">
          Connect with guests from the very first moment by automating messages
          and share any significant information they could need
        </div>
        <div className="flex items-center mb-5">
          <CheckCircleIcon className="text-green-500 mr-1" />
          <div className="text-themeGrey text-lg">
            Use our templates to save time
          </div>
        </div>
        <div className="my-10">
          <Info className="text-themeGreen mr-1" style={{ fontSize: 16 }} />
          <Link className="text-themeGreen">Learn more</Link> about how to add &
          manage message templates groups
        </div>
      </div>
    </div>
  );
};

export default InboxManageMessages;
