import { Input, Modal } from "antd";
import React from "react";

const ChangePassword = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <Modal
      title={
        <div className="text-bold text-themeGrey text-lg pb-5 border-b-2 border-creme">
          Change current password
        </div>
      }
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      width={620}
    >
      <div className="my-5">
        <div className="my-5">
          <div className="text-themeGrey font-bold mb-2">Current password</div>
          <Input
            type="password"
            className="rounded-none w-full"
            placeholder="Current password"
          />
        </div>
      </div>
      <div className="my-5">
        <div className="my-5">
          <div className="text-themeGrey font-bold mb-2">Type new password</div>
          <Input
            className="rounded-none w-full"
            placeholder="Type new password"
          />
        </div>
      </div>
      <div className="my-5">
        <div className="my-5">
          <div className="text-themeGrey font-bold mb-2">
            Re-type new password
          </div>
          <Input
            className="rounded-none w-full"
            placeholder="Re-type new password"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ChangePassword;
