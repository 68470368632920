export const calculateROIValues = (values) => {
  const {
    bankFee,
    bridgingDuration,
    bridgingLoanIr,
    councilTax,
    // councilTaxUseMonday,
    depositPercentage,
    expectedNetRevenues,
    groundRent,
    internetCost,
    // internetUseMonday,
    legalPrice,
    ltv,
    maintenance,
    mortgageIr,
    otherCosts,
    purchasePrice,
    refurbishment,
    revaluationPrice,
    serviceCharge,
    utilityBills,
    // utilityBillsUseMonday
  } = values

  const deposit = purchasePrice * (depositPercentage / 100)
  let stampDuty = 0

  if (purchasePrice <= 125000) {
    stampDuty = purchasePrice * 0.03
  } else if (purchasePrice <= 250000) {
    stampDuty = (purchasePrice - 125000) * 0.05 + 3750
  } else if (purchasePrice <= 925000) {
    stampDuty = (purchasePrice - 250000) * 0.08 + 10000
  } else if (purchasePrice <= 1500000) {
    stampDuty = (purchasePrice - 925000) * 0.13 + 64000
  } else {
    stampDuty = (purchasePrice - 1500000) * 0.15 + 138750
  }

  const coefficient = 1 - (depositPercentage / 100)

  const _bankFee = (bankFee / 100) * purchasePrice * coefficient

  const _internetCost = internetCost

  const _utilityBills = utilityBills / 12
  const _councilTax = councilTax / 12
  const _serviceCharge = serviceCharge / 12
  const _groundRent = groundRent / 12
  const _maintenance = maintenance / 12

  const bridgingLoanCost = purchasePrice * (ltv / 100) *
    (bridgingLoanIr / 100) / (12 / bridgingDuration)

  const titleSplitDealTotalCosts = deposit + refurbishment + stampDuty +
    bridgingLoanCost + legalPrice + _bankFee + otherCosts

  const totalDebt = purchasePrice * coefficient

  const mortgage = revaluationPrice * (ltv / 100)

  const cashLeftInDeal = totalDebt + titleSplitDealTotalCosts - mortgage

  const percentageOfCashOut = (titleSplitDealTotalCosts - cashLeftInDeal) /
    titleSplitDealTotalCosts * 100

  const interestOnlyMortgage = mortgage * (mortgageIr / 100) / 12

  const operatingModelTotalCosts = interestOnlyMortgage + _councilTax +
    _internetCost + _utilityBills + _serviceCharge + _groundRent + _maintenance

  const netProfits = expectedNetRevenues - operatingModelTotalCosts
  const netProfitMargin = netProfits / expectedNetRevenues * 100

  const roi = (netProfits * 12) / cashLeftInDeal * 100

  return {
    stampDuty,
    deposit,
    refurbishment: refurbishment,
    legalPrice: legalPrice,
    bridgingLoanCost,
    titleSplitDealTotalCosts,
    bankFee: _bankFee,
    otherCosts: otherCosts,
    totalDebt,
    mortgage,
    cashLeftInDeal,
    percentageOfCashOut,
    interestOnlyMortgage,
    councilTax: _councilTax, // replace
    internet: _internetCost, // replace
    utilityBills: _utilityBills, // replace
    serviceCharge: _serviceCharge,
    groundRent: _groundRent,
    maintenance: _maintenance,
    operatingModelTotalCosts,
    netProfits,
    netProfitMargin,
    roi,
  }
}

export const createResultTableItems = calculatedValues => ([
  {
    title: 'Title Split Deal',
    items: [
      {
        label: 'Deposit',
        key: 'Deposit',
        children: calculatedValues.deposit,
        hasCashSign: true
      },
      {
        label: 'Refurbishment',
        key: 'Refurbishment',
        children: calculatedValues.refurbishment,
        hasCashSign: true
      },
      {
        label: 'Stamp Duty',
        key: 'Stamp Duty',
        children: calculatedValues.stampDuty,
        hasCashSign: true
      },
      {
        label: 'Legal',
        key: 'Legal',
        children: calculatedValues.legalPrice,
        hasCashSign: true
      },
      {
        label: 'Bridging Loan Cost',
        key: 'Bridging Loan Cost',
        children: calculatedValues.bridgingLoanCost,
        hasCashSign: true
      },
      {
        label: 'Bank Fee',
        key: 'Bank Fee',
        children: calculatedValues.bankFee,
        hasCashSign: true
      },
      {
        label: 'Other Costs',
        key: 'Other Costs',
        children: calculatedValues.otherCosts,
        hasCashSign: true
      },
      {
        label: 'Total Costs',
        key: 'Total Costs',
        children: calculatedValues.titleSplitDealTotalCosts,
        hasCashSign: true,
        bgColor: 'rgba(255,0,0,0.8)',
        labelStyles: { color: '#000000' },
        contentStyles: { color: '#000000', fontWeight: 'bold' }
      },
      {
        label: 'Total Debt',
        key: 'Total Debt',
        children: calculatedValues.totalDebt,
        hasCashSign: true,
        bgColor: 'rgb(255,175,149,0.8)',
        labelStyles: { color: '#000000' },
        contentStyles: { color: '#000000', fontWeight: 'bold' }
      },
    ]
  },
  {
    title: 'Re-financing',
    items: [
      {
        label: 'Mortgage',
        key: 'Mortgage',
        children: calculatedValues.mortgage,
        hasCashSign: true
      },
      {
        label: 'Cash left in the deal',
        key: 'Cash left in the deal',
        children: calculatedValues.cashLeftInDeal,
        hasCashSign: true,
        bgColor: 'rgba(131,197,124,0.8)',
        labelStyles: { color: '#000000' },
        contentStyles: { color: '#000000', fontWeight: 'bold' }
      },
      {
        label: 'Percentage of cash out',
        key: 'Percentage of cash out',
        children: calculatedValues.percentageOfCashOut,
        hasPercentSign: true,
        bgColor: 'rgba(220,255,218,0.8)',
        labelStyles: { color: '#000000' },
        contentStyles: { color: '#000000', fontWeight: 'bold' }
      },
    ]
  },
  {
    title: 'Flex Living Operating Model',
    items: [
      {
        label: 'Interest Only Mortgage',
        key: 'Interest Only Mortgage',
        children: calculatedValues.interestOnlyMortgage,
        hasCashSign: true
      },
      {
        label: 'Council Tax',
        key: 'Council Tax',
        children: calculatedValues.councilTax,
        hasCashSign: true
      },
      {
        label: 'Internet',
        key: 'Internet',
        children: calculatedValues.internet,
        hasCashSign: true
      },
      {
        label: 'Utility Bills',
        key: 'Utility Bills',
        children: calculatedValues.utilityBills,
        hasCashSign: true
      },
      {
        label: 'Service Charge',
        key: 'Service Charge',
        children: calculatedValues.serviceCharge,
        hasCashSign: true
      },
      {
        label: 'Ground Rent',
        key: 'Ground Rent',
        children: calculatedValues.groundRent,
        hasCashSign: true
      },
      {
        label: 'Maintenance',
        key: 'Maintenance',
        children: calculatedValues.maintenance,
        hasCashSign: true
      },
      {
        label: 'Total Costs',
        key: 'Total Costs',
        children: calculatedValues.operatingModelTotalCosts,
        hasCashSign: true,
        bgColor: 'rgba(255,250,192,0.8)',
        labelStyles: { color: '#000000' },
        contentStyles: { color: '#000000', fontWeight: 'bold' }
      },
      {
        label: 'Net Profits',
        key: 'Net Profits',
        children: calculatedValues.netProfits,
        hasCashSign: true
      },
      {
        label: 'Net Profit Margin',
        key: 'Net Profit Margin',
        children: calculatedValues.netProfitMargin,
        hasPercentSign: true,
        bgColor: 'rgba(156,227,99,0.8)',
        labelStyles: { color: '#000000' },
        contentStyles: { color: '#000000', fontWeight: 'bold' }
      },
      {
        label: 'ROI',
        key: 'ROI',
        children: calculatedValues.roi,
        hasPercentSign: true,
        bgColor: 'rgba(228,255,206,0.8)',
        labelStyles: { color: '#000000' },
        contentStyles: { color: '#000000', fontWeight: 'bold' }
      }
    ]
  }
])