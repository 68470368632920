import React from "react";

const FinancialInfoCard = ({
  infoCardText = "Rental Revenue",
  infoDetail = "€300.00",
}) => {
  return (
    <div className="w-full h-28 bg-dashboardBackground flex flex-col justify-center pl-5">
      <div className="text-black font-extrabold text-xl">{infoCardText}</div>
      <div className="text-themeGrey text-3xl">{infoDetail}</div>
    </div>
  );
};

export default FinancialInfoCard;
