import { DatePicker, Select } from "antd";
import React from "react";
import {
  channelOptions,
  financialChannelOptions,
  financialChartOptions,
  listingFinancialsEventOptions,
  quickDatesOptions,
  rentalRevenueOptions,
} from "../../enum";
import GenericButton from "../../components/GenericButton/GenericButton";
import FinancialInfoCard from "../../components/FinancialInfoCard/FinancialInfoCard";
import FinancialReportingBarChart from "../../components/FinancialReportingBarChart/FinancialReportingBarChart";
import FinancialReportingDonutGraph from "../../components/FinancialReportingDonutGraph/FinancialReportingDonutGraph";
import FinancialReportingSmallBarGraph from "../../components/FinancialReportingSmallBarGraph/FinancialReportingSmallBarGraph";
import { useNavigate } from "react-router-dom";
import { FINANCIAL_REPORTING_FINANCIAL_SETTINGS } from "../../constants/dashboardRoutes";
import SettingsIcon from "@mui/icons-material/Settings";

const FinancialReportingAnalytics = () => {
  const navigate = useNavigate();
  const rentalRevenuePerChannel = {
    options: {
      chart: {
        id: "bar-chart",
        type: "bar",
        width: "100%",
        height: "100%",
      },
      xaxis: {
        categories: ["2023/07/01"],
      },
      yaxis: {
        title: {
          text: "Y-axis Title",
          offsetX: 0,
          offsetY: -15,
        },
        min: 0,
        max: 300,
        labels: {
          formatter: function (value) {
            return `€${value.toFixed(2)}`;
          },
        },
      },
    },
    series: [
      {
        name: "Data Set 1",
        data: [0, 0, 0, 0, 300, 0, 0, 0, 0],
      },
    ],
  };

  const bookingsPerNightData = {
    options: {
      chart: {
        id: "bar-chart",
        type: "bar",
        width: "100%",
        height: "100%",
      },
      xaxis: {
        categories: ["2023/07/01"],
      },
      yaxis: {
        title: {
          text: "Y-axis Title",
          offsetX: 0,
          offsetY: -15,
        },
        min: 0,
        max: 1.0,
        tickAmount: 10,
        labels: {
          formatter: function (value) {
            return `${(value * 100).toFixed(1)}%`;
          },
        },
      },
    },
    series: [
      {
        name: "Data Set 1",
        data: [0, 0, 0, 0, 1.0, 0, 0, 0, 0],
      },
    ],
  };
  const rentalRevenuePerReservationData = {
    options: {
      chart: {
        id: "bar-chart",
        type: "bar",
        width: "100%",
        height: "100%",
      },
      xaxis: {
        categories: ["2023/07/01"],
      },
      yaxis: {
        title: {
          text: "Y-axis Title",
          offsetX: 0,
          offsetY: -15,
        },
        min: 0,
        max: 300,
        labels: {
          formatter: function (value) {
            return `€${value.toFixed(2)}`;
          },
        },
      },
    },
    series: [
      {
        name: "Data Set 1",
        data: [0, 0, 0, 0, 300, 0, 0, 0, 0],
      },
    ],
  };

  return (
    <div className="mt-3 my-4">
      <div className="flex justify-end">
        <GenericButton
          label="Settings"
          className="bg-selectedGreen text-themeGreen rounded-none xs:w-full sm:w-28 xs:my-2"
          iconLeftAlign={true}
          icon={<SettingsIcon />}
          onClick={() => {
            navigate(FINANCIAL_REPORTING_FINANCIAL_SETTINGS);
          }}
        />
      </div>
      <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-7 my-5 gap-4">
        <div>
          <div className="text-themeGrey mb-1">Listings</div>
          <Select className="w-full rounded-none" placeholder="Listings" />
        </div>
        <div>
          <div className="text-themeGrey mb-1">Tags</div>
          <Select className="w-full rounded-none" placeholder="Tags" />
        </div>
        <div>
          <div className="text-themeGrey mb-1">From</div>
          <DatePicker className="w-full rounded-none" placeholder="From" />
        </div>
        <div>
          <div className="text-themeGrey mb-1">To(incl.)</div>
          <DatePicker className="w-full rounded-none" placeholder="To" />
        </div>
        <div>
          <div className="text-themeGrey mb-1">Quickdates</div>
          <Select
            className="w-full rounded-none"
            placeholder="Quickdates"
            options={quickDatesOptions}
          />
        </div>
        <div>
          <div className="text-themeGrey mb-1">Channels</div>
          <Select
            className="w-full rounded-none"
            placeholder="Channels"
            options={channelOptions}
          />
        </div>

        <div className="flex gap-2 items-end">
          <div className="text-themeGrey mb-1 hidden">1</div>
          <GenericButton
            label="Reset"
            className="xs:w-full sm:w-40 rounded-none bg-selectedGreen text-themeGreen"
          />
        </div>
      </div>
      <div className="my-5 grid xs:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4">
        <FinancialInfoCard infoCardText="Rental Revenue" infoDetail="€300.00" />
        <FinancialInfoCard infoCardText="Nights booked" infoDetail="1" />
        <FinancialInfoCard infoCardText="Occupancy rate" infoDetail="0.35%" />
        <FinancialInfoCard
          infoCardText="Average daily rate"
          infoDetail="€200.00"
        />
        <FinancialInfoCard
          infoCardText="Average revenue per stay"
          infoDetail="€300.00"
        />
      </div>
      <div className="my-5 grid xs:grid-cols-1 xmd:grid-cols-12 gap-7">
        {/* Filters */}
        <div className="xmd:col-span-8 w-full bg-white grid grid-cols-2 gap-2">
          <Select placeholder="Rental revenue" options={rentalRevenueOptions} />
          <div className="grid grid-cols-3 gap-2">
            <Select
              placeholder="All channels"
              options={financialChannelOptions}
            />
            <Select
              placeholder="Check-in"
              options={listingFinancialsEventOptions}
            />
            <Select placeholder="Bar Chart" options={financialChartOptions} />
          </div>
        </div>
        {/* Empty space */}
        <div className="xmd:col-span-4 w-full bg-white text-white">item 1</div>
        {/* Bar or Line chart card */}
        <div className="xmd:col-span-8 w-full h-96 bg-dashboardBackground flex items-end justify-start">
          <FinancialReportingBarChart />
        </div>
        {/* Donut card */}
        <div className="xmd:col-span-4 w-full h-96 bg-dashboardBackground flex items-center justify-center">
          <FinancialReportingDonutGraph />
        </div>
      </div>
      <div className="my-5 grid xs:grid-cols-1 xmd:grid-cols-12 gap-7">
        <div className="col-span-4 bg-dashboardBackground h-64 w-full flex items-end justify-end">
          <FinancialReportingSmallBarGraph
            barChartData={rentalRevenuePerChannel}
            chartContainerHeight={200}
            chartHeightOffset={2}
            cardHeaderText="Rental revenue per channel"
          />
        </div>
        <div className="col-span-4 bg-dashboardBackground h-64 w-full flex items-end justify-end">
          <FinancialReportingSmallBarGraph
            barChartData={bookingsPerNightData}
            chartContainerHeight={200}
            chartHeightOffset={2}
            cardHeaderText="Booked nights per channel"
          />
        </div>
        <div className="col-span-4 bg-dashboardBackground h-64 w-full flex items-end justify-end">
          <FinancialReportingSmallBarGraph
            barChartData={rentalRevenuePerReservationData}
            chartContainerHeight={200}
            chartHeightOffset={2}
            cardHeaderText="Rental revenue per reservation per channel
"
          />
        </div>
      </div>
      <div className="my-5 grid xs:grid-cols-1 xmd:grid-cols-12 gap-7">
        {/* Filters */}

        {/* Bar or Line chart card */}
        <div className="xmd:col-span-8 w-full h-96 bg-dashboardBackground flex items-end justify-start">
          <FinancialReportingBarChart />
        </div>
        {/* Donut card */}
        <div className="xmd:col-span-4 w-full h-96 bg-dashboardBackground flex items-center justify-center">
          <FinancialReportingDonutGraph graphText="Reservations per channel" />
        </div>
      </div>
      <div className="my-5 xs:w-full md:w-[40%] flex xs:flex-col md:flex-row items-center justify-between gap-5">
        <div className="text-themeGrey">Upcoming</div>
        <div>
          <div className="text-themeGrey mb-2">From (Incl.)</div>
          <DatePicker className="w-full rounded-none" placeholder="From" />
        </div>
        <div>
          <div className="text-themeGrey mb-2">To</div>
          <DatePicker className="w-full rounded-none" placeholder="To" />
        </div>
      </div>
      <div className="my-5 grid xs:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4">
        <FinancialInfoCard infoCardText="Rental Revenue" infoDetail="-" />
        <FinancialInfoCard infoCardText="Nights booked" infoDetail="-" />
        <FinancialInfoCard infoCardText="Occupancy rate" infoDetail="-" />
        <FinancialInfoCard infoCardText="Average daily rate" infoDetail="-" />
        <FinancialInfoCard
          infoCardText="Average revenue per stay"
          infoDetail="-"
        />
      </div>
    </div>
  );
};

export default FinancialReportingAnalytics;
