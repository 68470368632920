import { Help, PercentOutlined } from "@mui/icons-material";
import { Avatar, Input, Modal, Select, Switch } from "antd";
import React from "react";
import GenericButton from "../GenericButton/GenericButton";
import AirbnbLogo from "../../assets/airbnb.png";
import handshakeLogo from "../../assets/handshake.svg";

const ChannelManagerSettingsModal = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <Modal
      title={
        <div className="text-bold text-themeGrey text-xl pb-5 border-b-2 border-creme">
          <Avatar size="large" className="mr-2" />
          3B SW15 - The Pecham Apartments
        </div>
      }
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      width={620}
    >
      <div>
        <div className="text-themeGrey text-xl font-bold flex">
          Booking Window
        </div>
        <div className="flex items-center justify-between">
          <div className="mt-7">
            <div className="text-themeGrey font-bold">
              Number of months
              <Help className="text-labelBlue ml-1" style={{ fontSize: 18 }} />
            </div>
            <div>
              <Select placeholder="Select..." className="rounded-none" />
              <GenericButton
                label="Save"
                className="bg-gray-300 text-black rounded-none ml-1"
              />
            </div>
          </div>
          <div>
            <Switch />
          </div>
        </div>
        <div className="flex justify-evenly">
          <img src={AirbnbLogo} className="w-32 my-10" />
        </div>
        <div className="flex items-center justify-between">
          <div className="text-themeGrey font-bold mr-1">
            Is listed in Airbnb
            <Help className="text-labelBlue ml-1" style={{ fontSize: 18 }} />
          </div>
          <div>
            <Switch />
          </div>
        </div>
        <div className="flex items-center justify-evenly">
          <img src={handshakeLogo} className="w-16" />
          <div className="text-themeGrey text-xl font-bold">markup</div>
        </div>
        <div className="flex items-center justify-between my-10">
          <div className="text-themeGrey">3B SW15 - The Peckham Apartments</div>
          <div className="flex">
            <Input
              value={0}
              addonAfter={
                <PercentOutlined className="rounded-none text-themeGrey" />
              }
              className="rounded-none"
            />
            <GenericButton
              label="Save"
              className="bg-themeGreen rounded-none text-white"
            />
          </div>
        </div>
        <div className="text-themeGrey text-[13px]">
          Flexliving Calendar is used as a baseline for your rates, and if you
          wish to have different rates on other channels you can edit the
          markup. If you want a price thats 15% higher than on airbnb, set the
          markup as 15.
        </div>
      </div>
    </Modal>
  );
};

export default ChannelManagerSettingsModal;
