import React, { useEffect, useState } from "react";
import "./Sidebar.css";

import { SidebarMenu } from "./sidebarMenu";
import { Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

const Sidebar = ({ setSiderClass }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [current, setCurrent] = useState('/overview')

  useEffect(() => {
    setCurrent(location?.pathname)
  }, [location?.pathname])

  const handleMenuClick = (key) => {
    navigate(key);
  };

  return (<Menu
    className="w-60 h-max pb-24 px-2 pt-2 sidebar-menu"
    theme="white"
    mode="inline"
    selectedKeys={[current]}
    items={SidebarMenu()}
    onClick={(d) => {
      handleMenuClick(d.key)
      setCurrent(d.key)
      setSiderClass(false)

    }}
  />
  );
};

export default Sidebar;

