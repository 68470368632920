import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiInstance, routes } from "./utils/API";
import { getAuthorizationToken, messages } from "./utils/constants";
import { Button, Modal } from "antd";
import { sendTostErrorMessage, sendTostSuccessMessage } from "./utils/helper";
export const Login = (props) => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState(null);
  const [sending, setSending] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [resetPassword, setResetPassword] = useState("");
  const [resetConfirmPassword, setResetConfirmPassword] = useState("");
  const [steps, setSteps] = useState(0);
  const [otp, setOtp] = useState("");
  const [otpNew, setOtpNew] = useState("");
  const [seconds, setSeconds] = useState(30);
  const [minutes, setMinutes] = useState(1);
  const navigation = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    if (getAuthorizationToken()) {
      navigation("/cleaning");
    }
  }, []);

  const validateFields = () => {
    if (!email) {
      setError(messages.EMAIL_REQUIRED);
      return false;
    } else if (!pass) {
      setError(messages.PASSWORD_REQUIRED);
      return false;
    } else {
      setError(null);
      return true;
    }
  };

  const handleSubmit = async (e) => {
    const loginObject = {
      email: email,
      password: pass,
    };
    e.preventDefault();
    const status = validateFields();
    if (!status) {
      return;
    }
    setSending(true);
    try {
      const response = await apiInstance.post(routes.LOGIN, loginObject);
      const res = await apiInstance.get(routes.RESERVATION_PROPERTIES);
      const data = response.data.data;
      localStorage.setItem("authorizationToken", data?.jwt);
      localStorage.setItem(
        "userName",
        `${data?.user?.firstName} ${data?.user?.lastName}`
      );
      localStorage.setItem("role", data?.user.role);
      localStorage.setItem("userEmail", data?.user?.email);
      localStorage.setItem("reservationProperties", JSON.stringify(res));
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      setError(error.response.data.message);
      setSending(false);
    }
  };

  const resendOtp = async () => {
    setMinutes(1);
    setSeconds(30);
    setSteps(steps + 1);
    const OTP = await apiInstance.post("/auth/sendotp", {
      email: resetEmail,
    });
    if (OTP?.data?.otp) {
      setOtp(OTP?.data?.otp);
    } else {
      console.log(OTP);
      sendTostErrorMessage(OTP.data.message);
    }
  };

  const handleOk = async () => {
    if (steps === 0) {
      if (!resetEmail) {
        setError("Email is a required filed.");
      } else if (!resetPassword) {
        setError("Password is a required field.");
      } else if (!resetConfirmPassword) {
        setError("Confirm password is a required field.");
      } else if (resetPassword !== resetConfirmPassword) {
        setError("Pasword and confirm password does not match.");
      } else {
        await resendOtp();
      }
    } else if (steps == 1) {
      if (!otpNew) {
        setError("Enter otp to reset password");
      } else if (otpNew !== otp) {
        setError("Incorrect OTP. Please enter the correct otp.");
      } else {
        const reset = await apiInstance.post("/auth/resetpassword", {
          email: resetEmail,
          password: resetPassword,
        });
        if (reset.data.message === "Password Changed Successfully.") {
          sendTostSuccessMessage(reset.data.message);
          setOpenModal(false);
        } else {
          sendTostErrorMessage(reset.data.message);
        }
      }
    }
  };

  const handleCancel = () => {
    setError("");
    setOpenModal(false);
    setSteps(0);
    setResetEmail("");
    setOtpNew("");
    setResetPassword("");
    setResetConfirmPassword("");
  };

  return (
    <div className="login-main-wrap">
      <Modal
        title="Reset Password"
        okText={steps === 0 ? "Next" : "Reset Password"}
        open={openModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          style: { backgroundColor: "#00b4b4", color: "white" },
        }} // Customize OK button style
      >
        {steps === 0 ? (
          <div>
            <div>
              <input
                value={resetEmail}
                onChange={(e) => {
                  setResetEmail(e.target.value);
                  setError("");
                }}
                type="email"
                placeholder="Email"
                className="border-2 border-black w-full"
              />
            </div>
            <div>
              <input
                value={resetPassword}
                onChange={(e) => {
                  setResetPassword(e.target.value);
                  setError("");
                }}
                type="password"
                placeholder="Password"
                className="border-2 border-black w-full"
              />
            </div>
            <div>
              <input
                value={resetConfirmPassword}
                onChange={(e) => {
                  setResetConfirmPassword(e.target.value);
                  setError("");
                }}
                type="password"
                placeholder="Confirm Password"
                className="border-2 border-black w-full"
              />
            </div>
            {error && <p className="text-red-500">{error}</p>}
          </div>
        ) : (
          <div>
            <h2>OTP has been sent to email {resetEmail}</h2>
            <input
              value={otpNew}
              onChange={(e) => {
                setOtpNew(e.target.value);
                setError("");
              }}
              type="text"
              placeholder="Enter OTP"
              className="border-2 border-black w-full"
            />
            {seconds === 0 && minutes === 0 ? (
              <div
                className="cursor-pointer text-green-500"
                onClick={() => resendOtp()}
              >
                Resend otp
              </div>
            ) : (
              <div className="flex gap-2">
                <p>Resend OTP in</p>
                <p className="text-green-500">{`${minutes}:${seconds}`}</p>
              </div>
            )}
            {error && <p className="text-red-500">{error}</p>}
          </div>
        )}
      </Modal>
      <div className="login-wrapper">
        <div className="bg-login-wrapper"></div>
        <div className="login-content-wrap">
          <div className="auth-form-container">
            <h1>Flexliving</h1>
            {error && <p className="error-message">{error}</p>}
            <form className="login-form" onSubmit={handleSubmit}>
              <input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError("");
                }}
                type="email"
                placeholder="Email"
                id="email"
                name="email"
                className="form-control"
              />
              <input
                value={pass}
                onChange={(e) => {
                  setPass(e.target.value);
                  setError("");
                }}
                type="password"
                placeholder="Password"
                id="password"
                name="password"
                className="form-control"
              />
              <button
                disabled={sending}
                type="submit"
                className="btn btn-primary login-btn mt-3"
              >
                Log In
              </button>
            </form>
            <button
              onClick={() => {
                setOpenModal(!openModal);
                setResetConfirmPassword("");
                setResetEmail("");
                setResetPassword("");
                setOtpNew("");
              }}
              className="link-btn btn link-danger"
            >
              Forgot your password?{" "}
            </button>
          </div>
        </div>
      </div>
      <div className="login-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <div className="logo-wrap">
                <h2>
                  Flex <br /> Living
                </h2>
              </div>
            </div>
            <div className="col-md-8"></div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </div>
      <div className="bottom-footer">
        <div className="terms__item"> © 2023 FlexLiving</div>
      </div>
    </div>
  );
};

export default Login;
