import { Input, Select } from "antd";
import React from "react";
import {
  autoChargesAmountType,
  autoChargesReservationTypes,
  autoChargesScheduledByOptions,
  autoChargesTypes,
  paymentMethodOptions,
} from "../../enum";

const AutoPaymentsFormItems = () => {
  return (
    <div className="grid xs:grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-2">
      <div>
        <p className="my-1">Type</p>
        <Select
          placeholder="Charge"
          className="w-full rounded-none my-1"
          options={autoChargesTypes}
        />
      </div>
      <div>
        <p className="my-1">Title</p>
        <Input
          placeholder="All channels"
          className="w-full rounded-none my-1"
        />
      </div>
      <div>
        <p className="my-1">Method</p>
        <Select
          placeholder="Credit card"
          className="w-full rounded-none my-1"
          options={paymentMethodOptions}
        />
      </div>
      <div>
        <p className="my-1">Amount</p>
        <Select
          placeholder="Rest of pay"
          className="w-full rounded-none my-1"
          options={autoChargesAmountType}
        />
      </div>
      <div>
        <p className="my-1">Scheduled By</p>
        <Select
          placeholder="At"
          className="w-full rounded-none my-1"
          options={autoChargesScheduledByOptions}
        />
      </div>
      <div>
        <p className="my-1 text-white">v</p>
        <Select
          placeholder="Reservations"
          className="w-full rounded-none my-1"
          options={autoChargesReservationTypes}
        />
      </div>
    </div>
  );
};

export default AutoPaymentsFormItems;
