import { Help } from "@mui/icons-material";
import { Input, Switch, Upload } from "antd";
import React, { useState } from "react";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import AmenetiesOrderCard from "../AmenetiesOrderCard/AmenetiesOrderCard";

const BookingEngineBasicSettings = () => {
  const [imageUrl, setImageUrl] = useState();

  return (
    <div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-3">
      <div>
        <div className="mb-5 text-themeGrey">
          Website name SEO meta title
          <Help style={{ fontSize: 18 }} className="text-themeGrey ml-1" />
        </div>
        <Input placeholder="Meta title" className="w-full" />
        <div className="font-bold mt-10 mb-5">Favicon</div>
        <div className="flex items-center justify-start ">
          <Upload
            name="avatar"
            className="my-5 h-20 w-[45%] flex items-center justify-center border-2 border-themeGrey border-dotted"
            showUploadList={false}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                className="w-[100%]"
                alt="avatar"
                style={{
                  width: "100%",
                }}
              />
            ) : (
              "Drag user photo and drop it here."
            )}
          </Upload>
        </div>
        <div className="font-bold mt-10">Booking engine logo</div>
        <div className="flex items-center justify-center ">
          <Upload
            name="avatar"
            className="my-5 h-40 w-[100%] flex items-center justify-center border-2 border-themeGrey border-dotted"
            showUploadList={false}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                className="w-[100%]"
                alt="avatar"
                style={{
                  width: "100%",
                }}
              />
            ) : (
              "Drag user photo and drop it here."
            )}
          </Upload>
        </div>
        <div className="mb-5 text-themeGrey">Login URL</div>
        <Input placeholder="Login URL" className="w-full" />
        <div>
          <div className="flex items-center justify-between my-4">
            <div className="text-themeGrey text-sm">
              Show map in search results
            </div>
            <Switch />
          </div>
          <div className="flex items-center justify-between my-4">
            <div className="text-themeGrey text-sm">
              Show exact listing location in map
            </div>
            <Switch />
          </div>
          <div className="flex items-center justify-between my-4">
            <div className="text-themeGrey text-sm">
              Show opt-in for promotional emails in checkout form
            </div>
            <Switch />
          </div>
          <div className="flex items-center justify-between my-4">
            <div className="text-themeGrey text-sm">
              Include fee and discounts in the rightly price
            </div>
            <Switch />
          </div>
        </div>
        <div className="font-bold mt-10 mb-5">Reservation settings</div>
        <div>
          <div className="flex items-center justify-between my-4">
            <div className="text-themeGrey text-sm">Allow inquiries</div>
            <Switch />
          </div>
          <div className="flex items-center justify-between my-4">
            <div className="text-themeGrey text-sm">Allow instant booking</div>
            <Switch />
          </div>
          <div className="flex items-center justify-between my-4">
            <div className="text-themeGrey text-sm">
              Help credit card information section from checkout
            </div>
            <Switch />
          </div>
          <div className="flex items-center justify-between my-4">
            <div className="teAmenetiesOrderCardxt-themeGrey text-sm">
              Display discount code field in checkout
            </div>
            <Switch />
          </div>
        </div>
        <div className="font-bold mt-10 mb-5">
          Ameneties order
          <Help style={{ fontSize: 20 }} className="text-labelBlue ml-1" />
        </div>
        <div className="w-full border-2 border-black h-96 p-2 mb-10 overflow-y-auto">
          <AmenetiesOrderCard />
          <AmenetiesOrderCard />
          <AmenetiesOrderCard />
          <AmenetiesOrderCard />
          <AmenetiesOrderCard />
          <AmenetiesOrderCard />
          <AmenetiesOrderCard />
          <AmenetiesOrderCard />
          <AmenetiesOrderCard />
          <AmenetiesOrderCard />
          <AmenetiesOrderCard />
          <AmenetiesOrderCard />
          <AmenetiesOrderCard />
          <AmenetiesOrderCard />
          <AmenetiesOrderCard />
          <AmenetiesOrderCard />
          <AmenetiesOrderCard />
          <AmenetiesOrderCard />
        </div>
      </div>
    </div>
  );
};

export default BookingEngineBasicSettings;
