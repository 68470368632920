import { Table } from 'antd'

// import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import CleaningServicesIcon from '@mui/icons-material/CleaningServices'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import DeleteIcon from '@mui/icons-material/Delete'
// import DescriptionIcon from '@mui/icons-material/Description'
// import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
// import MoneyIcon from '@mui/icons-material/Money'
// import PersonIcon from '@mui/icons-material/Person'
// import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import SettingsIcon from '@mui/icons-material/Settings'
import StarIcon from '@mui/icons-material/Star'
// import TuneIcon from '@mui/icons-material/Tune'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined'

import FormCheckBox from '../../Form/FormCheckBox'

export default function OtherPermissions ({ isDetails, accessContactData }) {
  const columns = [
    {
      title: <div className="text-themeGrey font-bold text-lg"></div>,
      dataIndex: 'options',
      key: 'options',
    },
    {
      title: (
        <div
          className="text-themeGrey font-bold text-lg flex items-center gap-1">
          <VisibilityIcon style={{ fontSize: 18 }}/>
          View
        </div>
      ),
      dataIndex: 'view',
      key: 'view',
    },
    {
      title: (
        <div
          className="text-themeGrey font-bold text-lg flex items-center gap-1">
          <BorderColorIcon style={{ fontSize: 18 }}/>
          Modify
        </div>
      ),
      dataIndex: 'modify',
      key: 'modify',
    },
    {
      title: (
        <div
          className="text-themeGrey font-bold text-lg flex items-center gap-1">
          <SettingsIcon style={{ fontSize: 18 }}/>
          Create
        </div>
      ),
      dataIndex: 'create',
      key: 'create',
    },
    {
      title: (
        <div
          className="text-themeGrey font-bold text-lg flex items-center gap-1">
          <DeleteIcon style={{ fontSize: 18 }}/>
          Delete
        </div>
      ),
      dataIndex: 'delete',
      key: 'delete',
    },
  ]

  // Sample data.
  const data = [
    // {
    //   key: "1",
    //   options: (
    //     <div className="flex items-center gap-2">
    //       <FormatListBulletedIcon style={{ fontSize: 12 }} />
    //       Listing
    //     </div>
    //   ),
    //   view: <FormCheckBox disabled={isDetails} name="readListing" />,
    //   modify: <FormCheckBox disabled={isDetails} name="updateListing" />,
    //   create: <FormCheckBox disabled={isDetails} name="createListing" />,
    //   delete: <FormCheckBox disabled={isDetails} name="deleteListing" />,
    // },
    {
      key: '2',
      options: (
        <div className="flex items-center gap-2">
          <CalendarMonthIcon style={{ fontSize: 12 }}/>
          Reservation Report
        </div>
      ),
      view: <FormCheckBox name="readReservation"/>,
    },
    // {
    //   key: "3",
    //   options: (
    //     <div className="flex items-center gap-2">
    //       <CalendarMonthIcon style={{ fontSize: 12 }} />
    //       Owner Stays
    //     </div>
    //   ),
    //   view: <FormCheckBox disabled={isDetails} name="readOwnerStay" />,
    //   modify: <FormCheckBox disabled={isDetails} name="updateOwnerStay" />,
    //   create: <FormCheckBox disabled={isDetails} name="createOwnerStay" />,
    //   delete: <FormCheckBox disabled={isDetails} name="deleteOwnerStay" />,
    // },
    // {
    //   key: "4",
    //   options: (
    //     <div className="flex items-center gap-2">
    //       <CalendarMonthIcon style={{ fontSize: 12 }} /> Calendar
    //     </div>
    //   ),
    //   view: <FormCheckBox disabled={isDetails} name="readCalendar" />,
    //   modify: <FormCheckBox disabled={isDetails} name="updateCalendar" />,
    //   create: "",
    //   delete: "",
    // },
    // {
    //   key: "5",
    //   options: (
    //     <div className="flex items-center gap-2">
    //       <TuneIcon style={{ fontSize: 12 }} />
    //       Booking Engine
    //     </div>
    //   ),
    //   view: <FormCheckBox disabled={isDetails} name="readBookingEngine" />,
    //   modify: <FormCheckBox disabled={isDetails} name="updateBookingEngine"
    // />, },
    {
      key: '6',
      options: (
        <div className="flex items-center gap-2">
          <MonetizationOnIcon style={{ fontSize: 12 }}/>
          Financial reporting
        </div>
      ),
      view: <FormCheckBox name="readFinancialReporting"/>,
    },
    // {
    //   key: "7",
    //   options: (
    //     <div className="flex items-center gap-2">
    //       <MoneyIcon style={{ fontSize: 12 }} />
    //       Owner statements
    //     </div>
    //   ),
    //   view: <FormCheckBox disabled={isDetails} name="readOwnerStatement" />,
    //   modify: <FormCheckBox disabled={isDetails} name="updateOwnerStatement"
    // />, create: <FormCheckBox disabled={isDetails}
    // name="createOwnerStatement" />, delete: <FormCheckBox
    // disabled={isDetails} name="deleteOwnerStatement" />, }, { key: "8",
    // options: ( <div className="flex items-center gap-2"> <MonetizationOnIcon
    // style={{ fontSize: 12 }} /> Expenses and extra management </div> ),
    // view: <FormCheckBox disabled={isDetails} name="readExpense" />, modify:
    // <FormCheckBox disabled={isDetails} name="updateExpense" />, create:
    // <FormCheckBox disabled={isDetails} name="createExpense" />, delete:
    // <FormCheckBox disabled={isDetails} name="deleteExpense" />, }, { key:
    // "9", options: ( <div className="flex items-center gap-2"> <DescriptionIcon style={{ fontSize: 12 }} /> Channel manager </div> ), view: <FormCheckBox disabled={isDetails} name="readChannelManager" />, modify: <FormCheckBox disabled={isDetails} name="updateChannelManager" />, }, { key: "10", options: ( <div className="flex items-center gap-2"> <TuneIcon style={{ fontSize: 12 }} /> Task </div> ), view: <FormCheckBox disabled={isDetails} name="readTask" />, modify: <FormCheckBox disabled={isDetails} name="updateTask" />, create: <FormCheckBox disabled={isDetails} name="createTask" />, delete: <FormCheckBox disabled={isDetails} name="deleteTask" />, }, { key: "11", options: ( <div className="flex items-center gap-2"> <IntegrationInstructionsIcon style={{ fontSize: 12 }} /> Integration </div> ), modify: <FormCheckBox disabled={isDetails} name="updateIntegration" />, }, { key: "12", options: ( <div className="flex items-center gap-2"> <SettingsIcon style={{ fontSize: 12 }} /> Automation </div> ), view: <FormCheckBox disabled={isDetails} name="readAutomation" />, modify: <FormCheckBox disabled={isDetails} name="updateAutomation" />, create: <FormCheckBox disabled={isDetails} name="createAutomation" />, delete: <FormCheckBox disabled={isDetails} name="deleteAutomation" />, }, { key: "13", options: ( <div className="flex items-center gap-2"> <QuestionAnswerIcon style={{ fontSize: 12 }} /> Message </div> ), view: <FormCheckBox disabled={isDetails} name="readMessage" />, modify: <FormCheckBox disabled={isDetails} name="updateMessage" />, }, { key: "14", options: ( <div className="flex items-center gap-2"> <DescriptionIcon style={{ fontSize: 12 }} /> Bulk edit listings </div> ), modify: ( <FormCheckBox disabled={isDetails} name="updateBulkEditListings" /> ), },
    {
      key: '15',
      options: (
        <div className="flex items-center gap-2">
          <CreditCardIcon style={{ fontSize: 12 }}/>
          Check-In Instructions
        </div>
      ),
      view: <FormCheckBox name="readGuestInvoicing"/>,
      modify: <FormCheckBox name="updateGuestInvoicing"/>,
    },
    // {
    //   key: "16",
    //   options: (
    //     <div className="flex items-center gap-2">
    //       <StarIcon style={{ fontSize: 12 }} />
    //       Reviews
    //     </div>
    //   ),
    //   view: <FormCheckBox disabled={isDetails} name="readReview" />,
    //   modify: <FormCheckBox disabled={isDetails} name="updateReview" />,
    //   create: <FormCheckBox disabled={isDetails} name="createReview" />,
    //   delete: <FormCheckBox disabled={isDetails} name="deleteReview" />,
    // },
    // {
    //   key: "17",
    //   options: (
    //     <div className="flex items-center gap-2">
    //       <PersonIcon style={{ fontSize: 12 }} />
    //       Guestbook
    //     </div>
    //   ),
    //   view: (
    //     <FormCheckBox
    //       disabled={!accessContactData || isDetails}
    //       name="readGuestbook"
    //     />
    //   ),
    //   modify: (
    //     <FormCheckBox
    //       disabled={!accessContactData || isDetails}
    //       name="updateGuestbook"
    //     />
    //   ),
    //   create: (
    //     <FormCheckBox
    //       disabled={!accessContactData || isDetails}
    //       name="createGuestbook"
    //     />
    //   ),
    //   delete: (
    //     <FormCheckBox
    //       disabled={!accessContactData || isDetails}
    //       name="deleteGuestbook"
    //     />
    //   ),
    // },
    {
      key: '18',
      options: (
        <div className="flex items-center gap-2">
          <CleaningServicesIcon style={{ fontSize: 12 }}/>
          Cleaning
        </div>
      ),
      view: <FormCheckBox name="readCleaning"/>,
      modify: <FormCheckBox name="updateCleaning"/>,
    },
    {
      key: '19',
      options: (
        <div className="flex items-center gap-2">
          <StarIcon style={{ fontSize: 12 }}/>
          Views
        </div>
      ),
      view: <FormCheckBox name="readViews"/>,
    },
    {
      key: '20',
      options: (
        <div className="flex items-center gap-2">
          <StarIcon style={{ fontSize: 12 }}/>
          Reservation Tool
        </div>
      ),
      view: <FormCheckBox name="readRelocationTool"/>,
    },
    // {
    //   key: "21",
    //   options: (
    //     <div className="flex items-center gap-2">
    //       <StarIcon style={{ fontSize: 12 }} />
    //       Relocation Tool
    //     </div>
    //   ),
    //   view: <FormCheckBox disabled={isDetails} name="readRelocationTool" />,
    // },
    {
      key: '22',
      options: (
        <div className="flex items-center gap-2">
          <StarIcon style={{ fontSize: 12 }}/>
          Property Report
        </div>
      ),
      view: <FormCheckBox name="readPropertyReport"/>,
    },
    {
      key: '23',
      options: (
        <div className="flex items-center gap-2">
          <StarIcon style={{ fontSize: 12 }}/>
          Reservations Overview
        </div>
      ),
      view: (
        <FormCheckBox name="readReservationsOverview"/>
      ),
    },
    {
      key: '24',
      options: (
        <div className="flex items-center gap-2">
          <StarIcon style={{ fontSize: 12 }}/>
          Finance
        </div>
      ),
      view: <FormCheckBox name="readFinance"/>,
    },
    {
      key: '25',
      options: (
        <div className="flex items-center gap-2">
          <StarIcon style={{ fontSize: 12 }}/>
          Guest Com
        </div>
      ),
      view: <FormCheckBox name="readGuestCom"/>,
    },
    {
      key: '26',
      options: (
        <div className="flex items-center gap-2">
          <LockOpenOutlinedIcon style={{ fontSize: 12 }}/>
          LockBox
        </div>
      ),
      view: <FormCheckBox name="readLockBox"/>,
      modify: <FormCheckBox name="updateLockBox"/>,
      create: <FormCheckBox name="createLockBox"/>,
      delete: <FormCheckBox name="deleteLockBox"/>,
    },
    {
      key: '27',
      options: (
        <div className="flex items-center gap-2">
          <VpnKeyOutlinedIcon style={{ fontSize: 12 }}/>
          KeyNest
        </div>
      ),
      view: <FormCheckBox name="readKeys"/>,
    },
  ]

  return (
    <div className="mb-5">
      <Table
        className="border-t border-creme mb-10"
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{
          x: 1000,
          y: 8000,
        }}
      />
    </div>
  )
}
