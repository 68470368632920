import { useState } from "react";
import { Checkbox } from "antd";

import manageTasksBG from "../../../assets/manageTasksBG.jpg";

function ListingCard({ selectAll }) {
  const [singleSelect, setSingleSelect] = useState(false);

  return (
    <div className="flex w-full h-[80px]">
      <img src={manageTasksBG} className="w-[30%]" />
      <p className="m-2">Listing Description</p>
      <div className="flex justify-end items-end w-full">
        <Checkbox
          checked={selectAll || singleSelect}
          className="m-2"
          onClick={() => setSingleSelect(!singleSelect)}
        />
      </div>
    </div>
  );
}

export default ListingCard;
