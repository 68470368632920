import { Input, Select, Switch } from "antd";
import React, { useState } from "react";
import GenericButton from "../../components/GenericButton/GenericButton";
import ApartmentImage from "../../assets/apartment.jpeg";
import RoomImage from "../../assets/room.jpeg";
import ListingCard from "../../components/ListingCard/ListingCard";
import AddListingCategory from "../../components/AddListingCategory/AddListingCategory";

const BookingEngineListings = () => {
  const [addNewCategory, setAddNewCategory] = useState(false);
  const [newCategoryCount, setNewCategoryCount] = useState(0);

  // Recursive function to render AddListingCategory
  const renderAddListingCategories = (count) => {
    if (count <= 0) {
      return null;
    }
    return (
      <div key={count}>
        <AddListingCategory />
        {renderAddListingCategories(count - 1)}
      </div>
    );
  };

  return (
    <div>
      <div className="font-extrabold text-xl mb-5">
        Select listings for your website
      </div>
      <div className="text-themeGrey font-xs">
        Add automatically new listings <Switch className="ml-1" />
      </div>
      <div className="my-5 xs:grid xs:grid-cols-1 md:grid-cols-2 gap-8 lg:flex lg:items-center lg:justify-between xs:w-[100%] lg:w-[70%]">
        <div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-6">
          <Input
            placeholder="Type to search listings"
            allowClear
            style={{
              width: 280,
            }}
            className="rounded-none"
          />
          <Select
            placeholder="Select Tags"
            allowClear
            style={{
              width: 280,
            }}
            className="rounded-none"
          />
        </div>
        <GenericButton
          label="Select All"
          size="middle"
          className="bg-themeSecondaryGreen rounded-none w-20"
        />
      </div>
      <div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-10 xs:w-[100%] lg:w-[70%]">
        <ListingCard cardImage={ApartmentImage} />
        <ListingCard cardImage={RoomImage} />
        <ListingCard cardImage={ApartmentImage} />
        <ListingCard cardImage={ApartmentImage} />
        <ListingCard />
      </div>
      <div className="font-extrabold text-xl mb-5 mt-10 flex flex-col">
        <div className="mb-5">
          Create categories to show listings on your homepage
        </div>
        {addNewCategory && renderAddListingCategories(newCategoryCount)}

        <GenericButton
          label="Add category"
          size="middle"
          className="bg-themeGreen rounded-none w-28"
          onClick={() => {
            setAddNewCategory(true);
            setNewCategoryCount(newCategoryCount + 1);
          }}
        />
      </div>
    </div>
  );
};

export default BookingEngineListings;
