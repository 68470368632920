import { permissionTemplateOptions } from "../../../enum";
import FormInput from "../../Form/FormInput";
import FormSelect from "../../Form/FormSelect";
import FormTextArea from "../../Form/FormTextArea";
import { isAdminAccess, isAssistantAccess, isCleanerAccess, isFinanceAccess, isManagerAccess, isPropertyOwnerAccess } from "../Users/Helper";

export default function AddBasicInfo({ form }) {
    return (<>
        <div className="grid grid-cols-2 gap-2 md:gap-7 mt-2 items-center">
            <FormInput name="name" label="Group name" placeholder="Group name"
                rules={[{ required: true, message: 'Group name is required!' }]} />
            <FormSelect
                label="Permission Template"
                bordered={false}
                name="permissionsTemplates"
                className="w-[40%] border-2 border-creme"
                placeholder="Permission Template"
                optionFilterProp="children"
                options={permissionTemplateOptions}
                onChange={(e) => {
                    if (e === "Admin") {
                        form.setFieldsValue(isAdminAccess)
                    } else if (e === "Manager") {
                        form.setFieldsValue(isManagerAccess)
                    } else if (e === "Assistant") {
                        form.setFieldsValue(isAssistantAccess)
                    } else if (e === "Finance") {
                        form.setFieldsValue(isFinanceAccess)
                    } else if (e === "Property Owner") {
                        form.setFieldsValue(isPropertyOwnerAccess)
                    } else if (e === "Cleaner") {
                        form.setFieldsValue(isCleanerAccess)
                    }
                }}
            />
        </div>
        <div className="mb-10 mt-3">
            <FormTextArea rows={4} name="description" label="Description" placeholder="Description" />
        </div>
    </>
    )
}