import { DatePicker, Input, Select } from "antd";
import React, { useState } from "react";
import GenericButton from "../../components/GenericButton/GenericButton";
import AddIcon from "@mui/icons-material/Add";
import notFoundImage from "../../assets/notFound.svg";
import { downloadFinancialReportOptions } from "../../enum";
import ImportExpensesAndExtrasModal from "../../components/ImportExpensesAndExtrasModal/ImportExpensesAndExtrasModal";
import AntExpenseDrawer from "../../components/AntExpenseDrawer/AntExpenseDrawer";

const FinancialReportingExpensesAndExtras = () => {
  const [isImportExpensesModalOpen, setIsImportExpensesModalOpen] =
    useState(false);
  const [isAddExpenseDrawerOpen, setIsAddExpenseDrawerOpen] = useState(false);

  return (
    <div className="mt-3 my-4">kmkm
      <ImportExpensesAndExtrasModal
        isModalOpen={isImportExpensesModalOpen}
        setIsModalOpen={setIsImportExpensesModalOpen}
      />
      <div className="sm:flex items-center justify-end gap-3">
        <Select
          placeholder="Download report"
          className="bg-selectedGreen xs:w-full sm:w-40 xs:my-2"
          options={downloadFinancialReportOptions}
        />
        <GenericButton
          label="Import"
          className="bg-selectedGreen text-themeGreen rounded-none xs:w-full sm:w-24 xs:my-2"
          onClick={() => {
            setIsImportExpensesModalOpen(true);
          }}
        />
        <GenericButton
          label="Add expense"
          className="bg-themeGreen text-white rounded-none xs:w-full sm:w-36 xs:my-2"
          iconLeftAlign={true}
          icon={<AddIcon />}
          onClick={() => {
            setIsAddExpenseDrawerOpen(true);
          }}
        />
        <AntExpenseDrawer
          isDrawerOpen={isAddExpenseDrawerOpen}
          setIsDrawerOpen={setIsAddExpenseDrawerOpen}
        />
      </div>
      <div className="grid xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-5 xmd:grid-cols-7 lg:grid-cols-9 my-5 gap-3">
        <div>
          <div className="text-themeGrey mb-2">Concept</div>
          <Input className="w-full rounded-none" placeholder="Concept" />
        </div>
        <div>
          <div className="text-themeGrey mb-2">From</div>
          <DatePicker className="w-full rounded-none" placeholder="From" />
        </div>
        <div>
          <div className="text-themeGrey mb-2">To</div>
          <DatePicker className="w-full rounded-none" placeholder="To" />
        </div>
        <div>
          <div className="text-themeGrey mb-2">Categories</div>
          <Select className="w-full rounded-none" placeholder="Categories" />
        </div>
        <div>
          <div className="text-themeGrey mb-2">Listings</div>
          <Select className="w-full rounded-none" placeholder="Listings" />
        </div>
        <div>
          <div className="text-themeGrey mb-2">Reservations</div>
          <Select className="w-full rounded-none" placeholder="Reservations" />
        </div>
        <div>
          <div className="text-themeGrey mb-2">Owners</div>
          <Select className="w-full rounded-none" placeholder="Owners" />
        </div>
        <div>
          <div className="text-themeGrey mb-2">Owner statements</div>
          <Select
            className="w-full rounded-none"
            placeholder="Owner statements"
          />
        </div>
        <div className="xs:col-span-2 sm:col-span-1">
          <div className="text-white mb-2">1</div>
          <GenericButton
            label="Reset"
            className="text-themeGreen bg-selectedGreen rounded-none w-full"
          />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center gap-5 mt-10">
        <img src={notFoundImage} alt="" />
        <div className="font-extrabold text-2xl text-black">
          No expenses or extras found
        </div>
      </div>
    </div>
  );
};

export default FinancialReportingExpensesAndExtras;
