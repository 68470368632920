/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import "./Groups.css";
import GenericButton from "../../../components/GenericButton/GenericButton";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { Table } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { USER_MANAGEMENT_GROUPS } from "../../../constants/dashboardRoutes";
import DeleteAPIKeyModal from "../../../components/DeleteAPIKeyModal/DeleteAPIKeyModal";
import { apiInstance } from "../../../utils/API";
import Loading from "../../../components/Loaders/Loading";
import NoAccess from "../../../components/NoAccess";
import { AppContext } from "../../../AppContext";

export default function GroupTable() {
  const navigate = useNavigate();
  const profile = useContext(AppContext).profile || {}
  const hasAccess = profile.isAdmin
  const [groups, setGroups] = useState([])
  const [loading, setLoading] = useState(true)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [groupId, setGroupId] = useState("")

  useEffect(() => {
    if (hasAccess)
      fetchGroups();
  }, []);

  async function fetchGroups() {
    setLoading(true);
    try {
      setLoading(true);
      let url = `/api/group-rights`;
      const list = await apiInstance.get(url);
      setGroups(list.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const deleteGroup = async (groupId) => {
    try {
      await apiInstance.delete(`/api/group-rights/${groupId}`).then((response) => {
        if (response?.status === 200) {
          setIsDeleteModalOpen(false)
          fetchGroups()
        }
      });
    } catch (error) {
      console.log(error)
    }
  };

  const columns = [
    {
      title: (
        <div className="text-themeGrey font-normal text-sm whitespace-pre">Group Name</div>
      ),
      key: "name",
      width: "10%",
      render: (_, record) => {
        return (<Link to={`${USER_MANAGEMENT_GROUPS}/${record._id}/details`}>{record.name}</Link>
        )
      }
    },
    {
      title: <div className="text-themeGrey font-normal text-sm"></div>,
      key: "action",
      render: (_, record) => (
        <div className="flex items-center justify-end gap-8">
          <GenericButton
            label="Edit"
            className="bg-labelGreen text-themeGreen"
            iconLeftAlign={true}
            icon={<BorderColorIcon style={{ fontSize: 12 }} />}
            onClick={() => {
              navigate(`${USER_MANAGEMENT_GROUPS}/${record._id}`);
            }}
          />
          <GenericButton
            onClick={() => {
              setGroupId(record._id)
              setIsDeleteModalOpen(true)
            }}

            label="Delete group"
            className="bg-red-100 text-red-500"
            iconLeftAlign={true}
            icon={<DeleteIcon style={{ fontSize: 12 }} />}
          />
        </div>
      ),
    },
  ];

  if (profile?._id && !hasAccess) {
    return <NoAccess />
  }
  return (<>
    <DeleteAPIKeyModal
      onOk={() => deleteGroup(groupId)}
      isModalOpen={isDeleteModalOpen}
      setIsModalOpen={setIsDeleteModalOpen}
    />

    <div id="groups-table" className="bg-white py-5">
      <div className="flex justify-end mb-5 px-4">
        <GenericButton
          label={<p>Add new</p>}
          className="text-white bg-themeGreen rounded-none"
          size="middle"
          iconLeftAlign={true}
          icon={
            <PersonAddIcon style={{ fontSize: 16 }} className="mr-1 mb-[2px]" />
          }
          onClick={() => {
            navigate(`${USER_MANAGEMENT_GROUPS}/add`);
          }}
        />
      </div>
      {loading ? (<div className="h-[50vh] bg-white rounded-lg flex justify-center items-center"><Loading /></div>) : groups && groups.length > 0 ? (
        <Table
          className="border-t border-creme"
          columns={columns}
          dataSource={groups}
          scroll={{
            x: 1000,
          }}
        />
      ) : (
        <div className="text-center capitalize">No group found</div>
      )}
    </div>
  </>
  );
}
