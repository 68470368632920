import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import { Form, InputNumber, Tooltip } from "antd";
import Input from "antd/es/input/Input";
import React from "react";
import HelpIcon from "@mui/icons-material/Help";

export default function FormInput({
  name,
  type = "text",
  rules = [],
  placeholder,
  className = "",
  disabled = false,
  formatter,
  parser,
  controls = true,
  icon,
  label,
  labelClassName,
  onChange,
  tooltipTitle,
  toolTipPlacement = "top",
  toolTipFlag = false,
  addonBefore,
  ref,
  style,
  suffix,
  prefix,
  required
}) {
  const isRequired = required || rules?.find(({ required }) => required === true);
  return (
    <div className="custom-form-input">
      <div className="flex items-center">
        {label && (
          <label className={`font-bold text-themeGrey capitalize ${labelClassName}`}>
            {label}{" "}
            {isRequired && (
              <span className="text-cancelButton inline-flex items-center">
                *
              </span>
            )}
            {toolTipFlag && (
              <Tooltip
                overlayInnerStyle={{ fontSize: 12 }}
                placement={toolTipPlacement}
                title={tooltipTitle}
              >
                <HelpIcon className="text-sky-600 !text-[17px] ml-1 cursor-pointer" />
              </Tooltip>
            )}
          </label>
        )}
      </div>
      {type === "number" && (
        <Form.Item name={name} rules={rules} className="mb-0">
          <InputNumber
            className={`${className} w-[100%] rounded-md placeholder:capitalize`}
            placeholder={placeholder}
            formatter={formatter}
            parser={parser}
            controls={controls}
            disabled={disabled}
            onChange={onChange}
            suffix={suffix}
            prefix={prefix}
          />
        </Form.Item>
      )}
      {type === "password" && (
        <Form.Item name={name} rules={rules} className="mb-0">
          <Input.Password
            className={`${className} w-[100%] rounded-md placeholder:capitalize`}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
      )}
      {type !== "number" && type !== "password" && (
        <Form.Item name={name} rules={rules} className="mb-0">
          <Input
            addonBefore={addonBefore}
            className={`${className} w-[100%] rounded-md placeholder:capitalize`}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
            ref={ref}
            style={style}
          />
        </Form.Item>
      )}
    </div>
  );
}
