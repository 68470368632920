import React from "react";
import GenericButton from "../GenericButton/GenericButton";
import FormModal from "../FormModal/FormModal";
import { Checkbox } from "@mui/material";
import { HelpCenterRounded } from "@mui/icons-material";

const DeleteAutomationModal = ({
  isModalOpen,
  setIsModalOpen,
  title = null,
  onConfirm = null,
}) => {
  return (
    <FormModal
      title={title}
      setIsModalOpen={setIsModalOpen}
      isModalOpen={isModalOpen}
    >
      <div className="text-themeGrey">
        <div className="pt-[15px] pb-[15px] font-medium">
          You have choosen to DELETE this automation. Do you also want to delete
          its existing scheduled messages?
        </div>
        <div className="flex items-center">
          <Checkbox />
          <div className="font-semibold">
            YES! Please also delete any existing scheduled messages{" "}
            <HelpCenterRounded className="text-blue-500 border rounded cursor-pointer" />
          </div>
        </div>
        <div className="flex gap-3 justify-end pt-10 pb-2">
          <GenericButton
            label="Cancel"
            className="bg-lightButtonHoverTheme text-themeGreen rounded-none border-none"
            onClick={() => setIsModalOpen(false)}
          />
          <GenericButton
            label="Confirm"
            className="bg-red-500 text-white rounded-none border-none"
            onClick={onConfirm}
          />
        </div>
      </div>
    </FormModal>
  );
};

export default DeleteAutomationModal;
