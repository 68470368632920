import React from 'react'
import { Form } from 'antd'
import { useWindowSize } from 'react-use'

import GenericButton from '../../../components/GenericButton/GenericButton'
import Loading from '../../../components/Loaders/Loading'

import { apiInstance, routes } from '../../../utils/API'

import RoiCalculatorForm from './CalculatorForm'
import RoiCalculatorResultTable from './ResultTable'
import Card from './Card'

import { calculateROIValues, createResultTableItems } from './helpers'

const initialValues = {
  purchasePrice: undefined,
  depositPercentage: undefined,
  deposit: undefined,
  refurbishment: undefined,
  stampDuty: undefined,
  legalPrice: undefined,
  bridgingLoanCost: undefined,
  bankFee: undefined,
  otherCosts: undefined,
  titleSplitDealTotalCosts: undefined,
  totalDebt: undefined,
  mortgage: undefined,
  cashLeftInDeal: undefined,
  percentageOfCashOut: undefined,
  interestOnlyMortgage: undefined,
  councilTax: undefined,
  internet: undefined,
  utilityBills: undefined,
  serviceCharge: undefined,
  groundRent: undefined,
  maintenance: undefined,
  operatingModelTotalCosts: undefined,
  netProfits: undefined,
  netProfitMargin: undefined,
  roi: undefined
}

const initialRadius = 0.5

export default function RoiCalculator (props) {
  const { propertiesData } = props

  const [calculatedValues, setCalculatedValues] = React.useState(initialValues)
  const [loading, setLoading] = React.useState(false)
  const [properties, setProperties] = React.useState([])
  const [operationSucceeded, setOperationSucceeded] = React.useState(false)
  const [operationError, setOperationError] = React.useState(false)
  const [radius, setRadius] = React.useState(initialRadius)
  const [showLoadMoreButton, setShowLoadMoreButton] = React.useState(false)
  const [showResultTable, setShowResultTable] = React.useState(false)

  const [form] = Form.useForm()
  const purchasePrice = Form.useWatch('purchasePrice', form)
  const depositPercentage = Form.useWatch('depositPercentage', form)
  const refurbishment = Form.useWatch('refurbishment', form)
  const legalPrice = Form.useWatch('legalPrice', form)
  const bridgingLoanIr = Form.useWatch('bridgingLoanIr', form)
  const bridgingDuration = Form.useWatch('bridgingDuration', form)
  const bankFee = Form.useWatch('bankFee', form)
  const otherCosts = Form.useWatch('otherCosts', form)
  const revaluationPrice = Form.useWatch('revaluationPrice', form)
  const expectedNetRevenues = Form.useWatch('expectedNetRevenues', form)
  const mortgageIr = Form.useWatch('mortgageIr', form)
  const ltv = Form.useWatch('ltv', form)
  const internetCost = Form.useWatch('internetCost', form)
  const internetUseMonday = Form.useWatch('internetUseMonday', form)
  const utilityBills = Form.useWatch('utilityBills', form)
  const utilityBillsUseMonday = Form.useWatch('utilityBillsUseMonday', form)
  const councilTax = Form.useWatch('councilTax', form)
  const councilTaxUseMonday = Form.useWatch('councilTaxUseMonday', form)
  const serviceCharge = Form.useWatch('serviceCharge', form)
  const groundRent = Form.useWatch('groundRent', form)
  const maintenance = Form.useWatch('maintenance', form)
  const postalCode = Form.useWatch('postalCode', form)
  const numberOfBedrooms = Form.useWatch('numberOfBedrooms', form)

  const { width } = useWindowSize()

  const formValues = {
    purchasePrice, depositPercentage, refurbishment, legalPrice, maintenance,
    serviceCharge, groundRent, postalCode, numberOfBedrooms, bridgingLoanIr,
    bridgingDuration, bankFee, otherCosts, revaluationPrice, expectedNetRevenues,
    mortgageIr, ltv, internetCost, internetUseMonday, utilityBills,
    utilityBillsUseMonday, councilTax, councilTaxUseMonday
  }

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true)
      let url = `${routes.PROPERTIES_BY_POSTAL_CODE}?`
      url += `postalCode=${values.postalCode}&`
      url += `radiusInMiles=${radius}`

      const response = await apiInstance.get(url)
      const nextProperties = response?.data?.properties?.length
        ? response.data.properties.filter(item => {
          const nbBedrooms = values.numberOfBedrooms.toString()

          return item?.basicInfo?.internal_listing_name?.startsWith(nbBedrooms)
        })
        : []

      if (!propertiesData?.length) {
        setLoading(false)
        setOperationSucceeded(true)
        setShowLoadMoreButton(false)
        return
      }

      const foundProperties = []
      const foundPropertiesNames = []
      nextProperties.forEach(prop => {
        const property = propertiesData.find((item) => (
          item?.name === prop?.basicInfo?.internal_listing_name
        ))

        if (property) {
          foundProperties.push(property)
          foundPropertiesNames.push(property.name)
        }
      })

      if (!foundProperties.length) {
        setLoading(false)
        setOperationSucceeded(true)
        setOperationError(false)
        setProperties([])


        if (values.internetUseMonday || values.councilTaxUseMonday ||
        values.utilityBillsUseMonday) {
          setShowLoadMoreButton(true)
          setShowResultTable(false)
          setRadius(prev => prev + 12.5)
          return
        }

        if (!values.internetUseMonday && !values.councilTaxUseMonday &&
          !values.utilityBillsUseMonday) {
          if (radius === initialRadius) {
            setRadius(prev => prev + 12.5)
            setShowLoadMoreButton(true)
            setShowResultTable(false)
          } else {
            setShowResultTable(true)
            setShowLoadMoreButton(false)
          }
          return
        }

        return
      }

      const nextValues = calculateROIValues(values)

      const targetProperties = nextProperties.filter(property => {
        return foundPropertiesNames.includes(property?.basicInfo?.internal_listing_name)
      })

      setCalculatedValues(nextValues)
      setProperties(targetProperties)
      setOperationSucceeded(true)
      setOperationError(false)
      setLoading(false)
      setRadius(initialRadius)
      setShowResultTable(false)
      setShowLoadMoreButton(false)
    } catch (error) {
      setLoading(false)
      setOperationSucceeded(false)
      setOperationError(true)
      setShowResultTable(false)
    }
  }

  const handleFormReset = () => {
    form.resetFields()
    setCalculatedValues(initialValues)
    setRadius(initialRadius)
    setOperationSucceeded(false)
    setOperationError(false)
  }

  const resultItems = React.useMemo(
    () => createResultTableItems(calculatedValues),
    [calculatedValues])

  const containerClassName = React.useMemo(() => {
    let className = 'flex gap-7 mt-7 w-full min-w-[100%]'

    if (width <= 712) {
      className += ' flex-col'
    }

    return className
  }, [width])

  const resultTableClassName = React.useMemo(() => {
    let className = 'w-full max-h-[640px] pb-4 min-w-[44%]'

    if (width >= 712) {
      className += ' w-[37%] !min-w-[37%] sm:sticky sm:top-[50px]'
    }

    return className
  }, [width])

  const cardsContainerClassName = React.useMemo(() => {
    let className = 'w-full min-w-[50%]'

    if (width >= 712) {
      className += ' vlg:min-w-[61%]'
    }

    return className
  }, [width])

  const cardsClassName = React.useMemo(() => {
    let className = `grid justify-center grid-cols-1 gap-7`

    if (width <= 705 && width >= 630) {
      className += ' grid-cols-2'
    }

    if (width >= 1320) {
      className += ' grid-cols-2'
    }

    return className
  }, [width])

  return (
    <div className="min-h-[350px]">
      <RoiCalculatorForm
        handleFormSubmit={handleFormSubmit}
        handleFormReset={handleFormReset}
        form={form}
      />

      {loading
        ? (
          <div className={`bg-white rounded-lg flex justify-center h-[20px] 
            items-center`}>
            <Loading />
          </div>
        )
        : (
          <>
            {operationSucceeded && (
              <>
                {!!properties?.length && (
                  <>
                    <div className={containerClassName}>
                      <div className={resultTableClassName}>
                        <RoiCalculatorResultTable items={resultItems} />
                      </div>

                      <div className={cardsContainerClassName}>
                        <div className={cardsClassName}>
                          {properties.map((card, index) => (
                            <Card {...card} key={index}/>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {showLoadMoreButton && (
                  <div className="flex justify-between mt-5">
                    <div className="text-md font-bold text-themeGrey">
                      No properties found...
                    </div>

                    <GenericButton
                      onClick={() => handleFormSubmit(formValues)}
                      className={`disabled:bg-themeGrey disabled:bg-opacity-20 
                          disabled:hover:text-white disabled:!text-white 
                          hover:text-white hover:!bg-buttonHoverTheme shadow-0 
                          disabled:hover:!bg-opacity-20 bg-buttonTheme`}
                      label="Search nearest properties"
                    />
                  </div>
                )}

                {showResultTable && (
                  <div className={resultTableClassName}>
                    <RoiCalculatorResultTable items={resultItems}/>
                  </div>
                )}
              </>
            )}

            {operationError && (
              <div className={`text-md font-bold text-themeGrey text-center 
                mt-5`}>
                Invalid zip code
              </div>
            )}
          </>
        )}
    </div>
  )
}
