import React from "react";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import GenericButton from "../../components/GenericButton/GenericButton";
import { REVIEWS_REVIEW_TEMPLATES } from "../../constants/dashboardRoutes";
import { Help } from "@mui/icons-material";
import { Input, Rate, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";

const ReviewTemplatesAdd = () => {
  return (
    <div className="p-5">
      <div className="flex items-center justify-between text-themeGreen pb-5 border-b-2 border-creme">
        <Link to={REVIEWS_REVIEW_TEMPLATES}>
          <ArrowBackIosIcon /> Back to review templates
        </Link>
        <GenericButton
          className="text-white bg-themeGreen rounded-none"
          label="Save"
        />
      </div>
      <div className="py-5">
        <div className="font-bold text-xl">
          Review template{" "}
          <Help style={{ fontSize: 16 }} className="text-labelBlue" />
        </div>
        <div className="flex items-center gap-3 py-5">
          <Switch /> <div className="text-themeGrey">Recommend the guest</div>
        </div>
        <div className="mb-5">
          <div className="text-themeGrey mb-1">Name</div>
          <Input placeholder="Enter name" className="w-[50%]" />
        </div>
        <div className="mb-5">
          <div className="text-themeGrey mb-1">Message</div>
          <TextArea placeholder="Enter name" rows={5} />
        </div>
        <div className="my-5">
          <div className="text-themeGrey mb-1">Cleanliness rating</div>
          <Rate defaultValue={4} />
        </div>
        <div className="my-5">
          <div className="text-themeGrey mb-1">Communication rating</div>
          <Rate defaultValue={4} />
        </div>
        <div className="my-5">
          <div className="text-themeGrey mb-1">
            Observance of house rules rating
          </div>
          <Rate defaultValue={4} />
        </div>
        <div className="mb-5">
          <div className="text-themeGrey mb-1">Private note</div>
          <TextArea placeholder="Enter private note" rows={5} />
        </div>
      </div>
    </div>
  );
};

export default ReviewTemplatesAdd;
