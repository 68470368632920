import React, { useState } from "react";
import { Help, SearchOutlined } from "@mui/icons-material";
import { Avatar, Dropdown, Input, Select, Table, Tag } from "antd";
import GenericButton from "../../components/GenericButton/GenericButton";
import DownloadIcon from "@mui/icons-material/Download";
import AirBNBLogo from "../../assets/airbnb.png";
import w from "../../assets/W.jpeg";
import bookingLogo from "../../assets/booking.png";
import expediaLogo from "../../assets/expedia.png";
import vrboLogo from "../../assets/vrbo.svg";
import villasLogo from "../../assets/homevillas.png";
import houseLogo from "../../assets/house.jpeg";
import tripAdvisorLogo from "../../assets/tripAdvisor.png";
import PendingIcon from "@mui/icons-material/Pending";
import { Link } from "react-router-dom";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ChannelManagerSettingsModal from "../../components/ChannelManagerSettingsModal/ChannelManagerSettingsModal";
import ChannelManagerEditModal from "../../components/ChannelManagerEditModal/ChannelManageEditModal";

const ChannelManagerListingMapping = () => {
  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const items = [
    {
      key: "1",
      label: <a>Link child listings</a>,
    },
    {
      key: "2",
      label: <a>Delete listing</a>,
    },
    {
      key: "3",
      label: <a>Duplicate listing</a>,
    },
    {
      key: "4",
      label: <a>CSV export</a>,
    },
    {
      key: "5",
      label: <a>iCal export</a>,
    },
    {
      key: "6",
      label: <a>Custom iCals</a>,
    },
    {
      key: "7",
      label: <a>Add or remove units</a>,
    },
  ];

  const columns = [
    {
      title: (
        <div className="text-themeGrey font-normal text-sm">
          Listing name (internal)
          <Help style={{ fontSize: 16 }} className="text-labelBlue ml-1" />
        </div>
      ),
      dataIndex: "listingName",
      key: "listingName",
      width: "27%",
    },
    {
      title: <div className="text-themeGrey font-normal text-sm">Settings</div>,
      dataIndex: "settings",
      key: "settings",
    },
    {
      title: <img src={AirBNBLogo} className="w-9" />,
      dataIndex: "airbnb",
      key: "airbnb",
    },
    {
      title: <img src={w} className="w-9" />,
      key: "w",
      dataIndex: "w",
    },
    {
      title: <img src={bookingLogo} className="w-9" />,
      key: "booking",
      dataIndex: "booking",
    },
    {
      title: <img src={expediaLogo} className="w-9" />,
      key: "expedia",
      dataIndex: "expedia",
    },
    {
      title: <img src={vrboLogo} className="w-9" />,
      key: "vrbo",
      dataIndex: "vrbo",
    },
    {
      title: <img src={villasLogo} className="w-9" />,
      key: "villas",
      dataIndex: "villas",
    },
    {
      title: <img src={houseLogo} className="w-9" />,
      key: "house",
      dataIndex: "house",
    },
    {
      title: <img src={tripAdvisorLogo} className="w-9" />,
      key: "tripAdvisor",
      dataIndex: "tripAdvisor",
    },
    {
      title: <img src={tripAdvisorLogo} className="w-9" />,
      key: "tripAdvisor2",
      dataIndex: "tripAdvisor2",
    },
    {
      title: <img src={vrboLogo} className="w-9" />,
      key: "vrbo2",
      dataIndex: "vrbo2",
    },
    {
      title: <Help style={{ fontSize: 20 }} className="text-labelBlue" />,
      key: "action",
      align: "right",
      render: (_, record) => {
        return (
          <Dropdown
            menu={{
              items,
            }}
            placement="bottomRight"
            arrow={{
              pointAtCenter: true,
            }}
          >
            <PendingIcon className="text-themeGrey" />
          </Dropdown>
        );
      },
    },
  ];

  // Sample data.
  const data = [
    {
      key: "1",
      listingName: (
        <div>
          <Avatar size="small" className="mr-1" />
          <Link className="text-themeGreen">
            3B SW15 - The Putney Apartment (155609)
          </Link>
          <BorderColorIcon
            style={{ fontSize: 18 }}
            className="text-themeGrey ml-1"
            onClick={() => {
              setIsEditModalOpen(true);
            }}
          />
        </div>
      ),
      settings: (
        <Tag
          color="purple"
          className="bg-purple-700 text-white"
          onClick={() => {
            setIsSettingModalOpen(true);
          }}
        >
          Settings
        </Tag>
      ),
      airbnb: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      w: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      booking: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      expedia: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      vrbo: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      villas: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      house: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      tripAdvisor: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      tripAdvisor2: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      vrbo2: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
    },
    {
      key: "2",
      listingName: (
        <div>
          <Avatar size="small" className="mr-1" />
          <Link className="text-themeGreen">
            3B SW15 - The Putney Apartment (155609)
          </Link>
          <BorderColorIcon
            style={{ fontSize: 18 }}
            className="text-themeGrey ml-1"
            onClick={() => {
              setIsEditModalOpen(true);
            }}
          />
        </div>
      ),
      settings: (
        <Tag
          color="purple"
          className="bg-purple-700 text-white"
          onClick={() => {
            setIsSettingModalOpen(true);
          }}
        >
          Settings
        </Tag>
      ),
      airbnb: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      w: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      booking: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      expedia: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      vrbo: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      villas: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      house: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      tripAdvisor: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      tripAdvisor2: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      vrbo2: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
    },
    {
      key: "3",
      listingName: (
        <div>
          <Avatar size="small" className="mr-1" />
          <Link className="text-themeGreen">
            3B SW15 - The Putney Apartment (155609)
          </Link>
          <BorderColorIcon
            style={{ fontSize: 18 }}
            className="text-themeGrey ml-1"
            onClick={() => {
              setIsEditModalOpen(true);
            }}
          />
        </div>
      ),
      settings: (
        <Tag
          color="purple"
          className="bg-purple-700 text-white"
          onClick={() => {
            setIsSettingModalOpen(true);
          }}
        >
          Settings
        </Tag>
      ),
      airbnb: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      w: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      booking: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      expedia: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      vrbo: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      villas: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      house: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      tripAdvisor: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      tripAdvisor2: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      vrbo2: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
    },
    {
      key: "4",
      listingName: (
        <div>
          <Avatar size="small" className="mr-1" />
          <Link className="text-themeGreen">
            3B SW15 - The Putney Apartment (155609)
          </Link>
          <BorderColorIcon
            style={{ fontSize: 18 }}
            className="text-themeGrey ml-1"
            onClick={() => {
              setIsEditModalOpen(true);
            }}
          />
        </div>
      ),
      settings: (
        <Tag
          color="purple"
          className="bg-purple-700 text-white"
          onClick={() => {
            setIsSettingModalOpen(true);
          }}
        >
          Settings
        </Tag>
      ),
      airbnb: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      w: (
        <GenericButton
          label="ON"
          className="bg-themeGreen text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      booking: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      expedia: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      vrbo: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      villas: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      house: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      tripAdvisor: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      tripAdvisor2: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      vrbo2: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
    },
    {
      key: "5",
      listingName: (
        <div>
          <Avatar size="small" className="mr-1" />
          <Link className="text-themeGreen">
            3B SW15 - The Putney Apartment (155609)
          </Link>
          <BorderColorIcon
            style={{ fontSize: 18 }}
            className="text-themeGrey ml-1"
            onClick={() => {
              setIsEditModalOpen(true);
            }}
          />
        </div>
      ),
      settings: (
        <Tag
          color="purple"
          className="bg-purple-700 text-white"
          onClick={() => {
            setIsSettingModalOpen(true);
          }}
        >
          Settings
        </Tag>
      ),
      airbnb: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      w: (
        <GenericButton
          label="ON"
          className="bg-themeGreen text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      booking: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      expedia: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      vrbo: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      villas: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      house: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      tripAdvisor: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      tripAdvisor2: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
      vrbo2: (
        <GenericButton
          label="OFF"
          className="bg-red-700 text-white rounded-none p-0 m-0"
          size="small"
        />
      ),
    },
  ];

  const handleRowClick = (record) => {};

  return (
    <div className="my-5">
      <ChannelManagerEditModal
        isModalOpen={isEditModalOpen}
        setIsModalOpen={setIsEditModalOpen}
      />
      <ChannelManagerSettingsModal
        isModalOpen={isSettingModalOpen}
        setIsModalOpen={setIsSettingModalOpen}
      />
      <div className="grid xs:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 vlg:grid-cols-6 gap-2 mb-12">
        <Input
          placeholder="Type to search listings"
          className="bg-themeGreen w-full"
          addonAfter={
            <SearchOutlined className="flex items-center justify-center text-white" />
          }
        />
        <Select placeholder="Tags" className="w-full rounded-none" />
        <Select
          placeholder="Filter by country"
          className="w-full rounded-none"
        />
        <Select placeholder="Filter by city" className="w-full rounded-none" />
        <Select placeholder="Sort by" className="w-full rounded-none" />
        <div className="flex items-center gap-2">
          <Select placeholder="20" className="w-full rounded-none" />
          <GenericButton
            label="CSV"
            className="bg-themeGreen text-white rounded-none"
            icon={
              <DownloadIcon className="text-white" style={{ fontSize: 18 }} />
            }
          />
        </div>
      </div>
      <div>
        <Table
          className="border-t border-creme"
          columns={columns}
          dataSource={data}
          scroll={{
            x: 1000,
            y: 500,
          }}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
            className: "cursor-pointer",
          })}
        />
      </div>
    </div>
  );
};

export default ChannelManagerListingMapping;
