import { message } from "antd";

// This function returns true or false based on the screen size.
export const isMobileScreen = () => window.innerWidth < 600;

// This function handles the opening and closing of sidebar.
export const shouldSidebarOpen = () => {
  const isMobile = isMobileScreen();

  if (isMobile) {
    return false;
  }
  return true;
};

// This function sort items based on date.
export const sortByDate = (a, b) => {
  return new Date(b.createdAt) - new Date(a.createdAt);
};

export const getLabel = (value, obj) => {
  for (const [key, value] of Object.entries(obj)) {
    if (key === value?.toUpperCase()) {
      return value;
    }
  }
  return value;
};

// This function returns the label for a key in an array of object,
export const getLabelForKey = (arr, key) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].key === key) {
      return arr[i].label;
    }
  }
  return null;
};

export const getItem = (label, key, icon, children) => {
  return {
    key,
    icon,
    children,
    label,
  };
};

export const notificationMessage = (messageType, messageContent) => {
  return message.open({
    type: messageType,
    content: messageContent,
  });
};

export const getKeyByLabel = (label, array) => {
  if (label === "Reservation custom field") {
    const count = array.filter((item) => item.label === label).length;
    return `reservationCustomField_${count}`;
  }

  const item = array.find((item) => item.label === label);
  return item ? item.key : null;
};

export const getConditionObjectByType = (conditions, conditionType) => {
  return (
    conditions.find((condition) => condition.conditionType === conditionType) ||
    null
  );
};

export const getConditionObjectByKey = (conditions, key) => {
  return conditions.find((condition) => condition.key === key) || null;
};

export const numberWithCommas = (x) => {
  const roundedNumber = Number(x).toFixed(2);
  const numberWithCommas = roundedNumber
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return numberWithCommas;
};

