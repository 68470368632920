import { Form, Tooltip } from "antd";
import React from "react";
import HelpIcon from "@mui/icons-material/Help";
import TextArea from "antd/es/input/TextArea";

export default function FormTextArea({
  name,
  type = "text",
  rules = [],
  placeholder,
  className = "",
  disabled = false,
  icon,
  label,
  onChange,
  tooltipTitle,
  toolTipPlacement = "top",
  toolTipFlag = false,
  rows = 4
}) {

  const isRequired = rules?.find(({ required }) => required === true);
  return <div className="custom-form-input">
    <div className="flex items-center ">
      {label && <label className="font-bold text-themeGrey capitalize">{label} {isRequired && <span className="text-cancelButton inline-flex items-center" >*</span>}
        {toolTipFlag && (
          <Tooltip placement={toolTipPlacement} title={tooltipTitle}>
            <HelpIcon className="text-sky-600 !text-[17px] ml-1 cursor-pointer" />
          </Tooltip>
        )}
      </label>}
    </div>
    <Form.Item name={name} rules={rules} className="mb-0" >
      <TextArea rows={rows} className={`${className} w-[100%] rounded-md placeholder:capitalize`} placeholder={placeholder}
        disabled={disabled} onChange={onChange}
      />
    </Form.Item>
  </div>
}