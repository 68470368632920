import { useState } from "react";
import {
  Button,
  Input,
  Space,
  DatePicker,
  Select,
  Checkbox,
  Divider,
  Rate,
} from "antd";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import AttachmentModal from "./AttachmentModal";

function AddTask({ setShowAddTasks }) {
  const [openModal, setOpenModal] = useState(false);
  const [checkList, setChecklist] = useState([]);

  const { TextArea } = Input;

  return (
    <div className="bg-white">
      <Button
        className="text-buttonTheme font-semibold mt-5 hover:!bg-transparent hover:!text-buttonHoverTheme"
        type="text"
        onClick={() => setShowAddTasks(false)}
        icon={<KeyboardArrowLeftIcon className="mb-1" />}
      >
        Back to Tasks
      </Button>
      <div className="flex justify-between p-6 w-full">
        <Input
          placeholder="Task Title"
          className="w-[250px] h-[40px] max-[640px]:w-[200px]"
        />
        <Button
          type="primary"
          className="bg-buttonTheme font-semibold hover:!bg-buttonHoverTheme"
        >
          Save task
        </Button>
      </div>
      <div className="flex flex-wrap">
        <div className="flex flex-col p-6 w-[65%] max-[640px]:w-full max-[640px]:justify-center">
          <p className="font-bold text-xl">Schedule</p>
          <Space className="flex justify-between mt-5 flex-wrap max-[640px]:justify-center">
            <div className="flex flex-col">
              <p>Start Date (Local Time)</p>
              <DatePicker
                placeholder="Start Date (Local Time)"
                className="w-56"
              />
            </div>
            <div className="flex flex-col">
              <p>Start Time (Local Time)</p>
              <Select
                placeholder="Start Time (Local Time)"
                className="w-56"
                allowClear
                optionFilterProp="children"
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                  { value: "tom", label: "Tom" },
                ]}
              />
            </div>
            <div className="flex flex-col">
              <p>Start Date (Local Time)</p>
              <DatePicker
                placeholder="Start Date (Local Time)"
                className="w-56"
              />
            </div>
            <div className="flex flex-col">
              <p>Start Time (Local Time)</p>
              <Select
                placeholder="Start Time (Local Time)"
                className="w-56"
                allowClear
                optionFilterProp="children"
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                  { value: "tom", label: "Tom" },
                ]}
              />
            </div>
          </Space>
          <p className="font-bold text-xl mt-5">Details</p>
          <p className="mt-5">Description</p>
          <TextArea rows={2} />
          <p className="font-bold text-xl mt-5">Link</p>
          <p className="mt-5">Category</p>
          <Select
            placeholder="Category"
            className="w-[45%] max-[640px]:w-full"
            allowClear
            optionFilterProp="children"
            options={[
              { value: "jack", label: "Jack" },
              { value: "lucy", label: "Lucy" },
              { value: "tom", label: "Tom" },
            ]}
          />
          <div className="flex justify-between flex-wrap mt-5">
            <div className="flex flex-col">
              <p>Listing</p>
              <Select
                placeholder="Listing"
                className="w-[470px] max-[640px]:w-full"
                allowClear
                optionFilterProp="children"
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                  { value: "tom", label: "Tom" },
                ]}
              />
            </div>
            <div className="flex flex-col">
              <p>Channel</p>
              <Select
                placeholder="Channel"
                className="w-[470px] max-[640px]:w-full"
                allowClear
                optionFilterProp="children"
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                  { value: "tom", label: "Tom" },
                ]}
              />
            </div>
          </div>
          <p className="mt-5">Reservation</p>
          <Select
            placeholder="Reservation"
            className="w-full"
            allowClear
            optionFilterProp="children"
            options={[
              { value: "jack", label: "Jack" },
              { value: "lucy", label: "Lucy" },
              { value: "tom", label: "Tom" },
            ]}
          />
          <p className="font-bold text-xl mt-5">Task Checklist</p>
          <div className="grid grid-cols-1 w-full h-auto bg-themeWhite mt-5 p-3">
            {checkList.map((item, index) => (
              <div key={index} className="w-full">
                {item}
              </div>
            ))}
            <Button
              type="primary"
              className="bg-buttonTheme justify-self-end w-20 font-semibold m-2 hover:!bg-buttonHoverTheme"
              onClick={() =>
                setChecklist((prev) => [
                  ...prev,
                  // eslint-disable-next-line react/jsx-key
                  <Input className="w-full mt-2" />,
                ])
              }
            >
              Add
            </Button>
          </div>
          <div className="flex">
            <p className="font-bold text-xl mt-5">Attachments</p>
            <Button
              className="text-buttonTheme font-semibold mt-5 hover:!bg-transparent hover:!text-buttonHoverTheme"
              type="text"
              onClick={() => setOpenModal(true)}
            >
              ADD ATTACHMENT
            </Button>
          </div>
          <p className="font-bold text-xl mt-5">Custom Fields</p>
          <div className="w-full bg-themeWhite h-[30px] mt-5 flex items-center p-2">
            <p>No custom field added</p>
          </div>
        </div>
        <div className="w-[35%] p-6 max-[640px]:justify-center bg-themeWhite max-[640px]:w-full">
          <p className="font-bold text-xl">Status</p>
          <Select
            placeholder="Status"
            className="w-full mt-5"
            allowClear
            optionFilterProp="children"
            options={[
              { value: "jack", label: "Jack" },
              { value: "lucy", label: "Lucy" },
              { value: "tom", label: "Tom" },
            ]}
          />
          <p className="font-bold text-xl mt-5">Users</p>
          <p className="mt-5">Assignee</p>
          <Select
            placeholder="Assignee User"
            className="w-full"
            allowClear
            optionFilterProp="children"
            options={[
              { value: "jack", label: "Jack" },
              { value: "lucy", label: "Lucy" },
              { value: "tom", label: "Tom" },
            ]}
          />
          <p className="mt-5">Supervisor</p>
          <Select
            placeholder="Supervisor User"
            className="w-full"
            allowClear
            optionFilterProp="children"
            options={[
              { value: "jack", label: "Jack" },
              { value: "lucy", label: "Lucy" },
              { value: "tom", label: "Tom" },
            ]}
          />
          <p className="mt-5">Group</p>
          <Select
            placeholder="User Group"
            className="w-full"
            allowClear
            optionFilterProp="children"
            options={[
              { value: "jack", label: "Jack" },
              { value: "lucy", label: "Lucy" },
              { value: "tom", label: "Tom" },
            ]}
          />
          <p className="font-bold text-xl mt-5">Cost</p>
          <div className="flex justify-between max-[640]:flex-col mt-5 flex-wrap">
            <div className="flex flex-col">
              <p>Cost</p>
              <Input className="w-60" />
            </div>
            <div className="flex flex-col">
              <p>Cost Currency</p>
              <Select
                placeholder="Cost Currency"
                className="w-60"
                allowClear
                optionFilterProp="children"
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                  { value: "tom", label: "Tom" },
                ]}
              />
            </div>
          </div>
          <p className="mt-5">Cost Description</p>
          <TextArea rows={2} placeholder="Cost Description" />
          <Checkbox className="mt-5">Automatically generate expense</Checkbox>
          <Divider />
          <p className="font-bold text-xl mt-5">Resolution</p>
          <p className="mt-5">Feedback Score</p>
          <Rate />
          <p className="mt-5">Resolution Note</p>
          <TextArea rows={2} placeholder="Resolution Note" />
          <p className="mt-5">Feedback Note</p>
          <TextArea rows={2} placeholder="Feedback Note" />
        </div>
      </div>
      <AttachmentModal openModal={openModal} setOpenModal={setOpenModal} />
    </div>
  );
}

export default AddTask;
