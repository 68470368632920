import React from 'react'
import { isEmpty } from 'lodash'

import Loading from '../../../components/Loaders/Loading'

import RevenuesChart from './RevenuesChart'
import DirectBookingReferalsChart from './DirectBookingReferalsChart'
import BookingSourcesChart from './BookingSourcesChart'

export default function RevenueAndBookingSourceAnalysis (props) {
  const {
    revenuesChartData = {},
    directBookingReferalsChartData = {},
    bookingSourcesChartData = {},
  } = props

  const [chartJsComp, setChartJsComp] = React.useState()

  React.useEffect(() => {
    const importComponent = async () => {
      const module = await import('react-chartjs-2')
      await import('chart.js/auto')

      setChartJsComp(module)
    }

    importComponent()
  }, [])

  if (!chartJsComp) {
    return (
      <div className="h-[50vh] bg-white rounded-lg flex justify-center
        items-center">
        <Loading/>
      </div>
    )
  }

  const { Chart } = chartJsComp

  const renderTitle = title => (
    <div className="font-bold text-themeGrey text-lg pl-2 pb-2
      border-b border-[#bebfbf]">
      {title}
    </div>
  )

  const chartBoxClassNames = `w-full border-[#bebfbf] border-[1px] 
    rounded-lg py-2 shadow-card`
  const chartClassNames = `w-full h-[20rem] sm:h-[15rem]
    flex justify-center items-center sm:m-auto`

  const noDataPlaceholder = (
    <div className="flex items-center justify-center h-[90%] min-h-[200px] text-lg
      text-themeGrey font-bold">
      No data here
    </div>
  )

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-5">
        <div className={chartBoxClassNames}>
          {renderTitle('Revenues')}

          {isEmpty(revenuesChartData?.labels) || isEmpty(revenuesChartData?.datasets)
            ? noDataPlaceholder
            : (
              <RevenuesChart
                chartClassNames={chartClassNames}
                data={revenuesChartData}
                chartComponent={Chart}
              />
            )}
        </div>

        <div className={chartBoxClassNames}>
          {renderTitle('Direct Booking Referals')}

          {isEmpty(directBookingReferalsChartData?.labels) ||
          isEmpty(directBookingReferalsChartData?.datasets)
            ? noDataPlaceholder
            : (
              <DirectBookingReferalsChart
                chartClassNames={chartClassNames}
                data={directBookingReferalsChartData}
                chartComponent={Chart}
              />
            )}
        </div>
      </div>

      <div className={chartBoxClassNames}>
        {renderTitle('Booking sources')}

        {isEmpty(bookingSourcesChartData?.labels) || isEmpty(bookingSourcesChartData?.datasets)
          ? noDataPlaceholder
          : (
            <BookingSourcesChart
              data={bookingSourcesChartData}
              chartComponent={Chart}
            />
          )}
      </div>
    </>
  )
}