import { DatePicker, Select } from "antd";
import { formatDate } from "../../utils/dateHelper";
import dayjs from "dayjs";

import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { apiInstance, routes } from "../../utils/API";
import { useState } from "react";
import { useContext } from "react";

import GenericButton from "../../components/GenericButton/GenericButton";
import CustomInput from "../../components/Input/Input";
import { AppContext } from "../../AppContext";
import { manageToast } from "../../components/Common/ManageToast";
let timeOut = null;

export default function TopBar({ setTag }) {
  const [search, setSearch] = useState("");

  return (
    <div className="mb-4">
      <div className="flex flex-wrap md:flex-nowrap gap-2 md:gap-0 justify-between items-center mb-4">
        <div className="text-themeGrey text-xl font-bold">Data Sync</div>
      </div>
      <div className="flex w-full gap-x-6 justify-between">
        <div className="mob-w-100">
          <div className="flex gap-2">
            <div>
              <div className="text-sm text-buttonTheme font-medium flex items-center gap-x-1 mb-1 mr-5 md:mr-0">
                {"Search"}
              </div>

              <div className="w-[200px]">
                <CustomInput
                  placeholder={"Search by Sync Name"}
                  className="custom-input"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    if (timeOut) {
                      clearTimeout(timeOut);
                    }
                    timeOut = setTimeout(() => {
                      setTag(e.target.value);
                    }, 1000);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
