import React from 'react'
import { Table } from 'antd'
import { DownCircleTwoTone, UpCircleTwoTone } from '@ant-design/icons'
import { format, parse } from 'date-fns'

import { numberWithCommas } from '../../utils/helper'
import {
  getSummaryMonthsLabels,
  SUMMARY_TABLE_ROW_NAMES,
  ROW_NAMES_WITH_BG,
} from './helpers'

export default function SummaryTable (props) {
  const {
    loading,
    monthData = {},
    onTableChange,
  } = props

  const mainTableDataSource = React.useMemo(
    () => monthData?.summaryTableData || [],
    [monthData],
  )

  const marginsTableDataSource = React.useMemo(
    () => monthData?.summaryMarginsTableData || [],
    [monthData],
  )

  const MONTHS = getSummaryMonthsLabels('desc')

  const renderRowLabel = (label, className = '') => {
    const labelClassName = 'w-[200px] font-bold text-[16px] ' + className
    return (
      <p className={labelClassName}>
        {label || '-'}
      </p>
    )
  }

  const summaryColumns = [
    {
      title: '',
      dataIndex: 'key',
      render: (_, record) => (
        record?.isParent || record?.isChild
          ? null
          : renderRowLabel(record?.name, '!w-[265px]')
      ),
    },
    ...MONTHS.map((month, index) => {
      const title = format(parse(month, 'MMM-yyyy', new Date()), 'MMM-yy')
      return {
        title,
        dataIndex: month,
        render: (_, record, i) => {
          const classNames = {
            [SUMMARY_TABLE_ROW_NAMES.TOTAL_REVENUE]: 'font-bold',
            [SUMMARY_TABLE_ROW_NAMES.GROSS_PROFIT]: 'font-bold',
            [SUMMARY_TABLE_ROW_NAMES.PRE_CAPEX]: 'font-bold',
            [SUMMARY_TABLE_ROW_NAMES.CAPEX_COST]: 'font-bold',
            [SUMMARY_TABLE_ROW_NAMES.POST_CAPEX]: 'font-bold',
            [SUMMARY_TABLE_ROW_NAMES.POST_CAPEX]: 'font-bold',
            [SUMMARY_TABLE_ROW_NAMES.NET_MARGIN]: 'font-bold',
            [SUMMARY_TABLE_ROW_NAMES.NET_INCOME]: `font-bold 
            ${record[month] < 0 ? 'text-red-600' : 'text-[#4caf50]'}`,
          }
          const hasPercentageSign = record?.percentage
          const cellValue = typeof record[month] !== 'undefined'
            ? numberWithCommas(Number(record[month]), hasPercentageSign)
            : 'N/A'

          return (
            <p
              key={month + i}
              className={`whitespace-nowrap w-[70px] ${record[month] < 0 &&
              'text-red-600'} ${classNames[record.name]}`}
            >
              {cellValue}
            </p>
          )
        },
      }
    }),
  ]

  const generateRowClassName = record => {
    if (ROW_NAMES_WITH_BG.includes(record.name)) {
      return ' bg-[#eff5fa]'
    }

    return ''
  }

  const expandableOptions = {
    rowExpandable: record => record?.isParent,
    expandIcon: ({ expanded, onExpand, record }) => {
      if (!record?.isParent && !record?.isChild) {
        return null
      }

      if (record.isChild && !record.isParent) {
        return (
          <div className="ml-[95px] relative text-themeGrey">
            <div className={`absolute border-l-[1px] border-slate-300 w-[1px] 
              bottom-[-16px] top-[-16px] left-[-15px]`} />
            {renderRowLabel(record?.name, '!w-[220px] !text-[14px]')}
          </div>
        )
      }

      const expandableCell = expanded
        ? (
          <div className="flex items-center">
            <UpCircleTwoTone
              onClick={e => onExpand(record, e)}
              twoToneColor="#00b4b4"
              className="mr-3 text-base flex size-3"
            />
            {renderRowLabel(record?.name)}
          </div>
        )
        : (
          <div className="flex items-center">
            <DownCircleTwoTone
              onClick={e => onExpand(record, e)}
              twoToneColor="#00b4b4"
              className="mr-3 text-base flex size-3"
            />
            {renderRowLabel(record?.name)}
          </div>
        )

      if (record.isChild && record.isParent) {
        return (
          <div className="ml-[50px] relative">
            <div className={`absolute border-l-[1px] border-slate-300 w-[1px] 
            bottom-[-16px] top-[-16px] left-[-15px]`} />
            {expandableCell}
          </div>
        )
      }

      return expandableCell
    },
  }

  return (
    <>
      <Table
        rowClassName={generateRowClassName}
        dataSource={mainTableDataSource}
        expandable={expandableOptions}
        columns={summaryColumns}
        onChange={onTableChange}
        scroll={{ x: 2200 }}
        pagination={false}
        loading={loading}
        rowKey="key"
      />

      <div className="pt-20">
        <Table
          rowClassName={generateRowClassName}
          dataSource={marginsTableDataSource}
          columns={summaryColumns}
          onChange={onTableChange}
          scroll={{ x: 2200 }}
          pagination={false}
          loading={loading}
          rowKey="key"
        />
      </div>
    </>
  )
}