import React from 'react'
import { Button, Form, Input, Select } from 'antd'

import { STATUS_OPTIONS } from '../utils'

import './styles.css'

const requiredRules = [{
  required: true,
  message: 'This field is required'
}]

function LockBoxForm (props) {
  const {
    form,
    formName,
    handleCancel,
    handleSubmit,
    properties,
    submitButtonLabel,
    propertiesLoading,
    loading
  } = props

  return (
    <Form
      autoComplete="off"
      form={form}
      layout="vertical"
      name={formName}
      onFinish={handleSubmit}
    >
      <Form.Item
        name="propertyId"
        label="Property"
        rules={requiredRules}
        className="!mb-3"
      >
        <Select
          loading={propertiesLoading}
          placeholder="Select property"
          optionFilterProp="label"
          options={properties}
          showSearch
          allowClear
        />
      </Form.Item>

      <Form.Item
        name="lockboxName"
        label="Lockbox Name"
        rules={requiredRules}
        className="!mb-3"
      >
        <Input
          placeholder="Enter lockbox name"
        />
      </Form.Item>

      <Form.Item
        name="code"
        label="Code"
        rules={requiredRules}
        className="!mb-3"
      >
        <Input
          placeholder="Enter lockbox code"
        />
      </Form.Item>

      <Form.Item
        name="status"
        label="Status"
        rules={requiredRules}
        className="!mb-3"
      >
        <Select
          placeholder="Select status"
          options={STATUS_OPTIONS}
        />
      </Form.Item>

      <div className="flex gap-3 justify-end items-end pt-5">
        <Button
          className="hover:!text-buttonTheme hover:!border-buttonTheme"
          onClick={handleCancel}
        >
          Cancel
        </Button>

        <Form.Item
          label={null}
          className="!mb-0"
        >
          <Button
            loading={loading}
            className="bg-buttonTheme hover:!bg-buttonHoverTheme self-end"
            htmlType="submit"
            type='primary'
          >
            {submitButtonLabel}
          </Button>
        </Form.Item>
      </div>
    </Form>
  )
}

export default LockBoxForm

