import { Select, Tabs } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  BOOKING_ENGINE_DESIGN,
  BOOKING_ENGINE_LISTINGS,
  BOOKING_ENGINE_PAGES,
  BOOKING_ENGINE_SETTINGS,
} from "../../constants/dashboardRoutes";
import BookingEngineDesign from "../BookingEngineDesign/BookingEngineDesign";
import BookingEngineListings from "../BookingEngineListings/BookingEngineListings";
import BookingEnginePages from "../BookingEnginePages/BookingEnginePages";
import BookingEngineSettings from "../BookingEngineSettings/BookingEngineSettings";
import GenericButton from "../../components/GenericButton/GenericButton";

const BookingEngine = ({ defaultActiveKey }) => {
  const navigate = useNavigate();

  const items = [
    {
      key: "design",
      label: (
        <div
          className="text-themeGrey font-bold flex items-center gap-3"
          onClick={() => {
            navigate(BOOKING_ENGINE_DESIGN);
          }}
        >
          DESIGN
        </div>
      ),
      children: <BookingEngineDesign />,
    },
    {
      key: "listings",
      label: (
        <div
          className="text-themeGrey font-bold flex items-center gap-3"
          onClick={() => {
            navigate(BOOKING_ENGINE_LISTINGS);
          }}
        >
          LISTINGS
        </div>
      ),
      children: <BookingEngineListings />,
    },
    {
      key: "pages",
      label: (
        <div
          className="text-themeGrey font-bold flex items-center gap-3"
          onClick={() => {
            navigate(BOOKING_ENGINE_PAGES);
          }}
        >
          PAGES
        </div>
      ),
      children: <BookingEnginePages />,
    },
    {
      key: "settings",
      label: (
        <div
          className="text-themeGrey font-bold flex items-center gap-3"
          onClick={() => {
            navigate(BOOKING_ENGINE_SETTINGS);
          }}
        >
          SETTINGS
        </div>
      ),
      children: <BookingEngineSettings />,
    },
  ];
  return (
    <div className="m-5 bg-white px-5" id="custom-tabs">
      <div className="flex items-center justify-between font-bold text-3xl text-black mb-5 pt-5">
        <div className="xs:text-xs md:text-2xl lg:text-3xl">Booking engine</div>
        <div className="flex items-center gap-3">
          <Select
            bordered={true}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            placeholder="Select Value"
          />
          <GenericButton label="Save" className="bg-themeGreen text-white" />
          <GenericButton
            label="Publish"
            className="bg-labelGreen text-themeGreen"
          />
        </div>
      </div>
      <Tabs activeKey={defaultActiveKey} items={items} centered />
    </div>
  );
};

export default BookingEngine;
