import React from "react";
import { Badge } from "antd";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const TabDiv = ({
  id,
  badgeCount,
  badgeColor,
  tabText = "Test",
  selectedTabId,
  onTabClick,
}) => {
  const handleTabClick = () => {
    onTabClick(id);
  };

  const isTabSelected = selectedTabId === id;

  return (
    <div
      className={`flex items-center justify-between px-2 h-14 rounded-lg cursor-pointer ${
        isTabSelected ? "bg-themeHover" : "bg-white"
      }`}
      onClick={handleTabClick}
    >
      <div>{tabText}</div>
      <div className="flex items-center gap-2">
        <Badge
          count={badgeCount}
          color={badgeColor}
          className="xs:hidden lg:block"
        />
        <MoreHorizIcon style={{ fontSize: 16 }} />
      </div>
    </div>
  );
};

export default TabDiv;
