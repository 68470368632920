/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react'
import { isEmpty } from 'lodash'
import { Icon } from '@iconify-icon/react'
import { format, subMonths } from 'date-fns'
import Dropdown from 'antd/es/dropdown/dropdown'
import { Button, Tooltip } from 'antd'
import { CloseOutlined, DownOutlined } from '@ant-design/icons'

import { manageToast } from '../../components/Common/ManageToast'
import Loading from '../../components/Loaders/Loading'
import NoAccess from '../../components/NoAccess'
import { AppContext } from '../../AppContext'
import { apiInstanceFinance, routes_finance } from '../../utils/API_finance'
import { MONTHS_YEAR } from '../../utils/constants'
import { numberWithCommas } from '../../utils/helper'

import IncomeTable from './IncomeTable'
import PropertyMarginsGraph from './PropertyMargins'
import RevenueAndBookingSourceAnalysis from './RevenueAndBookingSourceAnalysis'
import RoiCalculator from './ROICalculator'
import RoiCalculatorV2 from './ROICalculatorV2'
import SummaryGraph from './Graph'
import SummaryTable from './SummaryTable'
import Tabs from './Tabs'
import TopBar from './TopBar'
import TopSectionCards from './Cards'
import {
  calculateTotalValueByRange,
  getDateRange,
  getDateRangeArray,
  handleDownloadCSV,
  normalizeMonthData,
} from './helpers'
import {
  normalizeDataToRevenuesChart,
  normalizeDataToPropertyMarginsChart,
  normalizeDataToDirectBookingReferalsChart,
  normalizeDataToBookingSourcesChart,
} from './graphHelpers'

const PROPERTIES_ITEMS = {
  ALL: 'All Properties',
  CURRENT: 'Current Properties',
  EXITED: 'Exited Properties'
}

export default function Finance () {
  const profile = React.useContext(AppContext).profile || {}
  const hasReadAccess = profile?.readFinance
  const [date, setDate] = React.useState()
  const [stringDate, setStringDate] = React.useState()
  const [data, setData] = React.useState([])
  const [propertyLoading, setPropertyLoading] = React.useState(true)
  const [summaryLoading, setSummaryLoading] = React.useState(true)
  const [limit, setLimit] = React.useState(20)
  const [page, setPage] = React.useState(1)
  const [sortBy, setSortBy] = React.useState('')
  const [tag, setTag] = React.useState('')
  const [areaFilter, setAreaFilter] = React.useState(null)
  const [bedCount, setBedCount] = React.useState(null)
  const [order, setOrder] = React.useState('asc')
  const [name, setName] = React.useState(null)
  const [months, setMonths] = React.useState(null)
  const [city, setCity] = React.useState(null)
  const [selectedTab, setSelectedTab] = React.useState(1)
  const [summaryData, setSummaryData] = React.useState([])
  const [directBookingReferals, setDirectBookingReferals] = React.useState({})
  const [propertiesToShow, setPropertiesToShow] = React.useState(PROPERTIES_ITEMS.ALL)
  const [triggerSummaryRefetch, setTriggerSummaryRefetch] = React.useState(false)

  React.useEffect(() => {
    if (hasReadAccess) {
      const controller = new AbortController()

      getAvailabilities(controller.signal)

      return () => {
        controller.abort()
      }
    }
  }, [
    hasReadAccess,
    date,
    tag,
    areaFilter,
    bedCount,
    order,
    name,
    months,
    city
  ])

  React.useEffect(() => {
    const controller = new AbortController()

    getSummaryData(controller.signal)

    return () => {
      controller.abort()
    }
  }, [city, triggerSummaryRefetch])

  const handleSortIcon = (sortOrder) => {
    if (sortOrder.sortOrder === 'ascend') {
      return (<Icon
        style={{ transform: 'rotateX(180deg)' }}
        icon="prime:sort-amount-up"
        height={24}
        width={24}
      />)
    } else if (sortOrder.sortOrder === 'descend') {
      return (<Icon
        style={{ transform: 'rotateX(0deg)' }}
        icon="prime:sort-amount-up"
        height={24}
        width={24}
      />)
    } else {
      return (<Icon
        style={{ transform: 'rotateX(180deg)' }}
        icon="prime:sort-amount-up"
        height={24}
        width={24}
      />)
    }
  }

  const handleTabSelect = tabIndex => () => setSelectedTab(tabIndex)

  const getAvailabilities = (signal) => {
    return Promise.resolve()
    .then(() => setPropertyLoading(true))
    .then(() => {
      let url = `${routes_finance.FINANCE_PROPERTY}?`
      if (months) {
        const dateRange = getDateRange(months)
        const datesArray = getDateRangeArray(dateRange[0], dateRange[1])
        url += `rangeMonth=${datesArray}&`
        url += `months=${months}&`
      } else if (date) {
        if (stringDate[0] === stringDate[1]) {
          const month = new Date(`${stringDate[0]}-01`).getMonth()
          const year = new Date(`${stringDate[0]}-01`).getFullYear()
          url += `month=${MONTHS_YEAR[month]}:${year}&`
        } else {
          const datesArray = getDateRangeArray(stringDate[0], stringDate[1])
          url += `rangeMonth=${datesArray}&`
          url += `months=${datesArray.length}&`
        }
      } else {
        const start = '2023-01'
        const end = format(subMonths(new Date(), 1), 'yyyy-MM')
        const datesArray = getDateRangeArray(start, end)
        url += `rangeMonth=${datesArray}&`
        url += `months=${months}&`
      }
      if (name) {
        url += `filter=${name}&`
      }
      if (tag) {
        url += `tag=${tag}`
      }

      if (city) {
        url += `city=${city}&`
      }

      return url
    })
    .then(url => {
      return apiInstanceFinance.get(url, signal ? { signal } : {}).then(response => {
        const nextData = response?.data?.finances?.length
          ? response.data.finances.filter(item => {
            return item?.check && !item?.propertyName?.toLowerCase()?.includes('communal area')
          })
          : []

        setData(nextData)
        setDirectBookingReferals(response?.data?.meta?.directNameBookingsPercent || {})
        setPropertyLoading(false)
      })
    })
    .catch((e) => {
      if (e?.name === 'CanceledError') {
        setPropertyLoading(true)
        console.log('Request cancelled.', e)
      } else {
        setPropertyLoading(false)
        manageToast(false, 'Server error. Something went wrong.')
      }
    })
  }

  const record = React.useMemo(() => {
    if (propertiesToShow === PROPERTIES_ITEMS.EXITED) {
      return data.filter(prop => (
        prop?.property?.availability === 'Exit'
      ))
    }

    if (propertiesToShow === PROPERTIES_ITEMS.CURRENT) {
      return data.filter(prop => (
        prop?.property?.availability !== 'Exit'
      ))
    }

    return data
  }, [data, propertiesToShow])

  const menuItems = [
    {
      label: PROPERTIES_ITEMS.CURRENT,
      key: PROPERTIES_ITEMS.CURRENT,
    },
    {
      label: PROPERTIES_ITEMS.EXITED,
      key: PROPERTIES_ITEMS.EXITED
    }
  ]

  const menuProps = {
    items: menuItems,
    onClick: (e) => setPropertiesToShow(e.key),
    placement: 'bottomLeft',
    sx: { zIndex: 10 }
  }

  const propertiesCollectionToCompare = record.map(item => ({
    name: item.property.propertyName,
    netRevenueByMonths: item?.netRevenueByMonths,
    expenseByMonths: item?.expenseByMonths,
  }))

  const revenuesChartData = React.useMemo(
    () => normalizeDataToRevenuesChart(record),
    [record]
  )

  const directBookingReferalsChartData = React.useMemo(
    () => normalizeDataToDirectBookingReferalsChart(directBookingReferals),
    [directBookingReferals]
  )

  const bookingSourcesChartData = React.useMemo(
    () => normalizeDataToBookingSourcesChart(record),
    [record]
  )

  const handleDownloadCsvFile = React.useCallback(
    () => handleDownloadCSV(record), [record])

  const monthData = React.useMemo(() => {
    return !isEmpty(summaryData)
      ? normalizeMonthData(summaryData)
      : {}
  }, [summaryData])

  const coggsValue = React.useMemo(() => {
    let value = 0

    if (!monthData?.costsOfGoods) {
      return value
    }

    if (months) {
      const dateRange = getDateRange(months)
      const datesArray = getDateRangeArray(dateRange[0], dateRange[1], 'MMM-yyyy')

      return calculateTotalValueByRange(datesArray, monthData.costsOfGoods)
    }

    if (stringDate?.length && stringDate?.[0] !== '' && stringDate?.[1] !== '') {
      const datesArray = getDateRangeArray(stringDate[0], stringDate[1],
        'MMM-yyyy')

      return calculateTotalValueByRange(datesArray, monthData.costsOfGoods)
    }

    for (const key of Object.keys(monthData.costsOfGoods)) {
      value += Number(monthData.costsOfGoods[key])
    }

    return value
  }, [monthData, months, stringDate])

  const showExpense = React.useMemo(() => {
    return name || propertiesToShow === PROPERTIES_ITEMS.EXITED ||
      propertiesToShow === PROPERTIES_ITEMS.CURRENT
  }, [name, propertiesToShow])

  const showNetIncome = React.useMemo(() => {
    return propertiesToShow === PROPERTIES_ITEMS.ALL && !name
  }, [name, propertiesToShow])

  const cardsValues = React.useMemo(() => {
    const values = {
      profit: 0,
      income: 0,
      expenses: 0,
      occupancy: 0,
      grossMargin: 0
    }

    if (!record.length) {
      return values
    }

    const recordsWithOccupancyRateCounter = record.filter(r => {
      return !!Number(r.occupancyRate)
    }).length
    const recordsWithGrossMargin = record.filter(r => {
      return Number(r.grossRevenueMargen) !== 0 && r.grossRevenueMargen !== -100
    }).length

    record.forEach((item) => {
      values.income = values.income + Number(item.netRevenue)
      values.occupancy = values.occupancy + Number((item.occupancyRate) /
        (recordsWithOccupancyRateCounter))

      values.grossMargin = Number(item.grossRevenueMargen) !== 0 && Number(item.grossRevenueMargen) !== -100
        ? values.grossMargin + (Number(item.grossRevenueMargen) / recordsWithGrossMargin)
        : values.grossMargin

      values.expenses = typeof item.expense === 'string'
        ? values.expenses
        : values.expenses = values.expenses + Number(item.expense)
    })

    if (values.income) {
      if (name || propertiesToShow === PROPERTIES_ITEMS.CURRENT ||
        propertiesToShow === PROPERTIES_ITEMS.EXITED) {
        values.profit = values.income - values.expenses
      } else {
        values.profit = values.income - coggsValue
      }
    } else {
      values.profit = 0
    }

    return values
  }, [record, coggsValue, name, propertiesToShow])

  const onTableChange = (pagination, filters, sorter) => {
    const customSortFields = [
      'airbnbNumberOfBookings', 'airbnbReviewingScore',
      'bookingComNumberOfBookings', 'bookingComRewievingScore',
    ]

    const keys = {
      'airbnbNumberOfBookings': 'nbAirbnb',
      'airbnbReviewingScore': 'scoreAirbnb',
      'bookingComNumberOfBookings': 'nbBDC',
      'bookingComRewievingScore': 'scoreBDC',
    }

    if (sorter.field) {
      if (customSortFields.includes(sorter.field)) {
        const valueKey = keys[sorter.field]

        const emptyScores = data.filter(item => isEmpty(item?.score))
        const hasScores = data
          .filter(item => !isEmpty(item?.score))
          .sort((a, b) => {
            if (sorter.order === 'ascend') {
              return a.score[valueKey] - b.score[valueKey]
            } else {
              return b.score[valueKey] - a.score[valueKey]
            }
          })

        setData([...hasScores, ...emptyScores])
        return
      }

      setData([...data].sort((a, b) => {
        const valueA = a[sorter.field] || Infinity
        const valueB = b[sorter.field] || Infinity

        if (isNaN(valueA)) return 1
        if (isNaN(valueB)) return -1

        if (sorter.order === 'ascend') {
          return valueA - valueB
        } else {
          return b[sorter.field] - a[sorter.field]
        }
      }))
    }
  }

  const getSummaryData = (signal) => {
    return Promise.resolve()
    .then(() => setSummaryLoading(true))
    .then(() => {
      const startDate = '2023-01'
      const endDate = format(subMonths(new Date(), 1), 'yyyy-MM')
      const datesArray = getDateRangeArray(startDate, endDate)

      let url = `${routes_finance.FINANCE_SUMMARY}?`
      url += `rangeMonth=${datesArray}&`
      url += `months=${datesArray.length}&`

      if (city) {
        url += `city=${city}&`
      }

      return url
    })
    .then(url => {
      return apiInstanceFinance.get(url, signal ? { signal } : {}).then(response => {
        setSummaryData(response.data || {})
        setSummaryLoading(false)
      })
    })
    .catch((e) => {
      if (e?.name === 'CanceledError') {
        setSummaryLoading(true)
        console.log('Request cancelled.', e)
      } else {
        manageToast(false, 'Server error. Something went wrong.')
        setSummaryLoading(false)
      }
    })
  }

  const netIncomeValue = React.useMemo(() => {
    let value = 0

    if (!monthData?.netIncome) {
      return value
    }

    if (months) {
      const dateRange = getDateRange(months)
      const datesArray = getDateRangeArray(dateRange[0], dateRange[1], 'MMM-yyyy')
      const totalValue = calculateTotalValueByRange(datesArray, monthData.netIncome)

      return numberWithCommas(totalValue)
    }

    if (stringDate?.length && stringDate?.[0] !== '' && stringDate?.[1] !== '') {
      const datesArray = getDateRangeArray(stringDate[0], stringDate[1],
        'MMM-yyyy')
      const totalValue = calculateTotalValueByRange(datesArray, monthData.netIncome)

      return numberWithCommas(totalValue)
    }

    for (const key of Object.keys(monthData.netIncome)) {
      value += Number(monthData.netIncome[key])
    }

    return numberWithCommas(value)
  }, [monthData, months, stringDate])

  const ebitaValue = React.useMemo(() => {
    let value = 0

    if (!monthData?.ebita) {
      return value
    }

    if (months) {
      const dateRange = getDateRange(months)
      const datesArray = getDateRangeArray(dateRange[0], dateRange[1], 'MMM-yyyy')
      const totalValue = calculateTotalValueByRange(datesArray, monthData.ebita)

      return numberWithCommas(totalValue)
    }

    if (stringDate?.length && stringDate?.[0] !== '' && stringDate?.[1] !== '') {
      const datesArray = getDateRangeArray(stringDate[0], stringDate[1],
        'MMM-yyyy')
      const totalValue = calculateTotalValueByRange(datesArray, monthData.ebita)

      return numberWithCommas(totalValue)
    }

    for (const key of Object.keys(monthData.ebita)) {
      value += Number(monthData.ebita[key])
    }

    return numberWithCommas(value)
  }, [monthData, months, stringDate])

  const marginsChartData = React.useMemo(() => {
    return monthData?.graphData?.length
      ? monthData.graphData
      : []
  }, [monthData])

  const dateFilters = React.useMemo(() => {
    if (months) {
      const dateRange = getDateRange(months)
      return getDateRangeArray(dateRange[0], dateRange[1], 'MMM-yy')
    }

    if (stringDate?.length && stringDate?.[0] !== '' && stringDate?.[1] !== '') {
      return getDateRangeArray(stringDate[0], stringDate[1], 'MMM-yy')
    }

    return undefined
  }, [months, stringDate])

  const propertyMarginsGraphData = React.useMemo(
    () => normalizeDataToPropertyMarginsChart(record, dateFilters),
    [record, dateFilters]
  )

  if (profile._id && !hasReadAccess) {
    return <NoAccess />
  }

  return (
    <div className="p-5">
      <TopBar
        date={date}
        setDate={setDate}
        count={record.length}
        tag={tag}
        handleDownloadCSV={handleDownloadCsvFile}
        setTag={setTag}
        months={months}
        setMonths={setMonths}
        bedCount={bedCount}
        setBedCount={setBedCount}
        city={city}
        setCity={setCity}
        setStringDate={setStringDate}
        page={page}
        name={name}
        setName={setName}
        areaFilter={areaFilter}
        setAreaFilter={setAreaFilter}
        setPage={setPage}
        limit={limit}
        setLimit={setLimit}
        sortBy={sortBy}
        refetchSummaryData={() => {
          setTriggerSummaryRefetch(!triggerSummaryRefetch)
        }}
        show={false}
      />

      {(propertyLoading || summaryLoading) ? (
        <div className={`h-[50vh] bg-white rounded-lg flex justify-center 
        items-center`}>
          <Loading />
        </div>
      )
        : (
          <div className="flex flex-col gap-4 mt-4">
            <div className=" flex flex-col gap-4">
              <div className="flex justify-between items-center">
                <div>
                  <h2 className="text-md font-bold">Finance Overview</h2>
                  <h1 className="text-xs ">All Properties</h1>
                </div>
              </div>

              <TopSectionCards
                coggsValue={numberWithCommas(coggsValue)}
                date={date}
                expense={cardsValues.expenses}
                grossMargin={cardsValues.grossMargin}
                income={cardsValues.income}
                netIncome={netIncomeValue}
                showNetIncome={showNetIncome}
                months={months}
                occupancy={cardsValues.occupancy}
                profit={cardsValues.profit}
                stringDate={stringDate}
                showExpense={showExpense}
                ebita={ebitaValue}
              />
            </div>

            <div className="flex flex-col gap-4 mt-5">
              <Tabs
                selectedTab={selectedTab}
                handleTabSelect={handleTabSelect}
              />

              {selectedTab === 1 && (
                <>
                  <div className="flex justify-end mb-3 mt-[-10px]">
                    <Dropdown menu={menuProps}>
                      <Button className="flex items-center justify-between"
                      >
                        {propertiesToShow} {<DownOutlined />}
                      </Button>
                    </Dropdown>

                    {propertiesToShow !== PROPERTIES_ITEMS.ALL && (
                      <Tooltip title='Clear filter'>
                        <Button
                          className={`flex items-center justify-center px-[8px] 
                          ml-3 hover:!text-red-600 hover:!border-red-600`}
                          onClick={() => {
                            setPropertiesToShow(PROPERTIES_ITEMS.ALL)
                          }}
                        >
                          <CloseOutlined />
                        </Button>
                      </Tooltip>
                    )}
                  </div>

                  <IncomeTable
                    handleSortIcon={handleSortIcon}
                    loading={propertyLoading}
                    onTableChange={onTableChange}
                    record={record}
                  />
                </>
              )}

              {selectedTab === 2 && (
                <SummaryTable
                  loading={summaryLoading}
                  monthData={monthData}
                  onTableChange={onTableChange}
                />
              )}

              {selectedTab === 3 && (
                <SummaryGraph
                  dateFilters={dateFilters}
                  marginsChartData={marginsChartData}
                />
              )}

              {selectedTab === 4 && (
                <PropertyMarginsGraph
                  chartData={propertyMarginsGraphData}
                />
              )}

              {selectedTab === 5 && (
                <RevenueAndBookingSourceAnalysis
                  revenuesChartData={revenuesChartData}
                  directBookingReferalsChartData={directBookingReferalsChartData}
                  bookingSourcesChartData={bookingSourcesChartData}
                />
              )}

              {selectedTab === 6 && (
                <RoiCalculator propertiesData={propertiesCollectionToCompare} />
              )}

              {selectedTab === 7 && (
                <RoiCalculatorV2 propertiesData={propertiesCollectionToCompare} />
              )}
            </div>
          </div>
        )}
    </div>
  )
}
