import { DatePicker, Select } from "antd";
import { formatDate } from "../../../utils/dateHelper";
import dayjs from "dayjs";
import GenericButton from "../../GenericButton/GenericButton";
import CustomInput from "../../Input/Input";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { apiInstance, routes } from "../../../utils/API";
import { useState } from "react";
import { useContext } from "react";
import { AppContext } from "../../../AppContext";
import { manageToast } from "../../Common/ManageToast";
let timeOut = null;

export default function TopBar({
  date,
  setDate,
  getAvailabilities,
  count,
  tag,
  setTag,
  page,
  setPage,
  limit,
  bedCount,
  setBedCount,
  areaFilter,
  setAreaFilter,
  setLimit,
  setCityFilter,
  cityFilter,
  show = true,
}) {
  const [syncing, setSyncing] = useState(false);
  const profile = useContext(AppContext).profile || {};
  const [search, setSearch] = useState("");
  const isAdmin = profile.isAdmin;
  const cityData = profile.assignedCity;

  console.log(cityData);

  const syncData = async () => {
    try {
      setSyncing(true);
      manageToast(
        true,
        "Sync is started. It will take some time. You can still continue browsing."
      );
      const res = await apiInstance.post(routes.SYNC_VIEWS);
      if (res.data.status === 200) {
      } else {
      }
      setTimeout(() => {
        getAvailabilities();
        setSyncing(false);
      }, 100000);
    } catch (error) {
      if (error) {
        setTimeout(() => {
          manageToast(false, "Server Error. Someting went wrong");
          setSyncing(false);
        }, 100010);
      }
    }
  };

  return (
    <div className="mb-4">
      <div className="flex flex-wrap md:flex-nowrap gap-2 md:gap-0 justify-between items-center mb-4">
        <div className="text-themeGrey text-xl font-bold">
          Available Properties
          {count > 0 && <span className="text-base">({count})</span>}
        </div>
        <div className="flex gap-x-0 md:gap-x-3">
          {isAdmin && (
            <GenericButton
              isLoading={syncing}
              label="Sync"
              loadingClass="text-white"
              disabled={syncing}
              className="disabled:hover:!bg-buttonTheme disabled:hover:text-white disabled:!text-white disabled:bg-buttonTheme  hover:!bg-buttonHoverTheme hover:text-white bg-buttonTheme mr-5 shadow-0"
              icon={<LocalOfferIcon style={{ fontSize: "14px" }} />}
              onClick={syncData}
            />
          )}
          {show && (
            <Select
              value={limit}
              options={[
                { value: 20, label: 20 },
                { value: 50, label: 50 },
                { value: 100, label: 100 },
              ]}
              className="w-max"
              onChange={(l) => {
                setLimit(l);
                setPage(1);
              }}
            />
          )}
        </div>
      </div>
      <div className="flex flex-wrap w-full gap-x-6 justify-between gap-y-2 ">
        <div className="mob-w-100">
          <div className="flex flex-wrap gap-y-2 gap-2">
            <div>
              <div className="text-sm text-buttonTheme font-medium flex items-center gap-x-1 mb-1 mr-5 md:mr-0">
                {!show ? "Property Name" : "Tag"}
              </div>

              <div className="w-[200px]">
                <CustomInput
                  placeholder={show ? "Enter Tag" : "Enter Property Name"}
                  className="custom-input"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    if (timeOut) {
                      clearTimeout(timeOut);
                    }
                    timeOut = setTimeout(() => {
                      setTag(e.target.value);
                    }, 1000);
                  }}
                />
              </div>
            </div>
            <div>
              <div className="text-sm text-buttonTheme font-medium flex items-center gap-x-1 mb-1 mr-5 md:mr-0">
                {!show ? "City" : "Tag"}
              </div>
              <div className="w-[200px]">
                <Select
                  allowClear
                  className="w-full"
                  status="blue"
                  rootClassName="border-red-500"
                  placeholder="Select Area"
                  options={
                    isAdmin || cityData?.includes("All")
                      ? [
                          { label: "London", value: "London" },
                          { label: "Algiers", value: "Algiers" },
                          { label: "Dubai", value: "Dubai" },
                          { label: "Paris", value: "Paris" },
                        ]
                      : [
                          ...[
                            cityData?.includes("London")
                              ? { label: "London", value: "London" }
                              : null,
                          ],
                          ...[
                            cityData?.includes("Algiers")
                              ? { label: "Algiers", value: "Algiers" }
                              : null,
                          ],
                          ...[
                            cityData?.includes("Dubai")
                              ? { label: "Dubai", value: "Dubai" }
                              : null,
                          ],
                          ...[
                            cityData?.includes("Paris")
                              ? { label: "Paris", value: "Paris" }
                              : null,
                          ],
                        ].filter((item) => item)
                  }
                  onChange={(e) => {
                    if (e) {
                      setCityFilter(e);
                      setAreaFilter(null);
                    } else {
                      setCityFilter(null);
                      setAreaFilter(null);
                    }
                  }}
                  value={cityFilter}
                />
              </div>
            </div>
            <div>
              <div className="text-sm text-buttonTheme font-medium flex items-center gap-x-1 mb-1 mr-5 md:mr-0">
                {!show ? "Area" : "Tag"}
              </div>
              <div className="w-[200px]">
                <Select
                  allowClear
                  className="w-full"
                  status="blue"
                  disabled={cityFilter !== "London"}
                  rootClassName="border-red-500"
                  placeholder="Select Area"
                  options={[
                    { label: "East London", value: "East London" },
                    { label: "West London", value: "West London" },
                    { label: "South London", value: "South London" },
                    { label: "North London", value: "North London" },
                    { label: "South East London", value: "SE" },
                    { label: "South West London", value: "SW" },
                    { label: "North East London", value: "NE" },
                    { label: "North West London", value: "NW" },
                  ]}
                  onChange={(e) => {
                    if (e) {
                      setAreaFilter(e);
                    } else {
                      setAreaFilter(null);
                    }
                  }}
                  value={areaFilter}
                />
              </div>
            </div>
            <div>
              <div className="text-sm text-buttonTheme font-medium flex items-center gap-x-1 mb-1 mr-5 md:mr-0">
                {!show ? "Bedrooms" : "Tag"}
              </div>
              <div className="w-[200px]">
                <Select
                  allowClear
                  className="w-full"
                  status="blue"
                  rootClassName="border-red-500"
                  placeholder="Select No. of Bedrroms"
                  options={[
                    { label: "0", value: "0" },
                    { label: "1", value: "1" },
                    { label: "2", value: "2" },
                    { label: "3", value: "3" },
                    { label: "4", value: "4" },
                    { label: "5", value: "5" },
                    { label: "6", value: "6" },
                  ]}
                  onChange={(e) => {
                    if (e) {
                      setBedCount(e);
                    } else {
                      setBedCount(null);
                    }
                  }}
                  value={bedCount}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="checkout-container">
          <div style={{ width: "100%" }}>
            <div className="text-sm text-buttonTheme font-medium flex items-center gap-x-1 mb-1">
              Date
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={18}
                  height={18}
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M19.002 3h-14c-1.103 0-2 .897-2 2v4h2V5h14v14h-14v-4h-2v4c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.898-2-2-2z"
                  />
                  <path fill="currentColor" d="m11 16l5-4l-5-4v3.001H3v2h8z" />
                </svg>
              </span>
            </div>
            <div className="flex items-center gap-x-1">
              {show && (
                <GenericButton
                  label="Today"
                  onClick={() => {
                    if (
                      formatDate(new Date(), "dd MMM, yyyy") ===
                      formatDate(date, "dd MMM, yyyy")
                    ) {
                      setDate(null);
                      getAvailabilities(null);
                    } else {
                      setDate(new Date());
                      getAvailabilities(new Date());
                    }
                    setPage(1);
                  }}
                  className={`py-1 rounded-md md:px-[15px] px-1 text-white inline-block ${
                    formatDate(new Date(), "dd MMM, yyyy") ===
                    formatDate(date, "dd MMM, yyyy")
                      ? "bg-buttonHoverTheme hover:!bg-buttonHoverTheme hover:!text-white "
                      : "bg-buttonTheme hover:!bg-buttonHoverTheme hover:!text-white "
                  }`}
                />
              )}
              <DatePicker
                labelName="check In date"
                className="h-8"
                size="small"
                allowClear={true}
                disabledDate={(e) =>
                  e < new Date().setDate(new Date().getDate() - 1)
                }
                value={date ? dayjs(date) : null}
                onChange={(e, stringDate) => {
                  const d = !stringDate ? null : new Date(stringDate);
                  setDate(d);
                  getAvailabilities(d);
                  setPage(1);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
