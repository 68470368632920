import { Input, Modal } from "antd";
import React from "react";

const ChannelManagerEditModal = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <Modal
      title={
        <div className="text-bold text-themeGrey text-xl pb-5 border-b-2 border-creme">
          Enter new listing name (internal)
        </div>
      }
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      width={620}
    >
      <div className="flex items-center justify-center">
        <Input
          value={"3B SW15 - The Putney Apartment"}
          className="rounded-none w-full"
        />
      </div>
    </Modal>
  );
};

export default ChannelManagerEditModal;
