import { Button } from "antd";

import Tasks from "..";
import { archivedBody } from "../Archived/Archived";
import manageTasksBG from "../../../assets/manageTasksBG.jpg";
import { manageTasksBody } from "../ManageTasksPage/ManageTasks";
import { manageAutoTasksBody } from "../ManageAutoTasks/manageAutoTasks";

export const checklistTemplatesBody = (setShowChecklistTemplate) => {
  return (
    <div className="flex flex-col justify-center items-center w-full h-auto gap-y-2">
      <img src={manageTasksBG} />
      <p className="font-bold text-xl">
        Things are more satisfying when they are organized
      </p>
      <p className="text-lg font-medium">
        Create tasks triggered by events with due dates. You can assign tasks to
        an individual or to a group/company
      </p>
      <p className="text-base">
        You can also add reminders or assign tasks to yourself to do later on
      </p>
      <div>
        <Button
          type="primary"
          className="bg-buttonTheme font-semibold hover:!bg-buttonHoverTheme"
          onClick={() => setShowChecklistTemplate(true)}
        >
          Add checklist template
        </Button>
      </div>
    </div>
  );
};

function ChecklistTemplates() {
  return (
    <Tasks
      manageTasksBody={manageTasksBody}
      manageAutoTasksBody={manageAutoTasksBody}
      checklistTemplatesBody={checklistTemplatesBody}
      archivedBody={archivedBody}
      activeKey={3}
    />
  );
}

export default ChecklistTemplates;
