import React from 'react'
import dayjs from 'dayjs'
import { DatePicker, Select } from 'antd'

import GenericButton from '../../components/GenericButton/GenericButton'
import CustomInput from '../../components/Input/Input'
import { formatDate } from '../../utils/dateHelper'

import ExcelParser from './ExcelParser'

let timeOut = null

export default function TopBar ({
  date,
  setDate,
  count,
  setTag,
  setPage,
  setName,
  city,
  setStringDate,
  setCity,
  months,
  setMonths,
  handleDownloadCSV,
  refetchSummaryData,
  show = true
}) {
  const [search, setSearch] = React.useState('')
  const [searchName, setSearchName] = React.useState('')

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf('day')
  }

  return (
    <div className="mb-4">
      <div className="flex flex-wrap md:flex-nowrap gap-2 md:gap-0
        justify-between items-center mb-4">
        <div className="text-themeGrey text-xl font-bold">
          Finance Dashboard
          {count > 0 && <span className="text-base">({count})</span>}
        </div>
        <div className="flex items-center">
          <ExcelParser refetchSummaryData={refetchSummaryData} />

          <GenericButton
            label="Download Excel"
            loadingClass="text-white"
            className="disabled:hover:!bg-buttonTheme disabled:hover:text-white
              disabled:!text-white disabled:bg-buttonTheme
              hover:!bg-buttonHoverTheme hover:text-white
              bg-buttonTheme mr-5 shadow-0"
            onClick={handleDownloadCSV}
          />
        </div>
      </div>
      <div className="flex flex-wrap w-full gap-x-6 justify-between">
        <div className="mob-w-100 mb-2">
          <div className="flex flex-wrap gap-2">
            <div>
              <div className="text-sm text-buttonTheme font-medium flex
                items-center gap-x-1 mb-1 mr-5 md:mr-0">
                {'Filter by Property'}
              </div>

              <div className="w-[200px]">
                <CustomInput
                  placeholder={'Enter Property name'}
                  className="custom-input"
                  value={searchName}
                  onChange={(e) => {
                    setSearchName(e.target.value)
                    if (timeOut) {
                      clearTimeout(timeOut)
                    }
                    timeOut = setTimeout(() => {
                      setName(e.target.value)
                    }, 1000)
                  }}
                />
              </div>
            </div>
            <div>
              <div className="text-sm text-buttonTheme font-medium flex
                items-center gap-x-1 mb-1 mr-5 md:mr-0">
                {'Filter by Tag'}
              </div>

              <div className="w-[200px]">
                <CustomInput
                  placeholder={'Enter tag'}
                  className="custom-input"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value)
                    if (timeOut) {
                      clearTimeout(timeOut)
                    }
                    timeOut = setTimeout(() => {
                      setTag(e.target.value)
                    }, 1000)
                  }}
                />
              </div>
            </div>
            <div>
              <div className="text-sm text-buttonTheme font-medium flex
                items-center gap-x-1 mb-1 mr-5 md:mr-0">
                Select City
              </div>
              <div className="w-[200px]">
                <Select
                  allowClear
                  className="w-full"
                  status="blue"
                  rootClassName="border-red-500"
                  placeholder="Select City"
                  options={[
                    { label: 'London', value: 'London' },
                    { label: 'Paris', value: 'Paris' },
                    { label: 'Dubai', value: 'Dubai' },
                    { label: 'Algiers', value: 'Algiers' },
                  ]}
                  onChange={(e) => {
                    if (e) {
                      setCity(e)
                    } else {
                      setCity(null)
                    }
                  }}
                  value={city}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="checkout-container flex flex-wrap gap-3">
          <div>
            <div className="text-sm text-buttonTheme font-medium flex
              items-center gap-x-1 mb-1 mr-5 md:mr-0">
              Select Range
            </div>
            <div className="w-[200px]">
              <Select
                allowClear
                className="w-full"
                status="blue"
                rootClassName="border-red-500"
                placeholder="Select Range"
                options={[
                  { label: 'Last Month', value: '1' },
                  { label: 'Last 3 Months', value: '3' },
                  { label: 'Last 6 Months', value: '6' },
                  { label: 'Last 12 Months', value: '12' },
                ]}
                onChange={(e) => {
                  if (e) {
                    setMonths(e)
                    setDate(null)
                  } else {
                    setMonths(null)
                  }
                }}
                value={months}
              />
            </div>
          </div>
          <div>
            <div className="text-sm text-buttonTheme font-medium
              flex items-center gap-x-1 mb-1">
              Date
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={18}
                  height={18}
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M19.002 3h-14c-1.103 0-2 .897-2 2v4h2V5h14v14h-14v-4h-2v4c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.898-2-2-2z"
                  />
                  <path fill="currentColor" d="m11 16l5-4l-5-4v3.001H3v2h8z"/>
                </svg>
              </span>
            </div>
            <div className="flex items-center gap-x-1">
              {show && (
                <GenericButton
                  label="Today"
                  onClick={() => {
                    if (
                      formatDate(new Date(), 'dd MMM, yyyy') ===
                      formatDate(date, 'dd MMM, yyyy')
                    ) {
                      setDate(null)
                    } else {
                      setDate(new Date())
                      setMonths(null)
                    }
                    setPage(1)
                  }}
                  className={`py-1 rounded-md md:px-[15px] px-1 text-white inline-block ${
                    formatDate(new Date(), 'dd MMM, yyyy') ===
                    formatDate(date, 'dd MMM, yyyy')
                      ? 'bg-buttonHoverTheme hover:!bg-buttonHoverTheme hover:!text-white '
                      : 'bg-buttonTheme hover:!bg-buttonHoverTheme hover:!text-white '
                  }`}
                />
              )}
              <DatePicker.RangePicker
                labelName="check In date"
                className="h-8"
                size="small"
                disabledDate={disabledDate}
                picker="month"
                allowClear={true}
                value={date}
                onChange={(e, stringDate) => {
                  setDate(e)
                  setStringDate(stringDate)
                  setMonths(null)
                  setPage(1)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
