import { Modal, Upload, Button } from "antd";

function AttachmentModal({ openModal, setOpenModal }) {
  return (
    <Modal
      title="Uploads"
      centered
      open={openModal}
      onCancel={() => setOpenModal(false)}
      footer={null}
    >
      <Upload
        listType="picture-card"
        showUploadList={false}
        className="mt-5"
        onChange={() => setOpenModal(false)}
      >
        <>
          <span className="mt2 ml-3">
            Drop files to upload or
            <Button
              className="text-buttonTheme font-semibold mb-2 px-1 py-0 hover:!bg-transparent hover:!text-buttonHoverTheme"
              type="text"
            >
              Browse
            </Button>
          </span>
        </>
      </Upload>
    </Modal>
  );
}

export default AttachmentModal;
