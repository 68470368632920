import { useState } from "react";
import { Select, Space, Button, Checkbox } from "antd";

import Tasks from "..";
import manageTasksBG from "../../../assets/manageTasksBG.jpg";
import MoreFiltersModal from "./MoreFiltersModal";
import { archivedBody } from "../Archived/Archived";
import { manageAutoTasksBody } from "../ManageAutoTasks/manageAutoTasks";
import { checklistTemplatesBody } from "../ChecklistTemplates/ChecklistTemplates";

export const manageTasksBody = (setShowAddTask) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="h-screen">
      <div>
        <Space size={20} className="flex max-[640px]:flex-col flex-wrap">
          <div className="flex-col">
            <p>Listing</p>
            <Select
              className="w-60"
              placeholder="Listing"
              allowClear
              optionFilterProp="children"
              options={[
                { value: "jack", label: "Jack" },
                { value: "lucy", label: "Lucy" },
                { value: "tom", label: "Tom" },
              ]}
            />
          </div>
          <div className="flex-col">
            <p>Channel</p>
            <Select
              className="w-60"
              placeholder="Channel"
              allowClear
              optionFilterProp="children"
              options={[
                { value: "jack", label: "Jack" },
                { value: "lucy", label: "Lucy" },
                { value: "tom", label: "Tom" },
              ]}
            />
          </div>
          <div className="flex-col">
            <p>Assignee</p>
            <Select
              className="w-60"
              placeholder="Assignee"
              allowClear
              optionFilterProp="children"
              options={[
                { value: "jack", label: "Jack" },
                { value: "lucy", label: "Lucy" },
                { value: "tom", label: "Tom" },
              ]}
            />
          </div>
          <div className="flex-col">
            <p>Supervisor</p>
            <Select
              className="w-60"
              placeholder="Supervisor"
              allowClear
              optionFilterProp="children"
              options={[
                { value: "jack", label: "Jack" },
                { value: "lucy", label: "Lucy" },
                { value: "tom", label: "Tom" },
              ]}
            />
          </div>
          <div className="flex-col">
            <p>Sort order</p>
            <Select
              className="w-60"
              placeholder="Sort order"
              allowClear
              optionFilterProp="children"
              options={[
                { value: "jack", label: "Jack" },
                { value: "lucy", label: "Lucy" },
                { value: "tom", label: "Tom" },
              ]}
            />
          </div>
          <Button
            className="text-buttonTheme font-semibold mt-5 hover:!bg-transparent hover:!text-buttonHoverTheme"
            type="text"
            onClick={() => setOpenModal(true)}
          >
            More filters
          </Button>
        </Space>
      </div>
      <div className="flex flex-col justify-center items-center w-full h-[500px] gap-y-2">
        <img src={manageTasksBG} />
        <p className="font-bold text-xl">Things are more satisfying when they are organized</p>
        <p className="text-lg font-medium">
          Create tasks triggered kby events with due dates. You can assign tasks to an individual or to a group/company
        </p>
        <p className="text-base">You can also add reminders or assign tasks to yourself to do later on</p>
        <div>
          <Space>
            <Button
              type="primary"
              className="bg-buttonTheme font-semibold hover:!bg-buttonHoverTheme"
              onClick={() => setShowAddTask(true)}
            >
              Add task
            </Button>
            <Button type="primary" className="bg-labelGreen text-buttonTheme font-semibold hover:!bg-buttonHoverTheme">
              Download report
            </Button>
            <Checkbox>Apply current filter</Checkbox>
          </Space>
        </div>
      </div>
      <MoreFiltersModal openModal={openModal} setOpenModal={setOpenModal} />
    </div>
  );
};

function ManageTasks() {
  return (
    <Tasks
      manageTasksBody={manageTasksBody}
      manageAutoTasksBody={manageAutoTasksBody}
      checklistTemplatesBody={checklistTemplatesBody}
      archivedBody={archivedBody}
      activeKey={1}
    />
  );
}

export default ManageTasks;
