import {
  listingFinancialsEventOptions,
  userManagementAccessPermissionsShowOptions,
} from "../../../enum";
import FormCheckBox from "../../Form/FormCheckBox";
import FormSelect from "../../Form/FormSelect";
import { isAdminAccess } from "./Helper";
import UserGroup from "./UserGroup";
import OtherPermissions from "./OtherPermissions";

export default function AccessPermissions({
  formulaNameToShowTotalPriceForm,
  isDetails,
  form,
  groups,
  accessContactData,
  isAdmin,
}) {
  return (
    <div>
      <div className="flex flex-col mb-5 gap-2">
        <FormCheckBox
          disabled={isDetails}
          label="User has admin level access"
          name="isAdmin"
          toolTipFlag={true}
          tooltipTitle="An admin-level user has the same level of access as the account owner"
          onChange={(e) => {
            if (e.target.checked) {
              form.setFieldsValue(isAdminAccess);
            }
          }}
        />
        <FormCheckBox
          disabled={isDetails}
          label="Allow access to contact data"
          name="accessContactData"
        />
        <FormCheckBox
          disabled={isDetails}
          label="Allow access to financial data"
          name="accessFinancialData"
          toolTipFlag={true}
          tooltipTitle="Select this option if you want to give access to reservation price breakdowns, reservation financial fields, and formulas applying to reservations accessible by this calendar rates user."
        />
        <div className="grid md:grid-cols-2 grid-cols-1 gap-2 ">
          <FormSelect
            disabled={isDetails}
            toolTipFlag={true}
            tooltipTitle="If a formula is selected instead of the Total Price (default value), this formula will always be visible to this user. If ' allow access to financial data is selected this user will also see other financial information. otherwise, he will only see the selected formula"
            label="Show"
            bordered={false}
            name="formulaNameToShowTotalPriceForm"
            className="w-[40%]  border-2 border-creme"
            placeholder="Show"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            options={userManagementAccessPermissionsShowOptions}
          />

          {isDetails ? (
            <FormSelect
              disabled={isDetails}
              label="Event"
              name="formulaNameToShowTotalPriceEventType"
              bordered={false}
              className="w-[40%]  border-2 border-creme"
              placeholder="Event"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              options={listingFinancialsEventOptions}
            />
          ) : (
            formulaNameToShowTotalPriceForm && (
              <FormSelect
                disabled={isDetails}
                label="Event"
                name="formulaNameToShowTotalPriceEventType"
                bordered={false}
                className="w-[40%]  border-2 border-creme"
                placeholder="Event"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                options={listingFinancialsEventOptions}
              />
            )
          )}
        </div>
        <div className="mt-10">
          {/* <FormCheckBox disabled={isDetails} className=""
                    label="Allow access to Inquiries,request to book and cancelled reservations" name="accessAllReservations" toolTipFlag={true} tooltipTitle="If unticked, only new and modified reservations and owner stays may be visible" />
                <FormCheckBox disabled={isDetails} label="Allow access to newly added listings" name="hasAccessToNewListings" toolTipFlag={true} tooltipTitle="Every time a new listing is added, this user will have access to that listing by default." /> */}
          <FormCheckBox
            disabled={isDetails}
            label="Allow listing tag management"
            name="accessTagManagement"
            toolTipFlag={true}
            tooltipTitle="This option will allow users to see all the tags, create new ones and remove new ones."
          />
          <FormCheckBox
            disabled={isDetails}
            label="Allow access to guest notes"
            name="accessGuestNotes"
            toolTipFlag={true}
            tooltipTitle="Sometimes channels such as Booking.com may include some financial-related data in the guest notes. If you don't want to share them with this user, untick this option."
          />
        </div>
      </div>

      <UserGroup
        form={form}
        groups={groups}
        isAdmin={isAdmin}
        isDetails={isDetails}
      />
      <OtherPermissions
        accessContactData={accessContactData}
        isDetails={isDetails || isAdmin}
      />
    </div>
  );
}
