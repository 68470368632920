import { numberWithCommas } from '../../../utils/helper'

export const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id)
  let listContainer = legendContainer.querySelector('ul')
  const scrollTop = listContainer?.scrollTop || 0

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.className = `flex flex-row flex-wrap sm:flex-col 
      justify-center sm:justify-start sm:items-start sm:flex-nowrap w-full h-full 
      overflow-auto p-2 rounded-lg shadow-inset gap-2`
    listContainer.scrollTop = scrollTop

    legendContainer.appendChild(listContainer)
  }

  return { ul: listContainer, scrollTop }
}

export const generateHtmlLegendPlugin = (id, containerId) => {
  return {
    id,
    afterUpdate (chart, args, options) {
      const { ul, scrollTop } = getOrCreateLegendList(chart, options.containerID)

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove()
      }

      const items = chart.options.plugins.legend.labels.generateLabels(chart)

      items.forEach(item => {
        const li = document.createElement('li')
        li.style.alignItems = 'center'
        li.style.cursor = 'pointer'
        li.style.display = 'flex'
        li.style.flexDirection = 'row'

        li.onclick = () => {
          const {type} = chart.config
          if (type === 'pie' || type === 'doughnut') {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(item.index)
          } else {
            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex))
          }
          chart.update()
        };

        // Color box
        const boxSpan = document.createElement('span')
        boxSpan.style.background = item.fillStyle
        boxSpan.style.borderColor = item.strokeStyle
        boxSpan.style.borderWidth = item.lineWidth + 'px'
        boxSpan.style.display = 'inline-block'
        boxSpan.style.flexShrink = 0
        boxSpan.style.height = '18px'
        boxSpan.style.marginRight = '5px'
        boxSpan.style.width = '18px'
        boxSpan.style.borderRadius = '50%'

        // Text
        const textContainer = document.createElement('p')
        textContainer.style.color = item.fontColor
        textContainer.style.fontSize = '11px'
        textContainer.style.padding = 0
        textContainer.style.textDecoration = item.hidden ? 'line-through' : ''

        const labelEl = document.createElement('span')
        const valueEl = document.createElement('span')

        labelEl.style.fontWeight = 600
        labelEl.style.color = '#4e4f50'

        const label = document.createTextNode(item.label)
        const value = document.createTextNode(item.value)

        labelEl.appendChild(label)
        valueEl.appendChild(value)

        textContainer.appendChild(labelEl)
        textContainer.appendChild(valueEl)

        li.appendChild(boxSpan)
        li.appendChild(textContainer)
        ul.appendChild(li)
      });

      const jsLegend = document.getElementById(containerId)
      jsLegend.appendChild(ul)
      ul.scrollTop = scrollTop
    },
  }
}

export const generateLabels = (chart, getText) => {
  const pointStyle = chart.legend.options.labels.pointStyle
  const labels = chart.config._config.data.labels

  return labels.map((label, i) => {
    const meta = chart.getDatasetMeta(0)
    const style = meta.controller.getStyle(i)
    const value = numberWithCommas(
      chart.config._config.data.datasets[0].data[i])
    const { _label, _value } = getText(label, value)

    return {
      label: _label,
      value: _value,
      fillStyle: style.backgroundColor,
      strokeStyle: style.borderColor,
      lineWidth: style.borderWidth,
      pointStyle: pointStyle,
      fontColor: 'rgb(103, 106, 108)',
      hidden: !chart.getDataVisibility(i),
      index: i,
    }
  })
}

export const generateTooltipLabel = ({ parsed, dataset }) => {
  return dataset.label + ': ' + numberWithCommas(parsed) + '%'
}

export const getBookingChartLegendText = (label, value) => {
  return {
    _label: `${label}: `,
    _value: `${value}%`
  }
}

export const getRevenueChartLegendText = (label, value) => {
  return {
    _label: `${label.split(':')[0]}: `,
    _value: `£ ${value} (${label.split(':')[1]})`
  }
}