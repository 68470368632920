import React from 'react'
import { Descriptions } from 'antd'

import { numberWithCommas } from '../../../utils/helper'

export default function RoiCalculatorResultTable (props) {
  const { items } = props

  return (
    <div className="rounded-lg shadow-themeGreenCard overflow-hidden">
      <Descriptions
        column={1}
        className={`[&_.ant-descriptions-view]:!border-0 !w-full 
        [&_.ant-descriptions-row]:!border-0`}
        size="small"
        bordered
      >
        {items.map((item, index) => {
          let value = item.children
            ? numberWithCommas(item.children)
            : '0.00'

          if (item.text) {
            value = item.text
          }

          if (item.noValue) {
            value = ''
          }

          return (
            <Descriptions.Item
              labelStyle={{
                ...item?.labelStyles,
                textAlign: 'center',
                backgroundColor: item.bgColor || '#ffffff',
                fontWeight: 'bold',
                fontSize: '0.75rem',
                width: 210,
                padding: 5
              }}
              contentStyle={{
                ...item?.contentStyles,
                backgroundColor: item.bgColor || '#ffffff',
                fontSize: '0.75rem',
                padding: '5px 10px'
              }}
              label={item.label}
              key={index}
            >
              <div className={`flex justify-${item.text ? 'center' : 'between'} 
                items-center`}>
                <span>{item.hasCashSign ? '£ ' : ''}</span>

                <span>
                  {`${value}${item.withPercentSign ? '%' : ''}`}
                </span>
              </div>
            </Descriptions.Item>
          )
        })}
      </Descriptions>
    </div>
  )
}