/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import "./User.css";
import GenericButton from "../../../components/GenericButton/GenericButton";
import Groups2Icon from "@mui/icons-material/Groups2";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Avatar, Table } from "antd";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useNavigate } from "react-router-dom";
import { USER_MANAGEMENT_USERS } from "../../../constants/dashboardRoutes";
import DeleteAPIKeyModal from "../../../components/DeleteAPIKeyModal/DeleteAPIKeyModal";
import { CSVLink } from "react-csv";
import moment from "moment";
import { UserOutlined } from "@ant-design/icons";
import { apiInstance } from "../../../utils/API";
import Loading from "../../../components/Loaders/Loading";
import { AppContext } from "../../../AppContext";
import NoAccess from "../../../components/NoAccess";

export default function UserTable() {
  const navigate = useNavigate();
  const profile = useContext(AppContext).profile || {};
  const hasAccess = profile.isAdmin;
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    if (hasAccess) fetchUsers();
  }, []);

  async function fetchUsers() {
    setLoading(true);
    try {
      setLoading(true);
      let url = `/api/users`;
      const list = await apiInstance.get(url);
      setUsers(list.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const deleteUser = async (userId) => {
    try {
      await apiInstance.delete(`/api/users/${userId}`).then((response) => {
        if (response?.status === 200) {
          setIsDeleteModalOpen(false);
          fetchUsers();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: <div className="text-themeGrey font-normal text-sm">Avatar</div>,
      dataIndex: "avatar",
      key: "avatar",
      width: "10%",
      render: (_, record) => {
        return (
          <>
            {record?.avtar ? (
              <img
                src={record?.avtar}
                alt={record?.firstName}
                height={40}
                width={40}
              />
            ) : (
              <Avatar icon={<UserOutlined />} size={"large"} shape="square" />
            )}
          </>
        );
      },
    },
    {
      title: <div className="text-themeGrey font-normal text-sm">Name</div>,
      key: "firstName",
      width: "12%",
      render: (_, record) => {
        return (
          <Link to={`${USER_MANAGEMENT_USERS}/${record._id}/details`}>
            {record.firstName} {record.lastName}
          </Link>
        );
      },
    },
    {
      title: <div className="text-themeGrey font-normal text-sm">Email</div>,
      key: "email",
      dataIndex: "email",
      width: "25%",
    },
    {
      title: <div className="text-themeGrey font-normal text-sm">Title</div>,
      key: "title",
      dataIndex: "title",
      width: "7%",
    },
    {
      title: <div className="text-themeGrey font-normal text-sm">Group</div>,
      key: "group",
      dataIndex: "group",
      width: "13%",
    },
    {
      title: (
        <div className="text-themeGrey font-normal text-sm">
          Last Login (Local Time)
        </div>
      ),
      key: "lastLoginOn",
      dataIndex: "lastLoginOn",
      render: (_, record) => {
        return (
          <>
            {" "}
            {record.lastLoginOn
              ? moment().format("MMMM Do YYYY", record.lastLoginOn)
              : ""}
          </>
        );
      },
    },
    {
      title: <div className="text-themeGrey font-normal text-sm"></div>,
      key: "action",
      render: (_, record) => {
        return (
          <div className="flex items-center justify-end gap-8">
            <GenericButton
              label="Edit"
              className="bg-labelGreen text-themeGreen"
              iconLeftAlign={true}
              icon={<BorderColorIcon style={{ fontSize: 12 }} />}
              onClick={() => {
                navigate(`${USER_MANAGEMENT_USERS}/${record._id}`);
              }}
            />
            <GenericButton
              onClick={() => {
                setUserId(record._id);
                setIsDeleteModalOpen(true);
              }}
              label="Delete"
              className="bg-red-100 text-red-500"
              iconLeftAlign={true}
              icon={<DeleteIcon style={{ fontSize: 12 }} />}
            />
          </div>
        );
      },
    },
  ];

  const csvData = [
    [
      "Account ID",
      "User ID",
      "Name",
      "Email",
      "Title",
      "Country",
      "City",
      "Phone",
      "Timezone",
      "Emergency phone",
      "Preferred contact method",
      "Postal code",
      "Address",
      "Note before contact",
      "User has admin - level access",
      "Allow access to contact data",
      "Allow access to financial data",
      "Allow access for newly added listings",
      "Allow access to guest notes",
      "Create listing",
      "Read listing",
      "Update listing",
      "Delete listing",
      "Create reservation",
      "Read reservation",
      "Update reservation",
      "Delete reservation",
      "Create ownerstay",
      "Read ownerstay",
      "Update ownerstay",
      "Delete ownerstay",
      "Create task",
      "Read task",
      "Update task",
      "Delete task",
      "Create automation",
      "Read automation",
      "Update automation",
      "Delete automation",
      "Read calendar",
      "Update calendar",
      "Read bookingengine",
      "Update bookingengine",
      "Read revenuemanagement",
      "Read channelmanager",
      "Update channelmanager",
      "Read message",
      "Update message",
      "Update integration",
      "Update bulkeditlistings",
      "Delete guestinvoicing",
      "Create guestinvoicing",
      "Read guestinvoicing",
      "Update guestinvoicing",
      "Create review",
      "Read review",
      "Update review",
      "Delete review",
      "Create ownerstatement",
      "Read ownerstatement	",
      "Update ownerstatement",
      "Delete ownerstatement",
      "Create expense",
      "Read expense",
      "Update expense",
      "Delete expense",
      "Create guestbook",
      "Read guestbook",
      "Update guestbook",
      "Delete guestbook",
    ],
  ];

  users.forEach((element) => {
    csvData.push([
      element.accountId,
      element._id,
      (element.firstName || "") + " " + (element.lastName || ""),
      element.email,
      element.title,
      element.country,
      element.city,
      element.phone,
      element.timezone,
      element.emergencyPhone,
      element.preferredContactMethod,
      element.postalCode,
      element.Address,
      element.noteBeforeContact,
      element.isAdmin,
      element.accessContactData,
      element.accessFinancialData,
      element.hasAccessToNewListings,
      element.accessGuestNotes,
      element.createListing,
      element.readListing,
      element.updateListing,
      element.deleteListing,
      element.createReservation,
      element.readReservation,
      element.updateReservation,
      element.deleteReservation,
      element.createOwnerStay,
      element.readOwnerStay,
      element.updateOwnerStay,
      element.deleteOwnerStay,
      element.createTask,
      element.readTask,
      element.updateTask,
      element.deleteTask,
      element.createAutomation,
      element.readAutomation,
      element.updateAutomation,
      element.deleteAutomation,
      element.readCalendar,
      element.updateCalendar,
      element.readBookingEngine,
      element.updateBookingEngine,
      element.readRevenuemanagement,
      element.readChannelManager,
      element.updateChannelManager,
      element.readMessage,
      element.updateMessage,
      element.updateupdateIntegration,
      element.updateBulkEditListings,
      element.deleteGuestInvoicing,
      element.createGuestInvoicing,
      element.readGuestInvoicing,
      element.updateGuestInvoicing,
      element.createReview,
      element.readReview,
      element.updateReview,
      element.deleteReview,
      element.createOwnerStatement,
      element.readOwnerstatement,
      element.updateOwnerStatement,
      element.deleteOwnerStatement,
      element.createExpense,
      element.readExpense,
      element.updateExpense,
      element.deleteExpense,
      element.createGuestbook,
      element.readGuestbook,
      element.updateGuestbook,
      element.deleteGuestbook,
    ]);
  });

  if (profile?._id && !hasAccess) {
    return <NoAccess />;
  }

  return (
    <>
      <DeleteAPIKeyModal
        onOk={() => deleteUser(userId)}
        isModalOpen={isDeleteModalOpen}
        setIsModalOpen={setIsDeleteModalOpen}
      />

      <div id="user-table" className=" bg-white py-5">
        <div className="flex items-center justify-between mb-5 px-5 md:px-0">
          <GenericButton
            label={
              <p>
                {" "}
                <CSVLink data={csvData} filename={`users.csv`}>
                  CSV
                </CSVLink>
              </p>
            }
            className="text-white bg-themeGreen rounded-none"
            size="middle"
            iconLeftAlign={true}
            icon={
              <Groups2Icon style={{ fontSize: 16 }} className="mr-1 mb-[2px]" />
            }
          />
          <GenericButton
            label={<p>Add new</p>}
            className="text-white bg-themeGreen rounded-none"
            size="middle"
            iconLeftAlign={true}
            icon={
              <PersonAddIcon
                style={{ fontSize: 16 }}
                className="mr-1 mb-[2px]"
              />
            }
            onClick={() => {
              navigate(`${USER_MANAGEMENT_USERS}/add`);
            }}
          />
        </div>
        {loading ? (
          <div className="h-[50vh] bg-white rounded-lg flex justify-center items-center">
            <Loading />
          </div>
        ) : users && users.length > 0 ? (
          <Table
            className="border-t border-creme"
            columns={columns}
            dataSource={users}
            scroll={{
              x: 1000,
            }}
          />
        ) : (
          <div className="text-center capitalize">No user found</div>
        )}
      </div>
    </>
  );
}
