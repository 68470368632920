/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import {
  Modal,
  Table,
  Select,
  Input,
  DatePicker,
  Checkbox,
} from 'antd'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import { format } from 'date-fns'
import { Icon } from '@iconify-icon/react'
import { EditOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import * as XLSX from 'xlsx'
import dayjs from 'dayjs'

import NoAccess from '../../components/NoAccess'
import Loading from '../../components/Loaders/Loading'
import { manageToast } from '../../components/Common/ManageToast'
import GenericButton from '../../components/GenericButton/GenericButton'
import CleaningDetails from '../../components/Cleaning/CleaningDetails'
import Actions from '../../components/Cleaning/Actions'
import CustomDropdown from '../../components/Dropdown/Dropdown'

import { apiInstance, routes } from '../../utils/API'
import { notificationMessage } from '../../utils'
import { paginationItemRender } from '../../utils/table'

import { AppContext } from '../../AppContext'

import CleanersCell from './CleanersCell'

let timeOut = null

const RESERVATION_STATUS_OPTIONS = [
  {
    label: 'Same day check-in',
    value: 'Same day check-in'
  },
  {
    label: 'No Same day check-in',
    value: 'No Same day check-in'
  },
  {
    label: 'Mid-Stay',
    value: 'Mid-Stay'
  },
  {
    label: 'Bin collection',
    value: 'Bin collection'
  },
  {
    label: 'Communal Only',
    value: 'Communal Only'
  }
]

const checkIfCommunalOrBin = status => {
  return status === 'Bin collection' || status === 'Communal Only'
}

const renderCell = (value, cleaning) => {
  if (checkIfCommunalOrBin(cleaning.reservationStatus)) {
    return (
      <p className="text-center text-xs">--</p>
    )
  }

  return <p className="text-center text-xs">{value || '--'}</p>
}

const daysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

const numberOfBedsAndTowelsLabels = {
  '0-bed': {
    '1-guest': {
      numberOfBedsToMake: '1 bed',
      numberOfTowels: 'Towels for 2 people',
    },
    '2-guest': {
      numberOfBedsToMake: '1 bed',
      numberOfTowels: 'Towels for 2 people',
    },
    '3-guest': {
      numberOfBedsToMake: '1 bed + 1 Guest Bed',
      numberOfTowels: 'Towels for 3 people',
    },
    'default': {
      numberOfBedsToMake: '1 bed + 1 Guest Bed',
      numberOfTowels: 'Towels for 3 people',
    },
  },
  '1-bed': {
    '1-guest': {
      numberOfBedsToMake: '1 bed',
      numberOfTowels: 'Towels for 2 people',
    },
    '2-guest': {
      numberOfBedsToMake: '1 bed + 1 Guest Bed',
      numberOfTowels: 'Towels for 2 people',
    },
    '3-guest': {
      numberOfBedsToMake: '1 bed + 2 Guest Beds',
      numberOfTowels: 'Towels for 3 people',
    },
    '4-guest': {
      numberOfBedsToMake: '1 bed + 2 Guest Beds',
      numberOfTowels: 'Towels for 4 people',
    },
    'default': {
      numberOfBedsToMake: '1 bed + 2 Guest Beds',
      numberOfTowels: 'Towels for 4 people',
    },
  },
  '2-bed': {
    '1-guest': {
      numberOfBedsToMake: '2 beds',
      numberOfTowels: 'Towels for 2 people',
    },
    '2-guest': {
      numberOfBedsToMake: '2 beds',
      numberOfTowels: 'Towels for 3 people',
    },
    '3-guest': {
      numberOfBedsToMake: '2 beds + 1 Guest Bed',
      numberOfTowels: 'Towels for 4 people',
    },
    '4-guest': {
      numberOfBedsToMake: '2 beds + 1 Guest Bed',
      numberOfTowels: 'Towels for 4 people',
    },
    '5-guest': {
      numberOfBedsToMake: '2 beds + 1 Guest Bed',
      numberOfTowels: 'Towels for 5 people',
    },
    'default': {
      numberOfBedsToMake: '2 beds + 1 Guest Bed',
      numberOfTowels: 'Towels for 6 people',
    },
  },
  '3-bed': {
    '1-guest': {
      numberOfBedsToMake: '3 beds',
      numberOfTowels: 'Towels for 3 people',
    },
    '2-guest': {
      numberOfBedsToMake: '3 beds',
      numberOfTowels: 'Towels for 3 people',
    },
    '3-guest': {
      numberOfBedsToMake: '3 beds ',
      numberOfTowels: 'Towels for 3 people',
    },
    '4-guest': {
      numberOfBedsToMake: '3 beds + 1 Guest Bed',
      numberOfTowels: 'Towels for 4 people',
    },
    '5-guest': {
      numberOfBedsToMake: '3 beds + 2 Guest Beds',
      numberOfTowels: 'Towels for 5 people',
    },
    '6-guest': {
      numberOfBedsToMake: '3 beds + 2 Guest Beds',
      numberOfTowels: 'Towels for 6 people',
    },
    '7-guest': {
      numberOfBedsToMake: '3 beds + 2 Guest Beds',
      numberOfTowels: 'Towels for 7 people',
    },
    'default': {
      numberOfBedsToMake: '3 beds + 2 Guest Beds',
      numberOfTowels: 'Towels for 7 people',
    },
  },
  '4-bed': {
    '1-guest': {
      numberOfBedsToMake: '4 beds',
      numberOfTowels: 'Towels for 4 people',
    },
    '2-guest': {
      numberOfBedsToMake: '4 beds',
      numberOfTowels: 'Towels for 4 people',
    },
    '3-guest': {
      numberOfBedsToMake: '4 beds',
      numberOfTowels: 'Towels for 4 people',
    },
    '4-guest': {
      numberOfBedsToMake: '4 beds',
      numberOfTowels: 'Towels for 4 people',
    },
    '5-guest': {
      numberOfBedsToMake: '4 beds + 1 Guest Bed',
      numberOfTowels: 'Towels for 5 people',
    },
    '6-guest': {
      numberOfBedsToMake: '4 beds + 2 Guest Beds',
      numberOfTowels: 'Towels for 6 people',
    },
    '7-guest': {
      numberOfBedsToMake: '4 beds + 2 Guest Beds',
      numberOfTowels: 'Towels for 7 people',
    },
    '8-guest': {
      numberOfBedsToMake: '4 beds + 2 Guest Beds',
      numberOfTowels: 'Towels for 8 people',
    },
    '9-guest': {
      numberOfBedsToMake: '4 beds + 2 Guest Beds',
      numberOfTowels: 'Towels for 9 people',
    },
    'default': {
      numberOfBedsToMake: '4 beds + 2 Guest Beds',
      numberOfTowels: 'Towels for 9 people',
    },
  },
}

const normalizeCleaningsCollection = (cleanings) => {
  if (!cleanings?.length) {
    return []
  }

  return cleanings.map((item, i) => {
    const numberOfBeds = Number(
      item.allocatedProperty.split(' ')[0].replaceAll('B', ''),
    )
    const numberOfGuests = item?.nb_guest
      ? Number(item.nb_guest)
      : undefined
    let numberOfBedsToMake
    let numberOfTowels

    if (numberOfGuests) {
      numberOfBedsToMake = numberOfBedsAndTowelsLabels?.[numberOfBeds +
        '-bed']?.[numberOfGuests + '-guest']?.numberOfBedsToMake

      numberOfTowels = numberOfBedsAndTowelsLabels?.[numberOfBeds +
        '-bed']?.[numberOfGuests + '-guest']?.numberOfTowels
    } else {
      // Fallback to the default option if numberOfGuests is not provided
      numberOfBedsToMake = numberOfBedsAndTowelsLabels?.[numberOfBeds + '-bed']?.['default']?.numberOfBedsToMake;
      numberOfTowels = numberOfBedsAndTowelsLabels?.[numberOfBeds + '-bed']?.['default']?.numberOfTowels;
    }

    let nextReservation = 'TBC'
    let next = ''
    if (item?.nextReservation) {
      next = format(
        new Date(item.nextReservation || ''),
        'dd/MM/yyyy',
      )
    }
    if (item?.nextReservation && next !== '01/01/3000')
      nextReservation = next
    let data = {
      ...item,
      keyStatus: item.key || '--',
      key: i,
      checkOutDate: item?.checkOutDate
        ? format(new Date(item?.checkOutDate), 'dd/MM/yyyy')
        : '--',
      nextReservation: nextReservation || '',
      nights: item?.nights ? item?.nights : '--',
      reservationStatus: item?.reservationStatus
        ? item.reservationStatus
        : '--',
      numberOfGuests: numberOfGuests || '--',
      numberOfBedsToMake: numberOfBedsToMake || 'All beds',
      numberOfTowels: numberOfTowels || '--',
    }

    return {
      ...data,
    }
  })
}

const Cleaning = () => {
  const profile = React.useContext(AppContext).profile || {}
  const isAdmin = profile.isAdmin
  const [cleanings, setCleanings] = React.useState([])
  const [total, setTotal] = React.useState(0)
  const [reservationStatus, setReservationStatus] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [pageSize, setPageSize] = React.useState(50)
  const [page, setPage] = React.useState(1)
  const [view, setView] = React.useState('today')
  const [type, setType] = React.useState('all')
  const [syncLoading, setSyncLoading] = React.useState(false)
  const [checkOutOrder, setCheckOutOrder] = React.useState('')
  const [infoModal, setInfoModal] = React.useState(null)
  const [handleModal, setHandleModal] = React.useState(false)
  const [listings, setListings] = React.useState([])
  const [cleaningDetails, setCleaningDetails] = React.useState({})
  const [error, setError] = React.useState(false)
  const [recurringEndError, setRecurringEndError] = React.useState('')
  const [cleanerNameList, setCleanerNameList] = React.useState([])
  const [editId, setEditId] = React.useState('')
  const [cleanersModal, setCleanersModal] = React.useState(false)
  const [cleanerName, setCleanerName] = React.useState('')
  const [cleaners, setCleaners] = React.useState([])
  const [searchValue, setSearchValue] = React.useState('')
  const [selectedManualReservationStatus, setSelectedManualReservationStatus] =
    React.useState(null)
  const [checkOutTimeOrder, setCheckOutTimeOrder] = React.useState('')
  const [statusOrder, setStatusOrder] = React.useState('')
  const [nextCheckInOrder, setNextCheckInOrder] = React.useState('')
  const [
    selectedManualReservationStatusValue,
    setSelectedManualReservationStatusValue,
  ] = React.useState(null)
  const [
    isManualReservationStatusEditable,
    setIsManualReservationStatusEditable,
  ] = React.useState(false)
  const [isRecordUpdated, setIsRecordUpdated] = React.useState(false)
  const [recurringDays, setRecurringDays] = React.useState([])
  const [selectedEndOption, setSelectedEndOption] = React.useState('')
  const [isRecurringCleaning, setIsRecurringCleaning] = React.useState(false)

  const handleSetRecurringDay = (item) => {
    if (recurringDays.includes(item)) {
      setRecurringDays(recurringDays.filter((el) => el !== item))
      return
    }

    setRecurringDays((prev) => [...prev, item])
  }

  React.useEffect(() => {
    if (profile.readCleaning) {
      getCleanings({ pageSize })
    }
  }, [profile.readCleaning, isRecordUpdated])

  React.useEffect(() => {
    getCleanerNameList()
  }, [])

  React.useEffect(() => {
    (async () => {
      const response = await apiInstance.get(routes.GET_LISTING_NAMES)
      setListings(
        response.data.listings.map((item) => ({
          label: item.internalListingName,
          value: `${item.internalListingName}---${item.address}`,
        })),
      )
      const cleaners = await apiInstance.get(routes.GET_CLEANER)
      setCleaners(cleaners.data.cleaners)
    })()
  }, [])

  const cleaningsCollection = React.useMemo(() => {
    return normalizeCleaningsCollection(cleanings)
  }, [cleanings])

  const getCleanerNameList = async (params = {}) => {
    try {
      setLoading(true)
      apiInstance.get(`/api/cleaning/get-all-cleaners`).then((response) => {
        if (response.status === 200) {
          setCleanerNameList(response?.data)
          setLoading(false)
        }
      }).catch((error) => console.log(error))
    } catch (error) {
      console.log(error)
    }
  }

  const getCleanings = async (params = {}) => {
    try {
      setLoading(true)
      let pageSize_ = pageSize
      let page_ = page
      let type_ = type
      let view_ = view
      let search_ = searchValue
      let checkOutOrder_
      let checkOutTimeOrder_
      let statusOrder_
      let nextCheckInOrder_

      if (params.hasOwnProperty('search')) {
        search_ = params.search
      }

      if (params.hasOwnProperty('pageSize')) {
        pageSize_ = params.pageSize
        setPageSize(params.pageSize)
      }

      if (params.hasOwnProperty('type')) {
        type_ = params.type || ''
        setType(params.type || '')
      }

      if (params.hasOwnProperty('view')) {
        view_ = params.view || ''
        setView(params.view || '')
      }

      if (params.hasOwnProperty('checkOutOrder')) {
        checkOutOrder_ = params.checkOutOrder || ''
        setCheckOutOrder(params.checkOutOrder || '')
        setCheckOutTimeOrder('')
        setStatusOrder('')
        setNextCheckInOrder('')
      }

      if (params.hasOwnProperty('checkOutTimeOrder')) {
        checkOutTimeOrder_ = params.checkOutTimeOrder || ''
        setCheckOutTimeOrder(params.checkOutTimeOrder || '')
        setCheckOutOrder('')
        setStatusOrder('')
        setNextCheckInOrder('')
      }

      if (params.hasOwnProperty('statusOrder')) {
        statusOrder_ = params.statusOrder || ''
        setStatusOrder(params.statusOrder || '')
        setCheckOutOrder('')
        setCheckOutTimeOrder('')
        setNextCheckInOrder('')
      }

      if (params.hasOwnProperty('nextCheckInOrder')) {
        nextCheckInOrder_ = params.nextCheckInOrder || ''
        setNextCheckInOrder(params.nextCheckInOrder || '')
        setCheckOutOrder('')
        setCheckOutTimeOrder('')
        setStatusOrder('')
      }

      if (params.hasOwnProperty('page')) {
        page_ = params.page
        setPage(params.page)
      } else {
        page_ = 1
        setPage(1)
      }

      let url = ''
      if (pageSize_) {
        url = url + 'pageSize=' + pageSize_ + '&'
      }
      if (view) {
        url = url + 'view=' + view_ + '&'
      }
      if (checkOutOrder_) {
        url = url + 'checkOutOrder=' + checkOutOrder_ + '&'
      }
      if (checkOutTimeOrder_) {
        url = url + 'checkOutTimeOrder=' + checkOutTimeOrder_ + '&'
      }

      if (statusOrder_) {
        url = url + 'statusOrder=' + statusOrder_ + '&'
      }

      if (nextCheckInOrder_) {
        url = url + 'nextCheckInOrder=' + nextCheckInOrder_ + '&'
      }

      if (type_ !== 'all') {
        url = url + 'type=' + type_ + '&'
      }
      if (search_) {
        url = url + 'search=' + search_ + '&'
      }

      apiInstance.get(`/api/cleaning?page=${page_}&${url}`).then((response) => {
        if (response.status === 200) {
          setCleanings(response?.data?.items || [])
          setTotal(response?.data?.meta?.total || 0)
          setLoading(false)
        }
      }).catch((error) => console.log(error))
    } catch (error) {
      console.log(error)
    }
  }

  const syncData = async () => {
    try {
      setSyncLoading(true)
      manageToast(
        true,
        'Sync is started. It will take some time. You can still continue browsing.',
      )
      const res = await apiInstance.post(routes.SYNC_CLEANINGS)
      if (res.data.status === 200) {
      } else {
      }
      setTimeout(() => {
        getCleanings({ pageSize })
        setSyncLoading(false)
      }, 300000)
    } catch (error) {
      if (error) {
        setTimeout(() => {
          manageToast(false, 'Server Error. Someting went wrong')
          setSyncLoading(false)
        }, 300010)
      }
    }
  }

  const updateCleaningStatus = async (id) => {
    try {
      // Validation - If no manual reservation value is selected.
      if (!selectedManualReservationStatusValue) {
        notificationMessage('error', 'Please select a status value!')
        return
      }

      const response = await apiInstance.put(`/api/cleaning/updatecleanings`, {
        id: id,
        status: selectedManualReservationStatusValue,
      })

      // Success case.
      if (response?.status === 200) {
        let tempCleanings = [...cleanings]

        tempCleanings = tempCleanings.map((item) => {
          if (item._id === id) {
            item.status = selectedManualReservationStatusValue
          }
          return item
        })
        setCleanings(tempCleanings)

        notificationMessage('success', `Status has been updated!`)
        setSelectedManualReservationStatus(null)
        setIsManualReservationStatusEditable(
          !isManualReservationStatusEditable,
        )
        setIsRecordUpdated(!isRecordUpdated)
        return
      }

      // Fail case.
      notificationMessage('error', 'Error updating the status!')
    } catch (error) {
      notificationMessage('error', 'Error updating the status!')
      console.error(error)
    }
  }

  const columns = [
    {
      title: 'Property',
      dataIndex: 'allocatedProperty',
      key: 'allocatedProperty',
      width: 250,
      render: (_, record) => {
        return (
          <p
            className="font-bold text-themeGreen cursor-pointer text-sx"
            onClick={() => {
              setInfoModal(record)
            }}
          >
            {record.allocatedProperty}
          </p>
        )
      },
    },
    {
      title: 'Address',
      dataIndex: 'mondayPropertyAddress',
      key: 'mondayPropertyAddress',
      width: 250,
      render: (_, record) => {
        return record.address === 'Address not found' &&
          record.city !== 'Londaon' ? (
          '--'
        ) : (
          <p className="font-bold text-xs">{record.address}</p>
        )
      },
    },
    {
      title: (
        <div
          className={`flex items-center justify-center gap-x-1 cursor-pointer test-xs ${checkOutOrder === 'asc' || checkOutOrder === 'desc'
            ? 'text-buttonTheme'
            : ''
            }`}
        >
          Check-Out
          <span
            className="flex items-center justify-center"
            style={{
              transform:
                checkOutOrder != 'desc' ? 'rotateX(180deg)' : 'rotateX(0deg)',
            }}
            onClick={() => {
              if (checkOutOrder === 'asc') {
                getCleanings({ checkOutOrder: 'desc' })
              } else {
                getCleanings({ checkOutOrder: 'asc' })
              }
            }}
          >
            <Icon icon="prime:sort-amount-up" height={24} width={24} />
          </span>
        </div>
      ),
      dataIndex: 'checkOutDate',
      key: 'checkOutDate',
      width: 140,
      render: (_, record) => (
        <p className="text-center text-xs">
          {record?.cleaningScheduled && record?.added === 'Manually'
            ? format(new Date(record.cleaningScheduled), 'dd/MM/yyyy')
            : record.checkOutDate}
        </p>
      )
    },
    {
      title: (
        <div
          className={`flex items-center justify-center gap-x-1 cursor-pointer test-xs ${checkOutTimeOrder === 'asc' || checkOutTimeOrder === 'desc'
            ? 'text-buttonTheme'
            : ''
            }`}
        >
          Check-Out time
          <span
            className="flex items-center justify-center"
            style={{
              transform:
                checkOutTimeOrder != 'desc'
                  ? 'rotateX(180deg)'
                  : 'rotateX(0deg)',
            }}
            onClick={() => {
              if (checkOutTimeOrder === 'asc') {
                getCleanings({ checkOutTimeOrder: 'desc' })
              } else {
                getCleanings({ checkOutTimeOrder: 'asc' })
              }
            }}
          >
            <Icon icon="prime:sort-amount-up" height={24} width={24} />
          </span>
        </div>
      ),
      dataIndex: 'checkOutTime',
      key: 'checkOutTime',
      width: 170,
      render: (_, record) => {
        return <p className="text-center text-xs">{record.checkOutTime}</p>
      },
    },
    {
      title: (
        <div
          className={`flex items-center justify-center gap-x-1 cursor-pointer test-xs ${statusOrder === 'asc' || statusOrder === 'desc'
            ? 'text-buttonTheme'
            : ''
            }`}
        >
          Status
          <span
            className="flex items-center justify-center"
            style={{
              transform:
                statusOrder != 'desc' ? 'rotateX(180deg)' : 'rotateX(0deg)',
            }}
            onClick={() => {
              if (statusOrder === 'asc') {
                getCleanings({ statusOrder: 'desc' })
              } else {
                getCleanings({ statusOrder: 'asc' })
              }
            }}
          >
            <Icon icon="prime:sort-amount-up" height={24} width={24} />
          </span>
        </div>
      ),
      dataIndex: 'reservationStatus',
      key: 'reservationStatus',
      width: isManualReservationStatusEditable ? 350 : 150,
      render: (_, record) => {
        let color = ''
        let textColor = ''
        if (record.reservationStatus === 'Same day check-in') {
          color = 'bg-[#FF645F]'
          textColor = 'text-[#FFFFFF]'
        } else if (record.reservationStatus === 'Manual check') {
          color = 'bg-[#964B00]'
          textColor = 'text-[#FFFFFF]'
        } else if (record.reservationStatus === 'No Same day check-in') {
          color = 'bg-[#ADD8E6]'
          textColor = 'text-[#000000]'
        } else {
          color = 'bg-[#FFA500]'
          textColor = 'text-[#000000]'
        }
        return (
          <div className="flex items-center justify-center gap-2">
            {record.reservationStatus !== 'Manual check' && (
              <div
                className={`flex items-center justify-center ${textColor} ${color} p-1 rounded-lg`}
              >
                <p className="text-center text-xs">
                  {record.reservationStatus}
                </p>
              </div>
            )}

            {record.reservationStatus === 'Manual check' && (
              <div className="flex gap-2">
                {isManualReservationStatusEditable &&
                  record._id === selectedManualReservationStatus ? (
                  <div className="w-48">
                    <Select
                      className="rounded-none w-full"
                      placeholder="Select Status"
                      onChange={(e) => {
                        setSelectedManualReservationStatusValue(e)
                      }}
                      value={selectedManualReservationStatusValue}
                      showArrow
                      options={[
                        {
                          label: 'Same day check-in',
                          value: 'Same day check-in',
                          key: 'Same day check-in',
                        },
                        {
                          label: 'No Same day check-in',
                          value: 'No Same day check-in',
                          key: 'No Same day check-in',
                        },
                        {
                          label: 'Bin collection',
                          value: 'Bin collection',
                          key: 'Bin collection'
                        },
                        {
                          label: 'Communal Only',
                          value: 'Communal Only',
                          key: 'Communal Only'
                        }
                      ]}
                    />
                  </div>
                ) : (
                  <div
                    className={`flex items-center justify-center ${textColor} ${color} p-1 rounded-lg`}
                  >
                    <p className="text-center text-xs">
                      {record.reservationStatus}
                    </p>
                  </div>
                )}
                {isManualReservationStatusEditable &&
                  record._id === selectedManualReservationStatus ? (
                  <div className="flex items-center gap-2">
                    <div
                      className="text-buttonTheme hover:text-buttonHoverTheme cursor-pointer font-bold"
                      onClick={() => {
                        updateCleaningStatus(record._id)
                      }}
                    >
                      Save
                    </div>
                    <div
                      className="text-buttonTheme hover:text-buttonHoverTheme cursor-pointer font-bold"
                      onClick={() => {
                        setSelectedManualReservationStatusValue(null)
                        setSelectedManualReservationStatus(null)
                        setIsManualReservationStatusEditable(
                          !isManualReservationStatusEditable,
                        )
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                ) : (
                  <GenericButton
                    icon={<EditOutlined />}
                    className="capitalize bg-buttonTheme text-white
                    hover:bg-buttonHoverTheme rounded-md h-7 w-4 flex
                    justify-center items-center mt-1.5"
                    onClick={() => {
                      setSelectedManualReservationStatus(record._id)
                      setIsManualReservationStatusEditable(
                        !isManualReservationStatusEditable,
                      )
                    }}
                  />
                )}
              </div>
            )}
          </div>
        )
      },
    },
    {
      title: 'Portfolio Name',
      dataIndex: 'portfolio',
      key: 'portfolio',
      render: (_, record) => record?.portfolio || '--',
      width: 150,
      align: 'center'
    },
    {
      title: (
        <div
          className={`flex items-center justify-center gap-x-1 cursor-pointer test-xs ${nextCheckInOrder === 'asc' || nextCheckInOrder === 'desc'
            ? 'text-buttonTheme'
            : ''
            }`}
        >
          Next Check-in
          <span
            className="flex items-center justify-center"
            style={{
              transform:
                nextCheckInOrder != 'desc'
                  ? 'rotateX(180deg)'
                  : 'rotateX(0deg)',
            }}
            onClick={() => {
              if (nextCheckInOrder === 'asc') {
                getCleanings({ nextCheckInOrder: 'desc' })
              } else {
                getCleanings({ nextCheckInOrder: 'asc' })
              }
            }}
          >
            <Icon icon="prime:sort-amount-up" height={24} width={24} />
          </span>
        </div>
      ),
      dataIndex: 'nextReservation',
      key: 'nextReservation',
      width: 160,
      render: renderCell,
    },
    {
      title: (
        <p className="text-center">
          Next booking length
        </p>
      ),
      dataIndex: 'nights',
      key: 'nights',
      width: 150,
      render: renderCell,
    },
    {
      title: (
        <p className="text-center">
          Number of guests
        </p>
      ),
      dataIndex: 'numberOfGuests',
      key: 'numberOfGuests',
      render: renderCell,
      width: 100,
    },
    {
      title: (
        <p className="text-center">
          Number of beds to make
        </p>
      ),
      dataIndex: 'numberOfBedsToMake',
      key: 'numberOfBedsToMake',
      render: renderCell,
      width: 200,
    },
    {
      title: (
        <p className="text-center">
          Number of towels
        </p>
      ),
      dataIndex: 'numberOfTowels',
      key: 'numberOfTowels',
      render: renderCell,
      width: 200,
    },
    {
      title: (
        <p className="text-center">
          Efforts
        </p>
      ),
      dataIndex: 'cleaningEffort',
      key: 'cleaningEffort',
      width: 120,
      render: renderCell,
    },
    {
      title: (
        <p className="text-center">
          Keys
        </p>
      ),
      dataIndex: 'keyStatus',
      key: 'keyStatus',
      width: 120,
      render: (status) => {
        return <p className="text-center text-xs">{status}</p>
      },
    },
    {
      title: 'Cleaners Name',
      dataIndex: 'cleanerName',
      key: 'cleanerName',
      align: 'center',
      width: !!editId ? 300 : 150,
      render: (_, record) => (
        <CleanersCell
          handleSaveClick={handleSaveButtonClick}
          cleaners={cleaners}
          setEditId={setEditId}
          {...record}
        />
      ),
    },
  ]

  const handleSaveButtonClick = async (cleaners, id, handleReset) => {
    setLoading(true)
    await apiInstance.put(`/api/cleaning/update-cleaner/${id}`, {
      cleanerName: cleaners,
    }).then(response => {
      if (response.status === 200) {
        getCleanings({ pageSize })
        getCleanerNameList()
        manageToast(true, 'Cleaner name updated successfully')
        setLoading(false)
        handleReset()
      } else {
        manageToast(false, 'Failed to update cleaner name')
        setLoading(false)
        handleReset()
      }
    }).catch(() => {
      manageToast(false, 'Error updating cleaner name')
      setLoading(false)
      handleReset()
    })
  }

  const handleDataModification = () => {
    let keys = [
      'allocatedProperty',
      'guest',
      'address',
      'checkInDate',
      'checkOutDate',
      'checkOutTime',
      'reservationStatus',
      'nextReservation',
      'nights',
      'cleaningEffort',
      'keyStatus',
      'city',
      'cleanerName',
      'status',
      'comment',
    ]
    let displays = [
      'Property',
      'Guest',
      'Address',
      'Check-in Date',
      'Check-out',
      'Check-out Time',
      'Status',
      'Next Check-in',
      'Next booking length',
      'Efforts',
      'Keys',
      'City',
      'Cleaners Name',
      'Actions',
      'Comment',
    ]
    let values = []
    cleanings.forEach((item, index) => {
      let dataArray = []
      for (let value of keys) {
        if (item[value]) {
          if (value === 'nextReservation') {
            if (item[value].includes('01/01/3000')) {
              dataArray.push('TBC')
            } else {
              dataArray.push(dayjs(item[value]).format('DD/MM/YYYY'))
            }
          } else if (value === 'cleanerName') {
            if (item[value].length > 0) {
              dataArray.push(item[value].join(', '))
            } else {
              dataArray.push('No Cleaners')
            }
          } else if (value === 'checkInDate' || value === 'checkOutDate') {
            dataArray.push(dayjs(item[value]).format('DD/MM/YYYY'))
          } else {
            dataArray.push(item[value])
          }
        } else {
          dataArray.push('--')
        }
      }
      values.push(dataArray)
    })
    return [[...displays], ...values]
  }

  const handleDownloadCSV = () => {
    const ws = XLSX.utils.aoa_to_sheet(handleDataModification())
    ws['!cols'] = [
      { wch: 30 },
      { wch: 30 },
      { wch: 55 },
      { wch: 12 },
      { wch: 12 },
      { wch: 12 },
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
      { wch: 5 },
      { wch: 20 },
    ]
    for (let r = 1; r <= cleanings.length; r++) {
      const cellRef = XLSX.utils.encode_cell({ c: 2, r: r }) // Column C
      if (!ws[cellRef]) ws[cellRef] = {}
      ws[cellRef].s = { alignment: { horizontal: 'right' } }
    }

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1')
    XLSX.writeFile(wb, 'cleanings.xlsx', { bookType: 'xlsx', type: 'blob' })
  }

  if (profile.updateCleaning) {
    columns.push({
      title: 'Actions',
      dataIndex: 'status',
      key: 'status',
      width: 200,
      align: 'center',
      render: (_, record) => {
        return (
          <Actions
            infoModal={infoModal}
            setInfoModal={setInfoModal}
            record={record}
            onSave={() => {
              getCleanings({ pageSize })
            }}
          />
        )
      },
    })
  }

  const handleOk = async () => {
    setRecurringEndError('')
    try {
      if (
        !cleaningDetails?.allocatedProperty ||
        (!isRecurringCleaning && !cleaningDetails?.cleaningScheduled) ||
        !cleaningDetails?.cleaningEffort
      ) {
        setError(true)
        return
      }

      if (
        isRecurringCleaning &&
        !cleaningDetails.recurringEndDate &&
        !cleaningDetails.recurringOccurences
      ) {
        setRecurringEndError('Select end date of recurring cleaning.')
        return
      }

      if (
        isRecurringCleaning &&
        cleaningDetails.recurringPeriod === 'Week' &&
        !recurringDays.length
      ) {
        setRecurringEndError('Please select days of week.')
        return
      }

      if (
        isRecurringCleaning &&
        cleaningDetails.recurringPeriod === 'Month' &&
        !cleaningDetails.monthlyPeriod
      ) {
        setRecurringEndError('Please select day of month.')
        return
      }

      if (!reservationStatus) {
        setRecurringEndError('Please select a status.')
        return
      }

      const newCleaningDetails = {
        reservationStatus,
        isRecurringCleaning,
        weekDays: recurringDays,
        ...cleaningDetails,
        recurringEndDate: cleaningDetails.recurringEndDate
          ? cleaningDetails.recurringEndDate
          : '',
        recurringOccurences:
          selectedEndOption === 'after'
            ? cleaningDetails.recurringOccurences
            : 0,
      }

      await apiInstance.post(
        `/api/cleaning/createCleaning?userName=${localStorage.getItem(
          'userName',
        )}`,
        newCleaningDetails,
      )
      setHandleModal(false)
      setCleaningDetails({})
      setIsRecurringCleaning(false)
      setReservationStatus(null)
      setSelectedEndOption('')
      setRecurringEndError('')
      setError('')
      toast.success('Cleaning added Successfully!', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })

      getCleanings({ view })
    } catch (error) {
      toast.error(error.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }
  }

  const handleCancel = () => {
    setHandleModal(false)
    setError(false)
    setCleaningDetails({})
    setCleanersModal(false)
    setCleanerName('')
  }

  const handleSaveCleaner = async () => {
    const res = await apiInstance.post(routes.CREATE_CLEANER, {
      name: cleanerName,
    })
    let cleanerList = [...cleaners]
    cleanerList.push({ name: cleanerName })
    setCleaners(cleanerList)
    if (res.status === 200) {
      toast.success('Cleaner added successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
      setCleanersModal(false)
      setCleanerName('')
    } else {
      toast.error('Could not create cleaner. Please try again later.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }
  }

  const handleCleanerName = (e) => {
    setCleanerName(e.target.value)
  }

  if (profile._id && !profile.readCleaning) {
    return <NoAccess />
  }

  const disabledDate = (current) => {
    const yesterday = dayjs().subtract(1, 'day').endOf('day')
    return current && current < yesterday
  }

  const onChangeCleaningDate = (date, dateString) => {
    setCleaningDetails({
      ...cleaningDetails,
      cleaningScheduled: dateString,
    })
  }

  return (
    <div className="p-5">
      {infoModal && (
        <CleaningDetails
          isModalOpen={infoModal}
          setIsModalOpen={setInfoModal}
          cleanings={cleanings}
          setActions={setCleanings}
        />
      )}
      <div className="">
        <Modal
          title="Add Cleaner"
          open={cleanersModal}
          onOk={handleSaveCleaner}
          onCancel={handleCancel}
          width={400}
          className="flex flex-col items-center justify-center"
          okButtonProps={{
            style: { backgroundColor: '#35d0cf', color: 'white' },
          }}
          cancelButtonProps={{
            style: { backgroundColor: 'white', color: 'black' },
          }}
        >
          <Input
            placeholder="Enter Cleaner Name"
            className="lg:w-[400px]"
            onChange={handleCleanerName}
            value={cleanerName}
          />
        </Modal>
        <Modal
          title="Schedule Cleaning"
          open={handleModal}
          onOk={handleOk}
          onCancel={handleCancel}
          width={350}
          className="flex flex-col items-center justify-center"
          okButtonProps={{
            style: { backgroundColor: '#35d0cf', color: 'white' },
          }}
          cancelButtonProps={{
            style: { backgroundColor: 'white', color: 'black' },
          }}
        >
          <div className="-mb-2 flex gap-1">
            <p>Select Listing</p>
            <p className=" text-red-600">*</p>
          </div>
          <CustomDropdown
            options={listings}
            showSearch={true}
            width={250}
            handleChange={(e) => {
              setError(false)
              setCleaningDetails({
                ...cleaningDetails,
                allocatedProperty: e.split('---')[0],
                address: e.split('---')[1],
              })
            }}
            value={cleaningDetails?.allocatedProperty || undefined}
          />

          <Checkbox
            checked={isRecurringCleaning}
            onChange={(e) => {
              setIsRecurringCleaning(e.target.checked)
            }}
            className="my-[15px]"
          >
            Recurring Cleaning
          </Checkbox>

          {isRecurringCleaning && (
            <>
              <div className="mb-[15px]">
                <p>Repeat every</p>
                <CustomDropdown
                  options={[
                    { label: 'Day', value: 'Day' },
                    { label: 'Week', value: 'Week' },
                    { label: 'Month', value: 'Month' },
                  ]}
                  handleChange={(e) => {
                    setError(false)
                    setCleaningDetails({
                      ...cleaningDetails,
                      recurringPeriod: e,
                    })
                  }}
                  value={cleaningDetails?.recurringPeriod}
                  width={250}
                />
              </div>

              {cleaningDetails.recurringPeriod === 'Month' && (
                <div className="mb-[15px]">
                  <p>Select day</p>
                  <CustomDropdown
                    options={[
                      {
                        label: `Every month on the 1st`,
                        value: 1,
                      },
                      {
                        label: `${cleaningDetails.cleaningScheduled
                          ? daysOfWeek[
                          new Date(
                            cleaningDetails.cleaningScheduled,
                          ).getDay() - 1
                          ]
                          : daysOfWeek[new Date().getDay() - 1]
                          } of every first week`,
                        value: cleaningDetails.cleaningScheduled
                          ? daysOfWeek[
                          new Date(
                            cleaningDetails.cleaningScheduled,
                          ).getDay() - 1
                          ]
                          : daysOfWeek[new Date().getDay() - 1],
                      },
                    ]}
                    handleChange={(e) => {
                      setError(false)
                      setCleaningDetails({
                        ...cleaningDetails,
                        monthlyPeriod: e,
                      })
                    }}
                    value={cleaningDetails?.monthlyPeriod}
                    width={250}
                  />
                </div>
              )}

              {cleaningDetails.recurringPeriod === 'Week' && (
                <>
                  <p className="mb-[10px]">Repeat on</p>
                  <div className="flex gap-[5px] cursor-pointer mb-[15px]">
                    {[
                      {
                        label: 'Mon',
                        value: 1,
                      },
                      { label: 'Tue', value: 2 },
                      { label: 'Wed', value: 3 },
                      { label: 'Thu', value: 4 },
                      { label: 'Fri', value: 5 },
                      { label: 'Sat', value: 6 },
                      { label: 'Sun', value: 7 },
                    ].map((el) => (
                      <div
                        className={`h-[40px] w-[40px] rounded-full flex justify-center items-center ${recurringDays.includes(el.value)
                          ? 'bg-[#35D0CF]'
                          : 'bg-[#d9d9d9]'
                          }`}
                        onClick={() => handleSetRecurringDay(el.value)}
                      >
                        {el.label}
                      </div>
                    ))}
                  </div>
                </>
              )}

              <p className="mb-[10px]">Ends</p>
              <div>
                <div className="flex justify-between items-center">
                  <div>
                    <input
                      type="radio"
                      id="on"
                      name="options"
                      value="on"
                      checked={selectedEndOption === 'on'}
                      onChange={(e) => setSelectedEndOption(e.target.value)}
                      className="mr-[10px]"
                    />
                    <label htmlFor="option2">On</label>
                  </div>

                  <DatePicker
                    disabledDate={disabledDate}
                    className="w-[200px] h-8"
                    onChange={(date, dateString) => {
                      setError(false)
                      setCleaningDetails({
                        ...cleaningDetails,
                        recurringEndDate: dateString,
                      })
                      setSelectedEndOption('on')
                    }}
                  />
                </div>
                <br />

                <div className="flex justify-between">
                  <div>
                    <input
                      type="radio"
                      id="after"
                      name="options"
                      value="after"
                      checked={selectedEndOption === 'after'}
                      onChange={(e) => setSelectedEndOption(e.target.value)}
                      className="mr-[10px]"
                    />
                    <label htmlFor="option3">After</label>
                  </div>

                  <div className="flex gap-[10px] items-center">
                    <input
                      type="number"
                      className="outline-0 rounded-[8px] h-[25px] w-[80px] mb-[-1px]"
                      onChange={(e) => {
                        setError(false)
                        setCleaningDetails({
                          ...cleaningDetails,
                          recurringOccurences: e.target.value,
                        })
                        setSelectedEndOption('after')
                      }}
                    />

                    <p>occurrences</p>
                  </div>
                </div>
                <br />
              </div>
            </>
          )}

          {!isRecurringCleaning && (
            <>
              <div className="mt-4 -mb-2 flex gap-1">
                <p>Select Cleaning Date</p>
                <p className=" text-red-600">*</p>
              </div>
              <DatePicker
                disabledDate={disabledDate}
                className="w-full mt-4 h-8"
                onChange={onChangeCleaningDate}
                value={cleaningDetails?.cleaningScheduled
                  ? dayjs(cleaningDetails.cleaningScheduled)
                  : null}
              />
            </>
          )}
          <div className="mt-4 -mb-2 flex gap-1">
            <p>Select Cleaning Type</p>
            <p className=" text-red-600">*</p>
          </div>
          <CustomDropdown
            options={[
              { label: 'Heavy clean', value: 'Heavy clean' },
              { label: 'Medium clean', value: 'Medium clean' },
              { label: 'Quick clean', value: 'Quick clean' },
              { label: 'Mid-Stay clean', value: 'Mid-Stay clean' },
            ]}
            handleChange={(e) => {
              setError(false)
              setCleaningDetails({
                ...cleaningDetails,
                cleaningEffort: e,
              })
            }}
            value={cleaningDetails?.cleaningEffort}
            width={250}
          />

          <div className="mt-4 -mb-2 flex gap-1">
            <p>Status</p>
            <p className=" text-red-600">*</p>
          </div>

          <CustomDropdown
            options={RESERVATION_STATUS_OPTIONS}
            handleChange={value => {
              setError(false)
              setReservationStatus(value)
              setReservationStatus(value)
            }}
            placeholder='Select status'
            value={reservationStatus}
            width={250}
          />

          {recurringEndError && (
            <p className="text-red-600 text-xs mt-1">{recurringEndError}</p>
          )}

          {error && (
            <p className="text-red-600 text-xs mt-1">
              All fields are required.
            </p>
          )}
        </Modal>
        <div
          className="flex flex-wrap md:flex-nowrap gap-2 md:gap-0 justify-between items-end mb-5">
          <div className="flex items-baseline gap-5">
            <div className="text-themeGrey text-xl font-bold">
              Cleanings{' '}
              {!!total && !loading && (
                <span className="text-base">
                  ({total})
                </span>
              )}
              <GenericButton
                label="Schedule Cleaning"
                disabled={loading}
                onClick={() => {
                  setHandleModal(!handleModal)
                }}
                className={`ml-4 disabled:opacity-50 disabled:bg-buttonTheme disabled:hover:!bg-buttonHoverTheme disabled:!text-white disabled:hover:!text-white py-1 rounded-md px-[15px] text-white inline-block bg-buttonTheme hover:!bg-buttonHoverTheme hover:!text-white `}
              />
            </div>
          </div>
          <div className="flex flex-wrap gap-y-2 items-center gap-x-1">
            <GenericButton
              label="Today"
              disabled={loading}
              onClick={() => {
                if (view !== 'today') {
                  getCleanings({ view: 'today' })
                }
              }}
              className={`disabled:opacity-50 disabled:bg-buttonTheme disabled:hover:!bg-buttonHoverTheme disabled:!text-white disabled:hover:!text-white py-1 rounded-md px-[15px] text-white inline-block ${view === 'today'
                ? 'bg-lightButtonHoverTheme border-lightButtonHoverTheme hover:!border-lightButtonHoverTheme  !text-buttonTheme cursor-default'
                : 'bg-buttonTheme hover:!bg-buttonHoverTheme hover:!text-white '
                }`}
            />

            <GenericButton
              label="Tomorrow"
              disabled={loading}
              onClick={() => {
                if (view !== 'tomorrow') {
                  getCleanings({ view: 'active' })
                }
              }}
              className={`disabled:opacity-50 disabled:bg-buttonTheme disabled:hover:!bg-buttonHoverTheme disabled:!text-white disabled:hover:!text-white py-1 rounded-md px-[15px] text-white inline-block ${view === 'active'
                ? 'bg-lightButtonHoverTheme border-lightButtonHoverTheme hover:!border-lightButtonHoverTheme  !text-buttonTheme cursor-default'
                : 'bg-buttonTheme hover:!bg-buttonHoverTheme hover:!text-white '
                }`}
            />

            <GenericButton
              label="Next 7 days"
              disabled={loading}
              onClick={() => {
                if (view !== 'next') {
                  getCleanings({ view: 'next' })
                }
              }}
              className={`disabled:opacity-50 disabled:bg-buttonTheme disabled:hover:!bg-buttonHoverTheme disabled:!text-white disabled:hover:!text-white py-1 rounded-md px-[15px] text-white inline-block ${view === 'next'
                ? 'bg-lightButtonHoverTheme border-lightButtonHoverTheme hover:!border-lightButtonHoverTheme  !text-buttonTheme cursor-default'
                : 'bg-buttonTheme hover:!bg-buttonHoverTheme hover:!text-white '
                }`}
            />

            <GenericButton
              label="Scheduled cleaning"
              disabled={loading}
              onClick={() => {
                if (view !== 'future') {
                  getCleanings({ view: 'future' })
                }
              }}
              className={`disabled:opacity-50 disabled:bg-buttonTheme disabled:hover:!bg-buttonHoverTheme disabled:!text-white disabled:hover:!text-white py-1 rounded-md px-[15px] text-white inline-block ${view === 'future'
                ? 'bg-lightButtonHoverTheme border-lightButtonHoverTheme hover:!border-lightButtonHoverTheme  !text-buttonTheme cursor-default'
                : 'bg-buttonTheme hover:!bg-buttonHoverTheme hover:!text-white '
                }`}
            />

            <GenericButton
              label="Past"
              disabled={loading}
              onClick={() => {
                if (view !== 'past') {
                  getCleanings({ view: 'past' })
                }
              }}
              className={`disabled:opacity-50 disabled:bg-buttonTheme disabled:hover:!bg-buttonHoverTheme disabled:!text-white disabled:hover:!text-white py-1 rounded-md px-[15px] text-white inline-block ${view === 'past'
                ? 'bg-lightButtonHoverTheme border-lightButtonHoverTheme hover:!border-lightButtonHoverTheme  !text-buttonTheme cursor-default'
                : 'bg-buttonTheme hover:!bg-buttonHoverTheme hover:!text-white '
                }`}
            />

            {isAdmin && (
              <GenericButton
                isLoading={syncLoading}
                label="Sync"
                loadingClass="text-white"
                disabled={syncLoading}
                className="disabled:hover:!bg-buttonTheme disabled:hover:text-white disabled:!text-white disabled:bg-buttonTheme  hover:!bg-buttonHoverTheme hover:text-white bg-buttonTheme ml-5 shadow-0"
                icon={<LocalOfferIcon style={{ fontSize: '14px' }} />}
                onClick={syncData}
              />
            )}

            <GenericButton
              label="Download CSV"
              loadingClass="text-white"
              className="disabled:hover:!bg-buttonTheme disabled:hover:text-white disabled:!text-white disabled:bg-buttonTheme  hover:!bg-buttonHoverTheme hover:text-white bg-buttonTheme mr-5 shadow-0"
              onClick={handleDownloadCSV}
            />
          </div>
        </div>
        <div className="flex items-center gap-2 mb-5">
          <div className="mob-w-100">
            <div
              className="text-sm text-buttonTheme font-medium flex items-center gap-x-1 mb-1 mr-5 md:mr-0">
              Search Cleanings
            </div>
            <div className="">
              <Input
                allowClear={true}
                value={searchValue}
                disabled={loading}
                onChange={(e) => {
                  setSearchValue(e.target.value)
                  if (timeOut) {
                    clearTimeout(timeOut)
                  }
                  timeOut = setTimeout(() => {
                    getCleanings({ search: e.target.value })
                  }, 1000)
                }}
                placeholder="Search"
              />
            </div>
          </div>
        </div>

        {loading ? (
          <div
            className="h-[50vh] bg-white rounded-lg flex justify-center items-center">
            <Loading />
          </div>
        ) : (
          <Table
            loading={loading}
            dataSource={cleaningsCollection}
            columns={columns}
            pagination={{
              current: page,
              pageSize,
              total,
              showSizeChanger: true,
              itemRender: paginationItemRender,
              onChange: (nextPage, nextSize) => {
                if (nextPage !== page) {
                  getCleanings({ page: nextPage })
                }

                if (nextSize !== pageSize) {
                  setPageSize(nextSize)
                  getCleanings({ page: 1, pageSize: nextSize })
                }
              }
            }}
            scroll={{ x: 2650 }}
          />
        )}
      </div>
    </div>
  )
}

export default Cleaning
