import { useEffect, useMemo, useState } from "react";
import PropertyCard from "../../components/PropertyCard/PropertyCard";
import { apiInstance, routes } from "../../utils/API";
import { Modal, Select } from "antd";
import CustomInput from "../../components/Input/Input";
// import Loader from "../../components/Loaders/Loader";
import Loading from "../../components/Loaders/Loading";

let timeOut = null;

export default function GuestCom() {
  const [itemId, setItemId] = useState(null);
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [search, setSearch] = useState(null);
  const [city, setCity] = useState(null);
  const [filter, setFilter] = useState(null);
  const [isPropertiesNotFound, setPropertiesNotFound] = useState(false);
  const [isDataLoding, setDataLoading] = useState(true);
  const [isHostawayGeneralAmenities, setHostawayGeneralAmenities] = useState(false);

  const amenities = [
    "Bathroom",
    "Kitchen",
    "Garden",
    "Floor Level",
    "Elevator",
    "Stairs/ WheelChair Access",
    "Balcony",
    "Allocated parking",
    "Dishwasher",
    "Has Concierge",
    "Dining Table",
    "Door Self-locking?",
    "Desk",
  ];
  useEffect(() => {
    (async () => {
      setDataLoading(true);
      // const properties = await apiInstance(routes.GUEST_COM); // MondayApi Data
      const properties = await apiInstance(routes.GUEST_COM_HOSTAWAY); // HostawayApi Data
      console.log(properties);
      setPropertyDetails(properties.data.properties);
    })();
  }, []);
  const filteredRecords = useMemo(() => {
    setDataLoading(true);
    if (propertyDetails) {
      setDataLoading(false);
      let properties = [...propertyDetails];
      if (filter) {
        properties = properties.filter((item) => {
          if (
            item?.details?.["External listing name"]
              ?.toLowerCase()
              ?.includes(filter.toLowerCase()) ||
            item?.details?.["Tag"]
              ?.toLowerCase()
              ?.includes(filter.toLowerCase()) ||
            item?.details?.["Property Name"]
              ?.toLowerCase()
              ?.includes(filter.toLowerCase()) ||
            item?.basicInfo?.external_listing_name?.toLowerCase()?.includes(filter.toLowerCase()) || 
            item?.basicInfo?.tags?.toLowerCase()?.includes(filter.toLowerCase()) ||
            item?.basicInfo?.internal_listing_name?.toLowerCase()?.includes(filter.toLowerCase())
          ) {
            return true;
          }
          return null;
        });
      }
      if (city) {
        properties = properties.filter((item) => {
          if (
            item?.city &&
            item?.city?.toLowerCase()?.includes(city.toLowerCase())
          ) {
            setPropertiesNotFound(false)
            return true;
          }
          return null;
        });
      }
      return properties;
    }
    return [];
  }, [filter, city, propertyDetails]);

  useEffect(() => {
    if(filteredRecords && filteredRecords.length === 0 && !isDataLoding) {
      setPropertiesNotFound(true)
    } else {
      setPropertiesNotFound(false)
    }

  }, [filteredRecords, isDataLoding])

  const selectedProperty = useMemo(() => {
    if (filteredRecords && itemId) {

      const [{amenities: hostawayAmeneties}] = filteredRecords;
      if(hostawayAmeneties !== undefined) {
        setHostawayGeneralAmenities(() => hostawayAmeneties.some((ha) => ha.name.includes(amenities)))
      } else {
        setHostawayGeneralAmenities(true)
      }

      return filteredRecords.filter((item) => {
        if(item?.listingId){
          return item.listingId === itemId;
        } else {
          return item.itemId === itemId;
        }
      });

      // return filteredRecords.filter((item) => item.itemId === itemId);
    }
    return [];
  }, [itemId, filteredRecords]);

  const handleCancel = () => {
    setItemId(null);
    localStorage.removeItem("itemId");
  };

  return (
    <div className="p-10">
      <Modal
        title="Property details"
        cancelText={"Close"}
        open={!!itemId}
        onCancel={handleCancel}
        okButtonProps={{
          style: { display: "none" },
        }}
        wrapClassName={'guest-com-modal-popup'}
        width={"90%"}
        cancelButtonProps={{
          style: { backgroundColor: "#00b4b4", color: "white" },
        }}
      >
        {itemId && selectedProperty && (
          <div>
            <div className="p-4">
              <div className="text-xl font-bold">Basic Information</div>
              <div className="border-[1px] border-black mb-2" />
              <div className="flex flex-wrap gap-2">
                <h2 className="font-bold">Tag:</h2>
                {Object.prototype.hasOwnProperty.call(selectedProperty[0], 'details') && (
                  <h2>
                    {selectedProperty[0].details["Tag"]
                        ? selectedProperty[0].details["Tag"]
                        : "No tag found"}
                  </h2>
                )}

                {Object.prototype.hasOwnProperty.call(selectedProperty[0], 'basicInfo') && (
                  <h2>
                    {selectedProperty[0].basicInfo.tags
                      ? selectedProperty[0].basicInfo.tags
                      : "No tag found"}
                </h2>
                )}
              </div>
              <div className="flex flex-wrap gap-2">
                <h2 className="font-bold">Internal Listing Name:</h2>
                {Object.prototype.hasOwnProperty.call(selectedProperty[0], 'details') && (
                  <h2>
                    {selectedProperty[0].details["Property Name"]
                        ? selectedProperty[0].details["Property Name"]
                        : "Name not found"}
                  </h2>
                )}

                {Object.prototype.hasOwnProperty.call(selectedProperty[0], 'basicInfo') && (
                  <h2>
                    {selectedProperty[0].basicInfo.internal_listing_name
                      ? selectedProperty[0].basicInfo.internal_listing_name
                    : "Name not found"}
                </h2>
                )}
              </div>
              <div className="flex flex-wrap gap-2">
                <h2 className="font-bold">External Listing Name:</h2>
                {Object.prototype.hasOwnProperty.call(selectedProperty[0], 'details') && (
                  <h2>
                    {selectedProperty[0].details["External listing name"]
                        ? selectedProperty[0].details["External listing name"]
                        : "Name not found"}
                  </h2>
                )}

                {Object.prototype.hasOwnProperty.call(selectedProperty[0], 'basicInfo') && (
                  <h2>
                    {selectedProperty[0].basicInfo.external_listing_name
                      ? selectedProperty[0].basicInfo.external_listing_name
                      : "No name Found"}
                </h2>
                )}
              </div>
              <div className="flex flex-wrap gap-2">
                <h2 className="font-bold">Address:</h2>
                <h2>
                  {selectedProperty[0].address
                    ? selectedProperty[0].address
                    : "Address not found"}
                </h2>
              </div>
              <div className="flex flex-wrap gap-2">
                <h2 className="font-bold">City:</h2>
                <h2>
                  {selectedProperty[0].city
                    ? selectedProperty[0].city
                    : "City not found"}
                </h2>
              </div>
              <div className="flex flex-wrap gap-2">
                <h2 className="font-bold"> No. Bedrooms:</h2>
                {Object.prototype.hasOwnProperty.call(selectedProperty[0], 'details') && (
                  <h2>
                    {selectedProperty[0].details["No. Bedrooms"]
                      ? selectedProperty[0].details["No. Bedrooms"]
                      : "No. Bedrooms not found"}
                  </h2>
                )}

                {Object.prototype.hasOwnProperty.call(selectedProperty[0], 'basicInfo') && (
                  <h2>
                    {selectedProperty[0].basicInfo.number_of_bedrooms
                      ? selectedProperty[0].basicInfo.number_of_bedrooms
                    : "No. Bedrooms not found"}
                </h2>
                )}
              </div>
              <div className="flex flex-wrap gap-2">
                <h2 className="font-bold"> No. Bathrooms:</h2>
                {Object.prototype.hasOwnProperty.call(selectedProperty[0], 'details') && (
                  <h2>
                      {selectedProperty[0].details["No. Bathrooms"]
                      ? selectedProperty[0].details["No. Bathrooms"]
                      : "No. Bathrooms not found"}
                    </h2>
                )}
    
                {Object.prototype.hasOwnProperty.call(selectedProperty[0], 'basicInfo') && (
                  <h2>
                    {selectedProperty[0].basicInfo.number_of_bathrooms
                      ? selectedProperty[0].basicInfo.number_of_bathrooms
                      : "No. Bedrooms not found"}
                  </h2>
                )}
              </div>
              {
                  Object.prototype.hasOwnProperty.call(selectedProperty[0], 'details') &&
                   (
                    <>
                      <div className="flex flex-wrap gap-2">
                        <h2 className="font-bold"> King Beds:</h2>
                        <h2>
                          {selectedProperty[0].details["King Beds"]
                            ? selectedProperty[0].details["King Beds"]
                            : "No King Bed"}
                        </h2>
                      </div>
                      <div className="flex flex-wrap gap-2">
                      <h2 className="font-bold"> Double Beds:</h2>
                      <h2>
                        {selectedProperty[0].details["Double Beds"]
                          ? selectedProperty[0].details["Double Beds"]
                          : "No Double Bed"}
                      </h2>
                    </div>
                    <div className="flex flex-wrap gap-2">
                      <h2 className="font-bold"> Single Beds:</h2>
                      <h2>
                        {selectedProperty[0].details["Single Beds"]
                          ? selectedProperty[0].details["Single Beds"]
                          : "No Single Bed"}
                      </h2>
                    </div>
                    <div className="flex flex-wrap gap-2">
                      <h2 className="font-bold"> Guest beds:</h2>
                      <h2>
                        {selectedProperty[0].details["Guest beds"]
                          ? selectedProperty[0].details["Guest beds"]
                          : "No Guest Bed"}
                      </h2>
                    </div>
                    <div className="flex flex-wrap gap-2">
                        <h2 className="font-bold"> Size SQM:</h2>
                        <h2>
                          {selectedProperty[0].details["Size SQM"]
                            ? selectedProperty[0].details["Size SQM"]
                            : "No Guest Bed"}
                        </h2>
                    </div>
                  </>
                  )
              }
            </div>
            <div className={`${!isHostawayGeneralAmenities?'hidden':'p-4 '}`}>
              <div className="text-xl font-bold">General Amenities</div>
              <div className="border-[1px] border-black mb-2" />
              {
                 Object.prototype.hasOwnProperty.call(selectedProperty[0], 'details') &&
                <div className="flex flex-wrap gap-4 ">
                  {Object.entries(selectedProperty[0].details)?.map(
                    (item) =>{
                      return amenities.includes(item[0]) &&
                      item[1] && (
                        <div className="flex flex-inline gap-1 bg-[#48c4b0] rounded-lg p-1" key={selectedProperty[0]?.basicInfo?.internal_listing_name}>
                          <h2 className="font-bold">{item[0]}</h2>
                          <h2>{item[1]}</h2>
                        </div>
                      )
                    }
                  )}
                </div>
              }
            </div>
            <div className={`${isHostawayGeneralAmenities?'flex flex-row':'p-4 '}`}>
              <div className={`${isHostawayGeneralAmenities?'basis-1/2 p-4':'p-1'}`}>
                <div className="text-xl font-bold">Detailed Amenities</div>
                <div className="border-[1px] border-black mb-2" />
                  {/* <div className="flex flex-wrap gap-4 max-content"> */}
                  {
                    selectedProperty[0].hostawayAmenities === null && (<div className="m-5 p-5 text-xl capitalize shadow-lg">
                    no detailed amenities found !!!
                  </div>)
                  }
                  <div className={`flex flex-wrap gap-4 ${isHostawayGeneralAmenities?'max-content':'max-content-1'}`}>
                    {JSON.parse(selectedProperty[0].hostawayAmenities)?.map(
                      (item) => (
                        <div key={item.name} className="flex flex-inline gap-3 items-center w-[200px]  rounded-lg"> 
                        {/* w-[280px] py-1 px-2 */}
                          <div className="w-[50px] h-[50px] bg-[#e3f1f0] p-3 rounded-[30px]">
                            <img
                              src={`/${item.name
                                .toLowerCase()
                                .split(" ")
                                .join("_")
                                .split("/")
                                .join("_")}.png`}
                              className=""
                              alt={`${item.name.substring(0,1).toLowerCase()}`}
                            />
                          </div>
                          <h2 className="font-bold">{item.name}</h2>
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className={`${isHostawayGeneralAmenities?'basis-1/2 p-4':'p-1'}`}>
                  {
                    Object.prototype.hasOwnProperty.call(selectedProperty[0], 'details') &&
                    <>
                      <div className="p-0">
                      <div className="text-xl font-bold">Near by Places</div>
                      <div className="border-[1px] border-black mb-2" />
                        <div className="max-content">
                          <div className="flex flex-col flex-wrap gap-4 max-content">
                            <div className="flex flex-wrap">
                              <h2 className="font-bold">Nearest hospital :</h2>
                              <h2>
                                {selectedProperty[0].details[
                                  "Nearest hospital (Accident & Emergency)"
                                ]
                                  ? selectedProperty[0].details[
                                      "Nearest hospital (Accident & Emergency)"
                                    ]
                                  : "No hospital near"}
                              </h2>
                            </div>
                            <div className="flex flex-wrap">
                              <h2 className="font-bold">Nearest Tube stations :</h2>
                              <h2>
                                {selectedProperty[0].details["Nearest Tube stations"]
                                  ? selectedProperty[0].details["Nearest Tube stations"]
                                  : "No Tube stations near"}
                              </h2>
                            </div>
                            <div className="flex flex-wrap">
                              <h2 className="font-bold">Nearest Supermarket :</h2>
                              <h2>
                                {selectedProperty[0].details["Nearest Supermarket"]
                                  ? selectedProperty[0].details["Nearest Supermarket"]
                                  : "No Supermarket near"}
                              </h2>
                            </div>
                          </div>
                      
                          <div className="pt-4">
                            <div className="text-xl font-bold">Instructions</div>
                            <div className="border-[1px] border-black mb-2" />
                            <div className="flex flex-col flex-wrap gap-4 ">
                              <div className="flex flex-wrap gap-x-2">
                                <h2 className="font-bold">Key Collection Instructions :</h2>
                                <h2>
                                  {selectedProperty[0].details["Key Collection Instructions"]
                                    ? selectedProperty[0].details[
                                        "Key Collection Instructions"
                                      ]
                                    : "No Key Collection Instructions"}
                                </h2>
                              </div>
                              <div className="flex flex-wrap gap-x-2">
                                <h2 className="font-bold">Parking instructions :</h2>
                                <h2>
                                  {selectedProperty[0].details["Parking instructions"]
                                    ? selectedProperty[0].details["Parking instructions"]
                                    : "No Parking instructions"}
                                </h2>
                              </div>
                              <div className="flex flex-wrap gap-x-2">
                                <h2 className="font-bold">Access Instructions :</h2>
                                <h2>
                                  {selectedProperty[0].details["Access Instructions"]
                                    ? selectedProperty[0].details["Access Instructions"]
                                    : "No Access Instructions"}
                                </h2>
                              </div>
                              <div className="flex flex-wrap gap-x-2">
                                <h2 className="font-bold">
                                  Guest Bed location/ Instructions :
                                </h2>
                                <h2>
                                  {selectedProperty[0].details[
                                    "Guest Bed location/ Instructions"
                                  ]
                                    ? selectedProperty[0].details[
                                        "Guest Bed location/ Instructions"
                                      ]
                                    : "No Guest Bed location/ Instructions"}
                                </h2>
                              </div>
                              <div className="flex flex-wrap gap-x-2">
                                <h2 className="font-bold">Intercom Instructions :</h2>
                                <h2>
                                  {selectedProperty[0].details["Intercom Instructions"]
                                    ? selectedProperty[0].details["Intercom Instructions"]
                                    : "No Intercom Instructions"}
                                </h2>
                              </div>
                              <div className="flex flex-wrap gap-x-2">
                                <h2 className="font-bold">
                                  Heating, Boiler & Hot Water Instructions :
                                </h2>
                                <h2>
                                  {selectedProperty[0].details[
                                    "Heating, Boiler & Hot Water Instructions"
                                  ]
                                    ? selectedProperty[0].details[
                                        "Heating, Boiler & Hot Water Instructions"
                                      ]
                                    : "No Heating, Boiler & Hot Water Instructions"}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  }
              </div>
            </div>            
          </div>
        )}
      </Modal>
      <h1 className="text-xl font-bold">Properties</h1>
      <div className="flex flex-wrap gap-2 mt-4">
        <div className="w-[250px]">
          <CustomInput
            placeholder={"Search"}
            className="custom-input"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              if (timeOut) {
                clearTimeout(timeOut);
              }
              timeOut = setTimeout(() => {
                setFilter(e.target.value);
              }, 1000);
            }}
          />
        </div>
        <div className="w-[250px]">
          <Select
            allowClear
            className="w-full"
            status="blue"
            rootClassName="border-red-500"
            placeholder="Select City"
            options={[
              { label: "Select City", value: "" },
              { label: "London", value: "London" },
              { label: "Paris", value: "Paris" },
              { label: "Dubai", value: "Dubai" },
              { label: "Algiers", value: "Algiers" },
            ]}
            onChange={(e) => {
              if (e) {
                setCity(e);
              } else {
                setCity(null);
              }
            }}
            value={city}
          />
        </div>
      </div>
      <div className=" flex flex-wrap gap-x-2 justify-center">
        {
          isDataLoding && <Loading />
        }

        {
          isPropertiesNotFound ? (
          <>
            <div className="m-5 p-5 text-xl capitalize shadow-lg">
              no properties found !
            </div>
          </>
          ):(
            filteredRecords?.map((item) => {
              return (
              <div className="mt-10 " key={item?.basicInfo?.internal_listing_name}>
                <PropertyCard  propertyDetails={item} setItemId={setItemId} />
              </div>
            )})
        )
        }
      </div>
    </div>
  );
}
