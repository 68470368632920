import React from "react";
import { Select, Form, Tooltip } from "antd";
import HelpIcon from "@mui/icons-material/Help";

function FormSelect({
  name,
  onChange,
  label,
  mode = "",
  rules = [],
  placeholder,
  options,
  className = "",
  disabled,
  dropdownStyle = "",
  style = {},
  showArrow = false,
  showSearch = false,
  tooltipTitle,
  toolTipPlacement = "top",
  toolTipFlag = false,
  childern,
  defaultValue,
  allowClear = false,
  filterOption,
  onClear,
  loading = false,
}) {
  const isRequired = rules?.find(({ required }) => required === true);

  return (
    <div className="custom-form-input">
      <div className="flex items-center ">
        {label && (
          <label className="font-bold text-themeGrey capitalize">
            {label}{" "}
            {isRequired && (
              <span className="text-cancelButton inline-flex items-center">
                *
              </span>
            )}
            {toolTipFlag && (
              <Tooltip placement={toolTipPlacement} title={tooltipTitle}>
                <HelpIcon className="text-sky-600 !text-[17px] ml-1 cursor-pointer" />
              </Tooltip>
            )}
          </label>
        )}
      </div>
      <Form.Item name={name} rules={rules} className="mb-0">
        <Select
          onClear={onClear}
          allowClear={allowClear}
          defaultValue={defaultValue}
          onChange={onChange}
          style={style}
          mode={mode}
          filterOption={filterOption}
          dropdownStyle={dropdownStyle ? dropdownStyle : ""}
          placeholder={<div className="capitalize">{placeholder || label}</div>}
          size="middle"
          options={options}
          className={`${className} w-[100%] rounded-md  placeholder:capitalize`}
          disabled={disabled}
          showArrow={showArrow}
          bordered={true}
          showSearch={showSearch}
          loading={loading}
        >
          {/* {childern} */}
        </Select>
      </Form.Item>
    </div>
  );
}

export default FormSelect;
