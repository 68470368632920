import React from "react";
import ReceiptIcon from "@mui/icons-material/Receipt";

const DocumentTemplateTab = ({
  isSelected = false,
  onClick = () => {},
  tabHeader = "Charge receipt",
  tabText = "Generated upon transaction",
}) => {
  return (
    <div
      className={`h-20 w-64 bg-themeWhite rounded-md grid grid-cols-3 cursor-pointer ${
        isSelected ? "bg-panelSelect" : ""
      }`}
      onClick={onClick}
    >
      <div className="col-span-1 flex items-center justify-center">
        <ReceiptIcon
          className={isSelected ? "text-white" : "text-themeGreen"}
        />
      </div>
      <div className="col-span-2 flex flex-col items-start justify-center py-4">
        <div
          className={isSelected ? "text-white text-sm" : "text-black text-sm"}
        >
          {tabHeader}
        </div>
        <div
          className={
            isSelected ? "text-white text-xs" : "text-themeGrey text-xs"
          }
        >
          {tabText}
        </div>
      </div>
    </div>
  );
};

export default DocumentTemplateTab;
