import React from "react";
import AirBNBLogo from "../../assets/airbnb.png";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import { MARKETPLACE } from "../../constants/dashboardRoutes";

const MarketplaceCard = ({
  cardTitle = "Airbnb",
  cardContent = "An online peer-to-peer marketplace with over 5 million unique accommodations & experiences available in 191 countries.",
  cardImage = AirBNBLogo,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className="h-80 border border-creme shadow-lg relative cursor-pointer"
      onClick={() => {
        navigate(
          `${MARKETPLACE}/${encodeURIComponent(cardTitle.toLowerCase())}`,
          {
            state: {
              cardTitle,
              cardContent,
              cardImage,
            },
          }
        );
      }}
    >
      <div className="h-[25%] w-[100%] p-5 flex items-center justify-center">
        <img
          src={cardImage}
          alt="airbnb"
          className="xs:w-32 sm:w-48 xmd:w-56 max-w-[210px]"
        />
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="font-bold text-xl text-themeGrey">{cardTitle}</div>
        <div className="text-sm font-thin text-themeGrey px-5">
          {cardContent}
        </div>
      </div>
      <div className="absolute bottom-0 right-0 text-white p-5 flex items-center">
        <SettingsIcon
          className="text-themeGreen mr-1"
          style={{ fontSize: 12 }}
        />
        <p className="text-themeGreen text-xs font-bold hover:underline">
          Configure
        </p>
      </div>
    </div>
  );
};

export default MarketplaceCard;
