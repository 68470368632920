import { Table } from "antd";
import FormCheckBox from "../../Form/FormCheckBox";

export default function NotificationsPermissions({ isDetails }) {
  const notificationColums = [
    {
      title: (
        <div className="text-themeGrey font-bold text-md flex items-center gap-1">
          Reservations
        </div>
      ),
      dataIndex: "reservations",
      key: "reservations",
      width: "42%",
    },
    {
      title: (
        <div className="text-themeGrey font-bold text-md flex items-center gap-1">
          Dashboard
        </div>
      ),
      dataIndex: "dashboard",
      key: "dashboard",
    },
    {
      title: (
        <div className="text-themeGrey font-bold text-md flex items-center gap-1">
          Mobile App
        </div>
      ),
      dataIndex: "mobileApp",
      key: "mobileApp",
    },
    {
      title: (
        <div className="text-themeGrey font-bold text-md flex items-center gap-1">
          Email
        </div>
      ),
      dataIndex: "email",
      key: "email",
    },
  ];

  const notificationData = [
    {
      key: "1",
      reservations: "New Reservation",
      dashboard: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "reservation", "new", "dashboard"]}
        />
      ),
      mobileApp: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "reservation", "new", "mobileApp"]}
        />
      ),
      email: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "reservation", "new", "email"]}
        />
      ),
    },
    {
      key: "2",
      reservations: "New owner Stay",
      dashboard: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "ownerStay", "new", "dashboard"]}
        />
      ),
      mobileApp: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "ownerStay", "new", "mobileApp"]}
        />
      ),
      email: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "ownerStay", "new", "email"]}
        />
      ),
    },
    {
      key: "3",
      reservations: "Owner stay is modified",
      dashboard: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "ownerStay", "modify", "dashboard"]}
        />
      ),
      mobileApp: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "ownerStay", "modify", "mobileApp"]}
        />
      ),
      email: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "ownerStay", "modify", "email"]}
        />
      ),
    },
    {
      key: "4",
      reservations: "Reservation is modified",
      dashboard: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "reservation", "modify", "dashboard"]}
        />
      ),
      mobileApp: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "reservation", "modify", "mobileApp"]}
        />
      ),
      email: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "reservation", "modify", "email"]}
        />
      ),
    },
    {
      key: "5",
      reservations: "Reservation is cancelled",
      dashboard: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "reservation", "cancel", "dashboard"]}
        />
      ),
      mobileApp: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "reservation", "cancel", "mobileApp"]}
        />
      ),
      email: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "reservation", "cancel", "email"]}
        />
      ),
    },
    {
      key: "6",
      reservations: "New Inquiry",
      dashboard: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "inquiry", "new", "dashboard"]}
        />
      ),
      mobileApp: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "inquiry", "new", "mobileApp"]}
        />
      ),
      email: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "inquiry", "new", "email"]}
        />
      ),
    },
    {
      key: "7",
      reservations: "Reservation is pending",
      dashboard: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "reservation", "pending", "dashboard"]}
        />
      ),
      mobileApp: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "reservation", "pending", "mobileApp"]}
        />
      ),
      email: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "reservation", "pending", "email"]}
        />
      ),
    },
    {
      key: "8",
      reservations: "Airbnb alteration requests",
      dashboard: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "airbnbAlterationRequests", "dashboard"]}
        />
      ),
      mobileApp: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "airbnbAlterationRequests", "mobileApp"]}
        />
      ),
      email: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "airbnbAlterationRequests", "email"]}
        />
      ),
    },
    {
      key: "9",
      reservations: "Rental agreement signed",
      dashboard: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "rentalAgreementSign", "dashboard"]}
        />
      ),
      mobileApp: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "rentalAgreementSign", "mobileApp"]}
        />
      ),
      email: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "rentalAgreementSign", "email"]}
        />
      ),
    },
    {
      key: "10",
      reservations: <div className="font-bold text-themeGrey">Tasks</div>,
      dashboard: <div className="font-bold text-themeGrey">Dashboard</div>,
      mobileApp: <div className="font-bold text-themeGrey">Mobile App</div>,
      email: <div className="font-bold text-themeGrey">Email</div>,
    },
    {
      key: "11",
      reservations: "New task created",
      dashboard: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "task", "new", "dashboard"]}
        />
      ),
      mobileApp: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "task", "new", "mobileApp"]}
        />
      ),
      email: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "task", "new", "email"]}
        />
      ),
    },
    {
      key: "12",
      reservations: "Task updated",
      dashboard: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "task", "update", "dashboard"]}
        />
      ),
      mobileApp: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "task", "update", "mobileApp"]}
        />
      ),
      email: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "task", "update", "email"]}
        />
      ),
    },
    {
      key: "13",
      reservations: "Task started",
      dashboard: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "task", "start", "dashboard"]}
        />
      ),
      mobileApp: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "task", "start", "mobileApp"]}
        />
      ),
      email: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "task", "start", "email"]}
        />
      ),
    },
    {
      key: "14",
      reservations: "Task ended",
      dashboard: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "task", "end", "dashboard"]}
        />
      ),
      mobileApp: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "task", "end", "mobileApp"]}
        />
      ),
      email: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "task", "end", "email"]}
        />
      ),
    },
    {
      key: "15",
      reservations: "Task cancelled",
      dashboard: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "task", "cancel", "dashboard"]}
        />
      ),
      mobileApp: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "task", "cancel", "mobileApp"]}
        />
      ),
      email: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "task", "cancel", "email"]}
        />
      ),
    },
    {
      key: "16",
      reservations: <div className="font-bold text-themeGrey">Messages</div>,
      dashboard: <div className="font-bold text-themeGrey">Dashboard</div>,
      mobileApp: <div className="font-bold text-themeGrey">Mobile App</div>,
      email: <div className="font-bold text-themeGrey">Email</div>,
    },
    {
      key: "17",
      reservations: "New message received",
      dashboard: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "message", "receive", "dashboard"]}
        />
      ),
      mobileApp: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "message", "receive", "mobileApp"]}
        />
      ),
      email: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "message", "receive", "email"]}
        />
      ),
    },
    {
      key: "18",
      reservations: "New automated message sent",
      dashboard: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "message", "sent", "dashboard"]}
        />
      ),
      mobileApp: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "message", "sent", "mobileApp"]}
        />
      ),
      email: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "message", "sent", "email"]}
        />
      ),
    },
    {
      key: "19",
      reservations: "Thread assigned",
      dashboard: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "thread", "assign", "dashboard"]}
        />
      ),
      mobileApp: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "thread", "assign", "mobileApp"]}
        />
      ),
      email: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "thread", "assign", "email"]}
        />
      ),
    },
    {
      key: "20",
      reservations: "Thread unassigned",
      dashboard: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "thread", "unassign", "dashboard"]}
        />
      ),
      mobileApp: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "thread", "unassign", "mobileApp"]}
        />
      ),
      email: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "thread", "unassign", "email"]}
        />
      ),
    },
    {
      key: "21",
      reservations: <div className="font-bold text-themeGrey">Payment</div>,
      dashboard: <div className="font-bold text-themeGrey">Dashboard</div>,
      mobileApp: <div className="font-bold text-themeGrey">Mobile App</div>,
      email: <div className="font-bold text-themeGrey">Email</div>,
    },
    {
      key: "22",
      reservations: "Pre-auth success",
      dashboard: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "preAuth", "success", "dashboard"]}
        />
      ),
      mobileApp: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "preAuth", "success", "mobileApp"]}
        />
      ),
      email: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "preAuth", "success", "email"]}
        />
      ),
    },
    {
      key: "23",
      reservations: "Pre-auth failed",
      dashboard: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "preAuth", "fail", "dashboard"]}
        />
      ),
      mobileApp: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "preAuth", "fail", "mobileApp"]}
        />
      ),
      email: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "preAuth", "fail", "email"]}
        />
      ),
    },
    {
      key: "24",
      reservations: <div className="font-bold text-themeGrey">Listings</div>,
      dashboard: <div className="font-bold text-themeGrey">Dashboard</div>,
      mobileApp: <div className="font-bold text-themeGrey">Mobile App</div>,
      email: <div className="font-bold text-themeGrey">Email</div>,
    },
    {
      key: "25",
      reservations: "Import success",
      dashboard: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "listingImport", "success", "dashboard"]}
        />
      ),
      mobileApp: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "listingImport", "success", "mobileApp"]}
        />
      ),
      email: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "listingImport", "success", "email"]}
        />
      ),
    },
    {
      key: "26",
      reservations: "Import failed",
      dashboard: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "listingImport", "fail", "dashboard"]}
        />
      ),
      mobileApp: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "listingImport", "fail", "mobileApp"]}
        />
      ),
      email: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "listingImport", "listingImport", "email"]}
        />
      ),
    },
    {
      key: "27",
      reservations: "Export success",
      dashboard: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "export", "success", "dashboard"]}
        />
      ),
      mobileApp: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "export", "success", "mobileApp"]}
        />
      ),
      email: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "export", "success", "email"]}
        />
      ),
    },
    {
      key: "28",
      reservations: "Export failed",
      dashboard: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "export", "fail", "dashboard"]}
        />
      ),
      mobileApp: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "export", "fail", "mobileApp"]}
        />
      ),
      email: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "export", "fail", "email"]}
        />
      ),
    },
    {
      key: "29",
      reservations: <div className="font-bold text-themeGrey">Other</div>,
      dashboard: <div className="font-bold text-themeGrey">Dashboard</div>,
      mobileApp: <div className="font-bold text-themeGrey">Mobile App</div>,
      email: <div className="font-bold text-themeGrey">Email</div>,
    },
    {
      key: "30",
      reservations: "Error reports",
      dashboard: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "errorReports", "dashboard"]}
        />
      ),
      mobileApp: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "errorReports", "mobileApp"]}
        />
      ),
      email: (
        <FormCheckBox
          disabled={isDetails}
          name={["notifications", "errorReports", "email"]}
        />
      ),
    },
  ];

  return (
    <div className="hidden">
      <div className="text-xl font-bold text-themeGrey">Notifications</div>
      <div className="my-10">
        <Table
          className="border-t border-creme mb-10"
          columns={notificationColums}
          dataSource={notificationData}
          pagination={false}
          scroll={{
            x: 1000,
            y: 1000,
          }}
        />
      </div>
    </div>
  );
}
