import React from 'react'

export default function PriceCard (props) {
  const {
    background,
    text,
    value,
    month = 'This Month',
    title,
    percentage = false
  } = props

  return (
    <div
      className={`${background} h-16 sm:h-[70px] w-full flex items-center px-4
      rounded-lg justify-between`}
    >
      <div>
        <p className={`${text} text-[12px] font-bold`}>{title}</p>

        <p className="lg:text-[16px] font-bold">
          {!percentage && '£'} {value} {percentage && '%'}
        </p>
      </div>

      <p className={`text-[12px] font-bold ${text}`}>{month}</p>
    </div>
  )
}
