export const convertArrayToFormData = (list) => {
    if (!list || !Array.isArray(list) || list.length === 0)
        return {}
    const data = {}
    list.forEach(e => {
        let keys = e.event.split(".")
        data[keys[0]] = data[keys[0]] || {}
        if (keys.length === 1) {
            data[keys[0]] = { ...e }
        } else if (keys.length === 2) {
            data[keys[0]][keys[1]] = { ...e }
        }
    })
    return { notifications: data }
}
export const convertFormDataToArray = (data, userId, channelId) => {
    if (!data || typeof data !== "object")
        return []

    const array = []
    for (const [key, value] of Object.entries(data)) {
        let event = key
        const record = { event, userId, channelId }
        for (const [key2, value2] of Object.entries(value)) {
            if (typeof value2 === "object")
                array.push({ event: `${event}.${key2}`, channelId, userId, ...value2 })
            else
                record[key2] = value2
        }
        if (Object.entries(record).length > 3)
            array.push(record)
    }
    return array
}