import { DatePicker, Select } from "antd";
import { formatDate } from "../../../utils/dateHelper";
import GenericButton from "../../GenericButton/GenericButton";
import CustomInput from "../../Input/Input";
import { useContext } from "react";
import { AppContext } from "../../../AppContext";
import dayjs from "dayjs";
import { apiInstance, routes } from "../../../utils/API";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useState } from "react";
import { manageToast } from "../../Common/ManageToast";

let timeOut = null;
export default function TopBarForAllReservation({
  checkOutDay,
  setCheckOutDay,
  getReservations,
  getReservationsCount,
  count,
  checkIn,
  setCheckIn,
  checkOut,
  setCheckOut,
  create,
  setCreate,
  tag,
  setTag,
  city,
  setCity,
  areaFilter,
  setAreaFilter,
  cityForUser,
  setCityForUser,
  limit,
  page,
  sortBy,
  setLimit,
  setPage,
}) {
  const profile = useContext(AppContext).profile || {};
  const isAdmin = profile.isAdmin;
  const cityData = profile.assignedCity;
  if (
    !isAdmin &&
    typeof profile?.assignedCity != "undefined" &&
    profile?.assignedCity !== ""
  ) {
    setCityForUser(profile?.assignedCity);
    if (!city) {
      setCity(profile?.assignedCity);
      console.log("city value updated");
    }
  }
  const [syncing, setSyncing] = useState(false);
  const searchTag = (e) => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    timeOut = setTimeout(() => {
      getReservations(
        limit,
        page,
        checkOutDay,
        sortBy,
        checkIn,
        checkOut,
        create,
        e.target.value,
        city,
        areaFilter,
        cityForUser
      );
      getReservationsCount(
        checkOutDay,
        checkIn,
        checkOut,
        create,
        e.target.value,
        city,
        areaFilter,
        cityForUser
      );
    }, 1000);
  };

  const searchArea = (e) => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    timeOut = setTimeout(() => {
      getReservations(
        limit,
        page,
        checkOutDay,
        sortBy,
        checkIn,
        checkOut,
        create,
        tag,
        city,
        e,
        cityForUser
      );
      getReservationsCount(
        checkOutDay,
        checkIn,
        checkOut,
        create,
        tag,
        city,
        e,
        cityForUser
      );
    }, 1000);
  };

  // const searchCity = (e) => {
  //     if (timeOut) {
  //         clearTimeout(timeOut)
  //     }
  //     timeOut = setTimeout(() => {
  //         getReservations(limit, page, checkOutDay, sortBy, checkIn, create, tag, e.target.value)
  //         getReservationsCount(checkOutDay, checkIn, create, tag, e.target.value)
  //     }, 1000);
  // }

  const today = new Date();
  const todayFormatedDate = formatDate(new Date(), "dd MMM, yyyy");
  let tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  let thirdDay = new Date(tomorrow);
  thirdDay.setDate(tomorrow.getDate() + 1);

  let fourthDay = new Date(thirdDay);
  fourthDay.setDate(thirdDay.getDate() + 1);

  const DAYS = [
    { id: 0, name: "Today" },
    { id: 1, name: tomorrow },
    { id: 2, name: thirdDay },
    { id: 3, name: fourthDay },
  ];

  const syncData = async () => {
    try {
      setSyncing(true);
      manageToast(
        true,
        "Sync is started. It will take some time. You can still continue browsing."
      );
      const res = await apiInstance.post(routes.SYNC_VIEWS);
      if (res.data.status === 200) {
      } else {
      }
      setTimeout(() => {
        getReservations(limit, 1);
        setSyncing(false);
      }, 300000);
    } catch (error) {
      if (error) {
        setTimeout(() => {
          manageToast(false, "Server Error. Something went wrong");
          setSyncing(false);
        }, 300010);
      }
    }
  };

  let cityOptions = [];
  console.log(cityData);

  if (isAdmin || cityData?.includes("All")) {
    // Admin has access to all cities
    cityOptions = [
      { label: "Algiers", value: "Algiers" },
      { label: "London", value: "London" },
      { label: "Dubai", value: "Dubai" },
      { label: "Paris", value: "Paris" },
    ];
  } else {
    cityOptions = [];
    if (cityData?.includes("Algiers")) {
      cityOptions.push({ label: "Algiers", value: "Algiers" });
    }
    if (cityData?.includes("London")) {
      cityOptions.push({ label: "London", value: "London" });
    }
    if (cityData?.includes("Dubai")) {
      cityOptions.push({ label: "Dubai", value: "Dubai" });
    }
    if (cityData?.includes("Paris")) {
      cityOptions.push({ label: "Paris", value: "Paris" });
    }

    // Non-admin user has access to assigned cities
    if (
      !isAdmin &&
      typeof profile?.assignedCity != "undefined" &&
      profile &&
      profile.assignedCity
    ) {
      const assignedCities = profile.assignedCity
        .flat()
        .map((city) => city.trim());
      cityOptions = cityOptions.filter((option) =>
        assignedCities.includes(option.value)
      );
    }
  }
  let areaFilterValueUpdate = areaFilter;

  return (
    <div className="mb-4">
      <div className="flex  justify-between items-center mb-4">
        <div className="text-themeGrey text-xl font-bold">
          Reservations{" "}
          {count > 0 && <span className="text-base">({count})</span>}
        </div>
        <div className="flex gap-x-3">
          {isAdmin && (
            <GenericButton
              isLoading={syncing}
              label="Sync"
              loadingClass="text-white"
              disabled={syncing}
              className="disabled:hover:!bg-buttonTheme disabled:hover:text-white disabled:!text-white disabled:bg-buttonTheme  hover:!bg-buttonHoverTheme hover:text-white bg-buttonTheme mr-2 md:mr-5 shadow-0"
              icon={<LocalOfferIcon style={{ fontSize: "14px" }} />}
              onClick={syncData}
            />
          )}
          <Select
            value={limit}
            options={[
              { value: 20, label: 20 },
              { value: 50, label: 50 },
              { value: 100, label: 100 },
            ]}
            className="w-max"
            onChange={(l) => {
              setLimit(l);
              setPage(1);
            }}
          />
        </div>
      </div>
      <div className="flex w-full gap-x-6 justify-between flex-wrap md:flex-nowrap gap-y-4 md:gap-0 md:gap-y-0">
        <div className="flex flex-wrap gap-2">
          <div className="mob-w-100">
            <div className="">
              <div className="text-sm text-buttonTheme font-medium flex items-center gap-x-1 mb-1 ">
                Search
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="m19.6 21l-6.3-6.3q-.75.6-1.725.95T9.5 16q-2.725 0-4.612-1.888T3 9.5q0-2.725 1.888-4.612T9.5 3q2.725 0 4.612 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l6.3 6.3l-1.4 1.4ZM9.5 14q1.875 0 3.188-1.313T14 9.5q0-1.875-1.313-3.188T9.5 5Q7.625 5 6.312 6.313T5 9.5q0 1.875 1.313 3.188T9.5 14Z"
                    />
                  </svg>{" "}
                </span>
              </div>
              <div className={`lg:w-52 md:w-32`}>
                <CustomInput
                  placeholder="Search"
                  className="custom-input"
                  value={tag}
                  onChange={(e) => {
                    setTag(e.target.value);
                    searchTag(e);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="mob-w-100">
            <div className="flex gap-2">
              <div>
                <div className="text-sm text-buttonTheme font-medium flex items-center gap-x-1 mb-1 mr-5 md:mr-0">
                  City
                </div>
                <div className="lg:w-[200px] sm:w-[120px] xs:w-[190px]">
                  <Select
                    mode={"multiple"}
                    disabled={
                      !isAdmin &&
                      typeof profile?.assignedCity != "undefined" &&
                      profile?.assignedCity.length === 1
                    }
                    allowClear
                    className="w-full"
                    status="blue"
                    rootClassName="border-red-500"
                    placeholder="Select City"
                    options={cityOptions}
                    onChange={(e) => {
                      if (e) {
                        setCity(e);
                        if (e && !e.includes("London")) {
                          setAreaFilter("");
                          areaFilterValueUpdate = "";
                        }
                        getReservations(
                          limit,
                          page,
                          checkOutDay,
                          sortBy,
                          checkIn,
                          checkOut,
                          create,
                          tag,
                          e,
                          areaFilterValueUpdate,
                          cityForUser
                        );
                        getReservationsCount(
                          checkOutDay,
                          checkIn,
                          checkOut,
                          create,
                          tag,
                          e,
                          areaFilterValueUpdate,
                          cityForUser
                        );
                      } else {
                        setCity(null);
                        setAreaFilter("");
                        getReservations(
                          limit,
                          page,
                          checkOutDay,
                          sortBy,
                          checkIn,
                          checkOut,
                          create,
                          tag,
                          null,
                          "",
                          cityForUser
                        );
                        getReservationsCount(
                          checkOutDay,
                          checkIn,
                          checkOut,
                          create,
                          tag,
                          null,
                          "",
                          cityForUser
                        );
                      }
                    }}
                    value={city !== null ? city : undefined}
                  />
                </div>
              </div>
              {city?.includes("London") && (
                <div>
                  <div className="text-sm text-buttonTheme font-medium flex items-center gap-x-1 mb-1 mr-5 md:mr-0">
                    Area
                  </div>
                  <div className="w-[120px]">
                    <Select
                      allowClear
                      className="w-full"
                      status="blue"
                      rootClassName="border-red-500"
                      placeholder="Select Area"
                      options={[
                        //{ label: "Bookings Algiers", value: "Bookings Algiers" },
                        { label: "East London", value: "East London" },
                        { label: "West London", value: "West London" },
                        { label: "South London", value: "South London" },
                        { label: "North London", value: "North London" },
                        // { label: "Bookings Dubai", value: "Bookings Dubai" },
                        // { label: "Bookings Paris", value: "Bookings Paris" },
                        // {
                        //     label: "Bookings Birmingham",
                        //     value: "Bookings Birmingham",
                        // },
                        { label: "South East London", value: "SE" },
                        { label: "South West London", value: "SW" },
                        { label: "North East London", value: "NE" },
                        { label: "North West London", value: "NW" },
                      ]}
                      onChange={(e) => {
                        if (e) {
                          setAreaFilter(e);
                          searchArea(e);
                        } else {
                          setAreaFilter(null);
                          searchArea(null);
                        }
                      }}
                      value={areaFilter}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mob-w-100">
          <div style={{ width: "100%" }}>
            <div className={`grid gap-x-2 grid-cols-2`}>
              <div>
                <div className="text-sm text-buttonTheme font-medium flex items-center gap-x-1 mb-1">
                  Check In
                  <span style={{ transform: "rotateY(180deg)" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M19.002 3h-14c-1.103 0-2 .897-2 2v4h2V5h14v14h-14v-4h-2v4c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.898-2-2-2z"
                      />
                      <path
                        fill="currentColor"
                        d="m11 16l5-4l-5-4v3.001H3v2h8z"
                      />
                    </svg>
                  </span>
                </div>
                <div className="button-container">
                  <div className="flex flex-wrap md:flex-nowrap gap-y-2 md:gap-y-0 items-center gap-x-1">
                    <DatePicker
                      labelName="check In date"
                      placeholder="CheckIn"
                      className="h-8"
                      size="small"
                      allowClear={true}
                      value={checkIn ? dayjs(checkIn) : null}
                      onChange={(e, stringDate) => {
                        const d = !stringDate ? null : new Date(stringDate);
                        setCheckIn(d);
                        getReservations(
                          limit,
                          page,
                          checkOutDay,
                          sortBy,
                          d,
                          checkOut,
                          create,
                          tag,
                          city,
                          areaFilter,
                          cityForUser
                        );
                        getReservationsCount(
                          checkOutDay,
                          d,
                          checkOut,
                          create,
                          tag,
                          city,
                          areaFilter,
                          cityForUser
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="text-sm text-buttonTheme font-medium flex items-center gap-x-1 mb-1">
                  Check Out
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M19.002 3h-14c-1.103 0-2 .897-2 2v4h2V5h14v14h-14v-4h-2v4c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.898-2-2-2z"
                      />
                      <path
                        fill="currentColor"
                        d="m11 16l5-4l-5-4v3.001H3v2h8z"
                      />
                    </svg>
                  </span>
                </div>
                <div className="button-container">
                  <div className="flex flex-wrap md:flex-nowrap gap-y-1 md:gap-y-0 items-center gap-x-1 ">
                    <DatePicker
                      labelName="check Out date"
                      placeholder="CheckOut"
                      className="h-8"
                      size="small"
                      allowClear={true}
                      value={checkOut ? dayjs(checkOut) : null}
                      onChange={(e, stringDate) => {
                        const d = !stringDate ? null : new Date(stringDate);
                        setCheckOut(d);
                        getReservations(
                          limit,
                          page,
                          checkOutDay,
                          sortBy,
                          checkIn,
                          d,
                          create,
                          tag,
                          city,
                          areaFilter
                        );
                        getReservationsCount(
                          checkOutDay,
                          checkIn,
                          d,
                          create,
                          tag,
                          city,
                          areaFilter
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
