import { Form, Checkbox, Tooltip } from "antd";
import React from "react";
import HelpIcon from "@mui/icons-material/Help";

export default function FormCheckBox({
  name,
  rules = [
    {
      required: false,
    },
  ],
  className = "",
  disabled = false,
  icon,
  label,
  onChange,
  tooltipTitle,
  toolTipPlacement = "top",
  toolTipFlag = false,

}) {
  return (<label className="flex gap-2 items-center cursor-pointer py-0" htmlFor={name}>
    <Form.Item name={name}
      valuePropName="checked"
      rules={rules} className="mb-0 "  >
      <Checkbox className={className} disabled={disabled} id={name} onChange={onChange}>
      </Checkbox>
    </Form.Item>
    {label}
    {toolTipFlag && (
      <Tooltip placement={toolTipPlacement} title={tooltipTitle}>
        <HelpIcon style={{ fontSize: 18 }} className="text-labelBlue ml-1 mb-1" />
      </Tooltip>
    )}
  </label>
  )
}