import React from 'react'
import { Form } from 'antd'

import { manageToast } from '../../../components/Common/ManageToast'
import { serverlessApi } from '../../../utils/API'

import LockBoxModal from './Modal'

function LockBoxCreateModal (props) {
  const { onClose, open, triggerLockBoxCollectionRefetch } = props

  const [form] = Form.useForm()

  const [loading, setLoading] = React.useState(false)

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  const handleLockBoxCreate = values => {
    return Promise.resolve()
      .then(() => setLoading(true))
      .then(() => {
        return serverlessApi.createLockBox({ ...values }).then(res => {
          triggerLockBoxCollectionRefetch()
          setLoading(false)
          manageToast(true, res.data.message)
          handleClose()
        })
      })
      .catch(() => setLoading(false))
  }

  return (
    <LockBoxModal
      form={form}
      formName="lockbox-create"
      handleClose={handleClose}
      handleSubmit={handleLockBoxCreate}
      open={open}
      submitButtonLabel="Create"
      loading={loading}
      title="Add New LockBox"
    />
  )
}

export default LockBoxCreateModal
