import { format } from "date-fns";

const ReservationOverviewCard = ({ item, cleaning = false }) => {
  const getFormattedDate = (checkout) => {
    const date = new Date(checkout);
    let formattedDate = format(date, "yyyy-MM-dd");
    return formattedDate;
  };
  return (
    <div className="bg-[#cbd8d8] p-2 border-2 border-gray-500 rounded-sm">
      <h2 className="font-bold text-xs">{item.guest}</h2>
      <p className="text-xs">{item.allocatedProperty}</p>
      {!cleaning ? (
        <p className="text-xs">
          Check out: {getFormattedDate(item.checkOutDate)}
        </p>
      ) : (
        <p className="text-xs">
          Cleaning Status:{" "}
          {item.status === "pending"
            ? "Pending"
            : item.status === "stuck"
            ? "Stuck"
            : item.status === "inProgress"
            ? "In Progress"
            : item.status === "complaint"
            ? "Complaint"
            : item.status === "completed"
            ? "Completed"
            : item.status === "notRequired"
            ? "Not Required"
            : ""}
        </p>
      )}
    </div>
  );
};

export default ReservationOverviewCard;
