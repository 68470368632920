import { Input } from "antd";

const CustomInput = ({ inputLabel, placeholder, width, onChange, value }) => {
  return (
    <div>
      {inputLabel && <label className="block mb-2 text-sm font-medium  text-gray-900">
        {inputLabel}
      </label>}
      <Input
        className={`${width}`}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default CustomInput;
