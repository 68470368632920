import React from 'react'
import { Select } from 'antd'

import CustomInput from '../../components/Input/Input'

let timeOut = null

export default function TopBar (props) {
  const {
    count,
    setName,
    available,
    setAvailable,
  } = props

  const [searchName, setSearchName] = React.useState('')

  return (
    <div className="mb-4">
      <div className={`flex flex-wrap md:flex-nowrap gap-2 md:gap-0 
        justify-between items-center mb-4`}>
        <div className="text-themeGrey text-xl font-bold">
          Property Report
          {count > 0 && <span className="text-base">({count})</span>}
        </div>
      </div>
      <div className="flex  gap-x-6 justify-between">
        <div className="mob-w-100">
          <div className="flex gap-2 flex-wrap">
            <div>
              <div className={`text-sm text-buttonTheme font-medium flex 
                items-center gap-x-1 mb-1 mr-5 md:mr-0`}>
                Search
              </div>
              <div className="w-[200px]">
                <CustomInput
                  placeholder={'Search'}
                  className="custom-input"
                  value={searchName}
                  onChange={(e) => {
                    setSearchName(e.target.value)
                    if (timeOut) {
                      clearTimeout(timeOut)
                    }
                    timeOut = setTimeout(() => {
                      setName(e.target.value)
                    }, 1000)
                  }}
                />
              </div>
            </div>

            <div>
              <div className={`text-sm text-buttonTheme font-medium flex 
                items-center gap-x-1 mb-1 mr-5 md:mr-0`}>
                Availability
              </div>
              <div className="flex gap-x-0 md:gap-x-3">
                <Select
                  value={available}
                  placeholder={'Availability'}
                  allowClear
                  options={[
                    { value: 'y', label: 'Available' },
                    { value: 'n', label: 'Unavailable' },
                  ]}
                  className="w-44"
                  onChange={(l) => {
                    setAvailable(l)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
