import React from "react";
import { Input, Switch } from "antd";
import { Help } from "@mui/icons-material";
import TextArea from "antd/es/input/TextArea";
import InfoIcon from "@mui/icons-material/Info";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const BookingEngineAdvancedSettings = () => {
  return (
    <div>
      {/* Choose domain name section */}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-1">
          <div className="font-bold">Choose domain name</div>
          <Help style={{ fontSize: 18 }} className="text-labelBlue" />
        </div>
        <div className="font-sm text-themeGreen cursor-pointer">
          Learn how to use custom domain for the Booking Engine
        </div>
      </div>
      <div className="xs:w-full lg:w-[70%] pb-10 border-b-2 border-creme">
        <div className="grid xs:grid-cols-1 lg:grid-cols-2 items-center justify-between">
          <div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-3">
            <div className="text-themeGrey">
              Use Flexliving pre-built domain
              <InfoIcon style={{ fontSize: 16 }} />
            </div>
            <div>
              <Switch />
            </div>
          </div>
          <div className="grid xs:grid-cols-2 lg:grid-cols-2">
            <div className="col-span-2 text-themeGrey">Sub-domain name</div>
            <Input placeholder="abc" className="rounded-none" />
            <Input placeholder=".holidayfuture.com" className="rounded-none" />
          </div>
        </div>
      </div>
      {/* Use your own domain section */}
      <div className="xs:w-full lg:w-[70%] pb-10 border-b-2 border-creme mt-5">
        <div className="grid xs:grid-cols-1 lg:grid-cols-2 items-center justify-between">
          <div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-3">
            <div className="text-themeGrey">Use your own domain</div>
            <div>
              <Switch />
            </div>
            <div className="col-span-2">Use main domain</div>
            <div className="col-span-2 flex items-center">
              <ContentCopyIcon
                style={{ fontSize: 16 }}
                className="text-themeGreen cursor-pointer mr-2"
              />
              <div className="text-themeGreen cursor-pointer">Copy CNAME</div>
            </div>
            <div className="col-span-2 flex items-center">
              <ContentCopyIcon
                style={{ fontSize: 16 }}
                className="text-themeGreen cursor-pointer mr-2"
              />
              <div className="text-themeGreen cursor-pointer">
                Copy a record
              </div>
            </div>
          </div>
          <div className="grid xs:grid-cols-2 lg:grid-cols-2">
            <div className="col-span-2 text-themeGrey">Domain name</div>
            <Input
              placeholder="www.customdomain.com"
              className="col-span-2 rounded-none"
            />
          </div>
        </div>
      </div>
      {/* Use sub-domain section */}
      <div className="xs:w-full lg:w-[70%] pb-10 border-b-2 border-creme mt-5">
        <div className="grid xs:grid-cols-1 lg:grid-cols-2 items-center justify-between">
          <div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-3">
            <div className="col-span-2">Use sub domain</div>
            <div className="col-span-2 flex items-center">
              <ContentCopyIcon
                style={{ fontSize: 16 }}
                className="text-themeGreen cursor-pointer mr-2"
              />
              <div className="text-themeGreen cursor-pointer">Copy CNAME</div>
            </div>
          </div>
          <div className="grid xs:grid-cols-2 lg:grid-cols-2">
            <div className="text-themeGrey">Subdomain name</div>
            <div className="text-themeGrey">Domain name</div>
            <Input
              placeholder="www.customdomain.com"
              className="rounded-none"
            />
            <Input
              placeholder="www.customdomain.com"
              className="rounded-none"
            />
          </div>
        </div>
      </div>
      {/* Captcha section */}
      <div className="xs:w-full lg:w-[70%] pb-10 border-b-2 border-creme mt-5">
        <div className="grid xs:grid-cols-1 lg:grid-cols-2 items-center justify-between">
          <div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-3">
            <div className="col-span-2 text-themeGrey">
              reCaptcha v2 Site Key
            </div>
            <Input
              placeholder="reCaptcha v2 Site Key"
              className="col-span-2 rounded-none"
            />
          </div>
          <div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-3">
            <div className="col-span-2 text-themeGrey">
              reCaptcha v2 Secret Key
            </div>
            <Input
              placeholder="reCaptcha v2 Secret Key"
              className="col-span-2 rounded-none"
            />
          </div>
        </div>
      </div>
      {/* Scripts section */}

      <div className="grid xs:grid-cols-1 lg:grid-cols-2">
        <div>
          <div className="font-bold mt-5">Script</div>
          <div className="text-themeGrey my-1">Header script</div>
          <TextArea placeholder="Header Script" rows={10} className="mb-5" />
          <div className="text-themeGrey my-1">Body script</div>
          <TextArea placeholder="Body Script" rows={10} className="mb-5" />
          <div className=" font-bold my-1">Embeddable search bar</div>
          <p className="text-themeGrey mb-5">
            Edit this and paste your code in the website to embed a search bar
            that sends guests to your booking engine. For instructions on how to
            use it, click
            <span className="text-themeGreen cursor-pointer ml-1">here.</span>
          </p>
          <TextArea rows={10} className="bg-themeSkin mb-5" />
          <div className=" font-bold my-1">Embeddable calendar widget</div>
          <p className="text-themeGrey mb-5">
            Edit this and paste your code in the website to embed a calendar
            that show a listing availability and sends guests directly to
            checkout. For instructions on how to use it, click
            <span className="text-themeGreen cursor-pointer ml-1">here.</span>
          </p>
          <TextArea rows={20} className="bg-themeSkin mb-10" />
          <div className=" font-bold my-2">Analytics</div>
          <div className="text-themeGrey my-1">
            Google analytics tracking ID
          </div>
          <Input
            className="w-full mb-5"
            placeholder="Google analytics tracking ID"
          />
          <div className="text-themeGrey my-1">Facebook Pixel</div>
          <Input className="w-full mb-5" placeholder="Facebook Pixel" />
          <div className=" font-bold mb-1 mt-10">
            SEO
            <Help
              className="ml-1mb-1 text-labelBlue cursor-pointer"
              style={{ fontSize: 18 }}
            />
          </div>
          <div className="text-themeGrey my-1">Meta title</div>
          <Input className="w-full mb-5" placeholder="Meta title" />
          <div className="text-themeGrey my-1">Meta description</div>
          <Input className="w-full mb-5" placeholder="Meta description" />
          <div className="text-themeGrey my-1">Keywords</div>
          <Input className="w-full mb-5" placeholder="Keywords" />
        </div>
      </div>
    </div>
  );
};

export default BookingEngineAdvancedSettings;
