import React, { useState } from "react";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import GenericButton from "../../components/GenericButton/GenericButton";
import { Input, Select, Switch, Upload } from "antd";
import TextEditor from "../../components/TextEditor/TextEditor";

const BookingEnginePages = () => {
  const [createCustomPage, setCreateCustomPage] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  return (
    <div className="grid xs:grid-cols-1 lg:grid-cols-10 gap-2">
      <div className="xs:col-span-1 lg:col-span-2 overflow-auto border-r-2 px-2 border-creme">
        <div className="mb-5">
          <div className="font-bold mt-10 p">Pages</div>
          {/* Row */}
          <div className="mt-5 flex items-center justify-between">
            <div className="text-black text-md font-bold">Home</div>
            <BorderColorIcon
              style={{ fontSize: 20 }}
              className="text-themeGrey"
            />
          </div>
          {/* Row */}
          <div className="mt-5 flex items-center justify-between">
            <div className="text-black text-md font-bold">All Listings</div>
            <BorderColorIcon
              style={{ fontSize: 20 }}
              className="text-themeGrey"
            />
          </div>
          {/* Row */}
          <div className="mt-5 flex items-center justify-between">
            <div className="text-black text-md font-bold">About us</div>
            <BorderColorIcon
              style={{ fontSize: 20 }}
              className="text-themeGrey"
            />
          </div>
          {/* Row */}
          <div className="mt-5 flex items-center justify-between">
            <div className="text-black text-md font-bold">Privacy Policy</div>
            <BorderColorIcon
              style={{ fontSize: 20 }}
              className="text-themeGrey"
            />
          </div>
          {/* Row */}
          <div className="mt-5 flex items-center justify-between">
            <div className="text-black text-md font-bold">
              Terms and Condition
            </div>
            <BorderColorIcon
              style={{ fontSize: 20 }}
              className="text-themeGrey"
            />
          </div>
          {/* Row */}
          <div className="mt-5 flex items-center justify-between">
            <div className="text-black text-md font-bold">Contact us</div>
            <BorderColorIcon
              style={{ fontSize: 20 }}
              className="text-themeGrey"
            />
          </div>
          {/* Row */}
          <div className="mt-5 flex items-center justify-between">
            <div className="text-black text-md font-bold">
              Reservation confirmation
            </div>
            <BorderColorIcon
              style={{ fontSize: 20 }}
              className="text-themeGrey"
            />
          </div>
          {/* Row */}
          <div className="mt-5 flex items-center justify-between">
            <div className="text-black text-md font-bold">Inquiry received</div>
            <BorderColorIcon
              style={{ fontSize: 20 }}
              className="text-themeGrey"
            />
          </div>
        </div>
        <GenericButton
          label="Create custom page"
          size="middle"
          className="bg-themeSecondaryGreen rounded-none"
          onClick={() => {
            setCreateCustomPage(true);
          }}
        />
        <div className="my-5">
          <p className="mb-2 text-black">Header menu</p>
          <Select
            bordered={true}
            className=" w-[100%] border-2 border-creme mb-5"
            showSearch
            placeholder={<div className="italic">All Categories</div>}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
          />
          <Select
            bordered={true}
            className=" w-[100%] border-2 border-creme mb-5"
            showSearch
            placeholder={<div className="italic">All Categories</div>}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
          />
          <Select
            bordered={true}
            className=" w-[100%] border-2 border-creme mb-5"
            showSearch
            placeholder={<div className="italic">All Categories</div>}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
          />
          <Select
            bordered={true}
            className=" w-[100%] border-2 border-creme mb-5"
            showSearch
            placeholder={<div className="italic">All Categories</div>}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
          />
          <Select
            bordered={true}
            className=" w-[100%] border-2 border-creme mb-5"
            showSearch
            placeholder={<div className="italic">All Categories</div>}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
          />
          <Select
            bordered={true}
            className=" w-[100%] border-2 border-creme mb-5"
            showSearch
            placeholder={<div className="italic">All Categories</div>}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
          />
        </div>
        <div className="my-5">
          <p className="mb-2 text-black">Footer menu</p>
          <Select
            bordered={true}
            className=" w-[100%] border-2 border-creme mb-5"
            showSearch
            placeholder={<div className="italic">All Categories</div>}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
          />
          <Select
            bordered={true}
            className=" w-[100%] border-2 border-creme mb-5"
            showSearch
            placeholder={<div className="italic">All Categories</div>}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
          />
          <Select
            bordered={true}
            className=" w-[100%] border-2 border-creme mb-5"
            showSearch
            placeholder={<div className="italic">All Categories</div>}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
          />
          <Select
            bordered={true}
            className=" w-[100%] border-2 border-creme mb-5"
            showSearch
            placeholder={<div className="italic">All Categories</div>}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
          />
          <Select
            bordered={true}
            className=" w-[100%] border-2 border-creme mb-5"
            showSearch
            placeholder={<div className="italic">All Categories</div>}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
          />
          <Select
            bordered={true}
            className=" w-[100%] border-2 border-creme mb-5"
            showSearch
            placeholder={<div className="italic">All Categories</div>}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
          />
        </div>
      </div>
      {createCustomPage ? (
        <div className="xs:col-span-1 lg:col-span-8 pl-2">
          <div className="flex items-center justify-between">
            <GenericButton
              label="Back"
              className="bg-gray-200 text-black"
              onClick={() => {
                setCreateCustomPage(false);
              }}
            />
            <div className="text-themeGrey xs:text-sm sm:text-xl md:text-3xl">
              Create custom page
            </div>
            <GenericButton label="Save" className="bg-themeGreen" />
          </div>
          <div className=" m-5 grid xs:grid-cols-1 lg:grid-cols-2">
            <div>
              <div className="flex items-center justify-between my-2">
                <div className="text-themeGrey text-sm">
                  Open in a new window
                </div>
                <Switch />
              </div>
              <div className="flex items-center justify-between my-2">
                <div className="text-themeGrey text-sm flex items-center">
                  <p>Open external URL </p>
                  <Switch className="ml-1" />
                </div>
                <Input placeholder="External URL" className="w-48" />
              </div>
              <div className="col-span-2">
                <div>
                  <div className="text-themeGrey">Title</div>
                  <Input
                    placeholder="Title"
                    className="w-[100%] rounded-none mt-1 mb-5"
                  />
                </div>
              </div>
              <div className="col-span-2">
                <div>
                  <div className="text-themeGrey">Header</div>
                  <Input
                    placeholder="Header"
                    className="w-[100%] rounded-none mt-1 mb-5"
                  />
                </div>
              </div>
              <div className="col-span-2">
                <div className="flex items-center justify-between">
                  <div className="font-bold text-themeGrey">
                    Header background image
                  </div>
                  <GenericButton
                    label="Delete"
                    className="bg-gray-300 text-gray-400 rounded-none"
                  />
                </div>
              </div>
              <div className="col-span-2">
                <div className="flex items-center justify-center ">
                  <Upload
                    name="avatar"
                    className="my-5 h-40 w-[100%] flex items-center justify-center border-2 border-themeGrey border-dotted"
                    showUploadList={false}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  >
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        className="w-[100%]"
                        alt="avatar"
                        style={{
                          width: "100%",
                        }}
                      />
                    ) : (
                      "Drag user photo and drop it here."
                    )}
                  </Upload>
                </div>
              </div>
              <div className="col-span-2">
                <div>
                  <div className="text-themeGrey">Sub-title</div>
                  <Input
                    placeholder="Sub-title"
                    className="w-[100%] rounded-none mt-1 mb-5"
                  />
                </div>
              </div>
              <div className="col-span-1 my-5">
                <TextEditor />
              </div>
              <div className="col-span-2">
                <div className="flex items-center justify-between">
                  <div className="font-bold text-themeGrey">Image</div>
                  <GenericButton
                    label="Delete"
                    className="bg-gray-300 text-gray-400 rounded-none"
                  />
                </div>
              </div>

              <div className="col-span-2">
                <div className="flex items-center justify-center ">
                  <Upload
                    name="avatar"
                    className="my-5 h-40 w-[100%] flex items-center justify-center border-2 border-themeGrey border-dotted"
                    showUploadList={false}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  >
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        className="w-[100%]"
                        alt="avatar"
                        style={{
                          width: "100%",
                        }}
                      />
                    ) : (
                      "Drag user photo and drop it here."
                    )}
                  </Upload>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="xs:col-span-1 lg:col-span-8 flex items-center justify-center">
          <div className="text-themeGrey text-2xl">
            To preview your website, please publish it using the button at the
            top-right
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingEnginePages;
