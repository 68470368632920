import React, { useState } from "react";
import BookingEngineBasicSettings from "../../components/BookingEngineBasicSettings/BookingEngineBasicSettings";
import BookingEngineAdvancedSettings from "../../components/BookingEngineAdvancedSettings/BookingEngineAdvancedSettings";

const BookingEngineSettings = () => {
  const [settingType, setSettingType] = useState("basic");

  return (
    <div className="grid grid-cols-10 gap-2">
      <div className="xs:grid-cols-1 md:col-span-2 px-2">
        <div
          className={`h-10 pl-5 flex items-center text-themeGreen hover:text-themeSecondaryGreen hover:bg-creme w-[100%] ${
            settingType === "basic" && "bg-creme"
          }`}
          onClick={() => {
            setSettingType("basic");
          }}
        >
          <div>Basic</div>
        </div>
        <div
          className={`h-10 px-5 flex items-center text-themeGreen hover:text-themeSecondaryGreen hover:bg-creme w-[100%] ${
            settingType === "advanced" && "bg-creme"
          }`}
          onClick={() => {
            setSettingType("advanced");
          }}
        >
          <div>Advanced</div>
        </div>
      </div>
      <div className="col-span-8">
        {settingType === "basic" ? (
          <BookingEngineBasicSettings />
        ) : (
          <BookingEngineAdvancedSettings />
        )}
      </div>
    </div>
  );
};

export default BookingEngineSettings;
