import React from "react";
import "react-quill/dist/quill.snow.css";
import { Form, Tooltip } from "antd";
import HelpIcon from "@mui/icons-material/Help";
const ReactQuill = require("react-quill");

const TextEditor = ({
  name,
  rules = [],
  label,
  tooltipTitle,
  toolTipPlacement = "top",
  toolTipFlag = false,
  handleAddCustomFieldToMessage,
  quillRef,
}) => {
  const isRequired = rules?.find(({ required }) => required === true);

  return (
    <div className="custom-form-input">
      <div className="flex items-center ">
        {label && (
          <label className="font-bold text-themeGrey capitalize">
            {label}{" "}
            {isRequired && (
              <span className="text-cancelButton inline-flex items-center">
                *
              </span>
            )}
            {toolTipFlag && (
              <Tooltip placement={toolTipPlacement} title={tooltipTitle}>
                <HelpIcon className="text-sky-600 !text-[17px] ml-1 cursor-pointer" />
              </Tooltip>
            )}
          </label>
        )}
      </div>
      <Form.Item name={name} rules={rules} className="mb-0">
        <HtmlEditor
          handleAddCustomFieldToMessage={handleAddCustomFieldToMessage}
          quillRef={quillRef}
        />
      </Form.Item>
    </div>
  );
};

export default TextEditor;

const HtmlEditor = ({
  value,
  onChange,
  placeholder,
  handleAddCustomFieldToMessage,
  quillRef,
}) => {
  const handleEditorClick = () => {
    handleAddCustomFieldToMessage();
  };

  const editorModules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        // { indent: '-1' },
        // { indent: '+1' },
      ],
      [
        "link",
        // 'image', 'video'
      ],
      // ['clean'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const editorFormats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    // 'image',
    // 'video',
  ];

  const formattedValue = String(value).replace(/\n/g, "<br>");

  return (
    <>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={formattedValue || ""}
        modules={editorModules}
        formats={editorFormats}
        onChange={onChange}
        placeholder={placeholder}
        onClick={handleEditorClick}
      />
    </>
  );
};
