import React from 'react'

import GenericButton from '../../../components/GenericButton/GenericButton'

import Modal from './Modal'

export default function ExcelParser (props) {
  const { refetchSummaryData } = props

  const [isOpen, setIsOpen] = React.useState(false)
  const [selectedFileList, setSelectedFileList] = React.useState([])
  const [error, setError] = React.useState('')
  const [fileBase64, setFileBase64] = React.useState('')

  const handleModalOpen = () => {
    setIsOpen(true)
  }

  const handleModalClose = () => {
    setSelectedFileList([])
    setFileBase64('')
    setError('')
    setIsOpen(false)
  }

  return (
    <>
      <GenericButton
        label="Import Excel file"
        loadingClass="text-white"
        className={`disabled:hover:!bg-buttonTheme disabled:hover:text-white 
          disabled:!text-white disabled:bg-buttonTheme hover:!bg-buttonHoverTheme 
          hover:text-white bg-buttonTheme mr-5 shadow-0`}
        onClick={handleModalOpen}
      />

      <Modal
        setSelectedFileList={setSelectedFileList}
        selectedFileList={selectedFileList}
        fileBase64={fileBase64}
        setFileBase64={setFileBase64}
        onClose={handleModalClose}
        refetchSummaryData={refetchSummaryData}
        setError={setError}
        error={error}
        open={isOpen}
      />
    </>
  )
}