export default function Loading() {
    return (<div className="h-40 flex justify-center items-center">
        <svg xmlns="http://www.w3.org/2000/svg" style={{ margin: "auto", display: "block" }} width="100px" height="100px" viewBox="0 0 100 100">
            <g transform="translate(20 50)">
                <circle cx="0" cy="0" r="6" fill="#00b4b4">
                    <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
                </circle>
            </g><g transform="translate(40 50)">
                <circle cx="0" cy="0" r="6" fill="#00b4b4">
                    <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
                </circle>
            </g><g transform="translate(60 50)">
                <circle cx="0" cy="0" r="6" fill="#00b4b4">
                    <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
                </circle>
            </g><g transform="translate(80 50)">
                <circle cx="0" cy="0" r="6" fill="#00b4b4">
                    <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
                </circle>
            </g>
        </svg>
    </div>)
}