import { Help } from "@mui/icons-material";
import { Select, Switch, Upload } from "antd";
import React, { useState } from "react";

const BookingEngineDesign = () => {
  const [imageUrl, setImageUrl] = useState();
  return (
    <div className="grid grid-cols-10 gap-2">
      {/* Scroll bar */}
      <div className="col-span-2 overflow-auto border-r-2 px-2 border-creme">
        <div className="font-bold ">
          Upload background image/video
          <Help style={{ fontSize: 18 }} className="text-labelBlue ml-1" />
        </div>
        <div className="flex items-center justify-center ">
          <Upload
            name="avatar"
            className="my-5 h-36 w-96 flex items-center justify-center border-2 border-themeGrey border-dotted"
            showUploadList={false}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                className="w-[100%]"
                alt="avatar"
                style={{
                  width: "100%",
                }}
              />
            ) : (
              "Drag user photo and drop it here."
            )}
          </Upload>
        </div>
        {/* Color table */}
        <div>
          <div className="font-bold mt-10">Colors</div>
          {/* Row */}
          <div className="mt-5 flex items-center justify-between">
            <div className="text-themeGrey text-xs">Brand color</div>
            <div className="h-6 w-14 border border-black rounded-md bg-labelBlue"></div>
          </div>
          {/* Row */}
          <div className="mt-5 flex items-center justify-between">
            <div className="text-themeGrey text-xs">Middle color</div>
            <div className="h-6 w-14 border border-black rounded-md bg-labelDarkBlue"></div>
          </div>
          {/* Row */}
          <div className="mt-5 flex items-center justify-between">
            <div className="text-themeGrey text-xs">Dark color</div>
            <div className="h-6 w-14 border border-black rounded-md bg-black"></div>
          </div>
          {/* Row */}
          <div className="mt-5 flex items-center justify-between">
            <div className="text-themeGrey text-xs">Header color</div>
            <div className="h-6 w-14 border border-black rounded-md bg-black"></div>
          </div>
          {/* Row */}
          <div className="mt-5 flex items-center justify-between">
            <div className="text-themeGrey text-xs">Header text color</div>
            <div className="h-6 w-14 border border-black rounded-md bg-black"></div>
          </div>
          {/* Row */}
          <div className="mt-5 flex items-center justify-between">
            <div className="text-themeGrey text-xs">Footer color</div>
            <div className="h-6 w-14 border border-black rounded-md bg-black"></div>
          </div>
          {/* Row */}
          <div className="mt-5 flex items-center justify-between">
            <div className="text-themeGrey text-xs">Footer text color</div>
            <div className="h-6 w-14 border border-black rounded-md bg-black"></div>
          </div>
        </div>
        <div>
          <div className="font-bold my-5">Fonts</div>
          <p className="text-themeGrey">Headings</p>
          <Select
            className="w-[100%] border-2 border-creme"
            bordered={false}
            showSearch
            placeholder={<div className="italic">Select Headings</div>}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
          />
          <p className="text-themeGrey mt-5">Text</p>
          <Select
            className="w-[100%] border-2 border-creme"
            bordered={false}
            showSearch
            placeholder={<div className="italic">Select Text</div>}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
          />
        </div>
        <div>
          <div className="font-bold my-5">Elements</div>
          <div className="mt-5 flex items-center justify-between">
            <div className="text-themeGrey text-xs">Rounded Elements</div>
            <Switch defaultChecked />
          </div>
          <div className="mt-5 flex items-center justify-between">
            <div className="text-themeGrey text-xs">Shadow behind buttons</div>
            <Switch defaultChecked />
          </div>
        </div>
      </div>
      {/* Preview */}
      <div className="col-span-8 flex items-center justify-center">
        <div className="text-themeGrey text-2xl">
          To preview your website, please publish it using the button at the
          top-right
        </div>
      </div>
    </div>
  );
};

export default BookingEngineDesign;
