import React, { useState } from "react";
import DocumentTemplateTab from "../../components/DocumentTemplateTab/DocumentTemplateTab";
import GenericButton from "../../components/GenericButton/GenericButton";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Checkbox,
  ColorPicker,
  Dropdown,
  Input,
  Radio,
  Space,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { documentTemplatesCustomFieldOptions } from "../../enum";
import DocumentTemplateReceipt from "../../components/DocumentTemplateReceipt/DocumentTemplateReceipt";

const GuestPaymentsDocumentTemplates = () => {
  const [receiptType, setReceiptType] = useState("charge");
  const [value, setValue] = useState(1);

  return (
    <div className="py-5">
      <div className="lg:flex md:gap-2 items-center justify-between">
        <div className="flex xs:flex-col sm:flex-row items-center gap-4 my-2">
          <DocumentTemplateTab
            isSelected={receiptType === "charge"}
            tabHeader="Charge receipt"
            tabText="Generated upon transaction"
            onClick={() => {
              setReceiptType("charge");
            }}
          />
          <DocumentTemplateTab
            isSelected={receiptType === "invoice"}
            tabHeader="Invoice"
            tabText="Generated upon reservation and reservation alterations"
            onClick={() => {
              setReceiptType("invoice");
            }}
          />
        </div>
        <div className="flex items-center justify-center gap-2">
          <GenericButton
            label="Reset template"
            className="bg-white text-red-500"
          />
          <GenericButton label="Save" className="bg-themeGreen text-white" />
        </div>
      </div>
      <div className="grid xs:grid-cols-1 md:grid-cols-8">
        <div className="col-span-2 overflow-y-auto border-r-2 border-creme">
          <div>
            <div>Logo</div>
            <GenericButton
              label="Add logo"
              size="large"
              className="bg-selectedGreen text-themeGreen rounded-none"
              iconLeftAlign={true}
              icon={<AddIcon />}
            />
          </div>
          <div className="py-5 border-b-2 border-creme">
            <div className="mb-2">Colors</div>
            <div className="grid xs:grid-cols-1 lg:grid-cols-2">
              <div className="flex flex-row items-center xs:justify-start lg:justify-center gap-1">
                <ColorPicker />
                <div>Background color</div>
              </div>
              <div className="flex flex-row items-center xs:justify-start lg:justify-center gap-1">
                <ColorPicker />
                <div>Accent color</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2 py-5 border-b-2 border-creme">
            <Checkbox>Host name</Checkbox>
            <Radio.Group value={value}>
              <Space direction="vertical">
                <Radio value={1}>Sid Flexliving test api account</Radio>
                <Radio value={2}>
                  Listing specific (contact person and invoicing)
                </Radio>
                <Radio value={3}>Option C</Radio>
                <Radio value={3}>
                  <Input placeholder="Custom name" className="w-full" />
                </Radio>
              </Space>
            </Radio.Group>
          </div>
          <div className="flex flex-col gap-2 py-5 border-b-2 border-creme">
            <Checkbox>Email</Checkbox>
            <Radio.Group value={value}>
              <Space direction="vertical">
                <Radio value={1}>yousfusud@gmail.com</Radio>
                <Radio value={2}>
                  Listing specific (contact person and invoicing)
                </Radio>
                <Radio value={3}>
                  <Input placeholder="Custom email" className="w-full" />
                </Radio>
              </Space>
            </Radio.Group>
          </div>
          <div className="flex flex-col gap-2 py-5 px-1 border-b-2 border-creme">
            <Checkbox>Additional Information</Checkbox>
            <TextArea placeholder="Additional information" rows={4} />
          </div>
          <div className="flex flex-col gap-2 py-5 border-b-2 border-creme">
            <Checkbox>Tax ID</Checkbox>
            <Radio.Group value={value}>
              <Space direction="vertical">
                <Radio value={1}>
                  Listing specific (contact person and invoicing)
                </Radio>
                <Radio value={1}>
                  <Input placeholder="Custom ID" className="w-full" />
                </Radio>
              </Space>
            </Radio.Group>
          </div>
          <div className="my-5">
            <p className="text-themeGrey font-sm">Custom fields</p>
            <Dropdown
              className="ml-28 mt-2"
              menu={{
                documentTemplatesCustomFieldOptions,
                selectable: true,
                multiple: true,
              }}
              placement="bottom"
              trigger={["click"]}
            >
              <Button className="text-themeGrey" type="text" />
            </Dropdown>
          </div>
        </div>
        <div className="col-span-6 overflow-y-auto w-full">
          <DocumentTemplateReceipt isSelected={receiptType === "charge"} />
        </div>
      </div>
    </div>
  );
};

export default GuestPaymentsDocumentTemplates;
