import React, { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";
import { FINANCIAL_REPORTING_ANALYTICS } from "../../constants/dashboardRoutes";
import { Help } from "@mui/icons-material";
import GenericButton from "../../components/GenericButton/GenericButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddCustomFinancialFormulaModal from "../../components/AddCustomFinancialFormulaModal/AddCustomFinancialFormulaModal";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Checkbox, Input, Select } from "antd";
import AddTaxModal from "../../components/AddTaxModal/AddTaxModal";
import TextArea from "antd/es/input/TextArea";

const FinancialReportingSettings = () => {
  const [
    isAddCustomFinancialFormulaModalOpen,
    setIsAddCustomFinancialFormulaModalOpen,
  ] = useState(false);
  const [isAddTaxModalOpen, setIsAddTaxModalOpen] = useState(false);
  return (
    <div className="m-5">
      <AddCustomFinancialFormulaModal
        isModalOpen={isAddCustomFinancialFormulaModalOpen}
        setIsModalOpen={setIsAddCustomFinancialFormulaModalOpen}
      />
      <AddTaxModal
        isModalOpen={isAddTaxModalOpen}
        setIsModalOpen={setIsAddTaxModalOpen}
      />
      <Link
        className="text-themeGreen flex items-center justify-start"
        to={FINANCIAL_REPORTING_ANALYTICS}
      >
        <ArrowBackIosIcon /> <div className="text-lg">Financial Reporting</div>
      </Link>
      <div className="my-10">
        <div className="flex gap-3 items-center justify-start">
          <div className="text-themeGrey text-xl font-bold">
            Financial formulas
          </div>
          <Help style={{ fontSize: 16 }} className="text-labelBlue" />
          <GenericButton
            label="Add custom financial formula"
            className="bg-selectedGreen text-themeGreen rounded-none"
            iconLeftAlign={true}
            icon={<AddCircleIcon />}
            onClick={() => {
              setIsAddCustomFinancialFormulaModalOpen(true);
            }}
          />
        </div>
        <div className="grid grid-cols-1 w-[85%]">
          {/* Input Item */}
          <div className="mt-5 mb-2">
            <div className="text-themeGrey font-bold mb-2 flex gap-2">
              <div>totalTax</div>
              <Help style={{ fontSize: 16 }} className="text-labelBlue" />
              <div className="font-normal">All channels | All statuses</div>
            </div>
            <div className="flex items-center gap-10">
              <Input
                className="w-full rounded-none"
                placeholder="Total Tax"
                value={
                  "vat + hotelTax + lodgingTax + salesTax + transientOccupancyTax + cityTax + roomTax + otherTaxes"
                }
                disabled={true}
              />
              <BorderColorIcon
                style={{ fontdSize: 20 }}
                className="text-themeGrey"
              />
            </div>
          </div>
          {/* Input Item */}
          <div className="mt-5 mb-2">
            <div className="text-themeGrey font-bold mb-2 flex gap-2">
              <div>totalGuestFees</div>
              <Help style={{ fontSize: 16 }} className="text-labelBlue" />
              <div className="font-normal">All channels | All statuses</div>
            </div>
            <div className="flex items-center gap-10">
              <Input
                className="w-full rounded-none"
                placeholder="Total guest fees"
                value={"cleaningFeeValue"}
                disabled={true}
              />
              <BorderColorIcon
                style={{ fontSize: 20 }}
                className="text-themeGrey"
              />
            </div>
          </div>
          {/* Input Item */}
          <div className="mt-5 mb-2">
            <div className="text-themeGrey font-bold mb-2 flex gap-2">
              <div>rentalRevenue</div>
              <Help style={{ fontSize: 16 }} className="text-labelBlue" />
              <div className="font-normal">All channels | All statuses</div>
            </div>
            <div className="flex items-center gap-10">
              <Input
                className="w-full rounded-none"
                placeholder="Rental revenue"
                value={
                  "baseRate + vat + hotelTax + lodgingTax + salesTax + transientOccupancyTax + cityTax + roomTax + otherTaxes + cleaningFeeValue"
                }
                disabled={true}
              />
              <BorderColorIcon
                style={{ fontSize: 20 }}
                className="text-themeGrey"
              />
            </div>
          </div>
          {/* Input Item */}
          <div className="mt-5 mb-2">
            <div className="text-themeGrey font-bold mb-2 flex gap-2">
              <div>subTotalPrice</div>
              <Help style={{ fontSize: 16 }} className="text-labelBlue" />
              <div className="font-normal">All channels | All statuses</div>
            </div>
            <div className="flex items-center gap-10">
              <Input
                className="w-full rounded-none"
                placeholder="Sub total price"
                value={"hostChannelFee + baseRate + cleaningFeeValue"}
                disabled={true}
              />
              <BorderColorIcon
                style={{ fontSize: 20 }}
                className="text-themeGrey"
              />
            </div>
          </div>
          {/* Input Item */}
          <div className="mt-5 mb-2">
            <div className="text-themeGrey font-bold mb-2 flex gap-2">
              <div>pmCommission</div>
              <Help style={{ fontSize: 16 }} className="text-labelBlue" />
              <div className="font-normal">All channels | All statuses</div>
            </div>
            <div className="flex items-center gap-10">
              <Input
                className="w-full rounded-none"
                placeholder="PM Commission"
                value={"baseRate * 0"}
                disabled={true}
              />
              <BorderColorIcon
                style={{ fontSize: 20 }}
                className="text-themeGrey"
              />
            </div>
          </div>
          {/* Input Item */}
          <div className="mt-5 mb-2">
            <div className="text-themeGrey font-bold mb-2 flex gap-2">
              <div>ownerPayout</div>
              <Help style={{ fontSize: 16 }} className="text-labelBlue" />
              <div className="font-normal">All channels | All statuses</div>
            </div>
            <div className="flex items-center gap-10">
              <Input
                className="w-full rounded-none"
                placeholder="Owner payout"
                value={
                  "baseRate + vat + hotelTax + lodgingTax + salesTax + transientOccupancyTax + cityTax + roomTax + otherTaxes + cleaningFeeValue - hostChannelFee - baseRate * 0"
                }
                disabled={true}
              />
              <BorderColorIcon
                style={{ fontSize: 20 }}
                className="text-themeGrey"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="w-80 my-5">
            <div className="mb-2 flex items-center gap-1">
              Change financial reporting symbol
              <Help style={{ fontSize: 16 }} className="text-labelBlue" />
            </div>
            <div className="flex items-center gap-2">
              <Select className="w-full rounded-none" />
              <GenericButton
                className="bg-themeGreen text-white rounded-none"
                label="Save"
              />
            </div>
          </div>
          <div className="w-80 my-5">
            <div className="mb-2 flex items-center gap-1">
              Show formula value instead of Total Price
              <Help style={{ fontSize: 16 }} className="text-labelBlue" />
            </div>
            <div className="flex items-center gap-2">
              <Select className="w-full rounded-none" />
              <GenericButton
                className="bg-themeGreen text-white rounded-none"
                label="Save"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="flex gap-3 items-center justify-start">
          <div className="text-themeGrey text-xl font-bold">Taxes</div>
          <Help style={{ fontSize: 16 }} className="text-labelBlue" />
          <GenericButton
            label="Add Tax"
            className="bg-selectedGreen text-themeGreen rounded-none"
            iconLeftAlign={true}
            icon={<AddCircleIcon />}
            onClick={() => {
              setIsAddTaxModalOpen(true);
            }}
          />
        </div>
      </div>
      <div className="my-10 xs:w-full sm:w-[85%] grid xs:grid-cols-1 sm:grid-cols-2">
        <div className="">
          <div className="text-themeGrey mb-2">
            Recalculate taxes for existing reservations
          </div>
          <TextArea
            className="w-full rounded-none"
            placeholder="Enter your reservation id(s)"
          />
        </div>
        <div className="flex items-center justify-center">
          <Checkbox className="xs:hidden sm:block">
            Update also total prices (internal channels)
          </Checkbox>
          <Checkbox className="xs:block sm:hidden"></Checkbox>
          <GenericButton
            label="Apply"
            className="text-white bg-themeGreen rounded-none"
          />
        </div>
        <div className="col-span-2 my-5">
          <div className="text-themeGrey font-bold">Airbnb Taxes Mapping</div>
          <div className="text-themeGrey">
            Airbnb reports taxes with specific names. We offer a default mapping
            to our internal taxes, but you can change them at any time. Changes
            will apply to any existing or new Airbnb reservation that includes a
            reported tax. This is useful to show accurate data internally (in
            reservations, reports etc.) but also externally (in the guest
            portal, for instance)
          </div>
        </div>
        <div>
          Default Mapping{" "}
          <Help className="text-labelBlue" style={{ fontSize: 20 }} />
        </div>
        <div className="flex flex-col gap-2">
          <Select className="rounded-none xs:w-full sm:w-40" />
          <GenericButton
            label="Apply"
            className="text-white bg-themeGreen rounded-none w-40"
          />
        </div>
      </div>
    </div>
  );
};

export default FinancialReportingSettings;
