/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useState } from "react";
import { getQuery } from "../../utils/helper";
import { apiInstance } from "../../utils/API";
import { differenceInDays, startOfDay } from "date-fns";
import { formatDate } from "../../utils/dateHelper";
import { Button, Pagination, Select, Table } from "antd";
import TopBar from "../../components/Views/checkinInstrectionsHeader/TopBar";
import { AppContext } from "../../AppContext";
import Status from "../../components/Views/reservations_/Status";
import Loading from "../../components/Loaders/Loading";
import { Icon } from "@iconify-icon/react";
import NoAccess from "../../components/NoAccess";

export default function CheckinInstructions() {
  const profile = React.useContext(AppContext).profile || {};
  const hasReadAccess = profile.readGuestInvoicing;
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [sortBy, setSortBy] = useState("checkInDesc");
  const [create, setCreate] = useState(null);
  const [tag, setTag] = useState("");

  useEffect(() => {
    if (hasReadAccess) getReservations(limit, page, sortBy, create);
  }, [limit, page, hasReadAccess]);

  // useEffect(() => {
  //   if (hasReadAccess) getReservationsCount(limit, page, sortBy, create);
  // }, [hasReadAccess]);

  const getReservations = async (
    limit = 20,
    page = 1,
    sortBy = "checkInDesc",
    create = "checkInDesc"
  ) => {
    setLoading(true);
    try {
      let url = `/checinInstructions?limit=${limit}&skip=${page - 1}`;
      if (sortBy) {
        console.log("sort by");
        url = url + "&sort=" + sortBy;
      }
      if (create) {
        console.log("create");
        url = url + "&create=" + create;
      }
      console.log(url);
      const response = await apiInstance.get(url);
      setReservations(response.data || []);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const tag_ = tag.toLowerCase();
  let filterData = reservations;
  if (tag)
    filterData = filterData.filter((e) => e.tag.toLowerCase().includes(tag_));

  let sortedData = filterData;

  const pageData = sortedData.filter(
    (e, i) => i >= (page - 1) * limit && i < page * limit
  );

  const columns = [
    {
      title: "Guest name",
      dataIndex: "guest",
      key: "guest",
    },
    {
      title: "Flat booked",
      dataIndex: "flatBooked",
      key: "flatBooked",
    },
    {
      title: "Allocted Property",
      dataIndex: "allocatedProperty",
      key: "allocatedProperty",
    },
    {
      title: "Key status",
      dataIndex: "lockBox",
      key: "lockBox",
    },
    {
      title: (
        <div
          className={`cursor-pointer flex items-center gap-x-1 ${
            sortBy === "checkInAsc" || sortBy === "checkInDesc"
              ? "text-buttonTheme"
              : ""
          }`}
        >
          CheckIn
          <span
            className="flex items-center justify-center  transition-all duration-300"
            style={{
              transform:
                sortBy === "checkInAsc" ? "rotateX(180deg)" : "rotateX(0deg)",
            }}
            onClick={() => {
              if (sortBy === "checkInAsc") {
                setSortBy("checkInDesc");
                getReservations(limit, page, "checkInDesc", create);
              } else if (sortBy === "checkInDesc") {
                setSortBy("checkInAsc");
                getReservations(limit, page, "checkInAsc", create);
              }
            }}
          >
            <Icon icon="prime:sort-amount-up" height={24} width={24} />
          </span>
        </div>
      ),
      dataIndex: "checkInDate",
      key: "checkInDate",
      render: (_, record) => {
        const getCheckInDate = () => {
          const date = record.checkInDate;
          if (!date) return "";

          if (
            differenceInDays(
              startOfDay(new Date(date)),
              startOfDay(new Date())
            ) === 0
          )
            return "Today";
          return formatDate(date, "dd MMM, yyyy");
        };
        const getColor = () => {
          const date = record.checkInDate;
          if (!date) return "";

          if (
            differenceInDays(
              startOfDay(new Date(date)),
              startOfDay(new Date())
            ) === 0
          )
            return "#164F4C";
        };
        return <div style={{ color: getColor() }}>{getCheckInDate()}</div>;
      },
    },
    {
      title: "Length of stay",
      dataIndex: "nights",
      key: "nights",
    },
    {
      title: "Mode of Communication",
      key: "invitationStatus",
      render: (_, record) => {
        return (
          <div>
            {record.guestEmail && record.guestContact ? (
              <div className="bg-green-500 flex justify-center text-center text-white">
                Email & Phonenumber
              </div>
            ) : record.guestEmail ? (
              <div className="bg-yellow-500 flex justify-center text-center">
                Email
              </div>
            ) : record.guestContact ? (
              <div className="bg-yellow-500 flex justify-center text-center">
                Phonenumber
              </div>
            ) : (
              <div className="bg-red-500 flex justify-center text-center text-white">
                No contact info
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Status",
      key: "invitationStatus",
      render: (_, record) => {
        return (
          <div>
            {record.invitationStatus ? (
              <div className="bg-green-500 flex justify-center text-center ">
                Instructions Sent
              </div>
            ) : (
              <div className="bg-yellow-500 flex justify-center text-center">
                Instructions not Sent
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Send Instructions",
      key: "invitationStatus",
      render: (_, record) => {
        return (
          <div className="flex justify-center">
            <Button className="bg-green-500 text-white">Send</Button>
          </div>
        );
      },
    },
  ];

  if (profile._id && !hasReadAccess) {
    return <NoAccess />;
  }
  return (
    <div className="p-5">
      <TopBar
        count={count}
        create={create}
        setCreate={setCreate}
        limit={limit}
        page={page}
        getAvailabilities={getReservations}
        setLimit={setLimit}
        setPage={setPage}
        tag={tag}
        setTag={setTag}
        sortBy={sortBy}
        title="Check-in Instructions"
        searchStatus={false}
      />

      {loading ? (
        <div className="h-[50vh] bg-white rounded-lg flex justify-center items-center">
          <Loading />
        </div>
      ) : (
        <Table
          loading={loading}
          dataSource={pageData || []}
          columns={columns}
          pagination={false}
          scroll={{
            x: 1000,
          }}
        />
      )}
    </div>
  );
}
