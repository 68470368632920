import React, { useState } from "react";
import GenericButton from "../../components/GenericButton/GenericButton";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Dropdown, Menu, Select, Table, Tag } from "antd";
import { exportCSVOptions } from "../../enum";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const GuestPaymentsCharges = () => {
  const [chargesType, setChargesType] = useState("all");
  const [chargesSortType, setChargesSortType] = useState("byReservation");

  const items = [
    {
      key: "1",
      label: "Register now",
    },
    { key: "2", label: "Pause" },
    { key: "3", label: "Cancel" },
    { key: "4", label: "Download" },
  ];

  const columns = [
    {
      title: <div className="text-themeGrey font-semibold">Guest name</div>,
      dataIndex: "guestName",
      key: "guestName",
      width: "10%",
    },
    {
      title: <div className="text-themeGrey font-semibold">Listing</div>,
      dataIndex: "listing",
      key: "listing",
      width: "25%",
    },
    {
      title: <div className="text-themeGrey font-semibold">Type</div>,
      dataIndex: "type",
      key: "type",
    },
    {
      title: <div className="text-themeGrey font-semibold">Amount</div>,
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: <div className="text-themeGrey font-semibold">Status</div>,
      dataIndex: "status",
      key: "status",
    },
    {
      title: <div className="text-themeGrey font-semibold">Description</div>,
      dataIndex: "description",
      key: "description",
    },
    {
      title: <div className="text-themeGrey font-semibold">Due date</div>,
      dataIndex: "dueDate",
      key: "dueDate",
    },
    {
      title: <div className="text-themeGrey font-semibold">Charge Name</div>,
      dataIndex: "chargeName",
      key: "chargeName",
    },
    {
      title: <div className="text-themeGrey font-semibold">Payment method</div>,
      dataIndex: "paymentMethod",
      key: "paymentMethod",
    },

    {
      title: <SettingsIcon style={{ fontSize: 16 }} />,
      key: "action",
      align: "right",
      render: (_, record) => {
        const menu = (
          <Menu>
            {items.map((item) => (
              <Menu.Item key={item.key}>{item.label}</Menu.Item>
            ))}
          </Menu>
        );

        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <MoreHorizIcon style={{ fontSize: 16 }} className="rounded-none" />
          </Dropdown>
        );
      },
    },
  ];

  // Sample data.
  const data = [
    {
      key: "1",
      guestName: <div className="text-themeGreen">msood111</div>,
      listing: (
        <div className="text-themeGreen">
          3B SW15-The Peckham Apartments (155615)
        </div>
      ),
      type: <Tag className="text-black bg-selectedGreen">CHARGE</Tag>,
      amount: "£275.00",
      status: <Tag className="bg-gray-300 text-white">DUE</Tag>,
      description: "",
      dueDate: "27 jun 2023 10:56pm",
      chargeName: "test",
      paymentMethod: "credit card (offline)",
    },
    {
      key: "2",
      guestName: <div className="text-themeGreen">Msood</div>,
      listing: (
        <div className="text-themeGreen">
          3B SW15- The Putney Apartment (155609)
        </div>
      ),
      type: <Tag className="text-black bg-selectedGreen">CHARGE</Tag>,
      amount: "$12.00",
      status: <Tag className="bg-gray-300 text-white">DUE</Tag>,
      description: "test",
      dueDate: "8 jun 2023 5:00",
      chargeName: "manual charge",
      paymentMethod: "check",
    },
  ];

  // Filter Items

  const filterItems = (
    <Menu>
      {items.map((item) => (
        <Menu.Item key={item.key}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );
  return (
    <div className="">
      <div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-2">
        <div className="xs:grid xs:grid-cols-1 lg:flex gap-2">
          <Dropdown overlay={filterItems} trigger={["click"]}>
            <GenericButton
              label="Filters"
              className="bg-labelGreen text-themeGreen font-bold rounded-none w-20"
              iconLeftAlign={true}
              icon={<FilterAltOutlinedIcon style={{ fontSize: 16 }} />}
            />
          </Dropdown>

          <div className="xs:grid xs:grid-col-1 sm:flex">
            <GenericButton
              label="All"
              className={`${
                chargesType === "all"
                  ? "bg-gray-500 text-gray-200"
                  : "bg-gray-200 text-gray-500"
              } font-semibold rounded-none xs:w-20 lg:w-fit`}
              onClick={() => {
                setChargesType("all");
              }}
            />
            <GenericButton
              label="Pending"
              className={`${
                chargesType === "pending"
                  ? "bg-gray-500 text-gray-200"
                  : "bg-gray-200 text-gray-500"
              } font-semibold rounded-none xs:w-20 lg:w-fit`}
              onClick={() => {
                setChargesType("pending");
              }}
            />
            <GenericButton
              label="Past Due"
              className={`${
                chargesType === "pastDue"
                  ? "bg-gray-500 text-gray-200"
                  : "bg-gray-200 text-gray-500"
              } font-semibold rounded-none xs:w-20 lg:w-fit`}
              onClick={() => {
                setChargesType("pastDue");
              }}
            />
          </div>
          <div className="xs:grid xs:grid-col-1 sm:flex">
            <GenericButton
              label="By Reservation"
              className={`${
                chargesSortType === "byReservation"
                  ? "bg-gray-500 text-gray-200"
                  : "bg-gray-200 text-gray-500"
              } font-semibold rounded-none xs:w-32 lg:w-fit`}
              onClick={() => {
                setChargesSortType("byReservation");
              }}
            />
            <GenericButton
              label="No Grouping"
              className={`${
                chargesSortType === "noGrouping"
                  ? "bg-gray-500 text-gray-200"
                  : "bg-gray-200 text-gray-500"
              } font-semibold rounded-none xs:w-32 lg:w-fit`}
              onClick={() => {
                setChargesSortType("noGrouping");
              }}
            />
          </div>
        </div>
        <div className="xs:grid xs:grid-cols-1 lg:flex items-start lg:justify-end gap-2">
          <div className="flex items-start gap-2">
            <Select
              defaultValue="all"
              style={{
                width: 100,
              }}
              options={exportCSVOptions}
              className="bg-labelGreen text-themeGreen font-bold"
            />
            <GenericButton
              label="Add charge"
              className="bg-themeGreen text-white font-bold rounded-none w-28"
              iconLeftAlign={true}
              icon={<AddOutlinedIcon style={{ fontSize: 16 }} />}
            />
          </div>
        </div>
      </div>
      <div>
        <Table
          className="border-t border-creme"
          dataSource={data}
          columns={columns}
          scroll={{
            x: 1000,
          }}
        />
      </div>
    </div>
  );
};

export default GuestPaymentsCharges;
