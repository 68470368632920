import React, { useState, useRef, useEffect } from "react";
import GenericButton from "../GenericButton/GenericButton";
import LinkIcon from "@mui/icons-material/Link";
import { Modal, Divider, Checkbox, Tooltip, Select, Input } from "antd";
import { NavLink } from "react-router-dom";
import { modalTypes } from "../../enum";
import { apiInstance, routes } from "../../utils/API";
import Creatable from "react-select/creatable";
import { toast } from "react-toastify";
import Loading from "../Loaders/Loading";

const {
  QUICK_TAGGING,
  INTERNAL_LISTING,
  EXTERNAL_LISTING,
  ICAL,
  DELETE,
  DELETE_CUSTOM_FIELDS,
} = modalTypes;

const GenericModal = ({
  isModalOpen,
  setIsModalOpen,
  modalFor,
  ruId,
  internalText,
  externaltext,
  setExternalText,
  setInternalText,
  saveHandler,
  deleteHandler,
  propertyId,
}) => {
  const [tagError, setTagError] = useState(false);
  const [disableConfirm, setDisableConfirm] = useState(true);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [tags, setTags] = useState();
  const [tagsId, setTagsId] = useState();
  const [listings, setListings] = useState([]);
  const [selectedListigs, setSelectedListings] = useState([]);
  const [isLoader, setIsLoading] = useState(false);

  const inputRef = useRef();

  useEffect(() => {}, [externaltext]);

  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i,
    });
  }

  useEffect(() => {
    (async () => {
      const response = await apiInstance(routes.RESERVATION_PROPERTIES);
      if (response.status === 200) {
        const list = response?.data?.data || [];
        setListings(list);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await apiInstance.get(routes.GET_TAGS);
        setTagsOptions(response?.data?.tagsList[0]?.tags || []);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const saveNewTag = async (newlyCreatedTag) => {
    try {
      const response = await apiInstance.post(routes.CREATE_TAGS, {
        newTag: newlyCreatedTag,
      });
      setTagsOptions(response?.data?.updatedTags?.tags);
      const { value, buildingId } =
        response?.data?.updatedTags?.tags[
          response?.data?.updatedTags?.tags.length - 1
        ];
      // tagsChangeHandler(propertyId, value, buildingId);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleCreatableSelectChange = async (eOpt, eMeta) => {
    const updatedTags = eOpt?.value;
    if (eMeta?.action === "select-option") {
      setTags(updatedTags);
      setTagsId(eOpt?.buildingId);
    } else if (eMeta?.action === "create-option") {
      let newlyCreatedTag = eOpt;
      await saveNewTag(newlyCreatedTag);
    } else if (eMeta?.action === "clear") {
      setTags("");
      setTagsId("");
    }
  };

  const _getOptionsForCreatableSelect = (optionTags) => {
    const tagsOptions = {};
    tagsOptions.label = optionTags;
    tagsOptions.value = optionTags;

    return tagsOptions;
  };

  const handleCopy = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand("copy");
    }
  };
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await apiInstance.put(routes.BULK_UPDATE_BUILDINGS, {
        tagsid: tagsId,
        tags: tags,
        selectedListigs: selectedListigs,
      });
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setIsModalOpen((prev) => !prev);
        setTags("");
        setTagsId("");
        setSelectedListings([]);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error(error.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsLoading(false);
    }
  };

  let renderModal = null;
  switch (modalFor) {
    case QUICK_TAGGING:
      renderModal = (
        <Modal
          open={isModalOpen}
          onCancel={() => {
            setIsModalOpen((prev) => !prev);
            setTags("");
            setTagsId("");
            setSelectedListings([]);
          }}
          onOk={() => handleSubmit()}
          width={600}
          footer={
            isLoader
              ? [<Loading />]
              : [
                  <GenericButton
                    label="Cancel"
                    className="bg-cancelButton hover:!bg-cancelButtonHover text-white"
                    onClick={() => {
                      setIsModalOpen((prev) => !prev);
                      setTags("");
                      setTagsId("");
                      setSelectedListings([]);
                    }}
                    key={1}
                  />,
                  <GenericButton
                    label="Confirm"
                    className={
                      disableConfirm === false
                        ? "bg-buttonTheme hover:!bg-buttonHoverTheme"
                        : ""
                    }
                    disabled={
                      tagsId && selectedListigs.length > 0 ? false : true
                    }
                    onClick={() => handleSubmit()}
                    key={2}
                  />,
                ]
          }
        >
          <p className="w-full text-center text-xl font-semibold text-slate-500 mt-6">
            Quick Tagging
          </p>
          <Divider className="mt-10" />

          <p className="text-[14px] text-slate-500">
            Use quick tagging to quickly add tags to one or more listings. These
            tags can be used as filter in many places and help you grouping and
            finding this listings more easily.
          </p>

          <div className="mt-3">
            <p className="font-semibold text-slate-500">
              Tags <span className="text-red-600">*</span>
            </p>
            <Creatable
              toolTipPlacement="right"
              selectTitle="Tags"
              isClearable={true}
              tooltipTitle="Tagging listings will help you grouping and filtering in many dashboard pages."
              options={tagsOptions}
              selectPlaceholder="Tag name"
              className="defaultTags mn-h"
              customClass="mn-h"
              toolTipFlag={true}
              name="tags"
              value={_getOptionsForCreatableSelect(tags)}
              onChange={(opt, meta) => handleCreatableSelectChange(opt, meta)}
            />
            {tagError && (
              <p className="text-red-600 text-[12px]">Select tags!</p>
            )}
          </div>

          <div className="mt-8">
            <p className="font-semibold text-slate-500">Listings</p>
            <Select
              mode="multiple"
              allowClear
              placeholder="All Listings"
              onChange={(e) => setSelectedListings(e)}
              value={selectedListigs}
              options={listings?.map((l, i) => {
                return {
                  label: (
                    <div className="flex items-center gap-x-2">
                      {l.basicInfo.number_of_units > 1 && (
                        <span className="border px-1 inline-flex ml-1 leading-[normal]">
                          {l?.basicInfo?.number_of_units}{" "}
                        </span>
                      )}
                      {l?.media.length > 0 && (
                        <span className="inline-flex leading-[normal]">
                          <img
                            src={l?.media[0].url}
                            alt={l?.name}
                            className="h-7 w-7 rounded-full p-0.5 "
                          />
                        </span>
                      )}
                      {l.basicInfo.internal_listing_name}
                    </div>
                  ),
                  value: l?.ruId + "," + l?.basicInfo?.number_of_units,
                  key: i,
                  data: l,
                };
              })}
              className="mt-1 w-full"
            />
          </div>
          <Divider />
        </Modal>
      );
      break;
    case INTERNAL_LISTING:
      renderModal = (
        <Modal
          open={isModalOpen}
          onCancel={() => setIsModalOpen((prev) => !prev)}
          width={600}
          footer={[
            <GenericButton
              label="Close"
              className="bg-lightButtonTheme hover:!bg-lightButtonHoverTheme text-buttonTheme hover:!text-buttonTheme"
              onClick={() => {
                setInternalText("");
                setIsModalOpen((prev) => !prev);
              }}
              key={1}
            />,
            <GenericButton
              label="Save"
              className="bg-buttonTheme hover:!bg-buttonHoverTheme"
              onClick={() => {
                setInternalText("");
                saveHandler(
                  propertyId,
                  {
                    basicInfo: {
                      internal_listing_name: internalText,
                    },
                  },
                  ruId
                );
                setIsModalOpen((prev) => !prev);
              }}
              key={2}
            />,
          ]}
        >
          <p className="text-center font-semibold text-slate-500">
            Enter new listing name (internal)
          </p>
          <Input
            defaultValue={""}
            value={internalText}
            className="mt-8"
            onChange={(e) => setInternalText(e.target.value)}
          />
          <Divider className="mt-11" />
        </Modal>
      );
      break;
    case EXTERNAL_LISTING:
      renderModal = (
        <Modal
          open={isModalOpen}
          onCancel={() => setIsModalOpen((prev) => !prev)}
          width={600}
          footer={[
            <GenericButton
              label="Close"
              className="bg-lightButtonTheme hover:!bg-lightButtonHoverTheme text-buttonTheme hover:!text-buttonTheme"
              onClick={() => {
                setExternalText("");
                setIsModalOpen((prev) => !prev);
              }}
              key={1}
            />,
            <GenericButton
              label="Save"
              className="bg-buttonTheme hover:!bg-buttonHoverTheme"
              onClick={() => {
                setExternalText("");
                saveHandler(
                  propertyId,
                  {
                    basicInfo: {
                      external_listing_name: externaltext,
                    },
                  },
                  ruId
                );
                setIsModalOpen((prev) => !prev);
              }}
              key={2}
            />,
          ]}
        >
          <p className="text-center font-semibold text-slate-500">
            Enter new listing name (external)
          </p>
          <Input
            defaultValue={""}
            value={externaltext}
            className="mt-8"
            onChange={(e) => setExternalText(e.target.value)}
          />
          <Divider className="mt-11" />
        </Modal>
      );
      break;
    case ICAL:
      renderModal = (
        <Modal
          open={isModalOpen}
          onCancel={() => setIsModalOpen((prev) => !prev)}
          width={600}
          footer={[
            <GenericButton
              label="Close"
              className="bg-lightButtonTheme hover:!bg-lightButtonHoverTheme text-buttonTheme hover:!text-buttonTheme"
              onClick={() => setIsModalOpen((prev) => !prev)}
              key={1}
            />,
          ]}
        >
          <div className="flex">
            <p className="text-slate-500">iCal link for</p>
            <NavLink
              to="https://www.google.com"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-1 text-buttonTheme hover:text-buttonHoverTheme font-semibold"
            >
              {internalText}
            </NavLink>
          </div>

          <p className="text-[13px] text-slate-600 mt-3">
            Each of your listings have a separate iCal calendar. You can add
            this link to channels such as Vrbo, Flipkey, Wimdu, Housetrip and
            Expedia. Please contact us for any questions.
          </p>

          <div className="flex items-center mt-3">
            <Checkbox />
            <p className="ml-2 font-semibold text-slate-600">
              Exclude blocked dates
            </p>
          </div>
          <div className="flex items-center mt-4">
            <Checkbox />
            <p className="ml-2 font-semibold text-slate-600">Exclude Airbnb</p>
          </div>
          <div className="flex items-center mt-4">
            <Checkbox />
            <p className="ml-2 font-semibold text-slate-600">
              Exclude Booking.com
            </p>
          </div>
          <div className="flex items-center mt-4">
            <Checkbox />
            <p className="ml-2 font-semibold text-slate-600">Exclude Vrbo</p>
          </div>
          <div className="flex items-center mt-4">
            <Checkbox />
            <p className="ml-2 font-semibold text-slate-600">
              Exclude custom iCal reservations
            </p>
          </div>
          <div className="flex items-center mt-4">
            <Checkbox />
            <p className="ml-2 font-semibold text-slate-600">Exclude Expedia</p>
          </div>

          <div className="flex mt-5">
            <Input ref={inputRef} />
            <GenericButton
              label="Copy"
              icon={<LinkIcon />}
              iconLeftAlign={true}
              className="bg-buttonTheme hover:!bg-buttonHoverTheme"
              onClick={handleCopy}
            />
          </div>
          <Divider />
        </Modal>
      );
      break;
    case DELETE:
      renderModal = (
        <Modal
          open={isModalOpen}
          onCancel={() => setIsModalOpen((prev) => !prev)}
          width={600}
          footer={[
            <GenericButton
              label="Cancel"
              className="bg-lightButtonTheme hover:!bg-lightButtonHoverTheme text-buttonTheme hover:!text-buttonTheme"
              onClick={() => setIsModalOpen((prev) => !prev)}
              key={1}
            />,
            <GenericButton
              label="Confirm"
              className="bg-cancelButton hover:!bg-cancelButtonHover text-white"
              onClick={() => {
                deleteHandler(propertyId);
                setIsModalOpen((prev) => !prev);
              }}
              key={2}
            />,
          ]}
        >
          <div className="m-1">
            <p className="text-[15px] font-semibold text-slate-500">
              When deleting your listing, all your listing details as well as
              reservation, message, financial and payment details will be
              deleted in Flexliving permanently.
            </p>
            <p className="text-[15px] font-semibold text-slate-500">
              Note that deleted list information cannot be restored.
            </p>
          </div>

          <Divider className="mt-8" />
        </Modal>
      );
      break;
    case DELETE_CUSTOM_FIELDS:
      renderModal = (
        <Modal
          open={isModalOpen}
          onCancel={() => setIsModalOpen((prev) => !prev)}
          width={600}
          footer={[
            <GenericButton
              label="Cancel"
              className="bg-lightButtonTheme hover:!bg-lightButtonHoverTheme text-buttonTheme hover:!text-buttonTheme"
              onClick={() => setIsModalOpen((prev) => !prev)}
              key={1}
            />,
            <GenericButton
              label="Confirm"
              className="bg-cancelButton hover:!bg-cancelButtonHover text-white"
              onClick={() => setIsModalOpen((prev) => !prev)}
              key={2}
            />,
          ]}
        >
          <p className="text-themeGrey pt-4 md:pt-0">
            Are you sure you want to delete this custom field?
          </p>
          <Divider />
        </Modal>
      );
      break;
    default:
      // eslint-disable-next-line no-unused-expressions
      renderModal;
  }

  return <div>{renderModal}</div>;
};

export default GenericModal;
