import React from 'react'
import { isEmpty } from 'lodash'
import { BorderOutlined, CheckSquareFilled } from '@ant-design/icons'

import Loading from '../../components/Loaders/Loading'
import { numberWithCommas } from '../../utils/helper'

import { SUMMARY_TABLE_ROW_NAMES } from './helpers'
import {
  normalizeDataToMarginsChart,
  MARGIN_BUTTONS_OPTIONS,
  CASH_VALUES_BUTTONS_OPTIONS,
} from './graphHelpers'

function Graph (props) {
  const { dateFilters, marginsChartData = {} } = props

  const [chartJsComp, setChartJsComp] = React.useState()
  const [selectedGraphs, setSelectedGraphs] = React.useState([
    SUMMARY_TABLE_ROW_NAMES.POST_CAPEX_MARGIN,
    SUMMARY_TABLE_ROW_NAMES.TOTAL_REVENUE,
  ])

  const normalizedMarginsChartData = React.useMemo(
    () => normalizeDataToMarginsChart(marginsChartData, selectedGraphs, dateFilters),
    [marginsChartData, selectedGraphs, dateFilters],
  )

  React.useEffect(() => {
    const importComponent = async () => {
      const module = await import('react-chartjs-2')
      await import('chart.js/auto')

      setChartJsComp(module)
    }

    importComponent()
  }, [])

  if (!chartJsComp) {
    return (
      <div className="h-[50vh] bg-white rounded-lg flex justify-center items-center">
        <Loading/>
      </div>
    )
  }

  if (isEmpty(marginsChartData)) {
    return (
      <div className="font-bold text-base text-themeGrey text-center">
        No data yet
      </div>
    )
  }

  const { Chart } = chartJsComp

  const handleChange = name => () => {
    if (selectedGraphs.includes(name)) {
      setSelectedGraphs(prev => prev.filter(graph => graph !== name))
    } else {
      setSelectedGraphs(prev => [...prev, name])
    }
  }

  const renderButtons = (title, buttons) => (
    <div className="pt-3">
      <div className="font-bold text-base text-themeGrey pb-2">
        {title}:
      </div>

      <div className="flex items-center flex-wrap gap-4">
        {buttons.map((button, i) => {
          const { label, className, selectedClassName } = button
          const selected = selectedGraphs.includes(label)

          const classes = `${className} ${selected ? selectedClassName : ''} 
            flex items-center gap-2 cursor-pointer px-3 py-1 rounded-[8px] 
            font-semibold text-xs`

          return (
            <div
              key={label + i}
              onClick={handleChange(label)}
              className={classes}
            >
              {selected ? <CheckSquareFilled/> : <BorderOutlined/>}
              {label}
            </div>
          )
        })}
      </div>
    </div>
  )

  const marginsChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true
      },
      y: {
        ticks: {
          callback: (value) => '£ ' + numberWithCommas(value)
        }
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false
        },
        ticks: {
          callback: (value) => value + '%'
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: ({ dataset, formattedValue }) => {
            const hasPercentage = dataset.yAxisID === 'y1'
            const cashSign = hasPercentage ? '' : '£ '
            const percentSign = hasPercentage ? '%' : ''

            return `${dataset.label}: ${cashSign}${formattedValue}${percentSign}`
          }
        }
      },
      title: {
        display: true,
        text: 'Margins',
        font: {
          size: 18
        }
      },
      legend: {
        position: 'bottom',
        labels: {
          padding: 20
        }
      }
    }
  }

  return (
    <>
      {renderButtons('Margins', MARGIN_BUTTONS_OPTIONS)}

      {renderButtons('Cash Values', CASH_VALUES_BUTTONS_OPTIONS)}

      <div className="w-[100%] h-[18rem] sm:h-[24rem] md:h-[30rem] pt-10">
        <Chart
          data={normalizedMarginsChartData}
          options={marginsChartOptions}
          type="bar"
        />
      </div>
    </>
  )
}

export default Graph
