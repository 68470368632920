/* eslint-disable react-hooks/exhaustive-deps */

import { useState } from "react";
import { apiInstance, routes } from "../../utils/API";
import TopBar from "./TopBar";
import { Table } from "antd";
import Loading from "../../components/Loaders/Loading";
import GenericButton from "../../components/GenericButton/GenericButton";
import { manageToast } from "../../components/Common/ManageToast";

export default function SyncData() {
  const [syncIdentifier, setSyncIdentifier] = useState(null);
  const [syncLoading, setSyncLoading] = useState(false);
  const [data, setData] = useState([
    {
      identifier: 1,
      syncName: "Update Calendars",
      route: routes.UPDATE_CALENDARS,
    },
  ]);

  const syncData = async (id, route) => {
    try {
      setSyncIdentifier(id);
      setSyncLoading(true);
      manageToast(
        true,
        "Sync is started. It will take some time. You can still continue browsing."
      );
      const res = await apiInstance.get(route);
      if (res.data.status === 200) {
      } else {
      }
      setTimeout(() => {
        setSyncLoading(false);
        setSyncIdentifier(null);
      }, 300000);
    } catch (error) {
      if (error) {
        setTimeout(() => {
          manageToast(false, "Server Error. Someting went wrong");
          setSyncLoading(false);
          setSyncIdentifier(null);
        }, 300010);
      }
    }
  };
  const [loading, setLoading] = useState(false);

  const [tag, setTag] = useState("");

  const columns = [
    {
      title: "Sync Name",
      dataIndex: "syncName",
      key: "syncName",
    },
    {
      title: "Sync",
      dataIndex: "sync",
      key: "sync",
      render: (_, record) => {
        return (
          <GenericButton
            label="Sync"
            className="text-white bg-themeGreen rounded-none"
            onClick={() => syncData(record.identifier, record.route)}
            disabled={syncLoading}
            isLoading={record.identifier === syncIdentifier ? true : false}
          />
        );
      },
    },
  ];

  return (
    <div className="p-5">
      <TopBar setTag={setTag} />

      {loading ? (
        <div className="h-[50vh] bg-white rounded-lg flex justify-center items-center">
          <Loading />
        </div>
      ) : (
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          pagination={false}
          scroll={{
            x: 1000,
          }}
        />
      )}
    </div>
  );
}
