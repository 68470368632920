import { Modal, Select, Button, DatePicker, Divider } from "antd";

function MoreFiltersModal({ openModal, setOpenModal }) {
  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <Modal
      title="Filter Tasks"
      centered
      open={openModal}
      onCancel={toggleModal}
      onOk={toggleModal}
      footer={[
        <Button
          key="reset"
          className="text-black font-semibold hover:!bg-transparent hover:!text-buttonHoverTheme"
          type="text"
          onClick={toggleModal}
        >
          Reset
        </Button>,
        <Button
          key="cancel"
          className="text-black font-semibold hover:!bg-transparent hover:!text-buttonHoverTheme"
          type="text"
          onClick={toggleModal}
        >
          Cancel
        </Button>,
        <Button
          key="reset"
          className="text-buttonTheme font-semibold hover:!bg-transparent hover:!text-buttonHoverTheme"
          type="text"
          onClick={toggleModal}
        >
          Apply
        </Button>,
      ]}
    >
      <div className="flex flex-col gap-y-5">
        <div className="flex-col">
          <p>Categories</p>
          <Select
            className="w-full"
            placeholder="Categories"
            allowClear
            optionFilterProp="children"
            options={[
              { value: "jack", label: "Jack" },
              { value: "lucy", label: "Lucy" },
              { value: "tom", label: "Tom" },
            ]}
          />
        </div>
        <div className="flex-col">
          <p>Tags</p>
          <Select
            className="w-full"
            mode="tags"
            placeholder="Tags"
            allowClear
            optionFilterProp="children"
            options={[
              { value: "jack", label: "Jack" },
              { value: "lucy", label: "Lucy" },
              { value: "tom", label: "Tom" },
            ]}
          />
        </div>
        <div className="flex justify-between flex-wrap w-full">
          <div className="flex-col w-[45%]">
            <p>Start after</p>
            <DatePicker className="w-full" placeholder="Start After" />
          </div>
          <div className="flex-col w-[45%]">
            <p>Start before</p>
            <DatePicker className="w-full" placeholder="Start Before" />
          </div>
        </div>
        <div className="flex justify-between flex-wrap w-full">
          <div className="flex-col w-[45%]">
            <p>Start end after</p>
            <DatePicker className="w-full" placeholder="Start End After" />
          </div>
          <div className="flex-col w-[45%]">
            <p>Start end before</p>
            <DatePicker className="w-full" placeholder="Start End Before" />
          </div>
        </div>
        <Divider className="my-3" />
      </div>
    </Modal>
  );
}

export default MoreFiltersModal;
