import React, { useEffect, useRef, useState } from "react";
import { CloseOutlined, MenuOutlined, UserOutlined } from "@ant-design/icons";
// import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
// import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
// import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import GenericButton from "../GenericButton/GenericButton";
import { Avatar, Card } from "antd";
import { getUserEmail, getUserName, logOut } from "../../utils/constants";
import { useContext } from "react";
import { AppContext } from "../../AppContext";
import { Link } from "react-router-dom";
import { USER_MANAGEMENT_USERS } from "../../constants/dashboardRoutes";
// const { Search } = Input;

const Header = ({ siderClass, setSiderClass }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const ref = useRef(null);
  const profile = useContext(AppContext).profile || {};

  const logOutUser = () => {
    logOut();
    window.location.reload(true);
  };

  useEffect(() => {
    //@ts-ignore
    const checkIfClickedOutside = (e) => {
      //@ts-ignore
      if (
        ref.current &&
        (!ref.current.contains(e.target) ||
          e.target?.className === "dropdown_item")
      ) {
        setOpenDropdown(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, []);

  return (
    <div className="bg-themeWhite h-12 flex items-center justify-between border-t-2 border-l-2 border-b-2 border-creme p-5 sticky top-0 z-[999]">
      <div className="flex ">
        <div className="flex items-center gap-3 md:hidden">
          {siderClass ? (
            <CloseOutlined
              className="text-themeGrey cursor-pointer"
              onClick={() => setSiderClass(false)}
            />
          ) : (
            <MenuOutlined
              className="text-themeGrey cursor-pointer"
              onClick={() => setSiderClass(true)}
            />
          )}
          <span className="font-bold text-2xl italic text-themeSecondaryGreen">
            Flexliving
          </span>
        </div>
        <div className="items-center gap-3 hidden md:flex">
          <MenuOutlined className="text-themeGrey" />
          <span className="text-2xl italic text-themeSecondaryGreen">
            Flexliving
          </span>
        </div>
      </div>
      <div className="flex items-center gap-6 ">
        {/* <Search
          placeholder="Search"
          allowClear
          onSearch={onSearch}
          style={{
            width: 210,
          }}
          className="rounded-none xs:hidden md:block"
        />
        <FeedOutlinedIcon className="text-themeGrey hover:text-themeGreen hover:bg-selectedGreen cursor-pointer" />
        <NotificationsNoneIcon className="text-[#6b778c] hover:text-themeGreen hover:bg-selectedGreen cursor-pointer" />
        <GenericButton
          label="Need help?"
          size="small"
          icon={<HelpOutlineIcon className=" mr-1 text-[#6b778c]" />}
          className="text-[#6b778c] !text-xs hover:bg-selectedGreen cursor-pointer"
        /> */}
        <div className="relative dropdown_item" ref={ref}>
          <Avatar
            onClick={() => {
              setOpenDropdown(!openDropdown);
            }}
            size={{
              xs: 34,
              md: 20,
              lg: 14,
            }}
            icon={<UserOutlined />}
            className="cursor-pointer "
          />
          {openDropdown && (
            <Card className="absolute top-full right-0 min-w-[300px] z-[9999]">
              <div className="flex gap-4">
                <Avatar
                  size={{
                    xs: 34,
                    md: 20,
                    lg: 14,
                  }}
                  icon={<UserOutlined />}
                  className="cursor-pointer"
                />
                <div className="text-themeSecondaryGreen font-semibold">
                  {getUserName()}
                  <br />
                  {getUserEmail() || "admin@gmail.com"}
                </div>
              </div>
              <div className="flex  justify-between items-center py-5 px-3">
                {profile && profile?._id && (
                  <Link
                    to={USER_MANAGEMENT_USERS + "/" + profile?._id}
                    onClick={() => {
                      setOpenDropdown(false);
                    }}
                    className="text-sm text-themeSecondaryGreen"
                  >
                    Edit
                  </Link>
                )}
                <GenericButton
                  type="button"
                  onClick={() => {
                    logOutUser();
                  }}
                  label="Log out"
                  className="text-[#5fb494]"
                />
              </div>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
