import React from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";

function FormModal({
  isModalOpen,
  setIsModalOpen,
  children,
  title = "",
  handleCancel,
  bodyStyle,
  closeIcon = <CloseCircleOutlined className="text-xl text-black" />,
  className = "!w-[350px] md:!w-[420px]",
}) {
  const closeModalHandler = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="monday-modal">
      <Modal
        destroyOnClose={true}
        open={isModalOpen}
        closeIcon={closeIcon}
        title={<h3 className="text-center text-xl">{title}</h3>}
        footer={null}
        className={className}
        onCancel={handleCancel ? handleCancel : closeModalHandler}
        bodyStyle={bodyStyle}
      >
        {children}
      </Modal>
    </div>
  );
}

export default FormModal;
