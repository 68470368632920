import { useState } from "react";
import { Button, Checkbox, Space, Tabs } from "antd";
import { useNavigate } from "react-router-dom";

import {
  TASKS_MANAGE_TASKS,
  TASKS_MANAGE_AUTO_TASKS,
  TASKS_CHECKLIST_TEMPLATES,
} from "../../constants/dashboardRoutes";
import AddTask from "./ManageTasksPage/AddTask";
import AddAutoTask from "./ManageAutoTasks/AddAutoTask";
import AddChecklistTemplate from "./ChecklistTemplates/AddChecklistTemplate";

function Tasks({
  manageTasksBody,
  manageAutoTasksBody,
  checklistTemplatesBody,
  archivedBody,
  activeKey,
}) {
  const [showAddTask, setShowAddTask] = useState(false);
  const [showAddAutoTask, setShowAddAutoTask] = useState(false);
  const [showChecklistTemplate, setShowChecklistTemplate] = useState(false);

  const navigate = useNavigate();

  const tabItems = [
    {
      key: 1,
      label: "MANAGE TASKS",
      children: manageTasksBody(setShowAddTask),
      route: TASKS_MANAGE_TASKS,
    },
    {
      key: 2,
      label: "MANAGE AUTO-TASKS",
      children: manageAutoTasksBody(setShowAddAutoTask),
      route: TASKS_MANAGE_AUTO_TASKS,
    },
    {
      key: 3,
      label: "CHECKLIST TEMPLATES",
      children: checklistTemplatesBody(setShowChecklistTemplate),
      route: TASKS_CHECKLIST_TEMPLATES,
    },
    {
      key: 4,
      label: "ARCHIVED",
      children: archivedBody(setShowAddTask),
    },
  ];

  return !showAddTask && !showAddAutoTask && !showChecklistTemplate ? (
    <div className="p-6 w-full h-full bg-white">
      <div className="flex justify-between flex-wrap">
        <h1 className="font-semibold text-4xl">Tasks</h1>
        <div>
          {activeKey === 1 ? (
            <Space>
              <Button
                type="primary"
                className="bg-buttonTheme font-semibold hover:!bg-buttonHoverTheme"
                onClick={() => setShowAddTask(true)}
              >
                Add task
              </Button>
              <Button
                type="primary"
                className="bg-labelGreen text-buttonTheme font-semibold hover:!bg-buttonHoverTheme"
              >
                Download report
              </Button>
              <Checkbox>Apply current filter</Checkbox>
            </Space>
          ) : activeKey === 2 ? (
            <Button
              type="primary"
              className="bg-buttonTheme font-semibold hover:!bg-buttonHoverTheme"
              onClick={() => setShowAddAutoTask(true)}
            >
              Add auto-task
            </Button>
          ) : activeKey === 3 ? (
            <Button
              type="primary"
              className="bg-buttonTheme font-semibold hover:!bg-buttonHoverTheme"
              onClick={() => setShowChecklistTemplate(true)}
            >
              Add checklist template
            </Button>
          ) : (
            <Button
              type="primary"
              className="bg-buttonTheme font-semibold hover:!bg-buttonHoverTheme"
              onClick={() => setShowAddTask(true)}
            >
              Add task
            </Button>
          )}
        </div>
      </div>
      <Tabs
        defaultActiveKey={activeKey}
        items={tabItems}
        onChange={(item) => {
          const obj = tabItems.filter((object) => object.key === item);
          navigate(obj[0].route);
        }}
      >
        {tabItems.map((item) => (
          <Tabs.TabPane tab={item.label} key={item.key}>
            {item.children}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  ) : showAddTask ? (
    <AddTask setShowAddTasks={setShowAddTask} />
  ) : showAddAutoTask ? (
    <AddAutoTask setShowAddAutoTask={setShowAddAutoTask} />
  ) : (
    <AddChecklistTemplate setShowChecklistTemplate={setShowChecklistTemplate} />
  );
}

export default Tasks;
