import React from 'react'
import { Button, Modal } from 'antd'

import LockBoxStatusCell from '../LockBoxStatusCell'

const LOCKBOX_FIELDS = [
  {
    label: 'Property',
    renderValue: lockbox => lockbox?.property?.internalListingName || '--'
  },
  {
    label: 'Lockbox Name',
    renderValue: lockbox => lockbox?.LockBoxName || '--'
  },
  {
    label: 'Code',
    renderValue: lockbox => lockbox?.Code || '--'
  },
  {
    label: 'Status',
    renderValue: (lockbox, canUpdate, refetch, onClose) => {
      if (!lockbox?.status) {
        return '--'
      }

      return (
        <LockBoxStatusCell
          disabled={!canUpdate}
          onSuccess={onClose}
          refetch={refetch}
          {...lockbox}
        />
      )
    }
  }
]

function LockBoxDetailsModal (props) {
  const {
    canDelete,
    canUpdate,
    handleDeleteModalOpen,
    lockBox,
    onClose,
    open,
    triggerLockBoxCollectionRefetch
  } = props

  return (
    <Modal
      title='Lockbox details'
      open={open}
      footer={null}
      onCancel={onClose}
    >
      <div className="grid sm:grid-cols-2 gap-2 pt-2">
        {LOCKBOX_FIELDS.map(({ label, renderValue }) => (
          <div key={label}>
            <div className="font-semibold">
              {label}
            </div>

            <div>
              {renderValue(lockBox, canUpdate, triggerLockBoxCollectionRefetch, onClose)}
            </div>
          </div>
        ))}
      </div>

      <div className="flex items-center justify-end gap-2 pt-10">
        {canDelete && (
          <Button
            onClick={handleDeleteModalOpen}
            className="mr-auto"
            type="primary"
            danger
          >
            Delete lockbox
          </Button>
        )}

        <Button
          className="hover:!text-black hover:!border-labelGrey"
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </Modal>
  )
}

export default LockBoxDetailsModal
