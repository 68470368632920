import React from "react";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import GenericButton from "../../components/GenericButton/GenericButton";
import { REVIEWS_AUTO_REVIEWS } from "../../constants/dashboardRoutes";
import { Delete, Help } from "@mui/icons-material";
import { Input, Rate, Select, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";
import ListingCard from "../../components/ListingCard/ListingCard";
import ApartmentImage from "../../assets/apartment.jpeg";
import RoomImage from "../../assets/room.jpeg";

const AutoReviewsAdd = () => {
  return (
    <div className="p-5">
      <div className="flex items-center justify-between text-themeGreen">
        <Link to={REVIEWS_AUTO_REVIEWS}>
          <ArrowBackIosIcon /> Back to auto-reviews
        </Link>
        <GenericButton
          className="text-white bg-themeGreen rounded-none"
          label="Save"
        />
      </div>
      <div className="py-5">
        <div className="font-bold text-xl">
          Airbnb auto-reviews{" "}
          <Help style={{ fontSize: 16 }} className="text-labelBlue" />
        </div>
        <Rate defaultValue={4} className="my-5" />
        <div className="flex items-center gap-3 py-5">
          <Switch />{" "}
          <div className="text-themeGrey">
            Recommend the guest
            <Help style={{ fontSize: 16 }} className="text-labelBlue ml-1" />
          </div>
        </div>
        <div className="mb-5 flex items-center gap-3">
          <div className="w-[70%]">
            <div className="text-themeGrey mb-1">Name</div>
            <Input placeholder="Enter name" />
          </div>
          <div className="w-[30%]">
            <div className="text-themeGrey mb-1">
              Send time (after-checkout)
            </div>
            <Select placeholder="Select value" />
          </div>
        </div>
        <div className="my-5 flex items-start">
          <div className="w-[90%]">
            <div className="text-themeGrey mb-1">
              Message
              <Help style={{ fontSize: 16 }} className="text-labelBlue ml-1" />
            </div>
            <TextArea placeholder="Enter message" rows={5} />
          </div>
          <Delete
            style={{ fontSize: 20 }}
            className="text-themeGrey mt-7 ml-4"
          />
        </div>
        <div className="my-5 flex items-start">
          <div className="w-[90%]">
            <div className="text-themeGrey mb-1">
              Message
              <Help style={{ fontSize: 16 }} className="text-labelBlue ml-1" />
            </div>
            <TextArea placeholder="Enter message" rows={5} />
          </div>
          <Delete
            style={{ fontSize: 20 }}
            className="text-themeGrey mt-7 ml-4"
          />
        </div>
        <div className="my-5 flex items-start">
          <div className="w-[90%]">
            <div className="text-themeGrey mb-1">
              Message
              <Help style={{ fontSize: 16 }} className="text-labelBlue ml-1" />
            </div>
            <TextArea placeholder="Enter message" rows={5} />
          </div>
          <Delete
            style={{ fontSize: 20 }}
            className="text-themeGrey mt-7 ml-4"
          />
        </div>
        <div className="mb-5">
          <div className="text-themeGrey mb-1">
            Private note
            <Help style={{ fontSize: 16 }} className="text-labelBlue ml-1" />
          </div>
          <TextArea placeholder="Enter private note" rows={5} />
        </div>
        <div className="font-bold text-xl">
          Listings <Help style={{ fontSize: 16 }} className="text-labelBlue" />
        </div>
        <div className="flex items-center justify-between">
          <div className="flex gap-3">
            <Input placeholder="Type to search listings" className="w-[60%]" />
            <Select placeholder="Select Tags" className="w-[60%]" />
          </div>
          <GenericButton
            label="Select All"
            className="rounded-none text-white bg-green-600"
          />
        </div>
        <div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-10">
          <ListingCard cardImage={ApartmentImage} />
          <ListingCard cardImage={RoomImage} />
          <ListingCard cardImage={ApartmentImage} />
          <ListingCard cardImage={ApartmentImage} />
          <ListingCard />
        </div>
      </div>
    </div>
  );
};

export default AutoReviewsAdd;
