import React, { useState } from "react";
import KeyIcon from "@mui/icons-material/Key";
import { Link } from "react-router-dom";
import GenericButton from "../../components/GenericButton/GenericButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateAPIKeyModal from "../../components/CreateAPIKeyModal/CreateAPIKeyModal";
import PendingIcon from "@mui/icons-material/Pending";
import { Dropdown, Table } from "antd";
import EditAPIKeyModal from "../../components/EditAPIKeyModal/EditAPIKeyModal";
import DeleteAPIKeyModal from "../../components/DeleteAPIKeyModal/DeleteAPIKeyModal";

const DashboardSettingsHostawayAPI = () => {
  const [isCreateAPIKeyModalOpen, setIsCreateAPIKeyModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleRowClick = (record) => {};

  const items = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            setIsEditModalOpen(true);
          }}
        >
          Edit
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            setIsDeleteModalOpen(true);
          }}
        >
          Delete
        </div>
      ),
    },
  ];

  const columns = [
    {
      title: <div className="text-themeGrey font-normal text-sm">ID</div>,
      dataIndex: "id",
      key: "id",
      width: "10%",
    },
    {
      title: <div className="text-themeGrey font-normal text-sm">Name</div>,
      dataIndex: "name",
      key: "name",
      width: "25%",
    },

    {
      title: <div className="text-themeGrey font-normal text-sm">API key</div>,
      dataIndex: "APIKey",
      key: "APIKey",
      width: "20%",
    },

    {
      title: <div className="text-themeGrey font-normal text-sm">Partner</div>,
      dataIndex: "partner",
      key: "partner",
    },

    {
      title: <div className="text-themeGrey font-normal text-sm">Created</div>,
      dataIndex: "created",
      key: "created",
    },

    {
      title: "",
      key: "action",
      align: "right",
      render: (_, record) => {
        return (
          <Dropdown
            menu={{
              items,
            }}
            placement="bottomRight"
            arrow={{
              pointAtCenter: true,
            }}
          >
            <PendingIcon className="text-themeGrey" />
          </Dropdown>
        );
      },
    },
  ];

  const data = [
    {
      key: "14501",
      id: "14501",
      name: "Rehan",
      APIKey: "f52d****7fbf",
      partner: "",
      created: "2023-04-18 16:01:56",
    },
    {
      key: "13943",
      id: "13943",
      name: "test",
      APIKey: "f52d****7fbf",
      partner: "",
      created: "2023-04-18 16:01:56",
    },
    {
      key: "16669",
      id: "16669",
      name: "Test New",
      APIKey: "f52d****7fbf",
      partner: "",
      created: "2023-04-18 16:01:56",
    },
    {
      key: "16458",
      id: "16458",
      name: "Ate",
      APIKey: "f52d****7fbf",
      partner: "",
      created: "2023-04-18 16:01:56",
    },
    {
      key: "14150",
      id: "14150",
      name: "Test - API",
      APIKey: "f52d****7fbf",
      partner: "",
      created: "2023-04-18 16:01:56",
    },
    {
      key: "14263",
      id: "14263",
      name: "Logiciel - API",
      APIKey: "f52d****7fbf",
      partner: "",
      created: "2023-04-18 16:01:56",
    },
    {
      key: "14784",
      id: "14784",
      name: "Logiciel Zapier Key",
      APIKey: "f52d****7fbf",
      partner: "",
      created: "2023-04-18 16:01:56",
    },
    {
      key: "17997",
      id: "17997",
      name: "Pricelabs 2",
      APIKey: "f52d****7fbf",
      partner: "",
      created: "2023-04-18 16:01:56",
    },
    {
      key: "17296",
      id: "17296",
      name: "pricelabs",
      APIKey: "f52d****7fbf",
      partner: "",
      created: "2023-04-18 16:01:56",
    },
    {
      key: "14155",
      id: "14155",
      name: "Muava",
      APIKey: "f52d****7fbf",
      partner: "",
      created: "2023-04-18 16:01:56",
    },
  ];

  return (
    <div>
      <CreateAPIKeyModal
        isModalOpen={isCreateAPIKeyModalOpen}
        setIsModalOpen={setIsCreateAPIKeyModalOpen}
      />
      <EditAPIKeyModal
        isModalOpen={isEditModalOpen}
        setIsModalOpen={setIsEditModalOpen}
      />
      <DeleteAPIKeyModal
        isModalOpen={isDeleteModalOpen}
        setIsModalOpen={setIsDeleteModalOpen}
      />
      <div className="text-themeGrey text-xl font-bold flex items-center gap-2 mb-5">
        <KeyIcon style={{ fontSize: 24 }} /> <p>Flexliving Public API</p>
      </div>
      <div className="mt-8 mb-5 text-themeGrey font-bold text-md">
        The Flexliving Public API can be used to set rates and availability,
        upload property data and retrieve reservations. You can generate a
        Secret API key which is needed to access it. Please refer to our{" "}
        <Link className="text-themeGreen">documentation.</Link>
      </div>
      <div className="mb-5 flex items-center justify-between">
        <GenericButton
          label="Create"
          className="bg-themeGreen text-white font-bold rounded-none"
          icon={<AddCircleIcon style={{ fontSize: 16 }} />}
          onClick={() => {
            setIsCreateAPIKeyModalOpen(true);
          }}
        />
        <GenericButton
          label="Revoke Access Token"
          className="bg-red-500 text-white font-bold rounded-none"
          icon={<DeleteIcon style={{ fontSize: 16 }} />}
        />
      </div>
      <div>
        <Table
          className="border-t border-creme"
          columns={columns}
          dataSource={data}
          scroll={{
            x: 1000,
          }}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
            className: "cursor-pointer",
          })}
        />
      </div>
      <div className="mb-10">
        If You have any question regarding the technical implementation of the
        Flexliving Public APi, please contact{" "}
        <Link className="text-themeGreen">support@flexliving.com</Link>
      </div>
    </div>
  );
};

export default DashboardSettingsHostawayAPI;
