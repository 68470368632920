import React, { useState } from "react";
import GenericButton from "../GenericButton/GenericButton";
import { Checkbox, Input, Select } from "antd";
import {
  autoChargesAmountType,
  autoChargesReservationTypes,
  autoChargesScheduledByOptions,
  autoChargesTypes,
  paymentMethodOptions,
} from "../../enum";
import AutoPaymentsFormItems from "../AutoPaymentsFormItems/AutoPaymentsFormItems";
import AddIcon from "@mui/icons-material/Add";
import AutoPaymentListingSection from "../AutoPaymentListingSection/AutoPaymentListingSection";

const AutoPaymentsForm = ({ selectedTabId, tab }) => {
  const [formItemCount, setFormItemCount] = useState(1);
  const [listingButton, setListingButton] = useState("applyForAll");
  const handleAddFormItems = () => {
    setFormItemCount((prevCount) => prevCount + 1);
  };
  return (
    <div className="px-5">
      <div className="flex items-center justify-between">
        <div className="font-bold text-lg text-themeGrey">
          Test {selectedTabId}
        </div>
        <div>
          <GenericButton label="Delete" className="bg-none text-red-500" />
          <GenericButton
            label="Discard changes"
            className="bg-none text-themeGreen"
          />
          <GenericButton label="Save" className="bg-themeGreen text-white" />
        </div>
      </div>
      <div className="grid xs:grid-cols-1 lg:grid-cols-3 gap-4 py-5  border-b-2 border-creme">
        <div>
          <p className="my-1">Title</p>
          <Input
            placeholder="test"
            className="w-full rounded-none my-1"
            value="Text"
          />
        </div>
        <div>
          <p className="my-1">Title</p>
          <Select
            placeholder="All channels"
            className="w-full rounded-none my-1"
          />
        </div>
        <div className="xs:hidden lg:block"></div>
      </div>
      <div className="my-5">
        <div className="font-bold text-themeGrey">Auto charges</div>
        <div className="text-themeGrey">
          Auto-payment consists of auto charges and pre authorisations. Add them
          and build suitable payment plan for your guests The reservations
          currency will be used.
        </div>
      </div>
      {[...Array(formItemCount)].map((_, index) => (
        <AutoPaymentsFormItems key={index} />
      ))}
      <div className="py-5 border-b-2 border-creme">
        <GenericButton
          label="Add auto charges"
          className="bg-themeGreen text-white  xs:w-full md:w-40 rounded-none"
          iconLeftAlign={true}
          icon={<AddIcon />}
          onClick={handleAddFormItems}
        />
      </div>
      <div className="flex mt-5 items-center justify-between">
        <div className="text-themeGrey font-bold">Listings</div>
        <Checkbox
          className="text-themeGrey font-bold"
          checked={listingButton === "applyForAll" ? true : false}
        >
          Apply for new listings automatically
        </Checkbox>
      </div>
      <div className="flex gap-1 mt-5">
        <GenericButton
          label="Apply for all"
          className={`w-32 rounded-none ${
            listingButton === "applyForAll"
              ? "bg-gray-600 text-gray-200"
              : "bg-gray-200 text-gray-600"
          }`}
          onClick={() => {
            setListingButton("applyForAll");
          }}
        />
        <GenericButton
          label="Select listings for apply"
          className={`w-40 rounded-none ${
            listingButton === "selectListingsForApply"
              ? "bg-gray-600 text-gray-200"
              : "bg-gray-200 text-gray-600"
          }`}
          onClick={() => {
            setListingButton("selectListingsForApply");
          }}
        />
      </div>
      <div className="my-5">
        {listingButton === "selectListingsForApply" && (
          <AutoPaymentListingSection />
        )}
      </div>
    </div>
  );
};

export default AutoPaymentsForm;
