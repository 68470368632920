import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'

export const paginationItemRender = (page, type, el) => {
  if (type === 'prev') {
    return <KeyboardArrowLeft />
  }

  if (type === 'next') {
    return <KeyboardArrowRight />
  }

  return el
}
