import React from "react";
import { Checkbox } from "antd";
import imageNotFound from "../../assets/imageNotFound.png";

const ListingCard = ({
  cardImage = imageNotFound,
  cardText = "Add Description here",
}) => {
  return (
    <div className="h-28 flex border-2 border-creme shadow-xl">
      <div className="w-[30%]">
        <img src={cardImage} alt="apartment" className="h-full" />
      </div>
      <div className="w-[70%] flex items-center ml-5">
        <div className="text-themeGrey text-lg cursor-pointer">{cardText}</div>
      </div>
      <div className="flex items-end justify-end pr-3 pb-2">
        <Checkbox />
      </div>
    </div>
  );
};

export default ListingCard;
