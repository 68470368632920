/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useMemo } from "react";
import { useState } from "react";
import { apiInstance, routes } from "../../utils/API";
import TopBar from "./TopBar";
import { Button } from "antd";
import Loading from "../../components/Loaders/Loading";

import NoAccess from "../../components/NoAccess";
import { useContext } from "react";
import { AppContext } from "../../AppContext";

import ReservationOverviewCard from "../../components/ReservationOverviewCard/ReservationOverviewCard";
import { Icon } from "@iconify-icon/react";
import CustomInput from "../../components/Input/Input";

export default function ReservationsOverview() {
  const profile = useContext(AppContext).profile || {};
  const hasReadAccess = profile?.readReservationsOverview;
  const [date, setDate] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [todayCheckins, setTodayCheckins] = useState(false);
  const [todayCheckouts, setodayCheckouts] = useState(false);
  const [currentGuests, setCurrentGuests] = useState(false);
  const [criticalBookings, setCriticalBookings] = useState(false);

  const [searchTodayCheckins, setSearchTodayCheckins] = useState("");
  const [searchTodayCheckouts, setSearchodayCheckouts] = useState("");
  const [searchCurrentGuests, setSearchCurrentGuests] = useState("");
  const [searchCriticalBookings, setSearchCriticalBookings] = useState("");

  useEffect(() => {
    if (hasReadAccess) getReservationsOverview();
  }, [hasReadAccess]);

  const getReservationsOverview = async () => {
    setLoading(true);
    try {
      let url = routes.RESERVATIONS_OVERVIEW;

      const response = await apiInstance.get(url);
      console.log(response.data);
      setData(response.data || []);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const todayCheckinsModefied = useMemo(() => {
    if (searchTodayCheckins) {
      return data?.todayCheckins?.filter((item) => {
        if (
          item.allocatedProperty
            .toLowerCase()
            .includes(searchTodayCheckins.toLowerCase()) ||
          item.guest
            .toLowerCase()
            .includes(searchTodayCheckins.toLowerCase()) ||
          item.checkOutDate
            .toLowerCase()
            .includes(searchTodayCheckins.toLowerCase())
        ) {
          return true;
        }
      });
    } else {
      return data?.todayCheckins;
    }
  }, [searchTodayCheckins, data]);

  const todayCheckoutsModefied = useMemo(() => {
    if (searchTodayCheckouts) {
      return data?.todayCheckouts?.filter((item) => {
        if (
          item.allocatedProperty
            .toLowerCase()
            .includes(searchTodayCheckouts.toLowerCase()) ||
          item.guest.toLowerCase().includes(searchTodayCheckouts.toLowerCase())
        ) {
          return true;
        }
      });
    } else {
      return data?.todayCheckouts;
    }
  }, [searchTodayCheckouts, data]);

  const currentGuestsModefied = useMemo(() => {
    if (searchCurrentGuests) {
      return data?.currentGuests?.filter((item) => {
        if (
          item.allocatedProperty
            .toLowerCase()
            .includes(searchCurrentGuests.toLowerCase()) ||
          item.guest
            .toLowerCase()
            .includes(searchCurrentGuests.toLowerCase()) ||
          item.checkOutDate
            .toLowerCase()
            .includes(searchCurrentGuests.toLowerCase())
        ) {
          return true;
        }
      });
    } else {
      return data?.currentGuests;
    }
  }, [searchCurrentGuests, data]);

  const criticalBookingModefied = useMemo(() => {
    if (searchCriticalBookings) {
      return data?.earlyCheckouts?.filter((item) => {
        if (
          item?.allocatedProperty
            ?.toLowerCase()
            ?.includes(searchCriticalBookings.toLowerCase()) ||
          item.guest
            .toLowerCase()
            .includes(searchCriticalBookings.toLowerCase()) ||
          item.checkOutDate
            .toLowerCase()
            .includes(searchCriticalBookings.toLowerCase())
        ) {
          return true;
        }
      });
    } else {
      return data?.earlyCheckouts;
    }
  }, [searchCriticalBookings, data]);

  if (profile._id && !hasReadAccess) {
    return <NoAccess />;
  }
  return (
    <div className="px-5 lg:mx-12">
      <TopBar />

      {loading ? (
        <div className="h-[50vh] bg-white rounded-lg flex justify-center items-center">
          <Loading />
        </div>
      ) : (
        <div className="flex flex-col gap-4 gap-y-24 ">
          <div className="flex flex-wrap justify-between mb-10 gap-y-28">
            <div
              className={`w-[500px] min-w-[300px] mx-2 ${
                !todayCheckins ? "h-[380px]" : "h-[760px]"
              } mb-10`}
            >
              <div className="flex flex-wrap justify-between">
                <div>
                  <div className="font-bold text-sm">Today's check-ins</div>
                  <div className=" text-xs mb-3">
                    {data?.todayCheckins?.length} check-ins today
                  </div>
                </div>

                <div className="w-[200px] mb-2">
                  <CustomInput
                    placeholder={"Search"}
                    className="custom-input"
                    value={searchTodayCheckins}
                    onChange={(e) => {
                      setSearchTodayCheckins(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="h-full overflow-y-auto flex flex-col gap-2">
                {todayCheckinsModefied?.map((item) => {
                  return <ReservationOverviewCard item={item} />;
                })}
              </div>
              <div className="flex justify-end">
                <Button
                  className="bg-green-800 text-white flex itemes-center justify-center hover:text-white mr-2 mt-2"
                  onClick={() => setTodayCheckins(!todayCheckins)}
                >
                  {!todayCheckins ? "Show More " : "Show Less"}
                  {!todayCheckins ? (
                    <Icon
                      icon="iconamoon:arrow-down-2-bold"
                      width={20}
                      height={20}
                    />
                  ) : (
                    <Icon
                      icon="iconamoon:arrow-up-2-bold"
                      width={20}
                      height={20}
                    />
                  )}
                </Button>
              </div>
            </div>
            <div
              className={`w-[500px] min-w-[300px] mx-2 ${
                !todayCheckouts ? "h-[380px]" : "h-[760px]"
              }`}
            >
              <div className="flex flex-wrap justify-between">
                <div>
                  <div className="font-bold text-sm">Today's check-outs</div>
                  <div className=" text-xs mb-3">
                    {data?.todayCheckouts?.length} check-outs today
                  </div>
                </div>

                <div className="w-[200px] mb-2">
                  <CustomInput
                    placeholder={"Search"}
                    className="custom-input"
                    value={searchTodayCheckouts}
                    onChange={(e) => {
                      setSearchodayCheckouts(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="h-full overflow-y-auto flex flex-col gap-2">
                {todayCheckoutsModefied?.map((item) => {
                  return (
                    <ReservationOverviewCard item={item} cleaning={true} />
                  );
                })}
              </div>
              <div className="flex justify-end">
                <Button
                  className="bg-green-800 text-white flex itemes-center justify-center hover:text-white mr-2 mt-2"
                  onClick={() => setodayCheckouts(!todayCheckouts)}
                >
                  {!todayCheckouts ? "Show More " : "Show Less"}
                  {!todayCheckouts ? (
                    <Icon
                      icon="iconamoon:arrow-down-2-bold"
                      width={20}
                      height={20}
                    />
                  ) : (
                    <Icon
                      icon="iconamoon:arrow-up-2-bold"
                      width={20}
                      height={20}
                    />
                  )}
                </Button>
              </div>
            </div>
          </div>
          <div
            className={`w-full min-w-[300px] mx-2  ${
              !currentGuests ? "h-[380px]" : "h-[760px]"
            } mb-10`}
          >
            <div className="flex flex-wrap justify-between">
              <div>
                <div className="font-bold text-sm">Current guests</div>
                <div className=" text-xs mb-3">
                  {data?.currentGuests?.length} Current guests
                </div>
              </div>

              <div className="w-[200px]">
                <CustomInput
                  placeholder={"Search"}
                  className="custom-input"
                  value={searchCurrentGuests}
                  onChange={(e) => {
                    setSearchCurrentGuests(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="h-full overflow-y-auto flex flex-col gap-2">
              {currentGuestsModefied?.map((item) => {
                return <ReservationOverviewCard item={item} />;
              })}
            </div>
            <div className="flex justify-end">
              <Button
                className="bg-green-800 text-white flex itemes-center justify-center hover:text-white mr-2 mt-2"
                onClick={() => setCurrentGuests(!currentGuests)}
              >
                {!currentGuests ? "Show More " : "Show Less"}
                {!currentGuests ? (
                  <Icon
                    icon="iconamoon:arrow-down-2-bold"
                    width={20}
                    height={20}
                  />
                ) : (
                  <Icon
                    icon="iconamoon:arrow-up-2-bold"
                    width={20}
                    height={20}
                  />
                )}
              </Button>
            </div>
          </div>
          <div
            className={`w-full min-w-[300px] mx-2 mb-2 ${
              !criticalBookings ? "h-[380px]" : "h-[760px]"
            } `}
          >
            <div className="flex flex-wrap justify-between">
              <div>
                <div className="font-bold text-sm">
                  Dispute and Early Check-outs
                </div>
                <div className=" text-xs mb-3">
                  {data?.earlyCheckouts?.length} Critical bookings
                </div>
              </div>

              <div className="w-[200px] mb-2">
                <CustomInput
                  placeholder={"Search"}
                  className="custom-input"
                  value={searchCriticalBookings}
                  onChange={(e) => {
                    setSearchCriticalBookings(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="h-full overflow-y-auto flex flex-col gap-2 pb-20">
              {criticalBookingModefied?.map((item) => {
                return <ReservationOverviewCard item={item} />;
              })}
            </div>
            <div className="flex justify-end">
              <Button
                className="bg-green-800 text-white flex itemes-center justify-center hover:text-white mr-2 mt-2"
                onClick={() => setCriticalBookings(!criticalBookings)}
              >
                {!criticalBookings ? "Show More " : "Show Less"}
                {!criticalBookings ? (
                  <Icon
                    icon="iconamoon:arrow-down-2-bold"
                    width={20}
                    height={20}
                  />
                ) : (
                  <Icon
                    icon="iconamoon:arrow-up-2-bold"
                    width={20}
                    height={20}
                  />
                )}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
