import { Avatar } from "antd";
import React from "react";

const MessageCard = () => {
  return (
    <div className="h-24 m-1 px-2 rounded-lg flex items-center justify-start gap-8 hover:bg-green-100">
      <Avatar size={"large"} />
      <div>
        <div className="font-bold text-base">Msood 111</div>
        <div className="text-xs">26 Jun 2023 - 27 Jun 23</div>
        <div className="text-themeGrey text-xs">
          3B SW15 - The Pecham Apartment
        </div>
      </div>
      <div>27 June 23</div>
    </div>
  );
};

export default MessageCard;
