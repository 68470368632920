import { Checkbox, Input, Modal } from "antd";
import React from "react";

const AddNewGuestModal = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <Modal
      title={
        <div className="text-bold text-themeGrey text-lg pb-5 border-b-2 border-creme">
          Add new guest
        </div>
      }
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      width={620}
    >
      <div>
        <div>
          <div className="text-themeGrey mb-2">First Name</div>
          <Input className="w-full rounded-none" placeholder="First Name" />
        </div>
        <div>
          <div className="text-themeGrey mb-2">Last Name</div>
          <Input className="w-full rounded-none" placeholder="Last Name" />
        </div>
        <div>
          <div className="text-themeGrey mb-2">Name</div>
          <Input className="w-full rounded-none" placeholder="Name" />
        </div>
        <div>
          <div className="text-themeGrey mb-2">Email</div>
          <Input className="w-full rounded-none" placeholder="Email" />
        </div>
        <div>
          <div className="text-themeGrey mb-2">Phone</div>
          <Input className="w-full rounded-none" placeholder="Phone" />
        </div>
        <div>
          <div className="text-themeGrey mb-2">City</div>
          <Input className="w-full rounded-none" placeholder="City" />
        </div>
        <div>
          <div className="text-themeGrey mb-2">Country</div>
          <Input className="w-full rounded-none" placeholder="Country" />
        </div>
        <Checkbox className="my-5 text-themeGrey">
          Agreed to marketing emails
        </Checkbox>
      </div>
    </Modal>
  );
};

export default AddNewGuestModal;
