import React from "react";
import { Divider, Switch, Input, Select, Button } from "antd";
import GenericButton from "../GenericButton/GenericButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Link, useNavigate } from "react-router-dom";
import { INBOX_AUTOMATIONS, LISTINGS } from "../../constants/dashboardRoutes";
import { apiInstance } from "../../utils/API";
import { useState } from "react";
import { useEffect } from "react";
import { formatDate } from "../../utils/dateHelper";
import { getLogoPath } from "../../utils/constants";
import Loading from "../Loaders/Loading";
import { AUTOMATION_NEW } from "../../constants/dashboardRoutes";
import { notificationMessage } from "../../utils";
import DeleteAutomationModal from "../DeleteAutomationModal/DeleteAutomationModal";
import { eventOptions, sortByOptions } from "../../enum";

const { Search } = Input;

const InboxAutomation = () => {
  const navigate = useNavigate();
  const [automations, setAutomations] = useState([]);
  const [channels, setChannels] = useState([
    { _id: "1", companyName: "Direct" },
    { _id: "2", companyName: "RentalsUnited" },
  ]);
  const [loading, setLoading] = useState(true);
  const [isDeleteAutomationModalOpen, setIsDeleteAutomationModalOpen] =
    useState(false);
  const [selectedAutomationId, setSelectedAutomationId] = useState(null);
  const [isShowMoreChannelsClicked, setIsShowMoreChannelsClicked] =
    useState(false);
  const [isShowMoreListingsClicked, setIsShowMoreListingsClicked] =
    useState(false);
  const [listings, setListings] = useState([]);
  const [isListingLoading, setIsListingLoading] = useState(false);
  const [selectedAutomation, setSelectedAutomation] = useState(null);
  const [filters, setFilters] = useState({
    name: "",
    listings: [],
    event: "",
    channelIds: [],
    sortBy: "",
  });

  useEffect(() => {
    getListings();
  }, []);

  const getListings = async () => {
    try {
      setIsListingLoading(true);
      const response = await apiInstance.get(
        `/properties/automationProperties?page=1&pageSize=1000`
      );
      if (response.status === 200) {
        setListings(response.data?.propertiesList);
      }
      setIsListingLoading(false);
    } catch (error) {
      notificationMessage("error", "Error fetching listings!");
      setIsListingLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getAutomations();
    getChannels();
  }, []);

  async function getAutomations() {
    try {
      setLoading(true);
      let url = `/api/automations`;
      const list = await apiInstance.get(url);
      setAutomations(list.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getFilteredAutomations();
  }, [filters]);

  const getFilteredAutomations = async () => {
    try {
      setLoading(true);
      let url = `/api/automations?`;
      if (filters.name) {
        url = url + `name=${filters.name}&`;
      }
      if (filters.listings.length) {
        url = url + `listings=${filters.listings}&`;
      }
      if (filters.event) {
        url = url + `event=${filters.event}&`;
      }
      if (filters.channelIds.length) {
        url = url + `channelIds=${filters.channelIds}&`;
      }
      if (filters.sortBy) {
        url = url + `sortBy=${filters.sortBy}&`;
      }
      const response = await apiInstance.get(url);
      if (response.status === 200) {
        setAutomations(response.data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getChannels = async () => {
    try {
      const apiUrl = `/reservation/channels`;
      const response = await apiInstance.get(apiUrl);
      setChannels((arr) => [...arr, ...response.data]);
    } catch (e) {}
  };

  const handleAutomationSwitch = async (checked, id) => {
    try {
      const response = await apiInstance.put(
        `/api/automations/${id}/${checked}`
      );

      // Success case.
      if (response?.status === 200) {
        notificationMessage(
          "success",
          `${response?.data?.name} has been ${
            checked ? "activated!" : "deactivated!"
          }`
        );

        return;
      }

      // Fail case.
      notificationMessage("error", "Error updating the status!");
    } catch (error) {
      notificationMessage("error", "Error updating the status!");
      console.error(error);
    }
  };

  const handleDeleteAutomation = async () => {
    try {
      setLoading(true);
      const response = await apiInstance.delete(
        `/api/automations/${selectedAutomation._id}`
      );
      if (response.status === 200) {
        const updatedAutomations = automations.filter(
          (automation) => automation._id !== selectedAutomation._id
        );
        setSelectedAutomation(null);
        setAutomations(updatedAutomations);
        notificationMessage("success", "Automation deleted!");
        setIsDeleteAutomationModalOpen(false);
        setLoading(false);
      }
    } catch (error) {
      notificationMessage("error", "Error deleting automation!");
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-6">
        {/* Name Input */}
        <Search
          placeholder="Search by automation name"
          className="w-full"
          onChange={(e) => setFilters({ ...filters, name: e.target.value })}
        />
        {/* Listings Select */}

        <Select
          options={listings.map((listing) => ({
            label: `${listing.mainTitle} (${listing.appartmentIndex})`,
            value: listing._id,
          }))}
          loading={isListingLoading}
          className="w-full"
          placeholder="Filter by listing"
          mode="multiple"
          onChange={(e) => setFilters({ ...filters, listings: e })}
        />

        {/* Event Select */}
        <Select
          options={eventOptions}
          className="w-full"
          placeholder="Filter by event"
          onChange={(e) => setFilters({ ...filters, event: e })}
        />
        {/* Channel Select */}
        <Select
          options={channels.map((e) => {
            return {
              label: e?.companyName,
              value: e?._id,
              key: e?._id,
            };
          })}
          className="w-full"
          placeholder="Filter by channel"
          mode="multiple"
          onChange={(e) => setFilters({ ...filters, channelIds: e })}
        />
        {/* Sort By Select */}
        <Select
          options={sortByOptions}
          className="w-full"
          placeholder="Sort by"
          onChange={(e) => setFilters({ ...filters, sortBy: e })}
        />
        <GenericButton
          label="Create new"
          icon={<AddCircleIcon className="!text-[15px]" />}
          className="bg-buttonTheme hover:!bg-buttonHoverTheme w-32 xl:place-self-center"
          // eslint-disable-next-line react/jsx-no-duplicate-props
          onClick={() => {
            navigate(AUTOMATION_NEW);
          }}
        />
      </div>

      <div>
        {loading ? (
          <div className="h-[50vh] bg-white rounded-lg flex justify-center items-center">
            <Loading />
          </div>
        ) : (
          <>
            {automations.length ? (
              automations.map((automation) => {
                return (
                  <div key={automation._id}>
                    <div className="flex items-center mt-3">
                      <Switch
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        defaultChecked={automation.isActive}
                        onChange={(checked) => {
                          handleAutomationSwitch(checked, automation._id);
                        }}
                      />
                      <p className="text-[22px] text-buttonTheme hover:text-buttonHoverTheme hover:underline hover:underline-offset-2 cursor-pointer ml-3">
                        {automation.name}
                      </p>
                    </div>
                    <div className="flex">
                      <p className="font-semibold text-slate-500">Subject:</p>
                      <p className="text-slate-500 ml-1">
                        {automation.subject}
                      </p>
                    </div>
                    <div className="flex">
                      <p className="font-semibold text-slate-500">Event:</p>
                      <p className="text-slate-500 ml-1">{automation.event}</p>
                    </div>
                    {automation.conditions.length > 0 && (
                      <div className="flex">
                        <p className="font-semibold text-slate-500">
                          Conditions:
                        </p>
                        <p className="text-slate-500 ml-1">
                          {automation.conditions.map((condition, index) => (
                            <span key={index}>
                              {Array.isArray(condition.label)
                                ? condition.label.join(", ")
                                : condition.label}
                              {index < automation.conditions.length - 1 && ", "}
                            </span>
                          ))}
                        </p>
                      </div>
                    )}
                    {/* Channels */}
                    {automation.channelIds.length > 0 && (
                      <p className="font-semibold text-slate-500">Channels:</p>
                    )}
                    {automation.channelIds.slice(0, 3).map((ch, idx) => {
                      const channel = channels.find((e) => e._id === ch);
                      return (
                        <div className="flex" key={idx}>
                          <img
                            src={getLogoPath(channel?.companyName)}
                            className="h-5 max-h-5 object-contain w-auto"
                            alt={channel?.companyName}
                          />
                          <p className="text-slate-500 ml-1">
                            {channel?.companyName}
                          </p>
                        </div>
                      );
                    })}
                    {automation.channelIds.length > 3 &&
                      isShowMoreChannelsClicked &&
                      automation._id === selectedAutomationId &&
                      automation.channelIds.slice(3).map((ch, idx) => {
                        const channel = channels.find((e) => e._id === ch);
                        return (
                          <div className="flex" key={idx}>
                            <img
                              src={getLogoPath(channel?.companyName)}
                              className="h-5 max-h-5 object-contain w-auto"
                              alt={channel?.companyName}
                            />
                            <p className="text-slate-500 ml-1">
                              {channel?.companyName}
                            </p>
                          </div>
                        );
                      })}
                    {automation.channelIds.length > 3 &&
                    !isShowMoreChannelsClicked ? (
                      <GenericButton
                        label={"Show more channels..."}
                        className="bg-lightButtonTheme hover:!bg-lightButtonHoverTheme text-buttonTheme hover:!text-buttonTheme mt-2"
                        onClick={() => {
                          setIsShowMoreChannelsClicked(true);
                          setSelectedAutomationId(automation._id);
                        }}
                      />
                    ) : (
                      <GenericButton
                        label={"Hide more channels..."}
                        className="bg-lightButtonTheme hover:!bg-lightButtonHoverTheme text-buttonTheme hover:!text-buttonTheme mt-2"
                        onClick={() => {
                          setIsShowMoreChannelsClicked(false);
                          setSelectedAutomationId(null);
                        }}
                      />
                    )}
                    {/* Listings */}
                    {automation.listings.length > 0 && (
                      <p className="font-semibold text-slate-500 mt-1">
                        Listings:
                      </p>
                    )}
                    {automation.listings.slice(0, 3).map((listing, idx) => {
                      return (
                        <p
                          key={idx}
                          className="text-buttonTheme hover:text-buttonHoverTheme cursor-pointer"
                          onClick={() => {
                            navigate(`/listings/${listing.appartmentIndex}`);
                          }}
                        >
                          {`${listing.mainTitle} (${listing.appartmentIndex})`}
                          <OpenInNewIcon className="!text-[17px]" />
                        </p>
                      );
                    })}

                    {automation.listings.length > 3 &&
                      isShowMoreListingsClicked &&
                      automation._id === selectedAutomationId &&
                      automation.listings.slice(3).map((listing, idx) => {
                        return (
                          <p
                            key={idx}
                            className="text-buttonTheme hover:text-buttonHoverTheme cursor-pointer"
                            onClick={() => {
                              navigate(`/listings/${listing.appartmentIndex}`);
                            }}
                          >
                            {`${listing.mainTitle} (${listing.appartmentIndex})`}
                            <OpenInNewIcon className="!text-[17px]" />
                          </p>
                        );
                      })}
                    {automation.listings.length > 3 &&
                    !isShowMoreListingsClicked ? (
                      <GenericButton
                        label={"Show more listings..."}
                        className="bg-lightButtonTheme hover:!bg-lightButtonHoverTheme text-buttonTheme hover:!text-buttonTheme mt-2"
                        onClick={() => {
                          setIsShowMoreListingsClicked(true);
                          setSelectedAutomationId(automation._id);
                        }}
                      />
                    ) : (
                      <GenericButton
                        label={"Hide more listings..."}
                        className="bg-lightButtonTheme hover:!bg-lightButtonHoverTheme text-buttonTheme hover:!text-buttonTheme mt-2"
                        onClick={() => {
                          setIsShowMoreListingsClicked(false);
                          setSelectedAutomationId(null);
                        }}
                      />
                    )}

                    <div className="flex">
                      <p className="font-semibold text-slate-500">
                        Creation date:
                      </p>
                      <p className="text-slate-500 ml-1">
                        {formatDate(
                          automation.createdAt,
                          "dd MMMM yyyy hh:mm aa"
                        )}
                      </p>
                    </div>
                    <div className="flex items-center justify-end">
                      <Link to={INBOX_AUTOMATIONS + "/" + automation._id}>
                        <GenericButton
                          label="Edit"
                          className="bg-lightButtonTheme hover:!bg-lightButtonHoverTheme text-buttonTheme hover:!text-buttonTheme"
                        />
                      </Link>
                      <GenericButton
                        label="Create similar"
                        icon={<ContentCopyIcon className="!text-[14px]" />}
                        iconLeftAlign={true}
                        className="bg-lightButtonTheme hover:!bg-lightButtonHoverTheme text-buttonTheme hover:!text-buttonTheme ml-2"
                        onClick={() => {
                          navigate(AUTOMATION_NEW, {
                            state: {
                              createSimilarId: automation._id,
                            },
                          });
                        }}
                      />
                      <GenericButton
                        label="Delete"
                        icon={<DeleteIcon className="!text-[16px]" />}
                        iconLeftAlign={true}
                        className="bg-[#ffebe6] hover:!bg-[#1d1d1d] text-[#ff5630] hover:!text-[#ff5630] ml-2"
                        onClick={() => {
                          setSelectedAutomation(automation);
                          setIsDeleteAutomationModalOpen(true);
                        }}
                      />
                      <DeleteAutomationModal
                        isModalOpen={isDeleteAutomationModalOpen}
                        setIsModalOpen={setIsDeleteAutomationModalOpen}
                        onConfirm={handleDeleteAutomation}
                      />
                    </div>
                    <Divider />
                  </div>
                );
              })
            ) : (
              <div className="h-screen flex items-center justify-center text-themeGrey font-bold">
                No Automations found.
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default InboxAutomation;
