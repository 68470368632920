import { Modal } from "antd";
import React from "react";
import GenericButton from "../GenericButton/GenericButton";

const DeleteAPIKeyModal = ({ isModalOpen, setIsModalOpen, onOk }) => {
  return (
    <Modal
      title={
        <div className="text-bold text-themeGrey text-xl pb-5 pt-4 md:pt-0 border-b-2 border-creme">
          Are you sure you want to delete?
        </div>
      }
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      width={620}
      onOk={onOk}
      footer={[
        <GenericButton
          label="Cancel"
          onClick={() => setIsModalOpen(false)}
          key={1}
        />,
        <GenericButton
          label="Delete"
          className="bg-cancelButton hover:!bg-cancelButtonHover text-white hover:!text-white"
          onClick={onOk}
          key={2}
        />,
      ]}>

    </Modal>
  );
};

export default DeleteAPIKeyModal;
