import { DatePicker, Select } from "antd";

import dayjs from "dayjs";

import LocalOfferIcon from "@mui/icons-material/LocalOffer";

import { useState } from "react";
import { useContext } from "react";
import { AppContext } from "../../AppContext";
import { manageToast } from "../../components/Common/ManageToast";
import GenericButton from "../../components/GenericButton/GenericButton";
import CustomInput from "../../components/Input/Input";
import { apiInstance, routes } from "../../utils/API";
import { formatDate } from "../../utils/dateHelper";

let timeOut = null;

export default function TopBar({
  date,
  setDate,
  count,
  setTag,
  setPage,
  limit,
  setName,
  setLimit,
  months,
  setMonths,
  show = true,
}) {
  const [syncing, setSyncing] = useState(false);
  const profile = useContext(AppContext).profile || {};
  const [search, setSearch] = useState("");
  const [searchName, setSearchName] = useState("");
  const isAdmin = profile.isAdmin;

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };

  return (
    <div className="mb-4">
      <div className="flex flex-wrap md:flex-nowrap gap-2 md:gap-0 justify-between items-center mb-4">
        <div className="text-themeGrey text-xl font-bold">
          Reservations Overview
          {/* {count > 0 && <span className="text-base">({count})</span>} */}
        </div>
        {/* <div className="flex gap-x-0 md:gap-x-3">
          {show && (
            <Select
              value={limit}
              options={[
                { value: 20, label: 20 },
                { value: 50, label: 50 },
                { value: 100, label: 100 },
              ]}
              className="w-max"
              onChange={(l) => {
                setLimit(l);
                setPage(1);
              }}
            />
          )}
        </div> */}
      </div>
      {/* <div className="flex w-full gap-x-6 justify-between">
        <div className="mob-w-100">
          <div className="flex gap-2">
            <div>
              <div className="text-sm text-buttonTheme font-medium flex items-center gap-x-1 mb-1 mr-5 md:mr-0">
                {"Filter by Property"}
              </div>

              <div className="w-[200px]">
                <CustomInput
                  placeholder={"Enter Property name"}
                  className="custom-input"
                  value={searchName}
                  onChange={(e) => {
                    setSearchName(e.target.value);
                    if (timeOut) {
                      clearTimeout(timeOut);
                    }
                    timeOut = setTimeout(() => {
                      setName(e.target.value);
                    }, 1000);
                  }}
                />
              </div>
            </div>
            <div>
              <div className="text-sm text-buttonTheme font-medium flex items-center gap-x-1 mb-1 mr-5 md:mr-0">
                {"Filter by Tag"}
              </div>

              <div className="w-[200px]">
                <CustomInput
                  placeholder={"Enter tag"}
                  className="custom-input"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    if (timeOut) {
                      clearTimeout(timeOut);
                    }
                    timeOut = setTimeout(() => {
                      setTag(e.target.value);
                    }, 1000);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="checkout-container flex gap-3">
          <div>
            <div className="text-sm text-buttonTheme font-medium flex items-center gap-x-1 mb-1 mr-5 md:mr-0">
              Select Range
            </div>
            <div className="w-[200px]">
              <Select
                allowClear
                className="w-full"
                status="blue"
                rootClassName="border-red-500"
                placeholder="Select Rance"
                options={[
                  { label: "Last 3 Months", value: "3" },
                  { label: "Last 6 Months", value: "6" },
                  { label: "Last 12 Months", value: "12" },
                ]}
                onChange={(e) => {
                  if (e) {
                    setMonths(e);
                    setDate(null);
                  } else {
                    setMonths(null);
                  }
                }}
                value={months}
              />
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div className="text-sm text-buttonTheme font-medium flex items-center gap-x-1 mb-1">
              Date
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={18}
                  height={18}
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M19.002 3h-14c-1.103 0-2 .897-2 2v4h2V5h14v14h-14v-4h-2v4c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.898-2-2-2z"
                  />
                  <path fill="currentColor" d="m11 16l5-4l-5-4v3.001H3v2h8z" />
                </svg>
              </span>
            </div>
            <div className="flex items-center gap-x-1">
              {show && (
                <GenericButton
                  label="Today"
                  onClick={() => {
                    if (
                      formatDate(new Date(), "dd MMM, yyyy") ===
                      formatDate(date, "dd MMM, yyyy")
                    ) {
                      setDate(null);
                    } else {
                      setDate(new Date());
                      setMonths(null);
                    }
                    setPage(1);
                  }}
                  className={`py-1 rounded-md md:px-[15px] px-1 text-white inline-block ${
                    formatDate(new Date(), "dd MMM, yyyy") ===
                    formatDate(date, "dd MMM, yyyy")
                      ? "bg-buttonHoverTheme hover:!bg-buttonHoverTheme hover:!text-white "
                      : "bg-buttonTheme hover:!bg-buttonHoverTheme hover:!text-white "
                  }`}
                />
              )}
              <DatePicker
                labelName="check In date"
                className="h-8"
                size="small"
                disabledDate={disabledDate}
                picker="month"
                allowClear={true}
                value={date ? dayjs(date) : null}
                onChange={(e, stringDate) => {
                  const d = !stringDate ? null : new Date(stringDate);
                  setDate(d);
                  setMonths(null);
                  setPage(1);
                }}
              />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
