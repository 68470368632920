export const getUserName = () => {
  return localStorage.getItem("userName");
};

export const getUserEmail = () => {
  return localStorage.getItem("userEmail");
};

export const getAuthorizationToken = () => {
  return localStorage?.getItem("authorizationToken");
};

export const getUserRole = () => {
  return localStorage?.getItem("role");
};

export const logOut = () => {
  localStorage.removeItem("userName");
  localStorage.removeItem("userEmail");
  localStorage.removeItem("role");
  localStorage.removeItem("authorizationToken");
};
export const PROPERTY_TYPE = {
  3: "Apartment",
  4: "Bed and breakfast",
  7: "Chalet",
  16: "Guest house",
  20: "Hotel",
  22: "Lodge",
  30: "Resort",
  35: "Villa",
  37: "Castle",
  63: "Aperthotel",
  64: "Boat",
  65: "Cottage",
  66: "Camping",
  67: "House",
};

export const ROOM_TYPE = {
  1: "Entire Home",
  2: "Private Room",
  3: "Shared Room",
};

export const BATH_TYPE = {
  1: "Private",
  2: "Shared",
};

export const messages = {
  INVALID_CREDENTIALS: "Invalid emai or Password",
  EMAIL_REQUIRED: "Email is a required field.",
  PASSWORD_REQUIRED: "Password is a required field",
};

export const sort = [
  { label: "Listing name (external) asc", value: "externalAsc" },
  { label: "Listing name (internal) asc", value: "internalAsc" },
  { label: "Listing name (external) desc", value: "externalDesc" },
  { label: "Listing name (internal) desc", value: "internalDesc" },
];

export const unitTypeList = [
  { value: "all", label: "All" },
  { value: "single units", label: "Single Units" },
  { value: "multi units", label: "Multi Units" },
];

export const YEARS = [2021, 2022, 2023, 2024, 2025];
export const MONTHS = [
  "january 2023",
  "feburary 2023",
  "march 2023",
  "april 2023",
  "may 2023",
];

export const MONTHS_YEAR = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

export const RESERVATION_STATUSES = [
  { label: "Confirmed", value: "Confirmed" },
  { label: "Modified", value: "Modified" },
  { label: "Canceled", value: "Canceled" },
  { label: "Request", value: "Request" },
  { label: "Pending", value: "Pending" },
];
export const USER_STATUSES = [
  { label: "ACTIVE", value: "ACTIVE" },
  { label: "INACTIVE", value: "INACTIVE" },
];

export function getLogoPath(name = "") {
  let name_ = name.toLowerCase();
  if (!name_ || name_ === "pms" || name_ === "direct")
    return "/channels/pms.jpeg";
  if (name_ === "rentalsunited") {
    return "/channels/rentalsunited.ico";
  }
  if (name_ === "vrbo") {
    return "/channels/vrbo.png";
  }
  if (name_ === "booking.com" || name_ === "bookingcom") {
    return "/channels/booking.com.png";
  }
  if (name_ === "expedia") {
    return "/channels/expedia.png";
  }
  if (name_ === "agoda") {
    return "/channels/agoda.jpg";
  }
  if (name_ === "hometogo") {
    return "/channels/hometogo.jpeg";
  }
  if (name_ === "hostelworld") {
    return "/channels/hostelworld.svg";
  }
  if (name_ === "leboncoin") {
    return "/channels/leboncoin.svg";
  }
  if (name_ === "travelstaytion.com") {
    return "/channels/travelstaytion.com.svg";
  }
  if (name_ === "ostrovok") {
    return "/channels/ostrovok.svg";
  }
  if (name_ === "clickstay") {
    return "/channels/clickstay.png";
  }
  if (name_ === "airbnb") {
    return "/channels/airbnb.png";
  }
  if (name_ === "tripadvisor") {
    return "/channels/tripadvisor.svg";
  }
  if (name_ === "cuddlynest") {
    return "/channels/cuddlynest.png";
  }
  if (name_ === "glamping hub") {
    return "/channels/glamping-hub.svg";
  }
  if (name_ === "holidu gmbh") {
    return "/channels/holidu-gmbh.svg";
  }
  if (name_ === "google travel") {
    return "/channels/google-travel.png";
  }
  if (name_ === "altovita") {
    return "/channels/altovita.svg";
  }
  if (name_ === "homelike") {
    return "/channels/homelike.svg";
  }
  if (name_ === "floridarentals") {
    return "/channels/floridarentals.png";
  }
  if (name_ === "housestay") {
    return "/channels/housestay.png";
  }
  if (name_ === "all luxury apartments") {
    return "/channels/all-luxury-apartments.png";
  }
  if (name_ === "vacation finder") {
    return "/channels/vacation-finder.png";
  }
  if (name_ === "trip.com") {
    return "/channels/trip.com.png";
  }
  if (name_ === "whimstay") {
    return "/channels/whimstay.png";
  }
  if (name_ === "villa finder") {
    return "/channels/villa-finder.svg";
  }
  if (name_ === "nestpick") {
    return "/channels/nestpick.png";
  }
  if (name_ === "plum guide") {
    return "/channels/plum-guide.png";
  }
  if (name_ === "top villas") {
    return "/channels/top-villas.svg";
  }
  if (name_ === "homes & villas by marriott international") {
    return "/channels/homes-&-villas-by-marriott-international.svg";
  }
  if (name_ === "at ease") {
    return "/channels/at-ease.png";
  }
  if (name_ === "golfhom") {
    return "/channels/golfhom.jpg";
  }
  if (name_ === "likibu") {
    return "/channels/likibu.png";
  }
  if (name_ === "rakuten stay") {
    return "/channels/rakuten-stay.png";
  }
  if (name_ === "kid & coe") {
    return "/channels/kid-&-coe.png";
  }
  if (name_ === "onefinestay") {
    return "/channels/onefinestay.png";
  }
  if (name_ === "hopper") {
    return "/channels/hopper.png";
  }
  if (name_ === "smiling house") {
    return "/channels/smiling-house.png";
  }
  if (name_ === "muchosol") {
    return "/channels/muchosol.png";
  }
  if (name_ === "the quintess collection") {
    return "/channels/the-quintess-collection.png";
  }
  if (name_ === "the dyrt") {
    return "/channels/the-dyrt.svg";
  }
  if (name_ === "furnished finder") {
    return "/channels/furnished-finder.webp";
  }
  if (name_ === "makemytrip") {
    return "/channels/makemytrip.png";
  }
  if (name_ === "zumper") {
    return "/channels/zumper.svg";
  }
  if (name_ === "stayone") {
    return "/channels/stayone.svg";
  }
  if (name_ === "oliver's travels") {
    return "/channels/oliver's-travels.jpg";
  }
  if (name_ === "villaway") {
    return "/channels/villaway.svg";
  }
  if (name_ === "housing anywhere") {
    return "/channels/housing-anywhere.svg";
  }
}
