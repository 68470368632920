import React from "react";
import { Checkbox } from "antd"; // Import the Ant Design Checkbox component
import placeholderImage from "../../assets/placeholder.jpeg";

const SmallListingCard = ({
  cardText = "3B SW15 The Putney Apartment (155609)",
  cardImage = placeholderImage,
  isChecked = true,
}) => {
  return (
    <div className="bg-selectedGreen w-full h-16 rounded-lg border border-themeGreen flex items-center justify-center relative">
      <div className="px-2 grid grid-cols-6 gap-1">
        <div className="col-span-1 flex items-center justify-center">
          <img src={cardImage} alt="Listing" className="rounded-lg" />
        </div>
        <div className="text-sm col-span-5">{cardText}</div>
      </div>
      {/* Add the Checkbox component to the bottom right */}
      <div className="absolute bottom-2 right-2">
        <Checkbox checked={isChecked} />
      </div>
    </div>
  );
};

export default SmallListingCard;
