import React from 'react'
import { Table, Tooltip } from 'antd'
import WarningOutlined from '@mui/icons-material/WarningOutlined'

import { numberWithCommas } from '../../utils/helper'

export default function IncomeTable (props) {
  const {
    handleSortIcon,
    loading,
    onTableChange,
    record,
  } = props

  const columns = [
    {
      title: 'Property Name',
      dataIndex: 'propertyName',
      key: 'propertyName',
      fixed: 'left', // Make this column sticky
      width: 300, // Increase the width of Property Name column
      className: '!bg-[#d3e5f0]',
      render: (_, record) => {
        return (
          <div className="flex items-center cursor-pointer">
            <p>{record.propertyName}</p>
          </div>
        )
      },
      sorter: (a, b) => a?.propertyName?.localeCompare(b.propertyName),
      sortIcon: (sortOrder) => handleSortIcon(sortOrder),
    },
    {
      title: 'Net revenue',
      dataIndex: 'netRevenue',
      key: 'netRevenue',
      sorter: true,
      render: (_, record) => {
        return <p>£ {numberWithCommas(record.netRevenue)}</p>
      },
      sortIcon: (sortOrder) => handleSortIcon(sortOrder),
    },
    {
      title: 'Direct Costs',
      dataIndex: 'expense',
      key: 'expense',
      sorter: true,
      render: (_, record) => {
        return (
          <p>
            {typeof record.expense === 'string'
              ? record.expense
              : `£ ${numberWithCommas(record.expense)}`}
          </p>
        )
      },
      sortIcon: (sortOrder) => handleSortIcon(sortOrder),
    },
    {
      title: 'Gross profit',
      dataIndex: 'profit',
      key: 'profit',
      sorter: true,
      render: (_, record) => {
        return <p>£ {numberWithCommas(record.profit)}</p>
      },
      sortIcon: (sortOrder) => handleSortIcon(sortOrder),
    },
    {
      title: 'Gross profit margin',
      dataIndex: 'grossRevenueMargen',
      key: 'grossRevenueMargen',
      sorter: true,
      render: (_, record) => {
        if (Number(record.netRevenue) !== 0) {
          return Number(record.grossRevenueMargen) < 0 ? (
            <div
              className="bg-red-200 flex justify-center items-center mx-10 rounded-xl">
              <p>{numberWithCommas(Number(record.grossRevenueMargen))} %</p>
              {record?.state && (
                <Tooltip title="Cost unit">
                  <WarningOutlined
                    className="text-black ml-1 !text-[20px] w-[50px]"/>
                </Tooltip>
              )}
            </div>
          ) : (
            <div className="bg-green-200 flex justify-center mx-10 rounded-xl">
              <p>{numberWithCommas(Number(record.grossRevenueMargen))} %</p>
            </div>
          )
        } else {
          return (
            <div className="bg-blue-200 flex justify-center mx-10 rounded-xl">
              <p>{'No revenues'}</p>
            </div>
          )
        }
      },
      sortIcon: (sortOrder) => handleSortIcon(sortOrder),
    },
    {
      title: 'Occupancy Rate',
      dataIndex: 'occupancyRate',
      key: 'occupancyRate',
      width: 120, // Reduce the width of Occupancy Rate column
      sorter: true,
      render: (_, record) => {
        return <p>{numberWithCommas(record.occupancyRate)} %</p>
      },
      sortIcon: (sortOrder) => handleSortIcon(sortOrder),
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      sorter: (a, b) =>
        new Date(a.contractStartDate) - new Date(b.contractStartDate),
      render: (_, record) => {
        return <p className="w-[95px]">{record.contractStartDate}</p>
      },
      sortIcon: (sortOrder) => handleSortIcon(sortOrder),
    },
    {
      title: 'Avg. Booking Lead time',
      dataIndex: 'averageBookingLeadDays',
      key: 'averageBookingLeadDays',
      width: 150, // Reduce the width of Avg. Booking Lead time column
      sorter: true,
      render: (_, record) => {
        return (
          <p>
            {record.averageBookingLeadDays?.toFixed(2) || '--'}
          </p>
        )
      },
      sortIcon: (sortOrder) => handleSortIcon(sortOrder),
    },
    {
      title: 'Airbnb Nb Reviews',
      dataIndex: 'airbnbNumberOfBookings',
      key: 'airbnbNumberOfBookings',
      width: 150, // Reduce the width of Airbnb Nb Reviews column
      sorter: true,
      render: (_, record) => {
        return <p>{record?.score?.nbAirbnb || '--'}</p>
      },
      sortIcon: (sortOrder) => handleSortIcon(sortOrder),
    },
    {
      title: 'Airbnb Score',
      dataIndex: 'airbnbReviewingScore',
      key: 'airbnbReviewingScore',
      width: 120, // Reduce the width of Airbnb Score column
      sorter: true,
      render: (_, record) => {
        return <p>{record?.score?.scoreAirbnb || '--'}</p>
      },
      sortIcon: (sortOrder) => handleSortIcon(sortOrder),
    },
    {
      title: 'BDC Nb Reviews',
      dataIndex: 'bookingComNumberOfBookings',
      key: 'bookingComNumberOfBookings',
      width: 150, // Reduce the width of BDC Nb Reviews column
      sorter: true,
      render: (_, record) => {
        return <p>{record?.score?.nbBDC || '--'}</p>
      },
      sortIcon: (sortOrder) => handleSortIcon(sortOrder),
    },
    {
      title: 'BDC Score',
      dataIndex: 'bookingComRewievingScore',
      key: 'bookingComRewievingScore',
      width: 120, // Reduce the width of BDC Score column
      sorter: true,
      render: (_, record) => {
        return <p>{record?.score?.scoreBDC || '--'}</p>
      },
      sortIcon: (sortOrder) => handleSortIcon(sortOrder),
    },
    {
      title: 'Availability',
      dataIndex: ['property', 'availability'],
      key: 'availability',
      width: 180,
      sorter: (a, b) => a?.property?.availability?.localeCompare(
        b?.property?.availability),
      sortIcon: (sortOrder) => handleSortIcon(sortOrder),
    },
  ]

  return (
    <Table
      loading={loading}
      dataSource={record}
      columns={columns}
      pagination={false}
      onChange={onTableChange}
      scroll={{ x: 2500 }}
      sticky={{ offsetHeader: 45 }}
      rowKey="propertyName"
    />
  )
}
