import { Input, Modal } from "antd";
import React from "react";

const ChannelManagerCreateNewAccountModal = ({
  isModalOpen,
  setIsModalOpen,
}) => {
  return (
    <Modal
      title={
        <div className="text-bold text-themeGrey text-xl pb-5 border-b-2 border-creme">
          Create new Airbnb account
        </div>
      }
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      width={620}
    >
      <div className="mb-5">
        <div className="text-themeGrey font-bold text-md">First name</div>
        <Input className="rounded-none w-full" placeholder="First name" />
      </div>
      <div className="mb-5">
        <div className="text-themeGrey font-bold text-md">Last name</div>
        <Input className="rounded-none w-full" placeholder="Last name" />
      </div>
      <div className="mb-5">
        <div className="text-themeGrey font-bold text-md">Email name</div>
        <Input className="rounded-none w-full" placeholder="Email name" />
      </div>
      <div className="mb-5">
        <div className="text-themeGrey font-bold text-md">Birthdate</div>
        <Input className="rounded-none w-full" placeholder="Birthdate" />
      </div>
    </Modal>
  );
};

export default ChannelManagerCreateNewAccountModal;
