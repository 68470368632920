import { apiInstance } from "./API";

export const uploadFile = async (file,) => {
    try {
        const resultOfS3Url = await apiInstance.get(`/s3/s3Url`);
        const s3Url = resultOfS3Url.data.url;
        const result = await fetch(s3Url, {
            method: "PUT",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            body: file,
        });
        const uploadedFileUrl = result.url.split("?")[0];
        return uploadedFileUrl
    } catch (e) {
        return e
    }
}