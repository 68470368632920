import { useState } from "react";
import { Button, Input, Switch } from "antd";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

function AddChecklistTemplate({ setShowChecklistTemplate }) {
  const [checkList, setChecklist] = useState([]);
  const [activeTask, setActiveTask] = useState(false);

  const { TextArea } = Input;

  return (
    <div className="bg-white w-full h-full">
      <Button
        className="text-buttonTheme font-semibold mt-5 hover:!bg-transparent hover:!text-buttonHoverTheme"
        type="text"
        onClick={() => setShowChecklistTemplate(false)}
        icon={<KeyboardArrowLeftIcon className="mb-1" />}
      >
        Back to checklist templates
      </Button>
      <div className="flex justify-between p-6 w-full flex-wrap gap-2">
        <Input
          placeholder="Task Title"
          className="w-[250px] h-[40px] max-[640px]:w-[200px]"
        />
        <Button
          type="primary"
          className="bg-buttonTheme font-semibold hover:!bg-buttonHoverTheme"
        >
          Save checklist template
        </Button>
      </div>
      <div className="flex flex-wrap">
        <div className="flex flex-col p-6 w-full sm:w-[65%] max-[640px]:justify-center">
          <p className="font-bold text-xl mt-5">Details</p>
          <p className="mt-5">Description</p>
          <TextArea rows={2} />
          <p className="font-bold text-xl mt-5">Checklist</p>
          <div className="grid grid-cols-1 w-full h-auto bg-themeWhite mt-5 p-3">
            {checkList.map((item, index) => (
              <div key={index} className="w-full">
                {item}
              </div>
            ))}
            <Button
              type="primary"
              className="bg-buttonTheme justify-self-end w-20 font-semibold m-2 hover:!bg-buttonHoverTheme"
              onClick={() =>
                setChecklist((prev) => [
                  ...prev,
                  // eslint-disable-next-line react/jsx-key
                  <Input className="w-full mt-2" />,
                ])
              }
            >
              Add
            </Button>
          </div>
        </div>
        <div className="w-full p-6 bg-themeWhite sm:w-[35%]">
          <p className="font-bold text-xl">Active</p>
          <div className="flex">
            <Switch
              className="w-10 mt-3"
              onChange={() => setActiveTask(!activeTask)}
            />
            <p className="mt-3 ml-2">{activeTask ? "Yes" : "No"}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddChecklistTemplate;
