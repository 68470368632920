import { DatePicker, Select } from "antd";
import { formatDate } from "../../../utils/dateHelper";
import dayjs from "dayjs";
import GenericButton from "../../GenericButton/GenericButton";
import CustomInput from "../../Input/Input";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { apiInstance, routes } from "../../../utils/API";
import { useState } from "react";
import { useContext } from "react";
import { AppContext } from "../../../AppContext";
import { manageToast } from "../../Common/ManageToast";

export default function TopBar({
  date,
  setDate,
  getAvailabilities,
  count,
  tag,
  setTag,
  page,
  setPage,
  limit,
  setLimit,
  title,
}) {
  const [syncing, setSyncing] = useState(false);
  const profile = useContext(AppContext).profile || {};
  const isAdmin = profile.isAdmin;

  const syncData = async () => {
    try {
      setSyncing(true);
      manageToast(
        true,
        "Sync is started. It will take some time. You can still continue browsing."
      );
      const res = await apiInstance.post(routes.SYNC_RESERVATION_REPORT);
      if (res.data.status === 200) {
      } else {
      }
      setTimeout(() => {
        window.location.reload();
      }, 300000);
    } catch (error) {
      if (error) {
        setTimeout(() => {
          manageToast(false, "Server Error. Someting went wrong");
          setSyncing(false);
        }, 300010);
      }
    }
  };

  return (
    <div className="mb-4">
      <div className="flex flex-wrap md:flex-nowrap gap-2 md:gap-0 justify-between items-center mb-4">
        <div className="text-themeGrey text-xl font-bold">
          {title} {count > 0 && <span className="text-base">({count})</span>}
        </div>
        <div className="flex gap-x-0 md:gap-x-3">
          {isAdmin && (
            <GenericButton
              isLoading={syncing}
              label="Sync"
              loadingClass="text-white"
              disabled={syncing}
              className="disabled:hover:!bg-buttonTheme disabled:hover:text-white disabled:!text-white disabled:bg-buttonTheme  hover:!bg-buttonHoverTheme hover:text-white bg-buttonTheme mr-5 shadow-0"
              icon={<LocalOfferIcon style={{ fontSize: "14px" }} />}
              onClick={syncData}
            />
          )}
          <Select
            value={limit}
            options={[
              { value: 20, label: 20 },
              { value: 50, label: 50 },
              { value: 100, label: 100 },
            ]}
            className="w-max"
            onChange={(l) => {
              setLimit(l);
              setPage(1);
            }}
          />
        </div>
      </div>
      <div className="flex w-full gap-x-6 justify-between">
        <div className="mob-w-100">
          <div className="text-sm text-buttonTheme font-medium flex items-center gap-x-1 mb-1 mr-5 md:mr-0">
            Search
          </div>
          <div className="">
            <CustomInput
              placeholder="Search"
              className="custom-input"
              value={tag}
              onChange={(e) => {
                setTag(e.target.value);
                setPage(1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
