import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";
import { List, Dropdown, Button, Table } from "antd";

function ExpensesMenu() {
  const [isExpanded, setIsExpanded] = useState(true);

  const listItems = [];
  const items = [
    { key: "1", label: "First" },
    { key: "2", label: "Second" },
    { key: "3", label: "Third" },
  ];

  const columns = [
    {
      title: <div className="text-themeGrey font-semibold">Concept</div>,
      dataIndex: "concept",
      key: "concept",
      width: "10%",
    },
    {
      title: <div className="text-themeGrey font-semibold">Date</div>,
      dataIndex: "date",
      key: "date",
    },
    {
      title: <div className="text-themeGrey font-semibold">Categories</div>,
      dataIndex: "category",
      key: "category",
    },
    {
      title: <div className="text-themeGrey font-semibold">Listing</div>,
      dataIndex: "listing",
      key: "listing",
      width: "25%",
    },
    {
      title: <div className="text-themeGrey font-semibold">Reservation</div>,
      dataIndex: "reservation",
      key: "reservation",
    },
    {
      title: <div className="text-themeGrey font-semibold">Owner</div>,
      dataIndex: "owner",
      key: "owner",
    },
    {
      title: <div className="text-themeGrey font-semibold">Amount</div>,
      dataIndex: "amount",
      key: "amount",
    },
  ];

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const ListHeader = () => {
    return (
      <div className="">
        <div className="flex justify-end">
          <Dropdown
            className="ml-28 mt-2"
            menu={{
              items,
              selectable: true,
              multiple: true,
            }}
            placement="bottom"
            trigger={["click"]}
          >
            <Button
              className="text-themeGrey"
              type="text"
              icon={<SettingsIcon />}
            />
          </Dropdown>
        </div>
      </div>
    );
  };

  return (
    <div className="mt-8">
      <div className="flex justify-between">
        <h1 className="text-xl font-semibold">Expenses and Extras</h1>
        {isExpanded ? (
          <KeyboardArrowDownIcon
            className="cursor-pointer"
            onClick={handleClick}
          />
        ) : (
          <KeyboardArrowLeftIcon
            className="cursor-pointer"
            onClick={handleClick}
          />
        )}
      </div>
      {isExpanded && (
        <>
          <div>
            <Table
              className="border-t border-creme"
              columns={columns}
              scroll={{
                x: 1000,
              }}
            />
          </div>
          <div className="flex items-center gap-4 sm:gap-8 md:gap-12">
            <p className="mt-3 font-semibold text-xl">Total</p>
            <p className="mt-3 col-span-1"> </p>
            <p className="mt-3 col-span-1"> </p>
            <p className="mt-3 col-span-1"> </p>
            <p className="mt-3 col-span-1"> </p>
            <p className="mt-3 col-span-1"> </p>
            <p className="mt-3 font-bold col-span-1">$0.00</p>
          </div>
          <div className="flex justify-center ml-14">
            <Button
              className="text-buttonTheme bg-labelGreen font-semibold hover:!bg-buttonHoverTheme"
              type="primary"
              icon={<AddIcon />}
            >
              Add
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default ExpensesMenu;
