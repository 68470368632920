import { Tabs } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  FINANCIAL_REPORTING_ANALYTICS,
  FINANCIAL_REPORTING_EXPENSES_AND_EXTRAS,
  FINANCIAL_REPORTING_LISTING_FINANCIALS,
  FINANCIAL_REPORTING_OCCUPANCY_REPORTS,
} from "../../constants/dashboardRoutes";
import FinancialReportingAnalytics from "../FinancialReportingAnalytics/FinancialReportingAnalytics";
import FinancialReportingListings from "../FinancialReportingListings/FinancialReportingListings";
import FinancialReportingOccupancyReport from "../FinancialReportingOccupancyReport/FinancialReportingOccupancyReport";
import FinancialReportingExpensesAndExtras from "../FinancialReportingExpensesAndExtras/FinancialReportingExpensesAndExtras";

const FinancialReporting = ({ defaultActiveKey }) => {
  const navigate = useNavigate();

  const items = [
    {
      key: "analytics",
      label: (
        <div
          className={`${
            defaultActiveKey === "analytics"
              ? "text-themeGreen"
              : "text-themeGrey"
          } font-bold flex items-center gap-3`}
          onClick={() => {
            navigate(FINANCIAL_REPORTING_ANALYTICS);
          }}
        >
          Analytics
        </div>
      ),
      children: <FinancialReportingAnalytics />,
    },
    {
      key: "listings",
      label: (
        <div
          className={`${
            defaultActiveKey === "listings"
              ? "text-themeGreen"
              : "text-themeGrey"
          } font-bold flex items-center gap-3`}
          onClick={() => {
            navigate(FINANCIAL_REPORTING_LISTING_FINANCIALS);
          }}
        >
          Listings Financials
        </div>
      ),
      children: <FinancialReportingListings />,
    },
    {
      key: "occupancy",
      label: (
        <div
          className={`${
            defaultActiveKey === "occupancy"
              ? "text-themeGreen"
              : "text-themeGrey"
          } font-bold flex items-center gap-3`}
          onClick={() => {
            navigate(FINANCIAL_REPORTING_OCCUPANCY_REPORTS);
          }}
        >
          Occupancy Reports
        </div>
      ),
      children: <FinancialReportingOccupancyReport />,
    },
    {
      key: "expenses-and-extras",
      label: (
        <div
          className={`${
            defaultActiveKey === "expenses-and-extras"
              ? "text-themeGreen"
              : "text-themeGrey"
          } font-bold flex items-center gap-3`}
          onClick={() => {
            navigate(FINANCIAL_REPORTING_EXPENSES_AND_EXTRAS);
          }}
        >
          Expenses & Extras
        </div>
      ),
      children: <FinancialReportingExpensesAndExtras />,
    },
  ];

  return (
    <div className="mx-5 my-6 bg-white pb-10 px-5">
      <div className="">
        <div className="font-semibold text-2xl mt-2">Financial Reporting</div>
        <Tabs activeKey={defaultActiveKey} items={items} />
      </div>
    </div>
  );
};

export default FinancialReporting;
