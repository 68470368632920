import React from "react";
import { Checkbox, Modal } from "antd";
import screenIcon from "../../assets/screenIcon.svg";
import filesIcon from "../../assets/filesIcon.svg";
import personHouseIcon from "../../assets/personHouseIcon.svg";
import calendarIcon from "../../assets/calendarIcon.svg";
import plugIcon from "../../assets/plugIcon.svg";
import moneyIcon from "../../assets/moneyIcon.svg";
import { Link } from "react-router-dom";
import GenericButton from "../GenericButton/GenericButton";

const ChannelManagerConnectExistingAccountModal = ({
  isModalOpen,
  setIsModalOpen,
}) => {
  return (
    <Modal
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      width={620}
      cancelText="Close"
      o
      okText={
        <GenericButton
          className="text-white bg-red-400 "
          label="Connect with Airbnb"
        />
      }
    >
      <div className="text-bold text-themeGrey text-sm pb-5 border-b-2 border-creme">
        <div className="flex justify-center font-extrabold mb-5">
          Please confirm the next statements to continue
        </div>
        <div>
          The following Flexliving terms of services apply to the use of the
          Flexliving software including all channel connections and 3rd party
          connections
        </div>
        <div className="grid grid-cols-12 gap-5 my-5">
          <div className="flex items-start justify-center col-span-2">
            <img src={screenIcon} />
          </div>
          <ul className="col-span-10">
            <li>
              Once Airbnb is connected, Flexliving is the Master Calendar for
              the property.
            </li>
            <li>
              All changes to availability and day rates can only be done by the
              Flexliving software.
            </li>
            <li>
              When importing a listing, initial availability and rates will be
              imported from Airbnb.
            </li>
          </ul>
        </div>
        <div className="grid grid-cols-12 gap-5 my-5">
          <div className="flex items-start justify-center col-span-2">
            <img src={filesIcon} />
          </div>
          <ul className="col-span-10">
            <li>
              After importing my listings, I will check that the Flexliving
              calendar matches my Airbnb calendar.
            </li>
          </ul>
        </div>
        <div className="grid grid-cols-12 gap-5 my-5">
          <div className="flex items-start justify-center col-span-2">
            <img src={personHouseIcon} />
          </div>
          <ul className="col-span-10">
            <li>
              Only fully owned listings can be imported. Co-hosted listings will
              not show in the import menu.
            </li>
          </ul>
        </div>
        <div className="grid grid-cols-12 gap-5 my-5">
          <div className="flex items-start justify-center col-span-2">
            <img src={calendarIcon} />
          </div>
          <ul className="col-span-10">
            <li>
              The Airbnb connection allows a maximum of 2 years of future
              updates to be set and synchronized.
            </li>
            <li>
              When I am using a booking window in Airbnb, this will be copied
              and applied in Flexliving with a maximum of 12 months. All future
              days blocked by default is not supported by the API.
            </li>
          </ul>
        </div>
        <div className="grid grid-cols-12 gap-5 my-5">
          <div className="flex items-start justify-center col-span-2">
            <img src={plugIcon} />
          </div>
          <ul className="col-span-10">
            <li>
              Internally in Airbnb linked listings will unlink during
              connection. I can use the Flexliving Cross Listings feature to
              link them in Flexliving after import.
            </li>
            <li>
              After connection, I will verify all settings, imported calendars,
              reservations and messages to ensure my data is correct.
            </li>
            <li>
              I am aware that disconnecting and/or remapping a listing in the
              future may remove some of my listing and reservation data from the
              Flexliving software.
            </li>
            <li>
              Airbnb Smart Pricing is not supported by Airbnb API. Please ensure
              you turn off Smart Pricing in Airbnb dashboard prior to
              connection.
            </li>
          </ul>
        </div>
        <div className="grid grid-cols-12 gap-5 my-5">
          <div className="flex items-start justify-center col-span-2">
            <img src={filesIcon} />
          </div>
          <ul className="col-span-10">
            <li>
              Once Airbnb is connected, Flexliving is the Master Calendar for
              the property.
            </li>
          </ul>
        </div>
        <div className="grid grid-cols-12 gap-5 my-5">
          <div className="flex items-start justify-center col-span-2">
            <img src={moneyIcon} />
          </div>
          <ul className="col-span-10">
            <li>
              Airbnb recommends the hosts to use the Host full commission fee
              model in the Airbnb accounts. After connection my account will be
              switched to this model. If I do not wish to be on this commission
              model, I will change this setting back in Airbnb under Account -
              payment - service fee settings.
            </li>
          </ul>
        </div>
        <Checkbox className="font-extrabold text-themeGrey">
          I agree to Flexliving{" "}
          <Link className="text-themeGreen">terms & Conditions.</Link>
        </Checkbox>
      </div>
    </Modal>
  );
};

export default ChannelManagerConnectExistingAccountModal;
