import React from 'react'
import { Descriptions } from 'antd'

import { numberWithCommas } from '../../../utils/helper'

export default function RoiCalculatorResultTable (props) {
  const { items } = props

  return (
    <div className="rounded-lg shadow-themeGreenCard overflow-hidden pt-2">
      {items.map((item, i) => {
        return (
          <div key={item.title}>
            <div className={`text-center font-bold border-y-[2px] border-black 
              ${i !== 0 ? 'mt-5' : ''}`}>
              {item.title}
            </div>

            <Descriptions
              column={1}
              className={`[&_.ant-descriptions-view]:!border-0 !w-full 
                [&_.ant-descriptions-row]:!border-0`}
              size="small"
              bordered
            >
              {item.items.map((innerItem, index) => {
                let value = innerItem.children
                  ? numberWithCommas(innerItem.children)
                  : '-'

                if (innerItem.hasPercentSign) {
                  value = innerItem.children
                    ? Math.round(Number(innerItem.children))
                    : 0
                }

                return (
                  <Descriptions.Item
                    labelStyle={{
                      ...innerItem?.labelStyles,
                      textAlign: 'center',
                      backgroundColor: innerItem.bgColor || '#ffffff',
                      borderRight: 'none',
                      fontWeight: 'bold',
                      fontSize: '12px',
                      width: 210,
                      padding: 1
                    }}
                    contentStyle={{
                      ...innerItem?.contentStyles,
                      backgroundColor: innerItem.bgColor || '#ffffff',
                      fontSize: '12px',
                      padding: '1px 10px'
                    }}
                    label={innerItem.label}
                    key={index}
                  >
                    <div className="flex justify-between items-center">
                      <span>{innerItem.hasCashSign ? '£ ' : ''}</span>

                      <span>
                        {`${value}${innerItem.hasPercentSign ? '%' : ''}`}
                      </span>
                    </div>
                  </Descriptions.Item>
                )
              })}
            </Descriptions>
          </div>
        )
      })}
    </div>
  )
}