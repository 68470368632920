import { Close } from "@mui/icons-material";
import { Upload } from "antd"

export default function UploadMedia({ imageUrl, imageFile, setImageFile, setImageUrl }) {
    const handleFileSelect = async (uploadedFile) => {
        if (uploadedFile && uploadedFile.file)
            setImageFile(uploadedFile.file)
    };
    return <div className="relative flex items-center justify-center h-28 md:h-36 md:w-96 w-full mx-auto my-1">
        {(imageUrl || imageFile) && <span className="absolute right-2 z-10 cursor-pointer top-0" onClick={() => {
            setImageFile(null)
            setImageUrl(null)
        }}><Close /></span>}
        <Upload
            customRequest={handleFileSelect}
            fileList={[]}
            showUploadList={false}
            multiple={false}
            className="w-full h-full flex items-center justify-center border-2 border-themeGrey border-dotted"
            accept="image/png, image/jpeg"
        >
            {(imageFile || imageUrl) ? (
                <img
                    src={imageFile ? URL.createObjectURL(imageFile) : imageUrl}
                    className="w-[100%] max-h-[9rem] max-w-[24rem] object-contain"
                    alt="avatar"
                    style={{
                        width: "100%",
                    }}
                />
            ) : (
                "Drag photo here or click"
            )}
        </Upload>
    </div>
}