import { useState } from "react";
import { apiInstance } from "../../utils/API";
import { manageToast } from "../Common/ManageToast";
import Comment from "./Comment";
import { Select, Tooltip } from "antd";
import GenericButton from "../GenericButton/GenericButton";
import { CommentOutlined, EditOutlined } from "@ant-design/icons";

export default function Actions({ record, onSave, setInfoModal }) {
  const [sending, setSending] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const updateStatus = async (id, status) => {
    try {
      setSending(true);
      const data = {
        status,
        pastStatus: record.status,
        listingName: record.allocatedProperty,
      };
      const res = await apiInstance.put(
        `/api/cleaning/${id}/status?userName=${localStorage.getItem(
          "userName"
        )}`,
        { ...data }
      );
      if (res.status === 201) {
        manageToast(true, "Status updated Successfully");
        onSave();
      }
      setSending(false);
    } catch (error) {
      manageToast(
        false,
        error?.response?.data?.message || "Something went wrong"
      );
      console.log(error);
      setSending(false);
    }
  };

  return (
    <div className="flex gap-x-1  items-center justify-end">
      <Comment
        id={record._id}
        listingName={record.allocatedProperty}
        oldComment={record?.comment}
        onSave={onSave}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <Select
        className="w-full"
        status="blue"
        rootClassName="border-red-500"
        disabled={sending}
        placeholder="Status"
        options={[
          { label: "Pending", value: "pending", color: "red" },
          { label: "Stuck", value: "stuck", color: "red" },
          { label: "In Progress", value: "inProgress", color: "orange" },
          { label: "Complaint", value: "complaint", color: "orange" },
          { label: "Completed", value: "completed", color: "green" },
          { label: "Not Required", value: "notRequired", color: "black" },
        ].map((e, i) => {
          return {
            label: (
              <div style={{ color: e.color }} className="font-semibold">
                {e.label}
              </div>
            ),
            value: e.value,
          };
        })}
        onChange={(e) => {
          updateStatus(record._id, e);
        }}
        value={record.status}
      />
      <Tooltip title="Comment" placement="left">
        <div>
          <GenericButton
            icon={<CommentOutlined />}
            className="capitalize border-buttonTheme  text-buttonTheme rounded-full h-7 w-4 flex justify-center items-center"
            onClick={() => {
              setIsModalOpen(true);
            }}
          />
        </div>
      </Tooltip>

      {/* <Tooltip title={record?.comment && "View Comment"} placement="left">
        <div>
          <GenericButton
            disabled={!record?.comment}
            icon={<CommentOutlined />}
            className="capitalize border-buttonTheme  text-buttonTheme rounded-full h-7 w-4 flex justify-center items-center"
            onClick={() => {
              setInfoModal(record);
            }}
          />
        </div>
      </Tooltip> */}
    </div>
  );
}
