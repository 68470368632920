import { Input, Modal } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const ConnectConfirmModal = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <Modal
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      width={620}
      className="text-themeGrey"
    >
      <div className="text-themeGrey">
        The following Flexliving terms of services apply to the use of the
        Flexliving software including all channel connections and 3rd party
        connections
      </div>
      <ol style={{ listStyleType: "decimal" }} className="px-10 mt-5">
        <li>
          By clicking on the confirm button you can connect to your existing
          Stripe account or set up a new Stripe account. Only fully confirmed
          Stripe accounts should be connected to Flexliving.
        </li>
        <li>
          Reservations made before a Stripe account was connected to Flexliving
          require guests to enter their CC details again by the Flexliving Guest
          portal in order to process payments.
        </li>
        <li>
          One unique Stripe account can be connected to your main Flexliving
          account under the settings menu. Individual other unique Stripe
          accounts can be connected under Listing - edit - payment accounts when
          required.
        </li>
      </ol>
      <div className="my-2">
        For more information visit our{" "}
        <Link className="text-themeGreen">
          Stripe Connect support guide page.
        </Link>
      </div>
      <div className="my-2">
        By clicking on Confirm you acknowledge the above points and agree to{" "}
        <Link className="text-themeGreen">Flexliving Terms & Conditions.</Link>
      </div>
    </Modal>
  );
};

export default ConnectConfirmModal;
