import React from 'react'
import { Button, Modal } from 'antd'
import DeleteIcon from '@mui/icons-material/Delete'

import { manageToast } from '../../../components/Common/ManageToast'
import { serverlessApi } from '../../../utils/API'

function LockBoxDeleteModal (props) {
  const { open, onClose, triggerLockBoxCollectionRefetch, lockBox } = props

  const [loading, setLoading] = React.useState(false)

  const handleLockBoxRemove = () => {
    if (!lockBox) {
      return
    }

    return Promise.resolve()
      .then(() => setLoading(true))
      .then(() => {
        return serverlessApi.deleteLockBox(lockBox._id).then(() => {
          triggerLockBoxCollectionRefetch()
          manageToast(true, `LockBox "${lockBox.LockBoxName}" 
          deleted successfully`)
          setLoading(false)
          onClose()
        })
      })
      .catch(() => {
        manageToast(false, 'Error deleting lockBox')
        setLoading(false)
      })
  }

  return (
    <Modal
      title='Remove lockbox'
      open={open}
      footer={null}
      onCancel={onClose}
    >
      Are you sure you want to remove lockBox "{lockBox?.LockBoxName}" ?
      <div className="flex gap-2 justify-end pt-7">
        <Button
          className="hover:!text-black hover:!border-labelGrey"
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          className="inline-flex items-center"
          onClick={handleLockBoxRemove}
          loading={loading}
          icon={<DeleteIcon className="!w-[20px]" />}
          type="primary"
          danger
        >
          Remove lockBox
        </Button>
      </div>
    </Modal>
  )
}

export default LockBoxDeleteModal
