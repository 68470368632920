import React from "react";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { Table } from "antd";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import MoneyIcon from "@mui/icons-material/Money";
import PrintIcon from "@mui/icons-material/Print";
import { Link } from "react-router-dom";

const DashboardSettingsInvoices = () => {
  const handleRowClick = (record) => {};

  const columns = [
    {
      title: (
        <div className="text-themeGrey font-normal text-sm">Charge amount</div>
      ),
      dataIndex: "chargeAmount",
      key: "chargeAmount",
      width: "25%",
    },
    {
      title: (
        <div className="text-themeGrey font-normal text-sm">Desciption</div>
      ),
      dataIndex: "description",
      key: "description",
      width: "20%",
    },
    {
      title: <div className="text-themeGrey font-normal text-sm">Date</div>,
      dataIndex: "date",
      key: "date",
      width: "7%",
    },

    {
      title: <div className="text-themeGrey">View</div>,
      key: "action",
      align: "right",
      render: (_, record) => {
        return (
          <Link className="text-themeGreen">
            <RemoveRedEyeIcon className="mr-1" style={{ fontSize: 15 }} />
            View
          </Link>
        );
      },
    },

    {
      title: <div className="text-themeGrey">Print</div>,
      key: "action",
      align: "right",
      render: (_, record) => {
        return (
          <Link className="text-themeGreen">
            <PrintIcon className="mr-1" style={{ fontSize: 15 }} />
            Print
          </Link>
        );
      },
    },
  ];

  const data = [
    {
      key: "1",
      chargeAmount: "0€",
      description: "Invoice",
      date: "2023-06-28",
    },
    {
      key: "2",
      chargeAmount: "0€",
      description: "Invoice",
      date: "2023-06-28",
    },
    {
      key: "3",
      chargeAmount: "0€",
      description: "Invoice",
      date: "2023-06-28",
    },
    {
      key: "4",
      chargeAmount: "0€",
      description: "Invoice",
      date: "2023-06-28",
    },
  ];

  const chargeInvoiceColumns = [
    {
      title: (
        <div className="text-themeGrey font-normal text-sm">Charge amount</div>
      ),
      dataIndex: "chargeAmount",
      key: "chargeAmount",
    },
    {
      title: (
        <div className="text-themeGrey font-normal text-sm">Desciption</div>
      ),
      dataIndex: "description",
      key: "description",
    },
    {
      title: <div className="text-themeGrey font-normal text-sm">Date</div>,
      dataIndex: "date",
      key: "date",
    },

    {
      title: <div className="text-themeGrey font-normal text-sm">Status</div>,
      dataIndex: "date",
      key: "date",
    },
    {
      title: <div className="text-themeGrey font-normal text-sm">Invoice</div>,
      dataIndex: "date",
      key: "date",
    },
  ];

  return (
    <div>
      <div className="text-themeGrey text-2xl font-bold flex items-center gap-2 mb-5">
        <CreditCardIcon style={{ fontSize: 24 }} /> <p>Invoices</p>
      </div>
      <div>
        {/* Invoice table */}
        <Table
          className="border-t border-creme mb-5"
          columns={columns}
          dataSource={data}
          scroll={{
            x: 1000,
            y: 500,
          }}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
            className: "cursor-pointer",
          })}
        />
        <div className="text-themeGrey text-2xl font-bold flex items-center gap-2 mb-5">
          <MoneyIcon style={{ fontSize: 24 }} /> <p>Charges</p>
        </div>
        {/* Charges table */}
        <Table
          className="border-t border-creme mb-10"
          columns={chargeInvoiceColumns}
          scroll={{
            x: 1000,
            y: 500,
          }}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
            className: "cursor-pointer",
          })}
        />
      </div>
    </div>
  );
};

export default DashboardSettingsInvoices;
