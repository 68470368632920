/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Table } from 'antd'

import Loading from '../../../components/Loaders/Loading'
import { paginationItemRender } from '../../../utils/table'

import { createKeysCollectionColumns, fetchCollection } from '../utils'

function KeyNestSection (props) {
  const {
    city,
    keyType,
    pageSize,
    searchKey,
    setPageSize
  } = props

  const [loading, setLoading] = React.useState(true)
  const [page, setPage] = React.useState(1)
  const [total, setTotal] = React.useState(0)
  const [data, setData] = React.useState([])

  const fetchKeysCollection = signal => {
    fetchCollection(
      { searchKey, keyType, city, page, pageSize },
      signal,
      setData,
      setTotal,
      setLoading,
      'getKeysCollection'
    )
  }

  React.useEffect(() => {
    const controller = new AbortController()

    fetchKeysCollection(controller.signal)

    return () => {
      controller.abort()
    }
  }, [searchKey, keyType, city, page, pageSize])

  return (
    <div className="pt-7">
      {loading
        ? <Loading />
        : (
          <div>
            <Table
              loading={loading}
              dataSource={data}
              columns={createKeysCollectionColumns()}
              scroll={{ x: 900 }}
              sticky={{ offsetHeader: 45 }}
              pagination={{
                current: page,
                pageSize,
                total,
                showSizeChanger: true,
                itemRender: paginationItemRender,
                onChange: (nextPage, nextLimit) => {
                  if (nextPage !== page) {
                    setPage(nextPage)
                  }

                  if (nextLimit !== pageSize) {
                    setPage(1)
                    setPageSize(nextLimit)
                  }
                }
              }}
              rowKey="_id"
            />
          </div>
        )}
    </div>
  )
}

export default KeyNestSection
