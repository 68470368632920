import { Avatar, Checkbox } from "antd";
import React from "react";

const PluginCard = ({ imageUrl, cardText }) => {
  return (
    <div className="h-20 bg-white grid grid-cols-6">
      <Checkbox className="col-span-1 flex justify-center" />
      <div className="col-span-1 flex items-center justify-start">
        <Avatar size={"large"} src={imageUrl} />
      </div>
      <div className="col-span-4 flex items-center text-themeGrey ">
        {cardText}
      </div>
    </div>
  );
};

export default PluginCard;
