import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./UserManagement.css";
import Users from "./Users/UserTable";
import { Tabs } from "antd";
import {
  USER_MANAGEMENT_GROUPS,
  USER_MANAGEMENT_USERS,
} from "../../constants/dashboardRoutes";
import LockIcon from "@mui/icons-material/Lock";
import GroupsIcon from "@mui/icons-material/Groups";
import Groups from "./Groups/GroupTable";
import { AppContext } from "../../AppContext";
import NoAccess from "../../components/NoAccess";

const UserManagement = ({ defaultActiveKey }) => {
  const navigate = useNavigate();
  const profile = useContext(AppContext).profile
  const hasAccess = profile.isAdmin

  if (profile?._id && !hasAccess) {
    return <NoAccess />
  }
  const items = [
    {
      key: "users",
      label: (
        <div
          className="text-themeGreen font-bold flex items-center gap-3"
          onClick={() => {
            navigate(USER_MANAGEMENT_USERS);
          }}
        >
          <LockIcon style={{ fontSize: 12 }} /> Users
        </div>
      ),
      children: <Users />,
    },
    {
      key: "groups",
      label: (
        <div
          className="text-themeGreen font-bold flex items-center gap-3"
          onClick={() => {
            navigate(USER_MANAGEMENT_GROUPS);
          }}
        >
          <GroupsIcon style={{ fontSize: 12 }} /> Groups
        </div>
      ),
      children: <Groups />,
    },
  ];

  return (<div className="p-5 bg-white px-5" id="custom-tabs">
    <Tabs activeKey={defaultActiveKey} items={items} centered />
  </div>
  );
};

export default UserManagement;
