export default function NoAccess() {
    return <div className="text-center flex flex-col justify-center h-[90vh] items-center">
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 48 48"><g fill="currentColor"><path d="M22.707 20.293a1 1 0 0 0-1.414 1.414L24.586 25l-3.293 3.293a1 1 0 0 0 1.414 1.414L26 26.414l3.293 3.293a1 1 0 0 0 1.414-1.414L27.414 25l3.293-3.293a1 1 0 0 0-1.414-1.414L26 23.586l-3.293-3.293Z" /><path fillRule="evenodd" d="M38 15v21a3 3 0 0 1-3 3H17a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3h11l10 10Zm-10 1a1 1 0 0 1-1-1V7H17a1 1 0 0 0-1 1v28a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V16h-8Zm1-7.172L34.172 14H29V8.828Z" clipRule="evenodd" /><path d="M12 11v27a3 3 0 0 0 3 3h19v2H15a5 5 0 0 1-5-5V11h2Z" /></g></svg>
        </span>
        <div>
            <p className="font-semibold text-sm mb-2">Access Restricted</p>
            <p className="text-sm text-themeGrey">You don‘t have the necessary permissions to access this page.<br />
                Please contact your account owner.
            </p>
        </div>
    </div>
}