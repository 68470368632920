import { Input, Switch } from "antd";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";

const AutoReview = () => {
  return (
    <div className="bg-white py-5">
      <div className="font-bold text-2xl mb-5">Auto-review names</div>
      <Input placeholder="Search by auto-review name" className="w-[30%]" />
      <div className=" border mt-10 grid grid-cols-5">
        <div className="col-span-3 p-5">
          <div className="font-bold text-xl">Rehan</div>
          <div className="text-themeGrey mb-2">
            Event: 2 days after checkout
          </div>
          <div className="flex gap-2">
            <div>Listings:</div>
            <div className="text-themeGreen">
              <p>2B BR1 - The Bromley Collection</p>
              <p> 3B SW15 - The Putney Apartment</p>
            </div>
          </div>
          <div className=" flex gap-2">
            Creation Date<p className="text-themeGrey">2023-07-19 16:59:01</p>
          </div>
        </div>
        <div className="col-span-2 border-l border-creme p-5 flex items-center justify-between">
          <div>
            <div>Active</div>
            <Switch />
          </div>
          <div className="flex gap-3">
            <EditIcon style={{ fontSize: 20 }} className="text-themeGrey" />
            <ContentCopyIcon
              style={{ fontSize: 20 }}
              className="text-themeGrey"
            />
            <DeleteIcon style={{ fontSize: 20 }} className="text-themeGrey" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutoReview;
