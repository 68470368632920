import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HOME, MONDAY_DOT_COM, NOT_FOUND } from "../constants/pageRoutes";
import NotFound from "../pages/NotFound";
import Dashboard from "../pages/Dashboard";
import Login from "../Login";
import MondayDashboard from "../monday/pages/MondayDashboard/MondayDashboard";

const AppRouter = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path={HOME} element={<Dashboard />} />
          {/* <Route path={MONDAY_DOT_COM} element={<MondayDashboard />} /> */}
          <Route path={NOT_FOUND} element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AppRouter;
