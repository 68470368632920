import React from "react";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import GenericButton from "../../components/GenericButton/GenericButton";
import { Checkbox, Table } from "antd";

const DashboardSettingsNotifications = () => {
  const notificationColums = [
    {
      title: (
        <div className="text-themeGrey font-bold text-md flex items-center gap-1">
          Reservations
        </div>
      ),
      dataIndex: "reservations",
      key: "reservations",
      width: "42%",
    },
    {
      title: (
        <div className="text-themeGrey font-bold text-md flex items-center gap-1">
          Dashboard
        </div>
      ),
      dataIndex: "dashboard",
      key: "dashboard",
    },
    {
      title: (
        <div className="text-themeGrey font-bold text-md flex items-center gap-1">
          Mobile App
        </div>
      ),
      dataIndex: "mobileApp",
      key: "mobileApp",
    },
    {
      title: (
        <div className="text-themeGrey font-bold text-md flex items-center gap-1">
          Email
        </div>
      ),
      dataIndex: "email",
      key: "email",
    },
  ];

  const notificationData = [
    {
      key: "1",
      reservations: "New Reservation",
      dashboard: <Checkbox />,
      mobileApp: <Checkbox />,
      email: <Checkbox />,
    },
    {
      key: "2",
      reservations: "New owner Stay",
      dashboard: <Checkbox />,
      mobileApp: <Checkbox />,
      email: <Checkbox />,
    },
    {
      key: "3",
      reservations: "Owner stay is modified",
      dashboard: <Checkbox />,
      mobileApp: <Checkbox />,
      email: <Checkbox />,
    },
    {
      key: "4",
      reservations: "Reservation is modified",
      dashboard: <Checkbox />,
      mobileApp: <Checkbox />,
      email: <Checkbox />,
    },
    {
      key: "5",
      reservations: "Reservation is cancelled",
      dashboard: <Checkbox />,
      mobileApp: <Checkbox />,
      email: <Checkbox />,
    },
    {
      key: "6",
      reservations: "New Inquiry",
      dashboard: <Checkbox />,
      mobileApp: <Checkbox />,
      email: <Checkbox />,
    },
    {
      key: "7",
      reservations: "Reservation is pending",
      dashboard: <Checkbox />,
      mobileApp: <Checkbox />,
      email: <Checkbox />,
    },
    {
      key: "8",
      reservations: "Airbnb alteration requests",
      dashboard: <Checkbox />,
      mobileApp: <Checkbox />,
      email: <Checkbox />,
    },
    {
      key: "9",
      reservations: "Rental agreement signed",
      dashboard: <Checkbox />,
      mobileApp: <Checkbox />,
      email: <Checkbox />,
    },
    {
      key: "10",
      reservations: <div className="font-bold text-themeGrey">Tasks</div>,
      dashboard: <div className="font-bold text-themeGrey">Dashboard</div>,
      mobileApp: <div className="font-bold text-themeGrey">Mobile App</div>,
      email: <div className="font-bold text-themeGrey">Email</div>,
    },
    {
      key: "11",
      reservations: "New task created",
      dashboard: <Checkbox />,
      mobileApp: <Checkbox />,
      email: <Checkbox />,
    },
    {
      key: "12",
      reservations: "Task updated",
      dashboard: <Checkbox />,
      mobileApp: <Checkbox />,
      email: <Checkbox />,
    },
    {
      key: "13",
      reservations: "Task started",
      dashboard: <Checkbox />,
      mobileApp: <Checkbox />,
      email: <Checkbox />,
    },
    {
      key: "14",
      reservations: "Task ended",
      dashboard: <Checkbox />,
      mobileApp: <Checkbox />,
      email: <Checkbox />,
    },
    {
      key: "15",
      reservations: "Task cancelled",
      dashboard: <Checkbox />,
      mobileApp: <Checkbox />,
      email: <Checkbox />,
    },
    {
      key: "16",
      reservations: <div className="font-bold text-themeGrey">Messages</div>,
      dashboard: <div className="font-bold text-themeGrey">Dashboard</div>,
      mobileApp: <div className="font-bold text-themeGrey">Mobile App</div>,
      email: <div className="font-bold text-themeGrey">Email</div>,
    },
    {
      key: "17",
      reservations: "New message received",
      dashboard: <Checkbox />,
      mobileApp: <Checkbox />,
      email: <Checkbox />,
    },
    {
      key: "18",
      reservations: "New automated message sent",
      dashboard: <Checkbox />,
      mobileApp: <Checkbox />,
      email: <Checkbox />,
    },
    {
      key: "19",
      reservations: "Thread assigned",
      dashboard: <Checkbox />,
      mobileApp: <Checkbox />,
      email: <Checkbox />,
    },
    {
      key: "20",
      reservations: "Thread unassigned",
      dashboard: <Checkbox />,
      mobileApp: <Checkbox />,
      email: <Checkbox />,
    },
    {
      key: "21",
      reservations: <div className="font-bold text-themeGrey">Payment</div>,
      dashboard: <div className="font-bold text-themeGrey">Dashboard</div>,
      mobileApp: <div className="font-bold text-themeGrey">Mobile App</div>,
      email: <div className="font-bold text-themeGrey">Email</div>,
    },
    {
      key: "22",
      reservations: "Pre-auth success",
      dashboard: <Checkbox />,
      mobileApp: <Checkbox />,
      email: <Checkbox />,
    },
    {
      key: "23",
      reservations: "Pre-auth failed",
      dashboard: <Checkbox />,
      mobileApp: <Checkbox />,
      email: <Checkbox />,
    },
    {
      key: "24",
      reservations: <div className="font-bold text-themeGrey">Listings</div>,
      dashboard: <div className="font-bold text-themeGrey">Dashboard</div>,
      mobileApp: <div className="font-bold text-themeGrey">Mobile App</div>,
      email: <div className="font-bold text-themeGrey">Email</div>,
    },
    {
      key: "25",
      reservations: "Import success",
      dashboard: <Checkbox />,
      mobileApp: <Checkbox />,
      email: <Checkbox />,
    },
    {
      key: "26",
      reservations: "Import failed",
      dashboard: <Checkbox />,
      mobileApp: <Checkbox />,
      email: <Checkbox />,
    },
    {
      key: "27",
      reservations: "Export success",
      dashboard: <Checkbox />,
      mobileApp: <Checkbox />,
      email: <Checkbox />,
    },
    {
      key: "28",
      reservations: "Export failed",
      dashboard: <Checkbox />,
      mobileApp: <Checkbox />,
      email: <Checkbox />,
    },
    {
      key: "29",
      reservations: <div className="font-bold text-themeGrey">Other</div>,
      dashboard: <div className="font-bold text-themeGrey">Dashboard</div>,
      mobileApp: <div className="font-bold text-themeGrey">Mobile App</div>,
      email: <div className="font-bold text-themeGrey">Email</div>,
    },
    {
      key: "30",
      reservations: "Error reports",
      dashboard: <Checkbox />,
      mobileApp: <Checkbox />,
      email: <Checkbox />,
    },
  ];
  return (
    <div>
      <div className="text-themeGrey text-2xl font-bold flex items-center justify-between gap-2 mb-5">
        <div className="flex items-center gap-3">
          <NotificationsActiveIcon style={{ fontSize: 24 }} />
          <p>Notifications</p>
        </div>
        <GenericButton
          label="Save"
          className="bg-themeGreen text-white font-bold rounded-none"
        />
      </div>
      <div className="flex justify-end mt-14">
        <GenericButton
          label="Select all"
          className="bg-themeGreen text-white font-bold rounded-none"
        />
      </div>
      <div className="my-10">
        <Table
          className="border-t border-creme mb-10"
          columns={notificationColums}
          dataSource={notificationData}
          pagination={false}
          scroll={{
            x: 1200,
          }}
        />
      </div>
    </div>
  );
};

export default DashboardSettingsNotifications;
