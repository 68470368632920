import React from "react";
import GenericButton from "../../GenericButton/GenericButton";
import { Checkbox, Tabs } from "antd";
import { Help } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  REVIEWS_AUTO_REVIEWS,
  REVIEWS_AUTO_REVIEWS_ADD,
  REVIEWS_MANAGE_REVIEWS,
  REVIEWS_REVIEW_TEMPLATES,
  REVIEWS_REVIEW_TEMPLATES_ADD,
} from "../../../constants/dashboardRoutes";
import ReviewMain from "../../../pages/ReviewsMain/ReviewMain";
import AutoReview from "../../../pages/AutoReviews/AutoReviews";
import ReviewTemplates from "../../../pages/ReviewTemplates/ReviewTemplates";

const Reviews = ({ activeKey }) => {
  const navigate = useNavigate();

  const items = [
    {
      key: "reviews",
      label: (
        <div
          className="text-themeGreen font-bold flex items-center gap-3"
          onClick={() => {
            navigate(REVIEWS_MANAGE_REVIEWS);
          }}
        >
          MANAGE REVIEWS
        </div>
      ),
      children: <ReviewMain />,
    },
    {
      key: "auto",
      label: (
        <div
          className="text-themeGreen font-bold flex items-center gap-3"
          onClick={() => {
            navigate(REVIEWS_AUTO_REVIEWS);
          }}
        >
          AUTO REVIEWS
        </div>
      ),
      children: <AutoReview />,
    },
    {
      key: "templates",
      label: (
        <div
          className="text-themeGreen font-bold flex items-center gap-3"
          onClick={() => {
            navigate(REVIEWS_REVIEW_TEMPLATES);
          }}
        >
          REVIEW TEMPLATES
        </div>
      ),
      children: <ReviewTemplates />,
    },
  ];

  return (
    <div className="bg-white px-3 py-5">
      <div className="flex items-center justify-between mb-5">
        <div className="text-3xl font-bold">Reviews</div>
        {activeKey === "reviews" && (
          <div className="flex items-center gap-3">
            <GenericButton
              label="Download Report"
              className="text-themeGreen bg-selectedGreen rounded-none"
            />
            <Checkbox>
              Apply current filter{" "}
              <Help className="text-labelBlue" style={{ fontSize: 16 }} />
            </Checkbox>
          </div>
        )}
        {activeKey === "auto" && (
          <div className="flex items-center gap-3">
            <GenericButton
              label="Add Auto Reviews"
              className="bg-themeGreen text-white rounded-none"
              onClick={() => {
                navigate(REVIEWS_AUTO_REVIEWS_ADD);
              }}
            />
          </div>
        )}
        {activeKey === "templates" && (
          <div className="flex items-center gap-3">
            <GenericButton
              label="Add review template"
              className="bg-themeGreen text-white rounded-none"
              onClick={() => {
                navigate(REVIEWS_REVIEW_TEMPLATES_ADD);
              }}
            />
          </div>
        )}
      </div>
      <div className="m-5 bg-white px-5" id="custom-tabs">
        <Tabs activeKey={activeKey} items={items} centered />
      </div>
    </div>
  );
};

export default Reviews;
