import axios from 'axios'
import { BASE_FINANCE_API_URL } from '../constants/serverUrl'
import { logOut } from './constants'

export const apiInstanceFinance = axios.create({
  baseURL: BASE_FINANCE_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('authorizationToken')}`,
  },
})

apiInstanceFinance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error?.response?.status === 401) {
      if (!error.request?.responseURL?.includes('auth/login')) {
        logOut()
        return (window.location.href = '/')
      }
    }
    return Promise.reject(error)
  },
)
export const routes_finance = {
  FINANCE_PROPERTY: '/finances-by-property',
  FINANCE_SUMMARY: '/summary',
  SUMMARY_IMPORT: '/summary/xlsx/update'
}
