import React from "react";
import { useNavigate } from "react-router-dom";
import {
  SETTINGS_ACCOUNT,
  SETTINGS_HOSTAWAY_API,
  SETTINGS_INTEGRATIONS,
  SETTINGS_INVOICES_AND_CHARGES,
  SETTINGS_NOTIFICATIONS,
} from "../../constants/dashboardRoutes";
import DashboardSettingsAccount from "../DashboardSettingsAccount/DashboardSettingsAccount";
import DashboardSettingsInvoices from "../DashboardSettingsInvoices/DashboardSettingsInvoices";
import DashboardSettingsIntegrations from "../DashboardSettingsIntegrations/DashboardSettingsIntegrations";
import DashboardSettingsHostawayAPI from "../DashboardSettingsHostawayAPI/DashboardSettingsHostawayAPI";
import DashboardSettingsNotifications from "../DashboardSettingsNotifications/DashboardSettingsNotifications";
import { Tabs } from "antd";
import PersonIcon from "@mui/icons-material/Person";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PowerIcon from "@mui/icons-material/Power";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

const DashboardSettings = ({ defaultActiveKey }) => {
  const navigate = useNavigate();
  const items = [
    {
      key: "account",
      label: (
        <div
          className="text-themeGrey flex items-center gap-3"
          onClick={() => {
            navigate(SETTINGS_ACCOUNT);
          }}
        >
          <PersonIcon style={{ fontSize: 18 }} /> Account
        </div>
      ),
      children: <DashboardSettingsAccount />,
    },
    {
      key: "invoices",
      label: (
        <div
          className="text-themeGrey flex items-center gap-3"
          onClick={() => {
            navigate(SETTINGS_INVOICES_AND_CHARGES);
          }}
        >
          <CreditCardIcon style={{ fontSize: 18 }} /> Invoices & Charges
        </div>
      ),
      children: <DashboardSettingsInvoices />,
    },
    {
      key: "integrations",
      label: (
        <div
          className="text-themeGrey flex items-center gap-3"
          onClick={() => {
            navigate(SETTINGS_INTEGRATIONS);
          }}
        >
          <PowerIcon style={{ fontSize: 18 }} /> Integrations
        </div>
      ),
      children: <DashboardSettingsIntegrations />,
    },
    {
      key: "hostaway-api",
      label: (
        <div
          className="text-themeGrey flex items-center gap-3"
          onClick={() => {
            navigate(SETTINGS_HOSTAWAY_API);
          }}
        >
          <CompareArrowsIcon style={{ fontSize: 18 }} /> Flexliving API
        </div>
      ),
      children: <DashboardSettingsHostawayAPI />,
    },
    {
      key: "notifications",
      label: (
        <div
          className="text-themeGrey flex items-center gap-3"
          onClick={() => {
            navigate(SETTINGS_NOTIFICATIONS);
          }}
        >
          <NotificationsActiveIcon style={{ fontSize: 18 }} /> Notifications
        </div>
      ),
      children: <DashboardSettingsNotifications />,
    },
  ];

  return (
    <div className="m-5 bg-white px-5" id="custom-tabs">
      <Tabs activeKey={defaultActiveKey} items={items} centered />
    </div>
  );
};

export default DashboardSettings;
