export const initialValues = {
    accessContactData: false,
    accessFinancialData: false,
    cannotSeeUsersOfOtherGroups: false,
    createAutomation: false,
    createBookingEngine: false,
    createBulkEditListings: false,
    createCalendar: false,
    createChannelManager: false,
    createExpense: false,
    createFinancialReporting: false,
    createGuestInvoicing: false,
    createGuestbook: false,
    createIntegration: false,
    createListing: false,
    createMessage: false,
    createOwnerStatement: false,
    createOwnerStay: false,
    createReservation: false,
    createReview: false,
    createTask: false,
    deleteAutomation: false,
    deleteBookingEngine: false,
    deleteBulkEditListings: false,
    deleteCalendar: false,
    deleteChannelManager: false,
    deleteExpense: false,
    deleteFinancialReporting: false,
    deleteGuestInvoicing: false,
    deleteGuestbook: false,
    deleteIntegration: false,
    deleteListing: false,
    deleteMessage: false,
    deleteOwnerStatement: false,
    deleteOwnerStay: false,
    deleteReservation: false,
    deleteReview: false,
    deleteTask: false,
    description: "",
    name: "",
    hasAccessToNewListings: false,
    permissionsTemplates: "",
    readAutomation: false,
    readBookingEngine: false,
    readBulkEditListings: false,
    readCalendar: false,
    readChannelManager: false,
    readExpense: false,
    readFinancialReporting: false,
    readGuestInvoicing: false,
    readGuestbook: false,
    readIntegration: false,
    readListing: false,
    readMessage: false,
    readOwnerStatement: false,
    readOwnerStay: false,
    readReservation: false,
    readReview: false,
    readTask: false,
    updateAutomation: false,
    updateBookingEngine: false,
    updateBulkEditListings: false,
    updateCalendar: false,
    updateChannelManager: false,
    updateExpense: false,
    updateFinancialReporting: false,
    updateGuestInvoicing: false,
    updateGuestbook: false,
    updateIntegration: false,
    updateListing: false,
    updateMessage: false,
    updateOwnerStatement: false,
    updateOwnerStay: false,
    updateReservation: false,
    updateReview: false,
    updateTask: false,
    readCleaning: false,
    readViews: false,
    updateCleaning: false
}