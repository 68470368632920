import React, { useRef, useState } from "react";
import { Table, Tooltip, Input, Select } from "antd";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import GenericButton from "../../components/GenericButton/GenericButton";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpIcon from "@mui/icons-material/Help";
import GenericModal from "../../components/GenericModal/GenericModal";
import { modalTypes } from "../../enum";

const TasksCustomFields = () => {
  const [dataSource, setDataSource] = useState([
    {
      key: "1",
      name: "Mike",
      type: "text",
      isPublic: "yes",
      variable: "{{listing_tester}}",
    },
    {
      key: "2",
      name: "John",
      type: "text",
      isPublic: "no",
      variable: "{{listing_tester}}",
    },
  ]);
  const [isDropDownSelected, setIsDropDownSelected] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [editableValues, setEditableValues] = useState({
    name: null,
    type: null,
    isPublic: null,
  });

  const navigate = useNavigate();
  const nameRef = useRef(null);
  const { DELETE_CUSTOM_FIELDS } = modalTypes;

  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i,
    });
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "isPublic",
      dataIndex: "isPublic",
      key: "isPublic",
    },
    {
      title: "Variable Name",
      dataIndex: "variable",
      key: "variable",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      align: "right",
      render: (_, record) => (
        <>
          <GenericButton
            label="Edit"
            icon={<EditNoteIcon />}
            iconLeftAlign={true}
            onClick={() => handleEdit(record)}
            className="bg-lightButtonTheme text-buttonTheme hover:!bg-lightButtonHoverTheme hover:!text-buttonTheme"
          />
          <GenericButton
            label="Delete"
            icon={<DeleteIcon />}
            iconLeftAlign={true}
            onClick={() => setIsModalOpen((prev) => !prev)}
            className="bg-lightCancelButton text-lightCancelButtonText hover:!bg-lightCancelHoverButton hover:!text-lightCancelButtonText ml-8"
          />
        </>
      ),
    },
  ];

  const selectedValues = {
    name: "",
    type: "",
    isPublic: "",
    variable: "{{listing_tester}}",
  };

  const handleSubmit = () => {
    const name = nameRef.current.input.value;
    selectedValues.name = name;
    setDataSource((prev) => [...prev, selectedValues]);
  };

  const handleEdit = (record) => {
    setIsEditable(true);
    setEditableValues({
      name: record.name,
      type: record.type,
      isPublic: record.isPublic,
    });
  };

  return (
    <div className="bg-white w-full h-[90vh] p-4">
      <GenericModal
        modalFor={DELETE_CUSTOM_FIELDS}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <div
        className="flex items-center cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <ArrowBackIosIcon className="!text-[14px] !font-semibold text-buttonTheme" />
        <p className="font-semibold text-buttonTheme ml-2">Tasks</p>
      </div>

      <div className="grid grid-cols-1 mt-3">
        <Table
          columns={columns}
          dataSource={dataSource}
          scroll={{
            x: 800,
          }}
        />
      </div>

      <p className="text-xl font-semibold text-slate-600">
        {isEditable ? "EDIT CUSTOM FIELD" : "ADD CUSTOM FIELD"}
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-2 xl:grid-cols-3 xl:gap-5 mt-3">
        <div>
          <div className="flex items-center">
            <p className="font-semibold text-slate-700">
              Custom field name <span className="text-red-600">*</span>
            </p>
            <Tooltip
              placement="right"
              title="Choose a name that describes the field, is short and memorable."
            >
              <HelpIcon className="text-sky-600 !text-[17px] ml-1" />
            </Tooltip>
          </div>
          <Input
            placeholder="Name"
            ref={nameRef}
            defaultValue={editableValues.name || ""}
            className="mt-1"
          />
        </div>
        <div className="mt-2 sm:mt-0">
          <p className="font-semibold text-slate-700">Custom field type</p>
          <Select
            defaultValue={editableValues.field || "text"}
            options={[
              {
                value: "text",
                label: "Text",
              },
              {
                value: "number",
                label: "Number",
              },
              {
                value: "textArea",
                label: "Textarea",
              },
              {
                value: "dropdown",
                label: "Dropdown",
              },
            ]}
            className="w-full mt-1"
            onChange={(value) => {
              selectedValues.type = value;
              value === "dropdown"
                ? setIsDropDownSelected(true)
                : setIsDropDownSelected(false);
            }}
          />
        </div>
        <div className="mt-2 xl:mt-0">
          <div className="flex">
            <p className="font-semibold text-slate-700">Is public</p>
            <Tooltip placement="right" title="This field is not in use yet.">
              <HelpIcon className="text-sky-600 !text-[17px] ml-1" />
            </Tooltip>
          </div>
          <Select
            defaultValue={editableValues.isPublic || "yes"}
            options={[
              {
                value: "yes",
                label: "Yes",
              },
              {
                value: "no",
                label: "No",
              },
            ]}
            className="w-full mt-1"
            onChange={(value) => (selectedValues.isPublic = value)}
          />
        </div>
        {isDropDownSelected && (
          <div className="mt-2">
            <p className="font-semibold text-slate-700">Dropdown options</p>
            <Select
              mode="multiple"
              allowClear
              placeholder="Try to add new options"
              options={options}
              className="w-full mt-1"
            />
          </div>
        )}
      </div>
      <div className="flex sm:justify-end">
        {isEditable ? (
          <div className="flex gap-2 mt-8">
            <GenericButton
              label="Update field"
              className="bg-buttonTheme hover:!bg-buttonHoverTheme"
            />
            <GenericButton
              label="Cancel"
              onClick={() => setIsEditable(false)}
              className="bg-lightButtonTheme text-buttonTheme hover:!bg-lightButtonHoverTheme hover:!text-buttonTheme"
            />
          </div>
        ) : (
          <GenericButton
            label="Add new field"
            className="bg-buttonTheme hover:bg-buttonHoverTheme w-28 mt-8"
            onClick={handleSubmit}
          />
        )}
      </div>
    </div>
  );
};

export default TasksCustomFields;
