import { Help } from "@mui/icons-material";
import { Checkbox, Input, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import { Link } from "react-router-dom";

const AddCustomFinancialFormulaModal = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <Modal
      title={
        <div className="text-bold text-themeGrey text-lg pb-5 border-b-2 border-creme">
          Add Formula
        </div>
      }
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      width={620}
    >
      <div className="my-5">
        The list of available financial fields can be found{" "}
        <Link className="text-themeGreen">here</Link>
      </div>
      <div>
        <div>
          <div className="text-themeGrey mb-2">
            Name <Help style={{ fontSize: 16 }} className="text-labelBlue" />
          </div>
          <Input className="w-full rounded-none" placeholder="Name" />
        </div>
        <div>
          <div className="text-themeGrey mb-2">
            Value <Help style={{ fontSize: 16 }} className="text-labelBlue" />
          </div>
          <TextArea
            rows={4}
            className="w-full rounded-none"
            placeholder="Start typing"
          />
        </div>
        <div className="flex flex-col">
          <Checkbox className="mt-5">
            Apply only to specific channels{" "}
            <Help style={{ fontSize: 16 }} className="text-labelBlue" />
          </Checkbox>
          <Checkbox className="mt-5">
            Apply only to specific statuses{" "}
            <Help style={{ fontSize: 16 }} className="text-labelBlue" />
          </Checkbox>
        </div>
      </div>
    </Modal>
  );
};

export default AddCustomFinancialFormulaModal;
