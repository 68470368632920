import { Checkbox, DatePicker, Select, Table } from "antd";
import React from "react";
import {
  channelOptions,
  listingFinancialsEventOptions,
  listingFinancialsStatusOptions,
  listingsFinancialsReportOptions,
  quickDatesOptions,
  rentalActivityOptions,
} from "../../enum";
import { Help } from "@mui/icons-material";
import GenericButton from "../../components/GenericButton/GenericButton";
import SettingsIcon from "@mui/icons-material/Settings";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { Link, useNavigate } from "react-router-dom";
import { FINANCIAL_REPORTING_FINANCIAL_SETTINGS } from "../../constants/dashboardRoutes";

const FinancialReportingListings = () => {
  const navigate = useNavigate();
  const columns = [
    {
      title: (
        <div className="text-themeGrey font-normal text-sm">
          Listing default order
          <ImportExportIcon
            style={{ fontSize: 16 }}
            className="text-labelBlue ml-1"
          />
        </div>
      ),
      dataIndex: "listingDefaultOrder",
      key: "listingDefaultOrder",
      width: "40%",
    },
    {
      title: (
        <div className="text-themeGrey font-normal text-sm">
          Total guest fees
        </div>
      ),
      dataIndex: "totalGuestFees",
      key: "totalGuestFees",
    },
    {
      title: (
        <div className="text-themeGrey font-normal text-sm">Total tax</div>
      ),
      dataIndex: "totalTax",
      key: "totalTax",
    },
    {
      title: (
        <div className="text-themeGrey font-normal text-sm">Rental revenue</div>
      ),
      dataIndex: "rentalRevenue",
      key: "rentalRevenue",
    },
    {
      title: (
        <div className="text-themeGrey font-normal text-sm">Owner payout</div>
      ),
      dataIndex: "ownerPayout",
      key: "ownerPayout",
    },

    {
      title: (
        <SettingsIcon style={{ fontSize: 20 }} className="text-labelBlue" />
      ),
      key: "action",
      align: "right",
    },
  ];

  // Sample data.
  const data = [
    {
      key: "1",
      listingDefaultOrder: (
        <Link className="text-themeGreen">
          3B SW15 - The Peckham Apartments
        </Link>
      ),
      totalGuestFees: "€75.00",
      totalTax: "€0.00",
      rentalRevenue: "€275.00",
      ownerPayout: "€275.00",
    },
    {
      key: "2",
      listingDefaultOrder: (
        <Link className="text-themeGreen">
          3B SW15 - The Peckham Apartments
        </Link>
      ),
      totalGuestFees: "€75.00",
      totalTax: "€0.00",
      rentalRevenue: "€275.00",
      ownerPayout: "€275.00",
    },
  ];
  return (
    <div className="mt-3 my-4">
      <div className="flex flex-col xs:items-center sm:items-end xs:justify-center sm:justify-end gap-2">
        <div className="flex xs:flex-col sm:flex-row items-center justify-between gap-2">
          <Select
            placeholder="Download report"
            className="w-40 rounded-none"
            options={listingsFinancialsReportOptions}
          />
          <Checkbox checked>
            Apply current filter{" "}
            <Help style={{ fontSize: 16 }} className="text-labelBlue" />
          </Checkbox>
          <GenericButton
            label="Settings"
            className="bg-selectedGreen text-themeGreen rounded-none"
            iconLeftAlign={true}
            icon={
              <SettingsIcon
                className="text-themeGreen"
                style={{ fontSize: 20 }}
              />
            }
            onClick={() => {
              navigate(FINANCIAL_REPORTING_FINANCIAL_SETTINGS);
            }}
          />
        </div>
        <GenericButton
          label="Reset"
          className="text-themeGreen bg-selectedGreen rounded-none"
        />
      </div>
      <div className="grid xs:grid-cols-1 sm:grid-cols-2 my-5 gap-4">
        <div>
          <div className="text-themeGrey mb-1">Listings</div>
          <Select className="w-full rounded-none" placeholder="Listings" />
        </div>
        <div>
          <div className="text-themeGrey mb-1">Tags</div>
          <Select className="w-full rounded-none" placeholder="Tags" />
        </div>
        <div>
          <div className="text-themeGrey mb-1">From</div>
          <DatePicker className="w-full rounded-none" placeholder="From" />
        </div>
        <div>
          <div className="text-themeGrey mb-1">To</div>
          <DatePicker className="w-full rounded-none" placeholder="To" />
        </div>
        <div>
          <div className="text-themeGrey mb-1">Quickdates</div>
          <Select
            className="w-full rounded-none"
            placeholder="Quickdates"
            options={quickDatesOptions}
          />
        </div>
        <div>
          <div className="text-themeGrey mb-1">Channels</div>
          <Select
            className="w-full rounded-none"
            placeholder="Channels"
            options={channelOptions}
          />
        </div>
        <div>
          <div className="text-themeGrey mb-1">Statuses</div>
          <Select
            className="w-full rounded-none"
            placeholder="Statuses"
            mode="multiple"
            options={listingFinancialsStatusOptions}
          />
        </div>
        <div>
          <div className="text-themeGrey mb-2">Event</div>
          <Select
            className="w-full rounded-none"
            placeholder="Event"
            options={listingFinancialsEventOptions}
          />
        </div>
        <div className="flex gap-2 items-center">
          <div className="text-themeGrey mb-1">Rental activity</div>
          <Select
            className="xs:w-full sm:w-40 rounded-none"
            placeholder="Rental activity"
            options={rentalActivityOptions}
          />
        </div>
      </div>
      <div className="my-5">
        <Table
          className="border-t border-creme"
          columns={columns}
          dataSource={data}
          scroll={{
            x: 1000,
            y: 500,
          }}
        />
      </div>
    </div>
  );
};

export default FinancialReportingListings;
