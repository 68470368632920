import { toast } from "react-toastify";
import { BATH_TYPE, PROPERTY_TYPE, ROOM_TYPE } from "./constants";
import { intervalToDuration } from 'date-fns'

export const generateCityFilterOptionsByUserAssignedCity = userProfile => {
  const assignedCities = userProfile.assignedCity || []

  if (userProfile.isAdmin || assignedCities.includes('All')) {
    return [
      { label: 'London', value: 'London' },
      { label: 'Algiers', value: 'Algiers' },
      { label: 'Paris', value: 'Paris' },
      { label: 'Dubai', value: 'Dubai' }
    ]
  }

  return assignedCities
  .filter(city => city !== 'All')
  .map(city => ({ label: city, value: city }))
}

export const findDifferentValues = (obj1, obj2) => {
  const differentValues = {};
  try {
    function compareObjects(innerObj1, innerObj2, currentPath) {
      for (const key in innerObj1) {
        const newPath = currentPath ? `${currentPath}.${key}` : key;

        if (innerObj1.hasOwnProperty(key)) {
          if (innerObj2.hasOwnProperty(key)) {
            if (
              typeof innerObj1[key] === "object" &&
              typeof innerObj2[key] === "object"
            ) {
              compareObjects(innerObj1[key], innerObj2[key], newPath);
            } else if (innerObj1[key] !== innerObj2[key]) {
              if (newPath?.split(".")?.[1] === "property_type") {
                differentValues[newPath] = [
                  PROPERTY_TYPE[innerObj1[key].split(",")[0]],
                  PROPERTY_TYPE[innerObj2[key].split(",")[0]],
                  "updated",
                ];
              } else if (newPath?.split(".")?.[1] === "bathroom_type") {
                differentValues[newPath] = [
                  BATH_TYPE[innerObj1[key]],
                  BATH_TYPE[innerObj2[key]],
                  "updated",
                ];
              } else if (newPath?.split(".")?.[1] === "room_type") {
                differentValues[newPath] = [
                  ROOM_TYPE[innerObj1[key]],
                  ROOM_TYPE[innerObj2[key]],
                  "updated",
                ];
              } else if (key === "checked") {
                differentValues[`amenities.${innerObj1.name}`] = [
                  innerObj1[key],
                  innerObj2[key],
                  "updated",
                ];
              } else if (
                newPath?.split(".")?.[0] === "start" ||
                newPath?.split(".")?.[0] === "end"
              ) {
                console.log(newPath?.split(".")?.[0]);
                break;
              } else {
                differentValues[newPath] = [
                  innerObj1[key],
                  innerObj2[key],
                  "updated",
                ];
              }
            }
            if (innerObj1[key] === undefined) {
              differentValues[newPath] = differentValues[newPath] || [];
              differentValues[newPath].push("removed");
            }
          } else {
            differentValues[newPath] = [innerObj1[key], undefined, "added"];
          }
        }
      }

      for (const key in innerObj2) {
        const newPath = currentPath ? `${currentPath}.${key}` : key;
        if (innerObj2.hasOwnProperty(key) && !innerObj1.hasOwnProperty(key)) {
          differentValues[newPath] = [undefined, innerObj2[key], "removed"];
        }
      }
    }

    compareObjects(obj1, obj2, "");
    return differentValues;
  } catch (e) {
    console.log(e);
    return differentValues;
  }
};

export const calculateTimeDifference = (date) => {
  const duration = intervalToDuration({
    start: new Date(date),
    end: new Date()
  })

  const differenceString = Object.entries(duration)
  .map(pair => {
    const [label, value] = pair

    return value ? `${value}${label[0]}` : ''
  })
  .join(' ')

  return differenceString + ' ago'
};

export const getImagesFromMediaObject = (imgSource) => {
  if (Array.isArray(imgSource)) {
    return imgSource[0]?.url;
  } else if (Array.isArray(imgSource?.images)) {
    return imgSource?.images[0];
  }
};

export const range = (from = 0, to = 0, step = 1) => {
  return [...Array(Math.floor((to - from) / step) + 1)].map(
    (_, i) => from + i * step
  );
};

export function getQuery(limit, page) {
  const query = {};
  query.limit = limit;
  query.skip = page * limit;

  var queryString = Object.keys(query)
    .map((key) => key + "=" + query[key])
    .join("&");
  return queryString;
}

export const getCurrencySymbol = (currency) => {
  let cur = currency;
  if (currency === "GBP") {
    cur = "£";
  } else if (currency === "USD") {
    cur = "$";
  } else if (currency === "EURO" || currency === "EUR") {
    cur = "€";
  } else if (currency === "DZA") {
    cur = "DZA";
  } else if (currency === "UAE") {
    cur = "AED";
  } else if (currency === "INR") {
    cur = "₹";
  }
  return cur;
};

const toRadians = (degrees) => {
  return degrees * (Math.PI / 180);
};

export const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 3958.8; // Earth radius in miles

  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c;

  return distance;
};

export const numberWithCommas = (x, percentage = false) => {
  const roundedNumber = Number(x).toFixed(2);
  const numberWithCommas = roundedNumber
  .toString()
  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return percentage
    ? numberWithCommas + '%'
    : numberWithCommas;
};

export const sendTostErrorMessage = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const sendTostSuccessMessage = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};
