import React, { useState } from "react";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { Input, Select, Switch, Table, Tag } from "antd";
import GenericButton from "../../components/GenericButton/GenericButton";
import SettingsIcon from "@mui/icons-material/Settings";
import stripeLogo from "../../assets/stripe.png";
import braintreeLogo from "../../assets/braintreeLogo.png";
import properlyLogo from "../../assets/properlyLogo.png";
import ConnectConfirmModal from "../../components/ConnectConfirmModal/ConnectConfirmModal";
import ExtensionIcon from "@mui/icons-material/Extension";
import { Help, SearchOutlined } from "@mui/icons-material";
import PluginCard from "../../components/PluginCard/PluginCard";
import WebhookIcon from "@mui/icons-material/Webhook";
import AddIcon from "@mui/icons-material/Add";
import ArticleIcon from "@mui/icons-material/Article";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const DashboardSettingsIntegrations = () => {
  const [isConnectConfirmModalOpen, setIsConnectConfirmModalOpen] =
    useState(false);

  const columns = [
    {
      title: "",
      dataIndex: "notConnected",
      key: "notConnected",
      width: "20%",
      render: (_, record) => {
        return (
          <div className="bg-gray-300 w-28 flex items-center justify-center">
            <div className="text-sm text-themeGrey">Not connected</div>
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "logo",
      key: "logo",
    },

    {
      title: "",
      key: "action",
      align: "right",
      render: (_, record) => {
        return (
          <GenericButton
            label="Connect"
            className="bg-themeGreen text-white rounded-none"
            iconLeftAlign={true}
            icon={
              <SettingsIcon style={{ fontSize: 16 }} className="rounded-none" />
            }
            onClick={() => {
              setIsConnectConfirmModalOpen(true);
            }}
          />
        );
      },
    },
  ];

  // Sample data.
  const data = [
    {
      key: "1",
      logo: <img src={stripeLogo} className="w-28 h-18" />,
    },
    {
      key: "2",
      logo: <img src={stripeLogo} className="w-28 h-18" />,
    },
    {
      key: "3",
      logo: <img src={braintreeLogo} className="w-28 h-18" />,
    },
    {
      key: "4",
      logo: <img src={properlyLogo} className="w-28 h-18" />,
    },
  ];

  const unifiedWebhookColumns = [
    {
      title: <div className="text-themeGrey text-md">Webhook url</div>,
      dataIndex: "webhookUrl",
      key: "webhookUrl",
    },
    {
      title: <div className="text-themeGrey text-md">Listing</div>,
      dataIndex: "listing",
      key: "listing",
    },
    {
      title: <div className="text-themeGrey text-md">Channel</div>,
      dataIndex: "channel",
      key: "channel",
    },
    {
      title: <div className="text-themeGrey text-md">Is Enabled</div>,
      dataIndex: "isEnabled",
      key: "isEnabled",
    },

    {
      title: <div></div>,
      key: "logs",
      align: "center",
      render: (_, record) => {
        return (
          <GenericButton
            label="Logs"
            className="bg-orange-500 text-black rounded-none"
            iconLeftAlign={true}
            icon={
              <ArticleIcon style={{ fontSize: 16 }} className="rounded-none" />
            }
          />
        );
      },
    },
    {
      title: <div></div>,
      key: "edit",
      align: "center",
      render: (_, record) => {
        return (
          <GenericButton
            label="Edit"
            className="bg-themeGreen text-black rounded-none"
            iconLeftAlign={true}
            icon={
              <EditIcon style={{ fontSize: 16 }} className="rounded-none" />
            }
          />
        );
      },
    },
    {
      title: <div></div>,
      key: "delete",
      align: "center",
      render: (_, record) => {
        return (
          <GenericButton
            label="Delete"
            className="bg-red-500 text-black rounded-none"
            iconLeftAlign={true}
            icon={
              <EditIcon style={{ fontSize: 16 }} className="rounded-none" />
            }
          />
        );
      },
    },
  ];

  const unifiedWebhookData = [
    {
      webhookUrl: "http://13.50.245.41:5000/webhook",
      listing: "-",
      channel: "-",
      isEnabled: "Yes",
    },
    {
      webhookUrl: "http://13.50.245.41:5000/webhook",
      listing: "-",
      channel: "-",
      isEnabled: "Yes",
    },
    {
      webhookUrl: "http://13.50.245.41:5000/webhook",
      listing: "-",
      channel: "-",
      isEnabled: "Yes",
    },
  ];

  return (
    <div>
      <ConnectConfirmModal
        isModalOpen={isConnectConfirmModalOpen}
        setIsModalOpen={setIsConnectConfirmModalOpen}
      />
      <div className="text-themeGrey text-xl font-bold flex items-center gap-2 mb-5">
        <CreditCardIcon style={{ fontSize: 24 }} /> <p>Integrations</p>
      </div>
      <Table
        className="border-t border-creme"
        dataSource={data}
        columns={columns}
        scroll={{
          x: 1000,
        }}
      />
      <div className="text-themeGrey text-xl font-bold flex items-center gap-2 mt-10 mb-5">
        <ExtensionIcon style={{ fontSize: 24 }} />{" "}
        <p>
          Wordpress plugin{" "}
          <Help style={{ fontSize: 16 }} className="text-labelBlue" />
        </p>
      </div>
      <div className="text-themeGrey font-bold mb-5">
        Select the listings you want to show in your wordpress website
      </div>
      <div className="flex items-center gap-3">
        <Input
          addonBefore={<SearchOutlined />}
          placeholder="Search for listings"
          className="rounded-none"
        />
        <GenericButton
          label="Select all"
          className="text-white bg-themeGreen rounded-none"
        />
      </div>
      <div className="h-[450px] mt-5 mb-10 p-3 bg-themeSkin overflow-y-auto">
        <div className="grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <PluginCard
            imageUrl={stripeLogo}
            cardText="3B SW15 - The Putney Apartment (155609)"
          />
          <PluginCard
            imageUrl={stripeLogo}
            cardText="3B SW15 - The Putney Apartment (155609)"
          />
          <PluginCard
            imageUrl={stripeLogo}
            cardText="3B SW15 - The Putney Apartment (155609)"
          />
          <PluginCard
            imageUrl={stripeLogo}
            cardText="3B SW15 - The Putney Apartment (155609)"
          />
          <PluginCard
            imageUrl={stripeLogo}
            cardText="3B SW15 - The Putney Apartment (155609)"
          />
          <PluginCard
            imageUrl={stripeLogo}
            cardText="3B SW15 - The Putney Apartment (155609)"
          />
        </div>
      </div>
      {/* Reservation Webhook */}
      <div>
        <div className="text-themeGrey text-xl font-bold flex items-center gap-2 my-5">
          <WebhookIcon style={{ fontSize: 24 }} /> <p>Reservation webhooks</p>
        </div>
        <div className="md:grid md:grid-cols-4 gap-6">
          <div className="text-themeGrey xs:hidden md:block">Webhook url</div>
          <div className="text-themeGrey xs:hidden md:block">Listing</div>
          <div className="text-themeGrey xs:hidden md:block">Channel</div>
          <div className="text-themeGrey xs:hidden md:block">Is enabled</div>
          <div className="text-themeGrey text-xl font-bold md:col-span-4">
            ADD WEBHOOK
          </div>
          <div className="xs:my-5 md:my-1">
            <p className="text-themeGrey">
              Url
              <Help
                style={{ fontSize: 16 }}
                className="text-labelBlue mb-1 ml-1"
              />
            </p>
            <Input placeholder="Url" className="rounded-none" />
          </div>
          <div className="xs:my-5 md:my-1">
            <p className="text-themeGrey">
              Listings
              <Help
                style={{ fontSize: 16 }}
                className="text-labelBlue mb-1 ml-1"
              />
            </p>
            <Select
              placeholder="All listings"
              className="rounded-none w-full"
              showSearch
            />
          </div>
          <div className="xs:my-5 md:my-1">
            <p className="text-themeGrey">
              Channel
              <Help
                style={{ fontSize: 16 }}
                className="text-labelBlue mb-1 ml-1"
              />
            </p>
            <Select
              placeholder="All channels"
              className="rounded-none w-full"
            />
          </div>
          <div className="xs:my-5 md:my-1">
            <p className="text-themeGrey">
              is enabled?
              <Help
                style={{ fontSize: 16 }}
                className="text-labelBlue mb-1 ml-1"
              />
            </p>
            <Select placeholder="Yes/No" className="rounded-none  w-full" />
          </div>
          <div className="xs:my-5 md:my-1">
            <p className="text-themeGrey">Login</p>
            <Input placeholder="Email" className="rounded-none" />
          </div>
          <div className="xs:my-5 md:my-1">
            <p className="text-themeGrey">
              Password
              <Help
                style={{ fontSize: 16 }}
                className="text-labelBlue mb-1 ml-1"
              />
            </p>
            <Input
              type="password"
              placeholder="Password"
              className="rounded-none"
            />
          </div>
        </div>
        <div className="flex justify-end">
          <GenericButton
            label="Add"
            className="bg-themeGreen rounded-none"
            iconLeftAlign={true}
            icon={<AddIcon />}
          />
        </div>
      </div>
      {/* Guest message webhook */}
      <div>
        <div className="text-themeGrey text-xl font-bold flex items-center gap-2 my-5">
          <WebhookIcon style={{ fontSize: 24 }} />{" "}
          <p>
            Guest message webhooks
            <Tag className="bg-themeGreen text-white ml-1">New</Tag>
          </p>
        </div>
        <div className="md:grid md:grid-cols-4 gap-6">
          <div className="text-themeGrey xs:hidden md:block">Webhook url</div>
          <div className="text-themeGrey xs:hidden md:block">Listing</div>
          <div className="text-themeGrey xs:hidden md:block">Channel</div>
          <div className="text-themeGrey xs:hidden md:block">Is enabled</div>
          <div className="text-themeGrey text-xl font-bold md:col-span-4">
            ADD WEBHOOK
          </div>
          <div className="xs:my-5 md:my-1">
            <p className="text-themeGrey">
              Url
              <Help
                style={{ fontSize: 16 }}
                className="text-labelBlue mb-1 ml-1"
              />
            </p>
            <Input placeholder="Url" className="rounded-none" />
          </div>
          <div className="xs:my-5 md:my-1">
            <p className="text-themeGrey">
              Listings
              <Help
                style={{ fontSize: 16 }}
                className="text-labelBlue mb-1 ml-1"
              />
            </p>
            <Select
              placeholder="All listings"
              className="rounded-none w-full"
              showSearch
            />
          </div>
          <div className="xs:my-5 md:my-1">
            <p className="text-themeGrey">
              Channel
              <Help
                style={{ fontSize: 16 }}
                className="text-labelBlue mb-1 ml-1"
              />
            </p>
            <Select
              placeholder="All channels"
              className="rounded-none w-full"
            />
          </div>
          <div className="xs:my-5 md:my-1">
            <p className="text-themeGrey">
              is enabled?
              <Help
                style={{ fontSize: 16 }}
                className="text-labelBlue mb-1 ml-1"
              />
            </p>
            <Select placeholder="Yes/No" className="rounded-none  w-full" />
          </div>
          <div className="xs:my-5 md:my-1">
            <p className="text-themeGrey">Login</p>
            <Input placeholder="Email" className="rounded-none" />
          </div>
          <div className="xs:my-5 md:my-1">
            <p className="text-themeGrey">
              Password
              <Help
                style={{ fontSize: 16 }}
                className="text-labelBlue mb-1 ml-1"
              />
            </p>
            <Input
              type="password"
              placeholder="Password"
              className="rounded-none"
            />
          </div>
        </div>
        <div className="flex justify-end">
          <GenericButton
            label="Add"
            className="bg-themeGreen rounded-none"
            iconLeftAlign={true}
            icon={<AddIcon />}
          />
        </div>
      </div>
      {/* Unified webhook */}
      <div>
        <div className="text-themeGrey text-xl font-bold flex items-center gap-2 my-5">
          <WebhookIcon style={{ fontSize: 24 }} />{" "}
          <p>
            Unified Webhooks
            <Tag className="bg-themeGreen text-white ml-1">New</Tag>
          </p>
        </div>
        <Table
          className="border-t border-creme"
          dataSource={unifiedWebhookData}
          columns={unifiedWebhookColumns}
          scroll={{
            x: 1000,
          }}
        />
      </div>
      {/* Language detection */}
      <div className="text-themeGrey my-5">
        <p className="text-xl font-bold mb-3">Language detection</p>
        <p>
          Use language information received from channels to determine guest
          language
        </p>
        <Switch />
      </div>
    </div>
  );
};

export default DashboardSettingsIntegrations;
