import { Link } from "react-router-dom";

export default function PageNotFound() {
  return (
    <div className="bg-white min-h-[60vh] flex items-center justify-center">
      <div className="text-center">
        <div className="text-xl font-semibold text-themeSecondaryGreen text-center">
          Page Not Found
        </div>
        <Link to="/cleaning">Go To Home</Link>
      </div>
    </div>
  );
}
