import React from 'react'

import {
  generateHtmlLegendPlugin,
  generateLabels,
  generateTooltipLabel,
  getBookingChartLegendText
} from './utils'

export default function DirectBookingReferalsChart (props) {
  const {
    chartClassNames,
    chartComponent: Chart,
    data = {}
  } = props

  const legendContainerId = 'direct-booking-referals-chart-legend'

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: generateTooltipLabel
        }
      },
      htmlLegend: {
        containerID: legendContainerId
      },
      legend: {
        display: false,
        labels: {
          generateLabels: chart => generateLabels(chart, getBookingChartLegendText)
        }
      }
    }
  }

  const htmlLegendPlugin = generateHtmlLegendPlugin('htmlLegend', legendContainerId)

  return (
    <div className="grid grid-cols-1 sm:grid-cols-[1fr,1fr] gap-2 pt-2 px-2">
      <div className={chartClassNames}>
        <Chart
          plugins={[htmlLegendPlugin]}
          options={options}
          data={data}
          type="pie"
        />
      </div>

      <div
        className="w-full h-full sm:h-[19rem] overflow-hidden"
        id={legendContainerId}
      />
    </div>
  )
}