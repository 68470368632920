/* eslint-disable no-useless-escape */
import FormInput from "../../Form/FormInput";
import FormTextArea from "../../Form/FormTextArea";
import FormPhoneInput from "../../Form/FormPhoneInput";
import UploadMedia from "../../Form/UploadMedia";
import FormSelect from "../../Form/FormSelect";
import { UserSwitchOutlined } from "@ant-design/icons";
import { USER_STATUSES } from "../../../utils/constants";
import GenericButton from "../../GenericButton/GenericButton";
import { Modal } from "antd";
import { useState } from "react";
import { apiInstance, routes } from "../../../utils/API";
import {
  sendTostErrorMessage,
  sendTostSuccessMessage,
} from "../../../utils/helper";

export default function AddBasicInfo({
  imageUrl,
  setImageUrl,
  id,
  imageFile,
  admin = true,
  setImageFile,
  email = null,
  form,
}) {
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(null);
  const [oldPassword, setOldPassword] = useState(null);
  const [resetPassword, setResetPassword] = useState(null);
  const [resetConfirmPassword, setResetConfirmPassword] = useState(null);

  const handleOk = async () => {
    if (!oldPassword || !resetPassword || !resetConfirmPassword) {
      setError("All fields are required");
    } else if (resetPassword !== resetConfirmPassword) {
      setError("New password and New confirm password does not match");
    } else {
      try {
        const res = await apiInstance.post(routes.CHANGE_PASSWORD, {
          oldPassword: oldPassword,
          newPassword: resetPassword,
          email: email,
        });
        sendTostSuccessMessage(res.data.message);
        setOpenModal(!openModal);
        console.log(res);
      } catch (error) {
        console.log(error);
        sendTostErrorMessage(error.response.data.message);
      }
    }
  };

  const handleCancel = () => {
    setError("");
    setOpenModal(false);
    setOldPassword("");
    setResetPassword("");
    setResetConfirmPassword("");
  };
  return (
    <div>
      <Modal
        title="Reset Password"
        okText={"Reset Password"}
        open={openModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          style: { backgroundColor: "#00b4b4", color: "white" },
        }} // Customize OK button style
      >
        <div>
          <div>
            <input
              value={oldPassword}
              onChange={(e) => {
                setOldPassword(e.target.value);
                setError("");
              }}
              type="password"
              placeholder="Old Password *"
              className="border-2 border-black w-full"
            />
          </div>
          <div>
            <input
              value={resetPassword}
              onChange={(e) => {
                setResetPassword(e.target.value);
                setError("");
              }}
              type="password"
              placeholder="New password *"
              className="border-2 border-black w-full"
            />
          </div>
          <div>
            <input
              value={resetConfirmPassword}
              onChange={(e) => {
                setResetConfirmPassword(e.target.value);
                setError("");
              }}
              type="password"
              placeholder="New confirm password *"
              className="border-2 border-black w-full"
            />
          </div>
          {error && <p className="text-red-500">{error}</p>}
        </div>
      </Modal>
      <div className="text-xl font-bold text-themeGrey">Basic info</div>
      {admin && (
        <UploadMedia
          imageFile={imageFile}
          imageUrl={imageUrl}
          setImageFile={setImageFile}
          setImageUrl={setImageUrl}
        />
      )}
      <div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-6 pb-10 border-b-2 border-creme">
        <FormInput
          name="firstName"
          label="First Name"
          placeholder="User first name"
          rules={[{ required: true, message: "First Name is required" }]}
        />
        <FormInput
          name="lastName"
          label="Last Name"
          placeholder="User last name"
        />
        {/* <FormInput
          name="title"
          label="Title"
          placeholder="User Title"
          tooltipTitle="Manager, Admin,Assistant, Cleaner, Finance"
          toolTipFlag={true}
        /> */}
        {/* <FormInput
          name="preferredContactMethod"
          label="preferred Contact Method"
          placeholder="User preferred contact method"
        /> */}
        {admin && (
          <FormInput
            name="email"
            label="email"
            placeholder="User email"
            rules={[
              { required: true, message: "Email is required" },
              {
                pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Enter valid email",
              },
            ]}
          />
        )}
        {id === "add" && (
          <FormInput
            type="password"
            name="password"
            label="password"
            placeholder="User password"
            rules={[
              { required: true, message: "Password is required" },
              {
                pattern:
                  /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])(?=.{12})/,
                message: `Password must contain at least 12 characters including uppercase, lowercase, number & symbol.`,
              },
            ]}
          />
        )}

        {admin && (
          <FormSelect
            label="Status"
            placeholder="Select Status"
            name={"status"}
            options={USER_STATUSES}
            rules={[{ required: true, message: "Status is required" }]}
          />
        )}

        {localStorage.getItem("userEmail") === email && id !== "add" && (
          <GenericButton
            label="Reset Password"
            size="middle"
            className="bg-[#00b4b4] rounded-none w-32 text-white"
            onClick={() => setOpenModal(!openModal)}
          />
        )}
        {/* <FormPhoneInput
          name="phone"
          label="phone"
          placeholder="User phone"
          addonBefore={true}
          form={form}
        /> */}
        {/* <FormPhoneInput
          name="emergencyPhone"
          label="emergency Phone"
          placeholder="User emergency Phone"
          form={form}
        /> */}
      </div>
      {/* <div className="text-xl font-bold text-themeGrey mt-5 pb-10">Address</div> */}
      {/* <div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-6">
        <FormInput name="address" label="address" placeholder="address" />
        <FormInput
          name="postalCode"
          label="postal code"
          placeholder="postal code"
        />
        <FormInput name="city" label="city" placeholder="city" />
        <FormInput name="country" label="country" placeholder="country" />
        <FormInput name="timezone" label="timezone" placeholder="timezone" />
        <FormTextArea
          rows={2}
          name="noteBeforeContact"
          label="note Before Contact"
          placeholder="note Before Contact"
        />
      </div> */}
    </div>
  );
}
