export default function BasicInfoDetails({ group }) {
    return (<div className="w-full md:w-[40%] grid grid-cols-2 gap-4 py-5">
        <div className="text-themeGrey">Group name</div>
        <div className="font-bold">{group?.name || "-"}</div>
        <div className="text-themeGrey">Descriptions</div>
        <div className="font-bold">{group?.description}</div>
        <div className="text-themeGrey">Permission Templates</div>
        <div className="font-bold">{group?.permissionTemplates}</div>
    </div>
    )
}
