import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import UploadIcon from "@mui/icons-material/Upload";
import { Input, Button } from "antd";

function DataMenu() {
  const [isExpanded, setIsExpanded] = useState(true);

  const { TextArea } = Input;

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="mt-8">
      <div className="flex justify-between">
        <h1 className="text-xl font-semibold">Data</h1>
        {isExpanded ? (
          <KeyboardArrowDownIcon
            className="cursor-pointer"
            onClick={handleClick}
          />
        ) : (
          <KeyboardArrowLeftIcon
            className="cursor-pointer"
            onClick={handleClick}
          />
        )}
      </div>
      {isExpanded && (
        <>
          <div>
            <div className="flex gap-8 mt-4">
              <div className="flex grow">
                <p className="w-1/3 xs:mr-3 -ml-2">Property Manager name</p>
                <Input className="w-2/3 bg-transparent" />
              </div>
              <div className="flex grow">
                <p className="w-1/3 xs:mr-3 -ml-2">Property Owner name</p>
                <Input className="w-2/3 bg-transparent" />
              </div>
            </div>
            <div className="flex gap-8 mt-4">
              <div className="flex grow">
                <p className="w-1/3 xs:mr-3 -ml-2">Property Manager email</p>
                <Input className="w-2/3 bg-transparent" />
              </div>
              <div className="flex grow">
                <p className="w-1/3 xs:mr-3 -ml-2">Property Owner email</p>
                <Input className="w-2/3 bg-transparent" />
              </div>
            </div>
            <div className="flex gap-8 mt-4">
              <div className="flex grow">
                <p className="w-1/3 xs:mr-3 -ml-2">Property Manager phone</p>
                <Input className="w-2/3 bg-transparent" />
              </div>
              <div className="flex grow">
                <p className="w-1/3 xs:mr-3 -ml-2">Property Owner phone</p>
                <Input className="w-2/3 bg-transparent" />
              </div>
            </div>
            <div className="flex gap-8 mt-4">
              <div className="flex grow">
                <p className="w-1/3 xs:mr-3 -ml-2">Property Manager address</p>
                <Input className="w-2/3 bg-transparent" />
              </div>
              <div className="flex grow">
                <p className="w-1/3 xs:mr-3 -ml-2">Property Owner address</p>
                <Input className="w-2/3 bg-transparent" />
              </div>
            </div>
            <div className="flex gap-8 mt-4">
              <div className="flex grow">
                <p className="w-1/3 xs:mr-6 -ml-3">
                  Property Manager tax number/ID
                </p>
                <Input className="w-2/3 bg-transparent" />
              </div>
              <div className="flex grow">
                <p className="w-1/3 xs:mr-6 -ml-3">
                  Property Owner tax number/ID
                </p>
                <Input className="w-2/3 bg-transparent" />
              </div>
            </div>
            <div className="flex gap-8 mt-4">
              <div className="flex grow">
                <p className="w-1/3 sm:mr-14 ">Logo</p>
                <div className="w-2/3 xs:w-1/3 xs:-ml-6">
                  <Button
                    type="primary"
                    className="bg-buttonTheme mr-6 ml-4 font-semibold hover:!bg-buttonHoverTheme"
                    icon={<UploadIcon />}
                  >
                    Upload
                  </Button>
                </div>
              </div>
              <div className="flex grow -ml-20">
                <p className="w-1/3 ml-20 xs:mr-1">
                  Property Owner Invoice number
                </p>
                <Input className="w-2/3 bg-transparent" />
              </div>
            </div>
            <div className="flex mt-4">
              <p className="w-1/6">Notes</p>
              <TextArea className="w-1/3 bg-transparent pr-5" />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default DataMenu;
