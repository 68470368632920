import { DatePicker, Drawer, Input, Select, Upload } from "antd";
import React, { useState } from "react";

const AntExpenseDrawer = ({ isDrawerOpen, setIsDrawerOpen }) => {
  const [imageUrl, setImageUrl] = useState("");

  return (
    <Drawer
      className="overflow-y-auto"
      title="Add new expenses and extras"
      placement="right"
      width={400}
      onClose={() => {
        setIsDrawerOpen(false);
      }}
      open={isDrawerOpen}
    >
      <div>
        Values can be negative (generally expenses) and positive (generally
        extras). These expenses or extras will appear in Owner Statements when a
        new statement is created, filters are changed there or a manual refresh
        is done, and only if Date + Listing or Date + Owner match the Owner
        Statement
      </div>
      <div className="mt-5">
        <div className="text-themeGrey mb-2">Concept</div>
        <Input className="w-full rounded-none" placeholder="Concept" />
      </div>
      <div className="mt-5">
        <div className="text-themeGrey mb-2">Date</div>
        <DatePicker className="w-full rounded-none" placeholder="Date" />
      </div>
      <div className="mt-5">
        <div className="text-themeGrey mb-2">Amount</div>
        <Input className="w-full rounded-none" placeholder="Amount" />
      </div>
      <div className="mt-5">
        <div className="text-themeGrey mb-2">Categories</div>
        <Select className="w-full rounded-none" placeholder="Categories" />
      </div>
      <div className="mt-5">
        <div className="text-themeGrey mb-2">Listings</div>
        <Select className="w-full rounded-none" placeholder="Listings" />
      </div>
      <div className="mt-5">
        <div className="text-themeGrey mb-2">Reservations</div>
        <Select className="w-full rounded-none" placeholder="Reservations" />
      </div>
      <div className="mt-5">
        <div className="text-themeGrey mb-2">Owners</div>
        <Select className="w-full rounded-none" placeholder="Owners" />
      </div>
      <div className="mt-5">
        <div className="text-themeGrey mb-2">Attachments</div>
        <div className="flex items-center justify-start ">
          <Upload
            name="avatar"
            className="my-5 h-20  w-full flex items-center justify-center border-2 border-themeGreen border-dotted"
            showUploadList={false}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                className="w-[100%]"
                alt="avatar"
                style={{
                  width: "100%",
                }}
              />
            ) : (
              "Drop files to upload or drag"
            )}
          </Upload>
        </div>
      </div>
    </Drawer>
  );
};

export default AntExpenseDrawer;
