import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CHANNEL_MANAGER_CHANNELS,
  CHANNEL_MANAGER_LISTING_MAPPING,
} from "../../constants/dashboardRoutes";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PowerIcon from "@mui/icons-material/Power";
import ChannelManagerListingMapping from "../ChannelManagerListingMapping/ChannelManagerListingMapping";
import ChannelManagerChannels from "../ChannelManagerChannels/ChannelManagerChannels";
import { Tabs } from "antd";

const ChannelManager = ({ defaultActiveKey }) => {
  const navigate = useNavigate();
  const items = [
    {
      key: "listing-mapping",
      label: (
        <div
          className="text-themeGreen font-bold flex items-center gap-3"
          onClick={() => {
            navigate(CHANNEL_MANAGER_LISTING_MAPPING);
          }}
        >
          <FormatListBulletedIcon style={{ fontSize: 12 }} /> Listing Mapping
        </div>
      ),
      children: <ChannelManagerListingMapping />,
    },
    {
      key: "channels",
      label: (
        <div
          className="text-themeGreen font-bold flex items-center gap-3"
          onClick={() => {
            navigate(CHANNEL_MANAGER_CHANNELS);
          }}
        >
          <PowerIcon style={{ fontSize: 12 }} /> Channels
        </div>
      ),
      children: <ChannelManagerChannels />,
    },
  ];

  return (
    <div className="m-5 bg-white px-5" id="custom-tabs">
      <Tabs activeKey={defaultActiveKey} items={items} centered />
    </div>
  );
};

export default ChannelManager;
