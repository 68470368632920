import { DatePicker, Input, Select } from "antd";
import React from "react";
import GenericButton from "../../components/GenericButton/GenericButton";
import NotFoundImage from "../../assets/notFound.svg";

const ReviewMain = () => {
  return (
    <div className="bg-white">
      <div className="py-5 grid xs:grid-cols-2 lg:grid-cols-6 xlg:grid-cols-8 gap-3">
        <div>
          <div className="text-black mb-1">From (check-out)</div>
          <DatePicker
            placeholder="Select date"
            className="mb-1 rounded-none w-full"
          />
        </div>
        <div>
          <div className="text-black mb-1">To (check-out)</div>
          <DatePicker
            placeholder="Select date"
            className="mb-1 rounded-none w-full"
          />
        </div>
        <div>
          <div className="text-black mb-1">Status</div>
          <Select
            placeholder="Select status"
            className="mb-1 rounded-none w-full"
          />
        </div>
        <div>
          <div className="text-black mb-1">Guest</div>
          <Input
            placeholder="Search by guest name"
            className="mb-1 rounded-none w-full"
          />
        </div>
        <div>
          <div className="text-black mb-1">Listing</div>
          <Select placeholder="Listing" className="mb-1 rounded-none w-full" />
        </div>
        <div>
          <div className="text-black mb-1">Tags</div>
          <Select placeholder="Tags" className="mb-1 rounded-none w-full" />
        </div>
        <div>
          <div className="text-black mb-1">Origin</div>
          <Select placeholder="All" className="mb-1 rounded-none w-full" />
        </div>
        <div className="flex items-center justify-between">
          <GenericButton
            label="Reset"
            className="bg-selectedGreen text-themeGreen rounded-none"
          />
        </div>
      </div>
      <div className="my-5 flex flex-col items-center justify-center">
        <img src={NotFoundImage} />
        <div className="my-3 font-bold text-lg">No Results.</div>
      </div>
    </div>
  );
};

export default ReviewMain;
