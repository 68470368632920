import { toast } from "react-toastify";
import React from "react";

export const broadCastToast = (message, status) => {
    if (status) {
        return toast.success(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    } else {
        return toast.error(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: 'custom-toast'
        });
    }
};
export const manageToast = (status, message) => {
    if (!status) {
        return broadCastToast(<div>{message}</div>, false);
    }

    return broadCastToast(<div>{message}</div>, true);
};
