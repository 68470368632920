import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import React from "react";

const GenericButton = ({
	label = null,
	icon,
	size,
	shape,
	className,
	onClick,
	buttonWidth,
	isLoading,
	disabled,
	iconLeftAlign = false,
	type = "button",
	loadingClass = "",
}) => {
	const antIcon = (
		<Loading3QuartersOutlined
			className={`${loadingClass} `}
			style={{ fontSize: 16 }}
			spin
		/>
	);

	return (
		<Button
			className={`${className ? className : "bg-dullGrey"}`}
			htmlType={type}
			shape={shape}
			size={size}
			onClick={onClick}
			disabled={disabled}
		>
			<div
				className={`flex items-center justify-around ${
					buttonWidth ? buttonWidth : "w-auto"
				}`}
			>
				{isLoading ? (
					<Spin indicator={antIcon} className=" w-4 h-4 text-white" />
				) : iconLeftAlign ? (
					<>
						{icon}
						{label && <p className="ml-1">{label}</p>}
					</>
				) : (
					<>
						{label && <p className="mr-1">{label}</p>}
						{icon}
					</>
				)}
			</div>
		</Button>
	);
};

export default GenericButton;
