/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import { useState } from "react";
import { apiInstance } from "../../utils/API";
import TopBar from "../../components/Views/available-properties/TopBar";
import { Pagination, Select, Table } from "antd";
import Loading from "../../components/Loaders/Loading";
import { formatDate } from "../../utils/dateHelper";
import { Icon } from "@iconify-icon/react";
import NoAccess from "../../components/NoAccess";
import { useContext } from "react";
import { AppContext } from "../../AppContext";

export default function PropertyAvailability() {
  const profile = useContext(AppContext).profile || {};
  const hasReadAccess = profile.readViews;
  const cityData = profile.assignedCity;
  const isAdmin = profile.isAdmin;
  const [date, setDate] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("");
  const [tag, setTag] = useState("");
  const [areaFilter, setAreaFilter] = useState(null);
  const [bedCount, setBedCount] = useState(null);
  const [cityFilter, setCityFilter] = useState(null);
  const [order, setOrder] = useState("asc");

  useEffect(() => {
    if (hasReadAccess) getAvailabilities();
  }, [hasReadAccess, date, tag, areaFilter, bedCount, cityFilter, order]);

  const getAvailabilities = async () => {
    console.log(date, tag);
    setLoading(true);
    try {
      let url = `/properties/availability?`;
      if (tag) {
        url = url + `filter=${tag}&`;
      }
      if (date) {
        url = url + `date=${date}&`;
      }
      if (areaFilter) {
        url = url + `area=${areaFilter}&`;
      }
      if (bedCount) {
        url = url + `beds=${bedCount}&`;
      }
      if (cityFilter) {
        url = url + `city=${cityFilter}&`;
      }
      if (order) {
        url = url + `sort=${order}&`;
      }
      if (!isAdmin) url = url + `cityData=${cityData}`;

      const response = await apiInstance.get(url);
      console.log(response.data.data);
      setData(response.data.data || []);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const columns = [
    {
      title: "Property",
      dataIndex: "allocatedProperty",
      key: "allocatedProperty",
    },
    {
      title: (
        <div
          className={`flex items-center gap-x-1 cursor-pointer test-xs ${
            order === "asc" || order === "desc" ? "text-buttonTheme" : ""
          }`}
        >
          Available From
          <span
            className="flex items-center justify-center"
            style={{
              transform: order != "desc" ? "rotateX(180deg)" : "rotateX(0deg)",
            }}
            onClick={() => {
              if (order === "asc") {
                setOrder("desc");
              } else {
                setOrder("asc");
              }
            }}
          >
            <Icon icon="prime:sort-amount-up" height={24} width={24} />
          </span>
        </div>
      ),
      dataIndex: "availableFrom",
      key: "availableFrom",
    },
    {
      title: "Next Checkin",
      dataIndex: "nextCheckin",
      key: "nextCheckin",
      render: (_, record) => {
        if (record.nextCheckin != "No reservation") {
          return (
            <div className="bg-green-500 text-white w-44 text-center">
              {record.nextCheckin}
            </div>
          );
        } else {
          return (
            <div className="bg-yellow-500 text-white w-44 text-center">
              {"N/A"}
            </div>
          );
        }
      },
    },
  ];

  if (profile._id && !hasReadAccess) {
    return <NoAccess />;
  }
  return (
    <div className="p-5">
      <TopBar
        date={date}
        setDate={setDate}
        count={data.length}
        getAvailabilities={getAvailabilities}
        tag={tag}
        setTag={setTag}
        cityFilter={cityFilter}
        setCityFilter={setCityFilter}
        bedCount={bedCount}
        setBedCount={setBedCount}
        page={page}
        areaFilter={areaFilter}
        setAreaFilter={setAreaFilter}
        setPage={setPage}
        limit={limit}
        setLimit={setLimit}
        sortBy={sortBy}
        show={false}
      />

      {loading ? (
        <div className="h-[50vh] bg-white rounded-lg flex justify-center items-center">
          <Loading />
        </div>
      ) : (
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          pagination={false}
          scroll={{
            x: 1000,
          }}
        />
      )}
    </div>
  );
}
