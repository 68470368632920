import React from "react";
import ReactApexChart from "react-apexcharts";

const FinancialReportingSmallBarGraph = ({
  barChartData,
  chartContainerHeight,
  chartHeightOffset,
  cardHeaderText,
}) => {
  return (
    <div className="w-full h-full flex flex-col p-5">
      <div className="font-bold text-xl">{cardHeaderText}</div>
      <ReactApexChart
        options={barChartData.options}
        series={barChartData.series}
        type="bar"
        height={chartContainerHeight - chartHeightOffset}
      />
    </div>
  );
};

export default FinancialReportingSmallBarGraph;
