/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useState } from "react";
import { getQuery } from "../../utils/helper";
import { apiInstance } from "../../utils/API";
import { differenceInDays, startOfDay } from "date-fns";
import { formatDate } from "../../utils/dateHelper";
import { Pagination, Select, Table } from "antd";
import TopBar from "../../components/Views/reservations_reports/TopBar";
import { AppContext } from "../../AppContext";
import Status from "../../components/Views/reservations_/Status";
import Loading from "../../components/Loaders/Loading";
import { Icon } from "@iconify-icon/react";
import NoAccess from "../../components/NoAccess";

export default function ReservationsReports() {
  const profile = React.useContext(AppContext).profile || {};
  const isAdmin = profile.isAdmin;
  const hasReadAccess = profile.readReservation;
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [create, setCreate] = useState(null);
  const [tag, setTag] = useState("");

  useEffect(() => {
    if (hasReadAccess) getReservations(limit, page, sortBy, create);
  }, [limit, page, hasReadAccess]);

  useEffect(() => {
    if (hasReadAccess) getReservationsCount(limit, page, sortBy, create);
  }, [hasReadAccess]);

  const getReservations = async (
    limit = 10,
    page = 1,
    sortBy = "",
    create = ""
  ) => {
    setLoading(true);
    try {
      let url = `/reservation/report/new?&${getQuery(limit, page - 1)}`;
      if (sortBy) {
        url = url + "&sort=" + sortBy;
      }
      if (create) {
        url = url + "&create=" + create;
      }
      const response = await apiInstance.get(url);
      setReservations(response.data || []);
      console.log(response.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getReservationsCount = async (create) => {
    try {
      let url = `/reservation/report/new?`;
      if (create) {
        url = url + "&create=" + create;
      }

      const response = await apiInstance.get(url);
      setCount(response.data.length);
    } catch (e) {
      console.log(e);
    }
  };

  const tag_ = tag.toLowerCase();
  let filterData = reservations;
  if (tag)
    filterData = filterData.filter((e) => e.tag.toLowerCase().includes(tag_));

  let sortedData = filterData;

  const pageData = sortedData.filter(
    (e, i) => i >= (page - 1) * limit && i < page * limit
  );

  const columns = [
    {
      title: "Guest name",
      dataIndex: "guest",
      key: "guest",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        return <Status status={record.status} />;
      },
    },
    {
      title: "Flat booked",
      dataIndex: "flatBooked",
      key: "flatBooked",
    },
    {
      title: (
        <div
          className={`cursor-pointer flex items-center gap-x-1 ${
            sortBy === "checkInAsc" || sortBy === "checkInDesc"
              ? "text-buttonTheme"
              : ""
          }`}
        >
          CheckIn
          <span
            className="flex items-center justify-center  transition-all duration-300"
            style={{
              transform:
                sortBy === "checkInAsc" ? "rotateX(180deg)" : "rotateX(0deg)",
            }}
            onClick={() => {
              if (sortBy === "checkInAsc") {
                setSortBy("checkInDesc");
                getReservations(limit, page, "checkInDesc", create);
              } else if (sortBy === "checkInDesc") {
                setSortBy("");
                getReservations(limit, page, "", create);
              } else {
                setSortBy("checkInAsc");
                getReservations(limit, page, "checkInAsc", create);
              }
            }}
          >
            <Icon icon="prime:sort-amount-up" height={24} width={24} />
          </span>
        </div>
      ),
      dataIndex: "checkInDate",
      key: "checkInDate",
      render: (_, record) => {
        const getCheckInDate = () => {
          const date = record.checkInDate;
          if (!date) return "";

          if (
            differenceInDays(
              startOfDay(new Date(date)),
              startOfDay(new Date())
            ) === 0
          )
            return "Today";
          return formatDate(date, "dd MMM, yyyy");
        };
        const getColor = () => {
          const date = record.checkInDate;
          if (!date) return "";

          if (
            differenceInDays(
              startOfDay(new Date(date)),
              startOfDay(new Date())
            ) === 0
          )
            return "#164F4C";
        };
        return <div style={{ color: getColor() }}>{getCheckInDate()}</div>;
      },
    },
    {
      title: "Length of stay",
      dataIndex: "lengthOfStay",
      key: "lengthOfStay",
    },
    {
      title: "Lead time",
      dataIndex: "leadTime",
      key: "leadTime",
    },
  ];

  if (profile._id && isAdmin) {
    columns.push({
      title: (
        <div
          className={`cursor-pointer flex items-center gap-x-1 ${
            sortBy === "bookingAsc" || sortBy === "bookingDesc"
              ? "text-buttonTheme"
              : ""
          }`}
        >
          Booking Value
          <span
            className="flex items-center justify-center  transition-all duration-300"
            style={{
              transform:
                sortBy === "bookingAsc" ? "rotateX(180deg)" : "rotateX(0deg)",
            }}
            onClick={() => {
              if (sortBy === "bookingAsc") {
                setSortBy("bookingDesc");
                getReservations(limit, page, "bookingDesc", create);
              } else if (sortBy === "bookingDesc") {
                setSortBy("");
                getReservations(limit, page, "", create);
              } else {
                setSortBy("bookingAsc");
                getReservations(limit, page, "bookingAsc", create);
              }
            }}
          >
            <Icon icon="prime:sort-amount-up" height={24} width={24} />
          </span>
        </div>
      ),
      dataIndex: "bookingValue",
      key: "bookingValue",
    });
  }

  if (profile._id && !hasReadAccess) {
    return <NoAccess />;
  }
  return (
    <div className="p-5">
      <TopBar
        count={count}
        create={create}
        getAvailabilities={getReservations}
        setCreate={setCreate}
        limit={limit}
        page={page}
        setLimit={setLimit}
        setPage={setPage}
        tag={tag}
        setTag={setTag}
        sortBy={sortBy}
        title="New Reservation Reports"
      />

      {loading ? (
        <div className="h-[50vh] bg-white rounded-lg flex justify-center items-center">
          <Loading />
        </div>
      ) : (
        <Table
          loading={loading}
          dataSource={pageData || []}
          columns={columns}
          pagination={false}
          scroll={{
            x: 1000,
          }}
        />
      )}
      <div className="flex flex-wrap gap-3 justify-end my-4">
        <Select
          value={limit}
          options={[
            { value: 20, label: 20 },
            { value: 50, label: 50 },
            { value: 100, label: 100 },
          ]}
          className="w-max"
          onChange={(l) => {
            setLimit(l);
            setPage(1);
          }}
        />
        <Pagination
          defaultCurrent={page}
          current={page}
          total={count}
          pageSize={limit}
          showSizeChanger={false}
          onChange={(p) => {
            if (p !== page) {
              setPage(p);
            }
          }}
        />
      </div>
    </div>
  );
}
