import React, { useState } from "react";
import GenericButton from "../../components/GenericButton/GenericButton";
import { Checkbox, Input, Select, Table } from "antd";
import { Help, Settings } from "@mui/icons-material";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddNewGuestModal from "../../components/AddNewGuestModal/AddNewGuestModal";

const ReservationGuestbook = () => {
  const [isAddNewGuestModalOpen, setIsAddNewGuestModalOpen] = useState(false);
  const columns = [
    {
      title: <div className="text-themeGrey">First name</div>,
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: <div className="text-themeGrey">Last name</div>,
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: <div className="text-themeGrey">Email</div>,
      dataIndex: "email",
      key: "email",
    },
    {
      title: <div className="text-themeGrey">Name</div>,
      dataIndex: "name",
      key: "name",
    },
    {
      title: <div className="text-themeGrey">Phone number</div>,
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: <div className="text-themeGrey">ID Number</div>,
      dataIndex: "idNumber",
      key: "idNumber",
    },
    {
      title: <div className="text-themeGrey">ID Photo</div>,
      dataIndex: "idPhoto",
      key: "idPhoto",
    },
    {
      title: <div className="text-themeGrey">Selfie</div>,
      dataIndex: "selfie",
      key: "selfie",
    },
    {
      title: <div className="text-themeGrey">Date of Birth</div>,
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
    },
    {
      title: <div className="text-themeGrey">City</div>,
      dataIndex: "city",
      key: "city",
    },
    {
      title: <div className="text-themeGrey">Country</div>,
      dataIndex: "country",
      key: "country",
    },
    {
      title: <div className="text-themeGrey">Reservations</div>,
      dataIndex: "reservations",
      key: "reservations",
    },
    {
      title: <div className="text-themeGrey">Related projects</div>,
      dataIndex: "relatedProjects",
      key: "relatedProjects",
    },
    {
      title: <div className="text-themeGrey">Agreed to marketing emails</div>,
      dataIndex: "agreedToMarketingEmails",
      key: "agreedToMarketingEmails",
    },
    {
      title: (
        <div>
          <Settings className="text-themeGrey" style={{ fontSize: 20 }} />
        </div>
      ),
      dataIndex: "settings",
      key: "settings",
      render: (_, record) => {
        return (
          <div className="flex gap-1">
            <GenericButton
              icon={<EditIcon className="text-white" />}
              className="bg-themeGreen w-10"
              onClick={() => {
                setIsAddNewGuestModalOpen(true);
              }}
            />
            <GenericButton
              icon={<DeleteIcon className="text-white" />}
              className="bg-red-500 w-10"
            />
          </div>
        );
      },
    },
  ];

  const data = [
    {
      firstName: "",
      lastName: "",
      email: "",
      name: "test guest",
      phoneNumber: "",
      idNumber: "",
      idPhoto: <Link className="text-themeGrey">Show</Link>,
      selfie: <Link className="text-themeGrey">Show</Link>,
      dateOfBirth: "",
      city: "",
      country: "",
      reservations: (
        <Link className="text-themeGreen">
          3B SW15 - The Putney Apartment (2023-04-16 - 2023-04-18)
        </Link>
      ),
      relatedProjects: (
        <Link className="text-themeGreen">
          3B SW15 - The Putney Apartment (155609)
        </Link>
      ),
      agreedToMarketingEmails: "No",
    },
    {
      firstName: "",
      lastName: "",
      email: "",
      name: "test guest",
      phoneNumber: "",
      idNumber: "",
      idPhoto: <Link className="text-themeGrey">Show</Link>,
      selfie: <Link className="text-themeGrey">Show</Link>,
      dateOfBirth: "",
      city: "",
      country: "",
      reservations: (
        <Link className="text-themeGreen">
          3B SW15 - The Putney Apartment (2023-04-16 - 2023-04-18)
        </Link>
      ),
      relatedProjects: (
        <Link className="text-themeGreen">
          3B SW15 - The Putney Apartment (155609)
        </Link>
      ),
      agreedToMarketingEmails: "No",
    },
    {
      firstName: "",
      lastName: "",
      email: "",
      name: "test guest",
      phoneNumber: "",
      idNumber: "",
      idPhoto: <Link className="text-themeGrey">Show</Link>,
      selfie: <Link className="text-themeGrey">Show</Link>,
      dateOfBirth: "",
      city: "",
      country: "",
      reservations: (
        <Link className="text-themeGreen">
          3B SW15 - The Putney Apartment (2023-04-16 - 2023-04-18)
        </Link>
      ),
      relatedProjects: (
        <Link className="text-themeGreen">
          3B SW15 - The Putney Apartment (155609)
        </Link>
      ),
      agreedToMarketingEmails: "No",
    },
    {
      firstName: "",
      lastName: "",
      email: "",
      name: "test guest",
      phoneNumber: "",
      idNumber: "",
      idPhoto: <Link className="text-themeGrey">Show</Link>,
      selfie: <Link className="text-themeGrey">Show</Link>,
      dateOfBirth: "",
      city: "",
      country: "",
      reservations: (
        <Link className="text-themeGreen">
          3B SW15 - The Putney Apartment (2023-04-16 - 2023-04-18)
        </Link>
      ),
      relatedProjects: (
        <Link className="text-themeGreen">
          3B SW15 - The Putney Apartment (155609)
        </Link>
      ),
      agreedToMarketingEmails: "No",
    },
    {
      firstName: "",
      lastName: "",
      email: "",
      name: "test guest",
      phoneNumber: "",
      idNumber: "",
      idPhoto: <Link className="text-themeGrey">Show</Link>,
      selfie: <Link className="text-themeGrey">Show</Link>,
      dateOfBirth: "",
      city: "",
      country: "",
      reservations: (
        <Link className="text-themeGreen">
          3B SW15 - The Putney Apartment (2023-04-16 - 2023-04-18)
        </Link>
      ),
      relatedProjects: (
        <Link className="text-themeGreen">
          3B SW15 - The Putney Apartment (155609)
        </Link>
      ),
      agreedToMarketingEmails: "No",
    },
    {
      firstName: "",
      lastName: "",
      email: "",
      name: "test guest",
      phoneNumber: "",
      idNumber: "",
      idPhoto: <Link className="text-themeGrey">Show</Link>,
      selfie: <Link className="text-themeGrey">Show</Link>,
      dateOfBirth: "",
      city: "",
      country: "",
      reservations: (
        <Link className="text-themeGreen">
          3B SW15 - The Putney Apartment (2023-04-16 - 2023-04-18)
        </Link>
      ),
      relatedProjects: (
        <Link className="text-themeGreen">
          3B SW15 - The Putney Apartment (155609)
        </Link>
      ),
      agreedToMarketingEmails: "No",
    },
    {
      firstName: "",
      lastName: "",
      email: "",
      name: "test guest",
      phoneNumber: "",
      idNumber: "",
      idPhoto: <Link className="text-themeGrey">Show</Link>,
      selfie: <Link className="text-themeGrey">Show</Link>,
      dateOfBirth: "",
      city: "",
      country: "",
      reservations: (
        <Link className="text-themeGreen">
          3B SW15 - The Putney Apartment (2023-04-16 - 2023-04-18)
        </Link>
      ),
      relatedProjects: (
        <Link className="text-themeGreen">
          3B SW15 - The Putney Apartment (155609)
        </Link>
      ),
      agreedToMarketingEmails: "No",
    },
    {
      firstName: "",
      lastName: "",
      email: "",
      name: "test guest",
      phoneNumber: "",
      idNumber: "",
      idPhoto: <Link className="text-themeGrey">Show</Link>,
      selfie: <Link className="text-themeGrey">Show</Link>,
      dateOfBirth: "",
      city: "",
      country: "",
      reservations: (
        <Link className="text-themeGreen">
          3B SW15 - The Putney Apartment (2023-04-16 - 2023-04-18)
        </Link>
      ),
      relatedProjects: (
        <Link className="text-themeGreen">
          3B SW15 - The Putney Apartment (155609)
        </Link>
      ),
      agreedToMarketingEmails: "No",
    },
    {
      firstName: "",
      lastName: "",
      email: "",
      name: "test guest",
      phoneNumber: "",
      idNumber: "",
      idPhoto: <Link className="text-themeGrey">Show</Link>,
      selfie: <Link className="text-themeGrey">Show</Link>,
      dateOfBirth: "",
      city: "",
      country: "",
      reservations: (
        <Link className="text-themeGreen">
          3B SW15 - The Putney Apartment (2023-04-16 - 2023-04-18)
        </Link>
      ),
      relatedProjects: (
        <Link className="text-themeGreen">
          3B SW15 - The Putney Apartment (155609)
        </Link>
      ),
      agreedToMarketingEmails: "No",
    },
    {
      firstName: "",
      lastName: "",
      email: "",
      name: "test guest",
      phoneNumber: "",
      idNumber: "",
      idPhoto: <Link className="text-themeGrey">Show</Link>,
      selfie: <Link className="text-themeGrey">Show</Link>,
      dateOfBirth: "",
      city: "",
      country: "",
      reservations: (
        <Link className="text-themeGreen">
          3B SW15 - The Putney Apartment (2023-04-16 - 2023-04-18)
        </Link>
      ),
      relatedProjects: (
        <Link className="text-themeGreen">
          3B SW15 - The Putney Apartment (155609)
        </Link>
      ),
      agreedToMarketingEmails: "No",
    },
  ];

  return (
    <div className="p-5 bg-white">
      <AddNewGuestModal
        isModalOpen={isAddNewGuestModalOpen}
        setIsModalOpen={setIsAddNewGuestModalOpen}
      />
      <div className="flex items-center justify-between">
        <div className="text-3xl font-bold">Guestbook</div>
        <div className="flex xs:flex-col sm:flex-row items-center gap-2">
          <GenericButton
            label="Add"
            className="bg-themeGreen text-white font-bold rounded-none"
            onClick={() => {
              setIsAddNewGuestModalOpen(true);
            }}
          />
          <GenericButton
            label="Download Report"
            className="bg-selectedGreen text-themeGreen font-bold rounded-none"
          />
          <Checkbox>
            Apply current filter{" "}
            <Help className="text-labelBlue" style={{ fontSize: 16 }} />
          </Checkbox>
        </div>
      </div>
      <div className="my-5 grid xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-3">
        <div>
          <div className="text-themeGrey mb-1">Guestbook</div>
          <Input
            placeholder="Type to search guestbook"
            className="rounded-none w-full"
          />
        </div>
        <div>
          <div className="text-themeGrey mb-1">City</div>
          <Input
            placeholder="Type to search city"
            className="rounded-none w-full"
          />
        </div>
        <div>
          <div className="text-themeGrey mb-1">Country</div>
          <Select
            placeholder="Type to search country"
            className="rounded-none w-full"
          />
        </div>
        <div className="flex items-center">
          <Checkbox>Has reservations</Checkbox>
        </div>
        <div className="flex items-center">
          <Checkbox>Agree to marketing emails</Checkbox>
        </div>
        <div className="flex items-center justify-end">
          <GenericButton
            label="Reset"
            className="text-selectedGreen bg-themeGreen rounded-none w-24"
          />
        </div>
      </div>
      <div>
        <Table
          className="border-t border-creme"
          dataSource={data}
          columns={columns}
          scroll={{
            x: 1000,
          }}
        />
      </div>
    </div>
  );
};

export default ReservationGuestbook;
