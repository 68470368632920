import React from 'react'
import { format } from 'date-fns'

import PriceCard from '../../components/PriceCard'
import { numberWithCommas } from '../../utils/helper'

export default function Cards (props) {
  const {
    coggsValue,
    date,
    ebita,
    expense,
    grossMargin,
    income,
    months,
    netIncome,
    occupancy,
    profit,
    showExpense,
    showNetIncome,
    stringDate
  } = props

  const dateLabel = React.useMemo(() => {
    if (!months) {
      return ''
    }

    if (months > 1) {
      return `Last ${months} months`
    }

    return 'Last month'
  }, [months])

  const dateRangeLabel = React.useMemo(() => {
    if (!date?.length) {
      return ''
    }
    if (stringDate[0] === stringDate[1]) {
      return format(new Date(date[0]), 'MMMM yyyy')
    }

    return format(new Date(date[0]), 'MMM yyyy') + ' - ' +
      format(new Date(date[1]), 'MMM yyyy')
  }, [date, stringDate])

  const monthLabel = React.useMemo(() => {
    if (!months && !date) {
      return 'All time'
    }

    return months ? dateLabel : dateRangeLabel
  }, [months, date, dateLabel, dateRangeLabel])

  const items = [
    {
      title: 'Total Income',
      value: income ? numberWithCommas(income) : '0.00',
      background: 'bg-blue-200',
      text: 'text-blue-500'
    },
    showExpense && {
      title: 'Direct Costs',
      value: expense ? numberWithCommas(expense) : '0.00',
      background: 'bg-red-200',
      text: 'text-red-500'
    },
    !showExpense && {
      title: 'Cost of Goods Sold',
      value: coggsValue,
      background: 'bg-red-200',
      text: 'text-red-500'
    },
    {
      title: 'Gross Profitability',
      value: profit ? numberWithCommas(profit) : '0.00',
      background: 'bg-yellow-200',
      text: 'text-yellow-600'
    },
    {
      subitems: [
        {
          title: 'Gross Margin',
          value: grossMargin ? numberWithCommas(grossMargin) : '0.00',
          background: 'bg-[#91c8c9]',
          text: 'text-[#074749]',
          percentage: true
        },
        {
          title: 'Occupancy Rate',
          value: occupancy ? numberWithCommas(occupancy) : '0.00',
          background: 'bg-gray-200',
          text: 'text-gray-500',
          percentage: true
        }
      ]
    },
    showNetIncome && {
      title: 'EBIT',
      value: ebita,
      background: 'bg-[#92a8c3]',
      text: 'text-[#354b66]'
    },
    showNetIncome && {
      title: 'Net Income',
      value: netIncome,
      background: 'bg-green-200',
      text: 'text-green-600'
    }
  ].filter(Boolean)

  return (
    <div className="grid gap-2 sm:grid-cols-2">
      {items.map((item, index) => {
        if (item?.subitems?.length > 0) {
          return (
            <div
              className="grid gap-2 sm:grid-cols-2"
              key={index}
            >
              {item.subitems.map(subitem => (
                <PriceCard
                  key={subitem.title}
                  background={subitem.background}
                  text={subitem.text}
                  month={monthLabel}
                  value={subitem.value}
                  title={subitem.title}
                  percentage={subitem?.percentage}
                />
              ))}
            </div>
          )
        }

        return (
          <PriceCard
            key={item.title}
            background={item.background}
            text={item.text}
            month={monthLabel}
            value={item.value}
            title={item.title}
            percentage={item?.percentage}
          />
        )
      })}
    </div>
  )
}
