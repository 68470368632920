import React from 'react'

export default function Tabs (props) {
  const { selectedTab, handleTabSelect } = props

  const getClassName = selected => {
    return `text-md font-bold cursor-pointer hover:bg-buttonTheme 
    hover:bg-opacity-10 px-3 py-1 rounded-lg transition-all 
    ${selected ? 'text-[#00B4B4] bg-buttonTheme bg-opacity-10' : 'text-black'}`
  }

  const tabs = [
    {
      label: 'Income Breakdown',
      value: 1
    },
    {
      label: 'Summary',
      value: 2
    },
    {
      label: 'Portfolio Margins',
      value: 3
    },
    {
      label: 'Property Margins',
      value: 4
    },
    {
      label: 'Revenue and Booking Source Analysis',
      value: 5
    },
    {
      label: 'ROI Calculator',
      value: 6
    },
    {
      label: 'ROI Calculator v2',
      value: 7
    }
  ]

  return (
    <div className="flex gap-2 overflow-x-scroll whitespace-nowrap">
      {tabs.map(({label, value}, i) => (
        <h2
          key={label + i}
          className={getClassName(selectedTab === value)}
          onClick={handleTabSelect(value)}
        >
          {label}
        </h2>
      ))}
    </div>
  )
}