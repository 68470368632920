import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import TabDiv from "../../components/TabDiv/TabDiv";
import AutoPaymentsForm from "../../components/AutoPaymentsForm/AutoPaymentsForm";

const GuestPaymentsAutoPayments = () => {
  const [selectedTabId, setSelectedTabId] = useState(null);
  const [isTabSelected, setIsTabSelected] = useState(false);
  const [tabs, setTabs] = useState([
    { id: 1, badgeCount: "ALL", badgeColor: "#49c5b1" },
    { id: 2, badgeCount: "W", badgeColor: "yellow" },
  ]);

  const handleAddTab = () => {
    const highestId = Math.max(...tabs.map((tab) => tab.id));
    const newTabId = highestId + 1;
    const newTab = {
      id: newTabId,
    };
    setTabs([newTab, ...tabs]);
  };

  const handleTabClick = (tabId) => {
    setSelectedTabId(tabId);
    setIsTabSelected(true);
  };

  return (
    <div className=" bg-white pb-10">
      <div className="grid grid-cols-7">
        <div className="col-span-1">
          <div>
            <div
              onClick={handleAddTab}
              className="flex items-center justify-center w-full h-14 rounded-none 
              font-bold bg-selectedGreen text-themeGreen cursor-pointer hover:bg-themeHover"
            >
              <AddIcon />
              <p className="xs:hidden lg:block">Add auto payments</p>
            </div>
          </div>
          <div className="my-4">
            {tabs.map((tab) => (
              <TabDiv
                key={tab.id}
                id={tab.id}
                tabText={`Test ${tab.id}`}
                badgeCount={tab.badgeCount}
                badgeColor={tab.badgeColor}
                selectedTabId={selectedTabId}
                onTabClick={handleTabClick}
              />
            ))}
          </div>
        </div>
        <div className="col-span-6">
          {isTabSelected && (
            <AutoPaymentsForm
              selectedTabId={selectedTabId}
              tab={tabs[selectedTabId]}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default GuestPaymentsAutoPayments;
