import React from "react";
import { Link, useNavigate } from "react-router-dom";
import GenericButton from "../GenericButton/GenericButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ColorPicker, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";

const AddMesssageTemplateGroup = () => {
  const navigate = useNavigate();
  return (
    <div className="px-5 py-5 bg-white">
      <div className="flex items-center justify-between">
        <Link
          className="text-themeGreen"
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon className="text-themeGreen" />
          Back
        </Link>
        <GenericButton
          label="Save"
          className="text-white bg-themeGreen rounded-none"
        />
      </div>
      <div className="grid xs:grid-cols-1 md:grid-cols-2 gap-3">
        <div>
          <div>Template group name</div>
          <Input
            placeholder="Template group name"
            className="rounded-none w-full"
          />
        </div>
        <div>
          <div>Template description</div>
          <Input
            placeholder="Template description"
            className="rounded-none w-full"
          />
        </div>
        <div>
          <div>Channel</div>
          <Select placeholder="All channel" className="rounded-none w-full" />
        </div>
        <div>
          <div>Listings</div>
          <Select placeholder="All listings" className="rounded-none w-full" />
        </div>
      </div>
    </div>
  );
};

export default AddMesssageTemplateGroup;
