import React from 'react'
import { ToastContainer } from 'react-toastify'
import { ConfigProvider } from 'antd'

import './App.css'
import './styles/custom.css'
import AppRouter from './routes/AppRouter'
import AppProvider from './context/AppProvider'

function App () {
  return (
    <div className="App">
      <AppProvider>
        <ConfigProvider theme={{
          token: { colorPrimary: '#00b4b4' }
        }}
        >
          <ToastContainer style={{ zIndex: 99999999 }}/>
          <AppRouter/>
        </ConfigProvider>
      </AppProvider>
    </div>
  )
}

export default App
