export const OVERVIEW = "/overview";
export const LOGS = "/logs";
export const RESERVATION_TOOL = "/reservationTool";
export const GUEST_COM = "/guestcom";
export const RELOCATION_TOOL = "/relocationTool";
export const PROPETY_REPORT = "/propertyReport";
export const MONDAY_DOT_COM = "/monday/home";
export const CALENDAR = "/calendar";
export const CALENDAR_MULTI = "/calendar/multi";
export const CALENDAR_MONTHLY = "/calendar/monthly";
export const CALENDAR_YEARLY = "/calendar/yearly";
export const RESERVATIONS = "/reservations";
export const RESERVATION = "/reservation";
export const RESERVATIONS_CUSTOM_FIELDS = "/reservations/manage-custom-fields";
export const RESERVATIONS_GUESTBOOK = "/reservations/guestbook";
export const RESERVATIONS_COUPONS = "/reservations/coupons";
export const RESERVATIONS_EDIT = "/reservations/:id";
export const RESERVATIONS_DETAILS = "/reservations/:id/details";
export const INBOX_MESSAGES = "/inbox/messages";
export const INBOX_MESSAGE = "/inbox/message";
export const INBOX_MESSAGE_TEMPLATES = "/inbox/message-templates";
export const INBOX_MESSAGE_TEMPLATES_ADD = "/inbox/message-templates/add";
export const INBOX_MESSAGE_TEMPLATES_ADD_GROUP =
  "/inbox/message-templates/add-group";
export const INBOX_AUTOMATIONS = "/inbox/automations";
export const INBOX_MANAGE_MESSAGES = "/inbox/manage-messages";
export const TASKS = "/tasks";
export const TASKS_MANAGE_TASKS = "/tasks/board";
export const TASKS_MANAGE_AUTO_TASKS = "/tasks/auto";
export const TASKS_CHECKLIST_TEMPLATES = "/tasks/checklist-templates";
export const TASKS_ARCHIVED = "/tasks/archived";
export const TASKS_CUSTOM_FIELDS = "/tasks/manage-custom-fields";
export const REVIEWS = "/review";
export const REVIEWS_MANAGE_REVIEWS = "/reviews";
export const REVIEWS_AUTO_REVIEWS = "/reviews/auto";
export const REVIEWS_AUTO_REVIEWS_ADD = "/reviews/auto/add";
export const REVIEWS_REVIEW_TEMPLATES = "/reviews/templates";
export const REVIEWS_REVIEW_TEMPLATES_ADD = "/reviews/templates/add";
export const GUEST_PAYMENTS = "/guest-payments";
export const GUEST_PAYMENTS_CHARGES = "/guest-payments/charges";
export const GUEST_PAYMENTS_AUTO_PAYMENTS = "/guest-payments/auto-payments";
export const GUEST_PAYMENTS_DOCUMENT_TEMPLATES =
  "/guest-payments/document-templates";
export const FINANCIAL_REPORTING = "/finance";
export const FINANCIAL_REPORTING_ANALYTICS = "/finance/analytics";
export const FINANCIAL_REPORTING_LISTING_FINANCIALS = "/finance/listings";
export const FINANCIAL_REPORTING_OCCUPANCY_REPORTS = "/finance/occupancy";
export const FINANCIAL_REPORTING_EXPENSES_AND_EXTRAS =
  "/finance/expenses-and-extras";
export const FINANCIAL_REPORTING_FINANCIAL_SETTINGS = "/finance/settings";
export const OWNER_STATEMENTS = "/owner-statements";
export const OWNER_STATEMENTS_CREATE_AND_EDIT = "/owner-statements/new";
export const OWNER_STATEMENTS_MANAGE_STATEMENTS = "/owner-statements/manage";
export const LISTING = "/listing";
export const CHECKIN_INSTRUCTIONS = "/checkin-instructions";
export const LISTINGS = "/listings";
export const APARTMENT_LISTING = "/listings/:id";
export const APARTMENT_LISTING_ROUTE = (id) => `/listings/${id}`;
export const MONTHLY_CALENDAR_BY_LISY_ID = (id) =>
  `/calendar/monthly?listing=${id}`;
export const LISTINGS_CUSTOM_FIELDS = "/listings/manage-custom-fields";
export const LISTINGS_EDIT_FIELDS = "/listings/edit/:id";
export const LISTINGS_EDIT_FIELDS_ROUTE = (id) => `edit/${id}`;
export const LISTINGS_NEW_FIELDS = "/listings/new";
export const AUTOMATION_NEW = "/automation/new";
export const CHANNEL_MANAGER = "/channel-manager";
export const CHANNEL_MANAGER_LISTING_MAPPING =
  "/channel-manager/listing-mapping";
export const CHANNEL_MANAGER_CHANNELS = "/channel-manager/channels";
export const USER_MANAGEMENT = "/user-management";
export const USER_MANAGEMENT_USERS = "/user-management/users";
export const USER_MANAGEMENT_DETAILS_ADD = "/user-management/users/:id";
export const USER_MANAGEMENT_DETAILS = "/user-management/users/:id/details";
export const USER_MANAGEMENT_GROUPS = "/user-management/groups";
export const USER_MANAGEMENT_GROUP_ADD = "/user-management/groups/:id";
export const USER_MANAGEMENT_GROUP_DETAILS =
  "/user-management/groups/:id/details";
export const BOOKING_ENGINE = "/booking-engine";
export const BOOKING_ENGINE_DESIGN = "/booking-engine/design";
export const BOOKING_ENGINE_LISTINGS = "/booking-engine/listings";
export const BOOKING_ENGINE_PAGES = "/booking-engine/pages";
export const BOOKING_ENGINE_SETTINGS = "/booking-engine/settings";
export const MARKETPLACE = "/marketplace";
export const SETTINGS = "/settings";
export const SETTINGS_ACCOUNT = "/settings/account";
export const SETTINGS_INVOICES_AND_CHARGES = "/settings/invoices";
export const SETTINGS_INTEGRATIONS = "/settings/integrations";
export const SETTINGS_HOSTAWAY_API = "/settings/hostaway-api";
export const SETTINGS_NOTIFICATIONS = "/settings/notifications";
export const VIEWS = "/views";
export const VIEWS_RESERVATIONS = "/views/reservations";
export const VIEWS_ALL_RESERVATIONS = "/views/all-reservations";
export const VIEWS_AVAILABLE_PROPERTIES = "/views/available-properties";
export const AVAILABLE_PROPERTIES = "/views/availability";
export const FINANCE = "/finance";
export const RESERVATION_OVERVIEW = "/reservationoverview";
export const SYNC_DATA = "/sync";
export const CLEANING = "/cleaning";
export const KEYS = "/keys";

export const RESERVATION_REPORT = "/reservations-reports";
export const RESERVATION_REPORT_NEW = "/reservations-reports/new";
export const RESERVATION_REPORT_CANCELED = "/reservations-reports/cancelled";
