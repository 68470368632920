import { Help } from "@mui/icons-material";
import { Input, Modal, Select } from "antd";
import React from "react";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;

const AddCouponModal = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <Modal
      title={
        <div className="text-bold text-themeGrey text-lg pb-5 border-b-2 border-creme">
          Add coupon
        </div>
      }
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      width={620}
    >
      <div className="grid grid-cols-2 gap-2">
        <div className="col-span-2">
          <div className="text-themeGrey mb-2">
            Coupon Name{" "}
            <Help className="text-labelBlue" style={{ fontSize: 16 }} />
          </div>
          <Input className="w-full rounded-none" placeholder="Coupon Name" />
        </div>
        <div>
          <div className="text-themeGrey mb-2">
            Amount <Help className="text-labelBlue" style={{ fontSize: 16 }} />
          </div>
          <Input className="w-full rounded-none" placeholder="Amount" />
        </div>
        <div>
          <div className="text-themeGrey mb-2">
            Type <Help className="text-labelBlue" style={{ fontSize: 16 }} />
          </div>
          <Select className="w-full rounded-none" placeholder="Percentage" />
        </div>
        <div className="col-span-2">
          <div className="text-themeGrey mb-2">
            Minimum nights{" "}
            <Help className="text-labelBlue" style={{ fontSize: 16 }} />
          </div>
          <Input className="w-full rounded-none" placeholder="1" />
        </div>
        <div className="col-span-2">
          <div className="text-themeGrey mb-2">
            Uses <Help className="text-labelBlue" style={{ fontSize: 16 }} />
          </div>
          <Input className="w-full rounded-none" placeholder="Unlimited uses" />
        </div>
        <div className="col-span-2">
          <div className="text-themeGrey mb-2">
            Check-in dates{" "}
            <Help className="text-labelBlue" style={{ fontSize: 16 }} />
          </div>
          <RangePicker
            className="w-full rounded-none"
            placeholder="Unlimited uses"
          />
        </div>
        <div className="col-span-2">
          <div className="text-themeGrey mb-2">
            Validity{" "}
            <Help className="text-labelBlue" style={{ fontSize: 16 }} />
          </div>
          <RangePicker
            className="w-full rounded-none"
            placeholder="Unlimited uses"
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddCouponModal;
