/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import GenericButton from "../../../components/GenericButton/GenericButton";
import { Form } from "antd";
import { manageToast } from "../../../components/Common/ManageToast";
import { USER_MANAGEMENT_GROUPS } from "../../../constants/dashboardRoutes";
import AddBasicInfo from "../../../components/UserManagement/Groups/AddBasicInfo";
import AccessPermissions from "../../../components/UserManagement/Groups/AccessPermissions";
import { initialValues } from "../../../components/UserManagement/Groups/Helper";
import OtherPermissions from "../../../components/UserManagement/Groups/OtherPermissions";
import { apiInstance } from "../../../utils/API";
import Loading from "../../../components/Loaders/Loading";
import { AppContext } from "../../../AppContext";
import NoAccess from "../../../components/NoAccess";

export default function GroupForm() {
  const navigate = useNavigate();
  const profile = useContext(AppContext).profile || {};
  const hasAccess = profile.isAdmin;
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [form] = Form.useForm();

  useEffect(() => {
    if (hasAccess && id && id !== "add") {
      fetchUser();
    } else {
      setLoading(false);
    }
  }, [id]);

  async function fetchUser() {
    try {
      setLoading(true);
      if (id && id !== "add") {
        const res = await apiInstance.get("/api/group-rights/" + id);
        const data = res.data;
        form.setFieldsValue(data);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      } else {
        setLoading(false);
      }
    } catch (error) {
      const message = error?.response?.data;
      console.log(error);
      manageToast(false, `${message?.err}` || "Something went wrong.");
      setLoading(false);
    }
  }

  const onSubmit = async (data) => {
    try {
      setSending(true);
      if (id !== "add") {
        const res = await apiInstance.put("/api/group-rights/" + id, {
          ...data,
        });
        if (res.status === 200) {
          manageToast(true, "Updated Successfully");
        }
      } else {
        const res = await apiInstance.post("/api/group-rights", { ...data });
        if (res.status === 200) {
          manageToast(true, "Saved Successfully");
          setTimeout(() => {
            navigate(USER_MANAGEMENT_GROUPS);
          }, 1500);
        }
      }
      setSending(false);
    } catch (error) {
      console.log(error);
      manageToast(false, "Invalid Id");
      setSending(false);
    }
  };

  if (profile?._id && !hasAccess) {
    return <NoAccess />;
  }
  return (
    <div id="user-table" className=" bg-white relative">
      <Form
        name="basic"
        initialValues={initialValues}
        onFinish={onSubmit}
        autoComplete="off"
        form={form}
      >
        <div className="p-5 flex items-center justify-between border-b-2 fixed bg-white top-12 left-0 md:left-[257px] right-0 z-[999]">
          <div
            className="font-bold text-md text-themeGreen flex items-center justify-between hover:underline cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIosIcon style={{ fontSize: 12 }} /> Groups
          </div>

          <div className="flex items-center gap-3">
            <GenericButton
              loadingClass="text-white"
              isLoading={sending}
              disabled={sending}
              type="submit"
              label="Save"
              className="bg-buttonTheme hover:!bg-buttonHoverTheme disabled:bg-buttonTheme disabled:hover:!bg-buttonTheme"
            />
            <GenericButton
              disabled={sending}
              onClick={() => {
                navigate(USER_MANAGEMENT_GROUPS);
              }}
              label="Cancel"
              className="bg-labelGreen text-themeGreen disabled:bg-labelGreen disabled:text-themeGreen disabled:opacity-50"
            />
          </div>
        </div>
        <div className=" px-5 pt-[72px]">
          {loading ? (
            <div className="h-[50vh] flex justify-center items-center">
              <Loading />
            </div>
          ) : (
            <>
              {" "}
              <AddBasicInfo form={form} />
              <AccessPermissions />
              <OtherPermissions />
            </>
          )}
        </div>
      </Form>
    </div>
  );
}
