/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import GenericButton from "../../../components/GenericButton/GenericButton";
import { Form } from "antd";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AccessPermissions from "../../../components/UserManagement/Users/AccessPermissions";
import OtherPermissions from "../../../components/UserManagement/Users/OtherPermissions";
import NotificationsPermissions from "../../../components/UserManagement/Users/NotificationsPermissions";
// import ListingAccess from "../../../components/UserManagement/Users/ListingAccess";
import { USER_MANAGEMENT_USERS } from "../../../constants/dashboardRoutes";
import FormSelect from "../../../components/Form/FormSelect";
import { permissionTemplateOptions } from "../../../enum";
import BasicInfoDetails from "../../../components/UserManagement/Users/BasicInfoDetails";
import { convertArrayToFormData } from "../helper";
import { apiInstance } from "../../../utils/API";
import Loading from "../../../components/Loaders/Loading";
import NoAccess from "../../../components/NoAccess";
import { AppContext } from "../../../AppContext";

export default function UserDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const profile = useContext(AppContext).profile || {};
  const hasAccess = profile.isAdmin;
  const [loading, setLoading] = useState(true);
  // const [notifications, setNotifications] = useState([])
  // const [listings, setListings] = useState([])
  // const [searchList, setSearchList] = useState("")
  const [user, setUser] = useState(null);
  const [groups, setGroups] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    // fetchListings()
    if (hasAccess) fetchGroups();
  }, []);

  useEffect(() => {
    if (hasAccess && id) {
      fetchUser();
      fetchNotifications();
    } else {
      setLoading(false);
    }
  }, [id]);

  async function fetchUser() {
    try {
      setLoading(true);
      if (id) {
        const res = await apiInstance.get(
          "/api/users/" + id + "?includeListing=true"
        );
        const data = res.data;
        // setListings(data.listings || [])
        delete data.listings;

        form.setFieldsValue(data);
        setUser(data || {});
        setTimeout(() => {
          setLoading(false);
        }, 500);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function fetchNotifications() {
    setLoading(true);
    try {
      setLoading(true);
      let url = `/api/users/${id}/notification-settings`;
      const list = await apiInstance.get(url);
      const data = convertArrayToFormData(list.data);
      form.setFieldsValue(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function fetchListings() {
    try {
      // let url = `/api/properties/propertiesList`
      // const list = await apiInstance.get(url)
      // setListings(list.data)
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchGroups() {
    try {
      let url = `/api/group-rights`;
      const list = await apiInstance.get(url);
      setGroups((arr) => [...list.data] || []);
    } catch (error) {
      console.log(error);
    }
  }

  if (profile?._id && !hasAccess) {
    return <NoAccess />;
  }
  return (
    <div id="user-table" className=" bg-white p-5">
      <Form name="basic" initialValues={user} autoComplete="off" form={form}>
        <div className="flex items-center justify-between  border-b-2 p-5 fixed bg-white left-0 md:left-[257px] right-0 z-[9999] top-12 border-creme">
          <div
            className="font-bold text-md text-themeGreen pl-0 md:pl-5 flex items-center justify-between hover:underline cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIosIcon style={{ fontSize: 12 }} /> Users
          </div>

          <div>
            <GenericButton
              disabled={loading}
              onClick={() => {
                navigate(`${USER_MANAGEMENT_USERS}/${id}`);
              }}
              label="Edit"
              className="bg-themeGreen text-white"
              iconLeftAlign={true}
              icon={<BorderColorIcon style={{ fontSize: 12 }} />}
            />
          </div>
        </div>

        <div className="pt-[53px]">
          {loading ? (
            <div className="h-[50vh] flex justify-center items-center">
              <Loading />
            </div>
          ) : (
            <div className="grid grid-cols-6 gap-4">
              <>
                {" "}
                <div className="lg:col-span-5 col-span-6 my-5">
                  <div className="text-xl font-bold text-themeGrey">
                    Basic info
                  </div>
                  <BasicInfoDetails user={user} />
                  <AccessPermissions
                    isDetails={true}
                    groups={groups}
                    form={form}
                  />
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <FormSelect
                      disabled={true || groups.length === 0}
                      label="Group"
                      bordered={false}
                      name="userGroupId"
                      className="w-[40%]  border-2 border-creme"
                      placeholder="Group"
                      optionFilterProp="children"
                      options={groups.map((e) => {
                        return { value: e._id, label: e.name };
                      })}
                    />

                    <FormSelect
                      disabled={true}
                      toolTipFlag={true}
                      tooltipTitle="If you are not experienced with permissions, you can select from our pre-defined permission templates to create a user"
                      label="Permission Template"
                      bordered={false}
                      name="template"
                      className="w-[40%]  border-2 border-creme"
                      placeholder="Permission Template"
                      optionFilterProp="children"
                      options={permissionTemplateOptions}
                    />
                  </div>

                  <OtherPermissions isDetails={true} />
                  {/* <NotificationsPermissions isDetails={true} /> */}
                  {/* <ListingAccess isDetails={true} listings={listings} fetchListings={fetchListings} searchList={searchList} setSearchList={setSearchList} /> */}
                </div>
                <div className="my-5 text-themeGrey font-bold text-md hidden md:flex">
                  <ul>
                    <li className="mb-7 cursor-pointer hover:underline text-themeGreen">
                      BASIC INFO
                    </li>
                    <li className="my-7 cursor-pointer hover:underline">
                      ACCESS PERMISSIONS
                    </li>
                    <li className="my-7 cursor-pointer hover:underline">
                      NOTIFICATIONS
                    </li>
                    <li className="my-7 cursor-pointer hover:underline">
                      LISTINGS ACCESS
                    </li>
                  </ul>
                </div>
              </>
            </div>
          )}
        </div>
      </Form>
    </div>
  );
}
