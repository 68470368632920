import { Checkbox, Input, Modal, Select } from "antd";
import React from "react";

const AddTaxModal = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <Modal
      title={
        <div className="text-bold text-themeGrey text-lg pb-5 border-b-2 border-creme">
          Add Tax
        </div>
      }
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      width={620}
    >
      <div className="my-5">
        <div>
          <div className="text-themeGrey mb-2">Tax type</div>
          <Select className="w-full rounded-none" placeholder="Tax type" />
        </div>
        <div className="flex items-center justify-center my-5 gap-2">
          <div className="w-[50%]">
            <div className="text-themeGrey mb-2">Amount</div>
            <Input className="w-full rounded-none" placeholder="Amount" />
          </div>
          <div className="w-[50%]">
            <div className="text-themeGrey mb-2">Tax type</div>
            <Select
              className="w-full rounded-none"
              placeholder="Flat fee"
              options={[
                { value: "flatFee", label: "Flat Fee" },
                { value: "percentage", label: "Percentage" },
              ]}
            />
          </div>
        </div>
        <Checkbox>
          Apply a different tax amount based on number of nights
        </Checkbox>
      </div>
    </Modal>
  );
};

export default AddTaxModal;
