import React from 'react'
import { Descriptions } from 'antd'
import { isEmpty } from 'lodash'

export default function RoiCalculatorResultPropertyCard (props) {
  const {
    media,
    basicInfo: {
      tags,
      internal_listing_name
    },
    address: {
      address,
      city,
      country
    },
    mondayAmenities
  } = props

  const getFormatedAmenities = (amenities) => {
    let arr = [];
    if (Object.keys(amenities).length > 0) {
      for (let [key, value] of Object.entries(amenities)) {
        if (key !== "External listing name") {
          arr.push(`${key} : ${value}`);
        }
      }
    }
    return arr.sort((a, b) => a.length - b.length);
  };

  const renderAmenities = amenities => {
    const notFoundLabel = 'Amenities not found... Please check Guest Comm Board'
    if (Array.isArray(amenities)) {
      if (isEmpty(amenities[0])) {
        return notFoundLabel
      } else {
        return getFormatedAmenities(amenities[0]).map(item => {
          return (
            <div className="bg-gray-200 px-2 rounded" key={item}>
              <p className="flex-inline text-xs">{item}</p>
            </div>
          );
        })
      }
    }

    return amenities
      ? getFormatedAmenities(amenities).map(item => {
        return (
          <div className="bg-gray-200 px-2 rounded" key={item}>
            <p className="flex-inline text-xs">{item}</p>
          </div>
        );
      })
      : notFoundLabel
  }

  const descriptionItems = [
    {
      label: 'Tag',
      children: tags || '--'
    },
    {
      label: 'Listing',
      children: internal_listing_name || '--'
    },
    {
      label: 'Address',
      children: address + ', ' + city + ', ' + country || '--'
    }
  ]

  return (
    <div className={`shadow-themeGreenCard rounded-lg p-4`}>
      <img className="rounded-md mb-7" src={media?.[0]} alt=""/>

      <Descriptions column={1} size="small">
        {descriptionItems.map((item, index) => {
          return (
            <Descriptions.Item
              labelStyle={{
                fontWeight: 'bold',
                color: '#000000',
                fontSize: '0.75rem',
                lineHeight: '1rem'
            }}
              contentStyle={{
                fontSize: '0.75rem',
                lineHeight: '1rem'
              }}
              label={item.label}
              key={index}
            >
              {item.children}
            </Descriptions.Item>
          )
        })}
      </Descriptions>

      <p className="text-xs font-bold mb-2">Amenities:</p>
      <div className="flex flex-wrap gap-1 w-full">
        {renderAmenities(mondayAmenities)}
      </div>
    </div>
  )
}