// src/components/ApexDonutChartComponent.js
import React from "react";
import ReactApexChart from "react-apexcharts";

const DonutChart = () => {
  const donutChartData = {
    options: {
      chart: {
        id: "donut-chart",
        type: "donut",
      },
      labels: ["Flexliving Direct"],
      colors: ["#F66130"],
      width: "100%",
      height: "100%",
    },
    series: [1000],
  };

  return (
    <ReactApexChart
      options={donutChartData.options}
      series={donutChartData.series}
      type="donut"
    />
  );
};

export default DonutChart;
