import React, { useState } from "react";
import GenericButton from "../../components/GenericButton/GenericButton";
import { Table, Tag } from "antd";
import AddCouponModal from "../../components/AddCouponModel/AddCouponModel";

const ReservationCoupons = () => {
  const [addCouponModal, setAddCouponModal] = useState(false);

  const columns = [
    {
      title: <div className="text-themeGrey">Name</div>,
      dataIndex: "name",
      key: "name",
    },
    {
      title: <div className="text-themeGrey">Amount</div>,
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: <div className="text-themeGrey">Minimum nights</div>,
      dataIndex: "minimumNights",
      key: "minimumNights",
    },
    {
      title: <div className="text-themeGrey">Check-in dates</div>,
      dataIndex: "checkinDates",
      key: "checkinDates",
    },
    {
      title: <div className="text-themeGrey">Validity</div>,
      dataIndex: "validity",
      key: "validity",
    },
    {
      title: <div className="text-themeGrey">Max uses</div>,
      dataIndex: "maxUses",
      key: "maxUses",
    },
    {
      title: <div className="text-themeGrey">Current uses</div>,
      dataIndex: "currentUses",
      key: "currentUses",
    },

    {
      title: <div className="text-themeGrey">Status</div>,
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        return <Tag className="text-white bg-red-500 p-2">Expired</Tag>;
      },
    },
  ];

  const data = [
    {
      key: "1",
      name: "TEST",
      amount: "10%",
      minimumNights: "1",
      checkinDates: "From: 2023-06-01 To: 2023-06-02",
      validity: "From: 2023-07-25 To: 2023-07-27",
      maxUses: "1",
      currentUses: "0",
    },
  ];

  return (
    <div className="p-5 bg-white">
      <AddCouponModal
        isModalOpen={addCouponModal}
        setIsModalOpen={setAddCouponModal}
      />
      <div className="flex items-center justify-between">
        <div className="text-3xl font-bold">Coupons</div>
        <GenericButton
          label="Add Coupon"
          className="bg-themeGreen text-white font-bold rounded-none"
          onClick={() => {
            setAddCouponModal(true);
          }}
        />
      </div>
      <div className="my-10">
        <Table
          className="border-t border-creme"
          dataSource={data}
          columns={columns}
          scroll={{
            x: 1000,
          }}
        />
      </div>
    </div>
  );
};

export default ReservationCoupons;
