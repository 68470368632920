/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react'
import { Table } from 'antd'
import { Icon } from '@iconify-icon/react'
import { format } from 'date-fns'

import { serverlessApi } from '../../utils/API'
import { manageToast } from '../../components/Common/ManageToast'
import Loading from '../../components/Loaders/Loading'
import NoAccess from '../../components/NoAccess'
import { AppContext } from '../../AppContext'

import TopBar from './TopBar'

export default function PropertyReport () {
  const profile = React.useContext(AppContext).profile || {}
  const hasReadAccess = profile?.readPropertyReport
  const isAdmin = profile.isAdmin
  const cityData = profile.assignedCity
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [name, setName] = React.useState(null)
  const [available, setAvailable] = React.useState()
  const [sort, setSort] = React.useState('')

  React.useEffect(() => {
    const controller = new AbortController()

    if (hasReadAccess) {
      getAvailabilities(controller.signal)
    }

    return () => {
      controller.abort()
    }
  }, [name, available])

  const getAvailabilities = (signal) => {
    return Promise.resolve()
    .then(() => setLoading(true))
    .then(() => {
      let url = '?'

      if (name) {
        url += `property=${name}&`
      }

      if (available) {
        url += `available=${available}&`
      }

      if (!isAdmin) url = url + `cityData=${cityData}`

      return url
    }).then(url => {
      return serverlessApi.getPropertyReport(url, signal ? { signal } : {}).then(response => {
        setData(response.data.propertyReport || []);
        setLoading(false)
      })
    }).catch(e => {
      if (e?.name === 'CanceledError') {
        setLoading(true)
        console.log('Request cancelled.', e)
      } else {
        manageToast(false, 'Server error. Something went wrong.')
        setLoading(false)
      }
    })
  }

  React.useEffect(() => {
    let tempProperty = [...data]
    if (sort === 'propertydesc') {
      tempProperty = tempProperty.sort((a, b) => {
        const nameA = a.allocatedProperty?.toUpperCase()
        const nameB = b.allocatedProperty?.toUpperCase()

        if (nameA < nameB) {
          return -1
        } else if (nameA > nameB) {
          return 1
        }
      })
      setData(tempProperty)
    } else if (sort === 'propertyasc') {
      tempProperty = tempProperty.sort((a, b) => {
        const nameA = a.allocatedProperty?.toUpperCase()
        const nameB = b.allocatedProperty?.toUpperCase()

        if (nameB < nameA) {
          return -1
        } else if (nameB > nameA) {
          return 1
        }
      })
      setData(tempProperty)
    } else if (sort === 'currentcheckindesc') {
      let na = []
      let rest = []
      tempProperty.forEach((item) => {
        if (item.currenCheckin === 'No reservation') {
          na.push(item)
        } else {
          rest.push(item)
        }
      })
      rest = rest.sort((a, b) => {
        const nameA = new Date(a.currenCheckin)
        const nameB = new Date(b.currenCheckin)

        if (nameA < nameB) {
          return -1
        } else if (nameA > nameB) {
          return 1
        }
      })
      setData([...rest, ...na])
    } else if (sort === 'currentcheckinasc') {
      let na = []
      let rest = []
      tempProperty.forEach((item) => {
        if (item.currenCheckin === 'No reservation') {
          na.push(item)
        } else {
          rest.push(item)
        }
      })
      rest = rest.sort((a, b) => {
        const nameA = new Date(a.currenCheckin)
        const nameB = new Date(b.currenCheckin)

        if (nameB < nameA) {
          return -1
        } else if (nameB > nameA) {
          return 1
        }
      })
      setData([...rest, ...na])
    } else if (sort === 'currentcheckoutdesc') {
      let na = []
      let rest = []
      tempProperty.forEach((item) => {
        if (item.currentCheckout === 'No reservation') {
          na.push(item)
        } else {
          rest.push(item)
        }
      })
      rest = rest.sort((a, b) => {
        const nameA = new Date(a.currentCheckout)
        const nameB = new Date(b.currentCheckout)

        if (nameA < nameB) {
          return -1
        } else if (nameA > nameB) {
          return 1
        }
      })
      setData([...rest, ...na])
    } else if (sort === 'currentcheckoutasc') {
      let na = []
      let rest = []
      tempProperty.forEach((item) => {
        if (item.currentCheckout === 'No reservation') {
          na.push(item)
        } else {
          rest.push(item)
        }
      })
      rest = rest.sort((a, b) => {
        const nameA = new Date(a.currentCheckout)
        const nameB = new Date(b.currentCheckout)

        if (nameB < nameA) {
          return -1
        } else if (nameB > nameA) {
          return 1
        }
      })
      setData([...rest, ...na])
    } else if (sort === 'nextcheckindesc') {
      let na = []
      let rest = []
      tempProperty.forEach((item) => {
        if (item.nextCheckin === 'No reservation') {
          na.push(item)
        } else {
          rest.push(item)
        }
      })
      rest = rest.sort((a, b) => {
        const nameA = new Date(a.nextCheckin)
        const nameB = new Date(b.nextCheckin)

        if (nameA < nameB) {
          return -1
        } else if (nameA > nameB) {
          return 1
        }
      })
      setData([...rest, ...na])
    } else if (sort === 'nextcheckinasc') {
      let na = []
      let rest = []
      tempProperty.forEach((item) => {
        if (item.nextCheckin === 'No reservation') {
          na.push(item)
        } else {
          rest.push(item)
        }
      })
      rest = rest.sort((a, b) => {
        const nameA = new Date(a.nextCheckin)
        const nameB = new Date(b.nextCheckin)

        if (nameB < nameA) {
          return -1
        } else if (nameB > nameA) {
          return 1
        }
      })
      setData([...rest, ...na])
    } else if (sort === 'nextcheckoutdesc') {
      let na = []
      let rest = []
      tempProperty.forEach((item) => {
        if (item.nextCheckout === 'No reservation') {
          na.push(item)
        } else {
          rest.push(item)
        }
      })
      rest = rest.sort((a, b) => {
        const nameA = new Date(a.nextCheckout)
        const nameB = new Date(b.nextCheckout)

        if (nameA < nameB) {
          return -1
        } else if (nameA > nameB) {
          return 1
        }
      })
      setData([...rest, ...na])
    } else if (sort === 'nextcheckoutasc') {
      let na = []
      let rest = []
      tempProperty.forEach((item) => {
        if (item.nextCheckout === 'No reservation') {
          na.push(item)
        } else {
          rest.push(item)
        }
      })
      rest = rest.sort((a, b) => {
        const nameA = new Date(a.nextCheckout)
        const nameB = new Date(b.nextCheckout)

        if (nameB < nameA) {
          return -1
        } else if (nameB > nameA) {
          return 1
        }
      })
      setData([...rest, ...na])
    }
  }, [sort])

  const columns = [
    {
      title: (
        <div
          className={`flex items-center gap-x-1 cursor-pointer test-xs ${
            sort === 'propertyasc' || sort === 'propertydesc'
              ? 'text-buttonTheme'
              : ''
          }`}
        >
          Property Name
          <span
            className="flex items-center justify-center"
            style={{
              transform:
                sort !== 'propertydesc' ? 'rotateX(180deg)' : 'rotateX(0deg)',
            }}
            onClick={() => {
              if (sort === 'propertyasc') {
                setSort('propertydesc')
              } else {
                setSort('propertyasc')
              }
            }}
          >
            <Icon icon="prime:sort-amount-up" height={24} width={24}/>
          </span>
        </div>
      ),
      dataIndex: 'allocatedProperty',
      key: 'allocatedProperty',
    },
    {
      title: 'Available',
      dataIndex: 'available',
      key: 'available',
      render: (_, record) => {
        return record.available ? (
          <div className="bg-green-200 flex justify-center mx-2 rounded-xl">
            <p>Yes</p>
          </div>
        ) : (
          <div className="bg-red-200 flex justify-center mx-2 rounded-xl">
            <p>No</p>
          </div>
        )
      },
    },
    {
      title: (
        <div
          className={`flex items-center gap-x-1 cursor-pointer test-xs ${
            sort === 'currentcheckinasc' || sort === 'currentcheckindesc'
              ? 'text-buttonTheme'
              : ''
          }`}
        >
          Current Checkin
          <span
            className="flex items-center justify-center"
            style={{
              transform:
                sort !== 'currentcheckindesc'
                  ? 'rotateX(180deg)'
                  : 'rotateX(0deg)',
            }}
            onClick={() => {
              if (sort === 'currentcheckinasc') {
                setSort('currentcheckindesc')
              } else {
                setSort('currentcheckinasc')
              }
            }}
          >
            <Icon icon="prime:sort-amount-up" height={24} width={24}/>
          </span>
        </div>
      ),
      dataIndex: 'currenCheckin',
      key: 'currenCheckin',
      render: (_, record) => {
        return record.currenCheckin !== 'No reservation' ? (
          <div className="flex justify-center mx-2 rounded-xl">
            <p>{format(new Date(record.currenCheckin), 'dd/MM/yyyy')}</p>
          </div>
        ) : (
          <div className="bg-yellow-400 flex justify-center mx-2 rounded-xl">
            <p>N/A</p>
          </div>
        )
      },
    },
    {
      title: (
        <div
          className={`flex items-center gap-x-1 cursor-pointer test-xs ${
            sort === 'currentcheckoutasc' || sort === 'currentcheckoutdesc'
              ? 'text-buttonTheme'
              : ''
          }`}
        >
          Current checkout
          <span
            className="flex items-center justify-center"
            style={{
              transform:
                sort !== 'currentcheckoutdesc'
                  ? 'rotateX(180deg)'
                  : 'rotateX(0deg)',
            }}
            onClick={() => {
              if (sort === 'currentcheckoutasc') {
                setSort('currentcheckoutdesc')
              } else {
                setSort('currentcheckoutasc')
              }
            }}
          >
            <Icon icon="prime:sort-amount-up" height={24} width={24}/>
          </span>
        </div>
      ),
      dataIndex: 'currentCheckout',
      key: 'currentCheckout',
      render: (_, record) => {
        return record.currentCheckout !== 'No reservation' ? (
          <div className="flex justify-center mx-2 rounded-xl">
            <p>{format(new Date(record.currentCheckout), 'dd/MM/yyyy')}</p>
          </div>
        ) : (
          <div className="bg-yellow-400 flex justify-center mx-2 rounded-xl">
            <p>N/A</p>
          </div>
        )
      },
    },
    {
      title: (
        <div
          className={`flex items-center gap-x-1 cursor-pointer test-xs ${
            sort === 'nextcheckinasc' || sort === 'nextcheckindesc'
              ? 'text-buttonTheme'
              : ''
          }`}
        >
          Next Checkin
          <span
            className="flex items-center justify-center"
            style={{
              transform:
                sort !== 'nextcheckindesc' ? 'rotateX(180deg)' : 'rotateX(0deg)',
            }}
            onClick={() => {
              if (sort === 'nextcheckinasc') {
                setSort('nextcheckindesc')
              } else {
                setSort('nextcheckinasc')
              }
            }}
          >
            <Icon icon="prime:sort-amount-up" height={24} width={24}/>
          </span>
        </div>
      ),
      dataIndex: 'nextCheckin',
      key: 'nextCheckin',
      render: (_, record) => {
        return record.nextCheckin !== 'No reservation' ? (
          <div className="bg-green-200 flex justify-center mx-2 rounded-xl">
            <p>{format(new Date(record.nextCheckin), 'dd/MM/yyyy')}</p>
          </div>
        ) : (
          <div className="bg-yellow-400 flex justify-center mx-2 rounded-xl">
            <p>N/A</p>
          </div>
        )
      },
    },
    {
      title: (
        <div
          className={`flex items-center gap-x-1 cursor-pointer test-xs ${
            sort === 'nextcheckoutasc' || sort === 'nextcheckoutdesc'
              ? 'text-buttonTheme'
              : ''
          }`}
        >
          Next Checkout
          <span
            className="flex items-center justify-center"
            style={{
              transform:
                sort !== 'nextcheckoutdesc'
                  ? 'rotateX(180deg)'
                  : 'rotateX(0deg)',
            }}
            onClick={() => {
              if (sort === 'nextcheckoutasc') {
                setSort('nextcheckoutdesc')
              } else {
                setSort('nextcheckoutasc')
              }
            }}
          >
            <Icon icon="prime:sort-amount-up" height={24} width={24}/>
          </span>
        </div>
      ),
      dataIndex: 'nextCheckout',
      key: 'nextCheckout',
      render: (_, record) => {
        return record.nextCheckout !== 'No reservation' ? (
          <div className="bg-green-200 flex justify-center mx-5 rounded-xl">
            <p>{format(new Date(record.nextCheckout), 'dd/MM/yyyy')}</p>
          </div>
        ) : (
          <div className="bg-yellow-400 flex justify-center mx-5 rounded-xl">
            <p>N/A</p>
          </div>
        )
      },
    },
  ]

  if (profile._id && !hasReadAccess) {
    return <NoAccess/>
  }

  return (
    <div className="p-5">
      <TopBar
        available={available}
        setAvailable={setAvailable}
        count={data.length}
        setName={setName}
      />

      {loading ? (
        <div
          className="h-[50vh] bg-white rounded-lg flex justify-center items-center">
          <Loading/>
        </div>
      ) : (
        <div className="flex flex-col gap-4 mt-4">
          <Table
            loading={loading}
            dataSource={data}
            columns={columns}
            pagination={{ defaultPageSize: 50 }}
            scroll={{ x: 1000 }}
            rowKey="allocatedProperty"
          />
        </div>
      )}
    </div>
  )
}
