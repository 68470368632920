import { Dropdown,Button, Space, DatePicker, Input } from "antd"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import CreateAndEdit from "./createAndEdit";

export const manageStatementBody=(items)=>{
    return (
        <>
            <div className='flex justify-between'>
                <div className='flex-col'>
                    <p>Search by name</p>
                    <Input className="bg-transparent"/>
                </div>
                <div className='flex-col'>
                    <p>Assigned Owners</p>
                    <Dropdown menu={{
                        items,
                        selectable: true,
                    }} trigger={['click']}>
                    <Button className='text-themeGrey'>
                        <Space size={50}>
                        Assigned Owners
                            <KeyboardArrowDownIcon />
                        </Space>
                    </Button>
                    </Dropdown>
                </div>
                <div className='flex-col'>
                    <p>Included Listings</p>
                    <Dropdown menu={{
                       items,
                       selectable: true,
                     }} trigger={['click']}>
                    <Button className='text-themeGrey'>
                        <Space size={125}>
                        Listings
                            <KeyboardArrowDownIcon />
                        </Space>
                    </Button>
                    </Dropdown>
                </div>
                <div className='flex-col'>
                    <p>From</p>
                    <DatePicker className='bg-transparent w-48' placeholder="From"/>
                </div>
                <div className='flex-col'>
                    <p>To</p>
                    <DatePicker className='bg-transparent w-48' placeholder="To"/>
                </div>
                <div className='flex-col'>
                    <p>Order By</p>
                    <Dropdown menu={{
                        items,
                        selectable: true,
                    }} trigger={['click']}>
                    <Button className='text-themeGrey'>
                        <Space size={50}>
                        Last saved (desc)
                            <KeyboardArrowDownIcon />
                        </Space>
                    </Button>
                    </Dropdown>
                </div>
                <div className='flex-col'>
                    <p>Status</p>
                    <Dropdown menu={{
                        items,
                        selectable: true,
                    }} trigger={['click']}> 
                    <Button className='text-themeGrey'>
                        <Space size={40}>
                        Current
                            <KeyboardArrowDownIcon />
                        </Space>
                    </Button>
                    </Dropdown>
                </div>
                <Button type='primary' className='bg-labelGreen text-themeGreen font-semibold mt-5 hover:!bg-buttonHoverTheme'>
                  Reset
                </Button>
            </div>
            <div className="flex justify-center mt-72">
                <span className="mt2">
                    <Button className="text-themeGreen font-semibold mb-2 px-1 py-0" type="text">Learn more</Button>
                    about how to add & manage Owner statements
                </span>
            </div>
        </>
    )
}
function ManageStatements() {
  return (
    <CreateAndEdit/>
  )
}

export default ManageStatements
