import { Modal, Upload } from "antd";
import React, { useState } from "react";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

const ImportExpensesAndExtrasModal = ({ isModalOpen, setIsModalOpen }) => {
  const [imageUrl, setImageUrl] = useState("");

  return (
    <div id="expenses-modal">
      <Modal
        title={
          <div className="text-bold text-black text-xl pb-5 border-b-2 border-creme">
            Import expenses and extras
          </div>
        }
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        width={620}
      >
        <div className="py-5 px-4">
          <div className="text-black">
            You can import many expenses and extras at once by uploading a csv.
          </div>
          <div className="grid grid-cols-8 h-14 w-[60%] border border-themeGreen rounded-md mt-5">
            <div className="col-span-2 bg-selectedGreen text-themeGreen flex items-center justify-center">
              <InsertDriveFileIcon />
            </div>
            <div className="col-span-6 text-themeGreen flex items-center ml-1">
              Spreadsheets with example
            </div>
          </div>
          <Upload
            name="avatar"
            className="my-5 w-full flex items-center justify-center border-2 border-themeGrey border-dotted"
            showUploadList={false}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                className="w-full"
                alt="avatar"
                style={{
                  width: "100%",
                }}
              />
            ) : (
              <div className="text-sm">
                We recommend to download it as a Microsoft Excel file (.xlsx) or
                use Google spreadsheets to fill it. The notes and validations
                applying to the template should help you to fill the data with
                the correct information and format. The outcome should be a CSV
                file.
              </div>
            )}
          </Upload>

          <div className="mt-5 mb-2 font-bold">Some important notes:</div>
          <ul>
            <li>The name of the columns must be respected</li>
            <li>All mandatory fields must be filled</li>
            <li>The csv must be comma-separated</li>
            <li>Please respect the format from the template</li>
          </ul>
        </div>
      </Modal>
    </div>
  );
};

export default ImportExpensesAndExtrasModal;
