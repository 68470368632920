import React, { useEffect, useState, useContext } from "react";
import { Pagination, Select, Table } from "antd";
import { format } from "date-fns"

import CustomDropdown from "../../components/Dropdown/Dropdown";
import CustomInput from "../../components/Input/Input";
import { AppContext } from "../../AppContext";
import NoAccess from "../../components/NoAccess";
import { apiInstance } from "../../utils/API";
import Loading from "../../components/Loaders/Loading";

let timeOut = null;

const columns = [
  {
    title: "User Name",
    dataIndex: "userName",
    key: "userName",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
  {
    title: "Listing Name",
    dataIndex: "listingName",
    key: "listingName",
  },
  {
    title: "Old Entry",
    dataIndex: "from",
    key: "from",
  },
  {
    title: "New Entry",
    dataIndex: "to",
    key: "to",
  },
  {
    title: "Time",
    dataIndex: "createdAt",
    key: "createdAt",
  },
];
const Actions = () => {
  const profile = useContext(AppContext).profile || {};
  const isAdmin = profile.isAdmin;
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [type, setType] = useState("all");
  const [filterType, setFilterType] = useState("listingName");
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (isAdmin) {
      getActions({ pageSize: 20 });
    }
  }, [isAdmin]);

  const getActions = async (params = {}, onlyList) => {
    try {
      setLoading(true);
      let pageSize_ = pageSize;
      let page_ = page;
      let type_ = type;
      let filterType_ = filterType;
      let search_ = search;

      if (params.hasOwnProperty("pageSize")) {
        pageSize_ = params.pageSize;
        setPageSize(params.pageSize);
      }

      if (params.hasOwnProperty("search")) {
        search_ = params.search;
      }

      if (params.hasOwnProperty("filterType")) {
        filterType_ = params.filterType || "";
        setFilterType(params.filterType || "");
      }

      if (params.hasOwnProperty("type")) {
        type_ = params.type || "";
        setType(params.type || "");
      }

      if (params.hasOwnProperty("page")) {
        page_ = params.page;
        setPage(params.page);
      } else {
        page_ = 1;
        setPage(1);
      }

      let url = "";
      if (pageSize_) {
        url = url + "pageSize=" + pageSize_ + "&";
      }
      if (filterType_) {
        url = url + "filterType=" + filterType_ + "&";
      }
      if (type_ !== "all") {
        url = url + "type=" + type_ + "&";
      }
      if (search_) {
        url = url + "search=" + search_ + "&";
      }

      if (!onlyList) {
        const countUrl = `/api/actions/count?${url}`;
        const countRes = await apiInstance.get(countUrl);
        setCount(countRes?.data?.count || 0);
      }

      apiInstance
        .get(`/api/actions?page=${page_}&${url}`)
        .then((response) => {
          if (response.status === 200) {
            setActions(response?.data?.notifications);
            setLoading(false);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    }
  };

  if (profile._id && !isAdmin) {
    return <NoAccess />;
  }
  return (
    <div className="p-5">
      <div className="flex flex-wrap md:flex-nowrap items-baseline mb-5 gap-2 md:gap-5">
        <CustomDropdown
          // calendars
          options={[
            { label: "All", value: "all" },
            { label: "Listings", value: "listing" },
            { label: "Reservations", value: "reservation" },
            { label: "Calendars", value: "calendars" },
            { label: "Cleaning", value: "cleaning" },
          ]}
          inputLabel={"Column Name"}
          showSearch={false}
          handleChange={(e) => {
            getActions({ type: e });
          }}
          value={type}
        />
        <CustomDropdown
          options={[
            { label: "User Name", value: "userName" },
            { label: "Listing Name", value: "listingName" },
          ]}
          inputLabel={"Column Name"}
          showSearch={false}
          handleChange={(e) => {
            getActions({ filterType: e });
          }}
          value={filterType}
        />
        <CustomInput
          onChange={(e) => {
            setSearch(e.target.value);
            if (timeOut) {
              clearTimeout(timeOut);
            }
            timeOut = setTimeout(() => {
              getActions({ search: e.target.value });
            }, 1000);
          }}
          value={search}
          inputLabel={"Search"}
          placeholder={"Search here"}
        />
      </div>
      {loading ? (
        <div className="h-[50vh] bg-white rounded-lg flex justify-center items-center">
          <Loading />
        </div>
      ) : (
        <Table
          loading={loading}
          dataSource={actions.map((item, i) => ({
            ...item,
            key: i,
            createdAt: item.createdAt
              ? format(new Date(item.createdAt), 'dd/MM/yyyy HH:mm')
              : "--",
            from: item.from ? item.from : "--",
            to: item.to ? item.to : "--",
          }))}
          columns={columns}
          pagination={false}
          scroll={{
            x: 1000,
            y: 500,
          }}
        />
      )}
      <div className="flex flex-wrap gap-3 md:items-center items-start justify-end my-4">
        <Select
          value={pageSize}
          options={[
            { value: 20, label: 20 },
            { value: 50, label: 50 },
            { value: 100, label: 100 },
          ]}
          className="w-max"
          onChange={(selectedOption) =>
            getActions({ pageSize: selectedOption })
          }
        />
        <Pagination
          defaultCurrent={page}
          current={page}
          total={count}
          pageSize={pageSize}
          showSizeChanger={false}
          onChange={(e) => {
            getActions({ page: e }, true);
          }}
        />
      </div>
    </div>
  );
};

export default Actions;
