import { Button } from "antd";

import Tasks from "..";
import manageTasksBG from "../../../assets/manageTasksBG.jpg";
import { archivedBody } from "../Archived/Archived";
import { manageTasksBody } from "../ManageTasksPage/ManageTasks";
import { checklistTemplatesBody } from "../ChecklistTemplates/ChecklistTemplates";

export const manageAutoTasksBody = (setAddAutoTask) => {
  return (
    <div className="flex flex-col justify-center items-center w-full h-auto gap-y-2">
      <img src={manageTasksBG} />
      <p className="font-bold text-xl">
        Things are more satisfying when they are organized
      </p>
      <p className="text-lg font-medium">
        Create tasks triggered by events with due dates. You can assign tasks to
        an individual or to a group/company
      </p>
      <p className="text-base">
        You can also add reminders or assign tasks to yourself to do later on
      </p>
      <div>
        <Button
          type="primary"
          className="bg-buttonTheme font-semibold hover:!bg-buttonHoverTheme"
          onClick={() => setAddAutoTask(true)}
        >
          Add auto-task
        </Button>
      </div>
    </div>
  );
};

function ManageAutoTasks() {
  return (
    <Tasks
      manageTasksBody={manageTasksBody}
      manageAutoTasksBody={manageAutoTasksBody}
      checklistTemplatesBody={checklistTemplatesBody}
      archivedBody={archivedBody}
      activeKey={2}
    />
  );
}

export default ManageAutoTasks;
