import React from "react";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";

const DocumentTemplateReceipt = ({ isSelected }) => {
  return (
    <div className="bg-blue-600">
      <div className="bg-blue-600 h-32 px-10 flex items-center justify-between">
        <div className="flex flex-col gap-3">
          <div className="font-bold text-sm bg-glass w-28 text-themeGrey">
            Your logo here
          </div>
          <div className="text-xl text-white font-extrabold">
            Receipt #0134524
          </div>
        </div>
        <div className="text-white font-thin text-xs flex flex-col">
          <div>Issue date: 1 September 2021</div>
          <div>Tax ID: Listing tax ID will be here</div>
        </div>
      </div>
      <div className="bg-white h-96 receipt-round pt-20 pl-24 pr-24">
        <div>
          <div className="font-bold text-xl">
            Little House on the Prairie with community pool
          </div>
          <div className="text-themeGrey font-xs mt-3 mb-10">
            555 Main St. Norton, Indiana
          </div>
        </div>
        <div className="grid xs:grid-cols-1 md:grid-cols-2 gap-4 pb-10 border-b-4 border-purple-500">
          {/* Reservation ID */}
          <div>
            <div className="text-themeGrey flex gap-1">
              <div className="font-bold">Reservation ID:</div>
              <div>123123123</div>
            </div>
          </div>
          {/* Host name */}
          <div>
            <div className="text-themeGrey flex gap-1">
              <div className="font-bold flex items-center gap-1">
                <PersonOutlineOutlinedIcon
                  className="text-pink-600"
                  style={{ fontSize: 20 }}
                />
                Host name:
              </div>
              <div>Sid flexliving api account</div>
            </div>
          </div>
          {/* Calendar */}
          <div>
            <div className="text-themeGrey flex gap-1">
              <div className="font-bold flex items-center gap-1">
                <CalendarMonthOutlinedIcon
                  className="text-pink-600"
                  style={{ fontSize: 20 }}
                />
              </div>
              <div>Monday, 4 April - Friday, 8 April 5 Nights</div>
            </div>
          </div>
          {/* Phone */}
          <div>
            <div className="text-themeGrey flex gap-1">
              <div className="font-bold flex items-center gap-1">
                <LocalPhoneOutlinedIcon
                  className="text-pink-600"
                  style={{ fontSize: 20 }}
                />
                Phone:
              </div>
              <div>+33619458501</div>
            </div>
          </div>
          {/* Guest */}
          <div>
            <div className="text-themeGrey flex gap-1">
              <div className="font-bold flex items-center gap-1">
                <Groups2OutlinedIcon
                  className="text-pink-600"
                  style={{ fontSize: 20 }}
                />
                Guests:
              </div>
              <div>2 Adults</div>
            </div>
          </div>
          {/* Email */}
          <div>
            <div className="text-themeGrey flex gap-1">
              <div className="font-bold flex items-center gap-1">
                <EmailOutlinedIcon
                  className="text-pink-600"
                  style={{ fontSize: 20 }}
                />
                Email:
              </div>
              <div className="text-pink-500">yousfisid@gmail.com</div>
            </div>
          </div>
          {/* Guest name */}
          <div>
            <div className="text-themeGrey flex gap-1">
              <div className="font-bold flex items-center gap-1">
                <DnsOutlinedIcon
                  className="text-pink-600"
                  style={{ fontSize: 20 }}
                />
                Guest name:
              </div>
              <div>John Doe</div>
            </div>
          </div>
        </div>
        <div className="my-10 grid xs:grid-cols-1 md:grid-cols-2 gap-10">
          <div>
            <div className="font-extrabold text-xl">Price breakdown</div>
            <div className="grid grid-cols-2 gap-2 pb-10 border-b-2 border-blue-500 border-dashed">
              <div>$102 x 5 nights</div>
              <div className="flex justify-end">$510</div>
              <div>Cleaning fee</div>
              <div className="flex justify-end">$50</div>
              <div>VAT</div>
              <div className="flex justify-end">$40</div>
            </div>
            <div className="font-extrabold text-xl mt-5 mb-3">Extras</div>
            <div className="grid grid-cols-2 gap-2 pb-5  border-b-2 border-blue-500 border-dashed">
              <div>Extra parking</div>
              <div className="flex justify-end">$50</div>
              <div>Pet amenities</div>
              <div className="flex justify-end">$50</div>
              <div>Fresh flowers</div>
              <div className="flex justify-end">$20</div>
            </div>
            <div className="mt-10 grid grid-cols-2 gap-2 font-extrabold text-xl md:mb-20">
              <div>Total Price</div>
              <div className="flex justify-end">$720</div>
            </div>
          </div>

          <div>
            <div>
              {isSelected && (
                <>
                  <div className="font-extrabold text-xl mb-5">
                    Payment Method
                  </div>
                  <div className="flex items-center justify-start gap-3">
                    <CreditCardOutlinedIcon
                      style={{ fontSize: 34 }}
                      className="text-pink-600"
                    />
                    <div>
                      <div>Card ending in 5523</div>
                      <div>22 Apr 22, 10:24 AM</div>
                    </div>
                  </div>
                  <div className="mt-10 grid grid-cols-2 gap-2 font-extrabold text-xl xs:mb-5 md:mb-20">
                    <div>Amount Paid</div>
                    <div className="flex justify-end">$340</div>
                  </div>
                </>
              )}
              <div>
                <div className="font-extrabold text-xl">
                  Cancellation Policy
                </div>
                <div className="text-themeGrey my-5">
                  According to cancellation policy on Booking.com
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentTemplateReceipt;
