import { InputNumber, Input, Select, Switch } from "antd";

// Input types for Ant Design.
export const InputType = {
  SELECT: "select",
  TEXT: "text",
  STRING: "string",
  NUMBER: "number",
  SWITCH: "switch",
  EMAIL: "email",
};

// Input Types for Ant Design Editable Table.
export const editableTableInputConfig = (inputProps) => ({
  [InputType.NUMBER]: <InputNumber {...inputProps} />,
  [InputType.STRING]: <Input {...inputProps} />,
  [InputType.EMAIL]: <Input {...inputProps} />,
  [InputType.SELECT]: <Select {...inputProps} />,
  [InputType.SWITCH]: <Switch {...inputProps} />,
});

// Marketplace Filter Options

export const marketplaceFilterOptions = [
  {
    value: "1",
    label: "Accounting",
  },
  {
    value: "2",
    label: "Automation",
  },
  {
    value: "3",
    label: "Blockchain Technology",
  },
  {
    value: "4",
    label: "Booking Channel",
  },
  {
    value: "5",
    label: "Booking Engines",
  },
  {
    value: "6",
    label: "Booking Websites",
  },
];

export const userManagementAccessPermissionsShowOptions = [
  {
    value: "1",
    label: "Total Price",
  },
  {
    value: "2",
    label: "Owner Payout",
  },
  {
    value: "3",
    label: "Pm Commission",
  },
  {
    value: "4",
    label: "Rental Revenue",
  },
  {
    value: "5",
    label: "Sub Total Price",
  },
  {
    value: "6",
    label: "Total Guest Fees",
  },
  {
    value: "7",
    label: "Total Fax",
  },
];

export const modalTypes = {
  QUICK_TAGGING: "quickTagging",
  INTERNAL_LISTING: "internalListing",
  EXTERNAL_LISTING: "externalListing",
  ICAL: "iCal",
  DELETE: "delete",
  DELETE_CUSTOM_FIELDS: "deleteCustomFields",
};

export const exportCSVOptions = [
  {
    value: "all",
    label: "All data",
  },
  {
    value: "filtered",
    label: "Filtered data",
  },
];

export const paymentMethodOptions = [
  { value: "creditCard", label: "Credit Card" },
  { value: "check", label: "Check" },
  { value: "bankTransfer", label: "Bank Transfer" },
  { value: "cash", label: "Cash" },
  { value: "paypal", label: "PayPal" },
  { value: "vcc", label: "VCC" },
  { value: "venmo", label: "Venmo" },
  { value: "otaPayment", label: "OTA Payment" },
  { value: "other", label: "Other" },
];

export const autoChargesTypes = [
  { value: "charge", label: "Charge" },
  { value: "preAuthorization", label: "Pre authorization" },
];

export const autoChargesAmountType = [
  {
    value: "flat",
    label: "Flat",
  },
  {
    value: "percentage",
    label: "Percentage",
  },
];

export const autoChargesScheduledByOptions = [
  {
    value: "before",
    label: "Before",
  },
  {
    value: "after",
    label: "After",
  },
  {
    value: "at",
    label: "At",
  },
];

export const autoChargesReservationTypes = [
  { value: "reservationCreation", label: "Reservation Creations" },
  { value: "checkin", label: "Check-in" },
  { value: "checkout", label: "Check-out" },
];

export const documentTemplatesCustomFieldOptions = [
  { value: "listingCustomFields", label: "Listing custom fields" },
  { value: "ReservationCustomFields", label: "Reservation custom fields" },
];

export const downloadFinancialReportOptions = [
  {
    value: "all",
    label: "All data",
  },
  {
    value: "filtered",
    label: "Currently filtered data",
  },
];

export const listingsFinancialsReportOptions = [
  {
    value: "reservationLineItem",
    label: "Reservation line item",
  },
  {
    value: "consolidatedReport",
    label: "Consolidated Report",
  },
  {
    value: "listingFinancialsReport",
    label: "Listing financials report",
  },
];

export const quickDatesOptions = [
  {
    value: "last12Months",
    label: "Last 12 months",
  },
  {
    value: "last3Months",
    label: "Last 3 months",
  },
  {
    value: "last12Months",
    label: "Last 12 months",
  },
  {
    value: "last30Days",
    label: "Last 30 days",
  },
  {
    value: "last7Days",
    label: "Last 7 days",
  },
  {
    value: "today",
    label: "Today",
  },
  {
    value: "YTD",
    label: "TTD",
  },
];

export const channelOptions = [
  {
    value: "airbnb",
    label: "AirBnb",
  },
  {
    value: "booking.com",
    label: "Booking.com",
  },
  {
    value: "bookingEngine",
    label: "Booking Engine",
  },
  {
    value: "google",
    label: "Google",
  },
  {
    value: "customiCal",
    label: "Custom iCal",
  },
  {
    value: "hostawayDirect",
    label: "Flexliving Direct",
  },
  {
    value: "expedia",
    label: "Expedia",
  },
  {
    value: "vrbo",
    label: "Vrbo",
  },
  {
    value: "homeawayiCal",
    label: "Homeaway iCal",
  },
  {
    value: "vrboiCal",
    label: "Vrbo iCal",
  },
  {
    value: "wordpress",
    label: "Wordpress",
  },
  {
    value: "marriott",
    label: "Marriott",
  },
  {
    value: "partners",
    label: "Partners",
  },
  {
    value: "tripAdvisorsiCal",
    label: "TripAdvisor iCal",
  },
];

export const listingFinancialsStatusOptions = [
  {
    value: "new",
    label: "New",
  },
  {
    value: "modified",
    label: "Modified",
  },
  {
    value: "pending",
    label: "Pending",
  },
  {
    value: "awaitingPayment",
    label: "Awaiting Payment",
  },
  {
    value: "awaitingGuestVerification",
    label: "Awaiting Guest Verification",
  },
  {
    value: "unconfirmed",
    label: "Unconfirmed",
  },
  {
    value: "ownerStay",
    label: "Owner stay",
  },
  {
    value: "confirmed",
    label: "Confirmed",
  },
  {
    value: "cancelled",
    label: "Cancelled",
  },
  {
    value: "declined",
    label: "Declined",
  },
  {
    value: "expired",
    label: "Expired",
  },
  {
    value: "inquiry",
    label: "Inquiry",
  },
];

export const listingFinancialsEventOptions = [
  {
    value: "checkin",
    label: "Check-in",
  },
  {
    value: "checkout",
    label: "Check-out",
  },
  {
    value: "reservationCreated",
    label: "Reservation created",
  },
  {
    value: "calendar",
    label: "Calendar",
  },
];

export const rentalActivityOptions = [
  {
    value: "perReservation",
    label: "Per reservation",
  },
  {
    value: "perListing",
    label: "Per Listing",
  },
];

export const rentalRevenueOptions = [
  {
    value: "totalPrice",
    label: "Total Price",
  },
  {
    value: "ownerPayout",
    label: "Owner Payout",
  },
  {
    value: "pmCommission",
    label: "Pm Commission",
  },
  {
    value: "rentalRevenue",
    label: "Rental Revenue",
  },
  {
    value: "subTotalPrice",
    label: "Sub Total Price",
  },
  {
    value: "totalGuestFees",
    label: "Total Guest Fees",
  },
  {
    value: "totalTax",
    label: "Total Tax",
  },
];

export const financialChannelOptions = [
  {
    value: "perChannel",
    label: "Per Channel",
  },
  {
    value: "ownChannel",
    label: "Own Channel",
  },
];

export const financialChartOptions = [
  {
    value: "bar",
    label: "Bar Chart",
  },
  {
    value: "line",
    label: "Line Chart",
  },
];

export const reservationDataLimitoptions = [
  { value: 20, label: 20 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
  { value: 200, label: 200 },
  { value: 300, label: 300 },
  { value: 500, label: 500 },
];

export const reservationExportOptions = [
  { value: "CSV", label: "CSV" },
  { value: "CSVAllFields", label: "CSV (All fields)" },
];

export const permissionTemplateOptions = [
  { value: "Admin", label: "Admin" },
  { value: "Manager", label: "Manager" },
  { value: "Assistant", label: "Assistant" },
  { value: "Finance", label: "Finance" },
  { value: "Property Owner", label: "Property Owner" },
  { value: "Cleaner", label: "Cleaner" },
];

export const addAutomationItems = [
  {
    key: "1",
    label: "Number of Guests",
    numberOfGuestsCondition: "",
    numberOfGuestsFrom: "",
    numberOfGuestsTo: "",
    NumberOfGuestsValue: "",
  },
  {
    key: "2",
    label: "Listing is available",
    listingIsAvailableValue: "",
    listingIsAvailableFrom: "",
    listingIsAvailableTo: "",
    listingIsAvailableEvent: "",
  },
  {
    key: "3",
    label: "Advance time",
    advanceTimeCondition: "",
    advanceTimeValue: "",
    advanceTimeUnit: "",
  },
  {
    key: "4",
    label: "Reservation custom field",
    reservationCustomField: "",
    reservationCustomFieldCondition: "",
    reservationCustomFieldValue: "",
  },
  {
    key: "5",
    label: "Rental agreement",
    rentalAgreementCondition: "",
    rentalAgreementValue: "",
  },
  {
    key: "6",
    label: "Payment status",
    paymentStatusCondition: "",
    paymentStatusValue: "",
  },
];

export const numberOfNightConditionOptions = [
  {
    key: "equals",
    value: "equals",
    label: "Equals",
  },
  {
    key: "doesNotEqual",
    value: "doesNotEqual",
    label: "Does not equal",
  },
  {
    key: "isEqualOrMoreThan",
    value: "isEqualOrMoreThan",
    label: "Is equal or more than",
  },
  {
    key: "isEqualOrLessThan",
    value: "isEqualOrLessThan",
    label: "Is equal or less than",
  },
  {
    key: "isBetween",
    value: "isBetween",
    label: "Is between",
  },
];

export const listingIsAvailableConditionOptions = [
  {
    key: "equals",
    value: "equals",
    label: "Equals",
  },

  {
    key: "isEqualOrMoreThan",
    value: "isEqualOrMoreThan",
    label: "Is equal or more than",
  },
  {
    key: "isBetween",
    value: "isBetween",
    label: "Is between",
  },
];

export const listingIsAvailableEventOptions = [
  {
    key: "beforeStay",
    value: "beforeStay",
    label: "Before stay",
  },

  {
    key: "afterStay",
    value: "afterStay",
    label: "After stay",
  },
  {
    key: "beforeOrAfterStay",
    value: "beforeOrAfterStay",
    label: "Before or after stay",
  },
  {
    key: "beforeAndAfterStay",
    value: "beforeAndAfterStay",
    label: "Before and after stay",
  },
];

export const advanceTimeConditionOptions = [
  {
    key: "moreThan",
    value: "moreThan",
    label: "More than",
  },

  {
    key: "lessThan",
    value: "lessThan",
    label: "Less than",
  },
];

export const advanceTimeUnitOptions = [
  {
    key: "minutes",
    value: "minutes",
    label: "Minutes",
  },
  {
    key: "hours",
    value: "hours",
    label: "Hours",
  },
  {
    key: "days",
    value: "days",
    label: "Days",
  },
];

export const reservationCustomFieldOptions = [
  {
    key: "asdasdasd",
    value: "asdasdasd",
    label: "asdasdasd",
  },
];

export const reservationCustomFieldConditionOptions = [
  {
    key: "is",
    value: "is",
    label: "is",
  },
  {
    key: "isNot",
    value: "isNot",
    label: "is not",
  },
  {
    key: "contains",
    value: "contains",
    label: "contains",
  },
  {
    key: "isEmpty",
    value: "isEmpty",
    label: "is empty",
  },
  {
    key: "isNotEmpty",
    value: "isNotEmpty",
    label: "is not empty",
  },
];

export const rentalAgreementConditionOptions = [
  {
    key: "is",
    value: "is",
    label: "is",
  },
];

export const rentalAgreementValueOptions = [
  {
    key: "signed",
    value: "signed",
    label: "Signed",
  },
  {
    key: "notSigned",
    value: "notSigned",
    label: "Not signed",
  },
  {
    key: "notRequired",
    value: "notRequired",
    label: "Not required",
  },
];

export const paymentStatusConditionOptions = [
  {
    key: "is",
    value: "is",
    label: "is",
  },
  {
    key: "isNot",
    value: "isNot",
    label: "is not",
  },
];

export const paymentStatusValueOptions = [
  {
    key: "paid",
    value: "paid",
    label: "Paid",
  },
  {
    key: "partiallyPaid",
    value: "partiallyPaid",
    label: "Partially paid",
  },
  {
    key: "unpaid",
    value: "unpaid",
    label: "Unpaid",
  },
  {
    key: "unknown",
    value: "unknown",
    label: "Unknown",
  },
];

export const standardFieldsOptions = [
  {
    key: "address",
    label: "{{address}}",
    value: "{{address}}",
  },
  {
    key: "airbnbCancellationPolicy",
    label: "{{airbnb_cancellation_policy}}",
    value: "{{airbnb_cancellation_policy}}",
  },
  {
    key: "airbnbListingBasePrice",
    label: "{{airbnb_listing_base_price}}",
    value: "{{airbnb_listing_base_price}}",
  },
  {
    key: "airbnbListingCancellation",
    label: "{{airbnb_listing_cancellation}}",
    value: "{{airbnb_listing_cancellation}}",
  },
  {
    key: "airbnbExpectedPayoutAmount",
    label: "{{airbnb_expected_payout_amount}}",
    value: "{{airbnb_expected_payout_amount}}",
  },
  {
    key: "airbnbListingCancellationHostFee",
    label: "{{airbnb_listing_cancellation_host_fee}}",
    value: "{{airbnb_listing_cancellation_host_fee}}",
  },
  {
    key: "airbnbListingCancellationPayout",
    label: "{{airbnb_listing_cancellation_payout}}",
    value: "{{airbnb_listing_cancellation_payout}}",
  },
  {
    key: "airbnbListingCleaningFee",
    label: "{{airbnb_listing_cleaning_fee}}",
    value: "{{airbnb_listing_cleaning_fee}}",
  },
  {
    key: "airbnbListingHostFee",
    label: "{{airbnb_listing_host_fee}}",
    value: "{{airbnb_listing_host_fee}}",
  },
  {
    key: "airbnbListingSecurityPrice",
    label: "{{airbnb_listing_security_price}}",
    value: "{{airbnb_listing_security_price}}",
  },
  {
    key: "airbnbOccupancyTaxPaidAmount",
    label: "{{airbnb_occupancy_tax_paid_amount}}",
    value: "{{airbnb_occupancy_tax_paid_amount}}",
  },
  {
    key: "airbnbTotalPaidAmount",
    label: "{{airbnb_total_paid_amount}}",
    value: "{{airbnb_total_paid_amount}}",
  },
  {
    key: "airbnbTransientOccupancyTaxPaidAmount",
    label: "{{airbnb_transient_occupancy_tax_paid_amount}}",
    value: "{{airbnb_transient_occupancy_tax_paid_amount}}",
  },
  {
    key: "balanceDue",
    label: "{{balance_due}}",
    value: "{{balance_due}}",
  },
  {
    key: "cancellationDate",
    label: "{{cancellation_date}}",
    value: "{{cancellation_date}}",
  },
  {
    key: "ccExpirationMonth",
    label: "{{cc_expiration_month}}",
    value: "{{cc_expiration_month}}",
  },
  {
    key: "ccExpirationYear",
    label: "{{cc_expiration_year}}",
    value: "{{cc_expiration_year}}",
  },
  {
    key: "ccName",
    label: "{{cc_name}}",
    value: "{{cc_name}}",
  },
  {
    key: "ccNumberEndingDigits",
    label: "{{cc_number_ending_digits}}",
    value: "{{cc_number_ending_digits}}",
  },
  {
    key: "channelName",
    label: "{{channel_name}}",
    value: "{{channel_name}}",
  },
  {
    key: "channelPropertyId",
    label: "{{channel_property_id}}",
    value: "{{channel_property_id}}",
  },
  {
    key: "channelReservationId",
    label: "{{channel_reservation_id}}",
    value: "{{channel_reservation_id}}",
  },
  {
    key: "channelUnitId",
    label: "{{channel_unit_id}}",
    value: "{{channel_unit_id}}",
  },
  {
    key: "checkinDateDay",
    label: "{{checkin_date_day}}",
    value: "{{checkin_date_day}}",
  },
  {
    key: "checkinDateMonth",
    label: "{{checkin_date_month}}",
    value: "{{checkin_date_month}}",
  },
  {
    key: "checkinDateYear",
    label: "{{checkin_date_year}}",
    value: "{{checkin_date_year}}",
  },
  {
    key: "checkinDate",
    label: "{{checkin_date}}",
    value: "{{checkin_date}}",
  },
  {
    key: "checkinTime",
    label: "{{checkin_time}}",
    value: "{{checkin_time}}",
  },
  {
    key: "checkoutDateDay",
    label: "{{checkout_date_day}}",
    value: "{{checkout_date_day}}",
  },
  {
    key: "checkoutDateMonth",
    label: "{{checkout_date_month}}",
    value: "{{checkout_date_month}}",
  },
  {
    key: "checkoutDateYear",
    label: "{{checkout_date_year}}",
    value: "{{checkout_date_year}}",
  },
  {
    key: "checkoutDate",
    label: "{{checkout_date}}",
    value: "{{checkout_date}}",
  },
  {
    key: "checkoutTime",
    label: "{{checkout_time}}",
    value: "{{checkout_time}}",
  },
  {
    key: "city",
    label: "{{city}}",
    value: "{{city}}",
  },
  {
    key: "cleaningFee",
    label: "{{cleaning_fee}}",
    value: "{{cleaning_fee}}",
  },
  {
    key: "contactPersonName",
    label: "{{contact_person_name}}",
    value: "{{contact_person_name}}",
  },
  {
    key: "currency",
    label: "{{currency}}",
    value: "{{currency}}",
  },
  {
    key: "doorCodeInstruction",
    label: "{{door_code_instruction}}",
    value: "{{door_code_instruction}}",
  },
  {
    key: "doorCodeVendor",
    label: "{{door_code_vendor}}",
    value: "{{door_code_vendor}}",
  },
  {
    key: "doorCode",
    label: "{{door_code}}",
    value: "{{door_code}}",
  },
  {
    key: "guestFirstName",
    label: "{{guest_first_name}}",
    value: "{{guest_first_name}}",
  },
  {
    key: "guestLastName",
    label: "{{guest_last_name}}",
    value: "{{guest_last_name}}",
  },
  {
    key: "guestNameAnyway",
    label: "{{guest_name_anyway}}",
    value: "{{guest_name_anyway}}",
  },
  {
    key: "guestName",
    label: "{{guest_name}}",
    value: "{{guest_name}}",
  },
  {
    key: "guestNote",
    label: "{{guest_note}}",
    value: "{{guest_note}}",
  },
  {
    key: "guestPhone",
    label: "{{guest_phone}}",
    value: "{{guest_phone}}",
  },
  {
    key: "guestPortalUrl",
    label: "{{guest_portal_url}}",
    value: "{{guest_portal_url}}",
  },
  {
    key: "hostName",
    label: "{{host_name}}",
    value: "{{host_name}}",
  },
  {
    key: "hostPhone",
    label: "{{host_phone}}",
    value: "{{host_phone}}",
  },
  {
    key: "hostawayReservationId",
    label: "{{hostaway_reservation_id}}",
    value: "{{hostaway_reservation_id}}",
  },
  {
    key: "isPaid",
    label: "{{is_paid}}",
    value: "{{is_paid}}",
  },
  {
    key: "listingId",
    label: "{{listing_id}}",
    value: "{{listing_id}}",
  },
  {
    key: "listingName",
    label: "{{listing_name}}",
    value: "{{listing_name}}",
  },
  {
    key: "listingPriceForExtraPerson",
    label: "{{listing_price_for_extra_person}}",
    value: "{{listing_price_for_extra_person}}",
  },
  {
    key: "nights",
    label: "{{nights}}",
    value: "{{nights}}",
  },
  {
    key: "numberOfAdults",
    label: "{{number_of_adults}}",
    value: "{{number_of_adults}}",
  },
  {
    key: "numberOfChildren",
    label: "{{number_of_children}}",
    value: "{{number_of_children}}",
  },
  {
    key: "numberOfGuests",
    label: "{{number_of_guests}}",
    value: "{{number_of_guests}}",
  },
  {
    key: "numberOfInfants",
    label: "{{number_of_infants}}",
    value: "{{number_of_infants}}",
  },
  {
    key: "numberOfPets",
    label: "{{number_of_pets}}",
    value: "{{number_of_pets}}",
  },
  {
    key: "onlineCheckinLink",
    label: "{{online_checkin_link}}",
    value: "{{online_checkin_link}}",
  },
  {
    key: "paymentMethod",
    label: "{{payment_method}}",
    value: "{{payment_method}}",
  },
  {
    key: "payoutSum",
    label: "{{payout_sum}}",
    value: "{{payout_sum}}",
  },
  {
    key: "priceBreakDown",
    label: "{{price_break_down}}",
    value: "{{price_break_down}}",
  },
  {
    key: "rentalAgreementLink",
    label: "{{rental_agreement_link}}",
    value: "{{rental_agreement_link}}",
  },
  {
    key: "reservationDate",
    label: "{{reservation_date}}",
    value: "{{reservation_date}}",
  },
  {
    key: "reservationPriceForExtraPerson",
    label: "{{reservation_price_for_extra_person}}",
    value: "{{reservation_price_for_extra_person}}",
  },
  {
    key: "reviewUrl",
    label: "{{review_url}}",
    value: "{{review_url}}",
  },
  {
    key: "unitNames",
    label: "{{unit_names}}",
    value: "{{unit_names}}",
  },
  {
    key: "wifiPassword",
    label: "{{wifi_password}}",
    value: "{{wifi_password}}",
  },
  {
    key: "wifiUsername",
    label: "{{wifi_username}}",
    value: "{{wifi_username}}",
  },
];

export const listingFieldOptions = [
  {
    key: "listingRehan",
    label: "{{listing_rehan}}",
    value: "{{listing_rehan}}",
  },
];

export const reservationCustomFieldsOptions = [
  {
    key: "reservationAsdAsd",
    label: "{{reservation_asdasd}}",
    value: "{{reservation_asdasd}}",
  },
  {
    key: "reservationBookingChannel",
    label: "{{reservation_booking_channel}}",
    value: "{{reservation_booking_channel}}",
  },
  {
    key: "reservationblabla",
    label: "{{reservation_blabla}}",
    value: "{{reservation_blabla}}",
  },
];

export const sortByOptions = [
  {
    key: "1",
    value: "1",
    label: "Ascending",
  },
  {
    key: "-1",
    value: "-1",
    label: "Descending",
  },
];

export const eventOptions = [{ label: "Reservation", value: "reservation" }];

export const extensionOptions = [
  { label: "Yes", value: true, key: "yes" },
  { label: "No", value: false, key: "no" },
];
