import React, { useState } from "react";
import GenericButton from "../../components/GenericButton/GenericButton";
import { Avatar } from "antd";
import { Person } from "@mui/icons-material";
import KeyTwoToneIcon from "@mui/icons-material/KeyTwoTone";
import BorderColorTwoToneIcon from "@mui/icons-material/BorderColorTwoTone";
import EditProfileModal from "../../components/EditProfileModal/EditProfileModal";
import ChangePasswordModal from "../../components/ChangePasswordModal/ChangePasswordModal";

const DashboardSettingsAccount = () => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  return (
    <div className="grid xs:grid-cols-1 lg:grid-cols-2">
      <EditProfileModal
        isModalOpen={isEditModalOpen}
        setIsModalOpen={setIsEditModalOpen}
      />
      <ChangePasswordModal
        isModalOpen={isChangePasswordModalOpen}
        setIsModalOpen={setIsChangePasswordModalOpen}
      />
      <div>
        <div>
          <div className="text-themeGrey text-xl font-bold">
            Account details
          </div>
          <div className="my-5 grid xs:grid-cols-1 lg:grid-cols-2">
            <div className="flex flex-col items-center">
              <p className="text-themeGrey font-bold mb-1">Profile logo:</p>
              <Avatar shape="square" size="large" icon={<Person />} />
            </div>
            <div className="flex flex-col items-center">
              <p className="text-themeGrey font-bold mb-1">Company logo:</p>
              <Avatar shape="square" size="large" icon={<Person />} />
            </div>
          </div>
          <div className="flex flex-col gap-5 text-themeGrey">
            <div className="flex gap-1">
              Email: <div className="font-extrabold">yousfisid@gmail.com</div>
            </div>
            <div className="flex gap-1">
              Account ID: <div className="font-extrabold">61148</div>
            </div>
            <div>Name: Sid Flexliving Test Api Account</div>
            <div>Surname: not set</div>
            <div>Company Name: not set</div>
            <div>Phone: +336 19 45 85 01</div>
            <div>Emergency Phone: not set</div>
            <div>Address: not set</div>
            <div>City: not set</div>
            <div>Postal Code: not set</div>
            <div>Country: France</div>
            <div>Website: not set</div>
            <div>Invoice email: not set</div>
          </div>
          <div className="my-5 flex gap-2">
            <GenericButton
              label="Change password"
              className="bg-themeGreen rounded-none"
              icon={<KeyTwoToneIcon style={{ fontSize: 16 }} />}
              iconLeftAlign={true}
              onClick={() => {
                setIsChangePasswordModalOpen(true);
              }}
            />
            <GenericButton
              label="Edit profile"
              className="bg-themeGreen rounded-none"
              icon={<BorderColorTwoToneIcon style={{ fontSize: 16 }} />}
              iconLeftAlign={true}
              onClick={() => {
                setIsEditModalOpen(true);
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="text-themeGrey text-xl font-bold">Payment details</div>
        <div className="text-themeGrey mt-5">
          Subscribe now and get the most effective vacation rental software!
        </div>
        <div className="grid md:grid-cols-1 lg:grid-cols-2 text-themeGrey mt-5 gap-4">
          <div className="font-extrabold">Plan:</div>
          <div>Custom pricing, 9 listings</div>
          <div className="font-extrabold">Account balance:</div>
          <div>0€</div>
        </div>
        <div className="mt-5 flex items-center gap-5">
          <GenericButton
            label="Subscribe"
            className="bg-themeGreen text-white"
          />
          <GenericButton
            label="Deactivate account"
            className="bg-red-500 text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardSettingsAccount;
