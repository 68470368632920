/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import GenericButton from "../../../components/GenericButton/GenericButton";
import { Form } from "antd";
import OtherPermissions from "../../../components/UserManagement/Users/OtherPermissions";
import { manageToast } from "../../../components/Common/ManageToast";
import { USER_MANAGEMENT_GROUPS } from "../../../constants/dashboardRoutes";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AccessPermissions from "../../../components/UserManagement/Groups/AccessPermissions";
import BasicInfoDetails from "../../../components/UserManagement/Groups/BasicInfoDetails";
import { apiInstance } from "../../../utils/API";
import Loading from "../../../components/Loaders/Loading";
import NoAccess from "../../../components/NoAccess";
import { AppContext } from "../../../AppContext";

export default function GroupDetails() {
  const navigate = useNavigate();
  const profile = useContext(AppContext).profile || {};
  const hasAccess = profile.isAdmin;
  const [loading, setLoading] = useState(true);
  const [group, setGroup] = useState(null);
  const { id } = useParams();
  const [form] = Form.useForm();

  useEffect(() => {
    if (hasAccess && id) {
      fetchUser();
    } else {
      setLoading(false);
    }
  }, [id]);

  async function fetchUser() {
    try {
      setLoading(true);
      if (id) {
        const res = await apiInstance.get("/api/group-rights/" + id);
        const data = res.data;
        form.setFieldsValue(data);
        setGroup(data);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      } else {
        setLoading(false);
      }
    } catch (error) {
      const message = error?.response?.data;
      console.log(error);
      manageToast(false, `${message?.err}` || "Something went wrong.");
      setLoading(false);
    }
  }

  if (profile?._id && !hasAccess) {
    return <NoAccess />;
  }
  return (
    <div id="user-table" className=" bg-white p-5">
      <Form name="basic" autoComplete="off" form={form}>
        <div className="p-5 flex items-center justify-between border-b-2 bg-white fixed left-4 md:left-[257px] right-0 top-12  z-[999] border-creme">
          <div
            className="font-bold text-md  text-themeGreen flex items-center justify-between hover:underline cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIosIcon style={{ fontSize: 12 }} /> Groups
          </div>

          <div>
            <GenericButton
              onClick={() => {
                navigate(`${USER_MANAGEMENT_GROUPS}/${id}`);
              }}
              label="Edit"
              className="bg-themeGreen text-white"
              iconLeftAlign={true}
              icon={<BorderColorIcon style={{ fontSize: 12 }} />}
            />
          </div>
        </div>

        <div className=" px-5 pt-[53px]">
          {loading ? (
            <div className="h-[50vh] flex justify-center items-center">
              <Loading />
            </div>
          ) : (
            <>
              <BasicInfoDetails group={group} />
              <AccessPermissions isDetails={true} />
              <OtherPermissions isDetails={true} />
            </>
          )}
        </div>
      </Form>
    </div>
  );
}
