import { format, parseISO } from "date-fns";

export function formatDate(date, dateFormat) {
    if (!date)
        return ""
    try {
        let d = date
        if (typeof date === "string")
            d = parseISO(date);
        return format(d, dateFormat || 'HH : mm');
    } catch (e) {
        // console.log(e.message, date, dateFormat)
        return ""
    }
}
export function formatDateTime(date) {
    if (!date)
        return ""
    try {
        let d = date
        if (typeof date === "string")
            d = new Date(date);

        return format(d, 'dd MMMM, yyyy @hh:mm ');
    } catch (e) {
        return "xx"
    }
}
