import React from "react";
import GenericButton from "../GenericButton/GenericButton";
import InboxIcon from "@mui/icons-material/Inbox";
import NotFoundImage from "../../assets/notFound.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link, useNavigate } from "react-router-dom";
import { Info } from "@mui/icons-material";
import WidgetsIcon from "@mui/icons-material/Widgets";

const InboxMessageTemplates = () => {
  const navigate = useNavigate();
  return (
    <div className="my-5">
      <div className="my-10">
        <div className="flex items-center justify-between py-5 border-b-2 border-creme">
          <div className="text-lg flex items-center gap-2">
            <InboxIcon
              className="text-themeGrey font-bold"
              style={{ fontSize: 20 }}
            />
            <div className="text-themeGrey text-xl">Message templates</div>
          </div>
          <GenericButton
            label="Add new template"
            className="bg-themeGreen text-white rounded-none"
            iconLeftAlign={true}
            icon={<InboxIcon className="text-white" style={{ fontSize: 16 }} />}
            onClick={() => {
              navigate("add");
            }}
          />
        </div>
        <div className="flex flex-col items-center justify-center my-10">
          <img src={NotFoundImage} />
          <div className="font-bold text-xl my-5">
            A good relationship starts with good communication
          </div>
          <div className="text-themeGrey text-xl mb-3">
            Connect with guests from the very first moment by automating
            messages and share any significant information they could need
          </div>
          <div className="flex items-center mb-5">
            <CheckCircleIcon className="text-green-500 mr-1" />
            <div className="text-themeGrey text-lg">
              Use our templates to save time
            </div>
          </div>
          <GenericButton
            label="ADD MESSAGE TEMPLATE"
            className="text-white bg-themeGreen rounded-none"
            onClick={() => {
              navigate("add");
            }}
          />
          <div className="my-10">
            <Info className="text-themeGreen mr-1" style={{ fontSize: 16 }} />
            <Link className="text-themeGreen">Learn more</Link> about how to add
            & manage message templates groups
          </div>
        </div>
      </div>
      <div className="my-10">
        <div className="flex items-center justify-between py-5 border-b-2 border-creme">
          <div className="text-lg flex items-center gap-2">
            <WidgetsIcon
              className="text-themeGrey font-bold"
              style={{ fontSize: 20 }}
            />
            <div className="text-themeGrey text-xl">
              Message template Groups
            </div>
          </div>
          <GenericButton
            label="Add new group"
            className="bg-themeGreen text-white rounded-none"
            iconLeftAlign={true}
            icon={<InboxIcon className="text-white" style={{ fontSize: 16 }} />}
            onClick={() => {
              navigate("add-group");
            }}
          />
        </div>
        <div className="flex flex-col items-center justify-center my-10">
          <img src={NotFoundImage} />
          <div className="font-bold text-xl my-5">
            A good relationship starts with good communication
          </div>
          <div className="text-themeGrey text-xl mb-3">
            Connect with guests from the very first moment by automating
            messages and share any significant information they could need
          </div>
          <div className="flex items-center mb-5">
            <CheckCircleIcon className="text-green-500 mr-1" />
            <div className="text-themeGrey text-lg">
              Use our templates to save time
            </div>
          </div>
          <GenericButton
            label="ADD MESSAGE TEMPLATE GROUP"
            className="text-white bg-themeGreen rounded-none"
            onClick={() => {
              navigate("add-group");
            }}
          />
          <div className="my-10">
            <Info className="text-themeGreen mr-1" style={{ fontSize: 16 }} />
            <Link className="text-themeGreen">Learn more</Link> about how to add
            & manage message templates groups
          </div>
        </div>
      </div>
    </div>
  );
};

export default InboxMessageTemplates;
