import React from 'react'
import { Alert, Modal, Button, Upload } from 'antd'
import { ExclamationCircleFilled, InboxOutlined } from '@ant-design/icons'

import GenericButton from '../../../components/GenericButton/GenericButton'
import { manageToast } from '../../../components/Common/ManageToast'
import { apiInstanceFinance, routes_finance } from '../../../utils/API_finance'

import './index.css'

const { Dragger } = Upload

export default function ExcelParserModal (props) {
  const {
    onClose,
    open,
    error,
    setError,
    fileBase64,
    setFileBase64,
    selectedFileList,
    setSelectedFileList,
    refetchSummaryData
  } = props

  const handleExcelUpdate = async () => {
    await apiInstanceFinance.put(routes_finance.SUMMARY_IMPORT, {
      fileBase64
    }).then((res) => {
      if (res.status === 200) {
        refetchSummaryData()
        manageToast(true, 'Summary data synchronized')
        onClose()
      }
    }).catch(error => {
      if (error?.response?.status === 500) {
        setError(error?.response?.data?.message?.message)
      } else {
        manageToast(false, 'Server error. Something went wrong.')
      }
    })
  }

  const onFileSelect = async uploadedFile => {
    if (uploadedFile && uploadedFile.file) {
      const reader = new FileReader()
      reader.readAsDataURL(uploadedFile.file)
      reader.onload = function () {
        const base64Data = reader.result.split('base64,')[1]
        setFileBase64(base64Data)
        setError('')
      }
      reader.onerror = function (error) {
        console.log('Error: ', error)
      }

      setSelectedFileList([uploadedFile.file])
    }
  }

  const onFileRemove = () => {
    setFileBase64('')
    setSelectedFileList([])
    setError('')
  }

  return (
    <Modal
      open={open}
      onCancel={onClose}
      centered
      width={400}
      title={(
        <div className="text-lg font-bold text-themeGrey">
          Import Excel file
        </div>
      )}
      footer={(
        <div className="mt-5">
          {error && (
            <Alert
              icon={<ExclamationCircleFilled />}
              message="Validation error"
              description={error}
              type="error"
              showIcon
            />
          )}

          <div className="flex justify-end mt-5">
            <Button
              className="hover:!text-buttonTheme hover:!border-buttonTheme"
              onClick={onClose}
            >
              Cancel
            </Button>

            <GenericButton
              label="Import"
              disabled={!fileBase64}
              loadingClass="text-white"
              className={`disabled:hover:!bg-themeGrey disabled:hover:!bg-opacity-30 
              disabled:hover:text-white disabled:!text-white disabled:bg-themeGrey 
              disabled:!bg-opacity-30 hover:!bg-buttonHoverTheme hover:text-white 
              bg-buttonTheme shadow-0`}
              onClick={handleExcelUpdate}
            />
          </div>
        </div>
      )}
    >
      <Dragger
        customRequest={onFileSelect}
        fileList={selectedFileList}
        onRemove={onFileRemove}
        multiple={false}
        accept=".xlsx"
        name="excelFile"
      >
        <p className="!text-buttonTheme">
          <InboxOutlined style={{ fontSize: 40 }}/>
        </p>

        <p className="ant-upload-hint">
          Click or drag file to this area to upload
        </p>
      </Dragger>
    </Modal>
  )
}