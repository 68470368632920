import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Checkbox, DatePicker, Select, Table } from "antd";
import GenericButton from "../../components/GenericButton/GenericButton";
import { channelOptions, quickDatesOptions } from "../../enum";
import { Help } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { FINANCIAL_REPORTING_FINANCIAL_SETTINGS } from "../../constants/dashboardRoutes";

const FinancialReportingOccupancyReport = () => {
  const navigate = useNavigate();
  const columns = [
    {
      title: (
        <div className="text-themeGrey font-normal text-sm">
          Listing default order
          <ImportExportIcon
            style={{ fontSize: 16 }}
            className="text-labelBlue ml-1"
          />
        </div>
      ),
      dataIndex: "listingDefaultOrder",
      key: "listingDefaultOrder",
      width: "35%",
    },
    {
      title: (
        <div className="text-themeGrey font-normal text-sm">
          Nights availble
        </div>
      ),
      dataIndex: "nightsAvailable",
      key: "nightsAvailable",
    },
    {
      title: (
        <div className="text-themeGrey font-normal text-sm">Nights booked</div>
      ),
      dataIndex: "nightsBooked",
      key: "nightsBooked",
    },
    {
      title: (
        <div className="text-themeGrey font-normal text-sm">
          Owner-stay nights
        </div>
      ),
      dataIndex: "ownerStayNights",
      key: "ownerStayNights",
    },
    {
      title: (
        <div className="text-themeGrey font-normal text-sm">Occupancy rate</div>
      ),
      dataIndex: "occupancyRate",
      key: "occupancyRate",
    },
    {
      title: (
        <div className="text-themeGrey font-normal text-sm">
          Total check-ins
        </div>
      ),
      dataIndex: "totalCheckins",
      key: "totalCheckins",
    },
    {
      title: (
        <SettingsIcon style={{ fontSize: 20 }} className="text-labelBlue" />
      ),
      key: "action",
      align: "right",
    },
  ];

  const data = [
    {
      key: "1",
      listingDefaultOrder: (
        <Link className="text-themeGreen">3B SW15 - The Putney Apartment</Link>
      ),
      nightsAvailable: "57",
      nightsBooked: "1",
      ownerStayNights: "-",
      occupancyRate: "1.75%",
      totalCheckins: "1",
    },
    {
      key: "2",
      listingDefaultOrder: (
        <Link className="text-themeGreen">
          2B BR1 - The Bromley Collection2B BR1 - The Bromley Collection
        </Link>
      ),
      nightsAvailable: "57",
      nightsBooked: "-",
      ownerStayNights: "-",
      occupancyRate: "-",
      totalCheckins: "-",
    },
    {
      key: "3",
      listingDefaultOrder: (
        <Link className="text-themeGreen">
          3B SW15 - The Peckham Apartments
        </Link>
      ),
      nightsAvailable: "19",
      nightsBooked: "-",
      ownerStayNights: "-",
      occupancyRate: "-",
      totalCheckins: "-",
    },
    {
      key: "4",
      listingDefaultOrder: (
        <Link className="text-themeGreen">2b W C - Test Apartments</Link>
      ),
      nightsAvailable: "19",
      nightsBooked: "-",
      ownerStayNights: "-",
      occupancyRate: "-",
      totalCheckins: "-",
    },
    {
      key: "5",
      listingDefaultOrder: <Link className="text-themeGreen">abcjkx</Link>,
      nightsAvailable: "-",
      nightsBooked: "-",
      ownerStayNights: "-",
      occupancyRate: "-",
      totalCheckins: "-",
    },
    {
      key: "6",
      listingDefaultOrder: <div className="text-black font-bold">Totals</div>,
      nightsAvailable: "171",
      nightsBooked: "1",
      ownerStayNights: "-",
      occupancyRate: "0.58%",
      totalCheckins: "1",
    },
  ];

  return (
    <div className="mt-3 my-4">
      <div className="sm:flex items-center justify-end gap-3 mb-5">
        <GenericButton
          label="Download report"
          className="bg-selectedGreen text-themeGreen rounded-none xs:w-full sm:w-36 xs:my-2"
        />
        <Checkbox>
          Apply current filter{" "}
          <Help style={{ fontSize: 16 }} className="text-labelBlue" />
        </Checkbox>
        <GenericButton
          label="Settings"
          className="bg-selectedGreen text-themeGreen rounded-none xs:w-full sm:w-28 xs:my-2"
          iconLeftAlign={true}
          icon={<SettingsIcon />}
          onClick={() => {
            navigate(FINANCIAL_REPORTING_FINANCIAL_SETTINGS);
          }}
        />
      </div>
      <div className="grid sx:grid-cols-1 sm:grid-cols-2 md:grid-cols-5 lg:grid-cols-7 gap-3">
        <div>
          <div className="text-themeGrey mb-2">Listings</div>
          <Select className="w-full rounded-none" placeholder="Listings" />
        </div>
        <div>
          <div className="text-themeGrey mb-2">Tags</div>
          <Select className="w-full rounded-none" placeholder="Tags" />
        </div>
        <div>
          <div className="text-themeGrey mb-2">From</div>
          <DatePicker className="w-full rounded-none" placeholder="From" />
        </div>
        <div>
          <div className="text-themeGrey mb-2">To</div>
          <DatePicker className="w-full rounded-none" placeholder="To" />
        </div>
        <div>
          <div className="text-themeGrey mb-2">Quickdates</div>
          <Select
            className="w-full rounded-none"
            placeholder="Quickdates"
            options={quickDatesOptions}
          />
        </div>
        <div>
          <div className="text-themeGrey mb-2">Channels</div>
          <Select
            className="w-full rounded-none"
            placeholder="Channels"
            options={channelOptions}
          />
        </div>
        <div>
          <Checkbox>By month</Checkbox>
          <Checkbox>Consider blocked dates</Checkbox>
        </div>
      </div>
      <div className="my-5">
        <Table
          className="border-t border-creme"
          columns={columns}
          dataSource={data}
          scroll={{
            x: 1000,
            y: 500,
          }}
        />
      </div>
    </div>
  );
};

export default FinancialReportingOccupancyReport;
