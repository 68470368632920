import React from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = () => {
  const barChartData = {
    options: {
      chart: {
        id: "bar-chart",
        type: "bar",
        width: "100%",
        height: "100%",
      },
      xaxis: {
        categories: [
          "2023/07/01",
          "2023/07/02",
          "2023/07/03",
          "2023/07/04",
          "2023/07/05",
          "2023/07/06",
          "2023/07/07",
          "2023/07/08",
          "2023/07/09",
          "2023/07/10",
          "2023/07/11",
          "2023/07/12",
          "2023/07/13",
          "2023/07/14",
          "2023/07/15",
          "2023/07/16",
          "2023/07/17",
          "2023/07/18",
          "2023/07/19",
        ],
      },
      yaxis: {
        title: {
          text: "Y-axis Title",
          offsetX: 0,
          offsetY: -15,
        },
        min: 0,
        max: 300,
        labels: {
          formatter: function (value) {
            return `€${value.toFixed(2)}`;
          },
        },
      },
    },
    series: [
      {
        name: "Data Set 1",
        data: [0, 0, 0, 0, 0, 0, 300, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      // Add more series if needed
    ],
  };

  const chartContainerHeight = 400; // Height of the container div in pixels
  const chartHeightOffset = 20;

  return (
    <div className="w-full h-full">
      <ReactApexChart
        options={barChartData.options}
        series={barChartData.series}
        type="bar"
        height={chartContainerHeight - chartHeightOffset}
      />
    </div>
  );
};

export default BarChart;
