/* eslint-disable no-useless-escape */
import { useState } from "react";
import FormSelect from "../../Form/FormSelect";

export default function AssignCity() {
  const [value, setValue] = useState(null);
  return (
    <div>
      <div className="text-xl font-bold text-themeGrey mt-5 pb-10">
        Assigned City
      </div>
      <div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-6">
        <FormSelect
          mode={value ? "multiple" : null}
          label="City"
          placeholder="Select City"
          name={"assignedCity"}
          options={[
            { label: "All", value: "All" },
            { label: "Algiers", value: "Algiers" },
            { label: "London", value: "London" },
            { label: "Dubai", value: "Dubai" },
            { label: "Paris", value: "Paris" },
          ]}
          onChange={(e) => setValue(e)}
          rules={[{ required: true, message: "City is required" }]}
        />
      </div>
    </div>
  );
}
