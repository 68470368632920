import React, { useState } from "react";
import GenericButton from "../GenericButton/GenericButton";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import SnoozeOutlinedIcon from "@mui/icons-material/SnoozeOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { Input } from "antd";
import MessageCard from "../MessageCard/MessageCard";

const InboxMessages = () => {
  const [conversationType, setConversationType] = useState("all");
  return (
    <div className="py-5">
      <div className="flex justify-end">
        <GenericButton
          label="Bulk update"
          className="bg-selectedGreen text-themeGreen rounded-none"
        />
      </div>
      <div className="flex xs:flex-col sm:flex-row gap-2">
        <GenericButton
          className="text-themeGreen bg-selectedGreen rounded-none w-7"
          icon={<FilterAltOutlinedIcon />}
        />
        <div>
          <GenericButton
            className={` rounded-none w-10 ${
              conversationType === "all"
                ? "bg-gray-500 text-gray-200"
                : "bg-gray-200 text-gray-500"
            }  `}
            onClick={() => {
              setConversationType("all");
            }}
            icon={<ForumOutlinedIcon />}
          />
          <GenericButton
            className={`rounded-none w-10 ${
              conversationType === "archived"
                ? "bg-gray-500 text-gray-200"
                : "bg-gray-200 text-gray-500"
            }  `}
            onClick={() => {
              setConversationType("archived");
            }}
            icon={<ArchiveOutlinedIcon />}
          />
          <GenericButton
            className={`rounded-none w-10 ${
              conversationType === "snoozed"
                ? "bg-gray-500 text-gray-200"
                : "bg-gray-200 text-gray-500"
            }  `}
            onClick={() => {
              setConversationType("snoozed");
            }}
            icon={<SnoozeOutlinedIcon />}
          />
        </div>
        <Input placeholder="Search guest" className="w-24 rounded-none" />
      </div>
      <div className={`mt-2 grid grid-cols-6 h-96 overflow-auto`}>
        {/* Messages section */}
        <div className={`col-span-2 overflow-auto `}>
          <MessageCard />
          <MessageCard />
          <MessageCard />
          <MessageCard />
          <MessageCard />
        </div>

        <div
          className={`col-span-4 overflow-auto border-2 border-creme rounded-md h-96 flex flex-col items-center justify-end`}
        >
          <div className="w-full h-full flex items-center justify-center text-themeGrey">
            Please select conversation
          </div>
          <div className="w-[96%] h-16 border-2 border-creme rounded-lg mx-4 flex items-center justify-between p-1">
            <div className="text-themeGrey italic">
              <AlternateEmailOutlinedIcon
                className="mr-2"
                style={{ fontSize: 20 }}
              />
              Type a message
            </div>
            <div>
              <AttachFileOutlinedIcon />
              <SendOutlinedIcon />
            </div>
          </div>
        </div>
        {/* Work on it to display later on */}
        <div className={`hidden`}>item 3</div>
      </div>
    </div>
  );
};

export default InboxMessages;
