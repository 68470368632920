import { Rate, Table } from "antd";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const ReviewTemplates = () => {
  const columns = [
    {
      title: <div className="text-themeGrey font-semibold">Name</div>,
      dataIndex: "name",
      key: "name",
    },
    {
      title: <div className="text-themeGrey font-semibold">Recommended</div>,
      dataIndex: "recommended",
      key: "recommnded",
    },

    {
      title: <div className="text-themeGrey font-semibold">Message</div>,
      dataIndex: "message",
      key: "message",
    },
    {
      title: <div className="text-themeGrey font-semibold">Private note</div>,
      dataIndex: "privateNote",
      key: "privateNote",
    },
    {
      title: <div className="text-themeGrey font-semibold">Cleanliness</div>,
      dataIndex: "cleanliness",
      key: "cleanliness",
    },
    {
      title: <div className="text-themeGrey font-semibold">Communication</div>,
      dataIndex: "communication",
      key: "communication",
    },
    {
      title: <div className="text-themeGrey font-semibold">House rules</div>,
      dataIndex: "houseRules",
      key: "houseRules",
    },
    {
      title: "",
      dataIndex: "editDelete",
      key: "editDelete",
      render: (_, record) => {
        return (
          <div className="text-themeGrey w-28 flex items-center justify-center">
            <EditIcon style={{ fontSize: 20 }} />
            <DeleteIcon style={{ fontSize: 20 }} />
          </div>
        );
      },
    },
  ];

  const data = [
    {
      key: "1",
      name: "gdcxv",
      recommnded: "Yes",
      message: "shtdjyfydhr",
      privateNote: "srhdtjfyg",
      cleanliness: <Rate count={4} />,
      communication: <Rate count={5} />,
      houseRules: <Rate count={5} />,
    },
  ];
  return (
    <div>
      <Table
        className="border-t border-creme"
        dataSource={data}
        columns={columns}
        scroll={{
          x: 1000,
        }}
      />
    </div>
  );
};

export default ReviewTemplates;
