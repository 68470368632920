import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";

export default function BasicInfoDetails({ user }) {
  return (
    <>
      <div className="flex justify-center ">
        {user?.avtar ? (
          <img
            alt={user?.firstName}
            src={user?.avtar}
            height={100}
            width={100}
          />
        ) : (
          <Avatar icon={<UserOutlined />} size={"large"} shape="square" />
        )}
      </div>
      <div className="mt-5 pb-10 grid xs:grid-cols-1 lg:grid-cols-2 gap-2 border-b-2 border-creme">
        <div className="grid grid-cols-2 gap-4">
          <div className="text-themeGrey text-sm">First name</div>
          <div className="text-themeGrey text-sm font-extrabold">
            {user?.firstName || "-"}
          </div>
          <div className="text-themeGrey text-sm">Title</div>
          <div className="text-themeGrey text-sm font-extrabold">
            {user?.title || "-"}
          </div>
          <div className="text-themeGrey text-sm">City</div>
          <div className="text-themeGrey text-sm font-extrabold">
            {user?.city || "-"}
          </div>
          <div className="text-themeGrey text-sm">Emergency Phone</div>
          <div className="text-themeGrey text-sm font-extrabold">
            {user?.emergencyPhone || "-"}
          </div>
          <div className="text-themeGrey text-sm">Postal Code</div>
          <div className="text-themeGrey text-sm font-extrabold">
            {user?.postalCode || "-"}
          </div>
          <div className="text-themeGrey text-sm">Timezone</div>
          <div className="text-themeGrey text-sm font-extrabold">
            {user?.timeZone || "-"}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="text-themeGrey text-sm">Email</div>
          <div className="text-themeGrey text-sm font-extrabold">
            {user?.email || "-"}
          </div>
          <div className="text-themeGrey text-sm">Last Name</div>
          <div className="text-themeGrey text-sm font-extrabold">
            {user?.lastName || "-"}
          </div>
          <div className="text-themeGrey text-sm">Country</div>
          <div className="text-themeGrey text-sm font-extrabold">
            {user?.country || ""}
          </div>
          <div className="text-themeGrey text-sm">Phone</div>
          <div className="text-themeGrey text-sm font-extrabold">
            {user?.phone || "-"}
          </div>
          <div className="text-themeGrey text-sm">Preferred contact method</div>
          <div className="text-themeGrey text-sm font-extrabold">
            {user?.preferredContactMethod}
          </div>
          <div className="text-themeGrey text-sm">Address</div>
          <div className="text-themeGrey text-sm font-extrabold">
            {user?.address || "-"}
          </div>
          <div className="text-themeGrey text-sm">Note before contact</div>
          <div className="text-themeGrey text-sm font-extrabold">
            {user?.noteBeforeContact || "-"}
          </div>
        </div>
      </div>
    </>
  );
}
