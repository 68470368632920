import { useState } from "react"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { LoadingOutlined } from '@ant-design/icons';
import { message, Upload, Button } from 'antd';

function AttachmentsMenu() {

    const [isExpanded, setIsExpanded]=useState(true)
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();

    const handleClick=()=>{
        setIsExpanded(!isExpanded)
    }

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
          setLoading(true);
          return;
        }
        if (info.file.status === 'done') {
          // Get this url from response in real world.
          getBase64(info.file.originFileObj, (url) => {
            setLoading(false);
            setImageUrl(url);
          });
        }
    };

    const getBase64 = (img, callback) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    };

    const beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
      }
      return isJpgOrPng
    };

    

  return (
    <div className="mt-8">
      <div className='flex justify-between'>
            <h1 className='text-xl font-semibold'>Attachments</h1>
            {isExpanded ? <KeyboardArrowDownIcon className='cursor-pointer' onClick={handleClick}/> : <KeyboardArrowLeftIcon className='cursor-pointer' onClick={handleClick}/>}
      </div>
      {isExpanded && 
         <Upload
         listType="picture-card"
         showUploadList={false}
         className="mt-5"
         beforeUpload={beforeUpload}
         onChange={handleChange}
         >
           {imageUrl ? (
               <img
               src={imageUrl}
               alt="avatar"
               style={{
                   width: '100%',
               }}
               />
               ) : ( loading ? <LoadingOutlined/> : 
                   <>
                      <span className="mt2 ml-3">Drop files to upload or
                       <Button className="text-buttonTheme font-semibold mb-2 px-1 py-0 hover:!bg-transparent hover:!text-buttonHoverTheme" type="text">Browse</Button>
                      </span>
                    </>
           )}
         </Upload>
    }
    </div>
  )
}

export default AttachmentsMenu
