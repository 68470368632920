import React from "react";
import { marketplaceFilterOptions } from "../../enum";
import { Select } from "antd";
import AirBNBLogo from "../../assets/airbnb.png";
import VRBOLogo from "../../assets/vrbo.svg";
import BookingLogo from "../../assets/booking.png";
import ExpediaLogo from "../../assets/expedia.png";
import HomeVillasLogo from "../../assets/homevillas.png";
import stripeLogo from "../../assets/stripe.png";

import MarketplaceCard from "../MarketplaceCard/MarketplaceCard";

const Marketplace = () => {
  return (
    <div className="bg-white">
      <div className="font-bold text-3xl text-black pl-5 mb-5">Marketplace</div>
      <Select
        bordered={false}
        className="w-[25%] ml-5 mb-7 border-b-4 border-l-2 border-r-2 border-creme"
        showSearch
        placeholder={<div className="italic">All Categories</div>}
        optionFilterProp="children"
        filterOption={(input, option) => (option?.label ?? "").includes(input)}
        options={marketplaceFilterOptions}
      />
      <div className="grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 vlg:grid-cols-4 gap-8 pl-5 mb-5">
        <MarketplaceCard
          cardTitle={"Airbnb"}
          cardContent={
            "An online peer-to-peer marketplace with over 5 million unique accommodations & experiences available in 191 countries."
          }
          cardImage={AirBNBLogo}
        />
        <MarketplaceCard
          cardTitle={"Vrbo"}
          cardContent={
            "A vacation rental booking channel that serves 75 million travelers per month across 190 countries."
          }
          cardImage={VRBOLogo}
        />
        <MarketplaceCard
          cardTitle={"Booking.com"}
          cardContent={
            "A travel fare aggregator offering more than 5 million places to stay in over 228 countries & territories worldwide."
          }
          cardImage={BookingLogo}
        />
        <MarketplaceCard
          cardTitle={"Expedia"}
          cardContent={
            "A group of more than 200 companies serving almost every travel segment imaginable."
          }
          cardImage={ExpediaLogo}
        />
        <MarketplaceCard
          cardTitle={"Home & Villas"}
          cardContent={
            "Homes & Villas by Marriott Bonvoy is a global home rental offering launched in 2019 by Marriott International with a vision to bring Marriott’s 90+ years of hospitality expertise to the home rental market. "
          }
          cardImage={HomeVillasLogo}
        />
        <MarketplaceCard
          cardTitle={"Stripe"}
          cardContent={
            "A payment processing platform built for growth that provides the tools needed to manage revenues, fight fraud and analyze data."
          }
          cardImage={stripeLogo}
        />
      </div>
    </div>
  );
};

export default Marketplace;
