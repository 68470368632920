import React from 'react'
import { Select, Tooltip } from 'antd'
import { CloseCircleOutlined, EditOutlined } from '@ant-design/icons'

import GenericButton from '../../components/GenericButton/GenericButton'

function CleaningTableCleanersCell (props) {
  const {
    cleanerName = [],
    cleaners = [],
    handleSaveClick,
    _id,
    setEditId
  } = props

  const [showField, setShowField] = React.useState(false)
  const [selectedCleaners, setSelectedCleaners] = React.useState([])

  const handleReset = () => {
    setShowField(false)
    setEditId('')
  }

  const handleCleanersSave = () => {
    handleSaveClick(selectedCleaners, _id, handleReset)
  }

  return showField
    ? (
      <div className="flex items-center">
        <Select
          className="rounded-none w-full text-left"
          placeholder="Select Cleaners"
          onChange={value => setSelectedCleaners(value)}
          value={selectedCleaners}
          mode="tags"
          maxTagCount={10}
        >
          {cleaners.map((cleaner, index) => (
            <Select.Option key={index} value={cleaner.name}>
              {cleaner.name}
            </Select.Option>
          ))}
        </Select>

        <GenericButton
          label="Done"
          onClick={handleCleanersSave}
          className={`ml-4 disabled:opacity-50 disabled:bg-buttonTheme 
            disabled:hover:!bg-buttonHoverTheme disabled:!text-white 
            disabled:hover:!text-white py-1 rounded-md px-[15px] text-white 
            inline-block bg-buttonTheme hover:!bg-buttonHoverTheme 
            hover:!text-white `}
        />

        <Tooltip title="Cancel" placement="right">
          <div>
            <GenericButton
              className="capitalize bg-buttonTheme text-white hover:bg-buttonHoverThemerounded-full
                w-[32px] flex justify-center items-center ml-1"
              onClick={handleReset}
              icon={<CloseCircleOutlined />}
            />
          </div>
        </Tooltip>
      </div>
    )
    : (
      <div className="flex items-center justify-between gap-1">
        <div className="gap-1 flex flex-wrap justify-center w-full">
          {cleanerName.length > 0 ? (
            cleanerName.map((item) => (
              <p
                key={item}
                className="bg-[#35d0cf] px-1 rounded-lg text-white text-xs">
                {item}
              </p>
            ))
          ) : (
            <p className="text-xs">No Cleaner</p>
          )}
        </div>

        <GenericButton
          icon={<EditOutlined/>}
          className="capitalize bg-buttonTheme text-white hover:bg-buttonHoverTheme
             rounded-full h-7 w-4 flex justify-center items-center"
          onClick={() => {
            setShowField(true)
            setEditId(_id)
            setSelectedCleaners(cleanerName)
          }}
        />
      </div>
    )
}

export default CleaningTableCleanersCell
