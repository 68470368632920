import React from 'react'
import { Form } from 'antd'
import { useWindowSize } from 'react-use'

import GenericButton from '../../../components/GenericButton/GenericButton'
import Loading from '../../../components/Loaders/Loading'

import { apiInstance, routes } from '../../../utils/API'

import RoiCalculatorForm from './CalculatorForm'
import RoiCalculatorResultTable from './ResultTable'
import Card from './Card'

import { calculateROIValues, createResultTableItems } from './helpers'

const initialValues = {
  purchasePrice: undefined,
  refurbishmentPrice: undefined,
  stampDuty: undefined,
  legalPrice: undefined,
  mortgage: undefined,
  deposit: undefined,
  maintenance: undefined,
  groundRent: undefined,
  averageRevenue: undefined,
  averageCosts: undefined,
  ebit: undefined,
  mortgageInterestOnly: undefined,
  netIncome: undefined,
  serviceCharge: undefined,
  totalCosts: undefined,
  roi: undefined
}

const initialRadius = 0.5

export default function RoiCalculator (props) {
  const { propertiesData } = props

  const [calculatedValues, setCalculatedValues] = React.useState(initialValues)
  const [loading, setLoading] = React.useState(false)
  const [properties, setProperties] = React.useState([])
  const [operationSucceeded, setOperationSucceeded] = React.useState(false)
  const [operationError, setOperationError] = React.useState(false)
  const [radius, setRadius] = React.useState(initialRadius)
  const [showLoadMoreButton, setShowLoadMoreButton] = React.useState(false)
  const [form] = Form.useForm()
  const purchasePrice = Form.useWatch('purchasePrice', form)
  const refurbishmentPrice = Form.useWatch('refurbishmentPrice', form)
  const legalPrice = Form.useWatch('legalPrice', form)
  const maintenance = Form.useWatch('maintenance', form)
  const groundRent = Form.useWatch('groundRent', form)
  const postalCode = Form.useWatch('postalCode', form)
  const numberOfBedrooms = Form.useWatch('numberOfBedrooms', form)
  const mortgageInterest = Form.useWatch('mortgageInterest', form)
  const serviceCharge = Form.useWatch('serviceCharge', form)

  const { width } = useWindowSize()

  const formValues = {
    purchasePrice, refurbishmentPrice, legalPrice, maintenance, serviceCharge,
    groundRent, postalCode, numberOfBedrooms, mortgageInterest
  }

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true)
      let url = `${routes.PROPERTIES_BY_POSTAL_CODE}?`
      url += `postalCode=${values.postalCode}&`
      url += `radiusInMiles=${radius}`

      const response = await apiInstance.get(url)
      const nextProperties = response?.data?.properties?.length
        ? response.data.properties.filter(item => {
          const nbBedrooms = values.numberOfBedrooms.toString()

          return item?.basicInfo?.internal_listing_name?.startsWith(nbBedrooms)
        })
        : []

      if (!propertiesData?.length) {
        setLoading(false)
        setOperationSucceeded(true)
        setShowLoadMoreButton(false)
        return
      }

      const foundProperties = []
      const foundPropertiesNames = []
      nextProperties.forEach(prop => {
        const property = propertiesData.find((item) => (
          item?.name === prop?.basicInfo?.internal_listing_name
        ))

        if (property) {
          foundProperties.push(property)
          foundPropertiesNames.push(property.name)
        }
      })

      if (!foundProperties.length) {
        setLoading(false)
        setOperationSucceeded(true)
        setProperties([])
        setRadius(prev => prev + 10)
        setShowLoadMoreButton(true)
        return
      }

      const nextValues = calculateROIValues(values, foundProperties)

      const targetProperties = nextProperties.filter(property => {
        return foundPropertiesNames.includes(property?.basicInfo?.internal_listing_name)
      })

      setCalculatedValues(nextValues)
      setProperties(targetProperties)
      setOperationSucceeded(true)
      setLoading(false)
      setRadius(initialRadius)
    } catch (error) {
      setLoading(false)
      setOperationSucceeded(false)
      setOperationError(true)
    }
  }

  const handleFormReset = () => {
    form.resetFields()
    setCalculatedValues(initialValues)
    setRadius(initialRadius)
    setOperationSucceeded(false)
  }

  const resultItems = React.useMemo(
    () => createResultTableItems(calculatedValues),
    [calculatedValues])

  const containerClassName = React.useMemo(() => {
    let className = 'flex gap-7 mt-7'

    if (width <= 712) {
      className += ' flex-col'
    }

    return className
  }, [width])

  const resultTableClassName = React.useMemo(() => {
    let className = 'w-full max-h-[640px] pb-4 mt'

    if (width >= 712) {
      className += ' max-w-[340px] sm:sticky sm:top-[50px]'
    }

    return className
  }, [width])

  const cardsClassName = React.useMemo(() => {
    let className = `grid justify-center grid-cols-1 gap-7`

    if (width <= 705 && width >= 630) {
      className += ' grid-cols-2'
    }

    if (width >= 1320) {
      className += ' grid-cols-2'
    }

    return className
  }, [width])

  return (
    <div className="min-h-[350px]">
      <RoiCalculatorForm
        handleFormSubmit={handleFormSubmit}
        handleFormReset={handleFormReset}
        form={form}
      />

      {loading
        ? (
          <div className={`bg-white rounded-lg flex justify-center h-[20px] 
            items-center`}>
            <Loading />
          </div>
        )
        : (
          <>
            {operationSucceeded
              ? !!properties?.length
                ? (
                  <>
                    <div className={containerClassName}>
                      <div className={resultTableClassName}>
                        <RoiCalculatorResultTable items={resultItems}/>
                      </div>

                      <div className="w-full">
                        <div className={cardsClassName}>
                          {properties.map((card, index) => (
                            <Card {...card} key={index}/>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                )
                : (
                  <div className="flex justify-between mt-5">
                    <div className="text-md font-bold text-themeGrey">
                      No properties found...
                    </div>

                    {showLoadMoreButton && (
                      <GenericButton
                        onClick={() => handleFormSubmit(formValues)}
                        className={`disabled:bg-themeGrey disabled:bg-opacity-20 
                          disabled:hover:text-white disabled:!text-white 
                          hover:text-white hover:!bg-buttonHoverTheme shadow-0 
                          disabled:hover:!bg-opacity-20 bg-buttonTheme`}
                        label="Search nearest properties"
                      />
                    )}
                  </div>
                )
              : operationError
                ? (
                  <div className={`text-md font-bold text-themeGrey text-center 
                    mt-5`}>
                    Invalid zip code
                  </div>
                )
                : null}
          </>
        )}
    </div>
  )
}
