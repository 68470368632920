import React from 'react'

import { numberWithCommas } from '../../../utils/helper'

import {
  generateHtmlLegendPlugin,
  generateLabels,
  getRevenueChartLegendText
} from './utils'

export default function RevenuesChart (props) {
  const {
    chartClassNames,
    chartComponent: Chart,
    data = {}
  } = props

  const legendContainerId = 'revenues-chart-legend'

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          title: tooltip => tooltip[0].label?.split(':')?.[0] + ':',
          label: ({ parsed, dataset, label }) => {
            return dataset.label + ': £ ' + numberWithCommas(parsed) +
              ` (${label?.split(':')?.[1]})`
          },
        },
      },
      htmlLegend: {
        containerID: legendContainerId
      },
      legend: {
        display: false,
        labels: {
          generateLabels: chart => generateLabels(chart, getRevenueChartLegendText)
        }
      }
    }
  }

  const htmlLegendPlugin = generateHtmlLegendPlugin('htmlLegend', legendContainerId)

  return (
    <div className="grid grid-cols-1 sm:grid-cols-[1fr,1fr] gap-2 pt-2 px-2">
      <div className={chartClassNames}>
        <Chart
          plugins={[htmlLegendPlugin]}
          options={options}
          data={data}
          type="pie"
        />
      </div>

      <div
        className="w-full h-full sm:h-[19rem] overflow-hidden"
        id={legendContainerId}
      />
    </div>
  )
}