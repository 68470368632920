import React from "react";
import { Button, Result } from "antd";
import { HOME } from "../constants/pageRoutes";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="h-screen flex flex-col items-center justify-center bg-themeGreen">
      <Result
        status="404"
        title="404"
        subTitle="The page you are trying to access does not exist."
        extra={
          <Button
            className="bg-white text-black"
            type="primary"
            onClick={() => {
              navigate(HOME);
            }}
          >
            Back Home
          </Button>
        }
      />
    </div>
  );
};

export default NotFound;
