/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react'
import { Button, Checkbox, DatePicker, Form, Select, Space } from 'antd'
import Dropdown from 'antd/es/dropdown/dropdown'
import { DownOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

import Loading from '../../components/Loaders/Loading'

import { apiInstance, routes, serverlessApi } from '../../utils/API'
import { calculateDistance } from '../../utils/helper'

import 'animation.css'
import './relocationTool.css'

const requiredRules = [{
  required: true,
  message: '${name} is a required field'
}]

const OTHER_PROPERTIES_SIZE_LIMIT = 21

const sortOtherPropertiesByUpgradeAvailable = (a, b) => {
  return Number(a.upgradeAvailable) - Number(b.upgradeAvailable)
}

const createOtherPropertiesMapByUpgrade = listing => {
  const listingValue = Number(listing?.internalListingName?.split('B')[0])

  return item => {
    const value = Number(
      item.propertyDetails?.internalListingName?.split('B')[0],
    )

    return ({
      ...item,
      upgradeAvailable: value > listingValue,
    })
  }
}

const normalizeOtherProperties = (properties, listing) => {
  const propertiesMappedByUpgrade = properties.map(
    createOtherPropertiesMapByUpgrade(listing))
  const sortedByUpgrade = [...propertiesMappedByUpgrade].sort(
    sortOtherPropertiesByUpgradeAvailable)

  const upgradeUnavailable = sortedByUpgrade.filter(
    item => !item.upgradeAvailable).sort((a, b) => a.distance - b.distance)

  const upgradeAvailable = sortedByUpgrade.filter(
    item => item.upgradeAvailable).sort((a, b) => a.rentInner - b.rentInner)

  return [...upgradeUnavailable, ...upgradeAvailable].slice(0,
    OTHER_PROPERTIES_SIZE_LIMIT)
}

const normalizeDiscount = days => {
  const discount = {}

  if (!days) {
    return discount
  }

  if (Number(days) < 7) {
    discount.value = 0
    discount.type = 'No discount'
  } else if (Number(days) >= 7 && Number(days) < 14) {
    discount.value = 10
    discount.type = 'Weekly discount'
  } else if (Number(days) >= 14 && Number(days) < 28) {
    discount.value = 15
    discount.type = '2-week discount'
  } else if (Number(days) >= 28 && Number(days) < 90) {
    discount.value = 20
    discount.type = 'Monthly discount'
  } else if (Number(days) >= 90 && Number(days) < 180) {
    discount.value = 27
    discount.type = 'Quarterly discount'
  } else if (Number(days) >= 180 && Number(days) < 363) {
    discount.value = 33
    discount.type = 'Semi-annual discount'
  } else if (Number(days) >= 364) {
    discount.value = 38
    discount.type = 'Annual discount'
  }

  return discount
}

const RelocationTool = () => {
  const [listings, setListings] = React.useState([])
  const [options, setOptions] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [discount, setDiscount] = React.useState(0)
  const [discountType, setDiscountType] = React.useState('')
  const [counter, setCounter] = React.useState(0)
  const [currency, setCurrency] = React.useState('GBP')
  const [exchangeRate, setExchangeRate] = React.useState(1)
  const [allExchangeRates, setAllExchangeRates] = React.useState([])
  const [notFoundError, setNotFoundError] = React.useState('')
  const [showCards, setShowCards] = React.useState(false)

  const [form] = Form.useForm()
  const listingId = Form.useWatch('listing', form)
  const dateFrom = Form.useWatch('from', form)
  const type = Form.useWatch('type', form)

  const disabledDate = current =>
    current && current < dayjs(dateFrom).endOf('day')

  const disabledDateCheckin = current =>
    current && current < dayjs().startOf('day')

  const selectedProperty = React.useMemo(
    () => listings.find(prop => prop?._id === listingId),
    [listingId, listings]
  )

  const showCleaning = React.useMemo(
    () => type !== 'extension',
    [type]
  )

  React.useEffect(() => {
    if (options && counter === 0) {
      const data = options?.otherProperties?.filter(item => (
        item?.availabilityInner !== false &&
        item?.propertyDetails?.listingTags?.[0]?.name === selectedProperty?.listingTags?.[0]?.name
      ));
      if (data?.length > 0) {
        let tempOptions = JSON.parse(JSON.stringify(options));
        let tempListings = JSON.parse(JSON.stringify(selectedProperty));
        tempListings.cleaningFee = data[0].propertyDetails.cleaningFee;
        tempOptions.rent = options.rent;
        setOptions(tempOptions);
        setCounter(1);
      }
    }
  }, [options]);

  React.useEffect(() => {
    (async () => {
      setLoading(true)

      await serverlessApi.getPropertiesForReservation().then(res => {
        if (res.status === 200) {
          const properties = res?.data.data || []
          const filteredProperties = properties.filter(item => {
            return !item?.internalListingName?.toLowerCase()?.includes('communal area')
          })

          setListings(filteredProperties)
        }
      })

      await apiInstance.get(routes.EXCHANGE_RATES).then(res => {
        if (res.status === 200) {
          setAllExchangeRates(res.data.data.exchangerates)
        }
      })

      setLoading(false)
    })()
  }, [])

  const handleCurrency = (currency) => {
    setCurrency(currency)
    if (currency !== 'GBP') {
      const selection = allExchangeRates.filter(
        (item) => item.slug === currency,
      )
      setExchangeRate(selection[0].currentRate)
    } else {
      setExchangeRate(1)
    }
  }

  const handleItemSelect = (e) => {
    handleCurrency(e.key)
  }

  const otherProperties = options?.otherProperties?.length > 0
    ? normalizeOtherProperties(options.otherProperties, selectedProperty)
    : []

  const menuItems = [
    {
      label: 'GBP',
      key: 'GBP',
    },
    {
      label: 'EUR',
      key: 'EUR',
    },
    {
      label: 'AED',
      key: 'AED',
    },
  ]

  const menuProps = {
    items: menuItems,
    onClick: handleItemSelect,
    placement: 'bottomLeft',
    sx: { zIndex: 10 },
  }

  const handleFormSubmit = async values => {
    setCounter(0)

    try {
      setIsLoading(true)

      let response

      let url = `listingId=${selectedProperty.id}`
      url += `&from=${dayjs(values.from).format('YYYY-MM-DD')}`
      url += `&to=${dayjs(values.to).format('YYYY-MM-DD')}`
      url += `&lat=${selectedProperty.lat}`
      url += `&lng=${selectedProperty.lng}`
      url += `&propertyId=${selectedProperty.id}`

      if (values.unitId) {
        url += `&unitId=${values.unitId}`
      }

      if (values.type === 'relocation') {
        response = await serverlessApi.getRelocationTool(url)
      } else {
        response = await serverlessApi.getReservationTool(url)
      }

      let requiredData = { ...response.data.propertyDetails }
      let modifiedOptions = []

      if (requiredData?.otherProperties?.length) {
        modifiedOptions = requiredData.otherProperties.filter(item => {
          return item?.availabilityInner &&
            !item?.propertyDetails?.internalListingName?.toLowerCase()
            ?.includes('communal area')
        }).map((item) => {
          let distance = calculateDistance(
            item.propertyDetails.lat,
            item.propertyDetails.lng,
            selectedProperty.lat,
            selectedProperty.lng,
          ).toFixed(2)
          return { ...item, distance: distance }
        })
      }

      requiredData = { ...requiredData, otherProperties: [...modifiedOptions] }

      const _discount = normalizeDiscount(requiredData.days)
      setDiscount(_discount?.value || 0)
      setDiscountType(_discount?.type || '')

      setOptions(requiredData)
      setNotFoundError('')
      setCounter(0)
      setIsLoading(false)
      setShowCards(true)
    } catch (error) {
      if (error?.response?.status === 404) {
        setNotFoundError(`${error.response.data.message} found`)
      }

      setIsLoading(false)
      setShowCards(false)
    }
  }

  const numberWithCommas = (x) => {
    const roundedNumber = Number(x).toFixed(2)
    return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const renderAmenities = amenities => {
    if (!amenities?.length) {
      return 'Amenities not found... Please check Guest Comm Board'
    } else {
      return amenities.map(({ id, amenityName}) => (
        <div className="bg-gray-200 px-2 rounded" key={id}>
          <p className="flex-inline text-xs">{amenityName}</p>
        </div>
      ))
    }
  }

  return (
    <div className="bg-[#f4f5fb] mt-[-20px] pt-3">
      {loading ? (
        <div className="h-[100vh]">
          <Loading />
        </div>
      ) : (
        <div className="bg-[#f4f5fb]">
          <div className="flex items-baseline gap-5 m-4">
            <div className="text-themeGrey text-xl font-bold ">
              {type === 'relocation'
                ? 'Relocation Tool'
                : 'Reservation Tool'}
            </div>
          </div>

          <div>
            <Dropdown menu={menuProps}>
              <Button className="flex items-center justify-around rounded-2xl bg-white fixed right-4 top-20 w-24 z-[10]">
                <Space>
                  {currency} <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </div>

          <div className={`flex ${notFoundError ? 'flex-col' : 'flex-row'} 
          flex-wrap ${notFoundError ? 'items-center' : ''} pb-10 gap-10 
          bg-slate-100 pt-14 justify-center`}>
            <div className="w-[90%] md:w-96 px-10 py-[50px] border-2 border-slate-100 rounded-xl shadow-xl bg-white">
              <Form
                name="relocation-tool-form"
                onFinish={handleFormSubmit}
                onValuesChange={changedValues => {
                  const listingChanged = Object.keys(changedValues)[0] === 'listing'
                  if (listingChanged) {
                    form.setFieldValue('unitId', undefined)
                  }

                  const dateFromChanged = Object.keys(changedValues)[0] === 'from'
                  if (dateFromChanged) {
                    form.setFieldValue('to', undefined)
                  }

                  if (showCards) {
                    setShowCards(false)
                  }
                }}
                autoComplete="off"
                form={form}
                layout="vertical"
              >
                <Form.Item
                  name="listing"
                  messageVariables={{ name: 'Listing' }}
                  rules={requiredRules}
                >
                  <Select
                    allowClear
                    disabled={isLoading}
                    showSearch={true}
                    filterOption={(input, option) => (
                      option.data?.internalListingName?.toLowerCase()
                      .includes(input?.toLowerCase()) ||
                      option.data?.address?.toLowerCase()
                      .includes(input?.toLowerCase()) ||
                      option.data?.city?.toLowerCase()
                      .includes(input?.toLowerCase()) ||
                      option.data?.country?.toLowerCase()
                      .includes(input?.toLowerCase()) ||
                      option.data?.tags?.toLowerCase()
                      .includes(input?.toLowerCase()) ||
                      option.data?.externalListingName?.toLowerCase()
                      .includes(input?.toLowerCase())
                    )}
                    placeholder="Select Listing"
                    options={listings.map((l, i) => {
                      return {
                        label: (
                          <div>
                            <div className="flex items-center gap-x-2">
                              {!!l?.listingUnits?.length && (
                                <span className="border px-1 inline-flex ml-1 leading-[normal]">
                              {l.listingUnits.length}{" "}
                            </span>
                              )}

                              {!!l?.listingTags?.length && (
                                <p className="text-xs font-bold">
                                  {`${l.listingTags[0]?.name}`}
                                </p>
                              )}

                              {l?.internalListingName}
                            </div>

                            <p className="text-xs">
                              {`${l?.externalListingName}`}
                            </p>
                          </div>
                        ),
                        value: l?._id,
                        key: i,
                        data: l,
                      }
                    })}
                    className=" w-full"
                  />
                </Form.Item>

                {selectedProperty?.listingUnits?.length > 1 && (
                  <Form.Item
                    name="unitId"
                    messageVariables={{ name: 'Listing unit' }}
                    rules={requiredRules}
                  >
                    <Select
                      allowClear
                      disabled={isLoading}
                      placeholder="Select Unit"
                      options={selectedProperty.listingUnits.map(unit => ({
                        label: unit.name,
                        value: unit.id
                      }))}
                      className=" w-full"
                    />
                  </Form.Item>
                )}

                <Form.Item
                  name="from"
                  messageVariables={{ name: 'Check-in date' }}
                  rules={requiredRules}
                >
                  <DatePicker
                    placeholder="Check-in date"
                    disabled={isLoading}
                    className="w-full h-8 rounded-md"
                    disabledDate={disabledDateCheckin}
                  />
                </Form.Item>

                <Form.Item
                  name="to"
                  messageVariables={{ name: 'Check-out date' }}
                  rules={requiredRules}
                >
                  <DatePicker
                    disabled={!dateFrom || isLoading}
                    placeholder="Check-out date"
                    className="w-full h-8 rounded-md"
                    disabledDate={disabledDate}
                  />
                </Form.Item>

                <Form.Item
                  name="type"
                  rules={[{
                    required: true,
                    message: 'This field is required'
                  }]}
                >
                  <div>
                    <div className="text-sm mb-3">
                      Please select one option:
                    </div>

                    <Checkbox
                      className="w-full !text-[16px] mb-3"
                      checked={type === 'reservation'}
                      value="reservation"
                      disabled={isLoading}
                    >
                      New reservation
                    </Checkbox>

                    <Checkbox
                      className="w-full !text-[16px] mb-3"
                      checked={type === 'relocation'}
                      value="relocation"
                      disabled={isLoading}
                    >
                      Relocation
                    </Checkbox>

                    <Checkbox
                      className="w-full !text-[16px] mb-3"
                      checked={type === 'extension'}
                      value="extension"
                      disabled={isLoading}
                    >
                      Extension
                    </Checkbox>
                  </div>
                </Form.Item>

                {isLoading ? (
                  <Loading/>
                ) : (
                  <Form.Item label={null}>
                    <Button
                      className={`w-full mt-6 text-black border-gray-300
                      hover:!text-buttonTheme hover:!border-buttonTheme
                      hover:!bg-white !shadow-none`}
                      htmlType="submit"
                      type='primary'
                    >
                      {type === 'relocation' ? 'Get Relocation' : 'Get Price'}
                    </Button>
                  </Form.Item>
                )}
              </Form>
            </div>

            {notFoundError && (
              <div className="font-bold text-lg text-themeGrey mx-auto">
                {notFoundError}
              </div>
            )}

            {/* first */}
            {showCards && options && (
              <div className=" w-[90%] md:w-96 mx-auto md:mx-0 px-5 py-5 border-2  border-slate-100 rounded-xl shadow-xl bg-white">
                <div className="mb-5 flex justify-between">
                  <div>
                    {!options.availability ? (
                      <p className="bg-red-400 text-white rounded-lg w-fit px-2 text-xs py-1">
                        Unavailable
                      </p>
                    ) : (
                      <p className="bg-green-600 text-white rounded-lg w-fit px-2 text-xs py-1">
                        Available
                      </p>
                    )}
                  </div>
                  {type !== 'relocation' && (
                    <div className="bg-[#00b4b4] rounded-lg shadow-xl">
                      <p className=" px-2 text-xs py-1 text-white">
                        Min stay {options.minStay} days
                      </p>
                    </div>
                  )}
                </div>

                {!!selectedProperty?.listingImages?.length && (
                  <img className="rounded-xl" src={selectedProperty.listingImages[0].url} alt=""/>
                )}

                {!!selectedProperty?.listingTags?.length && (
                  <div className="flex gap-2 mt-2">
                    <p className="text-xs font-bold">Tag</p>

                    <p className="text-xs">{`${selectedProperty?.listingTags[0].name}`}</p>
                  </div>
                )}

                {selectedProperty?.internalListingName && (
                  <div className="flex gap-2 mt-2">
                    <p className="text-xs font-bold">Listing</p>

                    <p className="text-xs">
                      {`${selectedProperty?.internalListingName}`}
                    </p>
                  </div>
                )}
                <div className="flex gap-2 mt-2">
                  <p className="text-xs font-bold">Address</p>
                  <p className="text-xs">
                    {`${selectedProperty?.address}, ${selectedProperty?.city
                    }, ${selectedProperty?.country}`}
                  </p>
                </div>
                <div className="gap-2 mt-2">
                  <p className="text-xs font-bold mb-2">Amenities</p>
                  <div className="flex flex-wrap gap-1 w-full ">
                    {renderAmenities(selectedProperty?.listingAmenities)}
                  </div>
                </div>

                <div className="flex justify-between mt-5">
                  <div className="flex gap-1">
                    <p>Rent</p>
                  </div>
                  <p>{` ${numberWithCommas(
                    Number(options?.rent) * exchangeRate
                  )} ${currency}`}</p>
                </div>

                <div>
                  {showCleaning && (
                    <div className="flex justify-between">
                      <p>Cleaning</p>
                      <p>{`${numberWithCommas(
                        Number(selectedProperty?.cleaningFee) * exchangeRate
                      )} ${currency}`}</p>
                    </div>
                  )}

                  {discount !== 0 && (
                    <div className="flex justify-between">
                      <div className="flex gap-1">
                        <p>Discount</p>
                        <p className="text-xs font-bold self-center">
                          ({discountType})
                        </p>
                      </div>
                      <p>{discount}%</p>
                    </div>
                  )}
                  <div className="w-full border-[1px] border-slate-150 my-2" />
                  <div className="flex justify-between">
                    <div className="flex gap-1 items-center">
                      <p className="font-bold">Total</p>
                      <p className="text-xs font-bold">
                        {` (${currency === 'EUR'
                          ? '€ '
                          : currency === 'AED'
                            ? 'AED '
                            : '£ '
                        }${(
                          (Number(options?.rent) / Number(options?.days)) *
                          ((100 - Number(discount)) / 100) *
                          exchangeRate
                        ).toFixed(2)} per night) `}
                      </p>
                    </div>
                    <p className="font-bold">
                      {`${numberWithCommas(
                        (Number(options?.rent) *
                          ((100 - Number(discount)) / 100) +
                          (showCleaning
                            ? Number(selectedProperty?.cleaningFee)
                            : 0)) *
                        exchangeRate
                      )} ${currency}`}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          {showCards && options?.otherProperties?.length > 0 && (
            <div className="flex justify-center text-themeGrey text-lg font-bold mt-12">
              Other Properties near by
            </div>
          )}
          {/*next */}
          {showCards && (
            <div
              className="flex flex-wrap pb-10 gap-10 bg-slate-100 pt-5 justify-center">
              {otherProperties.map((item, i) => {
                if (item?.propertyDetails?.internalListingName?.split("B")[0] >=
                  selectedProperty?.internalListingName?.split("B")[0]) {
                  return (
                    <div
                      className="w-[90%] md:w-96 mx-auto md:mx-0 px-5 py-5  border-2  border-slate-100 rounded-xl shadow-xl bg-white flex flex-col justify-between"
                      key={i + 1}>
                      <div>
                        <div className="flex justify-between">
                          <div className="mb-5 flex justify-between w-full">
                            <div>
                              {!item.availabilityInner ? (
                                <p
                                  className="bg-red-400 text-white rounded-lg w-fit px-2 text-xs py-1">
                                  Unavailable
                                </p>
                              ) : (
                                <p
                                  className="bg-green-600 text-white rounded-lg w-fit px-2 text-xs py-1">
                                  Available
                                </p>
                              )}
                            </div>

                            {type !== 'relocation' && (
                              <div
                                className="bg-[#00b4b4] rounded-lg shadow-xl">
                                <p className=" px-2 text-xs py-1 text-white">
                                  Min stay {item.minStayInner} days
                                </p>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="relative">
                          {item.upgradeAvailable && (
                            <div
                              className="absolute bg-[#00b4b4] py-2 px-4 rounded-br-xl rounded-tl-lg shadow-2xl">
                              <p className="text-white font-bold ">Upgrade</p>
                            </div>
                          )}
                          <div
                            className="absolute bg-green-600 py-2 px-4 right-[10px] bottom-[10px] rounded-lg shadow-2xl">
                            <p className="text-white font-bold ">
                              {item?.distance} miles away
                            </p>
                          </div>
                          <img
                            className="rounded-xl"
                            src={item?.propertyDetails?.listingImages?.[0]?.url}
                          />
                        </div>

                        {!!item?.propertyDetails?.listingTags?.length && (
                          <div className="flex gap-2 ">
                            <p className="text-xs font-bold mt-2">Tag</p>

                            <p className="text-xs mt-2">
                              {item?.propertyDetails?.listingTags?.[0]?.name}
                            </p>
                          </div>
                        )}

                        {item?.propertyDetails?.internalListingName && (
                          <div className="flex gap-2 ">
                            <p className="text-xs font-bold mt-2">Listing</p>

                            <p className="text-xs mt-2">
                              {item.propertyDetails.internalListingName}
                            </p>
                          </div>
                        )}
                        <div className="flex gap-2 mt-2">
                          <p className="text-xs font-bold">Address</p>
                          <p className="text-xs">
                            {`${item?.propertyDetails?.address}, ${item?.propertyDetails?.city}, ${item?.propertyDetails?.country}`}
                          </p>
                        </div>

                        <div className="gap-2 mt-2">
                          <p className="text-xs font-bold mb-2">Amenities</p>
                          <div className="flex flex-wrap gap-1 w-full ">
                            {renderAmenities(
                              item?.propertyDetails?.listingAmenities)}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-wrap"></div>
                      <div className="mt-5">
                        <div className="flex justify-between mt-5">
                          <div className="flex gap-1">
                            <p>Rent</p>
                          </div>
                          <p>{` ${numberWithCommas(
                            Number(item?.rentInner) * exchangeRate
                          )} ${currency}`}</p>
                        </div>

                        {showCleaning && (
                          <div className="flex justify-between">
                            <p>Cleaning</p>
                            <p>{`${numberWithCommas(
                              Number(
                                item?.propertyDetails?.cleaningFee
                              ) * exchangeRate
                            )} ${currency}`}</p>
                          </div>
                        )}
                        {discount !== 0 && (
                          <div className="flex justify-between">
                            <div className="flex gap-1">
                              <p>Discount</p>
                              <p className="text-xs font-bold self-center">
                                ({discountType})
                              </p>
                            </div>
                            <p>{discount}%</p>
                          </div>
                        )}
                        <div>
                          <div className="flex justify-between mt-5">
                            <div className="flex gap-1 items-center">
                              <p>Difference in price</p>
                            </div>
                            <p>
                              {`${numberWithCommas(
                                (Number(item?.rentInner) *
                                  ((100 - discount) / 100) +
                                  (showCleaning
                                    ? Number(
                                      item?.propertyDetails?.cleaningFee
                                    )
                                    : 0) -
                                  (Number(options.rent) *
                                    ((100 - Number(discount)) / 100) +
                                    (showCleaning
                                      ? Number(selectedProperty?.cleaningFee)
                                      : 0))) *
                                exchangeRate
                              )} ${currency}`}
                            </p>
                          </div>
                        </div>
                        <div>
                          <div
                            className="w-full border-[1px] border-slate-150 my-2"/>
                          <div className="flex justify-between">
                            <div className="flex gap-1 items-center">
                              <p className="font-bold">Total</p>
                              <p className="text-xs font-bold">{`(${currency ===
                              'EUR'
                                ? '€ '
                                : currency === 'AED'
                                  ? 'AED '
                                  : '£ '
                              }${(
                                (Number(item?.rentInner) /
                                  Number(options.days)) *
                                ((100 - Number(discount)) / 100) *
                                exchangeRate
                              ).toFixed(2)} per night)`}</p>
                            </div>
                            <p className="font-bold">
                              {`${numberWithCommas(
                                (Number(item?.rentInner) *
                                  ((100 - discount) / 100) +
                                  (showCleaning
                                    ? Number(
                                      item?.propertyDetails?.cleaningFee
                                    )
                                    : 0)) *
                                exchangeRate
                              )} ${currency}`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }

                return null
              })}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default RelocationTool
