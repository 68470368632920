export default function PropertyCard({ propertyDetails, setItemId }) {
  if(Object.prototype.hasOwnProperty.call(propertyDetails, 'details')){
  return (
    <div
      key={propertyDetails.itemId}
      className="flex flex-col juntify-center w-[360px] rounded-xl border-2 border-grey p-5 hover:shadow-2xl h-full cursor-pointer"
      onClick={() => {
        setItemId(propertyDetails.itemId);
        localStorage.setItem("itemId", propertyDetails.itemId);
      }}
    >
      <div className="flex justify-center">
        <img
          src={
            propertyDetails.image
              ? propertyDetails.image
              : "/no_image_found.png"
          }
          alt="avatar"
          style={{
            width: "100%",
            borderRadius: "20px",
          }}
        />
      </div>
      <div className="flex flex-col gap-y-1">
        <div
          className={`flex gap-2 ${
            !propertyDetails.image ? "mt-[29px]" : "mt-2"
          }`}
        >
          <h2 className="font-bold text-xs">Tag: </h2>
          <h2 className="text-xs">
            {propertyDetails.details.Tag
              ? propertyDetails.details.Tag
              : "No tag Found"}
          </h2>
        </div>
        <div className="flex gap-2">
          <h2 className="font-bold text-xs">Internal Name: </h2>
          <h2 className="text-xs">
            {propertyDetails.details["Property Name"]
              ? propertyDetails.details["Property Name"]
              : "No name Found"}
          </h2>
        </div>
        <div className="flex gap-2">
          <h2 className="font-bold text-xs">External Name: </h2>
          <h2 className="text-xs">
            {propertyDetails.details["External listing name"]
              ? propertyDetails.details["External listing name"]
              : "No name Found"}
          </h2>
        </div>
      </div>
    </div>
  );
  } else {
    return (
      <div
        key={propertyDetails.listingId}
        className="flex flex-col juntify-center w-[360px] rounded-xl border-2 border-grey p-5 hover:shadow-2xl h-full cursor-pointer"
        onClick={() => {
          setItemId(propertyDetails.listingId);
          localStorage.setItem("itemId", propertyDetails.listingId);
        }}
      >
        <div className="flex justify-center">
          <img
            src={
              propertyDetails.image
                ? propertyDetails.image
                : "/no_image_found.png"
            }
            alt="avatar"
            style={{
              width: "100%",
              borderRadius: "20px",
            }}
          />
        </div>
        <div className="flex flex-col gap-y-1">
          <div
            className={`flex gap-2 ${
              !propertyDetails.image ? "mt-[29px]" : "mt-2"
            }`}
          >
            <h2 className="font-bold text-xs">Tag: </h2>
            <h2 className="text-xs">
            {propertyDetails.basicInfo.tags
                  ? propertyDetails.basicInfo.tags
                  : "No tag Found"}
            </h2>
          </div>
          <div className="flex gap-2">
            <h2 className="font-bold text-xs">Internal Name: </h2>
            <h2 className="text-xs">
            {propertyDetails.basicInfo.internal_listing_name
                  ? propertyDetails.basicInfo.internal_listing_name
                  : "No name Found"}
            </h2>
          </div>
          <div className="flex gap-2">
            <h2 className="font-bold text-xs">External Name: </h2>
            <h2 className="text-xs">
            {propertyDetails.basicInfo.external_listing_name
                  ? propertyDetails.basicInfo.external_listing_name
                  : "No name Found"}
            </h2>
          </div>
        </div>
      </div>
    );
  }
}
