import { Badge, Modal } from "antd";
import GenericButton from "../GenericButton/GenericButton";
import { toast } from "react-toastify";
import { apiInstance } from "../../utils/API";
import { useState } from "react";
import { format } from 'date-fns'

export default function CleaningDetails (props) {
  const { isModalOpen, setIsModalOpen, setActions, cleanings } = props;
  const data = isModalOpen || null;

  const [openRecurringModal, setIsOpenRecurringModal] = useState(false);

  const status = data.status || "";
  let color;
  if (status === "pending") {
    color = "red";
  } else if (status === "inProgress") {
    color = "orange";
  } else if (status === "completed") {
    color = "green";
  }

  const removeRecurringCleanings = async () => {
    try {
      await apiInstance.delete(`/api/cleaning/recurring/${data.recurringId}?userName=${localStorage.getItem(
        "userName"
      )}&property=${data?.allocatedProperty}`);

      setIsOpenRecurringModal(false);
      setIsModalOpen(null);
      setActions(cleanings.filter((el) => el.recurringId !== data.recurringId));

      toast.success("Cleanings deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.log("Error", error);
      toast.error("Error deleting the cleaning.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const removeOneCleaning = async (item) => {
    try {
      await apiInstance.delete(`/api/cleaning/${item._id}?userName=${localStorage.getItem(
        "userName"
      )}`);
      setIsOpenRecurringModal(false);
      setIsModalOpen(null);
      setActions(cleanings.filter((el) => el._id !== item._id));

      toast.success("Cleaning deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.log("Error", error);
      toast.error("Error deleting the cleaning.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const handleDeleteItem = async () => {
    if (data.isRecurringCleaning) {
      setIsOpenRecurringModal(true);
      return;
    }

    await removeOneCleaning(data);
  };

  const handleCancel = () => {
    setIsOpenRecurringModal(false)
  }

  return (
    <>
      <Modal
        open={openRecurringModal}
        onCancel={handleCancel}
        width={380}
        className="pt-[30px]"
        footer={
          <div
            className={`${(data.isRecurringCleaning ||
              data?.added === 'Manually') &&
              'flex justify-center gap-[10px]'
              }`}
          >
            <div
              className="bg-[#f44336] hover:!bg-[#ef5350] text-white 
            h-[32px] rounded-[6px] flex items-center justify-center w-[140px] cursor-pointer"
              onClick={() => removeOneCleaning(data)}
            >
              Delete this cleaning
            </div>

            <div
              className="bg-[#f44336] hover:!bg-[#ef5350] text-white 
            h-[32px] rounded-[6px] flex items-center justify-center w-[70px] cursor-pointer"
              onClick={removeRecurringCleanings}
            >
              Delete all
            </div>

            <GenericButton
              label="Close"
              loadingClass="text-white"
              className="bg-buttonTheme hover:!bg-buttonHoverTheme disabled:bg-buttonTheme disabled:hover:!bg-buttonTheme"
              onClick={() => setIsOpenRecurringModal(false)}
              key={2}
            />
          </div>
        }
      >
        <div className="px-[20px] py-[5px] text-center font-medium">
          <p>
            This is the recurring cleaning. Do you want do delete only this
            cleaning or all?
          </p>
        </div>
      </Modal>
      <Modal
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(null)
        }}
        width={400}
        closable={false}
        title={
          <div className="flex justify-between items-center">
            <div className="w-[80%] text-lg font-bold">
              {data.allocatedProperty}
            </div>
            <div className="w-[15%]">
              <Badge className="capitalize" count={data.status} color={color} />
            </div>
          </div>
        }
        footer={
          <div
            className={`${(data.isRecurringCleaning ||
              data?.added === 'Manually') &&
              'flex justify-between'
              }`}
          >
            {(
              data.isRecurringCleaning ||
              data?.added === 'Manually'
            ) && (
                <div
                  className="bg-[#f44336] hover:!bg-[#ef5350] text-white 
              h-[32px] rounded-[6px] flex items-center justify-center w-[70px] cursor-pointer"
                  onClick={handleDeleteItem}
                >
                  Delete
                </div>
              )}

            <GenericButton
              label="Close"
              loadingClass="text-white"
              className="bg-buttonTheme hover:!bg-buttonHoverTheme disabled:bg-buttonTheme disabled:hover:!bg-buttonTheme"
              onClick={() => setIsModalOpen(null)}
              key={2}
            />
          </div>
        }
      >
        <div>
          <div>
            <div>
              <p className="text-sm  mt-2">{data.address}</p>
            </div>
            <div className="grid grid-cols-2 mt-5 gap-3 ">
              {data.checkOutDate && (
                <div>
                  <p className="font-semibold leading-[normal]">
                    Check-Out Date
                  </p>
                  <p>
                    {data.cleaningScheduled && data?.added === 'Manually'
                      ? format(new Date(data.cleaningScheduled), 'dd/MM/yyyy')
                      : data.checkOutDate}
                  </p>
                </div>
              )}

              {data.nights && (
                <div>
                  <p className="font-semibold leading-[normal]">Nights</p>
                  <p>{data.nights}</p>
                </div>
              )}

              {data.keyStatus && (
                <div>
                  <p className="font-semibold leading-[normal]">Keys</p>
                  <p>{data.keyStatus}</p>
                </div>
              )}

              {data.cleaningEffort && (
                <div>
                  <p className="font-semibold leading-[normal]">Effort</p>
                  <p>{data.cleaningEffort}</p>
                </div>
              )}

              {data.nextReservation && (
                <div>
                  <p className="font-semibold leading-[normal]">
                    Next Reservation
                  </p>
                  <p className="capitalize">{data.nextReservation}</p>
                </div>
              )}

              {data.cleaningScheduled && (
                <div>
                  <p className="font-semibold leading-[normal]">
                    Scheduled Date
                  </p>
                  <p className="capitalize">
                    {format(new Date(data.cleaningScheduled), 'dd/MM/yyyy')}
                  </p>
                </div>
              )}

              <div>
                <p className="font-semibold leading-[normal]">
                  Added
                </p>

                <p className="capitalize">
                  {data.added}
                </p>
              </div>
            </div>

            {data.comment && (
              <div className="mt-4">
                <p className="font-semibold leading-[normal]">Comment</p>
                <p className="capitalize">{data.comment}</p>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}
