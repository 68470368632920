/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Route, Routes } from 'react-router-dom'
// import Overview from '../components/Overview/Overview'
// import Listings from '../pages/Listings/Listings'
import {
  BOOKING_ENGINE_DESIGN,
  BOOKING_ENGINE_LISTINGS,
  BOOKING_ENGINE_PAGES,
  BOOKING_ENGINE_SETTINGS,
  // CALENDAR_MONTHLY,
  // CALENDAR_MULTI,
  // CALENDAR_YEARLY,
  CHANNEL_MANAGER_CHANNELS,
  CHANNEL_MANAGER_LISTING_MAPPING,
  FINANCIAL_REPORTING_ANALYTICS,
  FINANCIAL_REPORTING_EXPENSES_AND_EXTRAS,
  FINANCIAL_REPORTING_FINANCIAL_SETTINGS,
  FINANCIAL_REPORTING_LISTING_FINANCIALS,
  FINANCIAL_REPORTING_OCCUPANCY_REPORTS,
  GUEST_PAYMENTS_AUTO_PAYMENTS,
  GUEST_PAYMENTS_CHARGES,
  GUEST_PAYMENTS_DOCUMENT_TEMPLATES,
  // INBOX_AUTOMATIONS,
  // INBOX_MANAGE_MESSAGES,
  // INBOX_MESSAGE_TEMPLATES,
  // LISTINGS,
  // APARTMENT_LISTING,
  LISTINGS_CUSTOM_FIELDS,
  MARKETPLACE,
  // OVERVIEW,
  OWNER_STATEMENTS_CREATE_AND_EDIT,
  OWNER_STATEMENTS_MANAGE_STATEMENTS,
  // RESERVATIONS,
  RESERVATIONS_COUPONS,
  RESERVATIONS_CUSTOM_FIELDS,
  RESERVATIONS_GUESTBOOK,
  REVIEWS_AUTO_REVIEWS,
  REVIEWS_MANAGE_REVIEWS,
  REVIEWS_REVIEW_TEMPLATES,
  SETTINGS_ACCOUNT,
  SETTINGS_HOSTAWAY_API,
  SETTINGS_INTEGRATIONS,
  SETTINGS_INVOICES_AND_CHARGES,
  SETTINGS_NOTIFICATIONS,
  TASKS_ARCHIVED,
  TASKS_CHECKLIST_TEMPLATES,
  TASKS_CUSTOM_FIELDS,
  TASKS_MANAGE_AUTO_TASKS,
  TASKS_MANAGE_TASKS,
  USER_MANAGEMENT_DETAILS_ADD,
  USER_MANAGEMENT_GROUPS,
  USER_MANAGEMENT_GROUP_ADD,
  USER_MANAGEMENT_USERS,
  // LISTINGS_EDIT_FIELDS,
  // LISTINGS_NEW_FIELDS,
  INBOX_MESSAGES,
  // INBOX_MESSAGES_ADD,
  // INBOX_MESSAGES_ADD_GROUP,
  INBOX_MESSAGE_TEMPLATES_ADD,
  INBOX_MESSAGE_TEMPLATES_ADD_GROUP,
  // RESERVATIONS_EDIT,
  REVIEWS_AUTO_REVIEWS_ADD,
  REVIEWS_REVIEW_TEMPLATES_ADD,
  USER_MANAGEMENT_DETAILS,
  USER_MANAGEMENT_GROUP_DETAILS,
  LOGS,
  // RESERVATION_TOOL,
  // RESERVATIONS_DETAILS,
  CLEANING,
  VIEWS_RESERVATIONS,
  VIEWS_ALL_RESERVATIONS,
  // VIEWS_AVAILABLE_PROPERTIES,
  RESERVATION_REPORT_NEW,
  RESERVATION_REPORT_CANCELED,
  // AUTOMATION_NEW,
  CHECKIN_INSTRUCTIONS,
  AVAILABLE_PROPERTIES,
  SYNC_DATA,
  RELOCATION_TOOL,
  FINANCE,
  PROPETY_REPORT,
  RESERVATION_OVERVIEW,
  GUEST_COM,
  KEYS,
} from '../constants/dashboardRoutes'
import Marketplace from '../components/Marketplace/Marketplace'
import MarketplaceCardDetails
  from '../components/MarketplaceCardDetails/MarketplaceCardDetails'
import Review from '../components/Reviews/Reviews/Reviews'
import BookingEngine from '../pages/BookingEngine/BookingEngine'
// import ApartmentListing from '../components/ApartmentListing/ApartmentListing'
import ChannelManager from '../pages/ChannelManager/ChannelManager'
import CreateAndEdit from '../components/CreateEdit/createAndEdit'
import ManageStatements from '../components/CreateEdit/manageStatements'
import ListingsCustomFields
  from '../pages/ListingsCustomFields/ListingsCustomFields'
import ReservationsCustomFields
  from '../pages/ReservationsCustomFields/ReservationsCustomFields'
import DashboardSettings from '../pages/DashboardSettings/DashboardSettings'
import TasksCustomFields from '../pages/TasksCustomFields/TasksCustomFields'
import GuestPayments from '../pages/GuestPayments/GuestPayments'
// import ListingsNewFields from '../pages/ListingsNewFields/ListingsNewFields'
// import ListingsEditFields from '../pages/ListingsEditFields/ListingsEditFields'
import ManageTasks from '../pages/Tasks/ManageTasksPage/ManageTasks'
import ManageAutoTasks from '../pages/Tasks/ManageAutoTasks/manageAutoTasks'
import ChecklistTemplates
  from '../pages/Tasks/ChecklistTemplates/ChecklistTemplates'
import Archived from '../pages/Tasks/Archived/Archived'
import FinancialReporting from '../pages/FinancialReporting/FinancialReporting'
import FinancialReportingSettings
  from '../pages/FinancialReportingSettings/FinancialReportingSettings'
import Inbox from '../pages/Inbox/Inbox'
import AddMessageTemplate
  from '../components/AddMessageTemplate/AddMessageTemplate'
import AddMesssageTemplateGroup
  from '../components/AddMessageTemplateGroup/AddMessageTemplateGroup'
// import Reservations from '../pages/Reservations/Reservations'
// import AddReservation from '../pages/Reservations/AddReservation'
import ReservationGuestbook
  from '../pages/ReservationGuestbook/ReservationGuestbook'
import ReservationCoupons from '../pages/ReservationCoupons/ReservationCoupons'
import AutoReviewsAdd from '../pages/AutoReviewsAdd/AutoReviewsAdd'
import ReviewTemplatesAdd
  from '../components/ReviewTemplatesAdd/ReviewTemplatesAdd'
import UserManagement from '../pages/UserManagment/Index'
import UserForm from '../pages/UserManagment/Users/UserForm'
import GroupForm from '../pages/UserManagment/Groups/GroupForm'
import UserDetails from '../pages/UserManagment/Users/UserDetails'
import GroupDetails from '../pages/UserManagment/Groups/GroupDetails'
import Actions from '../pages/Actions/Actions'
import PageNotFound from '../pages/PageNotFound'
// import CalendarMulti from '../pages/Calendar/CalendarMulti'
// import CalendarMonthly from '../pages/Calendar/CalendarMonthly'
// import CalendarYearly from '../pages/Calendar/CalendarYearly'
// import ReservationTool from '../pages/ReservationTool/ReservationTool'
import RelocationTool from '../pages/RelocationTool/RelocationTool'
import Cleaning from '../pages/Cleaning'
import ViewReservations from '../pages/Views/Reservations'
import AllReservations from '../pages/Views/AllReservations'
// import ViewAvailableProperties from '../pages/Views/AvailableProperties'
// import AddAutomation from '../pages/Inbox/AddAutomation'
import ReservationsReports
  from '../pages/ReservationsReports/ReservationsReports'
import CancelledReservationsReports
  from '../pages/ReservationsReports/CancelledReservationsReports'
// import Automation from '../pages/Automation/Automation'
import CheckinInstructions
  from '../pages/CheckinInstructions/CancelledReservationsReports'
import PropertyAvailability from '../pages/Views/PropertyAvailability'
import SyncData from '../pages/SyncData/SyncData'
import Finance from '../pages/Finance/Finance'
import PropertyReport from '../pages/PropertyReport/PropertyReport'
import ReservationsOverview
  from '../pages/ReservationsOverview/ReservationsOverview'
import GuestCom from '../pages/GuestCom/GuestCom'
import KeysPage from '../pages/Keys'

const DashboardRouter = () => {
  return (
    <div>
      <Routes>
        <Route path="*" exact={true} element={<PageNotFound />} />
        <Route path="/404" exact={true} element={<PageNotFound />} />
        <Route path={LOGS} element={<Actions />} />
        {/* <Route path={RESERVATION_TOOL} element={<ReservationTool />} /> */}
        <Route path={GUEST_COM} element={<GuestCom />} />
        <Route path={RELOCATION_TOOL} element={<RelocationTool />} />
        <Route path={PROPETY_REPORT} element={<PropertyReport />} />
        {/* <Route path={OVERVIEW} element={<Overview />} /> */}
        {/* <Route path={CALENDAR_MULTI} element={<CalendarMulti />} /> */}
        {/* <Route path={CALENDAR_MONTHLY} element={<CalendarMonthly />} /> */}
        {/* <Route path={CALENDAR_YEARLY} element={<CalendarYearly />} /> */}
        {/* <Route path={RESERVATIONS} element={<Reservations />} /> */}
        {/* <Route path={RESERVATIONS_EDIT} element={<AddReservation />} /> */}
        {/* <Route path={RESERVATIONS_DETAILS} element={<AddReservation />} /> */}
        <Route path={CHECKIN_INSTRUCTIONS} element={<CheckinInstructions />} />
        <Route path={AVAILABLE_PROPERTIES} element={<PropertyAvailability />} />
        <Route path={RESERVATION_OVERVIEW} element={<ReservationsOverview />} />
        <Route path={FINANCE} element={<Finance />} />
        <Route path={SYNC_DATA} element={<SyncData />} />

        <Route
          path={RESERVATION_REPORT_NEW}
          element={<ReservationsReports />}
        />
        <Route
          path={RESERVATION_REPORT_CANCELED}
          element={<CancelledReservationsReports />}
        />

        <Route path={CLEANING} element={<Cleaning />} />
        <Route path={VIEWS_RESERVATIONS} element={<ViewReservations />} />
        <Route path={VIEWS_ALL_RESERVATIONS} element={<AllReservations />} />
        {/* <Route
          path={VIEWS_AVAILABLE_PROPERTIES}
          element={<ViewAvailableProperties />}
        /> */}

        <Route
          path={RESERVATIONS_CUSTOM_FIELDS}
          element={<ReservationsCustomFields />}
        />
        <Route
          path={RESERVATIONS_GUESTBOOK}
          element={<ReservationGuestbook />}
        />
        <Route path={RESERVATIONS_COUPONS} element={<ReservationCoupons />} />

        <Route path={INBOX_MESSAGES} element={<Inbox activeKey="messages" />} />
        <Route
          path={INBOX_MESSAGE_TEMPLATES_ADD}
          element={<AddMessageTemplate />}
        />
        <Route
          path={INBOX_MESSAGE_TEMPLATES_ADD_GROUP}
          element={<AddMesssageTemplateGroup />}
        />

        {/* <Route
          path={INBOX_MESSAGE_TEMPLATES}
          element={<Inbox activeKey="message-templates" />}
        />
        <Route
          path={INBOX_AUTOMATIONS}
          element={<Inbox activeKey="automations" />}
        /> */}

        {/* <Route path={INBOX_AUTOMATIONS + "/:id"} element={<AddAutomation />} /> */}
        {/* <Route
          path={INBOX_MANAGE_MESSAGES}
          element={<Inbox activeKey="manage-messages" />}
        /> */}

        <Route path={TASKS_MANAGE_TASKS} element={<ManageTasks />} />
        <Route path={TASKS_MANAGE_AUTO_TASKS} element={<ManageAutoTasks />} />
        <Route
          path={TASKS_CHECKLIST_TEMPLATES}
          element={<ChecklistTemplates />}
        />
        <Route path={TASKS_ARCHIVED} element={<Archived />} />
        <Route path={TASKS_CUSTOM_FIELDS} element={<TasksCustomFields />} />
        <Route
          path={REVIEWS_MANAGE_REVIEWS}
          element={<Review activeKey="reviews" />}
        />
        <Route
          path={REVIEWS_AUTO_REVIEWS}
          element={<Review activeKey="auto" />}
        />
        <Route path={REVIEWS_AUTO_REVIEWS_ADD} element={<AutoReviewsAdd />} />
        <Route
          path={REVIEWS_REVIEW_TEMPLATES}
          element={<Review activeKey="templates" />}
        />
        <Route
          path={REVIEWS_REVIEW_TEMPLATES_ADD}
          element={<ReviewTemplatesAdd />}
        />
        <Route
          path={GUEST_PAYMENTS_CHARGES}
          element={<GuestPayments activeKey="charges" />}
        />
        <Route
          path={GUEST_PAYMENTS_AUTO_PAYMENTS}
          element={<GuestPayments activeKey="auto-payments" />}
        />
        <Route
          path={GUEST_PAYMENTS_DOCUMENT_TEMPLATES}
          element={<GuestPayments activeKey="document-templates" />}
        />
        <Route
          path={FINANCIAL_REPORTING_ANALYTICS}
          element={<FinancialReporting defaultActiveKey="analytics" />}
        />
        <Route
          path={FINANCIAL_REPORTING_LISTING_FINANCIALS}
          element={<FinancialReporting defaultActiveKey="listings" />}
        />
        <Route
          path={FINANCIAL_REPORTING_OCCUPANCY_REPORTS}
          element={<FinancialReporting defaultActiveKey="occupancy" />}
        />
        <Route
          path={FINANCIAL_REPORTING_EXPENSES_AND_EXTRAS}
          element={
            <FinancialReporting defaultActiveKey="expenses-and-extras" />
          }
        />
        <Route
          path={FINANCIAL_REPORTING_FINANCIAL_SETTINGS}
          element={<FinancialReportingSettings />}
        />
        <Route
          path={OWNER_STATEMENTS_CREATE_AND_EDIT}
          element={<CreateAndEdit />}
        />
        <Route
          path={OWNER_STATEMENTS_MANAGE_STATEMENTS}
          element={<ManageStatements />}
        />
        {/* <Route path={LISTINGS} element={<Listings />} /> */}
        {/* <Route path={APARTMENT_LISTING} element={<ApartmentListing />} /> */}
        <Route
          path={LISTINGS_CUSTOM_FIELDS}
          element={<ListingsCustomFields />}
        />
        {/* <Route path={LISTINGS_EDIT_FIELDS} element={<ListingsEditFields />} /> */}
        {/* <Route path={LISTINGS_NEW_FIELDS} element={<ListingsNewFields />} /> */}
        {/* <Route path={AUTOMATION_NEW} element={<AddAutomation />} /> */}
        <Route
          path={CHANNEL_MANAGER_LISTING_MAPPING}
          element={<ChannelManager defaultActiveKey="listing-mapping" />}
        />
        <Route
          path={CHANNEL_MANAGER_CHANNELS}
          element={<ChannelManager defaultActiveKey="channels" />}
        />

        <Route
          path={KEYS}
          element={<KeysPage />}
        />

        <Route
          path={USER_MANAGEMENT_USERS}
          element={<UserManagement defaultActiveKey="users" />}
        />
        <Route path={USER_MANAGEMENT_DETAILS_ADD} element={<UserForm />} />
        <Route path={USER_MANAGEMENT_DETAILS} element={<UserDetails />} />

        <Route
          path={USER_MANAGEMENT_GROUPS}
          element={<UserManagement defaultActiveKey="groups" />}
        />
        <Route path={USER_MANAGEMENT_GROUP_ADD} element={<GroupForm />} />
        <Route
          path={USER_MANAGEMENT_GROUP_DETAILS}
          element={<GroupDetails />}
        />
        <Route
          path={BOOKING_ENGINE_DESIGN}
          element={<BookingEngine defaultActiveKey="design" />}
        />
        <Route
          path={BOOKING_ENGINE_LISTINGS}
          element={<BookingEngine defaultActiveKey="listings" />}
        />
        <Route
          path={BOOKING_ENGINE_PAGES}
          element={<BookingEngine defaultActiveKey="pages" />}
        />
        <Route
          path={BOOKING_ENGINE_SETTINGS}
          element={<BookingEngine defaultActiveKey="settings" />}
        />
        <Route path={MARKETPLACE} element={<Marketplace />} />
        <Route
          path={MARKETPLACE + '/:cardTitle'}
          element={<MarketplaceCardDetails />}
        />
        <Route
          path={SETTINGS_ACCOUNT}
          element={<DashboardSettings defaultActiveKey="account" />}
        />
        <Route
          path={SETTINGS_INVOICES_AND_CHARGES}
          element={<DashboardSettings defaultActiveKey="invoices" />}
        />
        <Route
          path={SETTINGS_INTEGRATIONS}
          element={<DashboardSettings defaultActiveKey="integrations" />}
        />
        <Route
          path={SETTINGS_HOSTAWAY_API}
          element={<DashboardSettings defaultActiveKey="hostaway-api" />}
        />
        <Route
          path={SETTINGS_NOTIFICATIONS}
          element={<DashboardSettings defaultActiveKey="notifications" />}
        />
      </Routes>
    </div>
  )
}

export default DashboardRouter
