import FormCheckBox from "../../Form/FormCheckBox";

export default function AccessPermissions({ isDetails }) {
    return <>
        <div className="flex flex-col gap-2 mb-5">
            <FormCheckBox disabled={isDetails} label="Allow access to contact data" name="accessContactData" />
            <FormCheckBox disabled={isDetails} label="Allow access to financial data" name="accessFinancialData" />
            <FormCheckBox disabled={isDetails} label="Allow access to newly added listings" name="hasAccessToNewListings"
                toolTipFlag={true} tooltipTitle="Every time a new listing is added, this user will have access to that listing by default" />
            <FormCheckBox disabled={isDetails} label="Users in this group cannot see users from other groups" name="cannotSeeUsersOfOtherGroups" />
        </div>
    </>

}