import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import DiamondIcon from "@mui/icons-material/Diamond";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import WidgetsIcon from "@mui/icons-material/Widgets";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import { Button } from "antd";

function SummaryMenu() {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="mt-8">
      <div className="flex justify-between">
        <h1 className="text-xl font-semibold">Summary</h1>
        {isExpanded ? (
          <KeyboardArrowDownIcon
            className="cursor-pointer"
            onClick={handleClick}
          />
        ) : (
          <KeyboardArrowLeftIcon
            className="cursor-pointer"
            onClick={handleClick}
          />
        )}
      </div>
      {isExpanded && (
        <>
          <div className="mt-4">
            <span>Statement period: </span>
            <span className="font-bold">2023-07-01 — 2023-07-22 </span>
            <span>for </span>
            <span className="font-bold">1 </span>
            <span>booking(s).</span>
          </div>
          <div className="grid grid-cols-1 xs:grid-cols-2  sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 vlg:grid-cols-6 gap-2 sm:gap-2 md:gap-4 lg:gap-6 vlg:gap-8">
            <div>
              <div className="m-2">
                <LocalAtmIcon className="mt-1" />
                <p className="text-lg">Owner Payout</p>
              </div>
              <p className="font-bold text-2xl ml-3 mt-2">$300.00</p>
            </div>
            <div>
              <div className="m-2">
                <WidgetsIcon className="mt-1" />
                <p className="text-lg">PM Commission</p>
              </div>
              <p className="font-bold text-2xl ml-3 mt-2">$300.00</p>
            </div>
            <div>
              <div className="m-2">
                <DiamondIcon className="mt-1" />
                <p className="text-lg">Rental Revenue</p>
              </div>
              <p className="font-bold text-2xl ml-3 mt-2">$300.00</p>
            </div>
            <div>
              <div className="m-2">
                <HomeWorkIcon className="mt-1" />
                <p className="text-lg">Total Guest Fees</p>
              </div>
              <p className="font-bold text-2xl ml-3 mt-2">$300.00</p>
            </div>
            <div>
              <div className="m-2">
                <AccountBalanceIcon className="mt-1" />
                <p className="text-lg">Total Tax</p>
              </div>
              <p className="font-bold text-2xl ml-2 mt-2">$300.00</p>
            </div>
          </div>
          <div className="flex justify-end">
            <Button
              className="text-buttonTheme font-semibold hover:!bg-transparent hover:!text-buttonHoverTheme"
              type="text"
              icon={<EditOutlinedIcon />}
            >
              Edit
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default SummaryMenu;
