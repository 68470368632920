import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import GenericButton from "../GenericButton/GenericButton";
import { ColorPicker, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";

const AddMessageTemplate = () => {
  const navigate = useNavigate();
  return (
    <div className="px-5 py-5 bg-white">
      <div className="flex items-center justify-between">
        <Link
          className="text-themeGreen"
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon className="text-themeGreen" />
          Back
        </Link>
        <GenericButton
          label="Save"
          className="text-white bg-themeGreen rounded-none"
        />
      </div>
      <div className="grid xs:grid-cols-1 md:grid-cols-2 gap-3">
        <div>
          <div>Template name</div>
          <Input placeholder="Template name" className="rounded-none w-full" />
        </div>
        <div>
          <div>Template description</div>
          <Input
            placeholder="Template description"
            className="rounded-none w-full"
          />
        </div>
        <div>
          <div>Channel</div>
          <Select placeholder="Channel" className="rounded-none w-full" />
        </div>
        <div>
          <div>Group name</div>
          <Select placeholder="Group name" className="rounded-none w-full" />
        </div>
        <div>
          <div>Listings</div>
          <Select placeholder="Group name" className="rounded-none w-full" />
        </div>
        <div className="text-white">1</div>
        <div className="col-span-2">
          <div>Message</div>
          <TextArea
            placeholder="Group name"
            className="rounded-none w-full"
            rows={4}
          />
        </div>
        <div>
          <div>Template Color</div>
          <ColorPicker
            placeholder="Group name"
            className="rounded-none w-[50%]"
          />
        </div>
      </div>
    </div>
  );
};

export default AddMessageTemplate;
