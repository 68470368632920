import { differenceInMonths, format, subMonths } from 'date-fns'
import * as XLSX from 'xlsx'
import { camelCase } from 'lodash'

export const getSummaryMonthsLabels = (order = 'asc', dateFormat = 'MMM-yyyy') => {
  const startDate = '2023-01'
  const endDate = format(subMonths(new Date(), 1), 'yyyy-MM')

  return getDateRangeArray(
    startDate, endDate, dateFormat, order,
  )
}

export const getDateRange = threshold => {
  const startDate = subMonths(new Date(), threshold)
  const currentDate = subMonths(new Date(), 1)

  const range = [startDate, currentDate]

  return range.map(date => format(date, 'yyyy-MM'))
}

export const getDateRangeArray = (
  start, end, dateFormat = 'MMMM:yyyy', order = 'asc',
) => {
  let datesArray = []
  let startDate = new Date(`${end}-01`)
  let endDate = new Date(`${start}-01`)

  const diff = differenceInMonths(startDate, endDate)

  for (let i = 0; i <= diff; i++) {
    const prevMonth = subMonths(startDate, i)
    const date = format(prevMonth, dateFormat)

    if (order === 'asc') {
      datesArray.unshift(date)
    } else {
      datesArray.push(date)
    }
  }

  return datesArray
}

export const changeFieldName = fieldName => {
  const mapField = {
    'Cost of Sales': SUMMARY_TABLE_ROW_NAMES.COGGS,
    'Administrative Costs': SUMMARY_TABLE_ROW_NAMES.OPERATING_EXPENSES,
    'Other Income': SUMMARY_TABLE_ROW_NAMES.CAPEX_COST,
    'Taxation': SUMMARY_TABLE_ROW_NAMES.VAT,
  }[fieldName]

  if(!mapField) {
    return fieldName
  }

  return mapField
}

export const normalizeMonthData = (data) => {
  if (!data?.margins || !data?.accounts) {
    return {}
  }

  const margins = []
  const numberValues = []
  const summaryTableData = []

  for (const [key, values] of Object.entries(data.margins)) {
    margins.push({
      name: key,
      key: camelCase(key),
      percentage: true,
      ...values
    })
  }

  for (const [key, values] of Object.entries(data.accounts)) {
    const cashTableObj = {
      name: changeFieldName(key),
      key: camelCase(changeFieldName(key)),
      ...values.total
    }

    if (values?.children) {
      cashTableObj.children = []
      cashTableObj.isParent = true
      const entries = Object.entries(values.children)[0]
      const name = entries[0]
      const value = entries[1].total

      const firstLevelChildKey = changeFieldName(key) + ' ' + changeFieldName(name)
      const firstLevelChildObj = {
        name: changeFieldName(name),
        key: camelCase(firstLevelChildKey),
        isChild: true,
        isParent: true,
        ...value
      }

      if (entries[1]?.children) {
        firstLevelChildObj.children = []
        cashTableObj.isParent = true

        for (const secondLevelChild of Object.entries(entries[1].children)) {
          const [secondLevelChildKey, secondLevelChildValue] = secondLevelChild

          const rowKey = firstLevelChildKey + ' ' + secondLevelChildKey
          const childObj = {
            name: changeFieldName(secondLevelChildKey),
            key: camelCase(rowKey),
            isChild: true,
            ...secondLevelChildValue
          }

          firstLevelChildObj.children.push(childObj)
        }
      }

      cashTableObj.children.push({ ...firstLevelChildObj })
      numberValues.push({ name: changeFieldName(name), ...value })
    }

    summaryTableData.push(cashTableObj)
    numberValues.push({ name: changeFieldName(key), ...values.total })
  }

  return {
    summaryTableData,
    summaryMarginsTableData: [...margins],
    netIncome: {
      ...data.accounts['Net Profits'].total
    },
    costsOfGoods: {
      ...data.accounts['Total revenue'].children['Cost of Sales'].total
    },
    ebita: {
      ...data.accounts['EBITA'].total
    },
    graphData: [
      ...margins,
      ...numberValues
    ]
  }
}

export const calculateTotalValueByRange = (range, data) => {
  return range.reduce((acc, valueKey) => {
    if (data.hasOwnProperty(valueKey)) {
      return acc + Number(data[valueKey])
    } else {
      return acc
    }
  }, 0)
}

export const SUMMARY_TABLE_ROW_NAMES = {
  TOTAL_REVENUE: 'Total revenue',
  COGGS: 'Cost of Goods Sold',
  GROSS_PROFIT: 'Gross profit',
  OPERATING_EXPENSES: 'Operating expenses',
  PRE_CAPEX: 'Operating Profits',
  CAPEX_COST: 'Capex cost',
  POST_CAPEX: 'EBITA',
  VAT: 'VAT',
  NET_INCOME: 'Net Profits',

  GROSS_MARGIN: 'Gross Profit Margin',
  PRE_CAPEX_MARGIN: 'Operating Profit Margin',
  POST_CAPEX_MARGIN: 'EBITA Margin',
  NET_MARGIN: 'Net Profit Margin',
}

export const ROW_NAMES_WITH_BG = [
  SUMMARY_TABLE_ROW_NAMES.GROSS_PROFIT,
  SUMMARY_TABLE_ROW_NAMES.NET_INCOME,
  SUMMARY_TABLE_ROW_NAMES.NET_MARGIN,
]

export const handleDataModification = (record) => {
  let keys = [
    'propertyName',
    'netRevenue',
    'expense', // Direct Costs
    'profit',
    'grossRevenueMargen',
    'occupancyRate',
    'contractStartDate',
  ]
  let displays = [
    'Property Name',
    'Net revenue (GBP)',
    'Direct Costs (GBP)',
    'Gross profit (GBP)',
    'Gross Profit Margen (%)',
    'Occupancy Rate (%)',
    'Start Date (dd/mm/yyyy)',
  ]
  let values = []

  record.forEach((item) => {
    let dataArray = []
    keys.forEach((key) => {
      let value = item[key]
      if (key === 'expense' &&
        (value === undefined || value === null || isNaN(value))) {
        value = 'Long / Short let management'
      } else if (key === 'netRevenue' || key === 'expense' || key ===
        'profit') {
        value = parseFloat(value)
      } else if (key === 'grossRevenueMargen' || key === 'occupancyRate') {
        value = parseFloat(value) / 100 // Store as decimal for Excel
      } else if (key === 'contractStartDate') {
        const date = new Date(value)
        const formattedDate = `${String(date.getDate())
          .padStart(2, '0')}/${String(date.getMonth() + 1)
          .padStart(2, '0')}/${date.getFullYear()}`
        value = formattedDate
      }
      dataArray.push(value)
    })
    values.push(dataArray)
  })

  return [[...displays], ...values]
}

export const handleDownloadCSV = (record) => {
  const data = handleDataModification(record)
  const ws = XLSX.utils.aoa_to_sheet(data)

  // Define column widths
  ws['!cols'] = [
    { wch: 35 }, // Property Name
    { wch: 15 }, // Net revenue
    { wch: 15 }, // Direct Costs
    { wch: 15 }, // Gross profit
    { wch: 20 }, // Gross Profit Margen
    { wch: 20 }, // Occupancy Rate
    { wch: 15 }, // Start Date
  ]

  // Format numerical data, percentages, and dates
  data.forEach((row, rowIndex) => {
    row.forEach((cell, colIndex) => {
      const cellRef = XLSX.utils.encode_cell({ c: colIndex, r: rowIndex })
      if (rowIndex === 0) {
        ws[cellRef].t = 's' // Header row as text
      } else {
        if (colIndex === 2 && cell === 'Long / Short let management') {
          ws[cellRef].t = 's' // Treat as text
        } else if (colIndex === 1 || colIndex === 2 || colIndex === 3) {
          // Financial figures in GBP
          ws[cellRef].t = 'n'
          ws[cellRef].z = '£0.00'
          ws[cellRef].v = parseFloat(cell)
        } else if (colIndex === 4 || colIndex === 5) {
          // Percentages
          if (typeof cell === 'number') {
            ws[cellRef].t = 'n'
            ws[cellRef].z = '0.00%'
          } else {
            ws[cellRef].t = 's'
          }
        } else if (colIndex === 6) {
          // Start Date
          ws[cellRef].t = 's'
          ws[cellRef].z = 'dd/mm/yyyy'
        } else {
          ws[cellRef].t = 's' // Property Name and other text fields
        }
      }
    })
  })

  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1')
  XLSX.writeFile(wb, 'Finance.xlsx', { bookType: 'xlsx', type: 'blob' })
}