import React, { useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import GenericButton from "../../components/GenericButton/GenericButton";
import connectYourAccount from "../../assets/connectYourAccount.svg";
import ChannelManagerCreateNewAccountModal from "../../components/ChannelManagerCreateNewAccountModal/ChannelManagerCreateNewAccountModal";
import ChannelManagerConnectExistingAccountModal from "../../components/ChannelManagerConnectExistingAccountModal/ChannelManagerConnectExistingAccountModal";

const ChannelManagerChannelDetails = ({
  detailsPageData,
  setIsDetailsPage,
}) => {
  const [isNewAccountModalOpen, setIsNewAccountModalOpen] = useState(false);
  const [isExistingAccountModalOpen, setIsExistingAccountModalOpen] =
    useState(false);

  return (
    <div>
      <ChannelManagerCreateNewAccountModal
        isModalOpen={isNewAccountModalOpen}
        setIsModalOpen={setIsNewAccountModalOpen}
      />
      <ChannelManagerConnectExistingAccountModal
        isModalOpen={isExistingAccountModalOpen}
        setIsModalOpen={setIsExistingAccountModalOpen}
      />
      <div className="flex items-center justify-between">
        <div
          className="text-themeGreen cursor-pointer"
          onClick={() => {
            setIsDetailsPage(false);
          }}
        >
          <KeyboardArrowLeftIcon
            className="text-themeGreen mr-1"
            style={{ fontSize: 16 }}
          />
          Channel
        </div>
        <div className="flex items-center gap-3">
          <GenericButton
            label="Connect existing AirBnb account"
            className="bg-themeGreen text-white rounded-none"
            onClick={() => {
              setIsExistingAccountModalOpen(true);
            }}
          />
          <GenericButton
            label="Create new Airbnb account"
            className="bg-lightButtonHoverTheme text-themeGreen rounded-none"
            onClick={() => {
              setIsNewAccountModalOpen(true);
            }}
          />
        </div>
      </div>
      <div className="flex items-center justify-center my-10">
        <img src={detailsPageData.logo.props.src} className="w-72" />
      </div>
      <div className="flex items-center justify-center my-10">
        <img src={connectYourAccount} className="w-60" />
      </div>
    </div>
  );
};

export default ChannelManagerChannelDetails;
