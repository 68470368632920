import React from 'react'
import { Form } from 'antd'

import { manageToast } from '../../../components/Common/ManageToast'
import { serverlessApi } from '../../../utils/API'

import LockBoxModal from './Modal'

function LockBoxUpdateModal (props) {
  const { lockBox, onClose, open, triggerLockBoxCollectionRefetch } = props

  const [form] = Form.useForm()

  const [loading, setLoading] = React.useState(false)

  const initialValues = React.useMemo(() => {
    if (!lockBox) {
      return {}
    }

    return {
      propertyId: Number(lockBox.PropertyID),
      lockboxName: lockBox.LockBoxName,
      code: lockBox.Code,
      status: lockBox.status
    }
  }, [lockBox])

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  const handleLockBoxUpdate = values => {
    return Promise.resolve()
      .then(() => setLoading(true))
      .then(() => {
        return serverlessApi.updateLockBox(lockBox._id, { ...values }).then(res => {
          triggerLockBoxCollectionRefetch()
          setLoading(false)
          manageToast(true, res.data.message)
          handleClose()
        })
      })
      .catch(() => setLoading(false))
  }

  return (
    <LockBoxModal
      form={form}
      formName="lockbox-update"
      handleClose={handleClose}
      handleSubmit={handleLockBoxUpdate}
      initialValues={initialValues}
      open={open}
      submitButtonLabel="Update"
      loading={loading}
      title="Update LockBox"
    />
  )
}

export default React.memo(LockBoxUpdateModal)
