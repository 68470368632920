import React from "react";
import GenericButton from "../GenericButton/GenericButton";
import { Input } from "@mui/material";
import { Select } from "antd";
import ListingCard from "../ListingCard/ListingCard";

const AddListingCategory = () => {
  return (
    <div className="my-5">
      <div className="flex items-center justify-between border-b border-themeGrey border-dotted">
        <div className="text-themeGrey text-sm">Category 1</div>
        <GenericButton
          label="Remove"
          size="middle"
          className="bg-red-500 rounded-none w-28"
        />
      </div>
      <div className="grid grid-cols-2 gap-4 my-5">
        <div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-4">
          <Input placeholder="Listing category name" className="w-[100%]" />
          <div>
            <div className="text-xs text-themeGrey">Tag Color</div>
            <div className="h-8 w-12 border border-black rounded-md bg-red-500"></div>
          </div>
          <div className="grid xs:grid-cols-1 lg:grid-cols-2  gap-3">
            <Input
              placeholder="Type to search listings"
              className="xs:w-[100%] lg:w-[50%]"
            />
            <Select
              placeholder="Select Tags"
              className="xs:w-[100%] lg:w-[50%]"
            />
          </div>
          <GenericButton
            label="Select All"
            size="middle"
            className="bg-themeSecondaryGreen rounded-none w-20"
          />
          <ListingCard />
          <ListingCard />
        </div>
        <div className="flex items-center justify-center bg-creme">
          <div className="font-thin text-themeGrey">Preview</div>
        </div>
      </div>
    </div>
  );
};

export default AddListingCategory;
