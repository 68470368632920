import { format, parse } from 'date-fns'
import { isEmpty } from 'lodash'

import { getSummaryMonthsLabels, SUMMARY_TABLE_ROW_NAMES } from './helpers'

export const MARGIN_BUTTONS_OPTIONS = [
  {
    label: SUMMARY_TABLE_ROW_NAMES.POST_CAPEX_MARGIN,
    className: 'text-[#00b4b4] border-[1px] border-[#00b4b4] hover:bg-[#00b4b4] hover:bg-opacity-10',
    selectedClassName: 'bg-[#00b4b4] bg-opacity-10',
  },
  {
    label: SUMMARY_TABLE_ROW_NAMES.GROSS_MARGIN,
    className: 'text-[#3b83f6] border-[1px] border-[#3b83f6] hover:bg-[#3b83f6] hover:bg-opacity-10',
    selectedClassName: 'bg-[#3b83f6] bg-opacity-10',
  },
  {
    label: SUMMARY_TABLE_ROW_NAMES.PRE_CAPEX_MARGIN,
    className: 'text-[#01cc3b] border-[1px] border-[#01cc3b] hover:bg-[#01cc3b] hover:bg-opacity-10',
    selectedClassName: 'bg-[#01cc3b] bg-opacity-10',
  },
  {
    label: SUMMARY_TABLE_ROW_NAMES.NET_MARGIN,
    className: 'text-[#b36af7] border-[1px] border-[#b36af7] hover:bg-[#b36af7] hover:bg-opacity-10',
    selectedClassName: 'bg-[#b36af7] bg-opacity-10',
  },
]

export const CASH_VALUES_BUTTONS_OPTIONS = [
  {
    label: SUMMARY_TABLE_ROW_NAMES.TOTAL_REVENUE,
    className: 'text-[#00b49f] border-[1px] border-[#00b49f] hover:bg-[#00b49f] hover:bg-opacity-10',
    selectedClassName: 'bg-[#00b49f] bg-opacity-10',
  },
  {
    label: SUMMARY_TABLE_ROW_NAMES.COGGS,
    className: 'text-[#e10101] border-[1px] border-[#e10101] hover:bg-[#e10101] hover:bg-opacity-10',
    selectedClassName: 'bg-[#e10101] bg-opacity-10',
  },
  {
    label: SUMMARY_TABLE_ROW_NAMES.GROSS_PROFIT,
    className: 'text-[#3b83f6] border-[1px] border-[#3b83f6] hover:bg-[#3b83f6] hover:bg-opacity-10',
    selectedClassName: 'bg-[#3b83f6] bg-opacity-10',
  },
  {
    label: SUMMARY_TABLE_ROW_NAMES.OPERATING_EXPENSES,
    className: 'text-[#a856f4] border-[1px] border-[#a856f4] hover:bg-[#a856f4] hover:bg-opacity-10',
    selectedClassName: 'bg-[#a856f4] bg-opacity-10',
  },
  {
    label: SUMMARY_TABLE_ROW_NAMES.PRE_CAPEX,
    className: 'text-[#00ac09] border-[1px] border-[#00ac09] hover:bg-[#00ac09] hover:bg-opacity-10',
    selectedClassName: 'bg-[#00ac09] bg-opacity-10',
  },
  {
    label: SUMMARY_TABLE_ROW_NAMES.CAPEX_COST,
    className: 'text-[#fab915] border-[1px] border-[#fab915] hover:bg-[#fab915] hover:bg-opacity-10',
    selectedClassName: 'bg-[#fab915] bg-opacity-10',
  },
  {
    label: SUMMARY_TABLE_ROW_NAMES.POST_CAPEX,
    className: 'text-[#ff7032] border-[1px] border-[#ff7032] hover:bg-[#ff7032] hover:bg-opacity-10',
    selectedClassName: 'bg-[#ff7032] bg-opacity-10',
  },
  {
    label: SUMMARY_TABLE_ROW_NAMES.VAT,
    className: 'text-[#e456f4] border-[1px] border-[#e456f4] hover:bg-[#e456f4] hover:bg-opacity-10',
    selectedClassName: 'bg-[#e456f4] bg-opacity-10',
  },
  {
    label: SUMMARY_TABLE_ROW_NAMES.NET_INCOME,
    className: 'text-[#1a40ff] border-[1px] border-[#1a40ff] hover:bg-[#1a40ff] hover:bg-opacity-10',
    selectedClassName: 'bg-[#1a40ff] bg-opacity-10',
  },
]

export const GRAPH_DATASETS_OPTIONS = {
  [SUMMARY_TABLE_ROW_NAMES.POST_CAPEX_MARGIN]: {
    label: SUMMARY_TABLE_ROW_NAMES.POST_CAPEX_MARGIN,
    type: 'line',
    yAxisID: 'y1',
    borderColor: 'rgb(57,204,204)',
    backgroundColor: 'rgba(255,255,255,1)',
    pointBorderColor: 'rgb(0,115,115)',
    pointHoverBackgroundColor: 'rgb(118,236,236)',
    pointHoverBorderColor: 'rgb(0,115,115)',
    pointBorderWidth: 4,
    pointHoverRadius: 6,
    lineTension: 0.3,
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    pointBackgroundColor: '#fff',
  },
  [SUMMARY_TABLE_ROW_NAMES.PRE_CAPEX_MARGIN]: {
    label: SUMMARY_TABLE_ROW_NAMES.PRE_CAPEX_MARGIN,
    type: 'line',
    yAxisID: 'y1',
    borderColor: 'rgb(64,200,105)',
    backgroundColor: 'rgba(255,255,255,1)',
    pointBorderColor: 'rgb(0,115,46)',
    pointHoverBackgroundColor: 'rgb(142,248,174)',
    pointHoverBorderColor: 'rgb(0,115,46)',
    pointBorderWidth: 4,
    pointHoverRadius: 6,
    lineTension: 0.3,
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    pointBackgroundColor: '#fff',
  },
  [SUMMARY_TABLE_ROW_NAMES.GROSS_MARGIN]: {
    label: SUMMARY_TABLE_ROW_NAMES.GROSS_MARGIN,
    type: 'line',
    yAxisID: 'y1',
    borderColor: 'rgba(59,131,246,1)',
    backgroundColor: 'rgba(255,255,255,1)',
    pointBorderColor: 'rgb(1,68,175)',
    pointHoverBackgroundColor: 'rgb(144,188,211)',
    pointHoverBorderColor: 'rgb(1,68,175)',
    pointBorderWidth: 4,
    pointHoverRadius: 6,
    lineTension: 0.3,
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    pointBackgroundColor: '#fff',
  },
  [SUMMARY_TABLE_ROW_NAMES.NET_MARGIN]: {
    label: SUMMARY_TABLE_ROW_NAMES.NET_MARGIN,
    type: 'line',
    yAxisID: 'y1',
    borderColor: 'rgb(207,149,255)',
    backgroundColor: 'rgba(255,255,255,1)',
    pointBorderColor: 'rgb(78,0,154)',
    pointHoverBackgroundColor: 'rgb(229,195,255)',
    pointHoverBorderColor: 'rgb(78,0,154)',
    pointBorderWidth: 4,
    pointHoverRadius: 6,
    lineTension: 0.3,
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    pointBackgroundColor: '#fff',
  },

  [SUMMARY_TABLE_ROW_NAMES.TOTAL_REVENUE]: {
    label: SUMMARY_TABLE_ROW_NAMES.TOTAL_REVENUE,
    minBarLength: 2,
    maxBarThickness: 40,
    lineTension: 0.3,
    borderWidth: 1,
    borderRadius: 2,
    hoverBorderWidth: 2,
    hoverBorderRadius: 4,
    borderColor: 'rgb(0,180,159)',
    hoverBorderColor: 'rgba(0,122,122,1)',
    backgroundColor: 'rgba(0,180,159,0.2)',
    hoverBackgroundColor: 'rgba(0,180,159,0.4)',
  },
  [SUMMARY_TABLE_ROW_NAMES.COGGS]: {
    label: SUMMARY_TABLE_ROW_NAMES.COGGS,
    minBarLength: 2,
    maxBarThickness: 40,
    lineTension: 0.3,
    borderWidth: 1,
    borderRadius: 2,
    hoverBorderWidth: 2,
    hoverBorderRadius: 4,
    borderColor: 'rgba(225,1,1,1)',
    hoverBorderColor: 'rgba(151,1,1,1)',
    backgroundColor: 'rgba(225,1,1,0.2)',
    hoverBackgroundColor: 'rgba(225,1,1,0.4)',
  },
  [SUMMARY_TABLE_ROW_NAMES.GROSS_PROFIT]: {
    label: SUMMARY_TABLE_ROW_NAMES.GROSS_PROFIT,
    minBarLength: 2,
    maxBarThickness: 40,
    lineTension: 0.3,
    borderWidth: 1,
    borderRadius: 2,
    hoverBorderWidth: 2,
    hoverBorderRadius: 4,
    borderColor: 'rgba(59,131,246,1)',
    hoverBorderColor: 'rgba(20,69,147,1)',
    backgroundColor: 'rgba(59,131,246,0.2)',
    hoverBackgroundColor: 'rgba(59,131,246,0.4)',
  },
  [SUMMARY_TABLE_ROW_NAMES.OPERATING_EXPENSES]: {
    label: SUMMARY_TABLE_ROW_NAMES.OPERATING_EXPENSES,
    minBarLength: 2,
    maxBarThickness: 40,
    lineTension: 0.3,
    borderWidth: 1,
    borderRadius: 2,
    hoverBorderWidth: 2,
    hoverBorderRadius: 4,
    borderColor: 'rgba(167,86,244,1)',
    hoverBorderColor: 'rgba(91,33,147,1)',
    backgroundColor: 'rgba(167,86,244,0.2)',
    hoverBackgroundColor: 'rgba(167,86,244,0.4)',
  },
  [SUMMARY_TABLE_ROW_NAMES.PRE_CAPEX]: {
    label: SUMMARY_TABLE_ROW_NAMES.PRE_CAPEX,
    minBarLength: 2,
    maxBarThickness: 40,
    lineTension: 0.3,
    borderWidth: 1,
    borderRadius: 2,
    hoverBorderWidth: 2,
    hoverBorderRadius: 4,
    borderColor: 'rgba(0,99,5,1)',
    hoverBorderColor: 'rgba(0,99,5,1)',
    backgroundColor: 'rgba(0,172,9,0.2)',
    hoverBackgroundColor: 'rgba(0,172,9,0.4)',
  },
  [SUMMARY_TABLE_ROW_NAMES.CAPEX_COST]: {
    label: SUMMARY_TABLE_ROW_NAMES.CAPEX_COST,
    minBarLength: 2,
    maxBarThickness: 40,
    lineTension: 0.3,
    borderWidth: 1,
    borderRadius: 2,
    hoverBorderWidth: 2,
    hoverBorderRadius: 4,
    borderColor: 'rgb(250,185,21)',
    hoverBorderColor: 'rgba(149,110,13,1)',
    backgroundColor: 'rgba(250,185,21,0.2)',
    hoverBackgroundColor: 'rgba(250,185,21,0.4)',
  },
  [SUMMARY_TABLE_ROW_NAMES.POST_CAPEX]: {
    label: SUMMARY_TABLE_ROW_NAMES.POST_CAPEX,
    minBarLength: 2,
    maxBarThickness: 40,
    lineTension: 0.3,
    borderWidth: 1,
    borderRadius: 2,
    hoverBorderWidth: 2,
    hoverBorderRadius: 4,
    borderColor: 'rgba(255,110,50,1)',
    hoverBorderColor: 'rgba(168,63,20,1)',
    backgroundColor: 'rgba(255,110,50,0.2)',
    hoverBackgroundColor: 'rgba(255,110,50,0.4)',
  },
  [SUMMARY_TABLE_ROW_NAMES.VAT]: {
    label: SUMMARY_TABLE_ROW_NAMES.VAT,
    minBarLength: 2,
    maxBarThickness: 40,
    lineTension: 0.3,
    borderWidth: 1,
    borderRadius: 2,
    hoverBorderWidth: 2,
    hoverBorderRadius: 4,
    borderColor: 'rgba(228,86,244,1)',
    hoverBorderColor: 'rgba(126,37,136,1)',
    backgroundColor: 'rgba(228,86,244,0.2)',
    hoverBackgroundColor: 'rgba(228,86,244,0.4)',
  },
  [SUMMARY_TABLE_ROW_NAMES.NET_INCOME]: {
    label: SUMMARY_TABLE_ROW_NAMES.NET_INCOME,
    minBarLength: 2,
    maxBarThickness: 40,
    lineTension: 0.3,
    borderWidth: 1,
    borderRadius: 2,
    hoverBorderWidth: 2,
    hoverBorderRadius: 4,
    borderColor: 'rgba(26,65,255,1)',
    hoverBorderColor: 'rgba(16,41,163,1)',
    backgroundColor: 'rgba(26,65,255,0.2)',
    hoverBackgroundColor: 'rgba(26,65,255,0.4)',
  },
}

export const normalizeDataToMarginsChart = (data, selectedGraphNames, dateFilters) => {
  if (isEmpty(data)) {
    return {}
  }

  const dates = dateFilters?.length
    ? dateFilters.map(date => {
      return format(parse(date, 'MMM-yy', new Date()), 'MMM-yyyy')
    })
    : getSummaryMonthsLabels()

  const labels = dateFilters?.length
    ? dateFilters
    : getSummaryMonthsLabels('asc', 'MMM-yy')

  const normalizedData = {
    labels,
    datasets: [],
  }

  selectedGraphNames.forEach(graphName => {
    const dataset = {
      ...GRAPH_DATASETS_OPTIONS[graphName],
      data: [],
    }

    const graphData = data.find(obj => {
      return obj.name === graphName
    })

    dates.forEach(month => {
      const value = Number(graphData[month]).toFixed(2)
      dataset.data.push(value)
    })

    normalizedData.datasets.push(dataset)
  })

  return normalizedData
}

const BACKGROUND_COLORS = [
  'rgba(94,155,246,0.85)',
  'rgba(244,106,131,0.85)',
  'rgba(65,228,143,0.85)',
  'rgba(184,184,184,0.85)',
  'rgba(251,160,85,0.85)',
  'rgba(80,81,234,0.85)',
  'rgba(251,87,243,0.85)',
  'rgba(105,246,95,0.85)',
  'rgba(250,223,59,0.85)',
  'rgba(0,220,255,0.85)',
  'rgba(169,108,255,0.85)',
  'rgba(5,129,7,0.85)',
  'rgba(255,94,48,0.85)',
  'rgba(0,20,255,0.85)',
  'rgba(152,181,144,0.85)',
  'rgba(198,18,248,0.85)',
  'rgba(251,41,104,0.85)',
  'rgba(211,241,50,0.85)',
  'rgba(109,218,205,0.85)',
  'rgba(60,120,251,0.85)',
  'rgba(117,154,8,0.85)',
  'rgba(235,149,255,0.85)',
  'rgba(177,177,255,0.85)',
  'rgba(69,85,47,0.85)',
  'rgba(0,255,235,0.85)',
  'rgba(25,52,112,0.85)',
]

const HOVER_BACKGROUND_COLORS = [
  'rgba(94,155,246, 1)',
  'rgba(244,106,131, 1)',
  'rgba(65,228,143, 1)',
  'rgba(184,184,184, 1)',
  'rgba(251,160,85, 1)',
  'rgba(80,81,234, 1)',
  'rgba(251,87,243, 1)',
  'rgba(105,246,95, 1)',
  'rgba(250,223,59, 1)',
  'rgba(0,220,255, 1)',
  'rgba(169,108,255, 1)',
  'rgba(5,129,7, 1)',
  'rgba(255,94,48, 1)',
  'rgba(0,20,255,1)',
  'rgba(152,181,144,1)',
  'rgba(198,18,248,1)',
  'rgba(251,41,104,1)',
  'rgba(211,241,50,1)',
  'rgba(109,218,205,1)',
  'rgba(60,120,251,1)',
  'rgba(117,154,8,1)',
  'rgba(235,149,255,1)',
  'rgba(177,177,255,1)',
  'rgba(69,85,47,1)',
  'rgba(0,255,235,1)',
  'rgba(25,52,112,1)',
]

const HOVER_BORDER_COLORS = [
  'rgba(94,155,246, 0.3)',
  'rgba(244,106,131, 0.3)',
  'rgba(65,228,143, 0.3)',
  'rgba(184,184,184, 0.3)',
  'rgba(251,160,85, 0.3)',
  'rgba(80,81,234, 0.3)',
  'rgba(251,87,243, 0.3)',
  'rgba(105,246,95, 0.3)',
  'rgba(250,223,59, 0.3)',
  'rgba(0,220,255, 0.3)',
  'rgba(169,108,255, 0.3)',
  'rgba(5,129,7, 0.3)',
  'rgba(255,94,48, 0.3)',
  'rgba(0,20,255,0.3)',
  'rgba(152,181,144,0.3)',
  'rgba(198,18,248,0.3)',
  'rgba(251,41,104,0.3)',
  'rgba(211,241,50,0.3)',
  'rgba(109,218,205,0.3)',
  'rgba(60,120,251,0.3)',
  'rgba(117,154,8,0.3)',
  'rgba(235,149,255,0.3)',
  'rgba(177,177,255,0.3)',
  'rgba(69,85,47,0.3)',
  'rgba(0,255,235,0.3)',
  'rgba(25,52,112,0.3)',
]

export const normalizeDataToRevenuesChart = data => {
  const normalizedData = {
    labels: [],
    datasets: [],
  }

  if (!data?.length) {
    return normalizedData
  }

  const revenueBookingSource = data.map(({ revenueBookingSource }) => ({
    ...revenueBookingSource
  }))

  const totalRevenues = revenueBookingSource.reduce((acc, cur) => {
    for (const key in cur) {
      acc += cur[key]
    }

    return acc
  }, 0)

  const bookingRevenues = revenueBookingSource.reduce((acc, cur) => {
    for (const [key, value] of Object.entries(cur)) {
      if (acc.hasOwnProperty(key)) {
        acc[key] = acc[key] + value
      } else {
        acc[key] = value
      }
    }
    return acc
  }, {})

  const filteredBookingRevenues = Object.entries(bookingRevenues)
    .filter(r => !!r[1])
    .sort(([, a],[, b]) => b - a)
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})

  const dataset = {
    label: 'Revenue',
    backgroundColor: [...BACKGROUND_COLORS],
    hoverBackgroundColor: [...HOVER_BACKGROUND_COLORS],
    hoverBorderColor: [...HOVER_BORDER_COLORS],
    borderWidth: 1,
    hoverBorderWidth: 4,
    hoverOffset: 4,
    data: []
  }

  for (const [label, value] of Object.entries(filteredBookingRevenues)) {
    normalizedData.labels.push(`${label}:${(value / totalRevenues * 100).toFixed(2)}%`)
    dataset.data.push(value)
  }

  normalizedData.datasets.push(dataset)

  return normalizedData
}

export const calculatePropertiesValues = (data, valueKey, dates) => {
  return data.map(item => {
    const obj = {}

    for (const [month, value] of Object.entries(item[valueKey])) {
      const label = format(parse(month, 'MMMM:yyyy', new Date()), 'MMM-yyyy')
      obj[label] = value
    }

    return obj
  }).reduce((acc, cur) => {
    for (const key of dates) {
      if (acc.hasOwnProperty(key)) {
        acc[key] = Number.parseFloat(acc[key]) + Number.parseFloat(cur[key])
      } else {
        acc[key] = Number.parseFloat(cur[key])
      }
    }
    return acc
  },{})
}

export const createOccupancyRateCounter = (data, dates) => {
  return data.map(item => {
    const obj = {}

    for (const [month, value] of Object.entries(item.occupancyRateByMonths)) {
      const label = format(parse(month, 'MMMM:yyyy', new Date()), 'MMM-yyyy')
      obj[label] = value
    }

    return obj
  }).reduce((acc, cur) => {
    for (const key of dates) {
      if (acc.hasOwnProperty(key)) {
        acc[key] = cur[key] === 0 ? acc[key] : acc[key] + 1
      } else {
        acc[key] = cur[key] === 0 ? 0 : 1
      }
    }
    return acc
  },{})
}

export const normalizeDataToPropertyMarginsChart = (data, dateFilters) => {
  const dates = dateFilters?.length
    ? dateFilters.map(date => {
      return format(parse(date, 'MMM-yy', new Date()), 'MMM-yyyy')
    })
    : getSummaryMonthsLabels()

  const labels = dateFilters?.length
    ? dateFilters
    : getSummaryMonthsLabels('asc', 'MMM-yy')

  const normalizedData = {
    labels,
    datasets: [],
  }

  if (!data?.length) {
    return normalizedData
  }

  const commonDatasetOptions = {
    minBarLength: 2,
    maxBarThickness: 40,
    lineTension: 0.3,
    borderWidth: 1,
    borderRadius: 2,
    hoverBorderWidth: 2,
    hoverBorderRadius: 4,
  }

  const revenuesDataset = {
    label: 'Total Revenue',
    data: [],
    borderColor: 'rgb(0,180,159)',
    hoverBorderColor: 'rgba(0,122,122,1)',
    backgroundColor: 'rgba(0,180,159,0.2)',
    hoverBackgroundColor: 'rgba(0,180,159,0.4)',
    ...commonDatasetOptions
  }

  const expenseDataset = {
    label: 'Direct Costs',
    data: [],
    borderColor: 'rgba(225,1,1,1)',
    hoverBorderColor: 'rgba(151,1,1,1)',
    backgroundColor: 'rgba(225,1,1,0.2)',
    hoverBackgroundColor: 'rgba(225,1,1,0.4)',
    ...commonDatasetOptions
  }
  const grossProfitDataset = {
    label: 'Gross Profit',
    data: [],
    borderColor: 'rgba(59,131,246,1)',
    hoverBorderColor: 'rgba(20,69,147,1)',
    backgroundColor: 'rgba(59,131,246,0.2)',
    hoverBackgroundColor: 'rgba(59,131,246,0.4)',
    ...commonDatasetOptions
  }

  const occupancyRateDataset = {
    label: 'Occupancy Rate',
    type: 'line',
    yAxisID: 'y1',
    borderColor: 'rgb(116,143,143)',
    backgroundColor: 'rgba(255,255,255,1)',
    pointBorderColor: 'rgb(87,106,106)',
    pointHoverBackgroundColor: 'rgb(216,223,223)',
    pointHoverBorderColor: 'rgb(75,81,81)',
    pointBorderWidth: 4,
    pointHoverRadius: 6,
    lineTension: 0.3,
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    pointBackgroundColor: '#fff',
    data: []
  }

  const revenuesData = calculatePropertiesValues(
    data, 'netRevenueByMonths', dates
  )
  const expenseData = calculatePropertiesValues(
    data, 'expenseByMonths', dates
  )
  const grossProfitData = calculatePropertiesValues(
    data, 'profitByMonths', dates
  )
  const occupancyRateData = calculatePropertiesValues(
    data, 'occupancyRateByMonths', dates
  )
  const occupancyRateCounter = createOccupancyRateCounter(data, dates)

  dates.forEach(month => {
    const revenue = Number(revenuesData[month])
    revenuesDataset.data.push(revenue)

    expenseDataset.data.push(expenseData[month])
    grossProfitDataset.data.push(grossProfitData[month])
    occupancyRateDataset.data.push(
      Number(occupancyRateData[month] / (occupancyRateCounter[month] || 1))
    )
  })

  normalizedData.datasets.push(revenuesDataset)
  normalizedData.datasets.push(expenseDataset)
  normalizedData.datasets.push(grossProfitDataset)
  normalizedData.datasets.push(occupancyRateDataset)

  return normalizedData
}

export const normalizeDataToDirectBookingReferalsChart = data => {
  const dataset = {
    label: 'Direct Booking Referals',
    backgroundColor: [...BACKGROUND_COLORS],
    hoverBackgroundColor: [...HOVER_BACKGROUND_COLORS],
    hoverBorderColor: [...HOVER_BORDER_COLORS],
    borderWidth: 1,
    hoverBorderWidth: 4,
    hoverOffset: 4,
    data: []
  }

  if (isEmpty(data)) {
    return
  }

  const bookingReferals = Object.entries(data)
    .filter(r => !!r[1])
    .sort(([, a],[, b]) => b - a)
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})

  const labels = []

  for (const [label, value] of Object.entries(bookingReferals)) {
    labels.push(label)
    dataset.data.push(value)
  }

  return {
    labels,
    datasets: [dataset]
  }
}

export const normalizeDataToBookingSourcesChart = data => {
  const normalizedData = {
    labels: [],
    datasets: [],
  }

  if (!data?.length) {
    return normalizedData
  }

  const bookingSourceReservations = data.map(({ revenueBookingSourceReservations }) => ({
    ...revenueBookingSourceReservations
  }))

  const totalBookingReservations = bookingSourceReservations.reduce((acc, cur) => {
    for (const value of Object.values(cur)) {
      acc += value
    }

    return acc
  }, 0)

  const bookingReservations = bookingSourceReservations.reduce((acc, cur) => {
    for (const [key, value] of Object.entries(cur)) {
      if (acc.hasOwnProperty(key)) {
        acc[key] = acc[key] + value
      } else {
        acc[key] = value
      }
    }
    return acc
  }, {})

  const sortedBookingReservations = Object.entries(bookingReservations)
    .filter(r => !!r[1])
    .sort(([, a],[, b]) => b - a)
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})

  const dataset = {
    label: 'Reservations per source',
    backgroundColor: [...BACKGROUND_COLORS],
    hoverBackgroundColor: [...HOVER_BACKGROUND_COLORS],
    hoverBorderColor: [...HOVER_BORDER_COLORS],
    borderWidth: 1,
    hoverBorderWidth: 4,
    hoverOffset: 4,
    data: []
  }

  for (const [label, value] of Object.entries(sortedBookingReservations)) {
    normalizedData.labels.push(label)
    dataset.data.push(value / totalBookingReservations * 100)
  }

  normalizedData.datasets.push(dataset)

  return normalizedData
}
