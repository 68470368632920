import React from "react";
import DragHandleIcon from "@mui/icons-material/DragHandle";

const AmenetiesOrderCard = ({ cardText = "Beach Front" }) => {
  return (
    <div className="w-full h-12 flex items-center px-4 my-2 rounded-lg border border-themeGrey">
      <DragHandleIcon className="text-themeGrey mr-2" />
      <div>{cardText}</div>
    </div>
  );
};

export default AmenetiesOrderCard;
