import { Tabs } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  INBOX_AUTOMATIONS,
  INBOX_MANAGE_MESSAGES,
  INBOX_MESSAGES,
  INBOX_MESSAGE_TEMPLATES,
} from "../../constants/dashboardRoutes";
import InboxMessages from "../../components/InboxMessages/InboxMessages";
import InboxMessageTemplates from "../../components/InboxMessageTemplates/InboxMessageTemplates";
import InboxAutomations from "../../components/InboxAutomation/InboxAutomation";
import InboxManageMessages from "../../components/InboxManageMessages/InboxManageMessages";

const Inbox = ({ activeKey }) => {
  const navigate = useNavigate();

  const items = [
    {
      key: "message-templates",
      label: (
        <div
          className="text-themeGreen font-bold flex items-center gap-3"
          onClick={() => {
            navigate(INBOX_MESSAGE_TEMPLATES);
          }}
        >
          Message Templates
        </div>
      ),
      children: <InboxMessageTemplates />,
    },
    {
      key: "automations",
      label: (
        <div
          className="text-themeGreen font-bold flex items-center gap-3"
          onClick={() => {
            navigate(INBOX_AUTOMATIONS);
          }}
        >
          Automations
        </div>
      ),
      children: <InboxAutomations />,
    },
    {
      key: "manage-messages",
      label: (
        <div
          className="text-themeGreen font-bold flex items-center gap-3"
          onClick={() => {
            navigate(INBOX_MANAGE_MESSAGES);
          }}
        >
          Manage Messages
        </div>
      ),
      children: <InboxManageMessages />,
    },
  ];

  if (activeKey === "messages") {
    items.unshift({
      key: "messages",
      label: (
        <div
          className="text-themeGreen font-bold flex items-center gap-3"
          onClick={() => {
            navigate(INBOX_MESSAGES);
          }}
        >
          Messages
        </div>
      ),
      children: <InboxMessages />,
    });
  }
  return (
    <div className="m-5 bg-white px-5" id="custom-tabs">
      <Tabs activeKey={activeKey} items={items} centered />
    </div>
  );
};

export default Inbox;
