import React, { useEffect, useMemo, useRef, useState } from "react";
import Header from "../components/Header/Header";
import DashboardRouter from "../routes/DashboardRouter";
import Sidebar from "../components/Sidebar/Sidebar";
import Sider from "antd/es/layout/Sider";
import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import { AppContext } from "../AppContext";
import { manageToast } from "../components/Common/ManageToast";
import { apiInstance } from "../utils/API";
import { getAuthorizationToken } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loaders/Loading";

const Dashboard = () => {
  const menuRef = useRef(null);
  const [collapsed, setCollapsed] = useState(false);
  const [siderClass, setSiderClass] = useState(false);
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    if (!getAuthorizationToken()) {
      navigate("/");
    } else {
      getProfile();
    }
  }, [getAuthorizationToken()]);

  async function getProfile() {
    try {
      let url = `/auth/profile`;
      const data = await apiInstance.get(url);
      const user = data.data;
      localStorage.setItem("userName", `${user?.firstName} ${user?.lastName}`);
      localStorage.setItem("role", user?.role);
      localStorage.setItem("userEmail", user?.email);
      setProfile(user);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.setItem("authorizationToken", "");
        localStorage.setItem("userName", "");
        localStorage.setItem("role", "");
        localStorage.setItem("userEmail", "");
        setProfile(null);
        navigate("/");
      }
      manageToast(
        false,
        error?.response?.data?.message ||
          error?.message ||
          "Sonthing went wrong"
      );
    }
  }

  useEffect(() => {
    //@ts-ignore
    const checkIfClickedOutside = (e) => {
      //@ts-ignore
      if (
        menuRef.current &&
        (!menuRef.current.contains(e.target) ||
          e.target?.className === "sidebar-panel")
      ) {
        setSiderClass(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, []);

  const value = useMemo(() => ({ profile, setProfile }), [profile]);

  return (
    <AppContext.Provider value={value}>
      {!profile ? (
        <Loading />
      ) : (
        <Layout>
          {siderClass && (
            <div className="bg-black fixed inset-0 z-[999] opacity-50"></div>
          )}
          <Sider
            ref={menuRef}
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            theme="light"
            width={collapsed ? "80px" : "auto"}
            style={{
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              left: 0,
              zIndex: 999,
            }}
            className={`sidebar-panel sidebar-container  border-r-2 border-creme  ${
              siderClass ? "xs:block !top-[48px]" : "xs:hidden md:block"
            }`}
          >
            <Sidebar setSiderClass={setSiderClass} />
          </Sider>
          <Layout className="bg-slate-100">
            <Header siderClass={siderClass} setSiderClass={setSiderClass} />
            <Content className="bg-white">
              <DashboardRouter />
            </Content>
          </Layout>
        </Layout>
      )}
    </AppContext.Provider>
  );
};

export default Dashboard;
