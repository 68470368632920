import { MONDAY_HOME, MONDAY_MY_WORK } from "../constants";
import { HomeOutlined } from "@ant-design/icons";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import SwapHorizOutlinedIcon from "@mui/icons-material/SwapHorizOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import UnfoldLessOutlinedIcon from "@mui/icons-material/UnfoldLessOutlined";
import RecyclingOutlinedIcon from "@mui/icons-material/RecyclingOutlined";
import RestoreFromTrashOutlinedIcon from "@mui/icons-material/RestoreFromTrashOutlined";
import AddIcon from "@mui/icons-material/Add";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import StarIcon from "@mui/icons-material/Star";
import TextIcon from "../components/TextIcon/TextIcon";
import CategoryIcon from "@mui/icons-material/Category";

export const mondaySidebarItems = [
  // Note: Make sure that key names are same as Dashboard route names.
  {
    key: "home",
    label: "Home",
    route: MONDAY_HOME,
    icon: <HomeOutlined />,
  },
  {
    key: "my-work",
    label: "My Work",
    route: MONDAY_MY_WORK,
    icon: <EventAvailableIcon />,
  },
];

export const mondaySidebarOptions = {
  FAVORITES: "favorites",
  BOOKINGS: "bookings",
  GUEST_COMMUNICATION: "guestCommunication",
  NEW_WORKSPACE: "newWorkspace",
  PROPERTIES: "properties",
  ADD_WORKSPACE: "addWorkspace",
  BROWSE_ALL: "browseAll",
};

export const mondaySidebarSelectOptions = [
  {
    value: "favorites",
    label: (
      <div className="flex items-center gap-2 text-mondayText">
        <StarIcon className="text-yellow-500" />
        Favorites
      </div>
    ),
  },
  {
    label: <div className="text-mondayText font-bold">My Workspaces</div>,
    options: [
      {
        value: "bookings",
        label: (
          <div className="flex items-center gap-2 text-mondayText">
            <TextIcon text="B" className="bg-red-500" />
            Bookings
          </div>
        ),
      },
      {
        value: "guestCommunication",
        label: (
          <div className="flex items-center gap-2 text-mondayText">
            <TextIcon text="G" className="bg-pink-500" />
            Guest Communication
          </div>
        ),
      },
      {
        value: "newWorkspace",
        label: (
          <div className="flex items-center gap-2 text-mondayText">
            <TextIcon text="N" className="bg-gray-500" />
            New Workspace
          </div>
        ),
      },
      {
        value: "properties",
        label: (
          <div className="flex items-center gap-2 text-mondayText">
            <TextIcon text="P" className="bg-red-500" />
            Properties
          </div>
        ),
      },
    ],
  },
  {
    label: <div className="text-mondayText font-bold">Options</div>,
    options: [
      {
        value: "addWorkspace",
        label: (
          <div className="text-mondayText flex items-center gap-2">
            <AddIcon />
            Add Workspace
          </div>
        ),
      },
      {
        value: "browseAll",
        label: (
          <div className="text-mondayText flex items-center gap-2">
            <CategoryIcon />
            Browse All
          </div>
        ),
      },
    ],
  },
];

// Sidebar cascading menu.
export const mondaySidebarEllipseOptions = [
  {
    key: "1",
    type: "group",
    label: "Monday Sales CRM Overview",
  },
  {
    type: "divider",
  },
  {
    key: "renameWorkspace",
    label: (
      <div className="flex items-center gap-2">
        <CreateOutlinedIcon style={{ fontSize: "16px" }} />
        Rename workspace
      </div>
    ),
    value: "renameWorkspace",
  },

  {
    key: "changeIcon",
    label: (
      <div>
        <SwapHorizOutlinedIcon
          style={{ fontSize: "16px", marginRight: "8px" }}
        />
        Change icon
      </div>
    ),
    children: [
      {
        key: "backgroundColor",
        label: <div>Icon</div>,
      },
      {
        key: "icon",
        label: <div>Background</div>,
      },
    ],
  },
  {
    key: "manageWorkspace",
    label: (
      <div className="flex items-center gap-2">
        <SettingsOutlinedIcon style={{ fontSize: "16px" }} />
        Manage workspace
      </div>
    ),
    value: "manageWorkspace",
  },
  {
    key: "deleteWorkspace",
    label: (
      <div className="flex items-center gap-2">
        <DeleteOutlineOutlinedIcon style={{ fontSize: "16px" }} />
        Delete workspace
      </div>
    ),
    value: "deleteWorkspace",
  },
  {
    type: "divider",
  },
  {
    key: "addNewWorkspace",
    label: (
      <div className="flex items-center gap-2">
        <AddIcon style={{ fontSize: "16px" }} />
        Delete workspace
      </div>
    ),
    value: "addNewWorkspace",
  },
  {
    key: "browseAllWorkspace",
    label: (
      <div className="flex items-center gap-2">
        <CategoryOutlinedIcon style={{ fontSize: "16px" }} />
        Browse all workspace
      </div>
    ),
    value: "addNewworkspace",
  },
  {
    type: "divider",
  },
  {
    key: "collapseAllFolder",
    label: (
      <div className="flex items-center gap-2">
        <UnfoldLessOutlinedIcon style={{ fontSize: "16px" }} />
        Collapse all workspace
      </div>
    ),
    value: "collapseAllFolder",
  },
  {
    type: "divider",
  },
  {
    key: "viewArchiveTrash",
    label: (
      <div>
        <RecyclingOutlinedIcon
          style={{ fontSize: "16px", marginRight: "8px" }}
        />
        View archive/trash
      </div>
    ),
    children: [
      {
        key: "archive",
        label: (
          <div className="flex items-center gap-2">
            <RestoreFromTrashOutlinedIcon style={{ fontSize: "16px" }} />
            Archive
          </div>
        ),
      },
      {
        key: "trash",
        label: (
          <div className="flex items-center gap-2">
            <DeleteOutlineOutlinedIcon style={{ fontSize: "16px" }} />
            Trash
          </div>
        ),
      },
    ],
  },
];

export const mondayImportDataOptions = [
  {
    key: "excelCsv",
    value: "excelCsv",
    label: <div>Excel/CSV</div>,
  },
  {
    key: "googleSheets",
    value: "googleSheets",
    label: <div>Google Sheets</div>,
  },
  {
    key: "asana",
    value: "asana",
    label: <div>Asana</div>,
  },
  {
    key: "trello",
    value: "trello",
    label: <div>Trello</div>,
  },
  {
    key: "basecamp",
    value: "basecamp",
    label: <div>Basecamp</div>,
  },
  {
    key: "facebookAds",
    value: "facebookAds",
    label: <div>Facebook Ads</div>,
  },
  {
    key: "zendesk",
    value: "zendesk",
    label: <div>Zendesk</div>,
  },
  {
    key: "jira",
    value: "jira",
    label: <div>Jira</div>,
  },
  {
    key: "jiraServer",
    value: "jiraServer",
    label: <div>Jira server</div>,
  },
  {
    key: "pipedrive",
    value: "pipedrive",
    label: <div>Pipedrive</div>,
  },
  {
    key: "hubspot",
    value: "hubspot",
    label: <div>HubSpot</div>,
  },
  {
    key: "salesforce",
    value: "salesforce",
    label: <div>Salesforce</div>,
  },
  {
    key: "copper",
    value: "copper",
    label: <div>Copper</div>,
  },
  {
    key: "zoho",
    value: "zoho",
    label: <div>Zoho</div>,
  },
];

export const mondaySidebarAddItemOptions = [
  {
    key: "newCRMBoards",
    label: (
      <div>
        <SpaceDashboardOutlinedIcon
          style={{ fontSize: "16px", marginRight: "8px" }}
        />
        New CRM boards
      </div>
    ),
    children: [
      {
        key: "coreCRMBoards",
        label: (
          <div>
            <div>Core CRM boards</div>
            <div className="font-bold">
              No core CRM boards found in this workspace.
            </div>
          </div>
        ),
      },
    ],
  },
  {
    key: "newBoard",
    label: (
      <div className="flex items-center gap-2">
        <SpaceDashboardOutlinedIcon style={{ fontSize: "16px" }} />
        New Board
      </div>
    ),
    value: "newBoard",
  },
  {
    key: "newDoc",
    label: (
      <div className="flex items-center gap-2">
        <DescriptionOutlinedIcon style={{ fontSize: "16px" }} />
        New Doc
      </div>
    ),
    value: "newDoc",
  },
  {
    key: "newDashboard",
    label: (
      <div className="flex items-center gap-2">
        <SpaceDashboardOutlinedIcon style={{ fontSize: "16px" }} />
        New Dashboard
      </div>
    ),
    value: "newDashboard",
  },
  {
    type: "divider",
  },
  {
    key: "importData",
    label: (
      <div>
        <DownloadOutlinedIcon
          style={{ fontSize: "16px", marginRight: "8px" }}
        />
        Import data
      </div>
    ),
    children: mondayImportDataOptions,
  },
  {
    key: "chooseFromTemplates",
    label: (
      <div className="flex items-center gap-2">
        <ArticleOutlinedIcon style={{ fontSize: "16px" }} />
        Choose from templates
      </div>
    ),
    value: "chooseFromTemplates",
  },
  {
    type: "divider",
  },
  {
    key: "apps",
    label: (
      <div>
        <AppsOutlinedIcon style={{ fontSize: "16px", marginRight: "8px" }} />
        Apps
      </div>
    ),
    children: [
      {
        key: "coreCRMBoards",
        label: <div>No core CRM boards found in this workspace.</div>,
      },
    ],
  },
  {
    key: "newForm",
    label: (
      <div className="flex items-center gap-2">
        <FeedOutlinedIcon style={{ fontSize: "16px" }} />
        New form
      </div>
    ),
    value: "newForm",
  },
  {
    type: "divider",
  },
  {
    key: "newFolder",
    label: (
      <div className="flex items-center gap-2">
        <FolderOpenOutlinedIcon style={{ fontSize: "16px" }} />
        New folder
      </div>
    ),
    value: "newFolder",
  },
];
