import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import PercentIcon from "@mui/icons-material/Percent";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button, Dropdown, Space, Divider } from "antd";

function GrandTotalMenu() {
  const [isExpanded, setIsExpanded] = useState(true);

  const items = [
    { key: "1", label: "First" },
    { key: "2", label: "Second" },
    { key: "3", label: "Third" },
  ];

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="mt-8 w=1/2">
      <div className="flex justify-between">
        <h1 className="text-xl font-semibold">Grand Total</h1>
        {isExpanded ? (
          <KeyboardArrowDownIcon
            className="cursor-pointer"
            onClick={handleClick}
          />
        ) : (
          <KeyboardArrowLeftIcon
            className="cursor-pointer"
            onClick={handleClick}
          />
        )}
      </div>
      {isExpanded && (
        <div className="mt-12">
          <div className="flex flex-col sm:flex-row items-center sm:justify-between">
            <div className="flex items-center space-x-2 sm:justify-start">
              <Dropdown menu={{ items, selectable: true }}>
                <Button className="text-themeGrey">
                  <Space size={120}>
                    Rental Revenue
                    <KeyboardArrowDownIcon />
                  </Space>
                </Button>
              </Dropdown>
              <Button
                className="text-buttonTheme hover:!bg-transparent hover:!text-buttonHoverTheme"
                type="text"
                icon={<PercentIcon />}
              />
              <span className="-ml-2">$300.0</span>
            </div>
            <div className="sm:flex sm:flex-row sm:items-center sm:justify-start sm:mt-0 md:-mt-8">
              <Button
                className="text-buttonTheme text-base font-semibold hover:!bg-transparent hover:!text-buttonHoverTheme xs:mt-6 sm:mt-0"
                type="text"
                icon={<AddCircleIcon />}
              >
                Add
              </Button>
            </div>
          </div>

          <div className="w-full md:w-2/5">
            <Divider className="mr-32" />
            <div className="flex flex-col md:flex-row md:justify-between md:items-center">
              <span className="font-semibold text-base">Total</span>
              <span className="font-bold text-lg mt-2 md:mt-0 md:ml-5">
                $300.00
              </span>
            </div>
            <Button
              className="bg-labelGreen text-buttonTheme font-semibold mt-2 hover:!bg-buttonHoverTheme w-full"
              type="primary"
            >
              Deduct Percentage
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default GrandTotalMenu;
