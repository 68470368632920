import { useState } from "react";
import {
  Button,
  Input,
  Space,
  Segmented,
  Select,
  Switch,
  Checkbox,
  Divider,
} from "antd";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import ListingCard from "./ListingCard";

function AddAutoTask({ setShowAddAutoTask }) {
  const [disableFirstSegment, setDisableFirstSegment] = useState(false);
  const [disableSecondSegment, setDisableSecondSegment] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [activeTask, setActiveTask] = useState(false);

  const { TextArea } = Input;

  return (
    <div className="bg-white w-full h-auto">
      <Button
        className="text-buttonTheme font-semibold mt-5 hover:!bg-transparent hover:!text-buttonHoverTheme"
        type="text"
        onClick={() => setShowAddAutoTask(false)}
        icon={<KeyboardArrowLeftIcon className="mb-1" />}
      >
        Back to auto-tasks
      </Button>
      <div className="flex justify-between p-6 w-full flex-wrap gap-2">
        <Input
          placeholder="Task Title"
          className="w-[250px] h-[40px] max-[640px]:w-[200px]"
        />
        <Button
          type="primary"
          className="bg-buttonTheme font-semibold hover:!bg-buttonHoverTheme"
        >
          Save auto-task
        </Button>
      </div>
      <div className="flex flex-wrap">
        <div className="flex flex-col p-6 w-full sm:w-[65%] max-[640px]:justify-center">
          <p className="font-bold text-xl">Schedule</p>
          <Space className="flex justify-between max-[640px]:flex-col flex-wrap">
            <div className="flex flex-col">
              <p>Starts at</p>
              <Input className="w-[300px] max-[640px]:w-full" />
            </div>
            <Segmented
              className="mt-5"
              options={["Hours", "Days"]}
              disabled={disableFirstSegment}
            />
            <Segmented
              className="mt-5"
              options={["Before", "At", "After"]}
              onChange={(item) => {
                if (item === "At") {
                  setDisableFirstSegment(true);
                  return;
                }
                setDisableFirstSegment(false);
              }}
            />
            <Select
              placeholder="Status"
              className="w-[300px] max-[640px]:w-full mt-5"
              allowClear
              optionFilterProp="children"
              options={[
                { value: "jack", label: "Jack" },
                { value: "lucy", label: "Lucy" },
                { value: "tom", label: "Tom" },
              ]}
            />
          </Space>
          <Space className="flex justify-between max-[640px]:flex-col flex-wrap mt-5">
            <div className="flex flex-col">
              <p>Should end by</p>
              <Input className="w-[300px] max-[640px]:w-full" />
            </div>
            <Segmented
              className="mt-5"
              options={["Hours", "Days"]}
              disabled={disableSecondSegment}
            />
            <Segmented
              className="mt-5"
              options={["Before", "At", "After"]}
              onChange={(item) => {
                if (item === "At") {
                  setDisableSecondSegment(true);
                  return;
                }
                setDisableSecondSegment(false);
              }}
            />
            <Select
              placeholder="Status"
              className="w-[300px] max-[640px]:w-full mt-5"
              allowClear
              optionFilterProp="children"
              options={[
                { value: "jack", label: "Jack" },
                { value: "lucy", label: "Lucy" },
                { value: "tom", label: "Tom" },
              ]}
            />
          </Space>
          <p className="font-bold text-xl mt-5">Details</p>
          <p className="mt-5">Description</p>
          <TextArea rows={2} />
          <p className="font-bold text-xl mt-5">Tasks Checklist</p>
          <p className="mt-5">Checklist Template</p>
          <Select
            placeholder="Checklist template"
            className="w-full mt-5"
            allowClear
            optionFilterProp="children"
            options={[
              { value: "jack", label: "Jack" },
              { value: "lucy", label: "Lucy" },
              { value: "tom", label: "Tom" },
            ]}
          />
          <p className="font-bold text-xl mt-5">Listing</p>
          <p className="mt-5">Add automatically new listings</p>
          <Switch className="w-10 mt-3" />
          <div className="mt-5 grid grid-cols-1 sm:grid-cols-3 sm:w-full gap-2">
            <Space className="grid grid-cols-1 sm:grid-cols-2 sm:col-span-2 gap-2">
              <Input placeholder="Type to search listings" />
              <Select
                placeholder="Select tags"
                className="w-full"
                mode="tags"
                allowClear
                optionFilterProp="children"
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                  { value: "tom", label: "Tom" },
                ]}
              />
            </Space>
            <Button
              type="primary"
              className="bg-buttonTheme font-semibold hover:!bg-buttonHoverTheme w-28 justify-self-end"
              onClick={() => setSelectAll(!selectAll)}
            >
              {!selectAll ? "Select all" : "Unselect all"}
            </Button>
          </div>
          <div className="mt-5 grid grid-cols-1 sm:grid-cols-2">
            <div className="m-2 shadow-lg">
              <ListingCard selectAll={selectAll} />
            </div>
          </div>
        </div>
        <div className="w-full p-6 bg-themeWhite sm:w-[35%]">
          <p className="font-bold text-xl">Active</p>
          <div className="flex">
            <Switch
              className="w-10 mt-3"
              onChange={() => setActiveTask(!activeTask)}
            />
            <p className="mt-3 ml-2">{activeTask ? "Yes" : "No"}</p>
          </div>
          <p className="font-bold text-xl mt-5">Users</p>
          <p className="mt-5">Assignee</p>
          <Select
            placeholder="Assignee User"
            className="w-full"
            allowClear
            optionFilterProp="children"
            options={[
              { value: "jack", label: "Jack" },
              { value: "lucy", label: "Lucy" },
              { value: "tom", label: "Tom" },
            ]}
          />
          <p className="mt-5">Supervisor</p>
          <Select
            placeholder="Supervisor User"
            className="w-full"
            allowClear
            optionFilterProp="children"
            options={[
              { value: "jack", label: "Jack" },
              { value: "lucy", label: "Lucy" },
              { value: "tom", label: "Tom" },
            ]}
          />
          <p className="mt-5">Group</p>
          <Select
            placeholder="User Group"
            className="w-full"
            allowClear
            optionFilterProp="children"
            options={[
              { value: "jack", label: "Jack" },
              { value: "lucy", label: "Lucy" },
              { value: "tom", label: "Tom" },
            ]}
          />
          <p className="font-bold text-xl mt-5">Link</p>
          <p className="mt-5">Category</p>
          <Select
            placeholder="Category"
            className="w-full"
            allowClear
            optionFilterProp="children"
            options={[
              { value: "jack", label: "Jack" },
              { value: "lucy", label: "Lucy" },
              { value: "tom", label: "Tom" },
            ]}
          />
          <p className="mt-5">Channel</p>
          <Select
            placeholder="Channel"
            className="w-full"
            allowClear
            optionFilterProp="children"
            options={[
              { value: "jack", label: "Jack" },
              { value: "lucy", label: "Lucy" },
              { value: "tom", label: "Tom" },
            ]}
          />
          <p className="font-bold text-xl mt-5">Cost</p>
          <div className="flex justify-between max-[640]:flex-col mt-5 flex-wrap">
            <div className="flex flex-col">
              <p>Cost</p>
              <Input className="w-60" />
            </div>
            <div className="flex flex-col">
              <p>Cost Currency</p>
              <Select
                placeholder="Cost Currency"
                className="w-60"
                allowClear
                optionFilterProp="children"
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                  { value: "tom", label: "Tom" },
                ]}
              />
            </div>
          </div>
          <p className="mt-5">Cost Description</p>
          <TextArea rows={2} placeholder="Cost Description" />
          <Checkbox className="mt-5">Automatically generate expense</Checkbox>
          <Divider />
          <Checkbox>
            Create tasks from this auto-task for existing reservations
          </Checkbox>
        </div>
      </div>
    </div>
  );
}

export default AddAutoTask;
