import React from 'react'
import { Form } from 'antd'

import FormInput from '../../../components/Form/FormInput'
import GenericButton from '../../../components/GenericButton/GenericButton'

const requiredMessage = 'This field is required'
const requiredRules = [{ required: true, message: requiredMessage }]

export default function RoiCalculatorForm (props) {
  const { form, handleFormSubmit, handleFormReset } = props

  const valueFormatter = value => {
    return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const valueParser = value => {
    return value?.replace(/\$\s?|(,*)/g, '')
  }

  return (
    <div className="bg-[#f1f5f9] shadow-card p-3 rounded-lg mb-[25px]">
      <Form
        name="roi-calculator"
        onFinish={handleFormSubmit}
        autoComplete="off"
        form={form}
      >
        <div className={`grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4 
          vlg:grid-cols-7 justify-center`}>
          <FormInput
            formatter={valueFormatter}
            parser={valueParser}
            controls={false}
            labelClassName="!text-black !text-xs"
            name="purchasePrice"
            label="Purchase Price:"
            rules={requiredRules}
            type="number"
          />

          <FormInput
            formatter={valueFormatter}
            parser={valueParser}
            controls={false}
            labelClassName="!text-black !text-xs"
            name="refurbishmentPrice"
            label="Refurbishment Price:"
            rules={requiredRules}
            type="number"
          />

          <FormInput
            formatter={valueFormatter}
            parser={valueParser}
            controls={false}
            labelClassName="!text-black !text-xs"
            name="legalPrice"
            label="Legal Price:"
            rules={requiredRules}
            type="number"
          />

          <FormInput
            formatter={valueFormatter}
            parser={valueParser}
            controls={false}
            labelClassName="!text-black !text-xs"
            name="maintenance"
            label="Maintenance:"
            rules={requiredRules}
            type="number"
          />

          <FormInput
            formatter={valueFormatter}
            parser={valueParser}
            controls={false}
            labelClassName="!text-black !text-xs"
            name="groundRent"
            label="Ground Rent:"
            rules={requiredRules}
            type="number"
          />

          <FormInput
            formatter={valueFormatter}
            parser={valueParser}
            controls={false}
            labelClassName="!text-black !text-xs"
            name="serviceCharge"
            label="Service charge:"
            rules={requiredRules}
            type="number"
          />

          <FormInput
            labelClassName="!text-black !text-xs"
            name="postalCode"
            label="Postal Code:"
            rules={requiredRules}
          />
        </div>

        <div className={`grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4 
          lg:grid-cols-4 vlg:grid-cols-7 justify-center mt-3`}>
          <FormInput
            formatter={valueFormatter}
            parser={valueParser}
            controls={false}
            labelClassName="!text-black !text-xs"
            name="numberOfBedrooms"
            label="Number of Bedrooms:"
            rules={requiredRules}
            type="number"
          />

          <div className="md:col-start-3 lg:col-start-3 lg:grid items-end vlg:col-start-4">
            <FormInput
              formatter={valueFormatter}
              parser={valueParser}
              controls={false}
              className="w-full"
              labelClassName="!text-black !text-xs"
              name="mortgageInterest"
              label="Mortgage Interest:"
              rules={requiredRules}
              type="number"
            />
          </div>

          <div className="flex items-end w-full col-span-2 sm:col-span-1
            vlg:col-start-6 vlg:col-span-2">
            <GenericButton
              onClick={handleFormReset}
              className={`bg-white mr-3 border-buttonTheme text-buttonTheme 
                hover:!text-buttonTheme hover:!border-buttonTheme 
                hover:!bg-themeGreen hover:!bg-opacity-10 !w-full`}
              label="Reset"
            />

            <GenericButton
              type="submit"
              className={`disabled:bg-themeGrey disabled:bg-opacity-20
                disabled:hover:text-white disabled:!text-white hover:text-white 
                hover:!bg-buttonHoverTheme disabled:hover:!bg-opacity-20 shadow-0 
                bg-buttonTheme !w-full`}
              label="Apply"
            />
          </div>
        </div>
      </Form>
    </div>
  )
}