import { Select } from "antd";

const CustomDropdown = ({
  options,
  inputLabel,
  showSearch,
  handleChange,
  value = undefined,
  disabled = false,
  width = null,
}) => {
  return (
    <div>
      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">
        {inputLabel}
      </label>
      <Select
        disabled={disabled}
        showSearch={showSearch}
        style={{ width: width || 160, minWidth: 160 }}
        placeholder="Search to Select"
        optionFilterProp="children"
        filterOption={(input, option) => { 
            return (option?.label.toLocaleLowerCase() ?? "").includes(input.toLocaleLowerCase())
        }}

        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={options}
        onChange={handleChange}
        value={value}
      />
    </div>
  );
};

export default CustomDropdown;
