import { SouthAmerica } from "@mui/icons-material";
import { Checkbox, Input, Modal, Upload } from "antd";
import React, { useState } from "react";

const EditProfileModal = ({ isModalOpen, setIsModalOpen }) => {
  const [imageUrl, setImageUrl] = useState("");
  return (
    <Modal
      title={
        <div className="text-bold text-themeGrey text-lg pb-5 border-b-2 border-creme">
          Change Profile Information
        </div>
      }
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      width={620}
    >
      <div>
        {/* Upload */}
        <div className="grid xs:grid-cols-1 lg:grid-cols-2">
          <div className="flex flex-col items-center">
            <div className="text-themeGrey mb-1">Profile logo:</div>
            <Upload
              name="avatar"
              className="mb-5 h-20 w-[90%] flex items-center justify-center border-2 border-themeGrey border-dotted"
              showUploadList={false}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  className="w-[100%]"
                  alt="avatar"
                  style={{
                    width: "100%",
                  }}
                />
              ) : (
                "Drag user photo and drop it here."
              )}
            </Upload>
          </div>
          <div className="flex flex-col items-center">
            <div className="text-themeGrey mb-1">Profile logo:</div>
            <Upload
              name="avatar"
              className="mb-5 h-20 w-[90%] flex items-center justify-center border-2 border-themeGrey border-dotted"
              showUploadList={false}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  className="w-[100%]"
                  alt="avatar"
                  style={{
                    width: "100%",
                  }}
                />
              ) : (
                "Drag user photo and drop it here."
              )}
            </Upload>
          </div>
        </div>
        {/* Name */}
        <div className="my-5">
          <div className="text-themeGrey font-bold mb-2">Name</div>
          <Input
            value={"Sid flexliving api account"}
            placeholder="Name"
            className="rounded-none w-full"
          />
        </div>
        {/* Surname */}
        <div className="my-5">
          <div className="text-themeGrey font-bold mb-2">Surname</div>
          <Input className="rounded-none w-full" placeholder="Surname" />
        </div>
        {/* Phone */}
        <div className="my-5">
          <div className="text-themeGrey font-bold mb-2">Phone</div>
          <Input
            type="phone"
            className="rounded-none w-full"
            placeholder="Phone Number"
            addonBefore={<SouthAmerica />}
          />
        </div>
        {/* Emergency Phone */}
        <div className="my-5">
          <div className="text-themeGrey font-bold mb-2">Emergency Phone</div>
          <Input
            type="phone"
            className="rounded-none w-full"
            placeholder="Emergency Phone Number"
            addonBefore={<SouthAmerica />}
          />
        </div>
        {/* Address */}
        <div className="my-5">
          <div className="text-themeGrey font-bold mb-2">Address</div>
          <Input className="rounded-none w-full" placeholder="Address" />
        </div>
        {/* City */}
        <div className="my-5">
          <div className="text-themeGrey font-bold mb-2">City</div>
          <Input className="rounded-none w-full" placeholder="city" />
        </div>
        {/* Postal Code */}
        <div className="my-5">
          <div className="text-themeGrey font-bold mb-2">Postal Code</div>
          <Input className="rounded-none w-full" placeholder="postalCode" />
        </div>
        {/* Website */}{" "}
        <div className="my-5">
          <div className="text-themeGrey font-bold mb-2">Website</div>
          <Input className="rounded-none w-full" placeholder="website" />
        </div>
        {/* Invoice Email */}
        <div className="my-5">
          <div className="text-themeGrey font-bold mb-2">Invoice Email</div>
          <Input className="rounded-none w-full" placeholder="invoiceEmail" />
        </div>
        <Checkbox className="my-5 text-themeGrey font-bold">
          Use company logo for guest portal
        </Checkbox>
      </div>
    </Modal>
  );
};

export default EditProfileModal;
