import { permissionTemplateOptions } from "../../../enum";
import FormSelect from "../../Form/FormSelect";
import {
  isAdminAccess,
  isAssistantAccess,
  isCleanerAccess,
  isFinanceAccess,
  isManagerAccess,
  isPropertyOwnerAccess,
} from "./Helper";

export default function UserGroup({ groups, form, isAdmin, isDetails }) {
  console.log(groups);
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mb-4 ">
      <FormSelect
        // toolTipFlag={true} tooltipTitle=""
        onClear={() => {
          form.setFieldsValue({ name: "userGroupId", value: null });
        }}
        label="Select Group"
        bordered={false}
        disabled={isAdmin || groups.length === 0 || isDetails}
        allowClear={true}
        name="userGroupId"
        className="w-[40%] border-2 border-creme"
        placeholder="Select Group"
        optionFilterProp="children"
        onChange={(e) => {
          if (e) {
            const group = groups.find((g) => g._id === e);
            if (group) {
              //   delete group._id;
              delete group.name;
              delete group.createdAt;
              delete group.accessContactData;
              delete group.accessFinancialData;
              delete group.cannotSeeUsersOfOtherGroups;
              delete group.description;
              delete group.hasAccessToNewListings;
              delete group.permissionsTemplates;
              delete group.updatedAt;
              delete group.__v;
              form.setFieldsValue(group);
            }
          }
        }}
        options={groups.map((e) => {
          return { value: e._id, label: e.name };
        })}
      />
      <FormSelect
        disabled={isAdmin || isDetails}
        toolTipFlag={true}
        tooltipTitle="If you are not experienced with permissions, you can select from our pre-defined permission templates to create a user"
        label="Permission Template"
        name="template"
        placeholder="Select Permission Template"
        className="w-[40%] border-2 border-creme"
        optionFilterProp="children"
        allowClear={true}
        options={permissionTemplateOptions}
        onChange={(e) => {
          if (e === "Admin") {
            form.setFieldsValue(isAdminAccess);
          } else if (e === "Manager") {
            form.setFieldsValue(isManagerAccess);
          } else if (e === "Assistant") {
            form.setFieldsValue(isAssistantAccess);
          } else if (e === "Finance") {
            form.setFieldsValue(isFinanceAccess);
          } else if (e === "Property Owner") {
            form.setFieldsValue(isPropertyOwnerAccess);
          } else if (e === "Cleaner") {
            form.setFieldsValue(isCleanerAccess);
          }
        }}
      />
    </div>
  );
}
