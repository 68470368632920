import { useState } from "react";
import { Modal } from "antd";
import GenericButton from "../GenericButton/GenericButton";
import TextArea from "antd/es/input/TextArea";
import { manageToast } from "../Common/ManageToast";
import { apiInstance } from "../../utils/API";
import { useEffect } from "react";

export default function Comment(props) {
  const {
    isModalOpen,
    setIsModalOpen,
    id,
    oldComment = "",
    onSave,
    listingName,
  } = props;
  const [sending, setSending] = useState(false);
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (oldComment) {
      setComment(oldComment);
    }
  }, []);

  const onSubmit = async () => {
    try {
      setSending(true);
      const data = { comment, oldComment, listingName };
      const res = await apiInstance.put(
        `/api/cleaning/${id}/comment?userName=${localStorage.getItem(
          "userName"
        )}`,
        {
          ...data,
        }
      );
      if (res.status === 201) {
        manageToast(true, "Saved Successfully");
        onSave();
      }
      setSending(false);
    } catch (error) {
      manageToast(
        false,
        error?.response?.data?.message || "Something went wrong"
      );
      console.log(error);
      setSending(false);
    }
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={() => {
        setIsModalOpen(false);
      }}
      width={400}
      title="Comment"
      footer={
        <GenericButton
          label="Save"
          isLoading={sending}
          loadingClass="text-white"
          disabled={sending}
          className="bg-buttonTheme hover:!bg-buttonHoverTheme disabled:bg-buttonTheme disabled:hover:!bg-buttonTheme"
          onClick={() => onSubmit()}
          key={2}
        />
      }
    >
      <TextArea
        rows={5}
        disabled={sending}
        value={comment}
        className={` w-[100%] rounded-none placeholder:capitalize`}
        onChange={(e) => {
          setComment(e.target.value);
        }}
      />
    </Modal>
  );
}
