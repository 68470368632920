import { DatePicker, Select } from "antd";
import { formatDate } from "../../../utils/dateHelper";
import GenericButton from "../../GenericButton/GenericButton";
import CustomInput from "../../Input/Input";
import { useContext } from "react";
import { AppContext } from "../../../AppContext";
import dayjs from "dayjs";
import { apiInstance, routes } from "../../../utils/API";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useState } from "react";
import { manageToast } from "../../Common/ManageToast";

let timeOut = null;

export default function TopBar({
  checkOutDay,
  setCheckOutDay,
  getReservations,
  getReservationsCount,
  count,
  checkIn,
  setCheckIn,
  create,
  setCreate,
  tag,
  setTag,
  city,
  setCity,
  limit,
  page,
  sortBy,
  setLimit,
  setPage,
}) {
  const profile = useContext(AppContext).profile || {};
  const isAdmin = profile.isAdmin;
  const [syncing, setSyncing] = useState(false);
  const searchTag = (e) => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    timeOut = setTimeout(() => {
      getReservations(
        limit,
        page,
        checkOutDay,
        sortBy,
        checkIn,
        create,
        e.target.value,
        city
      );
      getReservationsCount(checkOutDay, checkIn, create, e.target.value, city);
    }, 1000);
  };

  const searchCity = (e) => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    timeOut = setTimeout(() => {
      getReservations(
        limit,
        page,
        checkOutDay,
        sortBy,
        checkIn,
        create,
        tag,
        e.target.value
      );
      getReservationsCount(checkOutDay, checkIn, create, tag, e.target.value);
    }, 1000);
  };

  const today = new Date();
  const todayFormatedDate = formatDate(new Date(), "dd MMM, yyyy");
  let tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  let thirdDay = new Date(tomorrow);
  thirdDay.setDate(tomorrow.getDate() + 1);

  let fourthDay = new Date(thirdDay);
  fourthDay.setDate(thirdDay.getDate() + 1);

  const DAYS = [
    { id: 0, name: "Today" },
    { id: 1, name: tomorrow },
    { id: 2, name: thirdDay },
    { id: 3, name: fourthDay },
  ];

  const syncData = async () => {
    try {
      setSyncing(true);
      manageToast(
        true,
        "Sync is started. It will take some time. You can still continue browsing."
      );
      const res = await apiInstance.post(routes.SYNC_VIEWS);
      if (res.data.status === 200) {
      } else {
      }
      setTimeout(() => {
        getReservations(limit, 1);
        setSyncing(false);
      }, 300000);
    } catch (error) {
      if (error) {
        setTimeout(() => {
          manageToast(false, "Server Error. Something went wrong");
          setSyncing(false);
        }, 300010);
      }
    }
  };

  return (
    <div className="mb-4">
      <div className="flex  justify-between items-center mb-4">
        <div className="text-themeGrey text-xl font-bold">
          Reservations{" "}
          {count > 0 && <span className="text-base">({count})</span>}
        </div>
        <div className="flex gap-x-3">
          {isAdmin && (
            <GenericButton
              isLoading={syncing}
              label="Sync"
              loadingClass="text-white"
              disabled={syncing}
              className="disabled:hover:!bg-buttonTheme disabled:hover:text-white disabled:!text-white disabled:bg-buttonTheme  hover:!bg-buttonHoverTheme hover:text-white bg-buttonTheme mr-2 md:mr-5 shadow-0"
              icon={<LocalOfferIcon style={{ fontSize: "14px" }} />}
              onClick={syncData}
            />
          )}
          <Select
            value={limit}
            options={[
              { value: 20, label: 20 },
              { value: 50, label: 50 },
              { value: 100, label: 100 },
            ]}
            className="w-max"
            onChange={(l) => {
              setLimit(l);
              setPage(1);
            }}
          />
        </div>
      </div>
      <div className="flex w-full gap-x-6 justify-between flex-wrap md:flex-nowrap gap-y-4 md:gap-y-0">
        <div className="mob-w-100">
          <div className="text-sm text-buttonTheme font-medium flex items-center gap-x-1 mb-1">
            Check In
            <span style={{ transform: "rotateY(180deg)" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={18}
                height={18}
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M19.002 3h-14c-1.103 0-2 .897-2 2v4h2V5h14v14h-14v-4h-2v4c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.898-2-2-2z"
                />
                <path fill="currentColor" d="m11 16l5-4l-5-4v3.001H3v2h8z" />
              </svg>
            </span>
          </div>
          <div className="">
            <div
              className={`grid gap-x-2 ${
                isAdmin ? "grid-cols-3" : "grid-cols-2"
              }`}
            >
              <GenericButton
                label="Today"
                onClick={() => {
                  const date = new Date();
                  if (
                    todayFormatedDate === formatDate(checkIn, "dd MMM, yyyy")
                  ) {
                    setCheckIn(null);
                    getReservations(
                      limit,
                      page,
                      checkOutDay,
                      sortBy,
                      null,
                      create,
                      tag,
                      city
                    );
                    getReservationsCount(checkOutDay, null, create, tag, city);
                  } else {
                    setCheckIn(date);
                    getReservations(
                      limit,
                      page,
                      checkOutDay,
                      sortBy,
                      date,
                      create,
                      tag,
                      city
                    );
                    getReservationsCount(checkOutDay, date, create, tag, city);
                  }
                }}
                className={`py-1 rounded-md px-[15px] text-white inline-block ${
                  todayFormatedDate === formatDate(checkIn, "dd MMM, yyyy")
                    ? "bg-buttonHoverTheme hover:!bg-buttonHoverTheme hover:!text-white "
                    : "bg-buttonTheme hover:!bg-buttonHoverTheme hover:!text-white "
                }`}
              />
              <DatePicker
                labelName="check In date"
                placeholder="CheckIn"
                className="h-8"
                size="small"
                allowClear={true}
                value={checkIn ? dayjs(checkIn) : null}
                onChange={(e, stringDate) => {
                  const d = !stringDate ? null : new Date(stringDate);
                  setCheckIn(d);
                  getReservations(
                    limit,
                    page,
                    checkOutDay,
                    sortBy,
                    d,
                    create,
                    tag,
                    city
                  );
                  getReservationsCount(checkOutDay, d, create, tag, city);
                }}
              />
              {isAdmin && (
                <DatePicker
                  labelName="creation log"
                  placeholder="Creation Log"
                  className="h-8 px-1 md:px-0"
                  size="small"
                  allowClear={true}
                  value={create ? dayjs(create) : null}
                  onChange={(e, stringDate) => {
                    const d = !stringDate ? null : new Date(stringDate);
                    setCreate(d);
                    getReservations(
                      limit,
                      page,
                      checkOutDay,
                      sortBy,
                      checkIn,
                      d,
                      tag,
                      city
                    );
                    getReservationsCount(checkOutDay, checkIn, d, tag, city);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="mob-w-100">
          <div className="text-sm text-buttonTheme font-medium flex items-center gap-x-1 mb-1">
            Search
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="m19.6 21l-6.3-6.3q-.75.6-1.725.95T9.5 16q-2.725 0-4.612-1.888T3 9.5q0-2.725 1.888-4.612T9.5 3q2.725 0 4.612 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l6.3 6.3l-1.4 1.4ZM9.5 14q1.875 0 3.188-1.313T14 9.5q0-1.875-1.313-3.188T9.5 5Q7.625 5 6.312 6.313T5 9.5q0 1.875 1.313 3.188T9.5 14Z"
                />
              </svg>{" "}
            </span>
          </div>
          <div className="">
            <div className={`grid grid-cols-2 gap-x-3`}>
              <CustomInput
                placeholder="Enter Tag"
                className="custom-input"
                value={tag}
                onChange={(e) => {
                  setTag(e.target.value);
                  searchTag(e);
                }}
              />
              <CustomInput
                placeholder="Enter City"
                className="custom-input"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                  searchCity(e);
                }}
              />
            </div>
          </div>
        </div>
        <div className="checkout-container">
          <div style={{ width: "100%" }}>
            <div className="text-sm text-buttonTheme font-medium flex items-center gap-x-1 mb-1">
              Check Out
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={18}
                  height={18}
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M19.002 3h-14c-1.103 0-2 .897-2 2v4h2V5h14v14h-14v-4h-2v4c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.898-2-2-2z"
                  />
                  <path fill="currentColor" d="m11 16l5-4l-5-4v3.001H3v2h8z" />
                </svg>
              </span>
            </div>
            <div className="button-container">
              <div className="flex flex-wrap md:flex-nowrap gap-y-2 md:gap-y-0 items-center gap-x-1">
                {DAYS.map((e, i) => {
                  return (
                    <GenericButton
                      key={e.id}
                      onClick={() => {
                        if (checkOutDay === e.id) {
                          getReservations(
                            limit,
                            page,
                            null,
                            sortBy,
                            e.id === 0 ? null : checkIn,
                            create,
                            tag,
                            city
                          );
                          getReservationsCount(
                            null,
                            e.id === 0 ? null : checkIn,
                            create,
                            tag,
                            city
                          );
                          setCheckOutDay("");
                        } else {
                          getReservations(
                            limit,
                            page,
                            e.id,
                            sortBy,
                            e.id === 0 ? null : checkIn,
                            create,
                            tag,
                            city
                          );
                          getReservationsCount(
                            e.id,
                            e.id === 0 ? null : checkIn,
                            create,
                            tag,
                            city
                          );
                          setCheckOutDay(e.id);
                        }
                      }}
                      className={`py-1 rounded-md px-[15px] text-white inline-block ${
                        checkOutDay === e.id
                          ? "bg-buttonHoverTheme hover:!bg-buttonHoverTheme hover:!text-white "
                          : "bg-buttonTheme hover:!bg-buttonHoverTheme hover:!text-white "
                      }`}
                      label={
                        e.name !== "Today"
                          ? formatDate(e.name, "dd MMM, yyyy")
                          : e.name
                      }
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
