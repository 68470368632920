import { Input, Select } from "antd";
import React from "react";
import GenericButton from "../GenericButton/GenericButton";
import SmallListingCard from "../SmallListingCard/SmallListingCard";

const AutoPaymentListingSection = () => {
  return (
    <div>
      <div className="grid xs:grid-cols-1 lg:grid-cols-3">
        <Input
          placeholder="Type to search listings"
          className="rounded-none w-full"
        />
        <Select placeholder="Select tags" className="rounded-none w-full" />
        <div className="flex justify-end">
          <GenericButton
            label="Unselect all"
            className="rounded-none text-themeGreen bg-selectedGreen w-24"
          />
        </div>
      </div>
      <div className="grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 my-5">
        <SmallListingCard />
        <SmallListingCard />
        <SmallListingCard />
        <SmallListingCard />
        <SmallListingCard />
      </div>
    </div>
  );
};

export default AutoPaymentListingSection;
