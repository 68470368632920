/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'

import NoAccess from '../../components/NoAccess'
import { AppContext } from '../../AppContext'

import KeyNestSection from './KeyNestSection'
import LockBoxSection from './LockBoxSection'
import TopBar from './TopBar'

function KeysPage () {
  const profile = React.useContext(AppContext).profile || {}
  const canReadLockBox = profile?.isAdmin || profile?.readLockBox
  const canReadKeys = profile?.isAdmin || profile?.readKeys
  const hasReadAccess = canReadKeys || canReadLockBox

  const initialType = canReadLockBox ? 'lockBox' : 'keyNest'

  const [searchKey, setSearchKey] = React.useState('')
  const [search, setSearch] = React.useState('')
  const [keyType, setKeyType] = React.useState('')
  const [type, setType] = React.useState(initialType)
  const [city, setCity] = React.useState(undefined)
  const [status, setStatus] = React.useState(undefined)
  const [lockBoxPageSize, setLockBoxPageSize] = React.useState(20)
  const [keyNestPageSize, setKeyNestPageSize] = React.useState(20)
  const [refetchLockBoxCollection, setRefetchLockBoxCollection] = React.useState(false)

  const triggerLockBoxCollectionRefetch = () => {
    setRefetchLockBoxCollection(prev => !prev)
  }

  if (profile?._id && !hasReadAccess) {
    return <NoAccess />
  }

  return (
    <div className='px-5 pb-5 pt-3'>
      <TopBar
        triggerLockBoxCollectionRefetch={triggerLockBoxCollectionRefetch}
        setSearchKey={setSearchKey}
        setKeyType={setKeyType}
        setStatus={setStatus}
        setSearch={setSearch}
        setType={setType}
        setCity={setCity}
        status={status}
        city={city}
        type={type}
      />

      {canReadLockBox && type === 'lockBox' && (
        <LockBoxSection
          triggerLockBoxCollectionRefetch={triggerLockBoxCollectionRefetch}
          refetchLockBoxCollection={refetchLockBoxCollection}
          setPageSize={setLockBoxPageSize}
          pageSize={lockBoxPageSize}
          search={search}
          status={status}
          city={city}
        />
      )}

      {canReadKeys && type === 'keyNest' && (
        <KeyNestSection
          setPageSize={setKeyNestPageSize}
          pageSize={keyNestPageSize}
          searchKey={searchKey}
          keyType={keyType}
          city={city}
        />
      )}
    </div>
  )
}

export default KeysPage
