import React, { useState } from "react";
import MondayHeader from "../../components/MondayHeader/MondayHeader";
import { Layout, theme } from "antd";
import MondaySidebar from "../../components/MondaySidebar/MondaySidebar";
import MondayDashboardRouter from "../../routes/MondayDashboardRouter/MondayDashboardRouter";
const { Content } = Layout;

const MondayDashboard = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div>
      <MondayHeader />
      <Layout>
        <MondaySidebar
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
        <Layout className="bg-mondayThemePrimary">
          <Content className={`${isCollapsed ? "lg:-ml-[200px]" : "lg:-ml-7"}`}>
            <MondayDashboardRouter />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default MondayDashboard;
