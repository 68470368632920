import React from 'react'
import { Button, Select } from 'antd'

import CustomInput from '../../components/Input/Input'
import { AppContext } from '../../AppContext'

import LockBoxCreateModal from './LockBoxSection/CreateModal'
import { generateCityFilterOptionsByUserAssignedCity } from '../../utils/helper'

let timeOut = null

const labelClassNames = 'text-sm text-buttonTheme font-medium flex items-center gap-x-1 mb-1 mr-5 md:mr-0'

const generateButtonClassNames = active => {
  let bg = active ? 'bg-buttonTheme' : 'bg-gray-300'
  let hover = active
    ? 'hover:!bg-buttonHoverTheme'
    : 'hover:!bg-[#bdc1c5] hover:!text-black'
  let text = active ? 'text-white' : 'text-black'

  return `${bg} ${text} ${hover}`
}

function KeysPageTopBar (props) {
  const {
    city,
    setCity,
    setKeyType,
    setSearchKey,
    setSearch,
    setStatus,
    setType,
    status,
    triggerLockBoxCollectionRefetch,
    type
  } = props

  const profile = React.useContext(AppContext).profile || {}
  const canCreateLockBox = profile?.isAdmin || profile?.createLockBox
  const canReadLockBox = profile?.isAdmin || profile?.readLockBox
  const canReadKeys = profile?.isAdmin || profile?.readKeys

  const [searchKeyValue, setSearchKeyValue] = React.useState('')
  const [searchValue, setSearchValue] = React.useState('')
  const [keyTypeValue, setKeyTypeValue] = React.useState('')
  const [createModalOpen, setCreateModalOpen] = React.useState(false)

  const cityFilterOptions = generateCityFilterOptionsByUserAssignedCity(profile)

  return (
    <div>
      <div className="flex items-end gap-3 flex-wrap">
        <div className="text-themeGrey text-xl font-bold mr-auto">
          Keys{' '}
        </div>

        {canCreateLockBox && type === 'lockBox' && (
          <Button
            className="bg-buttonTheme hover:!bg-buttonHoverTheme"
            onClick={() => setCreateModalOpen(true)}
            type="primary"
          >
            Add new lockBox
          </Button>
        )}

        {canReadLockBox && (
          <Button
            className={generateButtonClassNames(type === 'lockBox')}
            onClick={() => setType('lockBox')}
            type="primary"
          >
            LockBox
          </Button>
        )}

        {canReadKeys && (
          <Button
            className={generateButtonClassNames(type === 'keyNest')}
            onClick={() => setType('keyNest')}
            type="primary"
          >
            KeyNest
          </Button>
        )}
      </div>

      <div className="flex items-end gap-3 flex-wrap pt-2">
        {canReadLockBox && type === 'lockBox' && (
          <>
            <div className="w-[200px]">
              <div className={labelClassNames}>
                Search lockBox
              </div>

              <CustomInput
                placeholder="Search"
                value={searchValue}
                allowClear
                onChange={(e) => {
                  setSearchValue(e.target.value)

                  if (timeOut) {
                    clearTimeout(timeOut)
                  }
                  timeOut = setTimeout(() => {
                    setSearch(e.target.value)
                  }, 600)
                }}
              />
            </div>

            <div className="w-[200px]">
              <div className={labelClassNames}>
                Status
              </div>

              <Select
                className="w-full"
                placeholder="Select status"
                options={[
                  { label: 'Available', value: 'Available' },
                  { label: 'Not Available', value: 'Not Available' },
                ]}
                value={status}
                onChange={value => setStatus(value)}
                allowClear
              />
            </div>
          </>
        )}

        {canReadKeys && type === 'keyNest' && (
          <>
            <div className="w-[200px]">
              <div className={labelClassNames}>
                Search key
              </div>

              <CustomInput
                placeholder="Search"
                value={searchKeyValue}
                allowClear
                onChange={(e) => {
                  setSearchKeyValue(e.target.value)

                  if (timeOut) {
                    clearTimeout(timeOut)
                  }
                  timeOut = setTimeout(() => {
                    setSearchKey(e.target.value)
                  }, 600)
                }}
              />
            </div>

            <div className="w-[200px]">
              <div className={labelClassNames}>
                Key Type
              </div>

              <CustomInput
                placeholder="Search by key type..."
                allowClear
                value={keyTypeValue}
                onChange={(e) => {
                  setKeyTypeValue(e.target.value)

                  if (timeOut) {
                    clearTimeout(timeOut)
                  }
                  timeOut = setTimeout(() => {
                    setKeyType(e.target.value)
                  }, 600)
                }}
              />
            </div>
          </>
        )}

        {(canReadKeys || canReadLockBox) && (
          <div className="w-[200px]">
            <div className={labelClassNames}>
              City
            </div>

            <Select
              className="w-full"
              placeholder="Select city"
              options={cityFilterOptions}
              disabled={!cityFilterOptions.length}
              onChange={value => setCity(value)}
              value={city}
              allowClear
            />
          </div>
        )}
      </div>

      {canCreateLockBox && (
        <LockBoxCreateModal
          open={createModalOpen}
          onClose={() => setCreateModalOpen(false)}
          triggerLockBoxCollectionRefetch={triggerLockBoxCollectionRefetch}
        />
      )}
    </div>
  )
}

export default KeysPageTopBar
