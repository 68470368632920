import { Button, Dropdown, Space, Tabs, DatePicker } from "antd";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RefreshIcon from "@mui/icons-material/Refresh";
import PersonIcon from "@mui/icons-material/Person";

import DataMenu from "./dataMenu";
import SummaryMenu from "./summaryMenu";
import RentalActivityMenu from "./rentalActivityMenu";
import ExpensesMenu from "./expensesMenu";
import AttachmentsMenu from "./attachmentsMenu";
import GrandTotalMenu from "./grandTotalMenu";
import { manageStatementBody } from "./manageStatements";
import "./createAndEdit.css";

export const createAndEditBody = (items) => {
  return (
    <>
      <div className="grid grid-cols-1 xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 vlg:grid-cols-4 gap-1 md:gap-0 lg:gap-1 vlg:gap-1">
        <div className="m-2 ">
          <p>Listings</p>
          <Dropdown
            menu={{
              items,
              selectable: true,
            }}
            trigger={["click"]}
          >
            <Button className="text-themeGrey">
              <Space size={125}>
                Listings
                <KeyboardArrowDownIcon />
              </Space>
            </Button>
          </Dropdown>
        </div>
        <div className="m-2">
          <p>Tags</p>
          <Dropdown
            menu={{
              items,
              selectable: true,
            }}
            trigger={["click"]}
          >
            <Button className="text-themeGrey">
              <Space>
                Tags
                <KeyboardArrowDownIcon />
              </Space>
            </Button>
          </Dropdown>
        </div>
        <div className="m-2">
          <p>From</p>
          <DatePicker className="bg-transparent" />
        </div>
        <div className="m-2">
          <p>To</p>
          <DatePicker className="bg-transparent" />
        </div>
        <div className="m-2">
          <p>Quick Dates</p>
          <Dropdown
            menu={{
              items,
              selectable: true,
            }}
            trigger={["click"]}
          >
            <Button className="text-themeGrey">
              <Space>
                Quick Dates
                <KeyboardArrowDownIcon />
              </Space>
            </Button>
          </Dropdown>
        </div>
        <div className="m-2">
          <p>Channels</p>
          <Dropdown
            menu={{
              items,
              selectable: true,
            }}
            trigger={["click"]}
          >
            <Button className="text-themeGrey">
              <Space size={125}>
                Channels
                <KeyboardArrowDownIcon />
              </Space>
            </Button>
          </Dropdown>
        </div>
        <div className="m-2">
          <p>Statuses</p>
          <Dropdown
            menu={{
              items,
              selectable: true,
            }}
            trigger={["click"]}
          >
            <Button className="text-themeGrey">
              <Space>
                Statuses
                <KeyboardArrowDownIcon />
              </Space>
            </Button>
          </Dropdown>
        </div>
        <div className="m-2">
          <p>Events</p>
          <Dropdown
            menu={{
              items,
              selectable: true,
            }}
            trigger={["click"]}
          >
            <Button className="text-themeGrey">
              <Space>
                Events
                <KeyboardArrowDownIcon />
              </Space>
            </Button>
          </Dropdown>
        </div>
        <div className="m-1 ">
          <Button
            type="primary"
            className="bg-labelGreen text-buttonTheme font-semibold mt-2 hover:!bg-buttonHoverTheme item-center"
            icon={<RefreshIcon />}
          >
            Reset
          </Button>
        </div>
      </div>
      <DataMenu />
      <SummaryMenu />
      <RentalActivityMenu />
      <ExpensesMenu />
      <AttachmentsMenu />
      <GrandTotalMenu />
    </>
  );
};
function CreateAndEdit() {
  const items = [
    { key: "1", label: "First" },
    { key: "2", label: "Second" },
    { key: "3", label: "Third" },
  ];

  const tabItems = [
    {
      key: 1,
      label: "CREATE & EDIT",
      children: createAndEditBody(items),
    },
    {
      key: 2,
      label: "MANAGE STATEMENTS ",
      children: manageStatementBody(items),
    },
  ];

  return (
    <div className="p-6 w-full h-full">
      <div className="grid grid-rows-10">
        <div className="m-4">
          <h1 className="font-semibold text-4xl">Owner Statements</h1>
        </div>
        <div className="">
          <div className="flex flex-wrap gap-3 w-full m-4">
            <Button
              type="primary"
              className="bg-buttonTheme font-semibold hover:!bg-buttonHoverTheme"
              icon={<AddCircleIcon />}
            >
              New
            </Button>
            <Button
              type="primary"
              className="bg-buttonTheme font-semibold hover:!bg-buttonHoverTheme"
              icon={<SaveOutlinedIcon />}
            >
              Save
            </Button>
            <Button
              type="primary"
              className="bg-labelGreen text-buttonTheme font-semibold hover:!bg-buttonHoverTheme"
              icon={<CachedOutlinedIcon />}
            >
              Refresh
            </Button>
            <Button
              type="primary"
              className="bg-labelGreen text-buttonTheme font-semibold hover:!bg-buttonHoverTheme"
              icon={<PersonIcon />}
            >
              Assign Owners
            </Button>
            <Button
              type="primary"
              className="bg-labelGreen text-buttonTheme font-semibold hover:!bg-buttonHoverTheme"
              icon={<SaveAltIcon />}
            >
              Download
            </Button>
            <Dropdown
              menu={{
                items,
                selectable: true,
              }}
              trigger={["click"]}
            >
              <Button>
                <Space size={50}>
                  Select owner statement
                  <KeyboardArrowDownIcon />
                </Space>
              </Button>
            </Dropdown>
          </div>
        </div>
      </div>
      <Tabs
        defaultActiveKey="1"
        items={tabItems}
        tabBarStyle={{ paddingLeft: "10px" }}
      >
        {tabItems.map((item) => (
          <Tabs.TabPane tab={item.label} key={item.key}>
            {item.children}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
}

export default CreateAndEdit;
