import React from 'react'
import { isNull, isUndefined } from 'lodash'
import { Form, Checkbox } from 'antd'

import FormInput from '../../../components/Form/FormInput'
import GenericButton from '../../../components/GenericButton/GenericButton'

const requiredMessage = 'This field is required'
const requiredRules = [{ required: true, message: requiredMessage }]

const graterThanZeroValidator = (_, value) => {
  if (isNull(value) || isUndefined(value)) {
    return Promise.reject(new Error('This field is required'))
  }

  if (value <= 0) {
    return Promise.reject(new Error('Value should be grater than 0'))
  }

  return Promise.resolve()
}

export default function RoiCalculatorForm (props) {
  const { form, handleFormSubmit, handleFormReset } = props

  const valueFormatter = value => {
    return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const valueParser = value => {
    return value?.replace(/\$\s?|(,*)/g, '')
  }

  return (
    <div className="bg-[#f1f5f9] shadow-card p-3 rounded-lg mb-[25px]">
      <Form
        name="roi-calculator"
        onFinish={handleFormSubmit}
        autoComplete="off"
        form={form}
      >
        <div className={`grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4 
          vlg:grid-cols-5 justify-center`}>
          <FormInput
            formatter={valueFormatter}
            parser={valueParser}
            controls={false}
            labelClassName="!text-black !text-xs"
            name="purchasePrice"
            label="Purchase Price:"
            rules={requiredRules}
            type="number"
            prefix="£"
          />

          <FormInput
            formatter={valueFormatter}
            parser={valueParser}
            controls={false}
            labelClassName="!text-black !text-xs"
            name="depositPercentage"
            label="Deposit Percentage:"
            rules={requiredRules}
            type="number"
            suffix="%"
          />

          <FormInput
            formatter={valueFormatter}
            parser={valueParser}
            controls={false}
            labelClassName="!text-black !text-xs"
            name="refurbishment"
            label="Refurbishment Price:"
            rules={requiredRules}
            type="number"
            prefix="£"
          />

          <FormInput
            formatter={valueFormatter}
            parser={valueParser}
            controls={false}
            labelClassName="!text-black !text-xs"
            name="legalPrice"
            label="Legal Price:"
            rules={requiredRules}
            type="number"
            prefix="£"
          />

          <FormInput
            formatter={valueFormatter}
            parser={valueParser}
            controls={false}
            labelClassName="!text-black !text-xs"
            name="bridgingLoanIr"
            label="Bridging Loan IR(base+):"
            rules={requiredRules}
            type="number"
            suffix="%"
          />

          <FormInput
            formatter={valueFormatter}
            parser={valueParser}
            controls={false}
            labelClassName="!text-black !text-xs"
            name="bridgingDuration"
            label="Bridging Duration(months):"
            rules={[{ validator: graterThanZeroValidator }]}
            type="number"
          />

          <FormInput
            formatter={valueFormatter}
            parser={valueParser}
            controls={false}
            labelClassName="!text-black !text-xs"
            name="bankFee"
            label="Bank Fee:"
            rules={[{ validator: graterThanZeroValidator }]}
            type="number"
            suffix="%"
            required
          />

          <FormInput
            formatter={valueFormatter}
            parser={valueParser}
            controls={false}
            labelClassName="!text-black !text-xs"
            name="otherCosts"
            label="Other Costs:"
            rules={requiredRules}
            type="number"
            prefix="£"
          />

          <FormInput
            formatter={valueFormatter}
            parser={valueParser}
            controls={false}
            labelClassName="!text-black !text-xs"
            name="revaluationPrice"
            label="Re-valuation Price:"
            rules={requiredRules}
            type="number"
            prefix="£"
          />

          <FormInput
            formatter={valueFormatter}
            parser={valueParser}
            controls={false}
            labelClassName="!text-black !text-xs"
            name="expectedNetRevenues"
            label="Expected Net Revenues:"
            rules={requiredRules}
            type="number"
            prefix="£"
          />

          <FormInput
            formatter={valueFormatter}
            parser={valueParser}
            controls={false}
            labelClassName="!text-black !text-xs"
            name="mortgageIr"
            label="Mortgage IR:"
            rules={requiredRules}
            type="number"
            suffix="%"
          />

          <FormInput
            formatter={valueFormatter}
            parser={valueParser}
            controls={false}
            labelClassName="!text-black !text-xs"
            name="ltv"
            label="LTV:"
            rules={requiredRules}
            type="number"
            suffix="%"
          />

          <div>
            <FormInput
              formatter={valueFormatter}
              parser={valueParser}
              controls={false}
              labelClassName="!text-black !text-xs"
              name="internetCost"
              label="InternetCost:"
              rules={requiredRules}
              type="number"
              prefix="£"
            />

            <Form.Item
              name="internetUseMonday"
              valuePropName="checked"
              className="mb-0"
            >
              <Checkbox disabled className="flex items-center h-[30px] mt-1">
                Use Monday Data
              </Checkbox>
            </Form.Item>
          </div>

          <div>
            <FormInput
              formatter={valueFormatter}
              parser={valueParser}
              controls={false}
              labelClassName="!text-black !text-xs"
              name="utilityBills"
              label="Utility Bills(year):"
              rules={requiredRules}
              type="number"
              prefix="£"
            />

            <Form.Item
              name="utilityBillsUseMonday"
              valuePropName="checked"
              className="mb-0"
            >
              <Checkbox disabled className="flex items-center h-[30px] mt-1">
                Use Monday Data
              </Checkbox>
            </Form.Item>
          </div>

          <div>
            <FormInput
              formatter={valueFormatter}
              parser={valueParser}
              controls={false}
              labelClassName="!text-black !text-xs"
              name="councilTax"
              label="Council Tax(year):"
              rules={requiredRules}
              type="number"
              prefix="£"
            />

            <Form.Item
              name="councilTaxUseMonday"
              valuePropName="checked"
              className="mb-0"
            >
              <Checkbox disabled className="flex items-center h-[30px] mt-1">
                Use Monday Data
              </Checkbox>
            </Form.Item>
          </div>

          <FormInput
            formatter={valueFormatter}
            parser={valueParser}
            controls={false}
            labelClassName="!text-black !text-xs"
            name="serviceCharge"
            label="Service Charge(year):"
            rules={requiredRules}
            type="number"
            prefix="£"
          />

          <FormInput
            formatter={valueFormatter}
            parser={valueParser}
            controls={false}
            labelClassName="!text-black !text-xs"
            name="groundRent"
            label="Ground Rent(year):"
            rules={requiredRules}
            type="number"
            prefix="£"
          />

          <FormInput
            formatter={valueFormatter}
            parser={valueParser}
            controls={false}
            labelClassName="!text-black !text-xs"
            name="maintenance"
            label="Maintenance Cost(year):"
            rules={requiredRules}
            type="number"
            prefix="£"
          />

          <FormInput
            labelClassName="!text-black !text-xs"
            name="postalCode"
            label="Postal Code:"
            rules={requiredRules}
          />

          <FormInput
            formatter={valueFormatter}
            parser={valueParser}
            controls={false}
            labelClassName="!text-black !text-xs"
            name="numberOfBedrooms"
            label="Number of Bedrooms:"
            rules={requiredRules}
            type="number"
          />
        </div>

        <div className={`grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4 
          vlg:grid-cols-5 justify-center`}>
          <GenericButton
            onClick={handleFormReset}
            className={`bg-white mr-3 border-buttonTheme text-buttonTheme 
              hover:!text-buttonTheme hover:!border-buttonTheme 
              hover:!bg-themeGreen hover:!bg-opacity-10 !w-full mt-[24px] 
              col-start-1 sm:col-start-2 md:col-start-3 vlg:col-start-4`}
            label="Reset"
          />

          <GenericButton
            type="submit"
            className={`disabled:bg-themeGrey disabled:bg-opacity-20
              disabled:hover:text-white disabled:!text-white hover:text-white 
              hover:!bg-buttonHoverTheme disabled:hover:!bg-opacity-20 shadow-0 
              bg-buttonTheme !w-full mt-[24px]`}
            label="Apply"
          />
        </div>
      </Form>
    </div>
  )
}