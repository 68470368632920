import React from 'react'

import Loading from '../../components/Loaders/Loading'
import { numberWithCommas } from '../../utils/helper'

export default function PropertyMarginsGraph (props) {
  const { chartData = {} } = props

  const [chartJsComp, setChartJsComp] = React.useState()

  React.useEffect(() => {
    const importComponent = async () => {
      const module = await import('react-chartjs-2')
      await import('chart.js/auto')

      setChartJsComp(module)
    }

    importComponent()
  }, [])

  if (!chartJsComp) {
    return (
      <div className={`h-[50vh] bg-white rounded-lg flex justify-center 
        items-center`}>
        <Loading/>
      </div>
    )
  }

  const { Chart } = chartJsComp

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,
      },
      y: {
        ticks: {
          callback: (value) => '£ ' + numberWithCommas(value),
        },
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          callback: (value) => value + '%',
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: ({ dataset, formattedValue, raw }) => {
            const hasPercentage = dataset.yAxisID === 'y1'
            const value = numberWithCommas(raw)
            const cashSign = hasPercentage ? '' : '£ '
            const percentSign = hasPercentage ? '%' : ''

            return `${dataset.label}: ${cashSign}${value}${percentSign}`
          },
        },
      },
      legend: {
        position: 'bottom',
        labels: {
          padding: 20,
        },
      },
    }
  }

  return (
    <div className={`w-[100%] h-[22rem] sm:h-[28rem] md:h-[30rem] pt-5 flex 
      justify-center`}>
      <Chart
        data={chartData}
        type="bar"
        options={chartOptions}
      />
    </div>
  )
}