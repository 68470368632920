import React from "react";

const TextIcon = ({ text, className, applyBigStyle }) => {
  return (
    <div
      className={`px-1 rounded-md flex items-center justify-center ${
        className ? className : "bg-red-500"
      } text-mondayText font-extrabold ${applyBigStyle}`}
    >
      {text}
    </div>
  );
};

export default TextIcon;
