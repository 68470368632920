/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useState } from "react";
import { getQuery } from "../../utils/helper";
import { apiInstance } from "../../utils/API";
import { differenceInDays, startOfDay } from "date-fns";
import { formatDate } from "../../utils/dateHelper";
import { Pagination, Select, Table } from "antd";
import TopBar from "../../components/Views/reservations_/TopBar";
import { AppContext } from "../../AppContext";
import Status from "../../components/Views/reservations_/Status";
import Loading from "../../components/Loaders/Loading";
import { Icon } from "@iconify-icon/react";
import NoAccess from "../../components/NoAccess";

export default function ViewReservations() {
  const profile = React.useContext(AppContext).profile || {};
  const isAdmin = profile.isAdmin;
  const hasReadAccess = profile.readViews;
  const [checkOutDay, setCheckOutDay] = useState(null);
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [checkIn, setCheckIn] = useState(null);
  const [create, setCreate] = useState(null);
  const [tag, setTag] = useState(null);
  const [city, setCity] = useState(null);

  useEffect(() => {
    if (hasReadAccess)
      getReservations(
        limit,
        page,
        checkOutDay,
        sortBy,
        checkIn,
        create,
        tag,
        city
      );
  }, [limit, page, hasReadAccess]);

  useEffect(() => {
    if (hasReadAccess)
      getReservationsCount(checkOutDay, checkIn, create, tag, city);
  }, [hasReadAccess]);

  const getReservations = async (
    limit,
    page,
    checkOutDay,
    sortBy,
    checkIn,
    create,
    tag,
    city
  ) => {
    setLoading(true);
    try {
      let url = `api/views/reservations?&${getQuery(limit, page - 1)}`;
      url = url + "&day=" + (checkOutDay === null ? "" : checkOutDay);
      if (sortBy) {
        url = url + "&sort=" + sortBy;
      }
      if (checkIn) {
        url = url + "&checkin=" + checkIn;
      }
      if (create) {
        url = url + "&create=" + create;
      }
      if (tag) {
        url = url + "&tag=" + tag;
      }
      if (city) {
        url = url + "&city=" + city;
      }
      const response = await apiInstance.get(url);
      setReservations(response.data || []);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getReservationsCount = async (
    checkOutDay,
    checkIn,
    create,
    tag,
    city
  ) => {
    try {
      let url = `api/views/reservations/count?`;
      url = url + "&day=" + (checkOutDay === null ? "" : checkOutDay);
      if (checkIn) {
        url = url + "&checkin=" + checkIn;
      }
      if (create) {
        url = url + "&create=" + create;
      }
      if (tag) {
        url = url + "&tag=" + tag;
      }
      if (city) {
        url = url + "&city=" + city;
      }
      const response = await apiInstance.get(url);
      setCount(response.data.count);
    } catch (e) {
      console.log(e);
    }
  };

  const columns = [
    {
      title: "Guest name",
      dataIndex: "guest",
      key: "guest",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        return <Status status={record.status} />;
      },
    },
    {
      title: "Flat booked",
      dataIndex: "flatBooked",
      key: "flatBooked",
    },
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
    },
    {
      title: (
        <div
          className={`cursor-pointer flex items-center gap-x-1 ${
            sortBy === "checkInAsc" || sortBy === "checkInDesc"
              ? "text-buttonTheme"
              : ""
          }`}
        >
          CheckIn
          <span
            className="flex items-center justify-center  transition-all duration-300"
            style={{
              transform:
                sortBy === "checkInAsc" ? "rotateX(180deg)" : "rotateX(0deg)",
            }}
            onClick={() => {
              if (sortBy === "checkInAsc") {
                setSortBy("checkInDesc");
                getReservations(
                  limit,
                  page,
                  checkOutDay,
                  "checkInDesc",
                  checkIn,
                  create,
                  tag,
                  city
                );
              } else if (sortBy === "checkInDesc") {
                setSortBy("");
                getReservations(
                  limit,
                  page,
                  checkOutDay,
                  "",
                  checkIn,
                  create,
                  tag,
                  city
                );
              } else {
                setSortBy("checkInAsc");
                getReservations(
                  limit,
                  page,
                  checkOutDay,
                  "checkInAsc",
                  checkIn,
                  create,
                  tag,
                  city
                );
              }
            }}
          >
            <Icon icon="prime:sort-amount-up" height={24} width={24} />
          </span>
        </div>
      ),
      dataIndex: "checkInDate",
      key: "checkInDate",
      render: (_, record) => {
        const getCheckInDate = () => {
          const date = record.checkInDate;
          if (!date) return "";

          if (
            differenceInDays(
              startOfDay(new Date(date)),
              startOfDay(new Date())
            ) === 0
          )
            return "Today";
          return formatDate(date, "dd MMM, yyyy");
        };
        const getColor = () => {
          const date = record.checkInDate;
          if (!date) return "";

          if (
            differenceInDays(
              startOfDay(new Date(date)),
              startOfDay(new Date())
            ) === 0
          )
            return "#164F4C";
        };
        return <div style={{ color: getColor() }}>{getCheckInDate()}</div>;
      },
    },
    {
      title: (
        <div
          className={`cursor-pointer flex items-center gap-x-1 ${
            sortBy === "checkOutAsc" || sortBy === "checkOutDesc"
              ? "text-buttonTheme"
              : ""
          }`}
        >
          Checkout
          <span
            className="flex items-center justify-center  transition-all duration-300"
            style={{
              transform:
                sortBy === "checkOutAsc" ? "rotateX(180deg)" : "rotateX(0deg)",
            }}
            onClick={() => {
              if (sortBy === "checkOutAsc") {
                setSortBy("checkOutDesc");
                getReservations(
                  limit,
                  page,
                  checkOutDay,
                  "checkOutDesc",
                  checkIn,
                  create,
                  tag,
                  city
                );
              } else if (sortBy === "checkOutDesc") {
                setSortBy("");
                getReservations(
                  limit,
                  page,
                  checkOutDay,
                  "",
                  checkIn,
                  create,
                  tag,
                  city
                );
              } else {
                setSortBy("checkOutAsc");
                getReservations(
                  limit,
                  page,
                  checkOutDay,
                  "checkOutAsc",
                  checkIn,
                  create,
                  tag,
                  city
                );
              }
            }}
          >
            <Icon icon="prime:sort-amount-up" height={24} width={24} />
          </span>
        </div>
      ),
      dataIndex: "checkOutDate",
      key: "checkOutDate",
      render: (_, record) => {
        const getCheckOutDate = () => {
          const date = record.checkOutDate;
          if (!date) return "";
          const diff = differenceInDays(
            startOfDay(new Date(date)),
            startOfDay(new Date())
          );
          if (diff === 0) {
            return "Today";
          } else if (diff === 1) {
            return "Tomorrow";
          } else if (diff === 2) {
            return "In 2 Days";
          } else if (diff === 3) {
            return "In 3 Days";
          }
          return formatDate(date, "dd MMM, yyyy");
        };

        const getOutColor = () => {
          const date = record.checkOutDate;
          if (!date) return "";
          const diff = differenceInDays(
            startOfDay(new Date(date)),
            startOfDay(new Date())
          );
          if (diff === 0) {
            return "#cca300";
          } else if (diff === 1) {
            return "#e6b800";
          } else if (diff === 2) {
            return "#ffd11a";
          } else if (diff === 3) {
            return "#ffdb4d";
          }
          return "";
        };

        return (
          <div
            style={{ backgroundColor: getOutColor() }}
            className="text-center rounded-md"
          >
            {getCheckOutDate()}
          </div>
        );
      },
    },
    {
      title: (
        <div
          className={`cursor-pointer flex items-center gap-x-1 ${
            sortBy === "creationLogAsc" || sortBy === "creationLogDesc"
              ? "text-buttonTheme"
              : ""
          }`}
        >
          Creation log
          <span
            className="flex items-center justify-center  transition-all duration-300"
            style={{
              transform:
                sortBy === "creationLogAsc"
                  ? "rotateX(180deg)"
                  : "rotateX(0deg)",
            }}
            onClick={() => {
              if (sortBy === "creationLogAsc") {
                setSortBy("creationLogDesc");
                getReservations(
                  limit,
                  page,
                  checkOutDay,
                  "creationLogDesc",
                  checkIn,
                  create,
                  tag,
                  city
                );
              } else if (sortBy === "creationLogDesc") {
                setSortBy("");
                getReservations(
                  limit,
                  page,
                  checkOutDay,
                  "",
                  checkIn,
                  create,
                  tag,
                  city
                );
              } else {
                setSortBy("creationLogAsc");
                getReservations(
                  limit,
                  page,
                  checkOutDay,
                  "creationLogAsc",
                  checkIn,
                  create,
                  tag,
                  city
                );
              }
            }}
          >
            <Icon icon="prime:sort-amount-up" height={24} width={24} />
          </span>
        </div>
      ),
      dataIndex: "from",
      key: "from",
      render: (_, record) => {
        return <div>{formatDate(record.creationLog, "dd MMM, yyyy")}</div>;
      },
    },
  ];

  if (profile._id && isAdmin) {
    columns.push({
      title: (
        <div
          className={`cursor-pointer flex items-center gap-x-1 ${
            sortBy === "bookingAsc" || sortBy === "bookingDesc"
              ? "text-buttonTheme"
              : ""
          }`}
        >
          Booking Value
          <span
            className="flex items-center justify-center  transition-all duration-300"
            style={{
              transform:
                sortBy === "bookingAsc" ? "rotateX(180deg)" : "rotateX(0deg)",
            }}
            onClick={() => {
              if (sortBy === "bookingAsc") {
                setSortBy("bookingDesc");
                getReservations(
                  limit,
                  page,
                  checkOutDay,
                  "bookingDesc",
                  checkIn,
                  create,
                  tag,
                  city
                );
              } else if (sortBy === "bookingDesc") {
                setSortBy("");
                getReservations(
                  limit,
                  page,
                  checkOutDay,
                  "",
                  checkIn,
                  create,
                  tag,
                  city
                );
              } else {
                setSortBy("bookingAsc");
                getReservations(
                  limit,
                  page,
                  checkOutDay,
                  "bookingAsc",
                  checkIn,
                  create,
                  tag,
                  city
                );
              }
            }}
          >
            <Icon icon="prime:sort-amount-up" height={24} width={24} />
          </span>
        </div>
      ),
      dataIndex: "bookingValue",
      key: "bookingValue",
    });
  }

  if (profile._id && !hasReadAccess) {
    return <NoAccess />;
  }
  return (
    <div className="p-5">
      <TopBar
        checkOutDay={checkOutDay}
        setCheckOutDay={setCheckOutDay}
        count={count}
        getReservations={getReservations}
        getReservationsCount={getReservationsCount}
        checkIn={checkIn}
        setCheckIn={setCheckIn}
        create={create}
        setCreate={setCreate}
        tag={tag}
        setTag={setTag}
        city={city}
        setCity={setCity}
        limit={limit}
        page={page}
        setLimit={setLimit}
        setPage={setPage}
        sortBy={sortBy}
      />

      {loading ? (
        <div className="h-[50vh] bg-white rounded-lg flex justify-center items-center">
          <Loading />
        </div>
      ) : (
        <Table
          loading={loading}
          dataSource={reservations || []}
          columns={columns}
          pagination={false}
          scroll={{
            x: 1000,
          }}
        />
      )}
      <div className="flex flex-wrap gap-3 justify-end my-4">
        <Select
          value={limit}
          options={[
            { value: 20, label: 20 },
            { value: 50, label: 50 },
            { value: 100, label: 100 },
          ]}
          className="w-max"
          onChange={(l) => {
            setLimit(l);
            setPage(1);
          }}
        />
        <Pagination
          defaultCurrent={page}
          current={page}
          total={count}
          pageSize={limit}
          showSizeChanger={false}
          onChange={(p) => {
            if (p !== page) {
              setPage(p);
            }
          }}
        />
      </div>
    </div>
  );
}
