import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import SettingsIcon from "@mui/icons-material/Settings";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { Button, Dropdown, Space, List, Table } from "antd";

function RentalActivityMenu() {
  const [isExpanded, setIsExpanded] = useState(true);

  const items = [
    { key: "1", label: "First" },
    { key: "2", label: "Second" },
    { key: "3", label: "Third" },
  ];

  const columns = [
    {
      title: <div className="text-themeGrey font-semibold">Guests</div>,
      dataIndex: "guests",
      key: "guests",
      width: "10%",
    },
    {
      title: <div className="text-themeGrey font-semibold">Nights</div>,
      dataIndex: "nights",
      key: "nights",
    },
    {
      title: (
        <div className="text-themeGrey font-semibold">
          Listing Default Order
          <Dropdown
            className="mt-2"
            menu={{
              items,
              selectable: true,
            }}
            placement="bottom"
            trigger={["click"]}
          >
            <Button
              className="text-buttonTheme hover:!bg-transparent hover:!text-buttonHoverTheme"
              type="text"
              icon={<SwapVertIcon />}
            />
          </Dropdown>
        </div>
      ),
      dataIndex: "listing",
      key: "listing",
      width: "25%",
    },
    {
      title: <div className="text-themeGrey font-semibold">Channel</div>,
      dataIndex: "chanel",
      key: "chanel",
    },
    {
      title: <div className="text-themeGrey font-semibold">Rental Revenue</div>,
      dataIndex: "rentalRevenue",
      key: "rentalRevenue",
    },
    {
      title: <div className="text-themeGrey font-semibold">PM Commission</div>,
      dataIndex: "pmCommission",
      key: "pmCommission",
    },
    {
      title: <div className="text-themeGrey font-semibold">Owner Payout</div>,
      dataIndex: "ownerPayout",
      key: "ownerPayout",
    },
  ];

  const data = [
    {
      key: "1",
      guests: <div className="">Final Test Final</div>,
      nights: "1",
      listing: (
        <div className="text-themeGreen">
          3B SW15-The Peckham Apartments (155615)
        </div>
      ),
      channel: "Flexliving Direct",
      rentalRevenue: "$300.00",
      pmCommission: "$0.00",
      ownerPayout: "$0.00",
    },
    {
      key: "2",
      guests: <div className="font-bold">Total</div>,
      nights: <div className="font-bold">2</div>,
      listing: <div className="text-themeGreen"></div>,
      channel: <div className="font-bold"></div>,
      rentalRevenue: <div className="font-bold">$300.00</div>,
      pmCommission: <div className="font-bold">$0.00</div>,
      ownerPayout: <div className="font-bold">$0.00</div>,
    },
  ];

  //     return (
  //       <div className="flex">
  //         <Button
  //           className="mt-2 w-48 pl-0 flex justify-start text-buttonTheme hover:!bg-transparent hover:!text-buttonHoverTheme"
  //           type="link"
  //         >
  //           {item.guest}
  //         </Button>
  //         <p className="mt-3 ml-1 w-28">{item.nights}</p>
  //         <Button
  //           className="mt-2 w-96 flex justify-start text-buttonTheme hover:!bg-transparent hover:!text-buttonHoverTheme"
  //           type="link"
  //         >
  //           {item.listing}
  //         </Button>
  //         <p className="mt-3 ml-20 w-64">{item.channel}</p>
  //         <p className="mt-3 ml-5 w-52">{item.rentalRevenue}</p>
  //         <p className="mt-3 w-52">{item.pmCommission}</p>
  //         <p className="mt-3 ml-1">{item.ownerPayout}</p>
  //       </div>
  //     );
  //   };

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  //   const listItems = [
  //     {
  //       guest: "Final Test Final",
  //       nights: "1",
  //       listing: "3B SW15-The Putney Appartment",
  //       channel: "Hostaway Direct",
  //       rentalRevenue: "$300.00",
  //       pmCommission: "$0.00",
  //       ownerPayout: "$0.00",
  //     },
  //   ];

  return (
    <div className="mt-8">
      <div className="flex justify-between">
        <div className="flex">
          <h1 className="text-xl mb-4 font-semibold">Rental Activity</h1>
          <Dropdown
            className="ml-4 overflow-x-auto"
            menu={{
              items,
              selectable: true,
              multiple: true,
            }}
          >
            <Button className="text-themeGrey">
              <Space>
                Per Reservation
                <KeyboardArrowDownIcon />
              </Space>
            </Button>
          </Dropdown>
        </div>
        {isExpanded ? (
          <KeyboardArrowDownIcon
            className="cursor-pointer"
            onClick={handleClick}
          />
        ) : (
          <KeyboardArrowLeftIcon
            className="cursor-pointer"
            onClick={handleClick}
          />
        )}
      </div>
      {isExpanded && (
        <>
          <div>
            <Table
              className="border-t border-creme"
              dataSource={data}
              columns={columns}
              scroll={{
                x: 1000,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default RentalActivityMenu;
