import { Input, Modal } from "antd";
import React from "react";

const EditAPIKeyModal = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <Modal
      title={
        <div className="text-bold text-themeGrey text-xl pb-5 border-b-2 border-creme">
          Edit public API secret key
        </div>
      }
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      width={620}
    >
      <div className="my-5">
        <div className="text-themeGrey mt-5 mb-1 font-bold">Name</div>
        <Input placeholder="name" className="rounded-none w-full" />
      </div>
      <div className="my-5">
        <div className="text-themeGrey mt-5 mb-1 font-bold">API key</div>
        <Input placeholder="name" className="rounded-none w-full" disabled />
      </div>
      <div className="my-5">
        <div className="text-themeGrey mt-5 mb-1 font-bold">Partner</div>
        <Input placeholder="name" className="rounded-none w-full" disabled />
      </div>
    </Modal>
  );
};

export default EditAPIKeyModal;
