export const calculateROIValues = (values, properties) => {
  const mortgage = (values.purchasePrice / 100) * 75
  const deposit = (values.purchasePrice / 100) * 25
  const mortgageInterestOnly = mortgage *
    (values.mortgageInterest / 100) / 12
  let stampDuty = 0

  if (values.purchasePrice <= 125000) {
    stampDuty = values.purchasePrice * 0.03
  } else if (values.purchasePrice <= 250000) {
    stampDuty = (values.purchasePrice - 125000) * 0.05 + 3750
  } else if (values.purchasePrice <= 925000) {
    stampDuty = (values.purchasePrice - 250000) * 0.08 + 10000
  } else if (values.purchasePrice <= 1500000) {
    stampDuty = (values.purchasePrice - 925000) * 0.13 + 64000
  } else {
    stampDuty = (values.purchasePrice - 1500000) * 0.15 + 138750
  }

  let revenues = 0
  let revenueCounter = 0
  let costs = 0
  let totalCosts = 0
  let costsCounter = 0
  let ebit = 0
  let netIncome = 0
  let roi = 0

  properties.forEach(property => {
    for (let key in property.netRevenueByMonths) {
      revenues += property.netRevenueByMonths[key]
      if (property.netRevenueByMonths[key]) {
        revenueCounter += 1
      }
    }

    for (let key in property.expenseByMonths) {
      costs += property.expenseByMonths[key]
      if (property.expenseByMonths[key]) {
        costsCounter += 1
      }
    }
  })

  const averageRevenue = revenues / (revenueCounter || 1)
  const averageCosts = costs / (costsCounter || 1)

  totalCosts = averageCosts + values.serviceCharge + values.groundRent +
    values.maintenance
  ebit = averageRevenue - totalCosts
  netIncome = ebit - mortgageInterestOnly
  roi = netIncome / (deposit + values.groundRent + values.maintenance +
      values.legalPrice + values.refurbishmentPrice + values.serviceCharge)
    * 100

  return {
    ...values,
    mortgage,
    mortgageInterestOnly,
    averageRevenue,
    averageCosts,
    ebit,
    totalCosts,
    netIncome,
    stampDuty,
    deposit,
    roi
  }
}

const labelStyles = {
  borderRight: '1px solid #676a6c',
  borderBottom: '1px solid #676a6c'
}

const firstItemLabelStyles = {
  border: '1px solid #676a6c',
  borderLeft: 'none'
}

const contentStyles = {
  borderBottom: '1px solid #676a6c'
}

const firstItemContentStyles = {
  border: '1px solid #676a6c',
  borderLeft: 'none',
  borderRight: 'none'
}

export const createResultTableItems = calculatedValues => ([
  {
    label: '',
    key: 'Cash in',
    text: 'Cash in',
    labelStyles: {
      border: 'none',
      borderRight: '1px solid #676a6c'
    },
    contentStyles: {
      fontWeight: 'bold'
    }
  },
  {
    label: 'Purchase Price',
    key: 'Purchase Price',
    children: calculatedValues.purchasePrice,
    labelStyles: firstItemLabelStyles,
    contentStyles: firstItemContentStyles,
    hasCashSign: true
  },
  {
    label: 'Refurbishment Price',
    key: 'Refurbishment Price',
    children: calculatedValues.refurbishmentPrice,
    hasCashSign: true,
    labelStyles,
    contentStyles
  },
  {
    label: 'Stamp Duty',
    key: 'Stamp Duty',
    children: calculatedValues.stampDuty,
    hasCashSign: true,
    labelStyles,
    contentStyles
  },
  {
    label: 'Legal',
    key: 'Legal',
    children: calculatedValues.legalPrice,
    hasCashSign: true,
    labelStyles,
    contentStyles
  },
  {
    label: 'Mortgage (75%)',
    key: 'Mortgage (75%)',
    children: calculatedValues.mortgage,
    hasCashSign: true,
    labelStyles,
    contentStyles
  },
  {
    label: 'Deposit (25%)',
    key: 'Deposit (25%)',
    children: calculatedValues.deposit,
    hasCashSign: true,
    labelStyles,
    contentStyles
  },
  {
    label: '',
    key: 'Space 1',
    noValue: true,
    labelStyles: { height: 30, border: 'none' }
  },
  {
    label: '',
    key: 'Cash model',
    text: 'Cash model',
    labelStyles: { borderRight: 'none' },
    contentStyles: {
      border: '1px solid #676a6c',
      borderRight: 'none',
      fontWeight: 'bold'
    }
  },
  {
    label: 'Average net revenues',
    key: 'Average net revenues',
    children: calculatedValues.averageRevenue,
    hasCashSign: true,
    labelStyles: firstItemLabelStyles,
    contentStyles: firstItemContentStyles,
    bgColor: 'rgba(221, 248, 204, 0.8)'
  },
  {
    label: 'Average Costs per month',
    key: 'Average Costs per month',
    children: calculatedValues.averageCosts,
    hasCashSign: true,
    labelStyles,
    contentStyles
  },
  {
    label: 'Service Charge',
    key: 'Service Charge',
    children: calculatedValues.serviceCharge,
    hasCashSign: true,
    labelStyles,
    contentStyles
  },
  {
    label: 'Ground Rent',
    key: 'Ground Rent',
    children: calculatedValues.groundRent,
    hasCashSign: true,
    labelStyles,
    contentStyles
  },
  {
    label: 'Maintenance',
    key: 'Maintenance',
    children: calculatedValues.maintenance,
    hasCashSign: true,
    labelStyles,
    contentStyles
  },
  {
    label: 'Total Costs',
    key: 'Total Costs',
    children: calculatedValues.totalCosts,
    hasCashSign: true,
    bgColor: 'rgba(193, 239, 235, 0.8)',
    labelStyles,
    contentStyles
  },
  {
    label: '',
    key: 'Space 2',
    noValue: true,
    labelStyles: { height: 30, border: 'none' }
  },
  {
    label: 'EBIT',
    key: 'EBIT',
    children: calculatedValues.ebit,
    hasCashSign: true,
    bgColor: 'rgba(172, 220, 164, 0.8)',
    labelStyles: {
      ...labelStyles,
      borderTop: '1px solid #676a6c'
    },
    contentStyles: {
      ...contentStyles,
      borderTop: '1px solid #676a6c'
    }
  },
  {
    label: `Mortgage Interest Only (${calculatedValues.mortgageInterest || '0'}%)`,
    key: 'Mortgage Interest Only (5.5%)',
    children: calculatedValues.mortgageInterestOnly,
    hasCashSign: true,
    labelStyles,
    contentStyles
  },
  {
    label: '',
    key: 'Space 3',
    noValue: true,
    labelStyles: { height: 30, border: 'none' }
  },
  {
    label: 'Monthly Net Income',
    key: 'Monthly Net Income',
    children: calculatedValues.netIncome,
    hasCashSign: true,
    bgColor: 'rgba(119, 169, 126, 0.9)',
    labelStyles: {
      ...labelStyles,
      borderTop: '1px solid #676a6c'
    },
    contentStyles: {
      ...contentStyles,
      borderTop: '1px solid #676a6c'
    }
  },
  {
    label: 'ROI',
    key: 'ROI',
    children: calculatedValues.roi,
    withPercentSign: true,
    labelStyles: {
      ...labelStyles,
      color: '#000000'
    },
    contentStyles: {
      ...contentStyles,
      color: '#000000',
      fontWeight: 'bold'
    }
  }
])